<div class="pt-3 pb-3">
  <h6> {{ 'component-block-wizard.block.title' | translate }}</h6>
  <small>
    {{ 'component-block-wizard.block.description' | translate }}
  </small>
</div>

<div class="row pl-3">
  <div class="col">
    <div class="pt-3">
      <div>
      <input class="form-check-input" type="radio" name="addNew" id="addNew" (change)="onActionTypeChanged()" [checked]="!extend">
      <label class="form-check-label" for="addNew">
        {{ 'component-block-wizard.block.add' | translate }}
      </label>
      </div>
      <div>
      <input class="form-check-input" type="radio" id="extendExisting" name="extendExisting" (change)="onActionTypeChanged()" [checked]="extend">
      <label class="form-check-label" for="extendExisting">
        {{ 'component-block-wizard.block.extend' | translate }}
      </label>
      </div>
    </div>
    </div>
  <div class="col pt-3">
    <ng-container *ngIf="extend">
      <div>
        <h6>{{ 'component-block-wizard.block.extendBlock.title' | translate}}</h6>
      </div>
      <op-select-box [options]="options" (selectEvent)="blockingActionSelected($event)">

      </op-select-box>

      <ng-container *ngIf="ComponentBlockWizardService.componentBlock.blockingAction">
        <small class="pl-1">
          {{ ComponentBlockWizardService.componentBlock.blockingAction.blockingReason }}

          <a [href]="['/actions/blockings/edit/' + ComponentBlockWizardService.componentBlock.blockingActionNr]" target="_blank">
            <i class="cpp-icon-link pl-3"></i>
          </a>
        </small>
      </ng-container>

      <op-form-help-text
        ariaId="blockingActionSelectionHelp"
        helpText="component-release-block.blockingActionSelectionHelp">
      </op-form-help-text>
    </ng-container>
    <ng-container *ngIf="!extend">
      <div>
        <h6>{{ 'component-block-wizard.block.addBlock.title' | translate}}</h6>
      </div>
      <textarea
        [(ngModel)]="blockingReason"
        class="form-control"
        aria-describedby="blockingReasonHelp"
        rows="3"
        required
        [disabled]="ComponentBlockWizardService.componentBlock.state !== ComponentBlockStatus.None">
      </textarea>
      <op-form-help-text ariaId="blockingReasonHelp" helpText="form.actions.blocking-action.help.blocking"></op-form-help-text>
    </ng-container>

    <div class="row pl-3 small">
      <div class="cpp-column-fixed-width-25">
        <i *ngIf="ComponentBlockWizardService.componentBlock.state === ComponentBlockStatus.Initiated" class="far fa-hourglass fa-spin" style="color: darkgray"></i>
        <i *ngIf="ComponentBlockWizardService.componentBlock.state === ComponentBlockStatus.Done" class="fas fa-check-circle" style="color: darkgreen"></i>
        <i *ngIf="ComponentBlockWizardService.componentBlock.state === ComponentBlockStatus.Failed" class="fas fa-times-circle" style="color: darkred"></i>
      </div>
    </div>
  </div>
</div>
<div class="row pl-3 pt-3">
  <div class="col">

  </div>
  <div class="col text-right">
    <button *ngIf="!cancelHidden" class="btn btn-sm btn-secondary mr-3" (click)="cancel()"
            [disabled]="blocking">{{ 'button.cancel' | translate }}
    </button>

    <button *ngIf="ComponentBlockWizardService.componentBlock.state !== ComponentBlockStatus.Done"
            class="btn btn-sm btn-primary"
            (click)="blockSelected()"
            [disabled]="blocking || invalid ">
      {{ 'button.save' | translate }}
    </button>

    <button *ngIf="ComponentBlockWizardService.componentBlock.state === ComponentBlockStatus.Done"
            class="btn btn-sm btn-primary"
            (click)="close()">
      {{ 'button.close' | translate }}
    </button>

    <i *ngIf="ComponentBlockWizardService.componentBlock.state === ComponentBlockStatus.Initiated" class="far fa-hourglass fa-spin pl-1" style="color: darkgray"></i>
    <i *ngIf="ComponentBlockWizardService.componentBlock.state === ComponentBlockStatus.Done" class="fas fa-check-circle pl-1" style="color: darkgreen"></i>
    <i *ngIf="ComponentBlockWizardService.componentBlock.state === ComponentBlockStatus.Failed" class="fas fa-times-circle pl-1" style="color: darkred"></i>
  </div>
</div>


<ng-template #legacyBlockingTooltip>
  {{ 'component-release-wizard.legacy-blocking.tooltip' | translate }}
</ng-template>
