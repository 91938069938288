<div class="container-fluid" [class.cpp-page-defaults]="!kioskMode" *ngIf="EnterpriseRoleProviderService.hasRequiredClaimByName('sfm-board-read', false)">
  <div class="py-2 mb-2 border-bottom">
    <op-breadcrumb [path]="'Home/SFM-Board'"></op-breadcrumb>
    <div class="float-right" style="cursor: pointer"><i class="fas fa-question"
                                                        (click)="scrollTo('loading-documentation')"></i></div>
  </div>
  <div class="row pt-3">
    <div class="col pr-0">
      <ul class="nav border-bottom">
        <li class="nav-item pr-0">
          <a [routerLink]="['./']"
             [queryParams]="{ activeTab: 'all', showInactiveShifts: showInactive ? 'true' : 'false' }">
            <button type="button" class="btn btn-sm button"
                    [class.cpp-button-tab-selected]="isActive('all')"
                    (click)="setActive('all')">
              {{ 'page.sfm.overView' | translate }}
            </button>
          </a>
        </li>
        <li class="nav-item pr-0">
          <a [routerLink]="['./']"
             [queryParams]="{ activeTab: 'costCenterView', showInactiveShifts: showInactive ? 'true' : 'false' }">
            <button type="button" class="btn btn-sm button"
                    [class.cpp-button-tab-selected]="isActive('costCenterView')"
                    (click)="setActive('costCenterView')">
              {{ 'page.sfm.costCenterView' | translate }}
            </button>
          </a>
        </li>
      </ul>
    </div>
    <div class="col-3 text-right border-bottom" *ngIf="!isActive('all') && !isActive('productUnitView')">
      <div class="custom-control custom-switch">
        <input type="checkbox" id="showInactive" class="custom-control-input" [(ngModel)]="showInactive"
               (ngModelChange)="toggleShowInactive()" [disabled]="!hasActiveItems()">
        <label for="showInactive" class="custom-control-label"> {{ 'page.sfm.showInactiveShifts' | translate }}</label>
      </div>
    </div>
  </div>


  <ng-container *ngIf="isActive('costCenterView')">
    <div class="row pt-3">
      <div class="col pr-0">
        <ul class="nav border-bottom">
          <li class="nav-item pr-0">
            <a [routerLink]="['./']"
               [queryParams]="{ activeTab: activeTab, pcActive: 'pcAll', showInactiveShifts: showInactive ? 'true' : 'false'}">
              <button type="button" class="btn btn-sm button"
                      [class.cpp-button-tab-selected]="isPcActive('pcAll')"
                      (click)="setPcActive('pcAll')">
                {{ 'page.sfm.allProductCenter' | translate }}
              </button>
            </a>
          </li>
          <ng-container *ngFor="let pC of productCenters">
          <ng-container *ngIf="pC.ProductUnits.length  > 0">
            <li class="nav-item pr-0">
              <a [routerLink]="['./']"
                 [queryParams]="{ activeTab: activeTab, pcActive: pC.Id, showInactiveShifts: showInactive ? 'true' : 'false'}">
                <button type="button" class="btn btn-sm button"
                        [class.cpp-button-tab-selected]="isPcActive(pC.Id)"
                        (click)="setPcActive(pC.Id)">
                  {{ pC.Description ? pC.Description : pC.Id }}
                </button>
              </a>
            </li>
          </ng-container>
          </ng-container>
        </ul>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isActive('costCenterView') && !isPcActive('pcAll')">
    <div class="row pt-3">
      <div class="col pr-0">
        <ul class="nav border-bottom">
          <li class="nav-item pr-0">
            <a [routerLink]="['./']"
               [queryParams]="{ activeTab: activeTab, pcActive: pcActive, puActive: 'puAll', showInactiveShifts: showInactive ? 'true' : 'false' }">
              <button type="button" class="btn btn-sm button"
                      [class.cpp-button-tab-selected]="isPuActive('puAll')"
                      (click)="setPuActive('puAll')">
                {{ 'page.sfm.allProductUnits' | translate }}
              </button>
            </a>
          </li>
          <ng-container *ngFor="let pU of productUnitList">
            <li class="nav-item pr-0">
              <a [routerLink]="['./']"
                 [queryParams]="{ activeTab: activeTab, pcActive: pcActive,  puActive: pU.Id, showInactiveShifts: showInactive ? 'true' : 'false'}">
                <button type="button" class="btn btn-sm button"
                        [class.cpp-button-tab-selected]="isPuActive(pU.Id)"
                        (click)="setPuActive(pU.Id)">
                  {{ pU.Description ? pU.Description : pU.Name }}
                </button>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </ng-container>

  <div class="row pl-3">
    <ng-container *ngFor="let sfm of sfmBoardViewModelListFiltered">
      <ng-container *ngIf="sfm.Description !== 'Werk Salzgitter'">
        <op-sfm-card [kioskMode]="kioskMode" [sfm]="sfm" [treeMode]="false" [showInactive]="showInactive"></op-sfm-card>
      </ng-container>
    </ng-container>
  </div>


  <div class="row pt-3" *ngIf="loading" style="min-font-size: 300px">
    <div class="col text-center">
      <div class="spinner-border spinner-border-sm"></div>
    </div>
  </div>

  <div class="row p-3 pt-5">
    <div class="col-sm-12">
      <div id="loading-documentation">
        <op-detail-section-wrapper
          [title]="'Anleitung und Dokumentation'"
          [description]="'Dies ist der Dokumentationsbereich für die Shopfloor-Management Tafel.'"
          [contentTemplate]="documentationTemplateRef"
          [hasContent]="true"
          [selfLink]="undefined"
          [contentContainerId]="'loading-documentation'">
        </op-detail-section-wrapper>
      </div>
    </div>
  </div>
</div>


<ng-template #documentationTemplateRef>
  <small>
    <section id="Einleitung">
      <h6>Einleitung</h6>
    <p>
        Die SFM-Tafel ist eine Anforderung aus dem Speed+. Sie soll einen schnellen Einblick in die aktuelle Fertigungssituation des Werkes erlauben.
        Dabei liegt der Schwerpunkt auf Folgenden Bereichen:
    </p>
      <ul>
      <li>
        Technische Störung
      </li>
      <li>
        Qualität
      </li>
      <li>
        Versorgungssicherheit
      </li>
      <li>
        Personal
      </li>
      <li>
        Logistik
      </li>
      <li>
        Arbeitssicherheit
      </li>
    </ul>

      Die Umsetzung erfolgte anhand <a href="https://devstack.vwgroup.com/jira/browse/ITSZDEV-368">UserStory ITSZDEV-368</a>.
      Function-Owner ist der Bereich Fertigung, aktuell vertreten durch <a href="mailto:barbara.glaeser@volkswagen.de">Barbara Gläser (CPS-O)</a>
    </section>

    <br>
    <br>

    <section id="Tab ProductCente">
    <h6>Tab ProductCenter</h6>

      Das Tab ProduktCenter visualisiert den aktuellen Zustand aller Produktcenter im Werk. Die Zustände aus den Kostenstellen werden hierbei allerdings nicht vererbt,
      d.h. die Indikatoren der einzelnen Bereiche müssen explizit von den Verantwortlichen Mitarbeiter in den korrekten Zustand gesetzt werden.
      <br>
      siehe: <b>Status für einen Indikator setzen</b>
    </section>

    <br>
    <br>

    <section id="Tab Kostenstellen">
    <h6>Tab Kostenstellen</h6>

    Unterhalb des Tab Kostenstellen werden initial alle Kostenstellen visualisiert.
    <br>
    <br>

    <div class="row">
      <div class="col-3">
        <div class="custom-control custom-switch">
          <input type="checkbox" id="showInactive-documentation" class="custom-control-input">
          <label for="showInactive-documentation"
                 class="custom-control-label"> {{ 'page.sfm.showInactiveShifts' | translate }}</label>
        </div>
      </div>
      <div class="col">
          Kostenstellen, die aktuell keine aktive Produktion haben, werden standardmäßig ausgeblendet. Über den Schalter oben Rechts können auch inaktive Kostenstellen angezeigt werden.
      </div>
    </div>

    Die Kostenstellen können nach Productcenter und anschließend nach Produkteinheit gefiltert werden, in dem man den jeweiligen Tab auswählt,
    <br>
    </section>

    <br>
    <br>

    <section id="Status für einen Indikator setzen">
    <h6>Status für einen Indikator setzen</h6>
    <p>
      Analog zur SFM-Tafel in SZOnline 1.0, kann man durch einen Klick auf die jeweilige Sektion (z.B. Techn. Störung) den Zustand
      der Sektion für das ausgewählte Ziel (Produktcenter oder Kostenstelle) verändern.

      <b>Der Wechsel zwischen den Zuständen (Grün / Rot) ist aktuell ohne Beschränkungen möglich.</b> Zu einem späteren Zeitpunkt wird es nach dem setzen
      des Status "Rot" erst nach einer gewissen Zeit wieder möglich sein in den Status "Grün" zu wechseln.

      Um Mitternacht werden alle Zustände wieder auf "Grün" zurückgesetzt.
    </p>
      <div class="row">
        <div class="col-2">
           <span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
                 style="max-width: 200px; max-height: 30px"
                 [class.badge-danger]="true">Logistk</span>
        </div>
        <div class="col pt-1">
         Der Zustand in diesem Bereich ist Rot. Dies bedeutet, es sind während des aktuellen Schichttages bereits Probleme vorhanden oder Probleme sind absehbar.
        </div>
      </div>

      <div class="row">
        <div class="col-2">
            <span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
                  style="max-width: 200px; max-height: 30px"
                  [class.badge-success]="true">Logistk</span>
        </div>
        <div class="col pt-1">
          Der Zustand in diesem Bereich ist Grün. Dies bedeutet, die Produktion sollte keinen erwartbaren Einschränkungen bzw. vorhersehbaren Problemen unterliegen.
        </div>
      </div>
    </section>

    <br>
    <br>

    <section id="Weiterführende Links in an den Kostenstellen">
    <h6>Weiterführende Links in an den Kostenstellen</h6>

    <div class="row pt-3">
    <div class="col-1">
    <div class="text-center border" style="height: 30px; width: 30px;">
      <a target="_blank">
        <i class="fas fa-cog"></i>
      </a>
    </div>
    </div>
    <div class="col">
      Dieser Link führt direkt zur Konfigurationsseite der jeweiligen Kostenstelle. Über die Konfigurationsseite kann die Kostenstelle im System parametrisiert werden.
      Der Link öffnet die Seite automatisch in einem neuen Browser-Tab.
    </div>
      </div>

    <div class="row pt-3">
      <div class="col-1">
        <div class="text-center border pointer" style="height: 30px; width: 30px;">
          <a target="_blank">
            <i class="fas fa-binoculars"></i>
          </a>
        </div>
      </div>
      <div class="col">
        Dieser Link öffnet die aktuelle Großbilanzeige der jeweilligen Kostenstelle.
        Der Link öffnet die Seite automatisch in einem neuen Browser-Tab.
      </div>
    </div>

    <div class="row pt-3">
      <div class="col-1">
        <div class="text-center border" style="height: 30px; width: 30px;">
          <a target="_blank">
            <i class="fas fa-chart-line"></i>
          </a>
        </div>
      </div>
      <div class="col">
        Dieser Link öffnet die aktuelle Ausbringungsstatistik für diese Kostenstelle.
        Der Link öffnet die Seite automatisch in einem neuen Browser-Tab.
      </div>
    </div>

    <div class="row pt-3">
      <div class="col-1">
        <div class="text-center border" style="height: 30px; width: 30px;">
          <a target="_blank">
            <i class="fas fa-percent"></i>
          </a>
        </div>
      </div>
      <div class="col">
        Dieser Link öffnet die aktuelle OEE-Statistik für diese Kostenstelle.
        Der Link öffnet die Seite automatisch in einem neuen Browser-Tab.
      </div>
    </div>
    </section>

    <br>
    <br>

    <section id="Performance Indikator">
    <h6>Performance Indikator</h6>

      Der Performance Indikator ist eine Neuerung in SZOnline 2.0. Er visualisiert die aktuelle Performance der
      jeweiligen Kostenstelle auf Basis der IST und SOLL Zahlen aus der Fertigung. Dieser Zustand wird auch "nach oben" vererbt.
      <br><br>
      Das bedeutet, der Performance Zustand eines Produktcenters ergibt sich aus der Performance der zugehörigen Kostenstellen.

      <br>
      <br>
      <div class="row pt-3">
      <div class="col-3">
<span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
      style="max-width: 200px; max-height: 30px"
      [class.badge-success]="true">Performance</span>
      </div>
      <div class="col">
        Ein grüner Performance Zustand bedeutet, die Kostenstelle erfüllt Ihr SOLL zum aktuellen Zeitpunkt vollständig (oder übererfüllt es).
      </div>
    </div>
      <div class="row pt-3">
        <div class="col-3">
      <span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
            style="max-width: 200px; max-height: 30px"
            [class.badge-danger]="true">Performance</span>
        </div>
        <div class="col">
          Ein roter Performance Zustand bedeutet, die Kostenstelle unterschreitet Ihr SOLL zum aktuellen Zeitpunkt um <b>20</b> oder mehr Prozent!
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-3">
<span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
      style="max-width: 200px; max-height: 30px"
      [class.badge-warning]="true"
>Performance</span>
        </div>
        <div class="col">
          Ein gelber Performance Zustand bedeutet, die Kostenstelle unterschreitet Ihr SOLL zum aktuellen Zeitpunkt um mindestens <b>5</b> Prozent!
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-3">
          <div class="row">
            <div class="col-12">
              <span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
                    style="max-width: 200px; max-height: 30px"
                    [class.badge-secondary]="true"
              >Performance</span>
            </div>
            <div class="col-12">
            <span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
                  style="max-width: 200px; max-height: 30px"
                  [class.badge-secondary]="true"
            >Performance
      <i class="fas fa-exclamation-triangle" style="color: yellow"></i></span></div>
          </div>
        </div>
        <div class="col">
          Ein grauer Performance Zustand bedeutet, die Kostenstelle hat keine aktive Fertigung. Hat der Indikator zusätzlich ein gelbes Warndreieck, so fertig die Kostentelle,
          <b>allerdings wurden keine SOLL Zeiten gemeldet</b>.
        </div>
      </div>
      <br><br>
      Man kann über dem Performance Indikator einer Kostelle mit der Maus stehen bleiben, um einen Tooltip mit den aktuellen Statistiken und der aktiven Schicht zu öffnen.
      <br>

      <br>



    <br>
    </section>
  </small>
</ng-template>

