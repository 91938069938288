<div class="row pb-3 pt-3 pl-5">
    <div class="col">
        <h4>{{ costCenter }}</h4>
        <button (click)="refresh()" class="btn btn-sm btn-primary"></button>

      <div *ngIf="updatePending" class="spinner-border small" role="status">
        <span class="sr-only">Loading...</span>
      </div>

    </div>
</div>
<div class="row pb-5 pl-5">
    <ng-container *ngFor="let tracingObject of engineTracingObjects; let i = index">
        <ng-container *ngIf="!(tracingObject.timeInvalid && tracingObject.locationType === LocationType.BUFFER)">

            <div class="col border" style="min-width: 350px; max-width: 350px; min-height: 180px; margin-top: 10px"
            >


                <!--<div style="position: absolute; top: 0; left: 0; bottom: 10px; min-width: 20px; max-width: 20px;"
                     [class.isReadyToShip]="tracingObject.engine?.engineState === 'READY_TO_SHIP'"
                     [class.isInProduction]="tracingObject.engine?.engineState === 'IN_PRODUCTION'"
                     [class.isSuspiciousState]="tracingObject.engine?.engineState !== 'IN_PRODUCTION' && tracingObject.engine?.engineState !== 'READY_TO_SHIP'">
                </div>-->

                <div class="text-center" style="padding-bottom: 25px; "
                     [tooltip]="engineTracingObjectToolTip"

                     [class.bufferBox]="tracingObject.locationType === LocationType.BUFFER"
                     [class.workSequenceBox]="tracingObject.locationType === LocationType.WORKSEQUENCE"

                     [class.isMissingEngine]="tracingObject.state === EngineTracingState.MISSING"
                     [class.isStackedEngine]="tracingObject.state === EngineTracingState.STACKED"
                     [class.isOnlineEngine]="tracingObject.state === EngineTracingState.ONLINE"
                     [class.isScannedEngine]="tracingObject.state === EngineTracingState.SCANNED">


                    <op-datetime-show [dateTime]="tracingObject.time" [noLocalTimeConversion]="true"></op-datetime-show>
                    <i *ngIf="tracingObject.timeInvalid && !tracingObject.preservedItem" class="fas fa-exclamation" style="color: darkred"></i>
                    <i *ngIf="tracingObject.preservedItem" class="fas fa-check pr-1" style="color: darkblue"></i>
                    <br>
                    <br>
                    <div *ngIf="tracingObject.state !== EngineTracingState.ERROR">

                        <ng-container *ngIf="tracingObject.markedForDeletion && !tracingObject.timeInvalid">
                            <h1 style="color: #343a40">LEER</h1>
                        </ng-container>

                        <ng-container *ngIf="tracingObject.markedForDeletion && tracingObject.timeInvalid">
                            <h1 style="color: darkorange">OFFLINE</h1>
                        </ng-container>

                        <ng-container *ngIf="!tracingObject.markedForDeletion">
                            <h2 style="color: #4B4453;font-weight: bold" [class.keyCodeChanged]="getKeyCodeColoring(i)">{{ tracingObject.keyCode }}</h2>



                            <span *ngIf="tracingObject.name" class="badge d-inline" style="font-size: 1rem" [ngClass]="getBadgeClass(tracingObject.engine)">

   {{ tracingObject.name }}

</span>





                        </ng-container>
                    </div>



                    <div *ngIf="tracingObject.state === EngineTracingState.ERROR">
                        <h2 style="color:darkred;">FEHLER</h2>
                        <h3>{{ tracingObject.name }}</h3>
                    </div>
                </div>

                <!--
               <div class="pb-1 pr-1 text-center"
                    style="position: absolute; left:0; right: 0; bottom: 25px; min-height: 25px"
               >
                  <div *ngIf="tracingObject.currentLocation">
                        <small style="color: white; font-weight: bolder;">{{ tracingObject.currentLocation }}</small>
                    </div>
                    <div *ngIf="tracingObject.lastLocation">
                        <small style="color: darkblue;">{{ tracingObject.lastLocation }}</small>
                    </div>
                </div>
    -->

              <div style="position:absolute; right: 10px; top: 50px; bottom: 50px; width: 80px;">
                <div class="rightInfo">
                  <ng-container *ngIf="tracingObject.isEndOfline">
                    <p>
                      <i class="fas fa-print fa-2x" style="color: darkgreen"></i>
                    </p>
                  </ng-container>
                  <ng-container *ngIf="tracingObject.stack">
                    <p>
                      <i class="fas fa-dolly fa-2x" style="color: #B8DAFF"></i>
                    </p>
                  </ng-container>
                </div>
              </div>

              <div style="position:absolute; left: 10px; top: 10px; width: 30px; height: 30px;">
              <ng-container *ngIf="tracingObject.keyCodeChanged">
                <i class="fas fa-exclamation-triangle fa-2x" style="color: darkgoldenrod"></i>
              </ng-container>
              </div>


              <div style="position:absolute; left: 10px; top: 50px; bottom: 50px; width: 80px;">
                <div class="leftInfo">
                  <ng-container *ngIf="tracingObject.hasManufacturingProblem">
                        <i class="fas fa-exclamation fa-2x" style="color: darkred; break-after: right"></i>
                  </ng-container>
                </div>
              </div>

                <div class="pb-1 pr-1 text-center" style="position: absolute; left:0; right: 0; bottom: 25px; min-height: 25px">
                    <i *ngIf="tracingObject.engine?.hasKeyCodeHistory" class="cpp-icon-keycodehistory d-inline"></i>
                    <i *ngIf="tracingObject.engine?.hasColdTests" class="cpp-icon-coldtest d-inline"></i>
                    <i *ngIf="tracingObject.engine?.hasHotTests" class="cpp-icon-hottest d-inline"></i>
                    <i *ngIf="tracingObject.engine?.hasReworks" class="cpp-icon-reworks d-inline"></i>
                    <i *ngIf="tracingObject.engine?.hasBlockingActions" class="cpp-icon-blockings d-inline"></i>
                    <i *ngIf="!tracingObject.markedForDeletion && tracingObject.state !== EngineTracingState.ERROR && tracingObject.engine?.engineBlockedState !== 'NONE'" class="cpp-icon-lock d-inline" style="color: darkred;"></i>
                </div>


                <div class="pb-1 pr-1 text-center" style="position: absolute; left:0; right: 0; bottom: 0; min-height: 15px"
                     [class.isInBuffer]="tracingObject.locationType === LocationType.BUFFER"
                     [class.isInWorkSequence]="tracingObject.locationType === LocationType.WORKSEQUENCE"
                     [class.isInRemoval]="tracingObject.locationType === LocationType.REMOVAL"
                     [class.isEndOfLine]="tracingObject.isEndOfline"
                     [class.isEOnLifter]="tracingObject.locationType === LocationType.LIFTER"
                >
                    <div *ngIf="tracingObject.currentLocation">
                        <small style="color: white; font-weight: bolder;">{{ tracingObject.currentLocation }}</small>
                    </div>
                </div>
            </div>

        </ng-container>


        <ng-template #engineTracingObjectToolTip>
            <div>
                <op-datetime-show [dateTime]="tracingObject.time" [noLocalTimeConversion]="true"></op-datetime-show>
                <br>
                Schlüsselnummer:  {{ tracingObject.keyCode }}
                <br>
                Name: {{ tracingObject.name }}
                <br>
                Motorzustand: {{ tracingObject.engine?.engineState }}
                <br>
                Nacharbeitszustand: {{ tracingObject.engine?.engineReworkState }}
                <br>
                Testzustand: {{ tracingObject.engine?.engineTestState }}
                <br>
                Auftragsnummer: {{ tracingObject.engine?.orderNumber }} | {{ tracingObject.orderNumber }}
                <br>
                Nacharbeiten: {{ tracingObject.engine?.hasReworks }}
                <br>
                Hottest: {{ tracingObject.engine?.hasHotTests }}
                <br>
                Kalttest: {{ tracingObject.engine?.hasColdTests }}
                <br>
                Sperre: {{ tracingObject.engine?.hasBlockingActions }}
                <br>
                Umgeschlüsselt: {{ tracingObject.engine?.hasKeyCodeHistory }}
                <br>
                MBK: {{ tracingObject.stack?.stackIdentifier }}
                <br>
                FehlerzählerTracing: {{ tracingObject.errorCounter }}
                <br>
                Location in Linie: {{ tracingObject.currentLocation ? tracingObject.currentLocation : tracingObject.lastLocation }}
                <br>
                Loccationzustand: <b>{{ tracingObject.locationType }}</b>
            </div>
        </ng-template>
    </ng-container>
</div>
