import {EventEmitter, Injectable} from '@angular/core';
import {Claim} from './roles.service';
import {Claims} from './claims-provider.service';
import {KeyValue} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseRoleProviderService {
  public static claimMissing: boolean;
  public static lastClaimChecked: EffectiveUserClaim;
  public static lastClaimNameRequested: string;

  public static permissionsLoaded: boolean;
  public static isApplication: boolean;

  public static onPermissionsLoaded = new EventEmitter();

  public static get hasEffectiveRoles(): boolean {
    if (EnterpriseRoleProviderService.effectiveEnterpriseRoles && EnterpriseRoleProviderService.effectiveEnterpriseRoles.length > 0) {
      return true;
    }

    return false;
  }

  public static set tokenGroups(groups: Array<string>) {
    EnterpriseRoleProviderService._tokenGroups = groups;
    EnterpriseRoleProviderService.onTokenGroupsLoaded.emit();
  }
  public static get tokenGroups(): Array<string> {
    return this._tokenGroups;
  }

  public static set claims(claims: Array<Claim>) {
    EnterpriseRoleProviderService._claims = claims;

    const effectiveUserClaims = new Array<EffectiveUserClaim>();
    EnterpriseRoleProviderService.tokenGroups.find(role => {
      EnterpriseRoleProviderService._claims.forEach(claim => {

        const foundClaimForRole =
          claim.roles.find(c => c.key === role);

        if (foundClaimForRole) {
          const existingEffectiveUserClaim =
            effectiveUserClaims.find(existing => existing.functionalResource === claim.name);

          if (existingEffectiveUserClaim && !existingEffectiveUserClaim.writePermission && foundClaimForRole.value === 2) {
            existingEffectiveUserClaim.writePermission = true;
          }

          if (!existingEffectiveUserClaim) {
            effectiveUserClaims.push(new EffectiveUserClaim(claim.name, foundClaimForRole.value === 2));
          }
        }
      });
    });

    EnterpriseRoleProviderService.effectiveUserClaims = effectiveUserClaims;

    EnterpriseRoleProviderService.rolesLoaded = true;
    EnterpriseRoleProviderService.permissionsLoaded = true;

    EnterpriseRoleProviderService.onPermissionsLoaded.emit();
  }
  public static rolesLoaded: boolean;
  public static effectiveUserClaims = new Array<EffectiveUserClaim>();
  public static effectiveEnterpriseRoles = new Array<string>();

  public static onTokenGroupsLoaded = new EventEmitter();


  private static _tokenGroups = new Array<string>();
  private static _claims = new Array<Claim>();

  public static hasClaim(claim: string): boolean {
    if (EnterpriseRoleProviderService.isApplication)  {
      return true;
    }

    const i =
      this.effectiveUserClaims.findIndex(effectiveClaim => effectiveClaim.functionalResource === claim);

    if (i === -1) {
      return false;
    }

    return true;
  }

  public static hasRequiredClaim(requiredClaim: EffectiveUserClaim): boolean {
    if (EnterpriseRoleProviderService.isApplication)  {
      return true;
    }

    let existingIndex: number;

    if (requiredClaim.writePermission) {
      if (requiredClaim.functionalResource.endsWith('-')) {
        existingIndex =   this.effectiveUserClaims.findIndex(effectiveClaim =>
          effectiveClaim.functionalResource.startsWith(requiredClaim.functionalResource)
          && effectiveClaim.writePermission === requiredClaim.writePermission);
      } else {
        existingIndex =   this.effectiveUserClaims.findIndex(effectiveClaim =>
          effectiveClaim.functionalResource === requiredClaim.functionalResource
          && effectiveClaim.writePermission === requiredClaim.writePermission);
      }
    } else {
      /**
       * If the required writePermission is false, we ignore permissions on search.
       * This means, if only a read permission is required, an effective write permission is although sufficient.
       */

      if (requiredClaim.functionalResource.endsWith('-')) {

        existingIndex =   this.effectiveUserClaims.findIndex(effectiveClaim =>
          effectiveClaim.functionalResource.startsWith(requiredClaim.functionalResource));

      } else {
        existingIndex = this.effectiveUserClaims.findIndex(effectiveClaim =>
          effectiveClaim.functionalResource === requiredClaim.functionalResource);
      }
    }

    if (existingIndex === -1) {
      return false;
    }

    return true;
  }

  public static hasRequiredClaimByFunctionResource(functionalResource: string | string[]): boolean {
    let result: boolean;

    let functionalResourceNames: Array<string>;
    let effectiveUserClaim: EffectiveUserClaim;
    if (typeof functionalResource === 'string')  {
      effectiveUserClaim = new EffectiveUserClaim(functionalResource);
    }
    else {

      if (functionalResource instanceof Array<string>) {
        functionalResourceNames = functionalResource as Array<string>;
      }
    }

    if (effectiveUserClaim) {
      result = EnterpriseRoleProviderService.hasRequiredClaim(effectiveUserClaim)
    }

    /**
     * This could be used to check for existence of one of multiple possible function resources
     */
    if (functionalResourceNames) {
      functionalResourceNames.forEach(fr => {
        effectiveUserClaim = new EffectiveUserClaim(fr);

        if (EnterpriseRoleProviderService.hasRequiredClaim(effectiveUserClaim)) {
          result = true;
        }
      });
    }


    return result;
  }

  public static hasRequiredClaimByName(name: string, ignoreClaimMissing: boolean = true): boolean {
    if (EnterpriseRoleProviderService.isApplication)  {
      return true;
    }

    if (!EnterpriseRoleProviderService.permissionsLoaded) {
      return false;
    }

    let skipGetRequiredClaim: boolean;
    if (name.endsWith('-')) {
      skipGetRequiredClaim = true;
    }

    let result = false;

    if (!ignoreClaimMissing) {
      EnterpriseRoleProviderService.claimMissing = false;
      EnterpriseRoleProviderService.lastClaimNameRequested = name;
    }

    let requiredClaim: KeyValue<string, EffectiveUserClaim>;

    if (!skipGetRequiredClaim) {
      requiredClaim = Claims.getRequiredClaim(name);
    } else {
      requiredClaim = { key: name, value: new EffectiveUserClaim(name)};
    }

    if (requiredClaim) {
      result = EnterpriseRoleProviderService.hasRequiredClaim(requiredClaim.value);
    }


    if (!result && !ignoreClaimMissing) {
      EnterpriseRoleProviderService.claimMissing = true;
      EnterpriseRoleProviderService.lastClaimChecked = requiredClaim?.value;
    }

    return result;
  }

  public static hasRequiredRole(requiredRole: string): boolean {
    if (EnterpriseRoleProviderService.isApplication)  {
      return true;
    }

    let existingIndex: number;
    if (requiredRole.endsWith('*')) {
      existingIndex =
        EnterpriseRoleProviderService.effectiveEnterpriseRoles.findIndex(existingRole => existingRole.startsWith(requiredRole.replace('*', '')));
    } else {
      existingIndex =
        EnterpriseRoleProviderService.effectiveEnterpriseRoles.findIndex(existingRole => existingRole === requiredRole)
    }

    if (existingIndex !== -1) {
      return true;
    }

    return false;
  }
}

export class EffectiveUserClaim {
  functionalResource: string;
  writePermission: boolean;

  constructor(functionalResource: string, writePermission?: boolean) {
    this.functionalResource = functionalResource;
    if (writePermission) {
      this.writePermission = writePermission;
    } else {
      this.writePermission = false;
    }
  }
}
