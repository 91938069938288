import {Component, EventEmitter, OnInit} from '@angular/core';
import {KioskModeService} from '../../../../core/services/kiosk-mode.service';
import {Info} from '../info';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {CostCenterService} from '../../../metadata/services/cost-center.service';
import {CostCenterFilter} from '../../../metadata/models/costCenterFilter';
import {InfosService} from '../infos.service';
import {
  BigScreenAppearance,
  BigScreenArea,
  UserInformationResponse,
  UserInformationType
} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {ConfigService} from '../../../../core/services/config.service';
import {FileHandlingService} from '../../../../shared/services/backend/file-handling.service';
import {HttpEventType} from '@angular/common/http';
import {UploadConfiguration} from '../../../../shared/components/upload-v2/uploadConfiguration';
import {InfoFile} from '../infoFile';
import {LoggingService} from '../../../../core/logging/logging.service';
import {LoggingSource} from '../../../../core/logging/loggingSource';
import {UploadV2Service} from '../../../../shared/components/upload-v2/upload-v2.service';
import {EnterpriseRoleProviderService} from '../../../../core/services/enterprise-role-provider.service';

@Component({
  selector: 'op-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  constructor(private kioskModeService: KioskModeService,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private costCenterService: CostCenterService,
              private infoService: InfosService,
              private router: Router,
              private configService: ConfigService,
              private fileHandlingService: FileHandlingService,
              public uploadV2Service: UploadV2Service) {

    this.kioskModeService.kioskMode$.subscribe(mode => {
      this.kioskMode = mode;
    });

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

  }

  public kioskMode: boolean;
  public createMode: boolean;
  public info: Info = new Info();
  public id: string;
  public form: UntypedFormGroup;
  public loading: boolean;
  public storing: boolean;
  public bsConfig: Partial<BsDatepickerConfig>;
  public showGlobal: boolean;
  public markedWorkSequenceEntries: Set<string> = new Set<string>();
  public workSequenceList = new Array<string>();
  public selectedEntries = new Array<string>();
  public markedSelectedEntries: Set<string> = new Set<string>();
  public availableEntries = new Array<string>();
  public markedAvailableEntries: Set<string> = new Set<string>();
  public bigScreenAreaOptions = [
    {label: 'None', value: BigScreenArea.None},
    {label: 'Marquee', value: BigScreenArea.Marquee},
    // {label: 'LEFT', value: BigScreenArea.Left},
    {label: 'Middle', value: BigScreenArea.Middle},
    {label: 'Right', value: BigScreenArea.Right}
  ];
  public typeOptions = [
    {label: 'NEWS', value: UserInformationType.News},
    {label: 'STOPNOTIFICATION', value: UserInformationType.Stopnotification},
    // {label: 'ALERT', value: UserInformationType.Alert},
    {label: 'EMPLOYEEINFO', value: UserInformationType.Employeeinfo}

  ];
  public bigScreenAreaSelectedOption: BigScreenArea = BigScreenArea.None;
  public workSequence: string;
  public typeSelectedOption: UserInformationType = UserInformationType.News;
  public currentStage: string;
  public uploadV2Configuration: UploadConfiguration = new UploadConfiguration();
  public reloadFileExplorerTrigger = new EventEmitter<any>();
  protected readonly UserInformationType = UserInformationType;
  private costCenters: string[];

  protected readonly EnterpriseRoleProviderService = EnterpriseRoleProviderService;


  // TODO: Formular change detection -> Button Save / Close
  // TODO: Info Type not loaded correctly
  // TODO: Attachments should be uploaded after info creation
  // TODO: Stopnotifcation -> find "first" image by extension to use in pdf

  public async ngOnInit() {

    this.initForm();
    this.subscribeToParamMap();
    this.configureDatepicker();
    this.getCostCenter();
    this.initUploadV2Configuration();

    this.currentStage = this.configService.getStage();
  }

  public async onSubmit() {
    this.storing = true;
    this.mapFromForm();

    const newInfo = await this.infoService.createOrUpdateInfo(this.info);

    if (newInfo.id !== this.id && this.uploadV2Service.files.length > 0) {
        this.uploadV2Service.upload(newInfo.id);

        this.uploadV2Service.onFilesUploaded.subscribe(async () => {
          await this.submitFinished(newInfo);
        });

        return;
    }

      await this.submitFinished(newInfo);
  }

  private async submitFinished(newInfo: UserInformationResponse) {
    this.storing = false;

    if (this.info.id) {
      this.router.onSameUrlNavigation = 'reload';
      await this.router.navigateByUrl('home/info/list');
    } else {
      await this.router.navigateByUrl('home/info/list/' + newInfo.id);
    }
  }

  public async onCancel() {
    this.router.onSameUrlNavigation = 'reload';
    await this.router.navigateByUrl('home/info/list');
  }

  public async onClose() {
    this.router.onSameUrlNavigation = 'reload';
    await this.router.navigateByUrl('home/info/list');
  }

  public showGlobalChanged(value: boolean) {
    this.info.showGlobal = value;
  }

  public markCheck(index: number, event: MouseEvent, marked: Set<string>, target: string[]) {
    if (this.info.showGlobal) {
      return;
    }

    const markedElement: string = target[index];

    if (event.ctrlKey) {
      if (marked.has(markedElement)) {
        marked.delete(markedElement);
      } else {
        marked.add(markedElement);
      }
      return;
    }
    if (marked.has(markedElement)) {
      marked.clear();
    } else {
      marked.clear();
      marked.add(markedElement);
    }
  }

  // TODO: Remove the already selected costCenters from having a "new sign"
  public getNewSelectedEntries(): Set<string> {
    const newSelected: Set<string> = new Set<string>(this.selectedEntries);
    return newSelected;
  }

  public getCostCenter() {
    const filter = new CostCenterFilter();
    filter.sort = ['nr,asc'];

    this.costCenterService.getCostCenters(filter, true).then(r => {
      this.costCenters = r.costCenters.map(c => c.nr);

      this.availableEntries = this.costCenters;
    });
  }

  public selectedBigScreenAreaChanged(area: BigScreenArea) {
    this.info.bigScreenArea = area;
    this.bigScreenAreaSelectedOption = area;
  }

  public selectEntry(entry: string) {
    this.form.markAsDirty();

    this.selectedEntries.unshift(entry);
    this.removeEntry(entry, this.availableEntries);

    this.info.hasChanged = true;
  }

  public getNewUnselectedEntries(): Set<string> {
    return new Set<string>();
  }

  public markedAvailableEntriesSelect() {
    this.form.markAsDirty();
    this.markedAvailableEntries.forEach(entry => {
      this.selectedEntries.unshift(entry);
      this.removeEntry(entry, this.availableEntries);
    });

    this.markedAvailableEntries.clear();
    this.info.hasChanged = true;
  }

  public markedSelectedEntriesRemove() {
    this.form.markAsDirty();

    this.markedSelectedEntries.forEach(entry => {
      this.availableEntries.unshift(entry);
      this.removeEntry(entry, this.selectedEntries);
    });

    this.markedSelectedEntries.clear();
    this.info.hasChanged = true;
  }

  public deselectEntry(entry: string) {
    this.form.markAsDirty();
    this.availableEntries.unshift(entry);
    this.removeEntry(entry, this.selectedEntries);
    this.markedSelectedEntries.clear();
    this.info.hasChanged = true;
  }

  public selectedTypeChanged(type: UserInformationType) {
    this.typeSelectedOption = type;
    this.info.informationType = type;
  }

  public addWorkSequence() {
    this.workSequenceList.push(this.form.value.workSequence);
    this.form.controls.workSequence.setValue('');

    this.info.hasChanged = true;
  }

  public removeWorkSequence(entry: string) {
    this.removeEntry(entry, this.workSequenceList);
  }

  public getNewSelectedWorkSequences(): Set<string> {
    return new Set<string>();
  }

  public removeMarkedWorkSequences() {
    this.markedWorkSequenceEntries.forEach(e => {
      this.removeEntry(e, this.workSequenceList);
    });

    this.markedWorkSequenceEntries.clear();
    this.info.hasChanged = true;
  }

  public invalidFileExtension() {
    console.log('Invalid fileExtension');
  }

  public addFiles(files: File[]) {
    this.info.hasChanged = true;
  }

  public saveFiles(files: File[]) {
    const infoFiles = new Array<InfoFile>();
    infoFiles.push(...files.map(file => new InfoFile(file)));

    infoFiles.forEach(infoFile => {
      const s3Key = this.id + '/' + infoFile.name;

      this.fileHandlingService.uploadUseCaseAsync('infos', infoFile.file, s3Key)
        .subscribe(httpEvent => {
          if (httpEvent.type === HttpEventType.UploadProgress) {

            const intermediate = httpEvent.loaded / httpEvent.total * 100;
            infoFile.uploadProgress = Math.round((intermediate + Number.EPSILON) * 100) / 100

          }

          if (httpEvent.type === HttpEventType.Response) {
            console.log(httpEvent);
          }

        });
    });
  }

  public fileUploaded() {
    LoggingService.logDebug(LoggingSource.CORE,
      'A file was uploaded. The fileExplorer component will now be informed to trigger an update of the file-list.');

    this.reloadFileExplorerTrigger.emit();
  }

  private configureDatepicker() {
    this.bsConfig = Object.assign({}, {containerClass: 'theme-default'});
    this.bsConfig.dateInputFormat = 'DD.MM.YYYY';
    this.bsConfig.showWeekNumbers = true;

  }

  private initForm() {
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      description: [''],
      activeFrom: ['', Validators.required],
      activeUntil: ['', Validators.required],
      showGlobal: [false],
      isPublished: [false],
      workSequence: [''],
    });
  }

  private removeEntry(entry: string, entries: Array<string>) {
    const index = entries.findIndex(item => item === entry);
    if (index > -1) {
      entries.splice(index, 1);
    }
  }

  private async update() {
    const userInformationResponse = await this.infoService.getInfoById(this.id);
    this.info.mapFromUserInformationResponse(userInformationResponse);

    this.bigScreenAreaSelectedOption = this.bigScreenAreaOptions.find(o => o.value === this.info.bigScreenArea).value;

    if (this.info.informationType !== UserInformationType.Alert && this.info.informationType !== UserInformationType.Supportrequest) {
      this.typeSelectedOption = this.typeOptions.find(o => o.value === this.info.informationType.toUpperCase()).value;
    }

    this.form.setValue(this.mapToForm());
  }

  private subscribeToForm() {
    this.form.get('showGlobal').valueChanges.subscribe((value) => {
      this.showGlobalChanged(value);
    });

    this.form.get('description').valueChanges.subscribe((value) => {
      this.info.description = value;
    });

    this.form.get('title').valueChanges.subscribe((value) => {
      this.info.title = value;
    });
    this.form.get('activeFrom').valueChanges.subscribe((value) => {
      this.info.activeFrom = value;
    });
    this.form.get('activeUntil').valueChanges.subscribe((value) => {
      this.info.activeUntil = value;
    });
    this.form.get('workSequence').valueChanges.subscribe((value) => {
      console.log('workSequence changed');
      // this.info.title = value;
    });
  }

  private mapToForm() {
    const formValue: any = {};
    formValue.title = this.info.title;
    formValue.description = this.info.description;
    formValue.activeFrom = new Date(this.info.activeFrom);
    formValue.activeUntil = new Date(this.info.activeUntil);
    formValue.showGlobal = this.info.showGlobal;
    formValue.isPublished = this.info.isPublished;
    formValue.workSequence = '';

    this.selectedEntries = this.info.costCenters;
    this.workSequenceList = this.info.workSequences;
    this.selectedTypeChanged(this.info.informationType);

    this.selectedEntries.forEach(e => {
      this.removeEntry(e, this.availableEntries);
    });

    return formValue;
  }

  private mapFromForm() {
    this.info.title = this.form.value.title;
    this.info.description = this.form.value.description;
    this.info.activeFrom = this.form.value.activeFrom;
    this.info.activeUntil = this.form.value.activeUntil;
    this.info.showGlobal = this.form.value.showGlobal;
    this.info.isPublished = this.form.value.isPublished;

    this.info.bigScreenArea = this.bigScreenAreaSelectedOption;
    this.info.bigScreenAppearance = BigScreenAppearance.None;
    this.info.informationType = this.typeSelectedOption;

    this.info.costCenters = this.selectedEntries;
    this.info.workSequences = this.workSequenceList;

  }

  private subscribeToParamMap() {
    this.route.paramMap.subscribe(async p => {
      this.id = p.get('id');

      if (this.id === 'new') {
        this.info = new Info();
        this.createMode = true;
      } else {
        // this.createMode = true;
        await this.update();
      }

      this.subscribeToForm();
    });
  }

  private initUploadV2Configuration() {
    this.uploadV2Configuration.useCaseS3Prefix = this.id;
    this.uploadV2Configuration.useCaseName = 'infos'
    this.uploadV2Configuration.noCancelButton = true;

    if (!this.id || this.id === 'new') {
      this.uploadV2Configuration.autoUpload = false;
    } else {
      this.uploadV2Configuration.autoUpload = true;
    }
  }
}
