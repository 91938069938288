import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { SelectBoxComponent } from './components/select-box/select-box.component';
import { ChartComponent } from './components/chart/chart.component';
import { NgChartsModule as ChartsModule } from 'ng2-charts';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SearchComponent } from './components/search/search.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CardComponent } from './components/card/card.component';
import { TreeviewModule } from 'ngx-treeview';
import { QrcodeComponent } from './components/qrcode/qrcode.component';
import { QRCodeModule} from 'angularx-qrcode';
import { ElementFooterComponent } from './components/element-footer/element-footer.component';
import { AssemblyDetailsComponent } from './components/component-profile/assembly-details/assembly-details.component';
import { DmcParserComponent } from './components/dmc-parser/dmc-parser.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RefreshComponent } from './components/refresh/refresh.component';
import { FormHelpTextComponent } from './components/form-help-text/form-help-text.component';
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MarkdownModule } from 'ngx-markdown';
import { WebcamModule } from 'ngx-webcam';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EventTrackingComponent } from './components/event-tracking/event-tracking.component';
import { StackInfoComponent } from './components/stack-info/stack-info.component';
import { SortOrderIndicatorComponent } from './components/sort-order-indicator/sort-order-indicator.component';
import { TranslateHttpPropertiesLoader } from '../core/utils/properties-loader/ngx-translate-properties-loader';
import { CopyToClipboardIconComponent } from './components/copy-to-clipboard-icon/copy-to-clipboard-icon.component';
import { OpMissingTranslationHandler } from '../core/utils/OpMissingTranslationHandler';
import { SlicingComponent } from './components/slicing/slicing.component';
import { TooltipModule} from 'ngx-bootstrap/tooltip';
import { SpinnerSmallComponent } from './components/spinner-small/spinner-small.component';
import { DateRangeInputComponent } from './components/date-range-input/date-range-input.component';
import { BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { ResizeColumnDirective } from './directives/resize-column.directive';
import { ManufacturingProcessComponent } from './components/component-profile/manufacturing-process/manufacturing-process.component';
import { EntityLabelComponent } from './components/entity-label/entity-label.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// tslint:disable-next-line:max-line-length
import { EventTrackingAdditionalPropertiesComponent } from './components/event-tracking-additional-properties/event-tracking-additional-properties.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ComponentStateBadgeComponent } from './components/component-state-badge/component-state-badge.component';
import { CountBadgeComponent } from './components/count-badge/count-badge.component';
import { ExcelExportComponent } from './components/excel-export/excel-export.component';
import { DatetimeShowComponent } from './components/datetime-show/datetime-show.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { GuidedFilterInputComponent } from './components/guided-filter-input/guided-filter-input.component';
import { FileExplorerComponent } from './components/file-explorer/file-explorer.component';
import { TreeviewComponent } from './components/file-explorer/treeview/treeview.component';
import { PreviewComponent } from './components/file-explorer/preview/preview.component';
import { UploadComponent } from './components/file-explorer/upload/upload.component';
import { ResultIndicatorComponent } from './components/result-indicator/result-indicator.component';
import { FileExplorerComponent as UseCaseFileExplorerComponent } from './components-merged/usecase-file-explorer/file-explorer.component';
import { TreeviewComponent as UseCaseTreeviewComponent} from './components-merged/usecase-file-explorer/treeview/treeview.component';
import { PreviewComponent as UseCasePreviewComponent} from './components-merged/usecase-file-explorer/preview/preview.component';
import { UploadComponent as UseCaseUploadComponent} from './components-merged/usecase-file-explorer/upload/upload.component';
import { CpoOnlyDirective } from './directives/cpo-only.directive';
import { LocationInfoComponent } from './components/location-info/location-info.component';
import { TelegramListComponent } from './components/component-profile/telegram-list/telegram-list.component';
import { AuditV2Component } from './components/audit-v2/audit-v2.component';
import { DetailSectionWrapperComponent } from './components/component-profile/detail-section-wrapper/detail-section-wrapper.component';
import { ComponentApprovalV2Component } from './components/component-approval-v2/component-approval-v2.component';
import { MonacoEditorComponent } from './components/monaco-editor/monaco-editor.component';
import { ComponentProfileComponent } from './components/component-profile/component-profile.component';
import { KeycodeHistoryComponent } from './components/component-profile/keycode-history/keycode-history.component';
import { MetadataComponent } from './components/component-profile/metadata/metadata.component';
import { FavoritesComponent } from './components/component-profile/favorites/favorites.component';
import { LogisticsComponent } from './components/component-profile/logistics/logistics.component';
import { TestsComponent } from './components/component-profile/tests/tests.component';
import { EventsComponent } from './components/component-profile/events/events.component';
import { ReworksComponent } from './components/component-profile/reworks/reworks.component';
import { BlockingsComponent } from './components/component-profile/blockings/blockings.component';
import { EnsureListSufficientlyFilledDirective } from './directives/ensure-list-sufficiently-filled.directive';
import { DlqMessagesComponent } from './components/component-profile/dlq-messages/dlq-messages.component';
import { ChristmasDirective } from './directives/christmas.directive';
import { ActionsComponent } from './components/component-profile/actions/actions.component';
import { LabelingComponent } from './components/component-profile/actions/labeling/labeling.component';
import { Scanner2Component } from './components/scanner2/scanner2.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { MeasurementsComponent } from './components/component-profile/measurements/measurements.component';
import { KanbanColumnDirective, KanbanComponent } from './components/kanban/kanban.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BetaFlagComponent } from './components/beta-flag/beta-flag.component';
import { AlphaFlagComponent } from './components/alpha-flag/alpha-flag.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { BeforeStackingEngineOrderTracingComponent } from './components/before-stacking-engine-order-tracing/before-stacking-engine-order-tracing.component';
import { MessageComponent } from './components/message/message.component';
import { DeleteEntryWithConfirmationComponent } from './components/delete-entry-with-confirmation/delete-entry-with-confirmation.component';
import { EditEntryWithConfirmationComponent } from './components/edit-entry-with-confirmation/edit-entry-with-confirmation.component';
import {
    LogisticalStateTooltipComponent
} from './components/logistical-state-tooltip/logistical-state-tooltip.component';
import { UploadV2Component } from './components/upload-v2/upload-v2.component';
import { FormValueLoadingAnimationComponent } from './components/form-value-loading-animation/form-value-loading-animation.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { UploadV2ListComponent } from './components/upload-v2/upload-v2-list/upload-v2-list.component';
import { ComponentStateCheckComponent } from './components/component-state-check/component-state-check.component';
import { StackStateCheckComponent } from './components/stack-state-check/stack-state-check.component';
import { RequiredClaimByFunctionalResourceDirective } from './directives/required-claim-by-functional-resource.directive';
import { RequiredRoleDirective } from './directives/required-role.directive';
import { PermissionsLoadRequired } from './directives/permissions-load-required.directive';
import { PermissionsGateComponent } from './components/permissions-gate/permissions-gate.component';
import { RequiredClaimByNameDirective } from './directives/required-claim-by-name.directive';
import { SendMessageComponent } from './components/send-message/send-message.component';
import { CheckBackendStatusComponent } from './components/check-backend-status/check-backend-status.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { OffsetsComponent } from './components/component-profile/offsets/offsets.component';
import { ComponentReleaseWizardComponent } from './components/component-release-wizard/component-release-wizard.component';
import { ComponentBlockWizardComponent } from './components/component-block-wizard/component-block-wizard.component';
import { DispatcherTaskFormComponent } from './components/dispatcher-task-form/dispatcher-task-form.component';
import { WebsocketConnectionsTesterComponent } from './components/websocket-connections-tester/websocket-connections-tester.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpPropertiesLoader(http, './assets/i18n/messages_', '.properties');
}

@NgModule({
    declarations: [
        SelectBoxComponent,
        ChartComponent,
        SpinnerComponent,
        SearchComponent,
        SlicingComponent,
        CardComponent,
        QrcodeComponent,
        ElementFooterComponent,
        AssemblyDetailsComponent,
        DmcParserComponent,
        RefreshComponent,
        FormHelpTextComponent,
        FilePreviewComponent,
        EventTrackingComponent,
        StackInfoComponent,
        SortOrderIndicatorComponent,
        CopyToClipboardIconComponent,
        SpinnerSmallComponent,
        DateRangeInputComponent,
        ResizeColumnDirective,
        ManufacturingProcessComponent,
        EntityLabelComponent,
        EventTrackingAdditionalPropertiesComponent,
        BreadcrumbComponent,
        ComponentStateBadgeComponent,
        CountBadgeComponent,
        ExcelExportComponent,
        DatetimeShowComponent,
        LoadingSpinnerComponent,
        GuidedFilterInputComponent,
        FileExplorerComponent,
        TreeviewComponent,
        PreviewComponent,
        UploadComponent,
        UseCaseFileExplorerComponent,
        UseCaseTreeviewComponent,
        UseCasePreviewComponent,
        UseCaseUploadComponent,
        ResultIndicatorComponent,
        CpoOnlyDirective,
        LocationInfoComponent,
        TelegramListComponent,
        AuditV2Component,
        DetailSectionWrapperComponent,
        ComponentApprovalV2Component,
        MonacoEditorComponent,
        ComponentProfileComponent,
        KeycodeHistoryComponent,
        MetadataComponent,
        FavoritesComponent,
        LogisticsComponent,
        TestsComponent,
        EventsComponent,
        ReworksComponent,
        BlockingsComponent,
        EnsureListSufficientlyFilledDirective,
        DlqMessagesComponent,
        ChristmasDirective,
        ActionsComponent,
        LabelingComponent,
        Scanner2Component,
        ScrollToTopComponent,
        MeasurementsComponent,
        KanbanColumnDirective,
        KanbanComponent,
        DropdownComponent,
        BetaFlagComponent,
        AlphaFlagComponent,
        ProgressBarComponent,
        BeforeStackingEngineOrderTracingComponent,
        MessageComponent,
        DeleteEntryWithConfirmationComponent,
        EditEntryWithConfirmationComponent,
        LogisticalStateTooltipComponent,
        UploadV2Component,
        FormValueLoadingAnimationComponent,
        VideoPlayerComponent,
        UploadV2ListComponent,
        ComponentStateCheckComponent,
        StackStateCheckComponent,
        RequiredClaimByFunctionalResourceDirective,
        RequiredRoleDirective,
        PermissionsLoadRequired,
        PermissionsGateComponent,
        RequiredClaimByNameDirective,
        SendMessageComponent,
        CheckBackendStatusComponent,
        OffsetsComponent,
        ComponentReleaseWizardComponent,
        ComponentBlockWizardComponent,
        DispatcherTaskFormComponent,
        WebsocketConnectionsTesterComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        QRCodeModule,
        TranslateModule.forChild({
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: OpMissingTranslationHandler
            },
            useDefaultLang: false,
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        ChartsModule,
        TreeviewModule.forRoot(),
        CollapseModule,
        NgxExtendedPdfViewerModule,
        WebcamModule,
        ModalModule.forRoot(),
        // MglTimelineModule,
        TooltipModule,
        BsDatepickerModule,
        BsDropdownModule,
        MarkdownModule.forRoot(),
        DragDropModule,
        PdfViewerModule,
        ReactiveFormsModule
    ],
  exports: [
    TranslateModule,
    SelectBoxComponent,
    ChartComponent,
    ChartsModule,
    SpinnerComponent,
    SearchComponent,
    SlicingComponent,
    CardComponent,
    QrcodeComponent,
    ElementFooterComponent,
    AssemblyDetailsComponent,
    DmcParserComponent,
    RefreshComponent,
    FormHelpTextComponent,
    NgxExtendedPdfViewerModule,
    EventTrackingComponent,
    StackInfoComponent,
    SortOrderIndicatorComponent,
    CopyToClipboardIconComponent,
    SpinnerSmallComponent,
    DateRangeInputComponent,
    ResizeColumnDirective,
    EntityLabelComponent,
    ManufacturingProcessComponent,
    BreadcrumbComponent,
    ComponentStateBadgeComponent,
    CountBadgeComponent,
    ExcelExportComponent,
    DatetimeShowComponent,
    LoadingSpinnerComponent,
    FilePreviewComponent,
    FileExplorerComponent,
    GuidedFilterInputComponent,
    ResultIndicatorComponent,
    UseCaseFileExplorerComponent,
    UseCaseTreeviewComponent,
    UseCasePreviewComponent,
    UseCaseUploadComponent,
    CpoOnlyDirective,
    LocationInfoComponent,
    TelegramListComponent,
    AuditV2Component,
    ComponentApprovalV2Component,
    MonacoEditorComponent,
    ComponentProfileComponent,
    EnsureListSufficientlyFilledDirective,
    MarkdownModule,
    ChristmasDirective,
    DetailSectionWrapperComponent,
    ScrollToTopComponent,
    BlockingsComponent,
    KanbanColumnDirective,
    KanbanComponent,
    DropdownComponent,
    FavoritesComponent,
    BetaFlagComponent,
    AlphaFlagComponent,
    ProgressBarComponent,
    BeforeStackingEngineOrderTracingComponent,
    MessageComponent,
    DeleteEntryWithConfirmationComponent,
    EditEntryWithConfirmationComponent,
    LogisticalStateTooltipComponent,
    UploadComponent,
    UploadV2Component,
    FormValueLoadingAnimationComponent,
    VideoPlayerComponent,
    UploadV2ListComponent,
    ComponentStateCheckComponent,
    StackStateCheckComponent,
    RequiredClaimByFunctionalResourceDirective,
    RequiredRoleDirective,
    PermissionsLoadRequired,
    PermissionsGateComponent,
    RequiredClaimByNameDirective,
    CheckBackendStatusComponent,
    ComponentReleaseWizardComponent,
    ComponentBlockWizardComponent,
    DispatcherTaskFormComponent,
    WebsocketConnectionsTesterComponent
  ]
})
export class SharedModule {
}
