import {AuditInformation} from '../../../shared/models/auditInformation';
import {DlqJob} from './dlqJob';

export class DlqDeleteJob extends DlqJob {
  public cleanupJobStatus: string;
  public numberOfDeletedMessages: number;

  public get jobStatus(): string {
    return this.cleanupJobStatus.toString();
  }

  public get numberOfMessagesDone(): number {
    return this.numberOfDeletedMessages;
  }
}
