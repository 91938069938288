import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserConfigurationService} from '../../../shared/services/backend/user-configuration.service';
import {AuthService} from '../../../core/services/auth.service';
import {ErrorHandler} from '../../../shared/services/error-handler/error-handler';
import {ApplicationManagementService} from '../../../shared/services/backend/application-management.service';
import {PerformanceMetric} from '@cstx/volkswagen-mqs-application-management-service-client';
import {ProcessError} from '@cstx/volkswagen-mqs-application-management-service-client/model/processError';

@Component({
  selector: 'op-landing-zone',
  templateUrl: './landing-zone.component.html',
  styleUrls: ['./landing-zone.component.scss']
})
export class LandingZoneComponent implements OnInit {

  constructor(private userConfigurationService: UserConfigurationService,
              private applicationManagementService: ApplicationManagementService,
              private authService: AuthService) {}

  public landingZoneError = LandingZoneError.NONE;
  public client: string;
  public targetRoute: string;

  protected readonly LandingZoneError = LandingZoneError;


  public async ngOnInit(): Promise<void> {
    if (this.authService.isApplicationUser) {

      this.client = this.authService.currentUser.username;
      const configuration = await this.userConfigurationService.loadClientConfiguration('landingZone', this.client);

      if (!configuration) {
        this.landingZoneError = LandingZoneError.NO_TARGET_ROUTE_CONFIGURATION;
        await this.sendErrorToApplicationManagementService('No user configuration received from userConfiguration Service.');
        return;
      }

      try {
        const targetRouteConfiguration: TargetRouteConfiguration = JSON.parse(configuration.configuration);

        if (targetRouteConfiguration.targetRoute) {
          this.targetRoute = targetRouteConfiguration.targetRoute;

          console.log(this.targetRoute);
          window.location.replace( this.targetRoute );
        }
      } catch (error) {
        ErrorHandler.printError(error);
        this.landingZoneError = LandingZoneError.NO_TARGET_ROUTE_CONFIGURATION;
        await this.sendErrorToApplicationManagementService(error);
        return;
      }
    } else {
      this.landingZoneError = LandingZoneError.NO_APPLICATION;
      return;
    }
  }

  private async sendErrorToApplicationManagementService(errorMessage: string) {
    const metrics = new Array<PerformanceMetric>();
    const errors = new Array<ProcessError>();

    errors.push({
      count: 1,
      errorType: 'LandingZoneError',
      lastErrorMessage: errorMessage,
      lastOccurrence: new Date().toISOString()
    });


    await this.applicationManagementService.postStatus(metrics, errors);
  }
}

export class TargetRouteConfiguration {
  targetRoute: string;
}

export enum LandingZoneError {
  NONE = 'NONE',
  NO_APPLICATION = 'NO_APPLICATION',
  NO_TARGET_ROUTE_CONFIGURATION = 'NO_TARGET_ROUTE_CONFIGURATION'
}
