import {Injectable} from '@angular/core';
import {ComponentInfoResponse, ComponentType, ParseXmlControllerV2Service} from "@cstx/volkswagen-mqs-parsing-service-client";
import {firstValueFrom} from "rxjs";
import {LoggingService} from "../../../core/logging/logging.service";
import {LoggingSource} from "../../../core/logging/loggingSource";

@Injectable({
  providedIn: 'root'
})
export class ParsingService {

  constructor(private parseXmlControllerV2Service: ParseXmlControllerV2Service) { }

  /**
   * Returns the results of parsing from all xml files found to a given component id.
   * @param componentId The id of the component we want to parse xml for.
   * @param componentType The type of the component.
   */
  public async parseXmlV2(componentId: string, componentType: ComponentType): Promise<ComponentInfoResponse[]> {
    try {
      return await firstValueFrom(this.parseXmlControllerV2Service.apiV2ParseXmlGet({
        componentId,
        componentType
      }));

    } catch (error) {
      LoggingService.logError(LoggingSource.PARSING_SERVICE,
        `Error occurred when parsing xml files of component with id [${componentId}].`, error)
    }

    return null;
  }
}
