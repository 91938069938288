import {Injectable} from '@angular/core';
import {ComponentTypeEnum, FileControllerService, FileTypeEnum} from '@cstx/volkswagen-mqs-file-handling-service-client';
import {ComponentType, ParseService} from '@cstx/volkswagen-mqs-parsing-service-client';
import {FileType} from "../file-explorer/fileType";

@Injectable()
export class FilePreviewService {

  constructor(private fileControllerService: FileControllerService, private parseControllerService: ParseService) { }

  public GetFile(id: string, fileName: string, componentType: ComponentTypeEnum,  fileType: FileTypeEnum): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      this.fileControllerService.getFile({
        componentType,
        id,
        fileName,
        fileType
      })
      .toPromise()
        .then(response => {
            resolve(response);
        })
        .catch(reason => {
          reject(reason);
          });
    });
  }

  public GetFileMetadata(id: string, fileName: string, componentType: ComponentTypeEnum,  fileType: FileTypeEnum) {
    return new Promise<any>((resolve, reject) => {
      this.fileControllerService.getMetadata({
        componentType,
        id,
        fileName,
        fileType
      }).toPromise()
        .then(response => {
          resolve(response);
      })
        .catch(reason => {
          reject(reason);
        });
    });
}
  public triggerAsyncParse(id: string, fileName: string, componentType: ComponentType, costCenter: string) {
    this.parseControllerService.parseKaitaiPublishPost({
      id,
      fileName,
      componentType,
      costCenter,
    }).toPromise();
  }

  // tslint:disable-next-line:max-line-length
  public TryParse(id: string, fileName: string, componentType: ComponentType, costCenter: string, parserType: string = 'kaitai'): Promise<string> {

    if (parserType === 'kaitai') {
      return new Promise<string>((resolve, reject) => {
        this.parseControllerService.parseKaitaiPost({
          id,
          fileName,
          componentType,
          costCenter,
        }).toPromise()
          .then(response => {
            resolve(response);
          })
          .catch(reason => {
            reject(reason);
          });
      });
    } else if (parserType === 'text') {
      return new Promise<string>((resolve, reject) => {
        this.parseControllerService.parseTexttemplatePost({
          id,
          fileName,
          componentType,
          costCenter,
        }).toPromise()
          .then(response => {
            resolve(response);
          })
          .catch(reason => {
            reject(reason);
         });
      });
    }
  }

  // This filetype is only for visualization purposes
  // and no copy of the file-handling-service FileTypeEnum
  GetFileTypeBasedOnFileExtension(fileName: string): FileType {
    if (!fileName) {
      return;
    }

    let fileExtension =  fileName.split('.').pop();
    fileExtension     = fileExtension.toLowerCase();

    switch (fileExtension) {
      case 'img':
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'svg':
        return FileType.Image;

      case 'csv':
      case 'txt':
      case 'xml':
      case 'json':
        return FileType.Text;

      case 'pdf':
        return FileType.Pdf;

      case 'bin':
      case 'rmb':
      case 'dat':
        return FileType.Binary;
    }

    return FileType.Other;
  }
}
