import {ScannerResult} from './scannerResult';
import {DetectedStack} from './detectedStack';
import {DetectedComponent} from './detectedComponent';

export class DetectedObject {
  protected _scan: ScannerResult;

  public free: boolean;
  public selfLink: string;
}
