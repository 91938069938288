import {FilterBase} from '../../../shared/models/filterBase';
import {Slice} from '../../../shared/models/slice';

export class CostCenterFilter extends FilterBase {
  public nr = '';
  public name = '';
  public productCenterName? = '';
  public productCenterShortName? = '';

  public isDeactivated?: boolean;
  public slice: Slice;

  constructor() {
    super();

    this.pagingSize = 250;
    this.totalPages = 1;
    this.currentPageIndex = 0;
    this.sort = [ 'nr,asc' ];

  }


  public getAllParams() {
    return {...this.getPageableParams(), ...this.getFilterParams()};
  }

  public IsEmpty(): boolean {
    if (this.nr && this.nr !== '') {
      return false;
    }
    if (this.name && this.name !== '') {
      return false;
    }
    if (this.productCenterName && this.productCenterName !== '') {
      return false;
    }

    if (this.productCenterShortName && this.productCenterShortName !== '') {
      return false;
    }

    if (this.isDeactivated !== undefined) {
      return false;
    }

    return true;
  }
  public getFilterParams() {
    if (this.IsEmpty()) {
      return {};
    }
    return {
      nr: this.nr,
      name: this.name,
      productCenterName: this.productCenterName,
      productCenterShortName: this.productCenterShortName,
      isDeactivated: this.isDeactivated
    };
  }

  public getPageableParams() {
    return {
      page: this.currentPageIndex,
      size: this.pagingSize,
      sort: this.sort,
    };
  }

}
