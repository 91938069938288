export class ExcelExportBase {
  title: string;
  description: string;
  ignoredPropertiesList = new Array<string>();
}

export class ExcelExport extends ExcelExportBase {
  headers: string[];
  rows: Row[];

  constructor() {
    super();

    this.title = 'Kein Title';
    this.headers = new Array<string>();
    this.rows  = new Array<Row>();
  }
}

export class Row {
  cells: Cell[];

  constructor() {
    this.cells = new Array<Cell>();
  }
}

export class Cell {
  constructor(content: string, type?: string) {
    this.value = content;
    this.type = type;
  }

  value: string;
  type?: string;
}

export class ExcelExportRaw extends ExcelExportBase {
  data: any[];
}

export class ExcelExportFilter extends ExcelExportBase {
  filterId: string;
}
