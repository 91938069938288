import {EventEmitter, Injectable} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import {VideoPlayerEntity, VideoState} from './video-player-entity';

@Injectable({
  providedIn: 'root'
})
export class VideoPlayerService {
  public static videos: Array<VideoPlayerEntity> = new Array<VideoPlayerEntity>();
  public static onNewVideoAdded = new EventEmitter<VideoPlayerEntity>();
  public static onVideoPlaybackReady = new EventEmitter<VideoPlayerEntity>();


  public static addFile(name: string, safeVideoUrl: SafeUrl, safeVideoUrlForPreView: SafeUrl = null) {
    const videoEntity= new VideoPlayerEntity(name, safeVideoUrl, safeVideoUrlForPreView);

    const videosCountBefore = VideoPlayerService.videos.length;

    VideoPlayerService.videos.push(videoEntity);
    VideoPlayerService.onNewVideoAdded.emit(videoEntity);

    if (videosCountBefore === 0) {
      VideoPlayerService.onVideoPlaybackReady.emit(videoEntity);
    }
  }

  public static removeFile(name: string) {
    const existingIndex = VideoPlayerService.videos.findIndex(v => v.name === name);

    if (existingIndex > -1 ) {
      VideoPlayerService.videos.splice(existingIndex, 1);
    }
  }

  static getNext(name: string): VideoPlayerEntity {
    const existingIndex = VideoPlayerService.videos.findIndex(v => v.name === name);

    if (existingIndex === -1 && VideoPlayerService.videos.length > 0) {
      return VideoPlayerService.videos[0];
    }

    if (existingIndex >= 0 && VideoPlayerService.videos.length > existingIndex + 1) {
      return VideoPlayerService.videos[existingIndex + 1];
    } else {
      return VideoPlayerService.videos[0];
    }
  }

  static isVideoPlaying(videoName: string) {
    return VideoPlayerService.videos.find(v => v.name === videoName)?.isPlaying;
  }

  static playVideo(videoName: string, previousVideoName: string = null) {
    if (previousVideoName) {
      const video = VideoPlayerService.videos.find(v => v.name === previousVideoName);

      if (video) {
        VideoPlayerService.videoPlaybackEnded(video.name)
      }
    } else {
      const videos = VideoPlayerService.videos.filter(v => v.isPlaying || v.videoState === VideoState.PAUSED);

      videos.forEach(video => {
        VideoPlayerService.videoPlaybackEnded(video.name)
      })
    }

    const newVideo = VideoPlayerService.videos.find(v => v.name === videoName);

    if (newVideo) {
      VideoPlayerService.videoPlaybackStart(newVideo.name)
    }
  }


  public static videoPlaybackEnded(videoName: string) {
    const video = VideoPlayerService.videos.find(v => v.name === videoName);

    if (video) {
      video.isPlaying = false;
      video.videoState = VideoState.ENDED;

      console.log(videoName + ' ended.');
    }
  }

  public static videoPlaybackPaused(videoName: string) {
    const video = VideoPlayerService.videos.find(v => v.name === videoName);

    if (video) {
      video.isPlaying = false;
      video.videoState = VideoState.PAUSED;
    }
  }

  public static videoPlaybackStart(videoName: string) {
    const video = VideoPlayerService.videos.find(v => v.name === videoName);

    if (video) {
      video.isPlaying = true;
      video.videoState = VideoState.PLAYING;
    }
  }
}
