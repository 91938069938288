import {ProductUnitResponse} from '@cstx/volkswagen-mqs-metadata-service-client';

export class ProductUnit {
  public id: string;
  public description: string;
  public name: string;
  public modifiedDate: string;
  public modifiedBy: string;
  public isDeactivated: boolean;

  constructor(productUnitResponse?: ProductUnitResponse) {
    if (productUnitResponse) {
      this.id = productUnitResponse.id;
      this.name = productUnitResponse.name;
      this.description = productUnitResponse.description;
      this.modifiedBy = productUnitResponse.modifiedBy;
      this.modifiedDate = productUnitResponse.modifiedAt;
      this.isDeactivated = productUnitResponse.isDeactivated;
    } else {
      this.modifiedDate = new Date().toISOString();
      this.modifiedBy = 'NEW';
    }
  }

  public get isNew() {
    if (this.id) {
      return false;
    }

    return true;
  }

}
