import {Injectable} from '@angular/core';
import {
  DivergentBigScreenTargetValueType
} from '../../../modules/metadata/costcenter-profile/models/BigScreenSettings';
import {
  BigScreenMetricType as BigScreenMetricTypeBackend,
  BigScreenTrendType as BigScreenTrendTypeBackend,
  CostCenterConfigurationResponse,
  CostCenterConfigurationService,
  CostCenterConfigurationUpdateRequest,
  CreateOrUpdateCostCenterConfigurationRequestParams,
  DivergentBigScreenTargetValueType as BackendDivergentBigScreenTargetValueType,
  ForceBigScreenReloadRequestParams,
  GetCostCenterConfigurationRequestParams,
  ParentCostCenterConfigurationResponse,
  ParentCostCenterConfigurationUpdateRequest,
  PutStatsRequestParams,
  UserConfigurationResponse,
  UserConfigurationService as BackendUserConfigurationService,
  UserLoginService,
  UserLoginStats
} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {firstValueFrom} from 'rxjs';
import {
  CostCenterConfiguration
} from '../../../modules/metadata/costcenter-profile/models/CostCenterConfiguration';
import {ErrorHandler} from '../error-handler/error-handler';
import {BigScreenMetricType} from '../../../modules/tools/bigscreen2/BigScreenMetricType';
import {BigScreenTrendType} from '../../../modules/tools/bigscreen2/BigScreenTrendType';
import {
  CountSinceRequestParams,
  GetLoggedInSinceRequestParams
} from '@cstx/volkswagen-mqs-user-configuration-service-client/api/userLogin.service';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';

@Injectable({
  providedIn: 'root'
})
export class UserConfigurationService {

  constructor(private costCenterConfigurationControllerService: CostCenterConfigurationService,
              private userConfigurationControllerService: BackendUserConfigurationService,
              private userStatsControllerService: UserLoginService) { }

  /**
   * Creates or updates a costcenter configuration for the infosystem.
   * @param configuration A costcenter configuration object which contains all old and new settings
   * @returns CostCenterConfigurationResponse A object of type CostCenterConfigurationResponse is returned or in case
   * of an error, null is returned.
   */
  public async updateCostCenterConfiguration(configuration: CostCenterConfiguration):Promise<CostCenterConfigurationResponse> {
    try {
      let updateRequest: CostCenterConfigurationUpdateRequest;
      updateRequest = {
        costCenter: configuration.costCenter,
        alertDurationInMinutes: configuration.alertDurationInMinutes,
        supportRequestDurationInMinutes: configuration.supportRequestDurationInMinutes,
        bmaLineAlias: configuration.bmaLineAlias ? configuration.bmaLineAlias : null,
        shiftoffsetForBuildablilityCheck: configuration.shiftoffsetForBuildablilityCheck,
        bigScreenConfiguration: {
          id: configuration.bigScreenSettings.id,
          bigScreenMetricType: this.getBigScreenMetricType(configuration.bigScreenSettings.bigScreenMetricType),
          bigScreenTrendType: this.getBigScreenTrendType(configuration.bigScreenSettings.bigScreenTrendType),
          divergentBigScreenTargetValue: configuration.bigScreenSettings.divergentBigScreenTargetValue,
          divergentBigScreenTargetValueActive: configuration.bigScreenSettings.divergentBigScreenTargetValueActive,
          divergentBigScreenTargetValueType:
            configuration.bigScreenSettings.divergentBigScreenTargetValueType
            === DivergentBigScreenTargetValueType.ABSOLUTE
              ? BackendDivergentBigScreenTargetValueType.Absolute
              : BackendDivergentBigScreenTargetValueType.Percentage
        }
      };

       if (configuration.id) {
        updateRequest = {
          ...updateRequest,
          id: configuration.id,
        }
      }

      let updateParams: CreateOrUpdateCostCenterConfigurationRequestParams;
      updateParams = {
        costCenterConfigurationUpdateRequest: updateRequest
      };


      return await firstValueFrom(this.costCenterConfigurationControllerService.createOrUpdateCostCenterConfiguration(updateParams));
    } catch (error) {
      ErrorHandler.printError(error);
      return null;
    }
  }

  /**
   * Gets a costCenter specific infosystem configuration
   * @param costCenter The costcenter number for which a configuration is requested.
   * @returns CostCenterConfigurationResponse A object of type CostCenterConfigurationResponse is returned or in case
   * of an error, null is returned.
   */
  public async getCostCenterConfiguration(costCenter: string): Promise<CostCenterConfigurationResponse> {
    try {
      let params: GetCostCenterConfigurationRequestParams;
      params = {
        costCenter
      };

      return await firstValueFrom(this.costCenterConfigurationControllerService.getCostCenterConfiguration(params));
    }
    catch (error) {
      ErrorHandler.printError(error);
      return null;
    }
  }

  /**
   * Gets a costCenter specific infosystem configuration
   * @param costCenter The costcenter number for which a configuration is requested.
   * @param withParent
   * @returns CostCenterConfiguration A object of type CostCenterConfiguration is returned or in case
   * of an error, null is returned.
   */
  public async getCostCenterConfigurationV2(costCenter: string,
                                          withParent: boolean = true): Promise<CostCenterConfiguration> {
    try {
      let params: GetCostCenterConfigurationRequestParams;
      params = {
        costCenter
      };

      const response =
        await firstValueFrom(this.costCenterConfigurationControllerService.getCostCenterConfiguration(params));

      if (!response) {
        return null;
      }

      const costCenterConfiguration = new CostCenterConfiguration();
      costCenterConfiguration.mapFromResponse(response);

      if (withParent && costCenter.length >= 4) {
        const parentCostCenterConfigurationResponse =
          await this.getParentCostCenterConfiguration(costCenter.substring(0,4))

        if (parentCostCenterConfigurationResponse) {
          costCenterConfiguration.createToolCorrectionProcessOnYellowReports
            = parentCostCenterConfigurationResponse.createToolCorrectionProcessOnYellowReports;
        }
      }

      return costCenterConfiguration;

    }
    catch (error) {
      LoggingService.logError(LoggingSource.USER_CONFIGURATION_SERVICE,
        `Error occurred receiving costCenter configuration for costCenter ${costCenter} withParent: ${withParent}`, error);
    }

    return null;
  }

  public getParentCostCenterConfiguration(costCenter: string): Promise<ParentCostCenterConfigurationResponse> {
    const request = {
      costCenter
    }

    return firstValueFrom(this.costCenterConfigurationControllerService.getParentCostCenterConfiguration(request));
  }

  public async createOrUpdateParentCostCenterConfiguration(
    costCenter: string,
    createToolCorrectionProcessOnYellowReports: boolean): Promise<ParentCostCenterConfigurationResponse> {
    let parentCostCenterConfigurationUpdateRequest: ParentCostCenterConfigurationUpdateRequest = {
        costCenter,
        createToolCorrectionProcessOnYellowReports
    }

    const response = await firstValueFrom(this.costCenterConfigurationControllerService.getParentCostCenterConfiguration({costCenter}));
    if (response) {
      parentCostCenterConfigurationUpdateRequest = {
        ...parentCostCenterConfigurationUpdateRequest,
       id: response.id
      }
    }

    return firstValueFrom(
      this.costCenterConfigurationControllerService
      .createOrUpdateParentCostCenterConfiguration({parentCostCenterConfigurationUpdateRequest}));
  }

  /**
   * Forces a reload of all connected bigscreen devices. This has cooldown of 4 -6 minutes.
   * As long es the bigScreenReload property is true, no further request will be processed.
   * @param costCenterConfigurationId The id of the costCenterConfiguration we want to initiate a forced bigscreen reload.
   */
  public async forceBigScreenReload(costCenterConfigurationId: string) {
    let params: ForceBigScreenReloadRequestParams;
    params = {
      id: costCenterConfigurationId
    }

    await firstValueFrom(this.costCenterConfigurationControllerService.forceBigScreenReload(params));
  }


  /**
   * This method loads based on given areaName and clientName the configuration and returns it.
   * Attention: Response could contain more then one result which will be omitted. Although the config version is ignored
   * currently
   * For further use the requester needs to know the target model.
   * @param areaName Custom areaName where to look for a certain configuration for a client, f.e portal.
   * @param clientName The client identifier we need a configuration for. For example the email address or an appName.
   */

  public async loadClientConfiguration(areaName: string, clientName: string): Promise<UserConfigurationResponse> {
    let response: UserConfigurationResponse = null;

    try {
      const responseSlice = await firstValueFrom(
        this.userConfigurationControllerService.getUserConfigurations({clientId: clientName, area: areaName}));


      const userConfigurationResponse = responseSlice.content[0];

      if (userConfigurationResponse) {
        response = userConfigurationResponse;
      }

    } catch (error) {
      ErrorHandler.printError(error);
    }

    return response
  }

  /**
   * This method saves a configuration json for a given areaName and client.
   * @param areaName Custom areaName which to store a certain configuration for, f.e portal.
   * @param clientName The client identifier we need a configuration to store. For example the email address or an appName.
   */
  public async saveClientConfiguration(areaName: string, clientName: string, configurationJson): Promise<string> {
    try {
      const userConfigurationCreateRequest = {
        clientId: clientName,
        area: areaName,
        configurationVersion: '1',
        configuration: configurationJson
      }

      const response = await firstValueFrom(
        this.userConfigurationControllerService.createUserConfiguration({ userConfigurationCreateRequest }));

      if (response.configuration) {
        configurationJson = response.configuration;
      }

    } catch (error) {
      ErrorHandler.printError(error);
    }

    return configurationJson;
  }


  private getBigScreenMetricType(bigScreenMetricType: BigScreenMetricType) {
    if (bigScreenMetricType === BigScreenMetricType.TARGET_ACTUAL_TREND) {
      return BigScreenMetricTypeBackend.TargetActualTrend;
    }

    if (bigScreenMetricType === BigScreenMetricType.CAPACITY_ACTUAL_CURRENT_CAPACITY) {
      return BigScreenMetricTypeBackend.CapacityActualCurrentCapacity;
    }

    return BigScreenMetricTypeBackend.CapacityActualTrend;
  }

  private getBigScreenTrendType(bigScreenTrendType: BigScreenTrendType) {
    if (bigScreenTrendType === BigScreenTrendType.Linear) {
      return BigScreenTrendTypeBackend.Linear;
    }

    if (bigScreenTrendType === BigScreenTrendType.SZOnlineV1) {
      return BigScreenTrendTypeBackend.SzOnlineV1;
    }

    return BigScreenTrendTypeBackend.Optimistic;
  }


  /**
   * This method deletes a configuration based on its id.
   * @param id The configuration id.
   */
  public async deleteClientConfiguration(id: string) {
    try {
      await firstValueFrom(this.userConfigurationControllerService.deleteUserConfiguration({id}));

    } catch (error) {
      ErrorHandler.printError(error);
    }
  }

  public async putUserStats(permissions: string): Promise<void> {
    try {
      const userLoginStats: UserLoginStats = {
        permissions
      }

      const params: PutStatsRequestParams = {
        userLoginStats
      }

      await firstValueFrom(this.userStatsControllerService.putStats(params));
    } catch (error) {
      LoggingService.logError(LoggingSource.USER_CONFIGURATION_SERVICE,
        'Sending userStatistic failed with error.', error);
    }
  }

  public async getUsersCount(): Promise<number> {
    let count = 0;

    try {
      const since = new Date();
      since.setMinutes(-5);


      const params: CountSinceRequestParams = {
        dateTimeOffset: since.toISOString()
      }

      count = await firstValueFrom(this.userStatsControllerService.countSince(params));


    } catch (error) {
      LoggingService.logError(LoggingSource.USER_CONFIGURATION_SERVICE,
        'Receiving user count failed with error.', error);
    }

    return count;
  }

  public async getUsers(): Promise<string[]> {
    let users;

    try {
      const since = new Date();
      since.setMinutes(-5);


      const params: GetLoggedInSinceRequestParams = {
        dateTimeOffset: since.toISOString()
      }

      const userResponses = await firstValueFrom(this.userStatsControllerService.getLoggedInSince(params));

      if (userResponses.length > 0) {
        users = userResponses.map(user => user.identifier);
      }

    } catch (error) {
      LoggingService.logError(LoggingSource.USER_CONFIGURATION_SERVICE,
        'Receiving user stats failed with error.', error);
    }

    return users;
  }

  public async getShiftOffsetMap():Promise<{ [key: string]: number; }>{
    try {
    return await firstValueFrom(this.costCenterConfigurationControllerService.getbuildabilityOffsetMapping());
    } catch (error) {
      LoggingService.logError(LoggingSource.USER_CONFIGURATION_SERVICE,
        'Receiving buildabilityOffsetMapping failed with error.', error);
    }
    return {};
  }
}
