<ng-container *ngIf="EnterpriseRoleProviderService.hasRequiredClaimByName('home-dashboard', false)">
  <div class="container-fluid">
    <div class="row pt-2 pb-3 vp">
      <ng-container *ngIf='!zoomEnabled; else zoomed'>
        <ng-container
          *ngFor="let actualTargetChart of getVisibleCharts(); let i = index;last as isLast">
          <div class="p-3" [ngClass]="isLast && (i % 2 === 0) ? 'col-sm-12':'col-sm-6'">
            <div class="card mh-sm">
              <div class="d-none d-lg-block">
                <div class="card-header">
                  <div class="row">
                    <div class="col-6">{{ actualTargetChart.groupName}}</div>
                    <div class="col-6 text-right">
                      <i class="fas fa-times pl-1 pointer" aria-hidden="true"
                         (click)="toggleVisibility(actualTargetChart)"
                         [hidden]="getVisibleCharts().length === 1"></i>
                      <i class="fas fa-search-plus pl-1 pointer" aria-hidden="true"
                         (click)="zoomChart(actualTargetChart)"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body h-100">
                <div class="d-lg-none">
                  {{ actualTargetChart.groupName}}
                </div>
                <op-chart [datasets]="actualTargetChart.barChartData"
                          [labels]="actualTargetChart.barChartLabels"
                          [options]="actualTargetChart.barChartOptions"
                          [legend]="actualTargetChart.showLegend"
                          [update]="chartUpdateEvent"
                          [colors]="actualTargetChart.colors"
                          [groupID]="actualTargetChart.groupID"
                          [mode]="chartStyle"
                          [statistics]="statistics">
                </op-chart>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="actualTargetCharts.length === 0 && !showSpinner">
          <div class="col-12">
            <div class="text-center pt-5">
              <img src="/assets/img/no_production.png" alt="" class="w-75" style="max-width: 512px">
            </div>
          </div>
          <div class="col-12 text-center" style="color: gray">
            <h3>{{ 'page.dashboard.noProduction' | translate }}</h3>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #zoomed>
        <ng-container *ngIf="!showSpinner">
          <ng-container *ngIf="zoomedChart, else zoomedNoData">
            <div class="p-3 col-sm-12" style="height: calc(100vh - 110px)">
              <div class="card h-100 ">
                <div class="card-header">
                  <div class="row">
                    <div class="col-6">{{ zoomedChart.groupName ? zoomedChart.groupName : zoomedChart.groupID }}</div>
                    <div class="col-6 text-right">
                      <!--<i class="fas fa-chart-bar pl-1 pointer" aria-hidden="true" data-toggle="modal"
                         data-target="#openDetail"
                         (click)="setCurrentStatisticsGroupId(zoomedChart.groupID)"></i>-->
                      <i class="fas fa-search-minus pl-1 pointer" aria-hidden="true"
                         (click)="zoomChart(zoomedChart)"></i>
                    </div>
                  </div>
                </div>
                <div class="card-body h-100">
                  <op-chart [datasets]="zoomedChart.barChartData"
                            [labels]="zoomedChart.barChartLabels"
                            [options]="zoomedChart.barChartOptions"
                            [legend]="zoomedChart.showLegend"
                            [update]="chartUpdateEvent"
                            [colors]="zoomedChart.colors"
                            [groupID]="zoomedChart.groupID"
                            [mode]="chartStyle"
                            [statistics]="statistics">
                  </op-chart>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #zoomedNoData>
            <div class="p-3 col-sm-12">
              <div class="card h-100 ">
                <div class="card-header">
                  <div class="row">
                    <div class="col-6">{{ zoomedChartId }}</div>
                    <div class="col-6 text-right">
                      <i class="fas fa-search-minus pl-1 pointer" aria-hidden="true"
                         (click)="zoomChart(zoomedChart)"></i>
                    </div>
                  </div>
                </div>
                <div class="card-body h-100">
                  {{ 'page.dashboard.noProductionOnChart' | translate }}
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </ng-template>
    </div>
    <ng-container *ngIf='!zoomEnabled && !showSpinner && actualTargetCharts.length > 0'>
      <div class="row">
        <div class="col-sm">
          <button (click)="toggleWithZeroValues()" type="button"
                  class="btn btn-danger btn-block mt-3 mr-3">
            {{ 'button.dashboard.toggle.zero' | translate }}
          </button>
        </div>
        <ng-container *ngFor="let actualTargetChart of actualTargetCharts">
          <div class="col-sm">
            <button *ngIf='!actualTargetChart.isHidden' (click)="toggleVisibility(actualTargetChart)"
                    type="button" class="btn btn-info btn-block mt-3 mr-3">
              {{ getGroupLabel(actualTargetChart) }}  {{ 'button.prefix.hide' | translate }}</button>
            <button *ngIf='actualTargetChart.isHidden' (click)="toggleVisibility(actualTargetChart)"
                    type="button" class="btn btn-warning btn-block mt-3 mr-3">
              {{ getGroupLabel(actualTargetChart) }} {{ 'button.prefix.show' | translate }} </button>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <op-spinner *ngIf='showSpinner'></op-spinner>
</ng-container>
