import { Injectable } from '@angular/core';
import {ManufacturingProcessControllerService } from '@cstx/volkswagen-mqs-work-sequence-service-client';
import {ProcessSet} from '../models/manufacturingProcess/processSet';

@Injectable({
  providedIn: 'root'
})
export class ManufacturingProcessServiceService {

  constructor(private manufacturingProcessService: ManufacturingProcessControllerService) {

  }

  getProcessSetByComponentId(id: string): Promise<ProcessSet> {
    return new Promise<any>((resolve, reject) => {
      this.manufacturingProcessService.getManufacturingProcessDataSetByComponentId({
        id
      })
        .toPromise()
        .then(result => {
          resolve(result);
        })
        .catch(error => reject(error));
    });
  }
}
