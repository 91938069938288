import { Component } from '@angular/core';
import {KioskModeService} from '../../../../core/services/kiosk-mode.service';
import {Slice} from '../../../../shared/models/slice';
import {InfoFilter} from '../infoFilter';
import {InfosService} from '../infos.service';
import {UserInformationResponse} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {
  AttributeEntry,
  FilterValue, InputType
} from '../../../../shared/components/guided-filter-input/guided-filter-input.component';
import {Subject} from 'rxjs';
import {Info} from "../info";

@Component({
  selector: 'op-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  public kioskMode: boolean;
  public listUpdating: boolean;
  public searching: boolean;

  public filter = new InfoFilter();
  public infos = new Array<Info>();
  public itemCount: number;


  filterValues: Array<FilterValue> = new Array<FilterValue>();
  searchAliasMappings: Array<AttributeEntry> = [
    {key: 'info.title', value: 'title', inputType: InputType.String},
    {key: 'info.description', value: 'description', inputType: InputType.String},
    {key: 'info.infoType.key', value: 'type', inputType: InputType.Selection,
      selection: [
        {key: 'info.infoType.news', value: 'NEWS',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorNews']},
        {key: 'info.infoType.alert', value: 'ALERT',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorAlert']},
        {key: 'info.infoType.stopnotification', value: 'STOPNOTIFCATION',
          hasDecorationIcon: true, decorationClasses: ['fas', 'fa-square', 'colorStopNote']}
      ]
    },
  ];

  listSort: string[] = [];
  public clearFilterSub: Subject<void> = new Subject<void>();
  private activeTab: string;

  constructor(private kioskModeService: KioskModeService,
              private infoService: InfosService) {

    this.kioskModeService.kioskMode$.subscribe(mode => {
      this.kioskMode = mode;
    });
  }

  onDown() {
    if (this.listUpdating) {
      return;
    }

    this.update();
  }

  onUp() {

  }

  isActive(tab: string) {
    return this.activeTab === tab;
  }

  setActive(tab: string) {
    this.activeTab = tab;
  }

  public onSort(column: string) {
    this.listSort = this.filter.sortOn(column);

    this.filter.pagingSize = 50;
    this.filter.totalPages = 1;
    this.filter.currentPageIndex = 0;
    this.filter.slice = undefined;

    this.update(true);
  }
  public setFilterValues(filterValues: Array<FilterValue>) {
    this.filterValues = filterValues;
  }

  public onSearch(filterValues?: Array<FilterValue>) {
    this.searching = true;

    if (!this.filter) {
      this.filter = new InfoFilter();
    }

    if (filterValues) {
      this.setFilterValues(filterValues);
    }

    this.applyFilterValuesToNewFilter();
    if (this.filter !== undefined) {
      this.filter.sort = this.listSort;
    }

    this.update(true);
  }

  private applyFilterValuesToNewFilter() {
    this.filter = new InfoFilter();

    this.filterValues.forEach(filterValue => {
      this.filter[filterValue.attribute] = filterValue.value;
    });
  }

  public onSearchAbort() {
    this.resetSearchAndFilter();
    this.update(true);
  }

  private resetSearchAndFilter() {
    this.filter = new InfoFilter();
    this.listSort = undefined;
  }

  update(reset: boolean = false) {
    this.listUpdating = true;
    const items = new Array<UserInformationResponse>();

    if (reset) {
      this.filter.currentPageIndex = 0;
    }


    this.infoService.getInfos(this.filter)
      .then(r => {
        r.content.forEach(response => {
          items.push(response);
        })

        if (reset) {
          this.infos = items.map<Info>(i => (new Info().mapFromUserInformationResponse(i)));
        } else {
          this.infos = this.infos.concat(items.map<Info>(i => (new Info().mapFromUserInformationResponse(i))));
        }


        this.filter.slice = new Slice(
          r.first, r.last, r.number, r.size, r.empty
        );

        this.filter.currentPageIndex++;
      })
      .catch(error => {
        console.log(error);
      }).finally(() => {
      this.listUpdating = false;
      this.searching = false;
    });
  }

  async delete(id: string) {
    await this.infoService.deleteInfo(id);
    this.update(true);
  }
}
