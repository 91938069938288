import {Component, Input, OnInit} from '@angular/core';
import {TrackingEvent} from '../../../models/tracking/trackingEvent';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {ComponentProfile} from '../component-profile.component';
import {LabelingType} from '../actions/labeling/LabelingType';
import {KeycodeHistory} from '../../../../modules/engine/list/engine';
import {EngineService} from '../../../../modules/engine/services/engine.service';
import {TrackingService} from '../../../services/tracking.service';
import {ComponentType, EventType} from '@cstx/volkswagen-mqs-tracking-service-client';
import {AuthService} from '../../../../core/services/auth.service';
import {ReferenceType} from '@cstx/volkswagen-mqs-tracking-service-client/model/referenceType';
import {TestInheritance} from '@cstx/volkswagen-mqs-engine-service-client/model/testInheritance';

@Component({
  selector: 'op-keycode-history',
  templateUrl: './keycode-history.component.html',
  styleUrls: ['./keycode-history.component.scss']
})
export class KeycodeHistoryComponent implements OnInit {
  @Input() componentProfile: ComponentProfile;
  @Input() selfLink: string;
  @Input() contentContainerId: string;

  trackingEvents = new Array<TrackingEvent>();
  loading = false;
  viewSizeSM: boolean;
  viewSizeXS: boolean;

  editTestInvalidate = -1;
  oldColdTestInheritance: TestInheritance;
  oldHotTestInheritance: TestInheritance;
  loadingTestInvalidate = false;
  errorTestInvalidate = false;

  testInheritance = [
    {label: 'Invalidieren', value: 'INVALIDATE'},
    {label: 'Übernehmen', value: 'KEEP'}
  ]

  protected readonly LabelingType = LabelingType;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private engineService: EngineService,
    private trackingService: TrackingService,
    private authService: AuthService
    ) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 1280px)'])
      .subscribe((state: BreakpointState) => {
        this.viewSizeSM = state.matches;
      });

    this.breakpointObserver
      .observe(['(max-width: 940px)'])
      .subscribe((state: BreakpointState) => {
        this.viewSizeXS = state.matches;
      });
  }

  handleEditTestInvalidate(row: number, entry: KeycodeHistory) {
    this.editTestInvalidate = row;
    this.oldHotTestInheritance = entry.hotTestInheritance;
    this.oldColdTestInheritance = entry.coldTestInheritance;
  }

  handleAbortEditTestInvalidate(entry: KeycodeHistory) {
    this.editTestInvalidate = -1;
    entry.hotTestInheritance = this.oldHotTestInheritance;
    entry.coldTestInheritance = this.oldColdTestInheritance;
    this.oldHotTestInheritance = undefined;
    this.oldColdTestInheritance = undefined;
  }

  public handleConfirmEditTestInvalidate(entry: KeycodeHistory) {
    this.loadingTestInvalidate = true;
    this.engineService.updateKeyCodeHistoryEntry(
      this.componentProfile.Id,
      entry.id,
      entry.keyCodeOld,
      entry.keyCodeNew,
      entry.keyCodeChangedAt,
      entry.coldTestInheritance === 'KEEP',
      entry.hotTestInheritance === 'KEEP',
      entry.source).then(response => {
        this.trackingService.createTrackingEvent(
          this.componentProfile.Id,
          this.componentProfile.Identifier,
          this.componentProfile.componentType as ComponentType,
          this.componentProfile.componentType as ReferenceType,
          (new Date()).toISOString(),
          EventType.KeycodeHistoryChanged,
          'Portal',
          '',
          {user:this.authService.currentUser.username}
        );
    }).then(() => {
      this.editTestInvalidate = - 1;
      this.oldHotTestInheritance = undefined;
      this.oldColdTestInheritance = undefined;
    })
    .catch(e => {
      this.errorTestInvalidate = true;
    })
    .finally(() => {
      this.loadingTestInvalidate = false;
    });
  }
}
