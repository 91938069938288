import {SafeUrl} from '@angular/platform-browser';

export class VideoPlayerEntity {
  constructor(name: string, video: SafeUrl, videoPreview: SafeUrl = null) {
    this.name = name;
    this.video = video;

    if (videoPreview) {
      this.videoPreview = videoPreview;

    }
  }
  public name: string;
  public video: SafeUrl;
  public videoPreview: SafeUrl;
  public isPlaying: boolean;
  public videoState: VideoState = VideoState.LOADED

}

export enum VideoState {
  LOADED = 'LOADED',
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
  ENDED = 'ENDED'
}
