import {Component, OnInit} from '@angular/core';
import {EffectiveUserClaim, EnterpriseRoleProviderService} from '../../services/enterprise-role-provider.service';
import {Router} from "@angular/router";

@Component({
  selector: 'op-permission-missing',
  templateUrl: './permission-missing.component.html',
  styleUrls: ['./permission-missing.component.scss']
})
export class PermissionMissingComponent implements OnInit {

  protected readonly EnterpriseRoleProviderService = EnterpriseRoleProviderService;
  public currentRoute: string;

  constructor(private router: Router) {
  }

  public ngOnInit(): void {
    this.currentRoute = this.router.url;
  }
}
