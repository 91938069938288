import { Component } from '@angular/core';

@Component({
  selector: 'op-form-value-loading-animation',
  templateUrl: './form-value-loading-animation.component.html',
  styleUrls: ['./form-value-loading-animation.component.scss']
})
export class FormValueLoadingAnimationComponent {

}
