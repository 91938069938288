import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Slice} from '../../models/slice';

@Component({
  selector: 'op-slicing',
  templateUrl: './slicing.component.html',
  styleUrls: ['./slicing.component.scss']
})
export class SlicingComponent implements OnInit, OnChanges {
  @Input() pagingOptions: Array<any>;

  @Input() slice: Slice;
  @Input() defaultLabel: any;

  @Output() pageChanged = new EventEmitter();
  @Output() pagingSizeChanged = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    if (this.pagingOptions === undefined) {
      this.pagingOptions = [
        {label: '50', value: '50'},
        {label: '100', value: '100'},
        {label: '250', value: '250'}
      ];
    }

    if (this.defaultLabel === undefined) {
      this.defaultLabel = this.pagingOptions[0][0];
    }
  }

  goBack() {
    this.pageChanged.emit(this.slice.pageNumber - 1);
  }

  goForward() {
    this.pageChanged.emit(this.slice.pageNumber + 1);
  }


  selectedItemChanged(value: string) {
    // tslint:disable-next-line:radix
    this.pagingSizeChanged.emit(parseInt(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.defaultLabel === undefined) {
      this.defaultLabel = this.pagingOptions[0][0];
    }
  }
}
