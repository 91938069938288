import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ManufacturingStatisticControllerService,
  ManufacturingStatisticResponse
} from '@cstx/volkswagen-mqs-manufacturing-monitor-service-client';
import {ActualTargetChart, SortIndicator} from '../../models/actualTargetChart';
import {chartOptions} from '../../models/chartOptions';
import {firstValueFrom, interval, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ChartDataset, Color} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import {UserConfigService} from '../../../../shared/services/user-config.service';
import {AuthService} from '../../../../core/services/auth.service';
import {User} from '../../../../core/models/user';
import {BarChartColorTheme, BarChartStyleTheme, UserConfig} from '../../../../shared/models/userConfig';
import {ThemingCategory, ThemingService} from '../../../../shared/services/theming.service';
import {EnterpriseRoleProviderService} from '../../../../core/services/enterprise-role-provider.service';
import {LoggingService} from '../../../../core/logging/logging.service';
import {LoggingSource} from '../../../../core/logging/loggingSource';

@Component({
  selector: 'op-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  constructor(private manufacturingStatisticService: ManufacturingStatisticControllerService,
              private route: ActivatedRoute,
              private userConfigService: UserConfigService,
              private themingService: ThemingService,
              private router: Router,
              private authService: AuthService) {
  }

  @ViewChild(BaseChartDirective) private chart: BaseChartDirective;

  currentStatisticsGroupId: string;
  actualTargetCharts: Array<ActualTargetChart> = new Array<ActualTargetChart>();
  hiddenCharts: string[] = [];
  zoomEnabled: boolean;
  zoomedChartId: string;
  zoomedChart: ActualTargetChart;
  zeroValueEnabled: boolean;
  chartUpdateEvent = new Subject();
  showSpinner = false;
  private stop$ = new Subject();
  chartStyle: BarChartStyleTheme  =  BarChartStyleTheme.FillingBar;
  colorTheme: BarChartColorTheme = BarChartColorTheme.Glossy;

  public statistics = new Array<ManufacturingStatisticResponse>();

  private colorSuccess: string;
  private colorWarning: string;
  private colorWarningBorder: string;
  private colorFail: string;
  private colorSuccessBorder: string;
  private colorFailBorder: string;
  private targetColor: string;
  public currentUser: User;

  protected readonly EnterpriseRoleProviderService = EnterpriseRoleProviderService;

  async ngOnInit(): Promise<void> {
    this.themingService.setThemeCategory(ThemingCategory.Dashboard);

    this.router.events.pipe(takeUntil(this.stop$)).subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.themingService.setThemeCategory(ThemingCategory.Dashboard);
      }});

    this.showSpinner = true;

    // LoadConfiguration from UserConfigurationService
    const config = await this.userConfigService.GetConfig();
    this.setConfiguration(config, true);

    // Watch for configuration changes
    this.userConfigService.config$.subscribe(configurationChanged => {
      this.setConfiguration(configurationChanged);
    });

    this.authService.loggedIn.subscribe(loggedIn => {
      this.currentUser = this.authService.currentUser;
    });

    this.route.queryParamMap.subscribe(params => {
      const pc = params.get('productCenter');
      if (pc) {
        this.zoomEnabled = true;
        this.zoomedChartId = pc;
      }

      this.pollData();
      this.startPolling();
    });
  }

  private setConfiguration(config: UserConfig, init = false) {
    if (config.dashboard) {
      if (init
        || (config.dashboard.chartStyle && config.dashboard.chartStyle !== this.chartStyle)
        || (config.dashboard.colorTheme && config.dashboard.colorTheme !== this.colorTheme))
      {
        this.chartStyle = config.dashboard.chartStyle ? config.dashboard.chartStyle : this.chartStyle;
        this.colorTheme = config.dashboard.colorTheme ? config.dashboard.colorTheme : this.colorTheme;
        this.zeroValueEnabled = config.dashboard.withZeroValues;
        this.zoomEnabled = config.dashboard.zoomEnabled;
        this.zoomedChartId = config.dashboard.zoomedChartId;

        this.hiddenCharts = new Array<string>();
        if (config.dashboard.productCenters) {
          this.hiddenCharts = this.hiddenCharts
            = Object.keys(config.dashboard.productCenters).filter(key => config.dashboard.productCenters[key].hidden)
        }

        this.updateColors();
        this.updateChartConfig();
      }
    } else if (init) {
      this.updateColors();
      this.updateChartConfig();
    }
  }

  toggleVisibility(currentChart: ActualTargetChart): void {
    if (!currentChart.isHidden) {
      this.hiddenCharts.push(currentChart.groupID);
    } else {
      const indexOf = this.hiddenCharts.indexOf(currentChart.groupID);
      this.hiddenCharts.splice(indexOf, 1);
    }

    currentChart.isHidden = !currentChart.isHidden;

    const configurationPatch = {dashboard: { productCenters: {} }}
    configurationPatch.dashboard.productCenters[currentChart.groupID] = { hidden: currentChart.isHidden };

    this.userConfigService.pushConfig(configurationPatch);
  }

  toggleWithZeroValues(): void {
    this.zeroValueEnabled = !this.zeroValueEnabled;

    const configurationPatch = {dashboard: { withZeroValues: this.zeroValueEnabled }};
    this.userConfigService.pushConfig(configurationPatch);

    this.actualTargetCharts.forEach(chart => {
        chart.barChartLabels = [];
        chart = this.initChartData(chart);
      }
    );

    this.showSpinner = true;
    this.pollData();
  }

  setCurrentStatisticsGroupId(id: string) {
    this.currentStatisticsGroupId = id;
  }

  zoomChart(zoomedChart: ActualTargetChart): void {
    if (zoomedChart) {
      this.zoomEnabled = !this.zoomEnabled;
      this.zoomedChart = zoomedChart;
      this.zoomedChartId = zoomedChart.groupID;

      const configurationPatch: UserConfig = {
        dashboard: {
          zoomEnabled: this.zoomEnabled,
          zoomedChartId: this.zoomEnabled ? this.zoomedChartId : undefined
        }
      };

      this.userConfigService.pushConfig(configurationPatch)

      this.chartUpdateEvent.next(null);
      } else if (this.zoomEnabled) {
        this.zoomEnabled = false;
      }
  }

  getVisibleCharts() {
    return this.actualTargetCharts.filter(value => !value.isHidden);
  }

  public getGroupLabel(chart: ActualTargetChart) {
    if (chart.groupName === '') {
      return chart.groupID;
    } else {
      return chart.groupName;
    }
  }

  createOrUpdateChartMetric(labelIndex: number, label: string, actual: number, target: number, chart: ActualTargetChart) {
    const calcColor = this.calculateBarColor(actual, target);

    if (labelIndex === -1) {
      chart.barChartLabels.push(label);
      chart.barChartData.labels.push(label);
      chart.barChartData.datasets[0].data.push(actual);
      chart.barChartData.datasets[1].data.push(target);

      (chart.barChartData.datasets[0].backgroundColor as Array<Color>).push(calcColor);
      (chart.barChartData.datasets[0].borderColor as Array<Color>).push(calcColor);
      (chart.barChartData.datasets[0].hoverBackgroundColor as Array<Color>).push(calcColor);
      (chart.barChartData.datasets[0].hoverBorderColor as Array<Color>).push(calcColor);
    } else {
      chart.barChartData.datasets[0].data[labelIndex] = actual;
      chart.barChartData.datasets[0].backgroundColor[labelIndex] = calcColor;
      chart.barChartData.datasets[0].borderColor[labelIndex] = calcColor;
      chart.barChartData.datasets[0].hoverBackgroundColor[labelIndex] = calcColor;
      chart.barChartData.datasets[0].hoverBorderColor[labelIndex] = calcColor;
      chart.barChartData.datasets[1].data[labelIndex] = target;
    }
  }

  ngOnDestroy(): void {
    this.stop$.next(null);
  }

  private calculateBarColor(actual: number, target: number): string {
    let color;
    if (actual >= target) {
      color = this.colorSuccess;
    } else if (actual >= (target * 0.9)) {
      color = this.colorWarning;
    } else {
      color = this.colorFail;
    }
    return color;
  }

  pollData(): void {
    if (!this.authService.currentUser.applicationUser
      && EnterpriseRoleProviderService.permissionsLoaded
      && !EnterpriseRoleProviderService.hasRequiredClaimByName('home-dashboard'))
    {

      this.router.navigate(['home/info/show']);
      return;
    }

    if (EnterpriseRoleProviderService.rolesLoaded && EnterpriseRoleProviderService.hasRequiredClaimByName('home-dashboard')) {
      firstValueFrom(this.manufacturingStatisticService.getManufacturingStatistics())
        .then(response => {

          /**
           * Quickfix to prevent to many wrong cost-centers being shown with null values.
           */
          if (!this.zeroValueEnabled) {
            response = response.filter(metrics => metrics.status === 'Active');
          }

          response.forEach(metric => {
            metric.actualValue = metric.actualValue ? metric.actualValue : 0;
            metric.actualValueV2 = metric.actualValueV2 ? metric.actualValueV2 : 0;
            metric.actualValueBigScreen = metric.actualValueBigScreen ?  metric.actualValueBigScreen : 0;


            metric.targetValue = metric.targetValue ? metric.targetValue : 0;
            metric.shiftTargetValue = metric.shiftTargetValue ? metric.shiftTargetValue : 0;
          })

          this.statistics = response;
          this.updateCharts(response);
          this.initState();
          this.chartUpdateEvent.next(null);
        })
        .catch(error => {
          LoggingService.logError(LoggingSource.DASHBOARD, 'An error occurred while retrieving manufacturing statistics.', error)
        })
        .finally(() => {
          this.showSpinner = false;
        });
    }
  }

  private startPolling() {
    interval(5000)
    .pipe(
      takeUntil(this.stop$),
    )
    .subscribe(() => this.pollData());
  }

  private initState(): void {
    if (this.zoomEnabled) {
      this.zoomedChart = this.actualTargetCharts.filter(value => value.groupID === this.zoomedChartId)[0];
    }
  }

  private updateCharts(metrics: ManufacturingStatisticResponse[]) {
    metrics.sort((metric1, metric2) => {
      if (metric1.costCenter > metric2.costCenter) {
        return 1;
      } else {
        return -1;
      }
    });

    const touched: Array<MetricIdentifier> = new Array<MetricIdentifier>();
    metrics.forEach(metric => {
      let chart = this.actualTargetCharts.find(i => i.groupID === metric.productCenter);

      if (chart === undefined) {
        chart = this.createChart(metric.productCenter);
        chart.sortIndicator = new SortIndicator(metric.productCenterName, +metric.productCenter);
        if (metric.productCenterName !== '' && metric.productCenterName !== undefined) {
          chart.groupName = metric.productCenterName;
        }
        this.actualTargetCharts.push(chart);
      }

      chart.isHidden = this.hiddenCharts.indexOf(chart.groupID) !== -1;
      const labelIndex = chart.barChartLabels.indexOf(metric.costCenter);
      const targetValue = (metric.targetValueV2 !== null && metric.targetValueV2 !== undefined) ? metric.targetValueV2 : metric.targetValue;
      if (this.zeroValueEnabled) {
        touched.push(new MetricIdentifier(metric.productCenter, metric.costCenter));
        this.createOrUpdateChartMetric(labelIndex, metric.costCenter, metric.actualValue, targetValue, chart);
      } else if (metric.actualValue !== 0 || targetValue !== 0) {
        touched.push(new MetricIdentifier(metric.productCenter, metric.costCenter));
        this.createOrUpdateChartMetric(labelIndex, metric.costCenter, metric.actualValue, targetValue, chart);
      }
    });
    this.actualTargetCharts.sort((a, b) => {
      if (a.sortIndicator.name && b.sortIndicator.name) {
        return a.sortIndicator.name > b.sortIndicator.name ? 1 : -1;
      } else if (a.sortIndicator.name && !b.sortIndicator.name) {
        return -1;
      } else if (!a.sortIndicator.name && b.sortIndicator.name) {
        return 1;
      } else {
        return (a.sortIndicator.productCenter > b.sortIndicator.productCenter ? 1 : -1);
      }
    });
    // remove all untouched metrics and empty charts
    for (let i = this.actualTargetCharts.length - 1; i > -1; i-- ) {
      const chart: ActualTargetChart = this.actualTargetCharts[i];
      for (let j = chart.barChartLabels.length - 1; j > -1; j--) {
        const index = touched.findIndex(entry => entry.chart === chart.groupID
          && entry.bar === chart.barChartLabels[j] );
        if (index < 0 ) {
          this.removeMetric(chart, j);
        }
      }

      if (chart.barChartLabels.length === 0) {
        const index = this.actualTargetCharts.indexOf(chart);
        this.actualTargetCharts.splice(index, 1);
      }
    }
  }
  private removeMetric(chart: ActualTargetChart, barIndex: number) {
    chart.barChartData.datasets[0].data.splice(barIndex, 1);
    chart.barChartData.datasets[1].data.splice(barIndex, 1);
    chart.barChartLabels.splice(barIndex, 1);
    chart.barChartData.labels.splice(barIndex, 1);
    (chart.barChartData.datasets[0].backgroundColor as Array<Color>).splice(barIndex, 1);
    (chart.barChartData.datasets[0].borderColor as Array<Color>).splice(barIndex, 1);
    (chart.barChartData.datasets[0].hoverBackgroundColor as Array<Color>).splice(barIndex, 1);
    (chart.barChartData.datasets[0].hoverBorderColor as Array<Color>).splice(barIndex, 1);
  }
  private createChart(groupID: string): ActualTargetChart {
    let chart = new ActualTargetChart(groupID, chartOptions);
    chart = this.initChartData(chart);

    return chart;
  }

  private initChartData(chart: ActualTargetChart): ActualTargetChart {
    chart.barChartData = {
      datasets: (new Array<ChartDataset<'bar', Array<number>>>()), labels: new Array<string>()
    };

    chart.barChartData.datasets.push({
      data: new Array<number>(),
      backgroundColor: new Array<Color>(),
      borderColor: new Array<Color>(),
      hoverBackgroundColor: new Array<Color>(),
      hoverBorderColor: new Array<Color>(),
      label: 'IST',
      borderWidth: 0,
      borderSkipped: false});
    chart.barChartData.datasets.push({
      data: new Array<number>(),
      label: 'SOLL'});

    return this.applyConfigToChart(chart);
  }
  applyConfigToChart(chart: ActualTargetChart): ActualTargetChart {
    const chartConfig: {
      overlap: boolean,
      backgroundColor: string,
      borderColor: string,
      borderSkipped: any,
      borderWidth: any,
      barPercentage: number,
      sharedTooltip: boolean
    } = {
      overlap: false,
      backgroundColor: this.targetColor,
      borderColor: this.targetColor,
      borderSkipped: false,
      borderWidth: 0,
      barPercentage: .9,
      sharedTooltip: false
    };
    switch (this.chartStyle) {
      case BarChartStyleTheme.FillingBar:
        chartConfig.overlap = true;
        chartConfig.backgroundColor = 'rgb(0,0,0,0)';
        chartConfig.borderColor = this.targetColor;
        chartConfig.borderSkipped = 'bottom';
        chartConfig.borderWidth = 2;
        chartConfig.sharedTooltip = true;
        break;
      case BarChartStyleTheme.Dash:
        chartConfig.overlap = true;
        chartConfig.backgroundColor = 'rgb(0,0,0,0)';
        chartConfig.borderColor = this.targetColor;
        chartConfig.borderSkipped = false;
        chartConfig.borderWidth = { top:2, right:0, bottom:0, left:0 };
        chartConfig.barPercentage = .95;
        chartConfig.sharedTooltip = true;
        break;
      case BarChartStyleTheme.TwoBars:
      default:
        break;
    }
    const newChart = {...chart};
    newChart.barChartOptions = {...newChart.barChartOptions,
      scales: {...newChart.barChartOptions.scales, x: chartConfig.overlap ? {stacked: true} : {stacked: false} } };
    newChart.barChartData.datasets[1] = {
      data: newChart.barChartData.datasets[1].data,
      backgroundColor: chartConfig.backgroundColor,
      borderColor: chartConfig.borderColor,
      hoverBorderColor: chartConfig.borderColor,
      hoverBackgroundColor: chartConfig.backgroundColor,
      borderWidth: chartConfig.borderWidth,
      borderSkipped: chartConfig.borderSkipped,
      barPercentage: chartConfig.barPercentage,
      label: 'SOLL'};
      if (chartConfig.sharedTooltip) {
        newChart.barChartOptions.plugins.tooltip = { callbacks: {
          label: ((ctx) => {
              return ctx.chart.data.datasets[1].data[ctx.dataIndex] + ' / ' + ctx.chart.data.datasets[0].data[ctx.dataIndex];
            }),
        },
          displayColors: false };
      } else {
        newChart.barChartOptions.plugins.tooltip = undefined;
      }

    return newChart;
  }
  updateColorConfig() {
    switch (this.colorTheme) {
      case BarChartColorTheme.Dim:
        this.colorFail =  '#dc3545';
        this.colorWarning = '#ffc107';
        this.colorFailBorder = this.colorFail;
        this.colorWarningBorder = this.colorFail;
        this.colorSuccess = 'green'
        this.colorSuccessBorder = this.colorSuccess;
        this.targetColor = '#343a40';
        break;
      case BarChartColorTheme.Glossy:
      default:
        this.colorFail = getComputedStyle(document.documentElement)
          .getPropertyValue('--VW-AG-lights-red');
        this.colorWarning = getComputedStyle(document.documentElement)
          .getPropertyValue('--VW-AG-lights-yellow');;
        this.colorFailBorder = this.colorFail;
        this.colorWarningBorder = this.colorWarning;
        this.colorSuccess = getComputedStyle(document.documentElement)
          .getPropertyValue('--VW-AG-lights-green');
        this.colorSuccessBorder = this.colorSuccess;
        this.targetColor = 'black';

        break;
    }
  }

  updateColors() {
    this.updateColorConfig();

    this.actualTargetCharts.forEach(chart => {
      const chartData = {...chart.barChartData};
      const colors = chart.barChartData.datasets[0].data.map((val, i) =>
        this.calculateBarColor(val, chart.barChartData.datasets[1].data[i]));

      chartData.datasets[0].borderColor = colors;
      chartData.datasets[0].backgroundColor = colors;
      chartData.datasets[0].hoverBackgroundColor = colors;
      chartData.datasets[0].hoverBorderColor = colors;
      chartData.datasets[1].borderColor = this.targetColor;
      chartData.datasets[1].hoverBorderColor = this.targetColor;
      chart.barChartData = chartData;
    });
    this.chartUpdateEvent.next(null);
  }

  updateChartConfig() {
    this.actualTargetCharts = this.actualTargetCharts.map(chart => this.applyConfigToChart(chart));
    if (this.zoomedChart) {
      const id = this.zoomedChart.groupID;
      this.zoomedChart = this.actualTargetCharts.find(chart => chart.groupID === id);
      if (!this.zoomedChart) {
        this.zoomEnabled = false;
      }
    }
    this.chartUpdateEvent.next(null);
  }
}
class MetricIdentifier {
  private chartName: string;
  private barName: string;

  constructor(chart: string, bar: string ) {
    this.chartName = chart;
    this.barName = bar;
  }

  get chart(): string {
    return this.chartName;
  }
  get bar(): string {
    return this.barName;
  }
}

