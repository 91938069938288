<div style="position: fixed; top: 0; right: 0; bottom: -50px; width: 25%; min-width: 1000px; border-left: solid; border-left-width: 1px; background-color: #fafafa; overflow: hidden;">
  <div class="pl-3 pr-3" style="height: 100%; padding-top: 100px; padding-bottom: 120px; overflow-y: scroll;">
    <div class="row pr-3 pb-3">
      <div class="col text-left" style="font-size: larger">
        <i class="fas fa-lightbulb" style="color: darkgoldenrod"></i>
        Application Logs
        <div *ngIf="LoggingService.log.length > 0" style="font-size: smaller"><op-count-badge [count]="LoggingService.log.length"></op-count-badge> Lines</div>
      </div>
      <div class="col text-right">
        <div class="pointer d-inline pr-3" (click)="onShareLog()"><i class="fas fa-share-alt"></i></div>
        <div class="pointer d-inline pr-3" (click)="LoggingService.deleteLogs()"><i class="fas fa-trash-alt"></i></div>
        <div class="pointer d-inline" (click)="LoggingService.export()"><i class="fas fa-file-export"></i></div>
      </div>
    </div>
    <op-message *ngIf="showMessageComponent"
                [title]="'logging.share.share-log' | translate"
                [addNoteEnabled]="true"
                [attachments]="[logExport]"
                [defaultMessageContent]="'logging.share.default-message' | translate"
                (canceled)="onSharingCanceled()"></op-message>
    <div>
      <tr class="row">
        <td class="col-sm align-middle cpp-input-search">
      <op-guided-filter-input [source]="searchAliasMappings"
                              (criteriaUpdate)="setFilterValues($event)"
                              (triggerSearch)="onSearch($event)"
                              [searching]="searching">
      </op-guided-filter-input>
      </td>
      </tr>
    </div>
    <div class="row pl-3 pr-3 pt-3 small">
      <div class="cpp-column-fixed-width-25"></div>
      <div class="cpp-column-fixed-date">
        Time
      </div>
      <div class="cpp-column-fixed-width-150">
        Source
      </div>
      <div class="col">
        Message
      </div>
    </div>
    <div style="display: flex; flex-direction: column-reverse;">
      <ng-container *ngFor=" let log of LoggingService.log">
        <div class="row pl-3 pr-3 small" [ngClass]="NewElementCheckerService.getClass(log.eventTimeString)">
          <div class="cpp-column-fixed-width-25">
            <ng-container *ngIf="log.level === LogLevel.TRACE">
              <i class="fas fa-train" style="color: purple" title="Trace"></i>
            </ng-container>
            <ng-container *ngIf="log.level === LogLevel.DEBUG">
              <i class="fas fa-question-circle" style="color: #343a40" title="Debug"></i>
            </ng-container>
            <ng-container *ngIf="log.level === LogLevel.INFO">
              <i class="fas fa-info-circle" style="color: blue" title="Info"></i>
            </ng-container>
            <ng-container *ngIf="log.level === LogLevel.WARNING">
              <i class="fas fa-exclamation-triangle" style="color: darkgoldenrod" title="Warning" [tooltip]="errorToolTipTemplate"></i>
            </ng-container>
            <ng-container *ngIf="log.level === LogLevel.ERROR">
              <i class="fas fa-times pointer" style="color: darkred" [tooltip]="errorToolTipTemplate"></i>
            </ng-container>


            <ng-template #errorToolTipTemplate>
              <div class="tooltip-350">
              <ng-container *ngIf="log.error">
                {{ log.error?.name }}
                <br>
                <br>
                {{ log.error?.message }}
              </ng-container>
                <ng-container *ngIf="!log.error">
                 No additional data.
                </ng-container>
              </div>
            </ng-template>
          </div>
          <div class="cpp-column-fixed-date text-monospace">
            {{ (log.eventTimeString | date:'dd.MM.yyyy HH:mm:ss.SSS')}}
          </div>
          <div class="cpp-column-fixed-width-150 text-overflow-ellipses">
            {{ log.source }}
          </div>
          <div class="col">
            {{ log.message }} <ng-container *ngIf="log.duplicateCounter > 0">({{ log.duplicateCounter }})</ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
