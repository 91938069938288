<op-detail-section-wrapper
  [title]="'component-profile.logistics.title' | translate"
  [description]="'component-profile.logistics.description' | translate"
  [contentTemplate]="contentTemplateRef"
  [hasContent]="stack != undefined"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <ng-container *ngIf="stack != undefined">
    <ng-container *ngIf="stack.stackIdentifierType">
      <tr class="row">
        <td class="col-md-4 pl-0 pt-2 pb-2" style="font-weight: 500; border-top: none">
          {{ 'component-profile.stackIdentifierType' | translate }}
        </td>
        <td class="col-md-8  pt-2" style="border-top: none">
          <op-copy-to-clipboard-icon style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="stack.stackIdentifierType"></op-copy-to-clipboard-icon>{{ stack.stackIdentifierType }}
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="stack.stackIdentifierType">
      <tr class="row">
        <td class="col-md-4 pl-0  pb-2" style="font-weight: 500; border-top: none">
        </td>
        <td class="col-md-8 pb-2" style="border-top: none">
          <op-entity-label [height]="'60'" [width]="'120'" [value]="stack.stackIdentifier" [labelType]="stack.stackIdentifierType.toLowerCase()"></op-entity-label>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="stack.stackIdentifier">
      <tr class="row">
        <td class="col-md-4 pl-0  pt-2 pb-2" style="font-weight: 500; border-top: none">
          {{ 'component-profile.stackIdentifier' | translate }}
        </td>
        <td class="col-md-8 pt-2 pb-2" style="border-top: none">
          <a *ngIf="stack.stackIdentifier" [routerLink]="['/tracking/' + 'stacks/' + stack.stackIdentifier]" target="_blank">
            <i class="fas fa-link"></i>
          </a> {{ stack.stackIdentifier }}
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="stack.stackedAt">
      <tr class="row">
        <td class="col-md-4 pl-0  pt-2  pb-2" style="font-weight: 500; border-top: none">
          {{ 'component-profile.stackingDate' | translate }}
        </td>
        <td class="col-md-8 pt-2  pb-2" style="border-top: none">
          <op-datetime-show [dateTime]="stack.stackedAt"></op-datetime-show>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="stack.transportDestination">
      <tr class="row">
        <td class="col-md-4 pl-0  pt-2 pb-2" style="font-weight: 500; border-top: none">
          {{ 'component-profile.stackShippingTarget' | translate }}
        </td>
        <td class="col-md-8 pt-2 pb-2" style="border-top: none">
          {{ stack.transportDestination }}
        </td>
      </tr>
      </ng-container>
      <ng-container *ngIf="stack.transportUnit">
      <tr class="row">
        <td class="col-md-4 pl-0  pt-2 pb-2" style="font-weight: 500; border-top: none">
          {{ 'component-profile.stackShippingCarrier' | translate }}
        </td>
        <td class="col-md-8 pt-2 pb-2" style="border-top: none">
          {{ stack.transportUnit }}
        </td>
      </tr>
      </ng-container>
      <ng-container *ngIf="stack.dispatchedAt">
      <tr class="row">
        <td class="col-md-4 pl-0 pt-2 pb-2" style="font-weight: 500; border-top: none">
          {{ 'component-profile.stackShippingDate' | translate }}
        </td>
        <td class="col-md-8 pt-2 pb-2" style="border-top: none">
          <op-datetime-show [dateTime]="stack.dispatchedAt"></op-datetime-show>
        </td>
      </tr>
    </ng-container>



    <ng-container *ngIf="stack.deliveryNote">
      <tr class="row">
        <td class="col-md-4 pl-0  pt-2 pb-2" style="font-weight: 500; border-top: none">
          {{ 'component-profile.deliveryNote' | translate }}
        </td>
        <td class="col-md-8 pt-2 pb-2" style="border-top: none">
          {{ stack.deliveryNote }}
        </td>
      </tr>
    </ng-container>

    <ng-container *ngIf="stack.costCenter">
      <tr class="row">
        <td class="col-md-4 pl-0  pt-2 pb-2" style="font-weight: 500; border-top: none">
          {{ 'component-profile.costCenter' | translate }}
        </td>
        <td class="col-md-8 pt-2 pb-2" style="border-top: none">
          {{ stack.costCenter }}
        </td>
      </tr>
    </ng-container>

    <ng-container *ngIf="stack.customer">
      <tr class="row">
        <td class="col-md-4 pl-0  pt-2 pb-2" style="font-weight: 500; border-top: none">
          {{ 'component-profile.customer' | translate }}
        </td>
        <td class="col-md-8 pt-2 pb-2" style="border-top: none">
          {{ stack.customer }}
        </td>
      </tr>
    </ng-container>

    <ng-container *ngIf="stack.storageArea">
      <tr class="row">
        <td class="col-md-4 pl-0  pt-2 pb-2" style="font-weight: 500; border-top: none">
          {{ 'component-profile.storageArea' | translate }}
        </td>
        <td class="col-md-8 pt-2 pb-2" style="border-top: none">
          {{ stack.storageArea }}
        </td>
      </tr>
    </ng-container>

    <ng-container *ngIf="stack.storageGroup">
      <tr class="row">
        <td class="col-md-4 pl-0  pt-2 pb-2" style="font-weight: 500; border-top: none">
          {{ 'component-profile.storageGroup' | translate }}
        </td>
        <td class="col-md-8 pt-2 pb-2" style="border-top: none">
          {{ stack.storageGroup }}
        </td>
      </tr>
    </ng-container>

    <ng-container *ngIf="stack.storageZone">
      <tr class="row">
        <td class="col-md-4 pl-0  pt-2 pb-2" style="font-weight: 500; border-top: none">
          {{ 'component-profile.storageZone' | translate }}
        </td>
        <td class="col-md-8 pt-2 pb-2" style="border-top: none">
          {{ stack.storageZone }}
        </td>
      </tr>
    </ng-container>

    <ng-container *ngIf="stack.storagePlace">
      <tr class="row">
        <td class="col-md-4 pl-0  pt-2 pb-2" style="font-weight: 500; border-top: none">
          {{ 'component-profile.storagePlace' | translate }}
        </td>
        <td class="col-md-8 pt-2 pb-2" style="border-top: none">
          {{ stack.storagePlace }}
        </td>
      </tr>
    </ng-container>
  </ng-container>
</ng-template>
