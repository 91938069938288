import {Component, Input, OnInit} from '@angular/core';
import {AssemblyControllerService, AssemblyResponse, TargetPartCreateRequest} from '@cstx/volkswagen-mqs-part-service-client';
import {ComponentApprovalPartSet} from './ComponentApprovalPartSet';
import {InstalledPartApprovalRate} from './InstalledPartApprovalRate';
import TargetPartTypeEnum = TargetPartCreateRequest.TargetPartTypeEnum;
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

@Component({
  selector: 'op-component-approval-v2',
  templateUrl: './component-approval-v2.component.html',
  styleUrls: ['./component-approval-v2.component.scss']
})
export class ComponentApprovalV2Component implements OnInit {
  @Input() componentId: string;
  @Input() selfLink: string;
  @Input() contentContainerId: string;

  assembly: AssemblyResponse;
  approvalSets = Array<ComponentApprovalPartSet>();
  loading = false;

  public viewSizeSM: boolean;

  constructor(private breakpointObserver: BreakpointObserver,
              private assemblyControllerService: AssemblyControllerService) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.viewSizeSM = true;
        } else {
          this.viewSizeSM = false;
        }
      });

    this.getAssembly();
  }

  getAssembly() {
    if (this.componentId === undefined) {
      return;
    }
    this.loading = true;
    this.assemblyControllerService.getAssemblyById({
      id: this.componentId
    }).toPromise()
      .then(
        response => {
          this.assembly = response;
          this.generateComponentApprovalList();
        })
      .catch( r => null)
      .finally(() => this.loading = false);
  }

  private generateComponentApprovalList() {
    this.assembly.assemblyParts.forEach(p => {
      const c = new ComponentApprovalPartSet();
      c.ActualPartNumber = p.part.partNumber;
      c.ActualPartType = p.part.partType;

      this.assembly.targetParts.forEach(t => {
        if (p.part.partType === t.partType) {
          if (t.targetPartType === TargetPartTypeEnum.Primary) {
            c.PrimaryPartNumber = t.partNumber;
            c.InstalledPartApprovalRate = p.part.partNumber === t.partNumber ? InstalledPartApprovalRate.PRIMARY_PART : c.InstalledPartApprovalRate;
          } else {
            c.AlternativePartNumbers.push(t.partNumber);
            c.InstalledPartApprovalRate = p.part.partNumber === t.partNumber ? InstalledPartApprovalRate.ALTERNATIVE_PART : c.InstalledPartApprovalRate;
          }
        }
      });
      this.approvalSets.push(c);
    });
  }

  fillMissingChars(value: string): string {
    const targetLength = 12;
    const actualLength = value?.length;

    const diff = targetLength - actualLength;

    if (diff >= 0) {
      let i = 0;
      for (i; i <= diff; i++) {
        value = value + '_';
      }
    }

    return value;
  }

  isSelected(a: string, b: string) {
    return a?.replace('_', '') === b?.replace('_', '');
  }
}
