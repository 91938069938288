import { Injectable } from "@angular/core";
import { ParseDfqService } from '@cstx/volkswagen-mqs-parsing-service-client';

@Injectable({
  providedIn: 'root'
})
export class DfqParserService {
  constructor(private parseDFQService: ParseDfqService) {}

  parseDFQ(dfq: Blob): Promise<JSON> {
    return new Promise<JSON>((resolve, reject) => {
      if (!dfq) {
        reject(new Error("No DFQ file provided"));
        return;
      }

      this.parseDFQService.parseDfqPost({ file: dfq }, 'body', true)
        .toPromise()
        .then(response => {
          try {
            resolve(response);
          } catch (error) {
            reject(new Error("Error parsing DFQ file: Invalid JSON format"));
          }
        })
        .catch(error => {
          reject(new Error("Error parsing DFQ file: " + error));
        });
    });
  }
}
