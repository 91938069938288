import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {
  CreateUserRequestParams, SubscriptionsControllerService,
  UpdateUserRequestParams,
  UserControllerService
} from '@cstx/volkswagen-mqs-notification-service-client';
import {WhatsUpSubscription} from './models/whatsUpSubscription';
import {UserData} from './models/userData';
import {AuthService} from '../../core/services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../core/services/config.service';
import {EnterpriseRoleProviderService} from "../../core/services/enterprise-role-provider.service";

const dummyPhone = '+4915111223344';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: UserData;
  isApplicationUser: boolean;
  isAdminUser: boolean;
  claims: any;

  whatsUpUserCreated = false;
  whatsUpUserCreateLoading = false;

  constructor(private oAuthService: OAuthService,
              private authService: AuthService,
              private configService: ConfigService,
              private translate: TranslateService,
              private userControllerService: UserControllerService,
              private subscriptionsControllerService: SubscriptionsControllerService) {
  }

  getUserData(): Promise<void> {
    const userdata: UserData = new UserData();

    if (!this.authService.isApplicationUser) {
      this.claims = this.oAuthService.getIdentityClaims();
      // tslint:disable:no-string-literal
      userdata.firstName = this.claims['given_name'] ? this.claims['given_name'] : '';
      userdata.lastName = this.claims['family_name'] ? this.claims['family_name'] : '';
      userdata.groups = this.claims['groups'] ? this.claims['groups'] as Array<string> : [];
      userdata.registrationDate = this.claims['auth_time'] ?
        new Date(parseInt(this.claims['auth_time'], 10) * 1000) : undefined;
      userdata.email = this.claims['email'] ? this.claims['email'] : '';
      userdata.phone = '';
      this.user = userdata;

      this.isAdminUser = (this.user?.groups?.indexOf(this.configService.getAdminUserRole()) > -1)

      if (!EnterpriseRoleProviderService.effectiveEnterpriseRoles
        || EnterpriseRoleProviderService.effectiveEnterpriseRoles.length <= 0) {
        return;
      } else {
        return this.initWhatsUp().then(() => {});
      }
    } else {
      userdata.firstName = 'System';
      userdata.lastName = 'User';
      userdata.groups = [ 'INTERNAL' ];
      userdata.registrationDate = undefined;
      userdata.email = this.authService.currentUser?.email;
      userdata.phone = '';
      this.user = userdata;
      this.isApplicationUser = true;
      this.isAdminUser = false;
    }

  }

  getUserEmail(): string {
    const claims = this.oAuthService.getIdentityClaims();
    return this.authService.isApplicationUser ? this.authService.currentUser.email : claims ? (claims['email'] ? claims['email'] : '') : '';
  }

  private initWhatsUp(): Promise<void | WhatsUpSubscription> {
    if (!this.whatsUpUserCreated) {
      return this.createWhatsUpUser().then(() => this.refreshWhatsUpUserData()).then(() => this.refreshSubscriptions());
    }
    return this.refreshWhatsUpUserData().then(() => this.refreshSubscriptions());


  }

  private createWhatsUpUser(): Promise<void> {
    this.whatsUpUserCreateLoading = true;
    const createUserRequest: CreateUserRequestParams = {
      userCreateRequest: {
        userId: this.claims.preferred_username ? this.claims.preferred_username : undefined,
        lastName: this.user.lastName,
        firstName: this.user.firstName,
        emailAddress: this.user.email,
        phone: dummyPhone,
        mobilePhone: dummyPhone,
        department: this.claims.department ? this.claims.department : 'VW',
        costCenter: '0000'
      }
    };

    return this.userControllerService.createUser(createUserRequest).toPromise()
    .then(response => {
      this.whatsUpUserCreated = true;
    })
    .finally(() => this.whatsUpUserCreateLoading = false);
  }

  private refreshWhatsUpUserData(): Promise<void> {
    return this.userControllerService.getUser().toPromise().then( (userdata) => {
      if (userdata.notificationTypes) {
        this.user.notificationMethods = {
          sms: userdata.notificationTypes.indexOf('SMS') > -1,
          app: userdata.notificationTypes.indexOf('PUSH') > -1,
          email: userdata.notificationTypes.indexOf('EMAIL') > -1,
          portal: false,
        };
      } else {
        this.user.notificationMethods = {
          sms: false,
          app: false,
          email: false,
          portal: false
        };
      }
      if (userdata.mobilePhone !== dummyPhone) {
        this.user.phone = userdata.mobilePhone;
      } else {
        this.user.phone = '';
      }
    });
  }

  public updateWhatsUpUserData(): Promise<void> {
    const request: UpdateUserRequestParams = {
      userUpdateRequest: {
        mobilePhone: this.user.phone && this.user.phone !== dummyPhone ? this.user.phone : dummyPhone,
        notificationTypes: [],
      }
    };
    if (this.user.notificationMethods.sms) {
      request.userUpdateRequest.notificationTypes.push('SMS');
    }
    if (this.user.notificationMethods.email) {
      request.userUpdateRequest.notificationTypes.push('EMAIL');
    }
    if (this.user.notificationMethods.app) {
      request.userUpdateRequest.notificationTypes.push('PUSH');
    }
    return this.userControllerService.updateUser(request).toPromise()
      .then(() => this.refreshWhatsUpUserData());
  }

  public refreshSubscriptions(): Promise<void | WhatsUpSubscription> {
    return Promise.all([
      this.subscriptionsControllerService.getSubscriptions({}).toPromise(),
      this.subscriptionsControllerService.getSubscriptions({subscribedonly: true}).toPromise()])
    .then(response => {
      if (response[0] && response[1]) {
        this.user.costCenters.clear();
        this.user.subscribableChannels = new Set<WhatsUpSubscription>(response[0]
        .filter(entry => !entry.subscribed)
        .map(entry => {
          this.user.costCenters.add(entry.costCenter);
          return { channelLabel: this.tryTranslate(entry.channel), channel: entry.channel, costCenter: entry.costCenter};
        }));
        this.user.subscribedChannels = new Set<WhatsUpSubscription>(response[1].map(entry => {
          this.user.costCenters.add(entry.costCenter);
          return { channelLabel: this.tryTranslate(entry.channel), channel: entry.channel, costCenter: entry.costCenter};
        }));
      }
    });
  }

  // tslint:disable-next-line:max-line-length
  public async processSubscriptions(subscribeTo: Set<WhatsUpSubscription>, unsubscribeFrom: Set<WhatsUpSubscription>): Promise<void | WhatsUpSubscription> {
    const subTo = Array.from(subscribeTo);
    const subToLength = subTo.length;
    let i: number;
    for (i = 0; i < subToLength; i++) {
      const entry = subTo[i];
      await this.subscriptionsControllerService.putSubscriptions({
        costcenter: entry.costCenter, channel: entry.channel
      }).toPromise();
    }
    const unsubFrom = Array.from(unsubscribeFrom);
    const unsubFromLength = unsubFrom.length;
    for (i = 0; i < unsubFromLength; i++) {
      const entry = unsubFrom[i];
      await this.subscriptionsControllerService.deleteSubscriptions({
        costcenter: entry.costCenter, channel: entry.channel
      }).toPromise();
    }
    await this.refreshSubscriptions();
  }

  private tryTranslate(channel: string): string {
    const translated = this.translate.instant('users.subscriptions.channels.' + channel.toLowerCase());

    if (translated === 'users.subscriptions.channels.' + channel.toLowerCase()) {
      return channel;
    }

    return translated;
  }
}



