import {Info} from '../info';
import {UseCaseFile} from '../../../../shared/components-merged/usecase-file-explorer/useCaseFile';
import {FilePreview} from '../../../../shared/components-merged/usecase-file-explorer/filePreview';
import {PreviewRequest} from '../../../../shared/components-merged/usecase-file-explorer/previewRequest';
import {PreviewService} from '../../../../shared/components-merged/usecase-file-explorer/preview/preview.service';

export class EmployeeInfo {
  public title: string;
  public file: UseCaseFile;
  public filePreview: FilePreview;
  public info: Info;

  public infoId: string;
  public published: boolean;
  public generatePdf: boolean;

  public selected: boolean;

  constructor(title?: string,
              file?: UseCaseFile,
              infoId?: string,
              published: boolean = false,
              generatePdf: boolean = false) {

    this.title = title;
    this.file = file;
    this.infoId = infoId;
    this.published = published;
    this.generatePdf = generatePdf;
  }

  public getPreviewRequest(): PreviewRequest {
    const previewRequest = new PreviewRequest();
    previewRequest.fileName = this.file?.Name ? this.file.Name : 'no-name.pdf' ;
    previewRequest.s3Key = this.file?.s3Key;
    previewRequest.createdAt = this.file?.CreatedAt;
    previewRequest.size = this.file?.Size ? this.file.Size : '0';

    return previewRequest;
  }

  public async setFromStopNotificationInfo(info: Info, stopSignPdf: Blob) {
    this.infoId = info.id;
    this.info = info;
    this.title = info.title;
    this.published = info.isPublished;
    this.generatePdf = true;
    this.filePreview = await PreviewService.createFilePreviewFromBlob(info.title + '.pdf', stopSignPdf);
  }
}
