import {EventEmitter, Injectable} from '@angular/core';
import {Stack} from '../models/tracking/stack';
import {
  ComponentResponse,
  ComponentType as ComponentTypeEnum,
  EventType,
  StackControllerService,
  StackControllerV2Service,
  StackResponse,
  TrackingEventControllerService,
  TrackingEventControllerV2Service
} from '@cstx/volkswagen-mqs-tracking-service-client';
import {StackFilter} from '../models/tracking/stackFilter';
import {LoggingService} from '../../core/logging/logging.service';
import {Component} from '../models/tracking/component';
import {ComponentType} from '../models/tracking/componentType';
import {ComponentState} from '../models/tracking/componentState';
import {TrackingEventFilter} from '../models/tracking/trackingEventFilter';
import {TrackingEvent} from '../models/tracking/trackingEvent';
import {EngineResponse} from '@cstx/volkswagen-mqs-engine-service-client';
import {PartResponse} from '@cstx/volkswagen-mqs-part-service-client';
import {firstValueFrom, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {ErrorHandler} from './error-handler/error-handler';
import {ReferenceType} from '@cstx/volkswagen-mqs-tracking-service-client/model/referenceType';
import {ComponentType as ComponentTypeRef} from '@cstx/volkswagen-mqs-tracking-service-client/model/componentType';
import {PartService} from './backend/part.service';
import {EngineService} from './backend/engine.service';
import {LoggingSource} from '../../core/logging/loggingSource';


@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  public events: Array<TrackingEvent>;
  public stacks: Array<Stack>;
  showSpinner = false;
  showStacksSpinner = false;
  requestPending: boolean;
  requestStacksPending: boolean;
  loadingFinished = new EventEmitter<boolean>();
  loadingStacksFinished = new EventEmitter<boolean>();
  trackingEventFilter = new TrackingEventFilter();
  stackFilter = new StackFilter();
  private pendingGetEventsRequests$ = new Subject<void>();
  private pendingGetStacksRequests$ = new Subject<void>();

  constructor(private stackService: StackControllerService, private trackingEventService: TrackingEventControllerService,
              private stackV2Service: StackControllerV2Service, private trackingEventV2Service: TrackingEventControllerV2Service,
              private engineBackendService: EngineService, private partBackendService: PartService) {
  }

  private static getComponentType(type: ComponentTypeEnum): ComponentType {
    if (type === 'ENGINE') {
      return ComponentType.Engine;
    }

    if (type === 'PART') {
      return ComponentType.Part;
    }

    return ComponentType.Unknown;
  }

  public getStacks(cancelPending: boolean, reset: boolean, next = false) {
    if (cancelPending) {
      LoggingService.logDebug(LoggingSource.TRACKING_SERVICE, 'Canceling running getStacks events.')
      this.cancelGetStacks();
    } else if (this.requestStacksPending) {
      LoggingService.logDebug(LoggingSource.TRACKING_SERVICE, 'Aborting request. There is a request already pending to getStacks')
      return;
    }
    this.showStacksSpinner = true;
    if (reset) {
      this.stackFilter.currentPageIndex = 0;
    }
    if (next) {
      this.stackFilter.currentPageIndex++;
    }

    this.requestStacksPending = true;
    this.stackV2Service.getStacksV2(this.stackFilter.getAllParams())
    .pipe(takeUntil(this.onCancelPendingStacksRequests()))
    .toPromise()
    .then(result => {
      this.stackFilter.slice = {
        first: result.first,
        last: result.last,
        pageNumber: this.stackFilter.currentPageIndex,
        empty: result.empty,
        size: result.size
      };
      const stacks = result.content.map(response => this.getStackFromStackResponse(response));
      if (reset) {
        this.stacks = stacks;
      } else {
        this.stacks = this.stacks.concat(stacks);
      }
      this.loadingStacksFinished.emit(true);
      this.requestStacksPending = false;
    })
    .finally(() => this.showStacksSpinner = false);
  }

  public getStackById(stackIdentifier: string): Promise<Stack> {
    return new Promise<Stack>((resolve, reject) => {
      this.stackService.getStack({stackIdentifier}).toPromise()
      .then(result => {
        resolve(this.getStackFromStackResponse(result));
      })
      .catch((error) => reject(error));
    });
  }

  public getEvents(cancelPending: boolean, reset: boolean, next = false) {
    if (cancelPending) {
      LoggingService.logDebug(LoggingSource.TRACKING_SERVICE, 'Canceling running getEvents events.');
      this.cancelGetEvents();
    } else if (this.requestPending) {
      LoggingService.logDebug(LoggingSource.TRACKING_SERVICE, 'Aborting request. There is a request already pending to getEvents');
      return;
    }
    this.showSpinner = true;
    if (reset) {
      this.trackingEventFilter.currentPageIndex = 0;
    }
    if (next) {
      this.trackingEventFilter.currentPageIndex++;
    }

    this.requestPending = true;
    this.trackingEventV2Service.getTrackingEventsV2(this.trackingEventFilter.getAllParams())
    .pipe(takeUntil(this.onCancelPendingRequests()))
    .toPromise()
    .then(result => {
      this.trackingEventFilter.slice = {
        first: result.first,
        last: result.last,
        pageNumber: this.trackingEventFilter.currentPageIndex,
        empty: result.empty,
        size: result.size
      };
      const res = result.content.map(eventResponse => {
        const event = new TrackingEvent();
        event.id = eventResponse.id;
        event.eventTime = eventResponse.eventTime;
        event.line = eventResponse.line;
        event.eventType = eventResponse.eventType;
        event.origin = eventResponse.origin;
        event.stackIdentifier = eventResponse.stack?.stackIdentifier;
        event.valid = eventResponse.valid !== false;
        event.component = new Component(eventResponse.component.componentName,
          TrackingService.getComponentType(eventResponse.component.componentType));
        event.component.id = eventResponse.component.componentId;
        event.referenceProperties = eventResponse.referenceProperties;
        event.referenceType = eventResponse.referenceType;
        event.referenceId = eventResponse.referenceId;
        return event;
      });
      if (reset) {
        this.events = res;
      } else {
        this.events = this.events.concat(res);
      }
      this.loadingFinished.emit(true);
      this.requestPending = false;
    })
    .finally(() => this.showSpinner = false);
  }

  public getEventById(id: string): Promise<TrackingEvent[]> {
    return new Promise<TrackingEvent[]>((resolve, reject) => {
      this.trackingEventService.getTrackingEventsByComponentId({id}).toPromise()
      .then(result => {
        const events = new Array<TrackingEvent>();
        result.forEach(entry => {
          const event = new TrackingEvent();
          event.origin = entry.origin;
          event.eventTime = entry.eventTime;
          event.eventType = entry.eventType;
          event.id = entry.id;
          event.stackIdentifier = entry.stack?.stackIdentifier;
          event.line = entry.line;
          event.valid = entry.valid;
          event.referenceProperties = entry.referenceProperties;
          event.referenceType = entry.referenceType;
          event.referenceId = entry.referenceId;
          events.push(event);
        });

        resolve(events);
      }).catch((error) => reject(error));
    });
  }

  private onCancelPendingRequests() {
    return this.pendingGetEventsRequests$.asObservable();
  }

  private cancelGetEvents() {
    this.pendingGetEventsRequests$.next();
  }

  private onCancelPendingStacksRequests() {
    return this.pendingGetStacksRequests$.asObservable();
  }

  private cancelGetStacks() {
    this.pendingGetStacksRequests$.next();
  }

  private getStackFromStackResponse(stackResponse: StackResponse) {
    const stack = new Stack(stackResponse.stackIdentifier, stackResponse);
    stack.stackLabel = stackResponse.stackLabel;
    stack.stackedAt = stackResponse.stackedAt;
    stack.dispatchedAt = stackResponse.dispatchedAt;
    stack.transportDestination = stackResponse.transportDestination;
    stack.transportUnit = stackResponse.transportUnit;
    stack.typeMappedComponents = this.getComponentsFromResponse(stackResponse.components);
    this.collectComponents(stack);
    this.getComponentsStatus(stack.typeMappedComponents).then(() => stack.isLoaded = true);
    stack.setStackComponentType(); // TODO: ???

    return stack;
  }

  private getComponentsFromResponse(componentResponses: Array<ComponentResponse>): Map<ComponentType, Array<Component>> {

    if (!componentResponses || componentResponses.length === 0) {
      return new Map<ComponentType, Array<Component>>(
        [[ComponentType.Part, []], [ComponentType.Engine, []], [ComponentType.Unknown, []]]);
    }

    const partResponses: Array<ComponentResponse> = componentResponses.filter((response) =>
      TrackingService.getComponentType(response.componentType) === ComponentType.Part);
    const engineResponses: Array<ComponentResponse> = componentResponses.filter((response) =>
      TrackingService.getComponentType(response.componentType) === ComponentType.Engine);
    const unknownResponses: Array<ComponentResponse> = componentResponses.filter((response) =>
      TrackingService.getComponentType(response.componentType) !== ComponentType.Engine &&
      TrackingService.getComponentType(response.componentType) !== ComponentType.Part
    );

    const result: Map<ComponentType, Array<Component>> = new Map<ComponentType, Array<Component>>(
      []);
    result.set(ComponentType.Engine, this.initComponents(engineResponses, ComponentType.Engine));
    result.set(ComponentType.Part, this.initComponents(partResponses, ComponentType.Part));
    result.set(ComponentType.Unknown, this.initComponents(unknownResponses, ComponentType.Unknown));

    return result;
  }

  private initComponents(componentReponses: Array<ComponentResponse>, type: ComponentType): Array<Component> {
    return componentReponses.map(response => {
      const component = new Component(response.componentName, type);
      component.id = response.componentId;
      component.state = ComponentState.Unknown;
      return component;
    });
  }

  private collectComponents(stack: Stack): void {
    stack.components = (new Array<Component>()).concat(...stack.typeMappedComponents.values());
  }

  private async getComponentsStatus(stack: Map<ComponentType, Array<Component>>): Promise<void> {
    let enginesMap: Map<string, EngineResponse>;
    let partsMap: Map<string, PartResponse>;
    [enginesMap, partsMap] = await Promise.all([
      new Promise<Map<string, EngineResponse>>((resolve) => {
        const engineIds = stack.get(ComponentType.Engine).filter(value => value.id !== null).map(response => response.id);
        if (engineIds.length > 0) {
          this.engineBackendService.getEnginesByIDList(engineIds)
          .then((res) => {
            const resultMap: Map<string, EngineResponse> = new Map(res.map((val) => [val.id, val]));
            resolve(resultMap);
          })
          .catch(error => {
            ErrorHandler.printError(error);
            resolve(new Map<string, EngineResponse>());
          });
        } else {
          resolve(new Map<string, EngineResponse>());
        }
      }),
      new Promise<Map<string, PartResponse>>((resolve) => {
        const partIds = stack.get(ComponentType.Part).filter(value => value.id !== null).map(response => response.id);
        if (partIds.length > 0) {
          this.partBackendService.getPartsById(partIds)
          .then((res) => {
            const resultMap: Map<string, PartResponse> = new Map(res.content.map((val) => [val.id, val]));
            resolve(resultMap);
          })
          .catch(error => {
            ErrorHandler.printError(error);
            resolve(new Map<string, PartResponse>())
          });
        } else {
          resolve(new Map<string, PartResponse>());
        }
      })
    ]);
    stack.get(ComponentType.Engine).forEach(component => {
      if (component.id) {
        const engineResponse = enginesMap.get(component.id);
        if (!engineResponse) {
          component.state = ComponentState.Unknown;
        } else if (engineResponse.engineBlockedState === 'BLOCKED') {
          component.state = ComponentState.Blocked;
        } else if (engineResponse.engineBlockedState === 'NONE') {
          component.state = ComponentState.Ready;
        }
      }
    });
    stack.get(ComponentType.Part).forEach(component => {
      if (component.id) {
        if (partsMap.get(component.id)) {
          if (partsMap.get(component.id).partBlockedState === 'BLOCKED') {
            component.state = ComponentState.Blocked;
          } else if (partsMap.get(component.id).partBlockedState === 'NONE') {
            component.state = ComponentState.Ready;
          }
        }
      }
    });
  }

  createTrackingEvent(
    componentId: string,
    componentName: string,
    componentType: ComponentTypeRef,
    referenceType: ReferenceType,
    eventTime: string,
    eventType: EventType,
    origin: string,
    line: string,
    referenceProperties?: {[key: string]: string;},
  ): Promise<TrackingEvent[]> {
    const componentCreateRequest = {
      componentId,
      componentName,
      componentType
    }
    const request = {
      trackingEventCreateRequest: {
        componentCreateRequest,
        eventTime,
        eventType,
        line,
        origin,
        referenceId: componentId,
        referenceType,
        referenceProperties,
      }
    }
    return firstValueFrom(this.trackingEventService.createTrackingEvent(request)
    .pipe(map(response => {
      return response.map(entry => {
        const event = new TrackingEvent();
        event.origin = entry.origin;
        event.eventTime = entry.eventTime;
        event.eventType = entry.eventType;
        event.id = entry.id;
        event.stackIdentifier = entry.stack?.stackIdentifier;
        event.line = entry.line;
        event.valid = entry.valid;
        event.referenceProperties = entry.referenceProperties;
        event.referenceType = entry.referenceType;
        event.referenceId = entry.referenceId;
        return event;
      });
    })));
  }
}
