import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {EffectiveUserClaim, EnterpriseRoleProviderService} from '../../core/services/enterprise-role-provider.service';

@Directive({
  selector: '[opPermissionsLoadRequired]'
})
export class PermissionsLoadRequired implements OnInit{
  @Input('opPermissionsLoadRequired') requiredState: boolean;

  public isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {}

  public ngOnInit() {
    EnterpriseRoleProviderService.onPermissionsLoaded.subscribe(() => {
      this.evaluatePermissions();
    })
  }

  private evaluatePermissions() {
    if (EnterpriseRoleProviderService.permissionsLoaded === this.requiredState) {
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.isVisible = false;
      this.viewContainerRef.clear();
    }
  }
}
