import {EventType, TrackingEventResponse} from '@cstx/volkswagen-mqs-tracking-service-client';
import {Component} from './component';
import {ReferenceType} from '@cstx/volkswagen-mqs-tracking-service-client/model/referenceType';

export class TrackingEvent {
  public origin: string;
  public line: string;
  public eventTime: string;
  public eventType: EventType;
  public id: string;

  public stackIdentifier?: string;
  public component?: Component;
  valid: boolean;

  referenceProperties?: {
    [key: string]: string;
  } | null;
  referenceType?: ReferenceType | null;
  referenceId?: string;
}

/*
origin: string;
line: string;
eventTime: string;
eventType: TrackingEventResponse.EventTypeEnum;
id: string;
stack?: StackFlatResponse;
component?: ComponentResponse;
*/
