import {BackendStatus} from './BackendStatus';

export class BackendService {
  public name: string;
  public  url: string;

  public async getStatus(): Promise<BackendStatus> {
    return null;
  }

  constructor(name: string, url?: string) {
    this.name = name;
    this.url = url;
  }
}
