import { Injectable } from '@angular/core';
import {ConfigService} from '../../../core/services/config.service';
import {AuthService} from '../../../core/services/auth.service';
import {MessageType} from './message-type';
import {EnterpriseRoleProviderService} from '../../../core/services/enterprise-role-provider.service';

@Injectable({
  providedIn: 'root'
})
export class SendMessageService {
  private static _currentStage: string;
  private static _username: string;
  private static _userMail: string;

  public static get currentStage(): string {
    if (this._currentStage) {
      return this._currentStage;
    }

    return ConfigService.currentStage.toString();
  }
  public static set currentStage(value: string) {
    this._currentStage = value;
  }

  public static get userMail(): string {
    return this._userMail;
  }
  public static set userMail(value: string) {
    this._userMail = value;
    SendMessageService.validate();
  }

  public static set username(value: string) {
    this._username = value;
    SendMessageService.validate();
  }
  public static get username(): string {
    return this._username;
  }

  public static attachments = new Array<{name: string, data: Blob}>();
  public static userFullName: string;

  public static previousLocation: string;
  public static messageReady: boolean = false;

  public static reset() {
    SendMessageService.attachments = new Array<{name: string, data: Blob}>();
    SendMessageService._username = undefined;
    SendMessageService._currentStage = undefined;
    SendMessageService.userFullName = undefined;
    SendMessageService._userMail = undefined;
    SendMessageService.previousLocation = undefined;

    SendMessageService.messageReady = false;
  }

  public static generateSubject(messageType: MessageType, date: string, stage: string, version: string): string {
    if (messageType === MessageType.PROBLEM_REPORT && SendMessageService.messageReady) {
      return '[CPP] Problembericht von '
        + date + ' Uhr '
        + '(v' + version + ' - ' + stage.toUpperCase() +  ') - '
        + SendMessageService.username;
    }

    return '[CPP] Neue Supportanfrage von ' + AuthService.userName;
  }
  public static generateBody(messageType: MessageType, date: string, stage: string, version: string): string {
    if (messageType === MessageType.PROBLEM_REPORT && SendMessageService.messageReady) {
      return SendMessageService.generateProblemReportBody(date, stage, version);
    }

    return SendMessageService.generateDefaultBody(date, stage, version);
  }

  private static generateDefaultBody(date: string, stage: string, version: string): string {
    return 'Sehr geehrte Damen und Herren, '
      + '\n'
      + '\n'
      + '...'
      + '\n'
      + 'Mit freundlichen Gruessen,'
      + '\n'
      + AuthService.userName;
  }
  private static generateProblemReportBody(date: string, stage: string, version: string): string {
    let body: string;

    const description = 'BESCHREIBEN SIE NACHFOLGEND IHR PROBLEM: ' + '\n'
      + '---------------------------------------------------------------------------'
      + '\n\n\n\n'
      + '---------------------------------------------------------------------------'
      + '\n';


    const hint = 'NACHFOLGEND SEHEN SIE DIE DATEN, DIE MIT UNS GETEILT WERDEN. '
      + '\n'
      + 'SIE KÖNNEN UNERWÜNSCHTE INFORMATIONEN ENTFERNEN, ERSCHWEREN DAMIT ABER MÖGLICHERWEISE DIE ANALYSE DES PROBLEMS.'
      + '\n'
      + 'DEM PROBLEMBERICHT WIRD AUßERDEM EIN SCREENSHOT VOM PROBLEM UND DIE LETZEN ZEILEN DER LOKALEN LOGDATEI ANGEHÄNGT.'
      + '\n'
      + '\n'
      + 'AUCH DIESE KÖNNEN SIE AUF WUNSCH ENTFERNEN!'
      + '\n'
      + '==========================================================================================================';

    const header = '\n'
      + 'P R O B L E M - B E R I C H T : '                            + '\n'
      + '----------------------------------------------------------'  + '\n'
      + 'Umgebung: '  + stage.toUpperCase() + '\n'
      + 'Version: '   + version                                + '\n'
      + 'Datum: '     + date                                    + '\n'
      + 'Benutzername: '  + SendMessageService.username               + '\n'
      + 'Name: '  + SendMessageService.userFullName                   + '\n'
      + 'E-Mail: ' + SendMessageService.userMail                      + '\n'
      + 'Letzte aktive Route: ' + SendMessageService.previousLocation + '\n'
    ;

    let usersRoles = 'Aktuelle Rollen:' + '\n';
    EnterpriseRoleProviderService.effectiveEnterpriseRoles.forEach(role => {
      usersRoles =  usersRoles + role + '\n' ;
    })

    let usersRights = 'Aktuelle Rechte:' + '\n';
    EnterpriseRoleProviderService.effectiveUserClaims.forEach(right => {
      usersRights = usersRights + right.functionalResource + ' [' + (right.writePermission ? 'Schreiben' : 'Lesen') + ']' + '\n'
    })

    let usersGroups = 'Aktuelle Gruppen am Token:' + '\n';
    EnterpriseRoleProviderService.tokenGroups.forEach(group => {
      usersGroups = usersGroups + group + '\n';
    })

    body = description + hint +  header + '\n\n' + usersRoles + '\n' + usersRights + '\n' + usersGroups;

    return body;
  }
  private static validate()  {
    if (this._username && this._userMail) {
      this.messageReady = true;
    } else {
      this.messageReady = false;
    }
  }
}
