<div class="container-fluid fill bg-full">
  <div  class="row login-welcome">
    <div class="col-12 login-welcome-text d-flex align-items-center justify-content-center pt-3">
        <h1 class="font-weight">{{ portalMode === 'SZO' ? ('page.login.headline' | translate) : ( 'navbar.title.cpo' | translate ) }}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12 login-welcome-text d-flex align-items-center justify-content-center pb-3">
        <!--<h2 *ngIf="!isLoggedIn()" class="font-light">{{ 'page.login.subtext' | translate }}</h2>-->

      <ng-container *ngIf="!isLoggedIn()"
      >
        <img src="../../../assets/img/group_components.png" alt="" class="d-inline-block"
             height="100px">

      </ng-container>

        <div *ngIf="isLoggedIn()" class="authFailed">
          <p class="font-light">{{ 'page.login.authFailed' | translate }}</p>
          <p class="font-light">{{ 'page.login.missingGroup' | translate }}</p>
        </div>
    </div>
  </div>
  <div class="row login-button">
    <div class="col-12 text-center" >
      <button *ngIf="!isLoggedIn()" (click)="onLogin()" class="btn btn-primary">{{ 'button.login' | translate }}</button>
      <button *ngIf="isLoggedIn()" (click)="onLogout()" class="btn btn-warning">{{ 'button.logout' | translate }}</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center" >
    <op-select-box class="nav-link text-light" (selectEvent)="languageSelected($event)" [options]="languages"></op-select-box>
    </div>
  </div>
</div>
