import {ComponentBlockWizardActionType} from './component-block-wizard-action-type';
import {BlockingAction} from '../../services/backend/models/blockingAction';

export class ComponentBlock {
  public type: ComponentBlockWizardActionType = ComponentBlockWizardActionType.AddBlockingAction;
  public blockingActionId?: string;
  public blockingReason?: string;
  public blockingActionNr: string;
  public blockingAction?: BlockingAction;
  public componentNames = new Set<string>();

  public state: ComponentBlockStatus = ComponentBlockStatus.None;

  public clear() {
    this.blockingAction = undefined;
    this.blockingActionNr = undefined;
    this.blockingReason = undefined;
    this.blockingActionId = undefined;
    this.state = ComponentBlockStatus.None;
    this.type = ComponentBlockWizardActionType.AddBlockingAction;
  }
}

export enum ComponentBlockStatus {
  None = 'NONE',
  Initiated = 'INITIATED',
  Done = 'DONE',
  Failed = 'FAILED'
}
