import {Component, HostListener, Input, OnInit} from '@angular/core';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {BlockingActionResponse} from '@cstx/volkswagen-mqs-engine-service-client';
import {TranslateService} from '@ngx-translate/core';
import {Blocking} from './blocking';
import {PartService} from '../../../services/backend/part.service';
import {EngineService} from '../../../services/backend/engine.service';
import {ComponentProfileService} from '../component-profile.service';

@Component({
  selector: 'op-blockings',
  templateUrl: './blockings.component.html',
  styleUrls: ['./blockings.component.scss']
})
export class BlockingsComponent implements OnInit {
  @Input() selfLink: string;
  @Input() contentContainerId: string;
  @Input() componentId: string;
  @Input() componentName: string;
  @Input() componentType: string;
  @Input() collapsable = true;
  @Input() sectionTitle : string;

  items = new Array<Blocking>();
  viewSizeXS: boolean;

  treeViewItems = new Array<TreeviewItem>();
  treeViewConfig: TreeviewConfig;

  dateTimeFields = [ 'releasedAt', 'blockedAt' ];

  private beforePrintCollapsed: Map<TreeviewItem,boolean> = new Map<TreeviewItem, boolean>();



  private static createTreeViewConfig() {
    return    TreeviewConfig.create({
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 0
    });
  }
  private mapToItemModel(item: BlockingActionResponse): Blocking {
    const b = new Blocking(item, this.partBackendService);

    b.id = item.id;
    b.createdAt = item.createdAt;
    b.createdBy = item.createdBy;
    b.modifiedAt = item.modifiedAt;
    b.modifiedBy = item.modifiedBy;
    b.blockedActionState = item.blockedActionState;
    b.blockedActionType = item.blockedActionType;
    b.source = item.source;
    b.blockedAt = item.blockedAt;
    b.blockedBy = item.blockedBy;
    b.blockingReason = item.blockingReason ? item.blockingReason : item.action;
    b.releaseReason = item.releaseReason;
    b.releasedAt = item.releasedAt;
    b.releasedBy = item.releasedBy;

    b.details.push({ key: 'blockedBy', value: b.blockedBy ? b.blockedBy : '' });
    b.details.push({ key: 'blockingReason', value: b.blockingReason ? b.blockingReason : '' });
    b.details.push({ key: 'blockedAt', value: b.blockedAt ? b.blockedAt : '' });
    b.details.push({ key: 'releaseReason', value: b.releaseReason ? b.releaseReason : ''  });
    b.details.push({ key: 'releasedAt', value: b.releasedAt ? b.releasedAt : ''  });
    b.details.push({ key: 'releasedBy', value: b.releasedBy ? b.releasedBy : ''  });

    return b;
  }

  constructor(private partBackendService: PartService,
              private engineBackendService: EngineService,
              private breakpointObserver: BreakpointObserver,
              private translate: TranslateService) { }

  @HostListener('window:beforeprint')
  private onBeforePrint() {
    this.beforePrintCollapsed.clear();
    this.copyTreviewStateAndExpandAll(this.treeViewItems);
  }

  @HostListener('window:afterprint')
  private onAfterPrint() {
    this.recoverTreviewState(this.treeViewItems);
  }

  private copyTreviewStateAndExpandAll(items: Array<TreeviewItem>) {
    items.forEach(item => {
      this.beforePrintCollapsed.set(item, item.collapsed);
      item.collapsed = false;
      if (item.children && item.children.length > 0) {
        this.copyTreviewStateAndExpandAll(item.children);
      }
    })
  }

  private recoverTreviewState(items: Array<TreeviewItem>) {
    items.forEach(item => {
      const previousValue = this.beforePrintCollapsed.get(item);
      item.collapsed = previousValue === undefined ? true : previousValue
      if (item.children && item.children.length > 0) {
        this.recoverTreviewState(item.children);
      }
    })
  }

  async ngOnInit(): Promise<void> {


    this.breakpointObserver
      .observe(['(max-width: 576px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.viewSizeXS = true;
        } else {
          this.viewSizeXS = false;
        }
      });

    this.treeViewConfig = BlockingsComponent.createTreeViewConfig();
    await this.getItems();

    /**
     * For further reloads of blocking actions, we now observe an event on which we need to re-create the blocking-actions
     * treeview.
     */
    ComponentProfileService.onBlockingActionsLoaded.subscribe(() => {
      this.workItems(ComponentProfileService.blockingActions);
    });
  }

  private async getItems() {
    let blockingResponse = new Array<BlockingActionResponse>();
    if (this.componentType === 'ENGINE') {

      const result = await ComponentProfileService
        .loadBlockingActions(this.componentId, this.componentName, this.engineBackendService);

      if (result) {
        blockingResponse = ComponentProfileService.blockingActions;
      }

    } else {
      const result = await ComponentProfileService
        .loadBlockingActions(this.componentId, this.componentName, this.partBackendService);

      if (result) {
        blockingResponse = ComponentProfileService.blockingActions;
      }
    }

    this.workItems(blockingResponse);
  }

  private workItems(blockingActions: Array<BlockingActionResponse>) {
    const items = new Array<Blocking>();
    blockingActions.forEach(blockingAction => {
      items.push(this.mapToItemModel(blockingAction));
    });

    items.sort((a, b) => {
      if (a.blockedAt > b.blockedAt) {
        return -1;
      } else {
        return 1;
      }
    });

    this.items = items;
    this.populateTree();
  }

  private populateTree() {
    const treeViewItems = new Array<TreeviewItem>();
    this.items.forEach(t => {
      const subItem = new TreeviewItem({text: t.blockedAt, value: t});

      subItem.children = this.createTestResultSubItems(t);
      subItem.collapsed = true;

      treeViewItems.push(subItem);
    });

    this.treeViewItems = treeViewItems;
  }



  private createTestResultSubItems(t: Blocking): TreeviewItem[] {
    let items: TreeviewItem[];
    t.details.forEach((item, index) => {
      if (index === 0) {
        items = new Array<TreeviewItem>();
      }


      const tryTranslateKey = this.translate.instant('component-profile.blockings.' + item.key);

      if (this.dateTimeFields.includes(item.key)) {
        this.dateTimeFields.push(tryTranslateKey);
      }

      items.push(new TreeviewItem({text: tryTranslateKey === 'component-profile.blockings.' + item.key ? item.key : tryTranslateKey, value: item.value}));
    });

    return items;
  }
}
