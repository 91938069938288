import {Injectable, isDevMode} from '@angular/core';
import { AudioContext } from 'standardized-audio-context';

@Injectable({
  providedIn: 'root'
})
export class BeepService {
  private static _audioContext: AudioContext;
  private audioContext: AudioContext;

  private static _beep(duration? , frequency?, volume?){
    duration = duration || 200;
    frequency = frequency || 440;
    volume = volume || 100;

    if (BeepService._audioContext === undefined) {

      try {
        BeepService._audioContext = new AudioContext();
      } catch (error) {
        if (isDevMode()) {
          console.log('An error occurred on creating audio context.');
          console.log(error);
          return;
        }
      }

    }

    try {
      const oscillatorNode = BeepService._audioContext.createOscillator();
      const gainNode = BeepService._audioContext.createGain();
      oscillatorNode.connect(gainNode);

      // Set the oscillator frequency in hertz
      oscillatorNode.frequency.value = frequency;

      // Set the type of oscillator
      oscillatorNode.type = 'square';
      gainNode.connect(BeepService._audioContext.destination);

      // Set the gain to the volume
      gainNode.gain.value = volume * 0.01;

      // Start audio with the desired duration
      const currentTime = BeepService._audioContext.currentTime;
      oscillatorNode.start(currentTime);
      oscillatorNode.stop(currentTime + duration * 0.001);

    }
    catch(error){
      if (isDevMode()) {
        console.log('An error occurred creating a beep.');
        console.log(error);
      }
    }
  }

  public static BeepSuccess(disabled?: boolean) {
    if (disabled) {
      return;
    }

    BeepService._beep(200, 600);
  }

  public static BeepError(disabled?: boolean) {
    if (disabled) {
      return;
    }
    BeepService._beep(200, 220);
  }

  public beep(duration? , frequency?, volume?){
    duration = duration || 200;
    frequency = frequency || 440;
    volume = volume || 100;

    if (this.audioContext === undefined) {

      try {
        this.audioContext = new AudioContext();
      } catch (error) {
        if (isDevMode()) {
          console.log('An error occurred on creating audio context.');
          console.log(error);
          return;
        }
      }

    }

    try {
      const oscillatorNode = this.audioContext.createOscillator();
      const gainNode = this.audioContext.createGain();
      oscillatorNode.connect(gainNode);

      // Set the oscillator frequency in hertz
      oscillatorNode.frequency.value = frequency;

      // Set the type of oscillator
      oscillatorNode.type = 'square';
      gainNode.connect(this.audioContext.destination);

      // Set the gain to the volume
      gainNode.gain.value = volume * 0.01;

      // Start audio with the desired duration
      const currentTime = this.audioContext.currentTime;
      oscillatorNode.start(currentTime);
      oscillatorNode.stop(currentTime + duration * 0.001);

      }
      catch(error){
        if (isDevMode()) {
          console.log('An error occurred creating a beep.');
          console.log(error);
        }
      }
  }
}
