<div class="row pl-3 pr-3" *ngIf="!searchDisabled">
  <div class="col align-middle cpp-input-search" style="padding: 0.75rem; border-top: 1px solid #dee2e6; border-bottom: 1px solid #dee2e6" *ngIf="groupsLoaded">
    <op-guided-filter-input [source]="searchAliasMappings"
                            (criteriaUpdate)="setFilterValues($event)"
                            (triggerSearch)="onSearch($event)"
                            [searching]="searching"
                            useFixedOrder="true">
    </op-guided-filter-input>

    <div class="invalid-feedback">
      {{ 'engine.list.invalid-feedback' | translate }}
    </div>
  </div>
</div>
<div class="d-flex justify-content-center pt-3" style="min-height: 100%">
    <div class="pr-0" [class.col-4]="!viewSizeSM" [class.col]="viewSizeSM" [hidden]="viewSizeSM && (showPreview || uploadRequested)">
      <op-usecase-treeview
                   [usecase]="usecase"
                   [disabled]="disabled"
                   [usecasePrefix]="usecasePrefix"
                   [uploadDisabled]="uploadDisabled"
                   (fileSelectedEvent)="fileSelected($event)"
                   (showUploadEvent)="showUpload()"
                   [updateTreeviewRequestEvent]="triggerTreeviewReload"></op-usecase-treeview>
    </div>
    <div [hidden]="!uploadRequested" class="col">
      <op-usecase-upload  [onShowUploadOverlay]="showUploadRequestEvent"
                          [useCase]="usecase"
                          [usecasePrefix]="usecasePrefix"
                   (uploadRequestEndEvent)="onUploadRequestEnd($event)"></op-usecase-upload>
    </div>
    <div [hidden]="uploadRequested|| (viewSizeSM && !showPreview)" class="col">
      <op-usecase-preview [newPreviewRequest]="previewRequestEvent"
                   [usecase]="usecase"
                   [disabled]="disabled"
                   (deleteRequestEndEvent)="onDeleteRequestEnd($event)"
                   [useCloseBtn]="viewSizeSM"
                   [extendedPdfViewer]="true"
                   (triggerPreviewClose)="showPreview=false">
      </op-usecase-preview>
    </div>
  </div>

