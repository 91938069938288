import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  CreateReportTicketRequestParams,
  EmailControllerService,
  ReportTicketControllerService
} from '@cstx/volkswagen-mqs-notification-service-client';
import {AuthService} from '../../../core/services/auth.service';
import {firstValueFrom} from 'rxjs';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../../core/services/config.service';

@Component({
  selector: 'op-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, AfterViewInit {

  @Input() title: string;
  @Input() shareLink: string;
  @Input() addNoteEnabled = false;
  @Input() editSubjectEnabled = false;
  @Input() allowUserAttachments = true;
  @Input() useRecipientHistory = true;
  @Input() attachments: Array<{name: string, data: Blob}>;
  @Input() subject = '[No Reply] Message from CPP';
  @Input() defaultMessageContent: string;
  @Input() sender: string;
  @Input() closeIconEnabled: boolean = true;
  @Input() fixedRecipients: string[];
  @Input() noteRowsCount: number = 3;
  @Input() optionalProvideSendMeCopy: boolean;
  @Input() createJiraTicket: boolean= false;

  @Output() onMessageSend = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  @ViewChild('fileInput') fileInput;

  recipients: Array<string> = [];
  recipientHistory: Array<string> = [];
  filteredRecipientHistory: Array<string> = [];

  recipientInput: string;
  note: string;

  showDropdown = false;
  dropdownIndex = -1;

  messageSent = false;
  messageError = false;
  messageLoading = false;

  TO_VALID = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  LS_HISTORY_KEY = 'cpp-message-recipient-history';

  public sendCopyToUser: boolean;

  constructor(
    private emailControllerService: EmailControllerService,
    private authService: AuthService,
    private translate: TranslateService,
    private reportTicketControllerService: ReportTicketControllerService
  ) {
  }

  ngAfterViewInit() {
    this.note = this.defaultMessageContent;
    if (this.shareLink) {
      this.note = this.note + '\n' + this.shareLink;
    }
    if (this.useRecipientHistory) {
      const history = localStorage.getItem(this.LS_HISTORY_KEY);
      if (history) {
        this.recipientHistory = JSON.parse(history);
        this.filteredRecipientHistory.push(...this.recipientHistory);
      }
    }
  }

  removeRecipient(i: number) {
    this.recipients.splice(i, 1);
    this.filterRecipients();
  }

  onEnter() {
    if (this.dropdownIndex >= 0) {
      this.recipients.push(this.filteredRecipientHistory[this.dropdownIndex]);
      this.dropdownIndex = -1;
    } else {
      if (this.recipientInput && this.recipientInput.length > 0) {
        this.recipients.push(this.recipientInput);
      } else if (this.recipients.length > 0) {
        this.send();
      }
    }
    this.recipientInput = '';
    this.filterRecipients();
  }

  onBackspace(event: Event) {
    event.stopPropagation();
    if (!this.recipientInput || this.recipientInput === '') {
      if (this.recipients.length > 0) {
        this.recipients.splice(this.recipients.length - 1, 1);
      }
    }
  }

  onEscape() {
    this.recipientInput = '';
    this.dropdownIndex = -1;
    this.filterRecipients();
  }

  onUp(event: any) {
    event.preventDefault();
    if (this.dropdownIndex > 0) {
      this.dropdownIndex--;
    } else if (this.filteredRecipientHistory.length > 0) {
      this.dropdownIndex = this.filteredRecipientHistory.length - 1;
    }
  }

  onDown(event: any) {
    event.preventDefault();
    if (this.dropdownIndex >= 0) {
      if (this.dropdownIndex < this.filteredRecipientHistory.length - 1) {
        this.dropdownIndex++;
      } else {
        this.dropdownIndex = 0;
      }
    } else if (this.filteredRecipientHistory.length > 0) {
      this.dropdownIndex = 0;
    }
  }

  filterRecipients() {
    this.filteredRecipientHistory = this.recipientHistory.filter(recipient => {
      return recipient.includes(this.recipientInput)
       && !this.recipients.some(otherRecipient => otherRecipient === recipient);
    });
  }

  selectOption(recipient: string) {
    this.recipients.push(recipient);
    this.recipientInput = '';
    this.filterRecipients();
  }

  onBlur() {
    setTimeout(() => this.showDropdown = false, 200);
  }

  onFocus() {
    this.showDropdown = true;
  }

  onAddAttachment() {
    this.fileInput.nativeElement.click();
  }

  addAttachment(event) {
    const file: File = event.target.files[0];
    this.attachments.push({name: file.name, data: file})
  }

  removeAttachment(i: number) {
    this.attachments.splice(i, 1);
  }

  public canceled() {
    this.onCancel.emit();
  }

  validateRecipients(): boolean {
    return !this.recipients.some(recipient => !this.validateRecipient(recipient)) && this.recipients.length > 0;
  }

  protected validateRecipient(recipient: string): boolean {
    return this.TO_VALID.test(recipient.toLowerCase());
  }

  public send() {
    if (this.createJiraTicket){
      this.createJiraTicketFromMessage();
    }


    const sender = this.sender ? this.sender : ConfigService.getSenderEmail();
    this.messageError = false;
    this.messageLoading = true;

    if (this.sendCopyToUser) {
      const userMail = this.authService.currentUser?.email;

      if (userMail) {
        if (this.recipients.findIndex(r => r.toLowerCase() === userMail.toLowerCase()) === -1) {
          this.recipients.push(userMail);
        }
      }
    }

    if (this.recipientInput) {
      if (this.recipients.length === 0) {
        this.recipients = this.recipientInput.split(' ');
      } else {
        this.recipients.push(...this.recipientInput.split(' '));
      }
    }
    this.recipientInput = '';
    const request = {
      from: sender,
      to: this.recipients,
      subject: this.sender ? this.translate.instant('shared.components.message.sender-info', { sender: this.authService.currentUser.name }) + ': ' + this.subject : this.subject,
      mailContent: this.note,
      attachments: this.attachments?.map(a => new File([a.data], a.name)),
      isContentHtml: false
    }
    firstValueFrom(this.emailControllerService.sendEmail(request)).then(response => {
      this.messageSent = true;
      this.onMessageSend.emit();
    })
    .catch(e => {
      this.messageError = true;
      LoggingService.logError(LoggingSource.CORE,
        'An error occurred sending a message with the notification service.', e)
    })
    .finally(() => {
      if (this.useRecipientHistory) {
        const history : Array<string> = JSON.parse(localStorage.getItem(this.LS_HISTORY_KEY));
        let changed = false;
        this.recipients?.forEach(recipient => {
          if (history !== null && !history.some(entry => entry === recipient)) {
            history.push(recipient);
            changed = true;
          }
        });
        if (changed) {
          localStorage.setItem(this.LS_HISTORY_KEY, JSON.stringify(history));
        }
        this.messageLoading = false;
      }
    });
  }

  private createJiraTicketFromMessage(){
    const request :CreateReportTicketRequestParams= {
      text: this.note,
      attachments: this.attachments?.map(a => new File([a.data], a.name)),
      title: this.sender ? this.translate.instant('shared.components.message.sender-info', { sender: this.authService.currentUser.name }) + ': ' + this.subject : this.subject,
    }
    firstValueFrom(this.reportTicketControllerService.createReportTicket(request)).then(response => {
      this.messageSent = true;
      this.onMessageSend.emit();
    }).catch(e => {
      this.messageError = true;
      LoggingService.logError(LoggingSource.CORE,
        'An error occurred creating a ticket with the notification service.', e)
    });
  }



  public ngOnInit(): void {
    if (this.fixedRecipients && this.fixedRecipients.length >= 1) {
      this.recipients.push(...this.fixedRecipients);
    }
  }
}
