import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {EffectiveUserClaim, EnterpriseRoleProviderService} from '../../core/services/enterprise-role-provider.service';

@Directive({
  selector: '[opRequiredClaimByFunctionalResource]'
})
export class RequiredClaimByFunctionalResourceDirective implements OnInit{
  @Input('opRequiredClaimByFunctionalResource') claim: EffectiveUserClaim | string | string[];

  public isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {}

  public ngOnInit() {
    EnterpriseRoleProviderService.onPermissionsLoaded.subscribe(() => {
      this.evaluateClaim();
    })

    this.evaluateClaim();
  }

  private evaluateClaim() {
    if (EnterpriseRoleProviderService.effectiveUserClaims?.length < 1) {
      this.viewContainerRef.clear();
    }

    // let functionalResourceNames: Array<string>;
    let result: boolean;
    let effectiveUserClaim: EffectiveUserClaim;

    if (typeof this.claim === 'string' || this.claim instanceof Array<string>) {
      result = EnterpriseRoleProviderService.hasRequiredClaimByFunctionResource(this.claim);
    } else {
      effectiveUserClaim =
        new EffectiveUserClaim(this.claim.functionalResource, this.claim.writePermission);
    }

    /*
    if (typeof this.claim === 'string')  {
      effectiveUserClaim = new EffectiveUserClaim(this.claim);
    }
    else {

      if (this.claim instanceof Array<string>) {
        functionalResourceNames = this.claim as Array<string>;


      } else {
        effectiveUserClaim =
          new EffectiveUserClaim(this.claim.functionalResource, this.claim.writePermission);
      }
    }
     */
    if (effectiveUserClaim) {
      result = EnterpriseRoleProviderService.hasRequiredClaim(effectiveUserClaim)
    }

    /*
    /**
     * This could be used to check for existence of one of multiple possible function resources
     */

      /*
    if (functionalResourceNames) {
      functionalResourceNames.forEach(fr => {
        effectiveUserClaim = new EffectiveUserClaim(fr);

        if (EnterpriseRoleProviderService.hasRequiredClaim(effectiveUserClaim)) {
          result = true;
        }
      });
    }
    */

    if (result) {
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.isVisible = false;
      this.viewContainerRef.clear();
    }
  }

}
