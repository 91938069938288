import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {ConfigService } from '../../core/services/config.service';

@Directive({
  selector: '[opChristmas]'
})
export class ChristmasDirective implements OnInit{
  @Input() opChristmas: boolean;
  isVisible = false;

  constructor(private viewContainerRef: ViewContainerRef,
              private templateRef: TemplateRef<any>) { }


  ngOnInit() {
    if (this.opChristmas) {
      if (this.isChristmasTime()) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    }
  }

  private isChristmasTime(): boolean {
    const todayDate = new Date();
    const christmasStart = new Date(todayDate.getFullYear() + '-12-1');
    const christmasEnd = new Date( todayDate.getFullYear() + '-12-28');


    if (todayDate > christmasStart && todayDate < christmasEnd) {
      return true;
    }

    return false;
  }
}
