<div class="input-group mt-auto">
  <input [disabled]="disabled" type="text" class="form-control" name="searchString" [(ngModel)]="searchString"
         (ngModelChange)="onNgModelChange($event)" placeholder="{{ 'search.placeholder' | translate }}"/>
  <button *ngIf="searchString" type="button" class="btn bg-transparent" style="margin-left: -40px; z-index: 100;"
          (click)="clearSearchString()">
    <i class="fas fa-times"></i>
  </button>
  <div *ngIf="clearSearch" class="input-group-append">
    <button class="btn btn-info" (click)="clearFilter()" type="button">{{ 'search.clear' | translate }}</button>
  </div>
</div>
