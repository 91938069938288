import {Injectable} from '@angular/core';
import {BlockingActionFilter} from '../../../modules/actions/models/blockingactionsfilter';
import {firstValueFrom, Subject} from 'rxjs';
import {ErrorHandler} from '../error-handler/error-handler';
import {
  BlockedComponentResponse,
  BlockFilteredComponentsRequestParams,
  BlockingActionByFilterResultsCreateRequest,
  BlockingActionResponse,
  BlockingActionResponseSliceResponse,
  BlockingActionsControllerV2Service,
  BlockingActionsService,
  CreateBlockingActionRequestParams,
  FilterControllerV2Service, OffSetCreateRequest, OffSetResponse, OffSetService,
  UpdateBlockingActionRequestParams
} from '@cstx/volkswagen-mqs-quality-management-service-client';
import {BlockingAction, BlockingServiceRequestType} from './models/blockingAction';
import {BlockingActionStatistic} from './models/blockingActionStatistic';
import {takeUntil} from 'rxjs/operators';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {
  AuditHistoryResponseSliceResponse
} from '@cstx/volkswagen-mqs-quality-management-service-client/model/auditHistoryResponseSliceResponse';
import {
  AddComponentsRequestParams, ReleaseBlockedComponentsRequestParams
} from '@cstx/volkswagen-mqs-quality-management-service-client/api/blockingActionsControllerV2.service';
import {HttpErrorResponse} from '@angular/common/http';
import {OffSet} from '../../components/component-profile/offsets/offSet';

@Injectable({
  providedIn: 'root'
})
export class QualityManagementService {
  private onAbortGetBlockingActionsCountV2Request = new Subject();

  constructor(private blockingActionsControllerService: BlockingActionsService,
              private blockingActionsControllerV2Service: BlockingActionsControllerV2Service,
              private filterControllerV2: FilterControllerV2Service,
              private offSetControllerService: OffSetService) {
  }

  /**
   * This method can be used to abort a running call against getBlockingActionsCountV2.
   */
  public abortGetBlockingActionsCountV2Request() {
    LoggingService.logDebug(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Abort event for blocking actions count request fired.')
    this.onAbortGetBlockingActionsCountV2Request.next(true);
  }

  /**
   * This method returns the amount / count of entities that math the given filter.
   * The call can be aborted by calling abortGetBlockingActionsCountV2Request.
   * @param filter The filter object that will be used to determine the amount of matching entities.
   * @returns A number representing the number of entities matching the filter is returned.
   */
  public async getBlockingActionsCountV2(filter: BlockingActionFilter): Promise<number> {
    try {
      LoggingService.logDebug(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Blocking actions count was requested.')

      return await firstValueFrom(this.blockingActionsControllerV2Service
          .getBlockingActionsCountV2(filter.getFilterParams()).pipe(takeUntil(this.onAbortGetBlockingActionsCountV2Request)));

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Error occurred when receiving blocking actions count.', error);
      ErrorHandler.printError(error);
    }
  }

  /**
   * This mehtod can be used to get a slice of the entities matching the given filter for blocking actions.
   * @param filter The filter object that will be used to determine the amount of matching entities.
   * @returns A slice of BlockingActions is returned or null.
   */
  public async getBlockingActionsV2(filter: BlockingActionFilter): Promise<BlockingActionResponseSliceResponse> {
    try {
      return await firstValueFrom(this.blockingActionsControllerV2Service
          .getBlockingActionsV2(filter.getAllParams()));

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Error occurred when receiving blocking actions.', error);
      ErrorHandler.printError(error);
    }

    return null;
  }

  /**
   * This method retrieves a blocking action statistic object based on a given blocking action id.
   * @param id The id of the blocking action we want the statistics for.
   * @returns A BlockingActionStatistic object is returned or null.
   */
  public async getBlockingActionStatistics(id: string): Promise<BlockingActionStatistic> {
    try {
      const response =
        await firstValueFrom(this.blockingActionsControllerService.getStats({id}));

      return new BlockingActionStatistic(response, id);

    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        const httpError = error as HttpErrorResponse;

        if (httpError.status === 404) {
          LoggingService.logInfo(LoggingSource.QUALITY_MANAGEMENT_SERVICE,
            `No stats found for blocking action [${id}]`);
        }

        LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE,
          'Http error occurred when receiving blocking action statistics.', error);
      }

      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE,
        'Unspecified error occurred when receiving blocking action statistics.', error);
    }

    return null;
  }

  /**
   * This method creates or updates a blocking action.
   * @param blockingAction The blocking action we want to create or update.
   * @returns The updated Blocking Action or null is returned.
   */
  public async postBlockingAction(blockingAction: BlockingAction): Promise<BlockingActionResponse> {
    try {
      if (blockingAction.id) {
        return await this.updateBlockingAction(blockingAction);
      } else {
        return await this.createBlockingAction(blockingAction);
      }

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Error occurred on create or update of a blocking action.', error);
      ErrorHandler.printError(error);
    }
    return null;
  }



  /**
   * This method creates or updates a blocking action.
   * @param blockingAction The blocking action we want to create or update.
   * @returns The updated Blocking Action or null is returned.
   */
  public async createOrUpdateBlockingActionV2(blockingAction: BlockingAction): Promise<BlockingActionResponse> {
    try {
      if (blockingAction.id) {
        return await this.updateBlockingAction(blockingAction);
      } else {
        return await this.createBlockingActionV2(blockingAction);
      }

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Error occurred on create or update of a blocking action.', error);
    }

    return null;
  }

  /**
   * This method releases components from a blocking action.
   * @param blockingAction The blocking action we want to do a release for.
   * @returns True on success and false on failure is returned
   */
  public async releaseComponentsForBlockingActionV2(blockingAction: BlockingAction): Promise<boolean> {
    try {

      if (blockingAction.blockingServiceRequestType === BlockingServiceRequestType.PARTIAL_RELEASE) {
        const params = blockingAction.getBlockingActionReleaseComponentsRequest();

        return  await firstValueFrom(this.blockingActionsControllerV2Service.releaseBlockedComponents(params));
      }

      //return await this.updateBlockingAction(blockingAction);

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Error occurred on create or update of a blocking action.', error);
      ErrorHandler.printError(error);
    }
    return false;
  }

  /**
   * This method releases components from a blocking action.
   * @returns True on success and false on failure is returned
   * @param blockingActionId
   * @param componentNames
   * @param releaseReason
   */
  public async partialReleaseBlockingAction(blockingActionId: string, componentNames: string[], releaseReason: string): Promise<boolean> {
    try {
        const params: ReleaseBlockedComponentsRequestParams = {
          blockingActionId,
          componentIdentifiers: componentNames,
          releaseReason
        }

        return  await firstValueFrom(this.blockingActionsControllerV2Service.releaseBlockedComponents(params));

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Error occurred on partial release of components.', error);
    }
    return false;
  }

  /**
   * Delete a blocking action.
   * @param id The id of the blocking action that should be deleted.
   * @returns True if deletions succeeded or false if not.
   */
  public async deleteBlockingAction(id: string): Promise<boolean> {
    try {
       await firstValueFrom(this.blockingActionsControllerService.deleteBlockingAction({id}));
       return true;
    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Error occurred on deleting a blocking action.', error);
      ErrorHandler.printError(error);
    }
    return false;
}

  /**
   * This method will return a blocking action based on its blockingFilterId (not entity id!).
   * @param id The blockingFilterId attribute of the blockingAction.
   * @returns If a blocking action exists it is returned, else null is returned.
   */
  public async getBlockingActionByBlockingFilterId(id: string) {
    try {

     // TODO: Deprecated
     /*
      const response = await firstValueFrom(this.blockingActionsControllerV2Service.getBlockingActionsV2(
        { blockingFilterId: id }));

      if (response.content.length > 0) {
        return response.content[0];
      }
      */

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Error occurred trying to get a blocking action based on blockingFilterId.', error);
      ErrorHandler.printError(error);
    }

    return null;
  }

  /**
   * This method will return a blocking action based on its blockingFilterId (not entity id!).
   * @param id The blockingFilterId attribute of the blockingAction.
   * @returns If a blocking action exists it is returned, else null is returned.
   */
  public async getBlockingActionByHumanReadableId(id: string) {
    try {
       const response = await firstValueFrom(this.blockingActionsControllerV2Service.getBlockingActionByHumanReadableId(
         { humanReadableId: id }));

       if (response) {
         return response;
       }

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Error occurred trying to get a blocking action based on blockingFilterId.', error);
      ErrorHandler.printError(error);
    }

    return null;
  }

  /**
   * This method should be used to get all components assigned to a blockingAction by
   * the blocking action id.
   * @param id The id of the blocking action.
   * @returns An Array of BlockedComponent objects will be returned.
   */
  public async getBlockedComponentsForBlockingAction(id: string): Promise<BlockedComponentResponse[]> {
    try {

    return await firstValueFrom(this.blockingActionsControllerService
        .getBlockedComponents({id}));

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Error occurred on trying to get assigned components for blocking action.', error);
      ErrorHandler.printError(error);
    }

    return new Array<BlockingActionResponse>();
  }

  /**
   * This method retrieves a blocking action history object based on a given blocking action id.
   * @param id The id of the blocking action we want the statistics for.
   * @param page
   * @returns A AuditHistoryResponseSliceResponse object is returned or null.
   */
  public async getBlockingActionAuditHistory(id: string, page: number = 0): Promise<AuditHistoryResponseSliceResponse> {
    try {
      const response =
        await firstValueFrom(this.blockingActionsControllerV2Service.getBlockingActionHistory({id, page}));

      return response;

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE,
        'Error occurred when receiving blocking action audit history.', error);
      ErrorHandler.printError(error);
    }

    return null;
  }

  /**
   * This method triggers a component state sync of a blocking action by humanReadableId.
   * @param id The humanReadableId of the blocking action we want to trigger a sync for.
   */
  public async triggerBlockingActionReSyncByHumanReadableId(id: string): Promise<boolean> {
    try {
      await firstValueFrom(this.blockingActionsControllerV2Service.syncBlockingActionV2({ humanReadableId: id }));

      return true;

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE,
        'Error occurred when triggering blocking action sync.', error);
    }

    return false;
  }

  public async getTimeUntilNewSyncIsPossible(humanReadableId: string): Promise<number> {
    try {
      const result = await firstValueFrom(this.blockingActionsControllerV2Service
        .syncWaitTimeInSeconds({ humanReadableId }));

      return result;

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE,
        `GetTimeUntilNewSyncIsPossible for blockingAction with id [${humanReadableId}] failed.`, error);
    }

    return null;
  }

  private async updateBlockingAction(blockingAction: BlockingAction): Promise<BlockingActionResponse> {
    const request = blockingAction.getBlockingActionUpdateRequest();

    const params: UpdateBlockingActionRequestParams = {
      blockingActionUpdateRequest: request
    };

    return  await firstValueFrom(this.blockingActionsControllerService.updateBlockingAction(params));
  }

  private async createBlockingAction(blockingAction: BlockingAction): Promise<BlockingActionResponse>  {
    const request = blockingAction.getBlockingActionCreateRequest();

    const params: CreateBlockingActionRequestParams = {
      blockingActionCreateRequest: request
    };

    return  await firstValueFrom(this.blockingActionsControllerService.createBlockingAction(params));
  }

  /**
   * V2 Endpoints
   */

  private async createBlockingActionV2(blockingAction: BlockingAction): Promise<BlockingActionResponse> {
    const request = blockingAction.getCreateBlockingActionV2Request();
;
    return  await firstValueFrom(this.blockingActionsControllerV2Service.createBlockingActionV2(request))
  }

  /**
   * This method can be used to extend the range of the blocking action by adding more ids.
   * @param blockingAction The blocking action we want to extend.
   * All new component ids have to be in attribute "componentIdsExtension"
   * @returns The updated Blocking Action or null is returned.
   */
  public async extendBlockingActionV2(blockingAction: BlockingAction): Promise<BlockingActionResponse> {
    try {

      const params: AddComponentsRequestParams = {
        id: blockingAction.id,
        componentIdentifiersToAdd: blockingAction.componentIdentifiersExtension,
        fileParseInputContent: blockingAction.componentsFile,
        fileParseInputColumnIndexOverride: blockingAction.componentsFileColumnIndex,
        fileParseInputIgnoreTopRowOverride: blockingAction.componentsFileHasHeader
      }

      return await firstValueFrom(this.blockingActionsControllerV2Service.addComponents(params));

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Error occurred extending a blocking action.', error);
      ErrorHandler.printError(error);
    }
    return null;
  }

  public async blockComponentsForFilter(blockingAction: BlockingAction): Promise<BlockingActionResponse> {
    try {

      const request: BlockingActionByFilterResultsCreateRequest = {
        blockingReason: blockingAction.blockingReason,
        action: blockingAction.blockingReason,
        description: blockingAction.description
      }

      const params: BlockFilteredComponentsRequestParams = {
        blockingActionByFilterResultsCreateRequest: request,
        id: blockingAction.filterId
      }

      return await firstValueFrom(this.filterControllerV2.blockFilteredComponents(params));
    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, 'Error occurred creating a blocking action for a specific filterId.', error);
      ErrorHandler.printError(error);
    }
    return null;
  }

  public async getOffSetsForComponentId(componentId: string): Promise<OffSetResponse[]> {
    try {
      const response = await firstValueFrom(this.offSetControllerService.getOffSets({ componentId }));

      if (response) {
        return response.content;
      }

    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, `Error occurred receiving offsets for componentId [${componentId}].`, error);
    }

    return null;
  }

  public async deleteOffSetById(id: string): Promise<boolean> {
    try {
      await firstValueFrom(this.offSetControllerService.deleteOffset({ id }));
      return true;
    } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, `Error occurred deleting offset with id [${id}].`, error);
    }

    return null;
  }

  public async saveOffSet(componentId: string, componentName: string, offSet: OffSet): Promise<boolean> {
    try {
        let offSetCreateRequest: OffSetCreateRequest = {
          componentId,
          creationType: 'Initial',
          value: offSet.type === 'Injector' ? 'Injector_' + offSet.position + '_' + offSet.raw : offSet.raw,
          type: offSet.type,
          componentName

        };

        await firstValueFrom(this.offSetControllerService.createOffSet({
          offSetCreateRequest
        }));

        return true;
      } catch (error) {
      LoggingService.logError(LoggingSource.QUALITY_MANAGEMENT_SERVICE, `Error occurred creating offset for component id [${componentId}].`, error);
    }

    return false;
  }
}
