import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {UploadV2Service} from './upload-v2.service';
import {UploadConfiguration} from './uploadConfiguration';
import {UploadEntity} from './uploadEntity';

@Component({
  selector: 'op-upload-v2',
  templateUrl: './upload-v2.component.html',
  styleUrls: ['./upload-v2.component.scss']
})
export class UploadV2Component implements OnInit, OnDestroy {
  @Input() showUploadArea = true;
  @Input() multiFileUpload = false;
  @Input() acceptedExtensions = ['.csv', '.xlsx'];
  @Input() useEmbeddedFileList = true;
  @Input() uploadV2Configuration = new UploadConfiguration();

  @Output() onNewFilesSelected = new Subject<File[]>();
  @Output() onNewFileSelected = new Subject<File>();
  @Output() onInvalidExtension = new Subject<string>();
  @Output() onFilesCleared = new Subject<string>();
  @Output() onUploadAborted = new Subject<string>();
  @Output() onUploadSubmit = new Subject<File[]>();
  @Output() onFileUploaded = new Subject<UploadEntity>();


  public embeddedFileList = new Array<File>();
  public invalidExtension: boolean;

  constructor(private uploadV2Service: UploadV2Service) {
    this.uploadV2Service.onFileUploaded.subscribe(uploadedEntity => {
      this.onFileUploaded.next(uploadedEntity);
    });
  }

  public ngOnInit(): void {
    this.uploadV2Service.config = this.uploadV2Configuration;
  }

  public ngOnDestroy(): void {
    this.uploadV2Service.reset();
  }

  public onFileDropped(dropEvent: any) {
    dropEvent.preventDefault();

    const files = new Array<File>();
    const filesCount = dropEvent.dataTransfer.items.length;

    for (let i = 1; i <= filesCount; i++) {
      const fileToUpload = dropEvent.dataTransfer.items[i - 1].getAsFile();
      files.push(fileToUpload);
    }

    this.addFiles(files);
  }

  public onDragOver(dragOverEvent: DragEvent) {
    dragOverEvent.preventDefault();
  }

  public onFileAdded(fileAddedEvent: any) {
    let fileList: FileList;
    fileList = fileAddedEvent.target.files as FileList;

    const files = new Array<File>();
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList.item(i));
    }

    this.addFiles(files);
  }

  public onUploadAbort() {
    this.onUploadAborted.next('Upload aborted.');
  }

  private isAcceptedExtension(fileName: string): boolean {
    const fileExtension = fileName.split('.').pop();

    if (this.acceptedExtensions.includes('*')) {
      return true;
    }

    if (this.acceptedExtensions.includes('.' + fileExtension)) {
      return true;
    }

    return false;
  }

  private addFiles(files: File[]) {
    this.invalidExtension = false;

    files.forEach(file => {
      if (!this.isAcceptedExtension(file.name)) {
        this.onInvalidExtension.next('Extension not accepted.');
        this.invalidExtension = true;
        return;
      }

      this.embeddedFileList.push(file); // Internal files list, works with embedded visualization
      this.uploadV2Service.files.push(new UploadEntity(file)); // This is for usage with more advanced additional file list in its on component

      if (!this.multiFileUpload && this.embeddedFileList.length === 1) {
        this.onNewFileSelected.next(file);


        if (this.useEmbeddedFileList) {
          this.showUploadArea = false;
        }

        return;
      }
    });


    if (!this.invalidExtension) {
      this.onNewFilesSelected.next(files);

      if (this.useEmbeddedFileList) {
        this.showUploadArea = false;
      }

    }

    if (this.uploadV2Configuration.autoUpload) {
      this.uploadV2Service.upload();
    }

  }

  public removeFile(i: number) {
    this.embeddedFileList.splice(i, 1);

    if (this.embeddedFileList.length === 0) {
      this.showUploadArea = true;

      this.onFilesCleared.next('All files deleted');
    } else if (this.embeddedFileList.length >= 1) {
      this.onNewFileSelected.next(this.embeddedFileList[0])
    }
  }

  public getAcceptedExtensions(): string {
    let acceptedExtensions: string;

    this.acceptedExtensions.forEach(extension => {
      acceptedExtensions = !acceptedExtensions ? extension : acceptedExtensions + (', ' + extension)
    });

    return acceptedExtensions;
  }
}
