import {Injectable} from '@angular/core';
import {
  AlternativePartNumberControllerService, AlternativePartNumberDefinitionsListRequest, AlternativePartNumberResponse,
  CostCenterControllerService,
  CostCenterControllerV2Service,
  CostCenterResponse,
  GetAlternativePartNumbersByOriginalPartNumberRequestParams,
  PartNumberDefinitionControllerService,
  PartNumberDefinitionResponse,
  PartNumberDefinitionsListRequest,
  ProductCenterControllerService,
  ProductCenterResponse,
  ProductCenterUpdateRequest,
  ProductUnitControllerService,
  ProductUnitResponse,
  ProductUnitUpdateRequest,
  UpdateProductUnitRequestParams
} from '@cstx/volkswagen-mqs-metadata-service-client';
import {CostCenter} from './models/cost-center';
import {firstValueFrom} from 'rxjs';
import {
  GetCostCentersV2RequestParams
} from '@cstx/volkswagen-mqs-metadata-service-client/api/costCenterControllerV2.service';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {ProductUnit} from './models/product-unit';
import {ProductCenter} from "./models/product-center";
import {
  UpdateProductCenterRequestParams
} from "@cstx/volkswagen-mqs-metadata-service-client/api/productCenterController.service";
import {
  UpdateCostCenterRequestParams
} from "@cstx/volkswagen-mqs-metadata-service-client/api/costCenterController.service";
import {
  GetAllPartNumberDefinitionsByPartNumbersListRequestParams
} from "@cstx/volkswagen-mqs-metadata-service-client/api/partNumberDefinitionController.service";
import {
  GetAllAlternativePartNumberDefinitionsByPartNumbersListRequestParams
} from "@cstx/volkswagen-mqs-metadata-service-client/api/alternativePartNumberController.service";

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor(private costCenterControllerServiceV2: CostCenterControllerV2Service,
              private costCenterControllerService: CostCenterControllerService,
              private productUnitControllerService: ProductUnitControllerService,
              private productCenterControllerService: ProductCenterControllerService,
              private partNumberDefinitionControllerService: PartNumberDefinitionControllerService,
              private alternativePartNumberControllerService: AlternativePartNumberControllerService) { }

  public async getCostCenters(): Promise<CostCenter[]> {
    try {

      const params: GetCostCentersV2RequestParams = {
        size: 200
      }

      const response = await firstValueFrom(this.costCenterControllerServiceV2.getCostCentersV2(params));

      if (response && response.content.length > 0) {
        return response.content.map(costCenterResponse => new CostCenter(costCenterResponse));
      }


    } catch (error) {
      LoggingService.logError(LoggingSource.METADATA_SERVICE, 'Error occurred retrieving costCenters from metadata-service', error);
    }

    return null;
  }

  public async updateProductUnit(productUnit: ProductUnit): Promise<ProductUnitResponse> {
    try {
      const request: ProductUnitUpdateRequest = {
        description: productUnit.description ? productUnit.description : productUnit.name,
        isDeactivated: productUnit.isDeactivated,
        name: productUnit.name
      }

      const params: UpdateProductUnitRequestParams = {
        id: productUnit.id,
        productUnitUpdateRequest: request
      }

      return await firstValueFrom(this.productUnitControllerService.updateProductUnit(params));


    } catch (error) {
      LoggingService.logError(LoggingSource.METADATA_SERVICE, 'Error occurred updating product-unit at metadata-service', error);
    }

    return null;
  }

  public async updateProductCenter(productCenter: ProductCenter): Promise<ProductCenterResponse> {
    try {
      const request: ProductCenterUpdateRequest = {
        name: productCenter.name,
        isDeactivated: productCenter.isDeactivated,
        shortName: productCenter.shortName
      }

      const params: UpdateProductCenterRequestParams = {
        id: productCenter.id,
        productCenterUpdateRequest: request
      }

      return await firstValueFrom(this.productCenterControllerService.updateProductCenter(params));


    } catch (error) {
      LoggingService.logError(LoggingSource.METADATA_SERVICE, 'Error occurred updating product-center at metadata-service', error);
    }

    return null;
  }

  public async updateCostCenter(costCenter: CostCenter): Promise<CostCenterResponse> {
    try {
      const request = costCenter.mapToUpdateRequest();

      const params: UpdateCostCenterRequestParams = {
        id: costCenter.id,
        costCenterUpdateRequest: request
      }

      return await firstValueFrom(this.costCenterControllerService.updateCostCenter(params));


    } catch (error) {
      LoggingService.logError(LoggingSource.METADATA_SERVICE, 'Error occurred updating product-center at metadata-service', error);
    }

    return null;
  }

  public async getPartNumberDefinitions(partNumbers: string[]): Promise<PartNumberDefinitionResponse[]> {
    try {

      const request:  PartNumberDefinitionsListRequest = {
        partNumbers
      }

      const params: GetAllPartNumberDefinitionsByPartNumbersListRequestParams = {
        partNumberDefinitionsListRequest: request
      }

      const response =
        await firstValueFrom(this.partNumberDefinitionControllerService.getAllPartNumberDefinitionsByPartNumbersList(params));

      if (response.length > 0) {
        return response;
      }

    } catch (error) {
      LoggingService.logError(LoggingSource.METADATA_SERVICE, 'Error occurred loading partNumberDefinition from metadata-service', error);
    }

    return null;
  }


  public async getPartNumberDefinition(partNumber: string): Promise<PartNumberDefinitionResponse> {
    const result =  await this.getPartNumberDefinitions([ partNumber ]);

    return result[0];
  }

  public async getAlternativePartNumbers(partNumber: string): Promise<Set<string>> {
    try {
      const params: GetAlternativePartNumbersByOriginalPartNumberRequestParams = {
        originalPartNumber: partNumber
      }

      const response =
        await firstValueFrom(this.alternativePartNumberControllerService.getAlternativePartNumbersByOriginalPartNumber(params));
      if (response) {
        return response.alternativePartNumbers;
      }

      return null;

    } catch (error) {
      LoggingService.logError(LoggingSource.METADATA_SERVICE, 'Error occurred loading alternative partNumbers from metadata-service', error);
    }
  }

  public async getAlternativePartNumbersByList(partNumbers: Array<string>): Promise<AlternativePartNumberResponse[]> {
    try {

      const request: AlternativePartNumberDefinitionsListRequest = {
        partNumbers
      }
      const params: GetAllAlternativePartNumberDefinitionsByPartNumbersListRequestParams = {
        alternativePartNumberDefinitionsListRequest: request
      }

      const response =
        await firstValueFrom(this.alternativePartNumberControllerService.getAllAlternativePartNumberDefinitionsByPartNumbersList(params));

      if (response) {
        return response;
      }

      return null;

    } catch (error) {
      LoggingService.logError(LoggingSource.METADATA_SERVICE, 'Error occurred loading alternative partNumbers from metadata-service', error);
    }
  }

}
