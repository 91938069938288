export enum PdfTemplateType {
  UNKNOWN = 'UNKNOWN',
  PETROL_ENGINE_LABEL = 'PETROL_ENGINE_LABEL',
  DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET = 'DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET',

  DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET = 'DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET',

  DIESEL_ENGINE_LABEL_DELPHI = 'DIESEL_ENGINE_LABEL_DELPHI',

  DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET_VECTO = 'DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET_VECTO',

  DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET_VECTO = 'DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET_VECTO',

  DIESEL_ENGINE_LABEL_DELPHI_VECTO = 'DIESEL_ENGINE_LABEL_DELPHI_VECTO',

  OUTPUT_STATISTIC = 'OUTPUT_STATISTIC',
  STOP_NOTIFICATION_INFO = 'STOP_NOTIFICATION_INFO',
  NEWS_INFO = 'NEWS_INFO',
}
