<op-detail-section-wrapper
  [title]="'component-profile.reworks.title' | translate"
  [description]="'component-profile.reworks.description' | translate"
  [contentTemplate]="contentTemplateRef"
  [hasContent]="items.length >= 1"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <ng-container *ngIf="items.length >= 1">
    <ngx-treeview class="cpp-treeview-h-100"
                  [itemTemplate]="treeViewItemTemplate"
                  [config]="treeViewConfig"
                  [items]="treeViewItems">
    </ngx-treeview>
  </ng-container>
</ng-template>

<ng-template #treeViewItemTemplate let-treeViewItem="item" let-onCollapseExpand="onCollapseExpand">
  <div class="p-0 pt-1 m-0" style="display: flex; line-height: 19px">
    <i *ngIf="treeViewItem.value.isItemModeType === true"
       (click)="onCollapseExpand()"
       aria-hidden="true"
       class="pr-1"
       [ngSwitch]="treeViewItem.collapsed">

      <i *ngSwitchCase="false" class="fas fa-fw fa-chevron-down small" pr-1></i>
      <i *ngSwitchCase="true" class="fas fa-fw fa-chevron-right small pr-1"></i>
    </i>

    <div *ngIf="treeViewItem.value.isItemModeType === true" class="small text-center p-0" style="width: 25px; text-align: center !important;">
      <i [class.cpp-component-profile-reworks-state-open]="treeViewItem.value.reworkState === 'OPEN'"
         [class.cpp-component-profile-reworks-state-unknown]="treeViewItem.value.reworkState === 'UNKNOWN'"
         [class.cpp-component-profile-reworks-state-closed]="treeViewItem.value.reworkState === 'CLOSED'"
         [class.cpp-component-profile-reworks-state-approved]="treeViewItem.value.reworkState === 'APPROVED'"

         class="fa-fw"
         aria-hidden="true"></i>
    </div>

    <label *ngIf="treeViewItem.value.isItemModeType === true; else details" class="form-check-label text-overflow-ellipses text-monospace small">
      <op-datetime-show [dateTime]="treeViewItem.text"></op-datetime-show>
    </label>
    <ng-template #details>
      <div class="form-check-label pb-1" *ngIf="treeViewItem.value">
        <div class="col small text-overflow-ellipses" [class.d-inline-block]="!viewSizeXS" style="min-width: 100px; max-width: 200px; font-weight: 500">{{ treeViewItem.text }}:</div>
        <div class="col small" [class.cpp-component-profile-reworks-viewSizeXS]="viewSizeXS"  [class.d-inline-block]="!viewSizeXS" style="min-width: 100px;">
          <ng-container *ngIf="!dateTimeFields.includes(treeViewItem.text); else dateTimeDetail">
            {{ treeViewItem.value }}
          </ng-container>
          <ng-template #dateTimeDetail>
            <op-datetime-show [dateTime]="treeViewItem.value"></op-datetime-show>
          </ng-template>

        </div>
      </div>
    </ng-template>
    <span *ngIf="treeViewItem.value.isItemModeType === true" class="small pl-3 d-none d-sm-inline-block">{{ treeViewItem.value.analysis }}</span>

    <span *ngIf="treeViewItem.value.isItemModeType === true" class="small pl-3 d-none d-sm-inline-block"><op-component-state-badge [prependIcon]="true" [useIconsAndText]="true" [name]="treeViewItem.value.reworkType + '_REWORK'"></op-component-state-badge></span>
    <span *ngIf="treeViewItem.value.isItemModeType === true" class="small pl-3 d-inline-block d-sm-none"><op-component-state-badge [useIcons]="true" [name]="treeViewItem.value.reworkType + '_REWORK'"></op-component-state-badge></span>

  </div>
</ng-template>



