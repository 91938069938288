import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {RefreshConfig} from './models/refresh-config';
import {LoggingService} from '../../../core/logging/logging.service';
import {distinctUntilChanged} from 'rxjs/operators';
import {VisibilityService} from '../../../core/services/visibility.service';
import {LoggingSource} from '../../../core/logging/loggingSource';

@Component({
  selector: 'op-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss']
})
export class RefreshComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() triggerAnimation = new Subject<boolean>();
  @Input() triggerActive = new Subject<boolean>();
  @Input() refreshConfig = new RefreshConfig(false);

  // Event occurring when somebody clicks the symbol
  @Output() refreshEvent = new EventEmitter<any>();

  // Event occurring when the timer ticks
  @Output() autoRefreshEvent = new EventEmitter<any>();

  // Event occurring when the selected update interval was changed
  @Output() selectedIntervalChanged = new EventEmitter<number>();

  isRefreshing = true;
  updateTimer: any;
  fadeout: boolean;
  active = true;
  windowVisible: boolean;
  windowVisibleSub: Subscription;

  constructor(private visibilityService: VisibilityService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshAnimation();
  }

  ngAfterViewInit(): void {
    this.windowVisibleSub = this.visibilityService.pageVisible$.subscribe(
      visibility => this.windowVisible = visibility);
    if (this.refreshConfig.getAutoReloadEnabled()) {
      this.updateTimer = setInterval(() => this.onRefreshTimerTick(), this.refreshConfig.getAutoReloadInterval());
    }

    this.triggerAnimation.subscribe(value => {
      if (value as boolean) {
        this.isRefreshing = true;
      } else {
        this.fadeout = true;
        setTimeout(() => this.resetRefreshing(), 1000);
      }
    });

    this.triggerActive.pipe(distinctUntilChanged())
    .subscribe(value => {
      this.active = value as boolean;
      if (value as boolean) {
        this.onRefreshIntervalChanged(this.refreshConfig.getAutoReloadInterval());
      } else {
        this.onRefreshIntervalChanged('Off');
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.updateTimer);
    this.triggerAnimation.unsubscribe();
    this.triggerActive.unsubscribe();
    this.windowVisibleSub.unsubscribe();
  }

  onRefreshClicked() {
    this.refreshEvent.emit();
  }

  onRefreshTimerTick() {
    if (this.windowVisible) {
      this.autoRefreshEvent.emit();
    }
  }

  onRefreshIntervalChanged(value: any) {
    if (value === 'Off') {
      this.selectedIntervalChanged.emit(0);
      this.refreshConfig.setAutoReloadEnabled(false);
    } else {
      this.selectedIntervalChanged.emit(value);
      this.refreshConfig.setAutoReloadEnabled(true);
      // tslint:disable-next-line:radix
      this.refreshConfig.setAutoReloadInterval(parseInt(value));
    }

    this.renewTimer();
  }

  refreshAnimation() {
    if (this.isRefreshing && this.fadeout) {
      return ['fa-spin', 'font-blue'];
    }

    if (this.isRefreshing) {
      return ['fa-spin', 'font-yellow'];
    }

    return 'font-blue';
  }

  private renewTimer() {
    LoggingService.logDebug(LoggingSource.REFRESH_COMPONENT, 'renewTimer in refresh component called.');
    clearInterval(this.updateTimer);

    if (this.refreshConfig.getAutoReloadEnabled()) {
      LoggingService.logDebug(LoggingSource.REFRESH_COMPONENT,
        'creating new Timer with intervall: ' + this.refreshConfig.getAutoReloadInterval());
      this.updateTimer = setInterval(() => this.onRefreshTimerTick(), this.refreshConfig.getAutoReloadInterval());
    } else {
      LoggingService.logDebug(LoggingSource.REFRESH_COMPONENT,'autoRefresh was disabled');
    }
  }

  private resetRefreshing() {
    this.isRefreshing = false;
    this.fadeout = false;
  }
}
