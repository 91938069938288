import {DlqJob} from './dlqJob';

export class DlqResendJob extends DlqJob {
  public resendJobStatus: string;
  public numberOfResendMessages: number;

  public get jobStatus() {
    return this.resendJobStatus.toString();
  }

  public get numberOfMessagesDone(): number {
    return this.numberOfResendMessages;
  }
}
