<op-detail-section-wrapper
  [title]="'component-profile-v2.keycode-history.title' | translate"
  [description]="'component-profile-v2.keycode-history.description'  | translate"
  [contentTemplate]="contentTemplateRef"
  [hasContent]="componentProfile.KeyCodeHistory.length >= 1"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <div class="pl-3">
  <table class="table table-hover">
    <tr class="row pr-3">
      <td class="cpp-column-fixed-width-75 small">
        {{ 'engine.card.keycode.keyCodeOld' | translate }}
      </td>
      <td class="cpp-column-fixed-width-40"></td>
      <td class="cpp-column-fixed-width-75 small">
        {{ 'engine.card.keycode.keyCodeNew' | translate }}
      </td>
      <td class="cpp-column-fixed-date pl-0 small">
        {{ 'engine.card.keycode.keyCodeChangedAt' | translate }}
      </td>
      <td class="cpp-column-fixed-width-150 small">
        {{ 'Hottest' }}
      </td>
      <td class="cpp-column-fixed-width-150 small">
        {{ 'Kalttest' }}
      </td>
      <td class="cpp-column-fixed-width-130"></td>
    </tr>
    <tbody>
    <op-loading-spinner *ngIf="loading"></op-loading-spinner>
    <tr *ngIf="componentProfile.KeyCodeHistory.length === 0 && !loading" class="row pt-3 pb-3" >
      <div class="col pl-0 text-center">
        <small> {{ 'global.nothing-to-show' | translate }}</small>
      </div>
    </tr>
    <ng-container *ngFor="let element of componentProfile.KeyCodeHistory; index as i">
      <tr class="row pr-3" >
        <td class="cpp-column-fixed-width-75 small">
          {{ element.keyCodeOld }}
        </td>
        <td class="cpp-column-fixed-width-40">
          <i class="fas fa-long-arrow-alt-right"></i>
        </td>
        <td class="cpp-column-fixed-width-75 small">
          {{ element.keyCodeNew }}
        </td>
        <td class="cpp-column-fixed-date pl-0 small" [class.cpp-border-top-none]="viewSizeXS">
          <op-datetime-show [dateTime]="element.keyCodeChangedAt"></op-datetime-show>
        </td>
        <td class="cpp-column-fixed-width-150 small">
          <ng-container *ngIf="editTestInvalidate !== i">
            {{ (element.hotTestInheritance === 'KEEP' ? 'test-result.keep' : 'test-result.invalid') | translate }}
          </ng-container>
          <ng-container *ngIf="editTestInvalidate === i">
            <select class="form-control form-control-sm shadow-none" style="margin-top: -5px; ; margin-left: -10px"
                    [(ngModel)]="element.hotTestInheritance">
              <option *ngFor="let option of testInheritance"
                      [ngValue]="option.value">{{option.label}}</option>
            </select>
          </ng-container>
        </td>
        <td class="cpp-column-fixed-width-150 small">
          <ng-container *ngIf="editTestInvalidate !== i">
            {{ (element.coldTestInheritance === 'KEEP' ? 'test-result.keep' : 'test-result.invalid') | translate }}
          </ng-container>
          <ng-container *ngIf="editTestInvalidate === i">
            <select class="form-control form-control-sm shadow-none" style="margin-top: -5px; margin-left: -10px"
                    [(ngModel)]="element.coldTestInheritance">
              <option *ngFor="let option of testInheritance"
                      [ngValue]="option.value">{{option.label}}</option>
            </select>
          </ng-container>
        </td>

        <td class="col" *opRequiredRole="'role_admin'">
          <button *ngIf="editTestInvalidate !== i" type="button" class="btn btn-primary"
                  style="font-size: .50rem; margin-top: -5px" (click)="handleEditTestInvalidate(i, element)">
            {{ 'button.edit' | translate }}
          </button>
          <div *ngIf="editTestInvalidate === i && !loadingTestInvalidate" class="d-inline">
            <button type="button" class="btn btn-secondary"
                    style="font-size: .50rem; margin-top: -5px" (click)="handleAbortEditTestInvalidate(element)">
              {{ 'button.cancel' | translate }}
            </button>
            <button type="button" class="btn btn-primary ml-1"
                    style="font-size: .50rem; margin-top: -5px" (click)="handleConfirmEditTestInvalidate(element)">
              {{ 'button.save' | translate }}
            </button>
          </div>
          <div *ngIf="editTestInvalidate === i && loadingTestInvalidate" class="spinner-border spinner-border-sm"></div>
        </td>

      </tr>
      <tr *ngIf="editTestInvalidate === i && errorTestInvalidate" class="row">
        <td class="small text-right col-9 border-0">
          <i class="fas fa-exclamation-circle text-danger pr-1"></i>
          <span class="text-danger">{{'page.fct.message.error-on-save' | translate}}</span>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  </div>
</ng-template>
