import { Injectable } from '@angular/core';
import {CostCenterFilter} from '../models/costCenterFilter';
import {ProductCenterFilter} from '../models/productCenterFilter';
import {ProductUnitFilter} from '../models/productUnitFilter';
import {firstValueFrom} from 'rxjs';
import {
  CostCenterControllerService,
  CostCenterControllerV2Service,
  CreateProductUnitRequestParams,
  ProductCenterControllerService,
  ProductCenterControllerV2Service, ProductCenterCreateRequest, ProductCenterUpdateRequest,
  ProductUnitControllerService,
  ProductUnitUpdateRequest, UpdateProductUnitRequestParams
} from '@cstx/volkswagen-mqs-metadata-service-client';
import {Slice} from '../../../shared/models/slice';
import {Cell, ExcelExport, Row} from '../../../shared/components/excel-export/excelExport';
import {ErrorHandler} from '../../../shared/services/error-handler/error-handler';
import {UpdateCostCenterRequestParams} from '@cstx/volkswagen-mqs-metadata-service-client/api/costCenterController.service';
import {ProductUnitCreateRequest} from '@cstx/volkswagen-mqs-metadata-service-client/model/productUnitCreateRequest';
import {
  CreateProductCentersRequestParams,
  UpdateProductCenterRequestParams
} from '@cstx/volkswagen-mqs-metadata-service-client/api/productCenterController.service';
import {ProductUnit} from '../../../shared/services/backend/models/product-unit';
import {ProductCenter} from "../../../shared/services/backend/models/product-center";
import {CostCenter} from "../../../shared/services/backend/models/cost-center";

@Injectable({
  providedIn: 'root'
})
export class MetadataV2Service {

  constructor(private costCenterControllerService: CostCenterControllerService,
              private costCenterControllerServiceV2: CostCenterControllerV2Service,
              private productUnitControllerService: ProductUnitControllerService,
              private productCenterController: ProductCenterControllerService,
              private productCenterControllerV2: ProductCenterControllerV2Service) { }


  /**
   * Gets a costCenter by given id.
   * @param id The id of the costCenter.
   */
  public async getCostCenterById(id: string): Promise<CostCenter> {
    let result: CostCenter;

    try {
      const response = await firstValueFrom(this.costCenterControllerService.getCostCenterById( { id }));
      result = new CostCenter(response);
    } catch (error) {
      ErrorHandler.printError(error);
    }

    return result;
  }

  /**
   * Deletes a costCenter by given id.
   * @param id The id of the object we want to delete.
   */
  public async deleteCostCenter(id: string): Promise<void> {
    return await firstValueFrom(this.costCenterControllerService.deleteCostCenter({id}));
  }

  /**
   * Creates a costCenter.
   * @param productCenterId The id of the product center we are about to create a costCenter for.
   * @param costCenter The object to be created.
   */
  public async createCostCenter(productCenterId: string, costCenter: CostCenter): Promise<CostCenter> {
    const response = await firstValueFrom(this.productCenterController.createCostCenterForProductCenter({
      id: productCenterId,
      costCenterCreateRequest: {
        nr: costCenter.nr,
        name: costCenter.name,
        isDeactivated: costCenter.isDeactivated,
        productCenterId: costCenter.productCenterId,
        productUnitId: costCenter.productUnitId
      }
    }));

    if (response) {
      return new CostCenter(response);
    }

    return null;
  }

  /**
   * Updates a costCenter.
   * @param id The id of the object we want to update.
   * @param costCenter The object to be updated.
   */
  public async updateCostCenter(id: string, costCenter: CostCenter): Promise<CostCenter> {
    let result: CostCenter;

    try {
      const request = costCenter.mapToUpdateRequest();
      let params: UpdateCostCenterRequestParams;

      params = {
        id: costCenter.id,
        costCenterUpdateRequest: request
      }

      const response = await firstValueFrom(this.costCenterControllerService.updateCostCenter(params));
      result = new CostCenter(response);
    } catch (error) {
      ErrorHandler.printError(error);
    }

    return result;
  }


  /**
   * Gets all costCenter for the given filter.
   * @param filter The filter to use for the request
   */
  public async getCostCenters(filter: CostCenterFilter): Promise<{ items: CostCenter[], slice: Slice }> {
    const result = new Array<CostCenter>();
    const response = await firstValueFrom(this.costCenterControllerServiceV2.getCostCentersV2(filter.getAllParams()));

    if (response.content.length >= 1) {
      response.content.forEach(c => {
        result.push(new CostCenter(c));
      });
    }

    const resultSlice = new Slice(response.first, response.last, response.page, response.size, response.empty);
    return { items: result, slice: resultSlice };
  }

  /**
   *
   * @param id The id of the object we want to delete
   */
  public async deleteProductCenter(id: string) {
    await firstValueFrom(this.productCenterController.deleteProductCenter({ id }));
  }

  /**
   * Gets all productCenter for the given filter.
   * @param filter The filter to use for the request
   */
  public async getProductCenters(filter: ProductCenterFilter): Promise<ProductCenter[]> {
    const result = new Array<ProductCenter>();

    const response = await firstValueFrom(this.productCenterControllerV2.getProductCentersV2(filter));

    response.content.forEach(r => {
      const productCenter = new ProductCenter(r);

      result.push(productCenter);
    });


    return result;
  }

  /**
   * Creates a productCenter.
   * @param productCenter The object to be created.
   */
  public async createProductCenter(productCenter: ProductCenter): Promise<ProductCenter> {
    let result = new ProductCenter();

    let productCenterCreateRequest: ProductCenterCreateRequest;
    productCenterCreateRequest = {
      isDeactivated: productCenter.isDeactivated,
      shortName: productCenter.shortName,
          name: productCenter.name
    }

    let params: CreateProductCentersRequestParams;
    params = {
      productCenterCreateRequest
    }

    const response = await firstValueFrom(this.productCenterController.createProductCenters(params));
    result = result.mapFromProductCenterResponse(response);
    return result;
  }

  /**
   * Updates a productCenter.
   * @param id The id of the object we want to update.
   * @param productCenter The object to be updated.
   */
  public async updateProductCenter(id: string, productCenter: ProductCenter): Promise<ProductCenter> {
    let result = new ProductCenter();

    let productCenterUpdateRequest: ProductCenterUpdateRequest;
    productCenterUpdateRequest = {
      isDeactivated: productCenter.isDeactivated,
      shortName: productCenter.shortName,
      name: productCenter.name
    }

    let params: UpdateProductCenterRequestParams;
    params = {
      id,
      productCenterUpdateRequest
    }

    const response = await firstValueFrom(this.productCenterController.updateProductCenter(params));
    result = result.mapFromProductCenterResponse(response);
    return result;
  }

  /**
   * Deletes a product unit by given id.
   * @param id The id of the object we want to delete.
   */
  public async deleteProductUnit(id: string): Promise<void> {
    return await firstValueFrom(this.productUnitControllerService.deleteProductUnit({id}));
  }

  /**
   * Gets all productUnits for the given filter.
   * @param filter The filter to use for the request
   */
  public async getProductUnits(filter: ProductUnitFilter): Promise<ProductUnit[]> {
    const result = new Array<ProductUnit>();
    const response = await firstValueFrom(this.productUnitControllerService.getProductUnits(filter.getAllParams()));

    response.content.forEach(r => {
      result.push(new ProductUnit(r));
    })

    return result;
  }

  /**
   * Creates a new product unit with the given properties.
   * @param productUnit The product unit we are going to create.
   */
  public async createProductUnit(productUnit: ProductUnit): Promise<ProductUnit> {
    let result = new ProductUnit();

    try {
      let request: ProductUnitCreateRequest;
      request = {
        name: productUnit.name,
        description: productUnit.description ? productUnit.description : productUnit.name
      }

      let params: CreateProductUnitRequestParams;
      params = {
        productUnitCreateRequest: request
      }

      const response = await firstValueFrom(this.productUnitControllerService.createProductUnit(params));

      result = new ProductUnit(response);
    } catch (error) {
      ErrorHandler.printError(error);
    }

    return result;
  }

  /**
   * Updates a productUnit.
   * @param id The id of the object we want to update.
   * @param productUnit The object to be updated.
   */
  public async updateProductUnit(id: string, productUnit: ProductUnit): Promise<ProductUnit> {
    let result = new ProductUnit();

    let request: ProductUnitUpdateRequest;
    request = {
      name: productUnit.name,
      description: productUnit.description ? productUnit.description : productUnit.name
    }

    let params: UpdateProductUnitRequestParams;
    params = {
      id,
      productUnitUpdateRequest: request
    }

    const response = await firstValueFrom(this.productUnitControllerService.updateProductUnit(params));

    result = new ProductUnit(response);
    return result;
  }

  /**
   * Generates an Excel export object from a cost center array.
   * @param costCenters An array of cost centers the export will be generated for.
   */
  public createCostCentersExcelExport(costCenters: CostCenter[]): ExcelExport {
    const excelExport = new ExcelExport();

    const headers = new Array<string>();
    headers.push('id');
    headers.push('name');
    headers.push('nr');
    headers.push('modifiedBy');
    headers.push('modifiedAt');

    excelExport.headers = headers;
    excelExport.rows = new Array<Row>();

    costCenters.forEach((cc, index) => {
      const excelRow = new Row();
      excelRow.cells = new Array<Cell>();

      excelRow.cells.push(new Cell(cc.id, 'text'));
      excelRow.cells.push(new Cell(cc.name, 'text'));
      excelRow.cells.push(new Cell(cc.nr, 'text'));
      excelRow.cells.push(new Cell(cc.modifiedBy, 'text'));
      excelRow.cells.push(new Cell(cc.modifiedAt, 'text'));

      excelExport.rows.push(excelRow);
    });


    return excelExport;
  }

  /**
   * Generates an Excel export object from a product units array.
   * @param productUnits An array of product units the export will be generated for.
   */
  public createProductUnitsExcelExport(productUnits: ProductUnit[]): ExcelExport {
    const excelExport = new ExcelExport();

    excelExport.title = 'Produkteinheit';

    const headers = new Array<string>();
    headers.push('Name');
    headers.push('Geändert am');
    headers.push('Geändert von');

    excelExport.headers = headers;
    excelExport.rows = new Array<Row>();

    productUnits.forEach((e, index) => {
      const excelRow = new Row();
      excelRow.cells = new Array<Cell>();

      excelRow.cells.push(new Cell(e.name, 'text'));
      excelRow.cells.push(new Cell(e.modifiedDate, 'text'));
      excelRow.cells.push(new Cell(e.modifiedBy, 'text'));

      excelExport.rows.push(excelRow);
    });

    return excelExport;
  }

  /**
   * Generates an Excel export object from a product centers array.
   * @param productCenters An array of product centers the export will be generated for.
   */
  public createProductCentersExcelExport(productCenters: ProductCenter[]): ExcelExport {
    const excelExport = new ExcelExport();

    excelExport.title = 'Produktcenter';

    const headers = new Array<string>();
    headers.push('Nummer');
    headers.push('Name');
    headers.push('Geändert am');
    headers.push('Geändert von');

    excelExport.headers = headers;
    excelExport.rows = new Array<Row>();

    productCenters.forEach((e, index) => {
      const excelRow = new Row();
      excelRow.cells = new Array<Cell>();

      excelRow.cells.push(new Cell(e.name, 'text'));
      excelRow.cells.push(new Cell(e.shortName, 'text'));
      excelRow.cells.push(new Cell(e.modifiedDate, 'text'));
      excelRow.cells.push(new Cell(e.modifiedBy, 'text'));

      excelExport.rows.push(excelRow);
    });


    return excelExport;
  }
}
