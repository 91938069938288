import { Component } from '@angular/core';

@Component({
  selector: 'op-beta-flag',
  templateUrl: './beta-flag.component.html',
  styleUrls: ['./beta-flag.component.scss']
})
export class BetaFlagComponent {

}
