<op-card title="{{ 'events.title' | translate }}" [showActions]="true" [showRefresh]="true"
         (refreshEvent)="onRefreshEvent()" [rightHeaderTemplateExtension]="eventViewSwitcher">
  <ng-template #eventViewSwitcher>
  <!--div class="ml-3 btn-group btn-group-sm btn-group-toggle" data-toggle="buttons">
    <label class="btn btn-sm btn-secondary active">
      <input type="radio" value="table" checked [(ngModel)]="displayOption"> {{ 'events.table' | translate }}
    </label>
    <label class="btn btn-sm btn-secondary">
      <input type="radio" value="timeline" [(ngModel)]="displayOption"> {{ 'events.timeline' | translate }}
    </label>
  </div-->
  </ng-template>
  <op-spinner-small *ngIf="loading"></op-spinner-small>
  <ng-container *ngIf="!loading">
    <!--div *ngIf="displayOption==='timeline'; else table" class="col p-5">
    <mgl-timeline [toggle]="false">
      <mgl-timeline-entry *ngFor="let event of trackingEvents">
        <mgl-timeline-entry-header>
          <i *ngIf="!event.valid" class="fas fa-exclamation-triangle warning-icon" aria-hidden="true"
             tooltip="{{ 'events.invalid' | translate }}"
             tooltipPlacement="top" container="body"></i>
          {{ 'events.eventType.'+ event.eventType + '.title' | translate: {Default: event.eventType.charAt(0).toUpperCase() + event.eventType.toLowerCase().slice(1) } }}
        </mgl-timeline-entry-header>
        <mgl-timeline-entry-side>{{ event.eventTime | date:'dd.MM.yyyy HH:mm:ss' }}</mgl-timeline-entry-side>
        <mgl-timeline-entry-content>
          <op-event-tracking-additional-properties [event]="event"></op-event-tracking-additional-properties>
        </mgl-timeline-entry-content>
        <mgl-timeline-entry-dot [size]="20" [class]="getTimelineStyle(event.eventType)"></mgl-timeline-entry-dot>
      </mgl-timeline-entry>
    </mgl-timeline>
    </div>
    <ng-template #table-->
    <table class="table table-borderless table-striped p-0 mb-0">
      <thead class="d-flex pl-3 pr-3">
      <th class="status-column"></th>
      <th class="col-sm-3">
        {{ 'events.eventTime' | translate }}
      </th>
      <th class="col-sm-3">
        {{ 'events.type' | translate }}
      </th>
      <th class="col-sm-6">
        {{ 'events.origin' | translate }}
      </th>
      </thead>
      <tbody>
      <ng-container *ngIf="trackingEvents.length > 0">
        <tr class="d-flex pl-3 pr-3" *ngFor="let event of trackingEvents;">
          <td class="status-column px-0 d-flex">
            <i *ngIf="!event.valid" class="fas fa-exclamation-triangle warning-icon mx-auto" aria-hidden="true"
               tooltip="{{ 'events.invalid' | translate }}"
               tooltipPlacement="top" container="body"></i>
          </td>
          <td class="col-sm-3">{{ event.eventTime | date:'dd.MM.yyyy HH:mm:ss' }}</td>
          <td class="col-sm-3">
            {{ 'events.eventType.'+ event.eventType + '.title' | translate:
            {Default: event.eventType.charAt(0).toUpperCase() + event.eventType.toLowerCase().slice(1) } }}
          </td>
          <td class="col-sm-6">{{ event.origin }}</td>
        </tr>
      </ng-container>
      <ng-container *ngIf="trackingEvents.length === 0">
        <tr class="d-flex">
          <td class="col-sm text-center">{{ 'global.nothing-to-show' | translate }}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <!--/ng-template-->
  </ng-container>
</op-card>
