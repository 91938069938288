import {ComponentTypeEnum} from '@cstx/volkswagen-mqs-file-handling-service-client';

export class FileUpload {
  public componentId: string;
  public componentType: ComponentTypeEnum;
  public costCenter: string;
  public source: string;
  public file: any;
  public fileName: string;
  public s3Key?: string;
}
