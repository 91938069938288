import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckDigitService {
  private modulo43CharacterTable = [
    { key: '0', value: '0'},
    { key: '1', value: '1'},
    { key: '2', value: '2'},
    { key: '3', value: '3'},
    { key: '4', value: '4'},
    { key: '5', value: '5'},
    { key: '6', value: '6'},
    { key: '7', value: '7'},
    { key: '8', value: '8'},
    { key: '9', value: '9'},
    { key: 'A', value: '10'},
    { key: 'B', value: '11'},
    { key: 'C', value: '12'},
    { key: 'D', value: '13'},
    { key: 'E', value: '14'},
    { key: 'F', value: '15'},
    { key: 'G', value: '16'},
    { key: 'H', value: '17'},
    { key: 'I', value: '18'},
    { key: 'J', value: '19'},
    { key: 'K', value: '20'},
    { key: 'L', value: '21'},
    { key: 'M', value: '22'},
    { key: 'N', value: '23'},
    { key: 'O', value: '24'},
    { key: 'P', value: '25'},
    { key: 'Q', value: '26'},
    { key: 'R', value: '27'},
    { key: 'S', value: '28'},
    { key: 'T', value: '29'},
    { key: 'U', value: '30'},
    { key: 'V', value: '31'},
    { key: 'W', value: '32'},
    { key: 'X', value: '33'},
    { key: 'Y', value: '34'},
    { key: 'Z', value: '35'},
    { key: '-', value: '36'},
    { key: '.', value: '37'},
    { key: ' ', value: '38'},
    { key: '$', value: '39'},
    { key: '/', value: '40'},
    { key: '+', value: '41'},
    { key: '%', value: '42'},
  ]

  public GenerateCheckDigit(labelContent: string, type: CheckDigitType = CheckDigitType.Modulo43, valueHasCheckDigit = true): string {
    if (valueHasCheckDigit) {
      labelContent = labelContent.substring(0, labelContent.length -1)
    }

    let checkDigit;

    if (type === CheckDigitType.Modulo43) {
      checkDigit = this.generateModulo43CheckDigit(labelContent);
    }

    if (type === CheckDigitType.Modulo10) {
      checkDigit = this.generateModulo10CheckDigit(labelContent);
    }

    return checkDigit;
  }

  private generateModulo10CheckDigit(labelContent: string): string {
    let checkDigit: number;
    let checkDigitSum = 0;

    // Make a char array from the given string
    const labelContentArray = labelContent.split('');

    // Reverse the array to start the weighting of the chars from the right side  (end) of the string
    const labelContentArrayReversed = labelContentArray.reverse();

    let isWeightThree = true;
    labelContentArrayReversed.forEach(e => {
      // tslint:disable-next-line:radix
      const parsedNumber = parseInt(e);
      if (isNaN(parsedNumber)) {
        // if the label content contains non number chars, we return undefined
        return checkDigit;
      }

      let weightedResult = 0;
      if (isWeightThree) {
        weightedResult = parsedNumber * 3;
        isWeightThree = false;
      } else {
        weightedResult = parsedNumber;
        isWeightThree = true;
      }

      checkDigitSum = checkDigitSum + weightedResult;
    });

    // Get the last digit of the calculated number
    const modulo10 = checkDigitSum.toString().substring(checkDigitSum.toString().length - 1, checkDigitSum.toString().length);

    // Calculate check digit as it is the difference between ten and the modulo10 char
    // tslint:disable-next-line:radix
    checkDigit = 10 - parseInt(modulo10);

    return checkDigit.toString();
  }

  private generateModulo43CheckDigit(labelContent: string): string {
    let checkDigit: string;

    let checkDigitSum = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < labelContent.length; i++) {
      const charValue = this.modulo43CharacterTable.find(c => c.key === labelContent[i])?.value;
      // tslint:disable-next-line:radix
      checkDigitSum = checkDigitSum + parseInt(charValue);
    }

    if (checkDigitSum > 0) {
      const result = checkDigitSum / 43;
      const truncatedResult = Math.trunc(result)

      const rest = checkDigitSum - (truncatedResult * 43);

      checkDigit = this.modulo43CharacterTable.find(c => c.value === rest.toString()).key;
    }

    return checkDigit;
  }
}

export enum CheckDigitType {
  Modulo43 = 'MODULO43',

  Modulo10 = 'MODULO10'
}


