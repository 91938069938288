import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ChartData, ChartDataset, ChartType, Color, Plugin} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import {UserConfigService} from '../../services/user-config.service';
import {BarChartStyleTheme} from '../../models/userConfig';
import {LocalStorageManagerService} from '../../services/local-storage-manager.service';
import {CostCenter} from '../../services/backend/models/cost-center';
import {MetadataService} from '../../services/backend/metadata.service';
import {ManufacturingStatisticResponse} from '@cstx/volkswagen-mqs-manufacturing-monitor-service-client';


@Component({
  selector: 'op-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges {
  @Input() datasets: ChartData<'bar', Array<number>>;
  @Input() labels: Array<string>;
  @Input() options: any;
  @Input() legend: any;
  @Input() update: Subject<void>;
  @Input() groupID: string;
  @Input() mode: BarChartStyleTheme = BarChartStyleTheme.FillingBar;
  @Input() statistics= new Array<ManufacturingStatisticResponse>()

  public costCenters = new Array<CostCenter>();

  chartType: ChartType = 'bar';
  barChartPlugins: Array<Plugin> = new Array<Plugin>(DataLabelsPlugin);

  @Input() colors: Array<Color>;
  @ViewChild(BaseChartDirective) private chart: BaseChartDirective;
  private unsubscribe$ = new Subject<void>();

  shownData: ChartData<'bar', Array<number>> = {
    datasets: (new Array<ChartDataset<'bar', Array<number>>>()),
    labels: new Array<string>()
  };

  graphToggleHidden = true;
  useAnimation = false;
  displayedGraphs: Array<{ id: string, shown: boolean, costCenterId?: string, statistic?: ManufacturingStatisticResponse }>
    = new Array<{ id: string, shown: boolean, costCenterId?: string, statistic?: ManufacturingStatisticResponse }>();

  private allPossibleGraphs: Map<string, boolean> = new Map<string, boolean>();
  needsScrollOffset = false;



  constructor(private userConfigService: UserConfigService,
              private metadataService: MetadataService ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mode) {
      this.filterCharts();
    }
  }

  public async ngOnInit() {
  }

  private tryFindCostCenterId(costCenterNumber: string): string {
    const existingIndex = this.costCenters.findIndex(costCenter => costCenterNumber === costCenter.number);

    if (existingIndex !== -1) {
      return this.costCenters[existingIndex].id;
    }

    return null;
  }

  public tryFindCostCenterStatistic(costCenterNumber: string): ManufacturingStatisticResponse  {
    const existingIndex =
      this.statistics
        .findIndex(statistic => costCenterNumber === statistic.costCenter && statistic.status === 'Active');


    if (existingIndex !== -1) {
      return this.statistics[existingIndex];
    }

    return null;
  }


  ngAfterViewInit(): void {
    const configString = LocalStorageManagerService.getItem('displayedGraphs-' + this.groupID);
    if (configString) {
      try {
        (JSON.parse(configString) as Array<any>).forEach(val => this.allPossibleGraphs.set(val.id, val.shown));
      } catch {
        this.allPossibleGraphs.clear();
      }
    }
    this.update.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.filterCharts();

        this.metadataService.getCostCenters().then(result => {
          if (result) {
            this.costCenters = result;

            this.displayedGraphs.forEach(graph => {
              graph.costCenterId = this.tryFindCostCenterId(graph.id);

              graph.statistic = this.tryFindCostCenterStatistic(graph.id);
            })

          }
        });
      });
    this.userConfigService.config$.pipe(takeUntil(this.unsubscribe$)).subscribe(conf => {
      if (conf.dashboard?.productCenters && conf.dashboard.productCenters[this.groupID]) {
        Object.keys(conf.dashboard.productCenters[this.groupID])
          .forEach(key => {
            const graph = this.displayedGraphs.find(entry => entry.id === key)
            if (graph) {
              graph.shown = !(conf.dashboard.productCenters[this.groupID][key].hidden);
            } else {
              this.displayedGraphs.push({id: key, shown: !(conf.dashboard.productCenters[this.groupID][key].hidden)});
            }
          });
      }
      this.filterCharts();
    });
    this.filterCharts();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  toggleCostcenterDisplay() {
    this.useAnimation = true;

    this.graphToggleHidden = !this.graphToggleHidden;

    if (this.graphToggleHidden) {
      this.needsScrollOffset = false;
    } else {
      const el = document.getElementById('ccContainer_' + this.groupID);
        setTimeout(() => {
          if (el && el.offsetWidth > el.clientWidth) {
            this.needsScrollOffset = true;
          }
        }, 0);
    }
  }

  toggleChart(index: number) {
    this.displayedGraphs[index].shown = !this.displayedGraphs[index].shown;
    this.filterCharts();
    const saveObj: Array<{id: string, shown: boolean }> = new Array<{id: string, shown: boolean }>();
    this.allPossibleGraphs.forEach((val, key) => saveObj.push({id: key, shown: val }));

    const configurationPatch = {dashboard: { productCenters: {} } };
    configurationPatch.dashboard.productCenters[this.groupID] = {};

    saveObj.forEach(value => {
      if (value.id !== 'hidden') {
        configurationPatch.dashboard.productCenters[this.groupID][value.id] = {hidden: !value.shown}
      }
    });

    this.userConfigService.pushConfig(configurationPatch);
  }

  private filterCharts() {
    this.options = {...this.options};
    this.checkAvailableGraphs();

    this.shownData = {
      datasets: new Array<any>({
          ...this.datasets.datasets[0],
          data: this.datasets.datasets[0].data
            .filter((value, index) => this.displayedGraphs[index].shown),
          backgroundColor: (this.datasets.datasets[0].backgroundColor as any)
            .filter((value, index) => this.displayedGraphs[index].shown),
          hoverBackgroundColor: (this.datasets.datasets[0].hoverBackgroundColor as any)
            .filter((value, index) => this.displayedGraphs[index].shown),
          borderColor: (this.datasets.datasets[0].borderColor as any)
            .filter((value, index) => this.displayedGraphs[index].shown),
          hoverBorderColor: (this.datasets.datasets[0].hoverBorderColor as any)
            .filter((value, index) => this.displayedGraphs[index].shown),
        }, {
          ...this.datasets.datasets[1],
          data: this.datasets.datasets[1].data
            .filter((value, index) => this.displayedGraphs[index].shown),
    }),
    labels: this.shownData.labels };

    if (this.mode === BarChartStyleTheme.FillingBar || this.mode === BarChartStyleTheme.Dash) {
      const temp = this.shownData.datasets[0];
      this.shownData.datasets[0] = this.shownData.datasets[1];
      this.shownData.datasets[1] = temp;
      this.options.plugins.datalabels = {
        display: false
      }
    } else {
      this.options.plugins.datalabels = {
          anchor: 'end',
            align: 'end',
            offset: 0
        }
    }
    this.shownData.labels = this.datasets.labels.filter((value, index) => this.displayedGraphs[index].shown);

    this.chart?.chart?.update();
  }
  private checkAvailableGraphs() {
    this.displayedGraphs.forEach(graph => {
      this.allPossibleGraphs.set(graph.id, graph.shown);
    });
    this.displayedGraphs = new Array<{id: string, shown: boolean}>()
    this.datasets.labels.forEach(label => {
        if (this.allPossibleGraphs.get(label as string) === undefined) {
          this.allPossibleGraphs.set(label as string, true);
        }
        this.displayedGraphs.push({id: label as string, shown: this.allPossibleGraphs.get(label as string)});
      });
  }
}
