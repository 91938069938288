import {PartResponse} from '@cstx/volkswagen-mqs-part-service-client';
import {CppItemBase} from '../../../models/cppItemBase';
import {PartBlockedState} from '@cstx/volkswagen-mqs-part-service-client';
import {LogisticalState} from '../../../../modules/engine/list/list.component';;

export class Part extends CppItemBase implements PartResponse  {
  partType?: string;
  dmc: string;
  parentId?: string;
  source: PartResponse.SourceEnum;
  creationType: PartResponse.CreationTypeEnum;
  partNumber?: string | null;
  productionDate?: string | null;
  partStatus: any;
  manufacturerCode?: string | null;
  id: string;
  activeUntil?: string | null;
  createdBy?: string;
  modifiedBy?: string;
  createdAt?: string;
  modifiedAt?: string;
  costCenter?: string;
  productionLine?: number;
  partBlockedState: PartBlockedState;

  hasBlockingActions: boolean;
  hasReworks: boolean;
  hasColdTests: boolean;
  hasHotTests: boolean;


  sequenceNumber?: number | null;
  previousDmcs?: string[];


  public get selfLink(): string {
    return '/part/' + this.id;
  }

  public set selfLink(value: string) {
    super.selfLink = value;
  }

  constructor(partResponse?: PartResponse) {
    super();

    if (partResponse !== undefined) {
      this.id = partResponse.id;
      this.dmc = partResponse.dmc;
      this.partType = partResponse.partType;
      this.parentId = partResponse.parentId;
      this.source = partResponse.source;
      this.creationType = partResponse.creationType;
      this.partNumber = partResponse.partNumber;
      this.productionDate = partResponse.productionDate;
      this.partStatus = partResponse.partStatus;
      this.manufacturerCode = partResponse.manufacturerCode;
      this.createdBy = partResponse.createdBy;
      this.modifiedBy = partResponse.modifiedBy;
      this.createdAt = partResponse.createdAt;
      this.modifiedAt = partResponse.modifiedAt;
      this.costCenter = partResponse.costCenter;
      this.productionLine = partResponse.productionLine;
      this.partBlockedState = partResponse.partBlockedState;
      this.hasBlockingActions = partResponse.hasBlockingActions;
      this.sequenceNumber = partResponse.sequenceNumber;
      this.previousDmcs = partResponse.previousDmcs;

      this._logisticalState = this.getLogisticalState();
      this._isDirectRunner = this.isDirectRunner(true);
    }
  }

  public partIdentifierType?: PartResponse.PartIdentifierTypeEnum;
  public installationCount: number;

  private readonly _isDirectRunner: boolean;
  private readonly _logisticalState: LogisticalState = LogisticalState.UNDETERMINED;

  public getLogisticalState(recheck: boolean = true): LogisticalState {
    if (recheck === false) {
      return this._logisticalState;
    }

    if (this.partBlockedState !== 'NONE') {
      return LogisticalState.UNFREE;
    }

    return LogisticalState.FREE;
  }

  public isDirectRunner(recheck?: boolean): boolean {
    if (!recheck) {
      return this._isDirectRunner;
    }
    /**
     * TODO: Hier muss die Anzahl der Kalttests, bzw. ob fehlgeschlagene Kalttests vorlagen noch berücksichtigt werden.
     */

    if (this.getLogisticalState() === LogisticalState.FREE
      && !this.hasBlockingActions) {
      return true;
    }

    return false;
  }

}
