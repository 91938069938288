<div [class.cpp-page-defaults]="!KioskModeService.kioskMode"
     class="container-fluid"
     style="min-height: 100%"
     (mousemove)="userInteractionHandler()"
>

  <div *ngIf="!KioskModeService.kioskMode" class="pt-2 pb-2">
    <op-breadcrumb version2="true" [path]="'Home/Infos'"></op-breadcrumb>
  </div>

  <ng-container *ngIf="employeeInfos.length !== 0; else nothingToShowOrLoading">
    <table class="table table-hover">
      <tr class="row pl-3 pr-3">
        <td class="col pb-0 pl-0 nav" [class.border-0]="KioskModeService.kioskMode">
          <ul class="nav">
            <ng-container *ngFor="let employeeInfo of employeeInfos">
              <li class="nav-item pr-0">
                <button type="button" class="btn btn-sm button text-truncate"
                        [class.button-selected]="isActive(employeeInfo.title)"
                        (click)="setActive(employeeInfo)">{{ employeeInfo.title }}
                  <ng-container *ngIf="!employeeInfo.published">
                    <div style="color:#dc3545;" class="d-inline small">(Vorschau)</div>
                  </ng-container>
                </button>
              </li>
            </ng-container>
          </ul>
        </td>
      </tr>
    </table>
    <div class="row">
      <div class="col" *ngFor="let employeeInfo of employeeInfos" [hidden]="!employeeInfo.selected">
        <ng-container
          *ngIf="employeeInfo.filePreview
             && employeeInfo.filePreview.objectUrl
             && employeeInfo.filePreview.loadingComplete">

          <pdf-viewer [src]="employeeInfo.filePreview.objectUrl"
                      [render-text]="true"
                      [original-size]="true"
                      [fit-to-page]="true"

                      (error)="handlePdfViewerError($event)"
                      (pages-initialized)="handlePdfViewerPagesInitialized($event)"
                      (page-rendered)="handlePdfViewerPageRendered($event)"
                      (after-load-complete)="handlePdfViewerAfterLoadComplete($event)"
                      style="width: auto; height: 75vh;"
          ></pdf-viewer>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #nothingToShowOrLoading>
  <ng-container *ngIf="!updateInProgress">
    <div class="text-center pt-5">
      {{ 'global.nothing-to-show' | translate }}
    </div>
  </ng-container>
  <ng-container *ngIf="updateInProgress">
    <op-spinner></op-spinner>
  </ng-container>

</ng-template>

<div style="position: absolute; right: 50px; bottom: 50px; z-index: 200;">
  <ng-container *ngFor="let employeeInfo of employeeInfos">
    <div class="row" *ngIf="employeeInfo.file" [class.fade-out]="employeeInfo.file.DownloadProgress >= 100">
      <div class="col small">
        {{ employeeInfo.file.OriginalName }}

        <div class="progress position-relative bg-secondary">
          <div class="progress-bar text-center" role="progressbar"
               [ngStyle]="{'width.%': employeeInfo.file.DownloadProgress, 'background-color':  'bg-primary' }">

            <div class="justify-content-center d-flex position-absolute w-100"
                 [ngStyle]=" { 'color': 'white','font-weight': 'bold' }">
              <div class="col text-monospace" style="padding-right: 15px">
                {{ employeeInfo.file.DownloadProgress ? employeeInfo.file.DownloadProgress + ' %' : '0 %' }}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
