import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate, NavigationExtras,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public authService: AuthService, public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise(resolve => {
      this.authService.isAuthorized()
      .then(isAuth => {
        if (isAuth) {
          resolve(true);
        } else {

          if (route.queryParams.appName && route.queryParams.appToken) {
            this.authService.isApplicationUser = true;
            resolve(true);
          }

          const urlParts = state.url.split('?');
          const url = urlParts[0];
          const queries = urlParts.slice(1).join();
          let anchor: string;
          let anchorSeperation: Array<string>;
          const navExtras: NavigationExtras = {queryParams: {}};
          if (queries) {
            anchorSeperation = queries.split('#');
            anchor = anchorSeperation.slice(1).join();
            navExtras.queryParams.fwdUrl = url;
            navExtras.queryParams.queries = anchorSeperation[0];
          } else {
            anchorSeperation = url.split('#');
            anchor = anchorSeperation.slice(1).join();
            navExtras.queryParams.fwdUrl = anchorSeperation[0];
          }
          if (anchor) {
            navExtras.queryParams.anchor = anchor;
          }
          resolve(this.router.createUrlTree(['login'], navExtras));
        }
      })
      .catch(reason => {
        this.router.navigate(['login']);
        resolve(false);
      });
    });
  }
}
