import {BigScreenMetricType} from '../../../tools/bigscreen2/BigScreenMetricType';
import {BigScreenTrendType} from '../../../tools/bigscreen2/BigScreenTrendType';

export class BigScreenSettings {
  public id: string;
  public divergentBigScreenTargetValueActive = false;
  public divergentBigScreenTargetValue = 0;
  public divergentBigScreenTargetValueType: DivergentBigScreenTargetValueType = DivergentBigScreenTargetValueType.PERCENT;
  public forceReload = false;
  public forceReloadTriggerTimeStamp = 0;
  public bigScreenMetricType: BigScreenMetricType = null;
  public bigScreenTrendType: BigScreenTrendType = null;

  public get bigScreenMetricTypeLabel(): string {
    if (this.bigScreenMetricType === BigScreenMetricType.CAPACITY_ACTUAL_TREND) {
      return 'Kapazität - Ist - Trend';
    }

    if (this.bigScreenMetricType === BigScreenMetricType.CAPACITY_ACTUAL_CURRENT_CAPACITY) {
      return 'Kapazität - Ist - Kapazität aktuell';
    }

    if (this.bigScreenMetricType === BigScreenMetricType.TARGET_ACTUAL_TREND) {
      return 'Soll - Ist - Trend';
    }

    return undefined;
  };

  public get bigScreenTrendTypeLabel(): string {
    if (this.bigScreenTrendType === BigScreenTrendType.SZOnlineV1) {
      return 'SZOnline 1.0 (obsolet)';
    }

    if (this.bigScreenTrendType === BigScreenTrendType.Linear) {
      return 'Linear';
    }

    return 'Optimistisch';
  };
}

export enum DivergentBigScreenTargetValueType {
  PERCENT = 'PERCENT',
  ABSOLUTE = 'ABSOLUTE'
}
