import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';

declare let $: any;

@Component({
  selector: 'op-guided-filter-input',
  templateUrl: './guided-filter-input.component.html',
  styleUrls: ['./guided-filter-input.component.scss']
})
export class GuidedFilterInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() source: Array<AttributeEntry> | Observable<Array<AttributeEntry>>;
  @Input() searching = true;
  @Input() resetObs: Observable<void>;
  @Input() useFixedOrder = false;
  @Output() criteriaUpdate: EventEmitter<Array<FilterValue>> = new EventEmitter<Array<FilterValue>>();
  @Output() triggerSearch: EventEmitter<Array<FilterValue>> = new EventEmitter<Array<FilterValue>>();
  @Output() triggerAbortSearch: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('criteriaInput') criteriaInput: ElementRef;
  @Input() initializeOnceAndNoFilterInURI = true;
  @Input() mutuallyExclusives: Array<Array<string>>;

  options: Array<AttributeEntry> = [];
  currentOptions: Array<DropdownEntry> = [];
  displayedOptions: Array<DropdownEntry> = [];
  selectedOption: DropdownEntry | AttributeEntry | null;
  inputFocused = false;
  searchString: string;
  step: CriteriaBuilderStep;
  steps = CriteriaBuilderStep;
  inputTypes = InputType;
  currentCriterion: Criterion;
  criteria: Array<Criterion> = new Array<Criterion>();

  public inputId: string;
  private jqueryAccessor: string;

  private unsubObs: Subject<void> = new Subject<void>();
  public keepDropdownOpen = false;
  public invalidInput: boolean;
  public disabled: boolean;
  constructor(private translate: TranslateService,
              private route: ActivatedRoute,
              private location: Location,
              private changeDetectorRef: ChangeDetectorRef) {
    this.inputId = uuid();
    this.jqueryAccessor = '#' + this.inputId;

  }

  ngOnInit(): void {
    this.reset();
  }


  ngAfterViewInit(): void {
    if (this.source instanceof Observable) {
      if (this.initializeOnceAndNoFilterInURI) {
        this.disabled = true;
        this.changeDetectorRef.detectChanges();
        (this.source as Observable<Array<AttributeEntry>>).pipe(take(1)).subscribe(options => {
          this.options = options;
          this.disabled = false;
          this.setStep(CriteriaBuilderStep.Attribute);
          this.checkQuery();
        });
      } else {
        this.changeDetectorRef.detectChanges();
        (this.source as Observable<Array<AttributeEntry>>).pipe(takeUntil(this.unsubObs)).subscribe(options => {
          this.options = options;
          this.setStep(CriteriaBuilderStep.Attribute);
          this.reset();
        });
      }
    } else {
      this.options = this.source as Array<AttributeEntry>;
      this.setStep(CriteriaBuilderStep.Attribute);
      this.checkQuery();
    }

    if (this.resetObs) {
      this.resetObs.pipe(takeUntil(this.unsubObs)).subscribe(() => this.reset());
    }
  }

  public filterDisplayedOptions(filter: string) {
    if (this.useFixedOrder) {
      this.displayedOptions = this.currentOptions.slice(this.criteria.length, this.criteria.length + 1).filter(val => {
        return this.translate.instant(val.key).toLowerCase().includes(filter.toLowerCase());
      });
    } else {
      this.displayedOptions = this.currentOptions.filter(val => {
        return this.translate.instant(val.key).toLowerCase().includes(filter.toLowerCase());
      });
    }
    if (this.step === CriteriaBuilderStep.Attribute) {
      const attr: Set<string> = new Set<string>();
      this.criteria.forEach(criterion => {
        if (this.mutuallyExclusives) {
          const exclusives = this.mutuallyExclusives.find(attributeList => attributeList.indexOf(criterion.attribute.value) > -1)
          if (exclusives) {
            exclusives.forEach(ex => attr.add(ex));
          }
        }
        attr.add(criterion.attribute.value);
      });
      this.displayedOptions = this.displayedOptions.filter(option => !attr.has(option.value) );
    }
    if (!this.selectedOption ||
      !this.displayedOptions.find(x => x.key === this.selectedOption.key && x.value === this.selectedOption.value)) {
      this.selectedOption = null;
    }
  }
  private setStep(nextStep: CriteriaBuilderStep) {
    if ($(this.jqueryAccessor).data('daterangepicker')) {
      $(this.jqueryAccessor).data('daterangepicker').remove();
    }
    this.invalidInput = false;
    this.step = nextStep;
    if (nextStep === CriteriaBuilderStep.Attribute) {
      this.currentOptions = this.options;
    } else if (nextStep === CriteriaBuilderStep.Operator) {
      this.currentOptions = [{key: '=', value: '='}];
    } else if (nextStep === CriteriaBuilderStep.Pattern &&
      (this.currentCriterion.attribute.inputType === InputType.Selection
        || this.currentCriterion.attribute.inputType === InputType.SelectionAndString
        || this.currentCriterion.attribute.inputType === InputType.MultiSelection)) {
      if (this.currentCriterion.attribute.selection) {
        this.currentOptions = this.currentCriterion.attribute.selection;
      } else {
        this.currentOptions = [];
      }
    } else if (nextStep === CriteriaBuilderStep.Pattern && this.currentCriterion.attribute.inputType === InputType.DateRange) {
      this.initDaterangePicker();
    } else {
      this.currentOptions = [];
    }
    this.filterDisplayedOptions('');
  }

  emitSearch() {
    if (this.initializeOnceAndNoFilterInURI) {
      const queries = JSON.stringify(this.criteria.map(criterion => criterion.simplifiy()));
      const params = new URLSearchParams(location.search);
      if (queries === '[]') {
        params.delete('searchCriteria');
      } else {
        params.set('searchCriteria', queries);
      }
      this.location.replaceState(location.pathname, params.toString(), this.location.getState());
    }

    this.triggerSearch.emit(this.mapCriteriaToKeyValue(this.criteria));
  }

  emitSearchAbort() {
    this.reset();
    this.triggerAbortSearch.emit();
  }
  onEnter() {
    this.propagate();
  }

  onTab(event: KeyboardEvent) {
    event.preventDefault();
    this.propagate();
  }

  private propagate () {
    setTimeout(() => {
      (this.criteriaInput.nativeElement as HTMLInputElement).blur();
      (this.criteriaInput.nativeElement as HTMLInputElement).focus();
    }, 0);
    switch (this.step) {
      case CriteriaBuilderStep.Attribute:
        if (this.selectedOption) {
          this.currentCriterion.attribute = (this.selectedOption as AttributeEntry);
          this.searchString = '';
          this.selectedOption = null;
          // For the moment skip Operator step
          // this.setStep(CriteriaBuilderStep.Operator);
          this.currentCriterion.operator = {key: '=', value: '='};
          if (this.currentCriterion.attribute.inputType === InputType.Range) {
            this.currentCriterion.pattern = new GenericRange();
          }
          if (this.currentCriterion.attribute.inputType === InputType.List
            || this.currentCriterion.attribute.inputType === InputType.MultiSelection) {
            this.currentCriterion.pattern = new Array<string>();
          }
          this.setStep(CriteriaBuilderStep.Pattern);
        } else {
          this.inputFocused = false;
          this.emitSearch();
        }
        break;
      case CriteriaBuilderStep.Operator:
        if (this.selectedOption) {
          this.currentCriterion.operator = this.selectedOption;
          this.searchString = '';
          this.selectedOption = null;
          this.setStep(CriteriaBuilderStep.Pattern);
        }
        break;
      case CriteriaBuilderStep.Pattern:
        this.doPatternStep();
        break;
      default:
        break;
    }
  }
  private doPatternStep() {
    switch (this.currentCriterion.attribute.inputType) {
      case InputType.DateRange:
        // is done by daterangepicker interaction (see apply event listener setup in initDaterangePicker() )
        break;
      case InputType.Selection:
        if (this.selectedOption) {
          this.currentCriterion.pattern = this.selectedOption;
          this.searchString = '';
          this.selectedOption = null;
          this.criteria.push(this.currentCriterion);
          this.currentCriterion = new Criterion();
          this.setStep(CriteriaBuilderStep.Attribute);
          this.criteriaUpdate.emit(this.mapCriteriaToKeyValue(this.criteria));
        } else {
          this.invalidInput = true;
        }
        break;
      case InputType.SelectionAndString:
        if (this.selectedOption) {
          this.selectOption(this.selectedOption);
        } else if (this.searchString) {
          this.currentCriterion.pattern = this.searchString;
          this.searchString = '';
          this.criteria.push(this.currentCriterion);
          this.currentCriterion = new Criterion();
          this.setStep(CriteriaBuilderStep.Attribute);
          this.criteriaUpdate.emit(this.mapCriteriaToKeyValue(this.criteria));
        } else {
          this.invalidInput = true;
        }
        break;
      case InputType.Range:
        const range = this.currentCriterion.pattern as GenericRange;
        if (!range.from) {
          range.from = this.searchString;
          this.searchString = '';
        } else {
          range.to = this.searchString;
          this.searchString = '';
          this.criteria.push(this.currentCriterion);
          this.currentCriterion = new Criterion();
          this.setStep(CriteriaBuilderStep.Attribute);
          this.criteriaUpdate.emit(this.mapCriteriaToKeyValue(this.criteria));
        }
        break;
      case InputType.List:
        if (this.searchString === '') {
          if ((this.currentCriterion.pattern as Array<string>).length > 0) {
            this.criteria.push(this.currentCriterion);
            this.currentCriterion = new Criterion();
            this.setStep(CriteriaBuilderStep.Attribute);
            this.criteriaUpdate.emit(this.mapCriteriaToKeyValue(this.criteria));
          }
        } else {
          const list = this.currentCriterion.pattern as Array<string>;
          list.push(this.searchString)
          this.searchString = '';
        }
        break;
      case InputType.MultiSelection:
        if ((this.currentCriterion.pattern as Array<string>).length > 0) {
          this.criteria.push(this.currentCriterion);
          this.currentCriterion = new Criterion();
          this.setStep(CriteriaBuilderStep.Attribute);
          this.criteriaUpdate.emit(this.mapCriteriaToKeyValue(this.criteria));
        }
        break;
      case InputType.String:
      default:
        if (this.searchString) {
          this.currentCriterion.pattern = this.searchString;
          this.searchString = '';
          this.criteria.push(this.currentCriterion);
          this.currentCriterion = new Criterion();
          this.setStep(CriteriaBuilderStep.Attribute);
          this.criteriaUpdate.emit(this.mapCriteriaToKeyValue(this.criteria));
        } else {
          this.invalidInput = true;
        }
        break;
    }
  }
  removeCriterion(i: number) {
    this.criteria.splice(i, 1);
    this.filterDisplayedOptions(this.searchString);
    this.criteriaUpdate.emit(this.mapCriteriaToKeyValue(this.criteria));
  }

  abortCriterion() {
    this.searchString = '';
    this.currentCriterion = new Criterion();
    this.setStep(CriteriaBuilderStep.Attribute);
  }

  onDown() {
    if (this.selectedOption) {
      const index = this.displayedOptions.indexOf(this.selectedOption);
      if (index > -1 && index < this.displayedOptions.length - 1) {
        this.selectedOption = this.displayedOptions[index + 1];
      }
    } else if (this.displayedOptions.length > 0) {
      this.selectedOption = this.displayedOptions[0];
    }
  }

  onUp() {
    if (this.selectedOption) {
      const index = this.displayedOptions.indexOf(this.selectedOption);
      if (index > 0) {
        this.selectedOption = this.displayedOptions[index - 1];
      }
    } else if (this.displayedOptions.length > 0) {
      this.selectedOption = this.displayedOptions[this.displayedOptions.length - 1];
    }
  }

  selectOption(option: any) {
    if (this.currentCriterion
      && this.currentCriterion.attribute
      && this.currentCriterion.attribute.inputType === InputType.SelectionAndString) {
      this.searchString = option.value;
      this.filterDisplayedOptions(this.searchString);
    } else {
      this.selectedOption = option;
      this.onEnter();
      this.keepDropdownOpen = false;
    }
  }

  onBlur() {
    this.inputFocused = false;
  }

  showDropDown(): boolean {
    // return true;
    return (this.inputFocused || this.keepDropdownOpen)
      && (this.step !== CriteriaBuilderStep.Pattern
        || this.currentCriterion.attribute.inputType === InputType.Selection
        || this.currentCriterion.attribute.inputType === InputType.SelectionAndString
        || this.currentCriterion.attribute.inputType === InputType.List
        || this.currentCriterion.attribute.inputType === InputType.MultiSelection);
  }

  showClassicDrop(): boolean {
    return (this.inputFocused || this.keepDropdownOpen)
      && (this.step !== CriteriaBuilderStep.Pattern
        || this.currentCriterion.attribute.inputType === InputType.Selection
        || this.currentCriterion.attribute.inputType === InputType.SelectionAndString
        || this.currentCriterion.attribute.inputType === InputType.MultiSelection);
  }

  private mapCriteriaToKeyValue(criteria: Array<Criterion>): Array<FilterValue> {
    const arr: Array<FilterValue> = [];
    criteria.forEach(criterion => {
      if (criterion.attribute.inputType === InputType.DateRange) {
        const dateRange: DateRange = criterion.pattern as DateRange;
        arr.push({attribute: criterion.attribute.value + 'From', value: dateRange.from.toISOString() });
        arr.push({attribute: criterion.attribute.value + 'To', value: dateRange.to.toISOString() });
      } else if (criterion.attribute.inputType === InputType.Range) {
        const pattern: GenericRange = criterion.pattern as GenericRange;
        arr.push({attribute: criterion.attribute.value + 'From', value: pattern.from });
        arr.push({attribute: criterion.attribute.value + 'To', value: pattern.to });
      } else {
        let value: string;
        if (criterion.attribute.inputType === InputType.Selection) {
          value = (criterion.pattern as DropdownEntry).value;
        } else {
          value = criterion.pattern as string;
        }
        arr.push( {attribute: criterion.attribute.value, value} );
      }
    });
    return arr;
  }

  onBackspace() {
    if (!this.searchString || this.searchString === '' ) {
      if (this.step === CriteriaBuilderStep.Attribute) {
        if (this.criteria.length > 0) {
          this.currentCriterion = new Criterion();
          this.currentCriterion.attribute = this.criteria[this.criteria.length - 1].attribute;
          this.currentCriterion.operator = this.criteria[this.criteria.length - 1].operator;
          this.criteria.splice(this.criteria.length - 1, 1);
          if (this.currentCriterion.attribute.inputType === InputType.Range) {
            this.currentCriterion.pattern = new GenericRange();
          }
          if (this.currentCriterion.attribute.inputType === InputType.List
            || this.currentCriterion.attribute.inputType === InputType.MultiSelection) {
            this.currentCriterion.pattern = new Array<string>();
          }
          this.setStep(CriteriaBuilderStep.Pattern);
        }
      } else {
        this.abortCriterion();
      }
    }
  }

  onFocus() {
    this.selectedOption = null;
    this.inputFocused = true;
  }

  public reset() {
    this.setStep(CriteriaBuilderStep.Attribute);
    this.searchString = '';
    this.currentCriterion = new Criterion();
    this.criteria = [];
    this.filterDisplayedOptions('');
    this.criteriaUpdate.emit(this.mapCriteriaToKeyValue(this.criteria));
  }

  ngOnDestroy(): void {
    this.unsubObs.next();
  }

  private initDaterangePicker() {
    $(this.jqueryAccessor).daterangepicker({
      parentEl: '#' + this.inputId + 'Par',
      startDate: undefined,
      endDate: undefined,
      timePicker: true,
      autoUpdateInput: false,
      timePicker24Hour: true,
      showWeekNumbers: true,
      linkedCalendars: false,
      locale: {
        cancelLabel: this.translate.instant('search.clear'),
        applyLabel: this.translate.instant('search.apply'),
        format: 'DD.MM.YYYY HH:mm',
        daysOfWeek: [
          this.translate.instant('global.weekday.su'),
          this.translate.instant('global.weekday.mo'),
          this.translate.instant('global.weekday.tu'),
          this.translate.instant('global.weekday.we'),
          this.translate.instant('global.weekday.th'),
          this.translate.instant('global.weekday.fr'),
          this.translate.instant('global.weekday.sa'),
        ],
        monthNames: [
          this.translate.instant('global.month.january'),
          this.translate.instant('global.month.february'),
          this.translate.instant('global.month.march'),
          this.translate.instant('global.month.april'),
          this.translate.instant('global.month.may'),
          this.translate.instant('global.month.june'),
          this.translate.instant('global.month.july'),
          this.translate.instant('global.month.august'),
          this.translate.instant('global.month.september'),
          this.translate.instant('global.month.october'),
          this.translate.instant('global.month.november'),
          this.translate.instant('global.month.december'),
        ],
        firstDay: 1
      }
    });

    $(this.jqueryAccessor).on('apply.daterangepicker', (ev, picker) => {
      this.currentCriterion.pattern = new DateRange(picker.startDate, picker.endDate);
      this.searchString = '';
      this.criteria.push(this.currentCriterion);
      this.currentCriterion = new Criterion();
      this.setStep(CriteriaBuilderStep.Attribute);
      this.criteriaUpdate.emit(this.mapCriteriaToKeyValue(this.criteria));
    });

    $(this.jqueryAccessor).on('show.daterangepicker', (ev, picker) => {
      const displayWidth = document.getElementsByTagName('html')[0].clientWidth;
      const pick = picker.container[0] as HTMLElement;
      pick.style.transform = undefined;
      const pickRight = pick.getBoundingClientRect().right;
      if (displayWidth < pickRight) {
        pick.style.setProperty('--translate-datepicker', (pickRight - displayWidth).toString(10) + 'px');
        pick.classList.add('daterangepicker-move');
        pick.style.transition = 'all .5s';
        pick.style.transform = 'translate(-' + (pickRight - displayWidth) + 'px, 0)';
      }
    });
    $(this.jqueryAccessor).on('hide.daterangepicker', (ev, picker) => {
      const pick = picker.container[0] as HTMLElement;
      pick.style.transform = '';
      if (pick.classList.contains('daterangepicker-move')) {
        pick.classList.remove('daterangepicker-move');
      }
      (this.criteriaInput.nativeElement as HTMLInputElement).focus();
    });
    $(this.jqueryAccessor).on('cancel.daterangepicker', (ev, picker) => {
      this.abortCriterion();
    });
  }

  private mapQueryToCriteria(entry: SimplifiedCriterion): Criterion {
    const criterion: Criterion = new Criterion();
    criterion.operator = new DropdownEntry();
    criterion.operator.key = '=';
    criterion.operator.value = '=';
    if (entry.att.substring(entry.att.length - 5) === 'Range') {
      criterion.attribute = this.options
        .find(mapping => entry.att.substring(0,entry.att.length - 5) === mapping.value && mapping.inputType === InputType.Range);
    } else {
      const findList = this.options.find(mapping => mapping.value.includes(entry.att) && mapping.inputType === InputType.List);
      if (findList){
        criterion.attribute = findList;
      } else {
        criterion.attribute = this.options.find(mapping => entry.att === mapping.value && mapping.inputType !== InputType.Range);
      }
    }

    if (!criterion.attribute) {
      return null;
    }
    while(true) {
      switch (criterion.attribute.inputType) {
        case InputType.MultiSelection:
        case InputType.List:
          try {
            const pattern: Array<string> = JSON.parse(entry.pat);
            const alternativeAttribute
              = this.options.find(mapping => mapping.value.includes(entry.att) && mapping.inputType !== InputType.List);
            if ((typeof pattern !== 'object' || pattern.length <= 1) && alternativeAttribute) {
              criterion.attribute = alternativeAttribute;
              if (pattern.length === 1) {
                entry.pat = pattern[0];
              }
              continue;
            }
            criterion.pattern = pattern;
          } catch (e) {
            return null;
          }
          break;
        case InputType.String:
        case InputType.SelectionAndString:
          criterion.pattern = entry.pat;
          break;
        case InputType.DateRange:
          const dates = entry.pat.split('_');
          criterion.pattern = new DateRange(new Date(dates[0]), new Date(dates[1]));
          break;
        case InputType.Selection:
          criterion.pattern = criterion.attribute.selection?.find(selection => selection.value === entry.pat);
          if (!criterion.pattern) {
            return null;
          }
          break;
        case InputType.Range:
          try {
            criterion.pattern = JSON.parse(entry.pat);
          } catch (e) {
            return null;
          }
          break;
      }
      break;
    }
    return criterion;
  }

  private checkQuery() {
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      if (params.searchCriteria) {
        try {
          this.criteria = (JSON.parse(decodeURIComponent(params.searchCriteria)) as Array<SimplifiedCriterion>)
            .map(entry => this.mapQueryToCriteria(entry)).filter(entry => entry !== null);
        } catch (e) {
          this.criteria = new Array<Criterion>();
        }
        this.filterDisplayedOptions('');
      }
      this.emitSearch();
    });
  }

  getPatternAsStringArray(criterion: Criterion): Array<string>  {
    if (criterion.attribute?.inputType === InputType.List || criterion.attribute?.inputType === InputType.MultiSelection) {
      return criterion.pattern as Array<string>;
    }
    return new Array<string>();
  }

  getPatternAsDropdownEntryArray(criterion: Criterion): Array<DropdownEntry> {
    if (criterion.attribute?.inputType === InputType.MultiSelection) {
      return criterion.pattern as Array<DropdownEntry>;
    }
    return new Array<DropdownEntry>();
  }

  removeEntryFromCurrentCriterionPattern(i: number) {
    const list = this.getPatternAsStringArray(this.currentCriterion);
    list.splice(i,1);
  }

  toggleMultiselectOption(option: DropdownEntry) {
    const pattern = this.currentCriterion?.pattern as Array<string>;
    const index = pattern.indexOf(option.value);
    if (index === -1) {
      pattern.push(option.value)
    } else {
      pattern.splice(index, 1)
    }
    this.currentCriterion.pattern = pattern;
  }

  optionSelected(option: DropdownEntry): boolean {
    return (this.currentCriterion?.pattern as Array<string>).indexOf(option.value) > -1
  }

  getTranslationForMultiSelection(value: string, criterion: Criterion): string {
    if (criterion.attribute?.inputType === InputType.MultiSelection) {
      const option = this.options.find(opt => opt.value === criterion.attribute.value);
      if (option) {
       const attribute = option.selection?.find(attr => attr.value === value);
       if (attribute) {
         return attribute.key;
       }
      }
    }

    return '';
  }
}
export class Criterion {
  public attribute: AttributeEntry;
  public operator: DropdownEntry;
  public pattern: string | DropdownEntry | DateRange | Array<string> | GenericRange | Array<DropdownEntry>;

  simplifiy(): SimplifiedCriterion {
    const value: SimplifiedCriterion = {att: this.attribute.value, pat: ''};

    switch (this.attribute.inputType) {
      case InputType.String:
      case InputType.SelectionAndString:
        value.pat = this.pattern as string;
        break;
      case InputType.Selection:
        value.pat = (this.pattern as DropdownEntry).value;
        break;
      case InputType.DateRange:
        const dateRange: DateRange = this.pattern as DateRange;
        value.pat = dateRange.from.toISOString() + '_' +  dateRange.to.toISOString();
        break;
      case InputType.Range:
        const range: GenericRange = this.pattern as GenericRange;
        value.att = this.attribute.value + 'Range';
        value.pat = JSON.stringify(range);
        break;
      case InputType.List:
      case InputType.MultiSelection:
        const list: Array<string> = this.pattern as Array<string>;
        value.pat = JSON.stringify(list);
    }
    return value;
  }
}
export class SimplifiedCriterion {
  att: string;
  pat: string;
}
enum CriteriaBuilderStep {
  Attribute, Operator, Pattern
}
export enum InputType {
  String = 'STRING',
  Selection = 'SELECTION',
  DateRange = 'DATERANGE',
  Date = 'DATE',
  SelectionAndString = 'SELECTIONANDSTRING',
  MultiSelection = 'MULTISELECTION',
  List = 'LIST',
  Range = 'RANGE',
}

export class DropdownEntry {
  public key: string;
  public value: string;
  public hasDecorationIcon?: boolean;
  public decorationClasses?: Array<string>;
}

export class AttributeEntry extends DropdownEntry {
  public selection?: Array<DropdownEntry>;
  public inputType: InputType;
}
export class FilterValue {
  public attribute: string;
  public value: string;
}
/**
 * Helper class to store information about the currently selected build daterange.
 */
export class DateRange {
  public from: Date;
  public to: Date;

  constructor(from: Date, to: Date) {
    this.from = from;
    this.to = to;
  }

}

export class GenericRange {
  public from: string;
  public to: string;

  constructor(from?: string, to?: string) {
    this.from = from;
    this.to = to;
  }
}
