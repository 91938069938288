<div [class.disabled]="disabled">
  <div class="d-flex">
    <div class="col small m-auto pl-0">
      <h5>{{ 'shared.components.file-explorer.treeview.files' | translate }} <op-count-badge *ngIf="!countUpdating" [count]="count"></op-count-badge></h5>
    </div>
    <div class="col text-right m-auto" *ngIf="!uploadDisabled">
      <i class="cpp-icon-upload" [class.disabled]="disabled" (click)="onShowUploadClicked()"></i>
    </div>
  </div>
  <ng-container *ngIf="list.length > 0 && !loading; else noFiles">
    <ngx-treeview class="cpp-treeview-min-h-85"
      [itemTemplate]="treeViewTemplateV2"
      [config]="treeViewConfig"
      [items]="treeViewItems">
    </ngx-treeview>
  </ng-container>
</div>

<ng-template #noFiles>
  <div *ngIf="loading">
    <div style="color: lightgray;">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ 'shared.components.file-explorer.files.loading' | translate }}
    </div>
  </div>
  <div *ngIf="!loading">
    {{ 'shared.components.file-explorer.treeview.no-files' | translate }}
  </div>
</ng-template>

<!-- TreeView Template V2--->
<ng-template #treeViewTemplateV2 let-treeViewItem="item" let-onCollapseExpand="onCollapseExpand">
  <div class="p-0 m-0" style="display: flex; line-height: 19px" [hidden]="treeViewItem.text === ''">
    <!-- If item is folder, change icon on collapsed/expand -->
    <i *ngIf="itemIsFolder(treeViewItem)" [ngSwitch]="itemIsCollapsed(treeViewItem)" (click)="onCollapseExpand()" aria-hidden="true" >
      <!-- Folder is expanded -->
      <i *ngSwitchCase="false" class="cpp-icon-folder-open"></i>
      <!-- Folder is collapsed --->
      <i *ngSwitchCase="true" class="cpp-icon-folder-closed"></i>
    </i>

    <!-- Label for folder items -->
    <label *ngIf="itemIsFolder(treeViewItem)" class="cpp-file-explorer-treeview-label small pl-2" (click)="onCollapseExpand()">{{ treeViewItem.text }}</label>
    <!-- Item folder handling end -->

    <!-- If item is file -->
    <label *ngIf="!itemIsFolder(treeViewItem)" (click)="onSelectItemClicked(treeViewItem.text, treeViewItem.value)"
           class="cpp-file-explorer-treeview-file-label overflow-hidden text-overflow-ellipses form-check-label small pl-0"
           [class.cpp-file-explorer-treeview-file-label-selected]="itemIsSelected(treeViewItem.text)"
           title="{{ treeViewItem.text }}">{{ treeViewItem.text }}
    </label>
    <!-- Item is file handling end -->
  </div>
</ng-template>
<!-- TreeView Template --->
