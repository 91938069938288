import { Component } from '@angular/core';

@Component({
  selector: 'op-alpha-flag',
  templateUrl: './alpha-flag.component.html',
  styleUrls: ['./alpha-flag.component.scss']
})
export class AlphaFlagComponent {

}
