import {
  AMS_CONFIG_PROVIDER,
  DLQ_CONFIG_PROVIDER,
  EES_CONFIG_PROVIDER,
  FCT_CONFIG_PROVIDER,
  FHS_CONFIG_PROVIDER,
  LDS_CONFIG_PROVIDER,
  MDS_CONFIG_PROVIDER,
  MMS_CONFIG_PROVIDER,
  MPS_CONFIG_PROVIDER,
  NNS_CONFIG_PROVIDER,
  OPS_CONFIG_PROVIDER,
  PSS_CONFIG_PROVIDER,
  PTS_CONFIG_PROVIDER,
  QMS_CONFIG_PROVIDER,
  RGS_CONFIG_PROVIDER,
  SAS_CONFIG_PROVIDER,
  TGS_CONFIG_PROVIDER,
  TRS_CONFIG_PROVIDER,
  UCS_CONFIG_PROVIDER,
  VWS_CONFIG_PROVIDER,
  WSS_CONFIG_PROVIDER
} from './apiConfigurationProvider';
import {EngineApiModule} from '@cstx/volkswagen-mqs-engine-service-client';
import {TrackingApiModule} from '@cstx/volkswagen-mqs-tracking-service-client';
import {
  ApplicationManagementApiModule
} from '@cstx/volkswagen-mqs-application-management-service-client';
import {QualityManagementApiModule} from '@cstx/volkswagen-mqs-quality-management-service-client';
import {FileHandlingApiModule} from '@cstx/volkswagen-mqs-file-handling-service-client';
import {
  ManufacturingMonitorApiModule
} from '@cstx/volkswagen-mqs-manufacturing-monitor-service-client';
import {MetadataApiModule} from '@cstx/volkswagen-mqs-metadata-service-client';
import {NotificationApiModule} from '@cstx/volkswagen-mqs-notification-service-client';
import {ParsingApiModule} from '@cstx/volkswagen-mqs-parsing-service-client';
import {PartApiModule} from '@cstx/volkswagen-mqs-part-service-client';
import {WorkSequenceApiModule} from '@cstx/volkswagen-mqs-work-sequence-service-client';
import {WorkshiftApiModule} from '@cstx/volkswagen-mqs-workshift-service-client';
import {DeadLetterQueueApiModule} from '@cstx/volkswagen-mqs-dead-letter-queue-service-client';
import {ReportApiModule} from '@cstx/volkswagen-mqs-report-generator-service-client';
import {TelegramReceiverApiModule} from '@cstx/volkswagen-mqs-telegram-receiver-service-client';
import {VirtualWarehouseApiModule} from '@cstx/volkswagen-mqs-virtual-warehouse-service-client';
import {OnPremiseProcedureApiModule} from '@cstx/volkswagen-mqs-onpremise-procedure-service-client';
import {UserConfigurationApiModule} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {
  FaultyComponentTrackingApiModule
} from '@cstx/volkswagen-mqs-faulty-component-tracking-service-client';

export const SZO_API_MODULES = [
  EngineApiModule,
  TrackingApiModule,
  ApplicationManagementApiModule,
  QualityManagementApiModule,
  FileHandlingApiModule,
  ManufacturingMonitorApiModule,
  MetadataApiModule,
  NotificationApiModule,
  OnPremiseProcedureApiModule,
  ParsingApiModule,
  PartApiModule,
  WorkSequenceApiModule,
  WorkshiftApiModule,
  ReportApiModule,
  DeadLetterQueueApiModule,
  TelegramReceiverApiModule,
  UserConfigurationApiModule,
  VirtualWarehouseApiModule,
  FaultyComponentTrackingApiModule
];

export const SZO_API_PROVIDERS = [
  EES_CONFIG_PROVIDER,
  TGS_CONFIG_PROVIDER,
  AMS_CONFIG_PROVIDER,
  FHS_CONFIG_PROVIDER,
  WSS_CONFIG_PROVIDER,
  MMS_CONFIG_PROVIDER,
  PTS_CONFIG_PROVIDER,
  PSS_CONFIG_PROVIDER,
  NNS_CONFIG_PROVIDER,
  MPS_CONFIG_PROVIDER,
  OPS_CONFIG_PROVIDER,
  QMS_CONFIG_PROVIDER,
  MDS_CONFIG_PROVIDER,
  DLQ_CONFIG_PROVIDER,
  RGS_CONFIG_PROVIDER,
  TRS_CONFIG_PROVIDER,
  UCS_CONFIG_PROVIDER,
  VWS_CONFIG_PROVIDER,
  FCT_CONFIG_PROVIDER,
  SAS_CONFIG_PROVIDER,
  LDS_CONFIG_PROVIDER
];
