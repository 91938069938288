import {DataFile, UseCaseForm} from '@cstx/volkswagen-mqs-file-handling-service-client';

export class UseCaseFile {
  public Id?: string;
  public Name: string;
  public CreatedAt: any;
  public OriginalName: string;
  public Source: string;
  public Origin: string;
  public Size?: string;
  public s3Key?: string;
  public FileType: string;
  public ParserType: string;
  public time?: string;
  public date?: string;

  public DownloadProgress?: number;

  public Token: string;

  constructor(source: DataFile = null) {
    if (source !== null) {
      this.Id            = source.id;
      this.Name         = source.fileName;
      this.CreatedAt    = source.modifiedDate;
      this.OriginalName = source.sourceFilename;
      this.Source       = source.source;
      this.Origin       = source.origin;

      this.FileType     = source.fileType;
      this.s3Key        = source.S3Key;
      this.Size         = source.fileSize;
    }

    this.ParserType = 'none';
  }

  public MapFromUseCaseForm(useCaseForm: UseCaseForm) {
    this.Source         = useCaseForm.Source;
    this.Origin         = useCaseForm.Origin;
    this.Size           = useCaseForm.Size?.toString();
    this.OriginalName   = useCaseForm.Metadata?.OriginalFileName;
    this.Token          = useCaseForm.Metadata?.Token;
    this.CreatedAt      = useCaseForm.LastModified;
    this.s3Key          = useCaseForm.S3Key;
    this.Name           = this.OriginalName;
  }
}
