import {FilterBase} from '../../../shared/models/filterBase';
import {Slice} from '../../../shared/models/slice';
import {BlockingActionState} from '@cstx/volkswagen-mqs-quality-management-service-client/model/models';
import {
  GetBlockingActionsV2RequestParams
} from '@cstx/volkswagen-mqs-quality-management-service-client/api/blockingActionsControllerV2.service';

export class BlockingActionFilter  extends FilterBase implements GetBlockingActionsV2RequestParams {
  blockingReason?: string;
  releaseReason?: string;
  blockingActionState?: BlockingActionState;
  blockedAtFrom?: string;
  blockedAtTo?: string;
  releasedAtFrom?: string;
  releasedAtTo?: string;
  humanReadableId?: string;
  public slice: Slice;

  constructor() {
    super();

    this.pagingSize = 50;
    this.totalPages = 1;
    this.currentPageIndex = 0;
    this.sort = [ 'createdAt,desc' ];
  }

  public IsEmpty(): boolean {
    if (this.blockingReason && this.blockingReason !== '') {
      return false;
    }
    if (this.releaseReason && this.releaseReason !== '') {
      return false;
    }
    if (this.blockingActionState) {
      return false;
    }
    if (this.blockedAtFrom || this.blockedAtTo) {
      return false;
    }
    if (this.releasedAtFrom || this.releasedAtTo) {
      return false;
    }

    if (this.humanReadableId && this.humanReadableId !== '') {
      return false;
    }

    return true;
  }

  public getAllParams() {
    return {...this.getPageableParams(), ...this.getFilterParams()};
  }

  public getFilterParams() {
    return {
      blockingReason: this.blockingReason,
      releaseReason: this.releaseReason,
      blockingActionState: this.blockingActionState,
      blockedAtFrom: this.blockedAtFrom,
      blockedAtTo: this.blockedAtTo,
      releasedAtFrom: this.releasedAtFrom,
      releasedAtTo: this.releasedAtTo,
      humanReadableId: this.humanReadableId
    };
  }

  public getPageableParams() {
    return {
      page: this.currentPageIndex,
      size: this.pagingSize,
      sort: this.sort,
    };
  }
}
