<div class="progress position-relative bg-secondary" [title]="currentAmount + '/' + targetAmount">
  <div class="progress-bar text-center" role="progressbar"
       [ngStyle]="{'width.%': progress, 'background-color':  barColor }">
    <div class="justify-content-center d-flex position-absolute w-100" [ngStyle]=" { 'color': textColor,'font-weight': 'bold' }">
      <div *ngIf="!disabled" class="col text-monospace" [class.text-right]="!loading" style="padding-right: 15px">
        {{ !loading ? progress + ' %' : 'Loading' }}
        <div  *ngIf="loading" class="spinner-border spinner-border-sm"></div>
      </div>

      <ng-container *ngIf="!loading && !disabled">
        <div class="col text-monospace text-left" style="padding-left: 15px">{{ label ? label : '' }}</div>
      </ng-container>

      <ng-container *ngIf="!loading && disabled">
        <div class="col text-monospace text-center" style="padding-left: 15px">{{ 'Keine Daten' }}</div>
      </ng-container>
    </div>
  </div>
</div>
