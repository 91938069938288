<op-detail-section-wrapper
  [title]="'component-profile.tests.title' | translate"
  [description]="'component-profile.tests.description' | translate"
  [contentTemplate]="contentTemplateRef"
  [hasContent]="tests.length >= 1"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <ng-container *ngIf="tests.length >= 1">
    <ngx-treeview class="cpp-treeview-h-100"
                  [itemTemplate]="treeViewItemTemplate"
                  [config]="treeViewConfig"
                  [items]="treeViewItems">
    </ngx-treeview>
    <p *ngIf="invalidatedTestsPresent() && componentProfile.componentType === ComponentType.ENGINE"
    class="small mt-2">
      {{'component-profile.tests.invalidated_message' | translate}}
      <a routerLink="./" [fragment]="'keycodeHistory'" (click)="handleOpenFragment('keycodeHistory')">{{'component-profile-v2.keycode-history.title' | translate}}</a>.
    </p>
  </ng-container>
</ng-template>

<ng-template #treeViewItemTemplate let-treeViewItem="item" let-onCollapseExpand="onCollapseExpand">
  <div class="p-0 pt-1 m-0" style="display: flex; line-height: 19px">
    <i *ngIf="treeViewItem.text === 'Tests'"
       (click)="onCollapseExpand()"
       aria-hidden="true"
       class="pr-1"
       [ngSwitch]="treeViewItem.collapsed">

      <i *ngSwitchCase="false" class="fas fa-folder-open"></i>
      <i *ngSwitchCase="true" class="fas fa-folder"></i>
    </i>

    <i *ngIf="treeViewItem.value.isTestObjectType === true"
       (click)="onCollapseExpand()"
       aria-hidden="true"
       class="pr-1"
       [ngSwitch]="treeViewItem.collapsed">

      <i *ngSwitchCase="false" class="fas fa-fw fa-chevron-down small pr-1"></i>
      <i *ngSwitchCase="true" class="fas fa-fw fa-chevron-right small pr-1"></i>
    </i>

    <span *ngIf="treeViewItem.value.isTestObjectType === true" class="small pl-1"><i *ngIf="treeViewItem.value.testResult === 'OK'" aria-hidden="true" class="fas fa-thumbs-up fa-fw" style="color: darkgreen"></i></span>
    <span *ngIf="treeViewItem.value.isTestObjectType === true" class="small pr-1"><i *ngIf="treeViewItem.value.testResult !== 'OK'" aria-hidden="true" class="fas fa-thumbs-down fa-fw" style="color: darkred"></i></span>
    <label *ngIf="treeViewItem.text === 'Tests'" class="cpp-treeview-label treeViewItemGroup text-overflow-ellipses" (click)="onCollapseExpand()">{{ treeViewItem.text }}</label>
    <label *ngIf="treeViewItem.value.isTestObjectType === true; else testResult" class="form-check-label text-overflow-ellipses text-monospace small">
      <op-datetime-show [dateTime]="treeViewItem.text"></op-datetime-show>
    </label>
    <ng-template #testResult>
      <div class="form-check-label pb-1">
        <div class="col small text-overflow-ellipses" [class.d-inline-block]="!viewSizeXS" style="min-width: 100px; max-width: 200px; font-weight: 500">{{ treeViewItem.text }}:</div>
        <div class="col small" [class.cpp-component-profile-test-viewSizeXS]="viewSizeXS"  [class.d-inline-block]="!viewSizeXS" style="min-width: 100px;">{{ treeViewItem.value }}</div>
      </div>
    </ng-template>
    <span *ngIf="treeViewItem.value.isTestObjectType === true" class="small pl-3 d-none d-sm-inline-block"><op-count-badge [title]="treeViewItem.value.testRuns + '. ' +  ( 'component-profile.tests.testrun' | translate )" [count]="treeViewItem.value.testRuns"></op-count-badge></span>

    <span *ngIf="treeViewItem.value.isTestObjectType === true" class="small pl-3 d-none d-sm-inline-block"><op-component-state-badge [prependIcon]="true" [useIconsAndText]="true" [name]="treeViewItem.value.testCategory"></op-component-state-badge></span>
    <span *ngIf="treeViewItem.value.isTestObjectType === true" class="small pl-3 d-inline-block d-sm-none"><op-component-state-badge [useIcons]="true" [name]="treeViewItem.value.testCategory"></op-component-state-badge></span>

  </div>
</ng-template>



