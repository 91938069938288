<ng-container *ngIf="parentComponentId !== undefined">
  <op-detail-section-wrapper [id]="'parent-' + contentContainerId"
    [title]="'assembly-detail.parent.title' | translate"
    [description]="'assembly-detail.parent.description' | translate"
    [contentTemplate]="assemblyParentTemplate"
    [hasContent]="true"
    [selfLink]="parentSelfLink"
    [contentContainerId]="'parent-' + contentContainerId">
  </op-detail-section-wrapper>
</ng-container>

<op-detail-section-wrapper
  [title]="'assembly-detail.title' | translate"
  [description]="'assembly-detail.description' | translate"
  [contentTemplate]="assemblyTemplate"
  [hasContent]="assembly != undefined && assembly.assemblyParts.length >= 1"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId">
</op-detail-section-wrapper>

<ng-template #assemblyTemplate>
  <ng-container *ngIf="assembly != undefined && assembly.assemblyParts.length >= 1">
      <ngx-treeview class="cpp-treeview-h-100"
        [itemTemplate]="treeViewItemTemplate"
        [config]="treeViewConfig"
        [items]="treeViewItems">
      </ngx-treeview>
  </ng-container>
</ng-template>

<ng-template #assemblyParentTemplate>
  <ng-container *ngIf="parentAssembly !== undefined; else assemblyNoParentTemplate">
    <div class="container pb-3 ml-0 mr-0">
      <div class="row pb-3">
        <div class="col col-lg-8">
          <div class="row pb-3">
            <div class="col pl-0">{{ 'assembly-detail.assembled-into.installed-in' | translate }}</div>
            <div class="cpp-column-fixed-width-25 pl-0">
              <a [routerLink]="'/' + parentAssembly?.assemblyType.toLowerCase() + '/' + parentAssembly?.id" target="_blank"><i class="fas fa-link ml-1" style="color: cornflowerblue"></i></a>
            </div>
            <div class="col pl-0">
              <i class="fas fa-fw" [class.fa-cogs]="parentAssembly?.assemblyType === 'ENGINE'" [class.fa-cog]="parentAssembly?.assemblyType === 'PART'" ></i>
              <span class="text-monospace pl-1 pr-1" style="font-weight: bolder">{{ parentAssembly?.assemblyName ? parentAssembly?.assemblyName : '-' }}</span>
            </div>
          </div>
          <div class="row" *ngFor="let assembly of previousParentComponents, index as i">
            <div class="col pl-0">
              <ng-container *ngIf="i === 0">{{ 'assembly-detail.assembled-into.previously-installed-in' | translate }}<br>
              </ng-container>
            </div>
              <div class="cpp-column-fixed-width-25 pl-0">
                <a [routerLink]="'/' + assembly.assemblyType.toLowerCase() + '/' + assembly.id" target="_blank"><i class="fas fa-link ml-1 small" style="color: cornflowerblue"></i></a>
              </div>
              <div class="col pl-0">
                <i class="fas fa-fw mr-1 small" [class.fa-cogs]="assembly.assemblyType === 'ENGINE'" [class.fa-cog]="assembly.assemblyType === 'PART'" ></i>
                <span class="text-monospace pl-1 pr-1" style="color: lightgray">{{ assembly.assemblyName ? assembly.assemblyName : '-' }}</span>
              </div>
          </div>
          <div class="row" *ngIf="previousParentComponentsIds.length > previousParentComponents.length && !loadingPreviousParentAssemblies">
            <div class="col"></div>
            <div class="col">
            <small>{{ 'assembly-detail.number-displayed-items' | translate: { numberShown: previousParentComponents.length, numberTotal: previousParentComponentsIds.length } }}
              <div class="pointer" (click)="loadMorePreviousAssemblies()">{{ 'assembly-detail.load-more' | translate }} ...</div>
            </small>
            </div>
          </div>
          <div class="row" *ngIf="loadingPreviousParentAssemblies">
            <div class="col">
              <div class="spinner-border spinner-border-sm"></div>
            </div>
          </div>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #assemblyNoParentTemplate>
  <div class="container pb-3 ml-0 mr-0">
    <div class="row pb-3">
      <div class="col-md-4 pl-0">
        {{ 'assembly-detail.assembled-into.title' | translate }}
      </div>
      <div class="col-md-8 pl-0">
        {{ 'assembly-detail.assembled-into.error' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<!-- ######################################### -->
<!-- This is the treeview used in ngx-treeview -->
<ng-template #treeViewItemTemplate let-treeViewItem="item" let-onCollapseExpand="onCollapseExpand">
  <div class="p-0 m-0" style="display: flex; line-height: 19px">

    <i *ngIf="treeViewItem.children; else treeViewWithOutChildren" style="width: 25px"
       (click)="onCollapseExpand()"
       aria-hidden="true"
       [ngSwitch]="treeViewItem.collapsed">

      <i *ngSwitchCase="false" class="cpp-component-profile-assembly-partgroup"
         [class.cpp-component-profile-assembly-partgroup-valid]="treeViewItem.value?.assemblyPartStatus === 'VALID'"
         [class.cpp-component-profile-assembly-partgroup-invalid]="treeViewItem.value?.assemblyPartStatus === 'INVALID'"
         [class.cpp-component-profile-assembly-partgroup-unknown]="treeViewItem.value?.assemblyPartStatus === 'UNKNOWN'"
      >
      </i>
      <i *ngSwitchCase="true"  class="cpp-component-profile-assembly-partgroup"
         [class.cpp-component-profile-assembly-partgroup-valid]="treeViewItem.value?.assemblyPartStatus === 'VALID'"
         [class.cpp-component-profile-assembly-partgroup-invalid]="treeViewItem.value?.assemblyPartStatus === 'INVALID'"
         [class.cpp-component-profile-assembly-partgroup-unknown]="treeViewItem.value?.assemblyPartStatus === 'UNKNOWN'"
      ></i>
    </i>
    <ng-template #treeViewWithOutChildren>
      <i class="cpp-component-profile-assembly-part" style="width: 25px"
         [class.cpp-component-profile-assembly-part-valid]="treeViewItem.value?.assemblyPartStatus === 'VALID'"
         [class.cpp-component-profile-assembly-part-invalid]="treeViewItem.value?.assemblyPartStatus === 'INVALID'"
         [class.cpp-component-profile-assembly-part-unknown]="treeViewItem.value?.assemblyPartStatus === 'UNKNOWN'"
      ></i>
    </ng-template>

    <label class="small" style="min-width: 150px; padding-top: 0 !important;">
      <op-count-badge [count]="treeViewItem.value?.position"></op-count-badge>
      {{ treeViewItem.value.part.partNumber ? treeViewItem.value.part.partNumber : ( 'component-profile.assembly.part-number.missing' | translate )}}
    </label>

    <span class="small text-overflow-ellipses d-none d-sm-inline" style="width: 200px">{{ treeViewItem.value.part?.partType ? treeViewItem.value.part.partType : '' }}</span>
    <op-copy-to-clipboard-icon style="font-size: 1.0em;" [customIconClass]="'cpp-force-pr-2'" [content]="treeViewItem.value.part?.dmc"></op-copy-to-clipboard-icon>
    <span class="small text-monospace text-overflow-ellipses pr-3 d-none d-lg-inline" style="width: 350px">{{ treeViewItem.value?.part?.dmc }}</span>
    <span *ngIf="isPartOfMainAssembly(treeViewItem.value)" class="text-right text-monospace small d-none d-sm-inline cpp-column-fixed-date">
      <op-datetime-show [dateTime]="treeViewItem.value?.part?.productionDate"></op-datetime-show>

    </span>
    <a *ngIf="isPartOfMainAssembly(treeViewItem.value)" [href]="'/part/' + treeViewItem.value.part?.id">
      <i class="cpp-icon-open-details d-inline pl-3" style="font-size: 1.0em"></i>
    </a>
  </div>
</ng-template>
<!-- ######################################### -->
