import {Component, HostListener, OnInit} from '@angular/core';
import {InfosService} from '../infos.service';
import {UserInformationResponse} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {FileExplorerService} from '../../../../shared/components-merged/usecase-file-explorer/file-explorer.service';
import {UseCaseFile} from '../../../../shared/components-merged/usecase-file-explorer/useCaseFile';
import {VideoPlayerService} from '../../../../shared/components/video-player/video-player.service';
import {DomSanitizer} from '@angular/platform-browser';
import {VideoPlayerEntity} from '../../../../shared/components/video-player/video-player-entity';
import fileSize from 'filesize';
import {HttpEventType} from '@angular/common/http';
import {ActivatedRoute} from "@angular/router";
import {KioskModeService} from "../../../../core/services/kiosk-mode.service";
import {window} from "ngx-bootstrap/utils";
@Component({
  selector: 'op-video-info',
  templateUrl: './video-info.component.html',
  styleUrls: ['./video-info.component.scss']
})
export class VideoInfoComponent implements OnInit{

  constructor(private infosService: InfosService,
              private fileExplorerService: FileExplorerService,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private kioskModeService: KioskModeService) {
  }
  public info: UserInformationResponse;
  public tabs: string[] = new Array<string>();
  public selectedVideo: VideoPlayerEntity;


  public autoLoad: boolean;
  public autoPlay: boolean;

  public kioskMode: boolean;

  public tabSetStartIndex = 0;
  public tabSetLength = 4;
  public tabSetActive = new Array<string>(4);

  private loadedFiles:  string[] = new Array<string>();
  private loadInitiated: string[] = new Array<string>();

  private useCaseFiles = new Array<UseCaseFile>();

  public visibleWidth: number;
  public visibleHeight: number;


  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    console.log(window.innerWidth);
    console.log(window.innerHeight);

    if (window.innerWidth > 1280) {
      this.visibleWidth = window.innerWidth;
    }

    if (window.innerHeight > 700) {
      this.visibleHeight = window.innerHeight;
    }
  }

  public async ngOnInit(): Promise<void> {
    this.kioskModeService.kioskMode$.subscribe(mode => this.kioskMode = mode);

    this.onWindowResize();
    VideoPlayerService.onVideoPlaybackReady.subscribe(video => {
      console.log('First video playable.');

      if (this.autoPlay) {
        this.videoSelected(video);
      }
    });

    this.route.queryParamMap.subscribe(params => {
      const autoLoad = params.get('autoLoad') === 'true';

      if (autoLoad) {
        this.autoLoad = autoLoad;
      }

      const autoPlay = params.get('autoPlay') === 'true';

      if (autoPlay) {
        this.autoPlay = autoPlay;
      }
    });

    this.route.paramMap.subscribe(async params => {
      const infoId = params.get('id');

      if (infoId) {
        await this.loadInfo(infoId);

        const useCaseFiles = await this.fileExplorerService
          .GetFilesForUseCaseAsync('infos', 1, 50, true, this.info.id);

        this.tabs = useCaseFiles.map(u => u.Name);
        this.useCaseFiles = useCaseFiles;

        this.setTabSet()

        if (this.autoLoad) {
          await this.getFiles(this.useCaseFiles);
        }
      }
    });
  }

  private setTabSet() {
    const currentTabSet = this.tabs.slice(this.tabSetStartIndex, this.tabSetStartIndex + this.tabSetLength);
    currentTabSet.forEach(c => {
      if (this.tabSetStartIndex === 0) {
        this.tabSetActive.pop();
        this.tabSetActive.unshift(c);
      } else {
        this.tabSetActive.shift();
        this.tabSetActive.push(c)
      }

    });
  }

  private async loadInfo(id: string) {
    this.info = await this.infosService.getInfoById(id);
  }

  private async getFile(useCaseFile: UseCaseFile) {
    this.loadInitiated.push(useCaseFile.Name);

    const downloadObservable =
      this.fileExplorerService.GetUseCaseFileAsync('infos', useCaseFile.s3Key);

    downloadObservable.subscribe(httpEvent => {
      if ( httpEvent.type === HttpEventType.DownloadProgress ) {
        // console.log(httpEvent.loaded + ' from ' + useCaseFile.Size + 'downloaded.');

        const intermediate = httpEvent.loaded / parseInt(useCaseFile.Size) * 100;
        useCaseFile.DownloadProgress = Math.round((intermediate + Number.EPSILON) * 100) / 100

        // console.log('[' + useCaseFile.Name + '] ' + useCaseFile.DownloadProgress + ' %')
      }

      if (httpEvent.type === HttpEventType.Response) {
        this.loadedFiles.push(useCaseFile.Name);
        const objectUrl = URL.createObjectURL(httpEvent.body);
        const newVideoSafeUrl = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
        const newVideoSafeUrlForPreview = this.sanitizer.bypassSecurityTrustUrl(objectUrl + '#t=2.0');


        VideoPlayerService.addFile(useCaseFile.Name, newVideoSafeUrl, newVideoSafeUrlForPreview);
      }
    });
  }

  private async getFiles(files: UseCaseFile[]) {
      for (const useCaseFile of files) {
        this.getFile(useCaseFile);

        /*
        if (file) {
          this.loadedFiles.push(useCaseFile.Name);
          const objectUrl = URL.createObjectURL(file);
          const newVideoSafeUrl = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
          const newVideoSafeUrlForPreview = this.sanitizer.bypassSecurityTrustUrl(objectUrl + '#t=2.0');


          VideoPlayerService.addFile(useCaseFile.Name, newVideoSafeUrl, newVideoSafeUrlForPreview);
        }*/
      }
  }

  public isLoaded(tab: string): boolean {
    if (this.loadedFiles.includes(tab)) {
      return true;
    }

    return false;
  }

  public getFormattedFileSize(tab: string) {
    const useCaseFile = this.useCaseFiles.find(u => u.Name === tab);

    if (useCaseFile) {
      return fileSize(parseInt(useCaseFile.Size));
    }

    return fileSize(0);
  }

  public getVideoEntity(tab: string): VideoPlayerEntity {
   return VideoPlayerService.videos.find(v => v.name === tab);
  }

  public videoSelected(videoPlayerEntity: VideoPlayerEntity) {
    this.selectedVideo = null;

    setTimeout(() => {
      this.selectedVideo = videoPlayerEntity;

    }, 500);

    // console.log(videoPlayerEntity);
  }

  getProgess(tab: string) {
    // return this.useCaseFiles.find(u => u.Name === tab).DownloadProgress;

    const useCaseFile = this.useCaseFiles.find(u => u.Name === tab);

    if (useCaseFile) {
      return useCaseFile.DownloadProgress;
    }

    return undefined;
  }

  public loadFile(tab: string) {
    if (tab && !this.isAlreadyInitiated(tab)) {
      this.getFile(this.useCaseFiles.find(u => u.Name === tab));
    }
  }

  public isAlreadyInitiated(tab: string): boolean {
    const result =this.loadInitiated.find(element => element === tab);

    if (result) {
      return true;
    }

    return false;
  }

  public moreTabSetsPresent() {
    const currentTabSetLastIndex = this.tabSetStartIndex + (this.tabSetLength - 1);
    // console.log('currentTabSetLastIndex ' + currentTabSetLastIndex);
    // console.log('currentArrayEndIndex ' + (this.tabs.length - 1));

    if (currentTabSetLastIndex < this.tabs.length - 1) {
      return true;
    }

    return false;
  }

  public tabSetBack() {
    // console.log(this.tabSetActive);
    // console.log('currentTabSetStartIndex ' + this.tabSetStartIndex);

    let newTabSetIndex = this.tabSetStartIndex - this.tabSetLength;

    if (newTabSetIndex < 0) {
      newTabSetIndex = 0;
    }

    this.tabSetStartIndex = newTabSetIndex;
    // console.log('newTabSetStartIndex ' + this.tabSetStartIndex);
    this.setTabSet();

    // console.log(this.tabSetActive);

  }

  public tabSetForward() {
    // console.log(this.tabSetActive);
    // console.log('currentTabSetStartIndex ' + this.tabSetStartIndex);

    this.tabSetStartIndex = this.tabSetStartIndex + this.tabSetLength;
    // console.log('newTabSetStartIndex ' + this.tabSetStartIndex);

    this.setTabSet();
    // console.log(this.tabSetActive);

  }

  getVideoPlayerWidth() {
    if (this.visibleWidth > 1270 && this.kioskMode) {
      return this.visibleWidth - 20;
    }

    return 1270;
  }

  getVideoPlayerHeight(offSet: number = 0, kioskModeOffset: number = 0): number {
    if (this.kioskMode) {
      const previewHeightOffset: number  = 200;
      const footerHeightOffSet: number = 70;
      const treshold: number = 25;

      return this.visibleHeight - previewHeightOffset - footerHeightOffSet - treshold - kioskModeOffset;
    }

    return 500 + offSet;
  }
}
