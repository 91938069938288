import {isDevMode} from '@angular/core';
import {Injector, InjectorVendor} from '@cstx/volkswagen-mqs-quality-management-service-client';
import {CheckDigitService} from '../../../../services/check-digit.service';

export class VirtualEngineLabel {
  private _engineCode: string;
  private _engineNumber: string;
  private _keyCode: string;
  public costCenter: string;

  public lotSize: string;
  public lotNumber: string;

  public typeApprovalLabel: string;
  public countryQualifier: string;

  private _timingBeltLabel: string;
  private _groupLabelValue: string;

  private _offSetLabelValue: string;
  private _offSetList = Array<string>();

  private _injectors: Injector[];

  public buildDate: Date;
  public timingBeltLabelIsReady: boolean;
  public groupLabelIsReady: boolean;
  public offSetLabelIsReady: boolean;
  public injectorLabelIsReady: boolean;

  public hasInjectors: boolean;
  public hasOffSet: boolean;

  public injectorManufacturer: InjectorVendor = InjectorVendor.Unknown;

  public plant = '17';




  public set engineCode(value) {
    this._engineCode = value;
  }
  public get engineCode(): string {
    return this._engineCode;
  }

  public set engineNumber(value) {
    this._engineNumber = value;

    this.validateEngineLabel()
  }
  public get engineNumber(): string {
    return this._engineNumber;
  }

  public set keyCode(value) {
    this._keyCode = value;

    this.validateEngineLabel();
  }
  public get keyCode(): string {
    return this._keyCode;
  }

  public get componentName(): string {
    if (this._engineCode && this._engineNumber) {
      return this._engineCode + '-' + this._engineNumber;
    }

    return undefined;
  }

  public get engineLabelValue(): string {
    if (this._keyCode && this._engineNumber) {
      return '0' + this.engineNumber + this.keyCode + 17;
    }

    return undefined;
  }

  public set timingBeltLabelValue(value: string) {
    this._timingBeltLabel = value;
    this.validateTimingBeltLabel();
  }

  public get timingBeltLabelValue(): string {
    return this._timingBeltLabel;
  }


  public set groupLabelValue(value: string) {
      this._groupLabelValue = value;
      this.validateGroupLabel();
  }
  public get groupLabelValue(): string {
    return this._groupLabelValue;
  }

  public set offsetLabelValue(value: string) {
    this._offSetLabelValue = value;

    this.validateOffSetLabel();
  }

  public get offsetLabelValue(): string {
    return this._offSetLabelValue;
  }


  public set injectors (value: Injector[]) {
    this._injectors = value;

    this.validateInjectorLabels();
  }

  public get injectors(): Injector[] {
    return this._injectors;
  }

  public get injectorLabel1(): string {
    const injectorLeft = this.injectors.find(i => i.position === '1');
    const injectorRight = this.injectors.find(i => i.position === '2');

    return injectorLeft.injectorLabel + injectorRight.injectorLabel;
  }

  public get injectorLabel2(): string {
    const injectorLeft = this.injectors.find(i => i.position === '3');
    const injectorRight = this.injectors.find(i => i.position === '4');

    return injectorLeft.injectorLabel + injectorRight.injectorLabel;
  }

  public set offSetList(value: string[]) {
    this._offSetList = value;
    this.validateOffSet();
  }

  public get engineLabelCheckDigit(): string {
    if (this.timingBeltLabelIsReady) {
      return this.timingBeltLabelValue.substring(this.timingBeltLabelValue.length - 1, this.timingBeltLabelValue.length);
    }

    return undefined;
  }

  public sznwdata: string;
  public szkwdata: string;
  public offsetPairList = new Array<string[]>();

  constructor() {}

  private validateEngineLabel() {
    if (this.engineLabelValue !== undefined) {

      const checkDigitService = new CheckDigitService();

      if (isDevMode()) {
        console.log(new Date().toLocaleString() + ': The engineLabel is now ready to be used.');
      }
    }
  }

  private validateGroupLabel() {
    if (this.groupLabelValue) {
      this.groupLabelIsReady = true;

      if (isDevMode()) {
        console.log(new Date().toLocaleString() + ': The groupLabel is now ready to be used.');
      }
    }
  }

  private validateTimingBeltLabel() {
    if (this._timingBeltLabel) {
      this.timingBeltLabelIsReady = true;

      if (isDevMode()) {
        console.log(new Date().toLocaleString() + ': The timingBeltLabel is now ready to be used.');
      }
    }
  }

  private validateOffSetLabel() {
    if (this.offsetLabelValue !== undefined) {
      this.offSetLabelIsReady = true;

      if (isDevMode()) {
        console.log(new Date().toLocaleString() + ': The offsetLabel is now ready to be used.');
      }
    }
  }

  private validateInjectorLabels() {
    if (this.injectors !== undefined && this.injectors.length >= 1) {

      this.hasInjectors = true;
      this.injectorManufacturer = this.injectors[0].vendor;

      const injector1 = this.injectors.find(i => i.position === '1');
      const injector2 = this.injectors.find(i => i.position === '2');
      const injector3 = this.injectors.find(i => i.position === '3');
      const injector4 = this.injectors.find(i => i.position === '4');

      if (injector1 && injector2 && injector3 && injector4) {
        this.injectorLabelIsReady = true;

        if (isDevMode()) {
          console.log(new Date().toLocaleString() + ': The injector labels are now ready to be used.');
        }
      }
    } else {
      this.hasInjectors = false;
      this.injectorLabelIsReady = false;
      this.injectorManufacturer = InjectorVendor.Unknown;
    }
  }

  private validateOffSet() {
    if (this._offSetList.length >= 2) {
      this.hasOffSet = true;
      this.offsetPairList = this.getOffSetPairList();
      console.log(this.offsetPairList);
    } else {
      this.hasOffSet = false;
    }
  }

  private getOffSetPairList(): string[][] {
    let offsetPair = new Array<string>();
    const offsetPairList = new Array<string[]>();

    this._offSetList.forEach(v =>{
      if (v.toLowerCase().startsWith('xk')) {
        this.sznwdata = v;
      } else {

        // TODO: I guess, this behaviour does not fit all available label types
        // TODO: It seems, sometimes we need the checkdigit, and sometimes not. Re-evaluate
        // TODO: Trimming by length -1 was removed for now.
        let values: string[];
        if (v.includes('&')) {
           values = v.substring(0, v.length - 1).split('&')
        } else {
          values = v.split('&');
        }

        values.forEach(s => {
          if (offsetPair.length <= 1) {
            offsetPair.push(s);
          }

          if (offsetPair.length === 2) {
            offsetPairList.push(offsetPair);
            offsetPair = new Array<string>();
          }
        });

        if (offsetPair.length > 0) {
          offsetPairList.push(offsetPair);
        }
      }
    });

    return offsetPairList;
  }

  public getInjectorOffSet(position: number) {
    return this.injectors.find(i => i.position === position.toString()).injectorLabel;
  }

  public hasTypeApprovalLabel(): boolean {
    return this.typeApprovalLabel && this.typeApprovalLabel !== '';
  }
}
