import {AuditInformation} from '../../../shared/models/auditInformation';

export class DlqMessage {
  public id: string;
  public sendAt: string;
  public channel: string;
  public service: string;
  public instance: string;
  public subject: string;
  public sequence: number;
  public errorType: string;
  public traceId: string;
  public originalMessage: string;
  public errorMessage: string;
  public stackTrace: string;
  public auditInfos: AuditInformation;

  constructor() {
    this.sendAt = new Date().toLocaleDateString();
    this.auditInfos = new AuditInformation();
  }
}
