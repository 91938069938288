<div class="container-fluid" [class.cpp-page-defaults]="!KioskModeService.kioskMode">
  <div class="pt-2 pb-2"><op-breadcrumb [version2]="true" path="Admin/Websocket"></op-breadcrumb></div>
  <div class="row">
    <div class="col-9">
      <table class="table table-hover">
        <tr class="row pl-3 pr-3">
          <td class="col pb-0 pl-0 nav border-bottom">
            <ul class="nav">
              <li class="nav-item pr-0">
                <a [routerLink]="['/admin/websocket']" [queryParams]="{ activeTab: 'raw' }">
                  <button type="button" class="btn btn-sm button cpp-button-tab-selector"
                          [class.cpp-button-tab-selected]="isActiveTab('raw')" (click)="setActive('raw')">
                    Raw
                  </button>
                </a>
              </li>
              <li class="nav-item pr-0" *ngIf="componentCreatedMessages.length > 0">
                <a [routerLink]="['/admin/websocket']" [queryParams]="{ activeTab: 'componentCreated' }">
                  <button type="button" class="btn btn-sm button cpp-button-tab-selector"
                          [class.cpp-button-tab-selected]="isActiveTab('componentCreated')" (click)="setActive('componentCreated')">
                    ComponentCreatedEvents
                  </button>
                </a>
              </li>
              <li class="nav-item pr-0" *ngIf="faultCreatedMessages.length > 0">
                <a [routerLink]="['/admin/websocket']" [queryParams]="{ activeTab: 'faultCreated' }">
                  <button type="button" class="btn btn-sm button cpp-button-tab-selector"
                          [class.cpp-button-tab-selected]="isActiveTab('faultCreated')" (click)="setActive('faultCreated')">
                    FctEvents
                  </button>
                </a>
              </li>
              <li class="nav-item pr-0" *ngIf="genericMessages.length > 0">
                <a [routerLink]="['/admin/websocket']" [queryParams]="{ activeTab: 'generic' }">
                  <button type="button" class="btn btn-sm button cpp-button-tab-selector"
                          [class.cpp-button-tab-selected]="isActiveTab('generic')" (click)="setActive('generic')">
                    GenericEvents
                  </button>
                </a>
              </li>
              <li class="nav-item pr-0" *ngIf="workShiftMessages.length > 0">
                <a [routerLink]="['/admin/websocket']" [queryParams]="{ activeTab: 'workShiftEvents' }">
                  <button type="button" class="btn btn-sm button cpp-button-tab-selector"
                          [class.cpp-button-tab-selected]="isActiveTab('workShiftEvents')" (click)="setActive('workShiftEvents')">
                    WorkShiftEvents
                  </button>
                </a>
              </li>
            </ul>
          </td>
        </tr>
      </table>
      <div style="display: flex; flex-direction: column-reverse;">

        <ng-container *ngIf="isActiveTab('raw')">
          <ng-container *ngFor="let messageEvent of messages; index as i">
            <ng-container *ngIf="!hiddenSubjects.has(messageEvent.subject)">
            <div class="row small pt-1 pl-3 pr-3">
              <div class="cpp-column-fixed-width-40 text-center text-monospace">
                {{ (i + 1).toString().padStart(messages.length.toString().length < 3 ? 3 : messages.length.toString().length, '0') }}
              </div>
              <div class="col-2 text-overflow-ellipses">
                <op-component-state-badge [name]="messageEvent.subject"></op-component-state-badge>
              </div>
              <div class="col-6 text-overflow-ellipses pre-white">
                {{ messageEvent.message }}
              </div>
              <div class="cpp-column-fixed-date">
                <op-datetime-show [noLocalTimeConversion]="false" [dateTime]="messageEvent.receivedAt?.substring(0,19)"></op-datetime-show>
              </div>
              <div class="cpp-column-fixed-width-25">
              <op-copy-to-clipboard-icon [content]="messageEvent.message"></op-copy-to-clipboard-icon>
              </div>
            </div>
          </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="isActiveTab('componentCreated')">
          <ng-container *ngFor="let entity of componentCreatedMessages; index as i">
            <ng-container *ngIf="!isHidden(entity)">
            <div class="row small pt-1 pl-3 pr-3">
              <div class="cpp-column-fixed-width-40 text-center text-monospace">
                {{ (i + 1).toString().padStart(componentCreatedMessages.length.toString().length < 3 ? 3 : componentCreatedMessages.length.toString().length, '0') }}
              </div>
              <div class="cpp-column-fixed-width-100 text-overflow-ellipses">
                <op-component-state-badge [name]="entity.componentType"></op-component-state-badge>
              </div>
              <div class="cpp-column-fixed-cost-center text-monospace text-overflow-ellipses pre-white">
                {{ entity.costCenter }}
              </div>
              <div class="cpp-column-fixed-width-150 text-overflow-ellipses pre-white">
                {{ entity.partNumber }}
              </div>
              <div class="col text-overflow-ellipses pre-white">
                {{ entity.componentName }}
              </div>
              <div class="cpp-column-fixed-date">
                <op-datetime-show [noLocalTimeConversion]="false" [dateTime]="entity.componentCreationDate"></op-datetime-show>
              </div>
              <div class="cpp-column-fixed-width-25">
                <op-copy-to-clipboard-icon [content]="entity.rawMessageJson"></op-copy-to-clipboard-icon>
              </div>
            </div>
              </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="isActiveTab('faultCreated')">
          <ng-container *ngFor="let entity of faultCreatedMessages; index as i">
            <ng-container *ngIf="!isHidden(entity)">

            <div class="row small pt-1 pl-3 pr-3">
              <div class="cpp-column-fixed-width-40 text-center text-monospace">
                {{ (i + 1).toString().padStart(faultCreatedMessages.length.toString().length < 3 ? 3 : faultCreatedMessages.length.toString().length, '0') }}
              </div>
              <div class="col-2 text-overflow-ellipses">
                <op-component-state-badge [name]="entity.componentType"></op-component-state-badge>
              </div>
              <div class="cpp-column-fixed-cost-center text-overflow-ellipses pre-white">
                {{ entity.componentName }}
              </div>
              <div class="col-3 text-overflow-ellipses pre-white">
                {{ entity.reason }}
              </div>
              <div class="col-3 text-overflow-ellipses pre-white">
                {{ entity.checkResult }}
              </div>
              <div class="cpp-column-fixed-date">
                <op-datetime-show [noLocalTimeConversion]="false" [dateTime]="entity.createdAt"></op-datetime-show>
              </div>
              <div class="cpp-column-fixed-width-25">
              <op-copy-to-clipboard-icon [content]="entity.rawMessageJson"></op-copy-to-clipboard-icon>
              </div>
            </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="isActiveTab('generic')">
          <ng-container *ngFor="let entity of genericMessages; index as i">
            <ng-container *ngIf="!isHidden(entity)">

            <div class="row small pt-1 pl-3 pr-3">
              <div class="cpp-column-fixed-width-40 text-center text-monospace">
                {{ (i + 1).toString().padStart(faultCreatedMessages.length.toString().length < 3 ? 3 : faultCreatedMessages.length.toString().length, '0') }}
              </div>
              <div class="col-3 text-overflow-ellipses">
                {{ entity.subject }}
              </div>
              <div class="col text-overflow-ellipses">
                {{ entity.message }}
              </div>
              <div class="col text-overflow-ellipses">
                {{ entity.event }}
              </div>
              <div class="col text-overflow-ellipses">
                {{ entity.costCenter }}
              </div>
              <div class="cpp-column-fixed-date">
                <op-datetime-show [noLocalTimeConversion]="false" [dateTime]="entity.receivedAt"></op-datetime-show>
              </div>
              <div class="cpp-column-fixed-width-25">
                <op-copy-to-clipboard-icon [content]="entity.rawMessageJson"></op-copy-to-clipboard-icon>
              </div>
            </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="isActiveTab('workShiftEvents')">
          <ng-container *ngFor="let entity of workShiftMessages; index as i">
            <ng-container *ngIf="!isHidden(entity)">

              <div class="row small pt-1 pl-3 pr-3">
                <div class="cpp-column-fixed-width-40 text-center text-monospace">
                  {{ (i + 1).toString().padStart(faultCreatedMessages.length.toString().length < 3 ? 3 : faultCreatedMessages.length.toString().length, '0') }}
                </div>
                <div class="col-3 text-overflow-ellipses">
                  {{ entity.actual }} / {{ entity.target }}
                </div>
                <div class="col text-overflow-ellipses">
                  {{ entity.shiftEvent }}
                </div>
                <div class="col text-overflow-ellipses">
                  {{ entity.shift }}
                </div>
                <div class="col text-overflow-ellipses">
                  {{ entity.costCenter }}
                </div>
                <div class="cpp-column-fixed-date">
                  <op-datetime-show [noLocalTimeConversion]="false" [dateTime]="entity.receivedAt"></op-datetime-show>
                </div>
                <div class="cpp-column-fixed-width-25">
                  <op-copy-to-clipboard-icon [content]="entity.rawMessageJson"></op-copy-to-clipboard-icon>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

    </div>
    <div class="col-3">

      <div class="row">
        <div class="col">
          <div class="pt-3">
            <ng-container *ngIf="!isConnected">
              <button
                class="btn btn-primary btn-sm btn-spacing"
                (click)="open()">
                Connect
              </button>
            </ng-container>

            <ng-container *ngIf="isConnected">
              <button
                class="btn btn-danger btn-sm btn-spacing"
                (click)="close()">
                Disconnect
              </button>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col small">
          Connect at: <br>
          <op-datetime-show [dateTime]="connectedAt"></op-datetime-show>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col small">
          Last message received: <br>
          <op-datetime-show [dateTime]="lastMessageReceivedAt"></op-datetime-show>
        </div>
      </div>

      <ng-container *ngIf="counters.length > 0">
      <div class="row pt-3">
        <div class="col small font-weight-bold">
          Category counter <op-count-badge [count]="counters.length"></op-count-badge>
        </div>
      </div>
      <ng-container *ngFor="let counter of counters">
        <div class="row pt-3">
          <div class="col small">
            {{ counter.category }}
          </div>
            <div class="col small">
              <op-count-badge [zeroPaddingLeft]="3" [count]="counter.messageCount">

              </op-count-badge>
            </div>
        </div>
      </ng-container>
      </ng-container>


      <ng-container *ngIf="costCenters.size > 0 || partNumbers.size > 0  || subjects.size > 0">
        <div class="row pt-3">
          <div class="col small font-weight-bold">
            Type filters

            <i *ngIf="filtered"
               class="fas fa-filter pointer"
               (click)="clearFilters()"

               title="Click to remove filters"
            >
            </i>
          </div>
        </div>
      <div class="row pt-3 pl-3" *ngIf="costCenters.size > 0" style="width: 250px;">
      <ng-container *ngFor="let costCenter of costCenters">
          <div class="cpp-column-fixed-width-40 small pointer text-monospace" (click)="toggleCostCenterVisibility(costCenter)">
           <op-component-state-badge [name]="costCenter" [customBadgeClass]="hiddenCostCenter.has(costCenter) ? 'badge-secondary' : 'badge-danger'"></op-component-state-badge>
          </div>
      </ng-container>
      </div>

      <div class="row pt-3 pl-3" *ngIf="partNumbers.size > 0" style="width: 250px;">
        <ng-container *ngFor="let value of partNumbers">
          <div class="cpp-column-fixed-width-80 small pointer" (click)="togglePartNumberVisibility(value)">
            <op-component-state-badge [name]="value" [customBadgeClass]="hiddenPartNumbers.has(value) ? 'badge-secondary' : 'badge-success'"></op-component-state-badge>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="subjects.size > 0">
        <ng-container *ngFor="let subject of subjects">
          <div class="row pt-3">
          <div class="col small pointer" (click)="toggleSubjectVisibility(subject)">
            <op-component-state-badge [name]="subject" [customBadgeClass]="hiddenSubjects.has(subject) ? 'badge-secondary' : 'badge-warning'" ></op-component-state-badge>
          </div>
          </div>
        </ng-container>
      </ng-container>
      </ng-container>

      <ng-container *ngIf="isConnected">
       <div class="row pt-3">
          <div class="col small font-weight-bold">
            Cycletimes since start
            <i class="fas fa-eraser pointer" style="color: darkred;"
               (click)="resetCycleTimes()"
               title="Click to reset cycle times"
            ></i>
          </div>
        </div>

      <ng-container *ngIf="costCenterCounters.length > 0; else waitingForCountersTemplate">
        <ng-container *ngIf="plantCycleTime">
          <div class="row pl-3">
            <div class="cpp-column-fixed-width-60 small">
             Overall
            </div>
            <div class="cpp-column-fixed-width-75 small pointer text-right text-monospace">
              {{ plantCycleTime.toFixed(1) }} sec
            </div>
          </div>
        </ng-container>
      <ng-container *ngFor="let counter of costCenterCounters">
        <div class="row pl-3">
          <div class="cpp-column-fixed-width-60 small text-monospace">
            {{ counter.costCenter }}
          </div>
          <div class="cpp-column-fixed-width-75 small">
            {{ counter.count }} pcs
          </div>
          <div class="cpp-column-fixed-width-75 small text-right text-monospace">
            {{ counter.count >= 5 ? counter.cycleTimeCurrent.toFixed(1) : '-' }} sec
          </div>
        </div>
      </ng-container>
      </ng-container>
      <ng-template #waitingForCountersTemplate>
        <div class="row pl-3">
          <div class="cpp-column-fixed-width-140 text-center">
            <i class="fas fa-cog fa-spin"></i>
          </div>
        </div>
      </ng-template>
      </ng-container>

      <ng-container *ngIf="websocketService.webSocketClients
      && websocketService.webSocketClients.length > 0">
        <div class="row pt-3">
          <div class="col small font-weight-bold">
            Websocket users
            <i *ngIf="websocketService.webSocketClientsUpdating"
                                 class="fas fa-spinner fa-spin"></i>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let client of websocketService.webSocketClients">
        <div class="row">
          <div class="col small">
            {{ client }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
