import {Component, DoCheck, Input, OnInit} from '@angular/core';
import {FilterBase} from '../../models/filterBase';
import {SortOrder} from '../../models/sortOrder';

@Component({
  selector: 'op-sort-order-indicator',
  templateUrl: './sort-order-indicator.component.html',
  styleUrls: ['./sort-order-indicator.component.scss']
})
export class SortOrderIndicatorComponent implements OnInit, DoCheck {
  active: boolean;
  ascending: boolean;
  @Input() filter: FilterBase;
  @Input() target: string;
  constructor() {
  }

  ngOnInit(): void {
  }

  ngDoCheck(): void {
    const tmp: Array<string> = this.filter.sort[0]?.split(',');
    if (tmp && tmp.length >= 2 && tmp[0] === this.target) {
      this.active = true;
      this.ascending = tmp[1] === SortOrder.Ascending.toLowerCase();
    } else {
      this.active = false;
    }
  }

}
