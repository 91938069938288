<div class="input-group mt-auto" [id]="inputId+'Par'" (keydown.escape)="clear()">
  <div *ngIf="prependIcon" class="input-group-prepend pointer" [id]="inputId+'Label'">
    <span class="input-group-text"><i class="fas fa-calendar"></i></span>
  </div>

  <input [disabled]="false" [ngClass]="{ 'is-invalid': isInvalid}" [id]="inputId" type="text" class="form-control"
         placeholder="{{ noPlaceHolder ? '' : 'search.rangePlaceholder' | translate}}" readonly>

  <div *ngIf="!prependIcon" class="input-group-append pointer" [id]="inputId+'Label'">
    <span class="input-group-text"><i class="fas fa-calendar"></i></span>
  </div>

</div>
