import {EventEmitter, Injectable, Input, Output} from '@angular/core';
import {
  CostCenterControllerService,
  CostCenterControllerV2Service,
  CostCenterResponse,
  ProductCenterControllerService,
  ProductCenterControllerV2Service,
  SliceCostCenterResponse
} from '@cstx/volkswagen-mqs-metadata-service-client';
import {CostCenterFilter} from '../models/costCenterFilter';
import {firstValueFrom, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CostCentersResponse} from '../models/costCentersResponse';
import {Cell, ExcelExport, Row} from '../../../shared/components/excel-export/excelExport';
import {FavoriteMenuEventArgs} from "../../../shared/components/component-profile/actions/actions.component";
import {CostCenter} from "../../../shared/services/backend/models/cost-center";

@Injectable({
  providedIn: 'root'
})
export class CostCenterService {
  @Input() onFavoriteExpandCollapse = new EventEmitter<FavoriteMenuEventArgs>();
  @Output() toggleFavoriteExpandCollapse = new EventEmitter<string>()

  public costCentersResponse = new CostCentersResponse();
  private pendingGetRequests$ = new Subject<void>();


  constructor(private costCenterControllerV2: CostCenterControllerV2Service) {
    this.onFavoriteExpandCollapse.subscribe(e => {
      this.toggleFavoriteExpandCollapse.emit(e.Favorite);
    });
  }

  /**
   * @deprecated Should be replaced with public getCostCenters from metadata-v2 service.
   * @param filter
   * @param reset
   */
  public getCostCenters(filter: CostCenterFilter, reset: boolean = false): Promise<CostCentersResponse> {
    return new Promise<CostCentersResponse>((resolve, reject) => {
      this.costCenterControllerV2.getCostCentersV2(filter.getAllParams())
        .pipe(takeUntil(this.onCancelPendingCostCentersRequests()))
        .toPromise()
        .then(r => {
          const costCenters = this.mapToCostCenterArray(r);

          if (reset) {
            this.costCentersResponse.costCenters = costCenters;
          } else {
            this.costCentersResponse.costCenters = this.costCentersResponse.costCenters.concat(costCenters);
          }

          this.costCentersResponse.slice = {
            first: r.first,
            last: r.last,
            pageNumber: r.page,
            empty: r.empty,
            size: r.size
          };

          resolve(this.costCentersResponse);
        });
    });
  }

  private onCancelPendingCostCentersRequests() {
    return this.pendingGetRequests$.asObservable();
  }



  private mapToCostCenterArray(response: SliceCostCenterResponse) {
    const arr = new Array<CostCenter>();
    response.content?.forEach( r => {
      arr.push(this.mapToCostCenter(r));
    });

    return arr;
  }

  public mapToCostCenter(r: CostCenterResponse): CostCenter {
    return new CostCenter(r);
  }


}
