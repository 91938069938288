<ng-container *ngIf="engine">
  <ng-container [ngTemplateOutlet]="titleAndDescriptionTemplate">
  </ng-container>

  <div class="row">
    <div class="col small" *ngIf="engine.engineTestState === 'NONE' || engine.engineTestState === 'FAILED'">
      Fehlender oder fehlgeschlagener Kalttest!
    </div>
  </div>
  <div class="row">
    <div class="col small" *ngIf="engine.engineReworkState === 'PENDING'">
      Nicht abgeschlossene oder nicht abgenommene Nacharbeit vorhanden.
    </div>
  </div>
  <div class="row">
    <div class="col small" *ngIf="engine.engineBlockedState === 'BLOCKED'">
      Aktive Sperre vorhanden!
    </div>
  </div>
</ng-container>

<ng-container *ngIf="part">
  <ng-container [ngTemplateOutlet]="titleAndDescriptionTemplate">
  </ng-container>

  <div class="row">
    <div class="col small" *ngIf="part.partBlockedState === 'BLOCKED'">
      Aktive Sperre vorhanden!
    </div>
  </div>
</ng-container>

<ng-container *ngIf="stack">
  <ng-container [ngTemplateOutlet]="titleAndDescriptionTemplate">
  </ng-container>

  <div class="row" *ngFor="let insight of stack?.getLogisticalStateInsights()">
    <div class="col small" *ngIf="insight.problem === 'engineTestState' && insight.count > 0">
      Fehlender oder fehlgeschlagener Kalttest! ({{ insight.count }})
    </div>
    <div class="col small" *ngIf="insight.problem === 'engineReworkState' && insight.count > 0">
      Nicht abgeschlossene oder nicht abgenommene Nacharbeit vorhanden. ({{ insight.count }})
    </div>

    <div class="col small" *ngIf="insight.problem === 'engineBlockedState' && insight.count > 0">
      Aktive Sperre vorhanden! ({{ insight.count }})
    </div>
  </div>
</ng-container>


<ng-template #titleAndDescriptionTemplate>
    <ng-container *ngIf="free">
      <div class="row">
        <div class="col font-weight-bolder">
          {{ 'logistic.state.title-free' | translate }}
        </div>
      </div>

      <div class="row pb-3">
        <div class="col small">
          {{ 'logistic.state.description-free' | translate }}
        </div>
      </div>
    </ng-container>
  <ng-container *ngIf="!free">
    <div class="row">
      <div class="col font-weight-bolder">
        {{ 'logistic.state.title' | translate }}
      </div>
    </div>

    <div class="row pb-3">
      <div class="col small">
        {{ 'logistic.state.description' | translate }}
      </div>
    </div>
  </ng-container>
</ng-template>
