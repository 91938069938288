<ng-container *ngIf="!hidden">
  <ng-container *ngIf="!deletePending; else deleteConfirmationTemplate">
    <i
       [ngClass]="iconClass()"
       style="color: #343a40;"
       (click)="onDeleteClick()"></i>
  </ng-container>
</ng-container>

<ng-template #deleteConfirmationTemplate>
  <small>
    {{ securityQuestion }}
    <button type="button" class="btn btn-sm btn-danger pt-0 pb-0 small mr-1"
            (click)="onConfirmation.emit(); deletePending = false">
      {{ 'button.yes' | translate }}
    </button>
    <button type="button" class="btn btn-sm btn-secondary pt-0 pb-0"
            (click)='onCancellation.emit(); deletePending = false;'>
      {{ 'button.cancel' | translate }}
    </button>
  </small>
</ng-template>

<ng-container *ngIf="errorMessage">
  <div class="pl-1 d-inline" style="color: darkred">{{ errorMessage }}.</div>
</ng-container>

