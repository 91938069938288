<div class="container-fluid h-100 cpp-preview-no-focus" tabindex="0" [class.cpp-preview-maximized]="filePreview.maximized"
     (keydown.escape)="onMinimizeClicked($event)"
      (click)="onMinimizeClicked($event)">
  <ng-container *ngIf="!filePreview.LoadingComplete && loading"
    [ngTemplateOutlet]="loadingFileTemplate">
  </ng-container>
  <ng-container *ngIf="filePreview.LoadingComplete; else selectFileToPreviewTemplate">
  <div *ngIf="!filePreview.maximized" class="cpp-preview-header-area">
    <div class="d-flex">
      <div class="col small pl-0">
        <op-datetime-show [dateTime]="filePreview.Uploaded ? filePreview.Uploaded : undefined" [noPlaceHolder]="true"></op-datetime-show>
      </div>
      <div class="col-4 d-none d-md-block small pl-0 text-center">
        {{ filePreview.Size }}
      </div>
      <div class="col-4 text-right pr-0">
        <i *ngIf="filePreview.parserType.toLowerCase() === 'kaitai'"  (click)="triggerComponentUpdate()" class="cpp-icon-refresh pr-3 d-none d-md-inline" [class.cpp-icon-refresh-spin]="refreshSpinnerSpin"></i>
        <i *ngIf="filePreview.extension === 'xml'"  (click)="triggerCsvCreation()" class="cpp-icon-export pr-3 d-none d-md-inline"></i>
        <i *ngIf="filePreview.fileType === 3" class="cpp-icon-maximize d-none d-md-inline" id="MaximizePreviewIcon" (click)="onMaximizedClicked($event)"></i>
        <i class="cpp-icon-download d-none d-md-inline" (click)="onDownloadClicked()"></i>
        <op-copy-to-clipboard-icon class="d-none d-md-inline" [content]="filePreview.directLink"></op-copy-to-clipboard-icon>
        <i *ngIf="useCloseBtn" class="cpp-icon-base cpp-icon-size pr-3 fa-times" (click)="onCloseClicked()"></i>
      </div>
    </div>
  </div>
  <div class="cpp-preview-content-area" id=PreviewContentArea>
        <ng-container *ngIf="filePreview.IsImage() && !filePreview.HasError"
                      [ngTemplateOutlet]="previewImageAreaTemplate"></ng-container>
        <ng-container *ngIf="(filePreview.IsString() || filePreview.IsParsedBinary() || filePreview.IsParsedOther())
                      && !filePreview.HasError"
                      [ngTemplateOutlet]="previewEditorAreaTemplate"></ng-container>
        <ng-container *ngIf="filePreview.IsPdf() && !filePreview.HasError"
                      [ngTemplateOutlet]="previewPdfAreaTemplate"></ng-container>
        <ng-container *ngIf="filePreview.HasError" [ngTemplateOutlet]="errorTemplate"></ng-container>
  </div>
  </ng-container>
</div>

<!-- Templates -->
<!-- selectFileToPreviewTemplate -->
<ng-template #selectFileToPreviewTemplate>
  <div *ngIf="!loading" class="cpp-preview-select-file-area h-100" style="min-height: 200px">
    <div class="row d-flex justify-content-center h-100">
      <div class="m-auto">
        <div class="text-center pb-3" style="color: lightgray;">
          {{ 'shared.components.file-explorer.preview.select-for-preview' | translate }}
        </div>
        <div class="text-center">
          <i class="fas fa-file-alt fa-5x" style="color: lightgray"></i>
        </div>
    </div>
    </div>
  </div>
</ng-template>

<!-- loadingFileTemplate -->
<ng-template #loadingFileTemplate>
  <div class="cpp-preview-loading-file-area h-100">
    <div class="row">
      <div class="col justify-content-end d-flex">
        <i *ngIf="useCloseBtn" class="cpp-icon-base cpp-icon-size pr-3 fa-times" (click)="onCloseClicked()"></i>
      </div>
    </div>
    <div class="row d-flex justify-content-center h-100" style="position: relative;">
      <div class="m-auto">
        <div class="text-center pb-3" style="color: lightgray;">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ 'shared.components.file-explorer.preview.loading' | translate }}
        </div>
        <div class="text-center">
          <i class="fas fa-file-alt fa-5x" style="color: lightgray"></i>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- errorTemplate -->
<ng-template #errorTemplate>
  <div *ngIf="!loading" class="cpp-preview-select-file-area h-100">
    <div class="row d-flex justify-content-center h-100">
      <div class="m-auto">
        <div class="text-center pb-3" style="color: lightgray;">
          {{ 'shared.components.file-explorer.preview.no-preview' | translate }}
          <br>
          {{ 'shared.components.file-explorer.preview.click-to-download' | translate }}

        </div>
        <div class="text-center">
          <i class="fas fa-ban fa-5x" style="color: lightgray"></i>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- previewImageAreaTemplate -->
<ng-template #previewImageAreaTemplate>
  <img [src]="filePreview.File" class="cpp-preview-image-style m-auto" [class.cpp-preview-image-style-maximized]="filePreview.maximized" />
</ng-template>

<!-- previewPdfAreaTemplate -->
<ng-template #previewPdfAreaTemplate>
  <ngx-extended-pdf-viewer [src]="filePreview.File"
                           useBrowserLocale="true"
                           height="100%">
  </ngx-extended-pdf-viewer>
</ng-template>

<!-- previewEditorAreaTemplate -->
<ng-template #previewEditorAreaTemplate>
  <div style="height: 85vh">
    <op-monaco-editor [options]="monacoEditorOptions"></op-monaco-editor>
  </div>
</ng-template>


