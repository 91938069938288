import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoutingWatcherService {
  public lastFailedUrl: string;
  constructor(private router: Router) {
    router.events.subscribe(event => {
      // tslint:disable:no-string-literal
      if (event['urlAfterRedirects'] && event['url'] && event['url'] !== event['urlAfterRedirects']) {
        this.lastFailedUrl = event['url'];
      }
    });
  }
}
