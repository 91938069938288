import {AfterViewChecked, Component, EventEmitter, Input, isDevMode, OnInit, Output} from '@angular/core';
import {LanguageService} from '../services/language.service';
import {InboxService} from '../../shared/services/inbox.service';
import {DatePipe} from '@angular/common';
import {ConfigService, PORTALMODE} from '../services/config.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../models/user';
import {ApplicationControllerService} from '@cstx/volkswagen-mqs-application-management-service-client';
import {firstValueFrom} from 'rxjs';
import {ErrorHandler} from '../../shared/services/error-handler/error-handler';
import {DeviceLocation, LocationService} from '../services/location.service';
import {UserInformationResponse } from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {LocationAwareCallSystemService} from '../../shared/services/location-aware-call-system.service';
import {EnterpriseRoleProviderService} from '../services/enterprise-role-provider.service';
import {NgxCaptureService} from 'ngx-capture';
import {SendMessageService} from '../../shared/components/send-message/send-message.service';
import {LoggingService} from '../logging/logging.service';

declare let $: any;

@Component({
  selector: 'op-navbar-top',
  templateUrl: './navbar-top.component.html',
  styleUrls: ['./navbar-top.component.scss']
})
export class NavbarTopComponent implements OnInit, AfterViewChecked {


  constructor(private languageService: LanguageService,
              private inboxService: InboxService,
              private datePipe: DatePipe,
              public configService: ConfigService,
              private applicationManagementService: ApplicationControllerService,
              public route: ActivatedRoute,
              public router: Router,
              private locationService: LocationService,
              private captureService: NgxCaptureService,
              private locationAwareCallSystemService: LocationAwareCallSystemService) {
  }
  @Output() logoutClickEvent = new EventEmitter<any>();

  @Input() isLoggedIn: boolean;
  @Input() currentUser: User;
  @Input() kioskMode = false;

  currentStage: string;
  languages: Array<any>;
  portalMode: PORTALMODE;

  tokenCheckFinished = false;
  tokenCheckPending = false;
  tokenAboutToExpire = 0;
  tokenExpired = 0;

  unReadMessageCounter = 0;
  public deviceLocation = new DeviceLocation();

  private sidebarCollapsed: boolean;
  public formattedDate: string = this.datePipe.transform(new Date(), 'dd.MM.yyyy HH:mm:ss');

  public initiallyCalledUrl: string;
  public supervisorCalled: boolean;
  private activeSupervisorInfo: UserInformationResponse = undefined;

  public helpCalled: boolean;
  private activeAlertInfo: UserInformationResponse = undefined;

  protected readonly EnterpriseRoleProviderService = EnterpriseRoleProviderService;

  public ngOnInit(): void {
    this.currentStage = this.configService.getStage();
    this.languages = this.languageService.languages;

    this.portalMode = this.configService.getPortalMode();

    this.inboxService.onUnReadCounterChanged.subscribe(count => {
      if (this.unReadMessageCounter !== count) {
        this.unReadMessageCounter = count;
      }
    });

    EnterpriseRoleProviderService.onPermissionsLoaded.subscribe(() => {
      if (!this.tokenCheckPending) {
        this.tokenCheckPending = true;
        this.checkForTokenExpiry();
      }
    });

    this.locationService.deviceLocation$
      .subscribe(deviceLocation => {
      this.deviceLocation = deviceLocation;

      if (this.deviceLocation) {
        this.locationAwareCallSystemService.Init(this.deviceLocation);
      }

    });

    this.initiallyCalledUrl = window.location.href;
  }


  isProductionStage() {
    if (this.currentStage === undefined) {
      return true;
    }

    return false;
  }

  private async checkForTokenExpiry() {
    try {
      if (EnterpriseRoleProviderService.effectiveEnterpriseRoles.length <= 0) {
        if (isDevMode()) {
          console.log(new Date().toLocaleString() + ': No effective roles on user. Aborting token expiry check.')
        }

        return;
      }

      if (EnterpriseRoleProviderService.hasRequiredRole('role_admin') && !this.tokenCheckFinished) {
        if (isDevMode()) {
          console.log(new Date().toLocaleString() + ': User is member of role admin. Trying token expiry check.')
        }

        const req = this.applicationManagementService.getExpiredTokenStatistics({});
        const result = await firstValueFrom(req);

        this.tokenExpired = result.alreadyExpired;
        this.tokenAboutToExpire = result.soonExpiring;

        this.tokenCheckFinished = true;

      }
    } catch (error) {
      ErrorHandler.printError(error);
    } finally {
      this.tokenCheckPending = false;
    }
  }

  onLogoutClick() {
    this.logoutClickEvent.emit();
  }

  languageSelected(value: string) {
    this.languageService.setLanguage(value);
  }

  ngAfterViewChecked(): void {
    this.sidebarCollapsed = localStorage.getItem('sidebarCollapsed') === 'true';
    $('#stageNotificationDevelop').toggleClass('active', this.sidebarCollapsed);
    $('#stageNotificationIntegration').toggleClass('active', this.sidebarCollapsed);
    $('#stageNotificationProduction').toggleClass('active', this.sidebarCollapsed);
  }


  callForHelp() {
    this.locationAwareCallSystemService.createAlert();
  }

  callForSupervisor() {
    this.locationAwareCallSystemService.createSupportRequest();
  }

  public getHomeUrl() {
    if (EnterpriseRoleProviderService.hasRequiredClaimByName('home-dashboard')) {
      return '#';
    }

    return 'home/info/show';
  }

  public async createErrorReport() {
      SendMessageService.reset();
      SendMessageService.username = this.currentUser.username;
      SendMessageService.currentStage = this.currentStage;
      SendMessageService.userFullName = this.currentUser.name;
      SendMessageService.userMail = this.currentUser.email;
      SendMessageService.previousLocation = this.router.url;

      const logFile = LoggingService.createExportFile(100);
      SendMessageService.attachments.push({ name: this.currentUser.username.toLowerCase() + '_last-logs.csv', data: logFile});

      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';

    await this.router.navigate(['home', 'send-message', 'PROBLEM_REPORT'], { skipLocationChange: false });

  }

  protected readonly ConfigService = ConfigService;
}
