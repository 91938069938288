<ng-container *ngIf="!maxWidthForMobileView || screenWidth > maxWidthForMobileView; else mobileView">
  <div class="kanban-board" [style.grid-template-columns]="calcGridTemplateColumns()" cdkDropListGroup>

    <div class="vertical-line"></div>

    <ng-container *ngFor="let column of columns; let iColumn = index">

      <div class="kb-column" [style.width]="column.width ?? null">

        <div class="kb-column-title" [ngClass]="column.titleStyleClasses"
          [style.border-bottom]="'4px solid ' + column.color">
          <div class="kb-column-title-content">{{ column.title }}</div>
          <div *ngIf="column.showCounter" class="kb-column-counter">{{ column.count ?? column.items?.length }}</div>
        </div>

        <div class="kb-column-content" cdkDropList [cdkDropListData]="column.items" (cdkDropListDropped)="drop($event)"
          [id]="'column-' + column.id">
          <ng-container *ngFor="let item of column.items">
            <div class="kb-item" (click)="onClickedItem(item)" cdkDrag [cdkDragData]="item"
              [cdkDragDisabled]="!column.draggableItems">
              <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
              <ng-container *ngTemplateOutlet="column.itemTemplate; context: { $implicit: item }"></ng-container>
            </div>
          </ng-container>
        </div>

        <div *ngIf="column.items?.length < column.count" class="kb-column-more-elements small">
          <div *ngIf="!moreElementsLoadable">{{ translateKeyForMoreElements | translate }}</div>
          <button *ngIf="moreElementsLoadable" type="button" class="btn btn-sm btn-outline-dark"
            (click)="onClickedLoadMoreElements(column.id)">Weitere Elemente
            laden</button>
        </div>
      </div>

      <div class="vertical-line"></div>

    </ng-container>
  </div>
</ng-container>

<ng-template #mobileView>
  <div *ngIf="columns" class="kanban-board" cdkDropListGroup>

    <div class="kb-column" [style.width]="columns.get(mobileColumnIndex).width ?? null">

      <div class="kb-column-title" [ngClass]="columns.get(mobileColumnIndex).titleStyleClasses"
        [style.border-bottom]="'4px solid ' + columns.get(mobileColumnIndex).color">
        <div *ngIf="mobileColumnIndex > 0" class="kb-column-navigation-left mb-2 fas fa-chevron-left"
          (click)="previousColumnIndex()"></div>
        <div class="kb-column-title-content">{{
          columns.get(mobileColumnIndex).title }}</div>
        <div *ngIf="mobileColumnIndex < columns.length - 1" class="kb-column-navigation-right mb-2 fas fa-chevron-right"
          (click)="nextColumnIndex()"></div>
        <div *ngIf="columns.get(mobileColumnIndex).showCounter" class="kb-column-counter">{{
          columns.get(mobileColumnIndex).count ?? columns.get(mobileColumnIndex)?.items?.length }}</div>
      </div>

      <div class="kb-column-content" cdkDropList [cdkDropListData]="columns.get(mobileColumnIndex).items"
        (cdkDropListDropped)="drop($event)" [id]="'column-' + columns.get(mobileColumnIndex).id">
        <ng-container *ngFor="let item of columns.get(mobileColumnIndex).items">
          <div class="kb-item" (click)="onClickedItem(item)" cdkDrag [cdkDragData]="item"
            [cdkDragDisabled]="!columns.get(mobileColumnIndex).draggableItems">
            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
            <ng-container
              *ngTemplateOutlet="columns.get(mobileColumnIndex).itemTemplate; context: { $implicit: item }"></ng-container>
          </div>
        </ng-container>
      </div>

      <div *ngIf="columns.get(mobileColumnIndex).items?.length < columns.get(mobileColumnIndex).count"
        class="kb-column-more-elements small">
        <div *ngIf="!moreElementsLoadable">{{ translateKeyForMoreElements | translate }}</div>
        <button *ngIf="moreElementsLoadable" type="button" class="btn btn-sm btn-outline-dark"
          (click)="onClickedLoadMoreElements(columns.get(mobileColumnIndex).id)">Weitere Elemente laden</button>
      </div>

    </div>
  </div>
</ng-template>