import {Component, Input, OnInit} from '@angular/core';
import {ComponentType} from '@cstx/volkswagen-mqs-parsing-service-client';
import {Engine} from '../../services/backend/models/engine';
import {Part} from '../../services/backend/models/part';
import {StackV2} from '../../services/backend/models/stackv2';

@Component({
  selector: 'op-logistical-state-tooltip',
  templateUrl: './logistical-state-tooltip.component.html',
  styleUrls: ['./logistical-state-tooltip.component.scss']
})
export class LogisticalStateTooltipComponent implements OnInit{
  @Input() engine: Engine;
  @Input() part: Part;
  @Input() stack: StackV2;


  public free: boolean;
  protected readonly ComponentType = ComponentType;

  public ngOnInit(): void {
    if (this.engine) {
      this.free = this.checkState(this.engine);
    }

    if (this.part) {
      this.free = this.checkState(this.part);
    }

    if (this.stack) {
      this.free = this.checkState(this.stack);
    }
  }

  public checkState(entity: any): boolean {
    const state = false;

    if (entity instanceof Engine) {
      if (entity.engineTestState === 'NONE' ||
          entity.engineTestState === 'FAILED' ||
          entity.engineReworkState === 'PENDING' ||
          entity.engineBlockedState === 'BLOCKED') {

        return false;
      } else {
        return true;
      }
    }

    if (entity instanceof Part) {
      return this.part.partBlockedState !== 'BLOCKED';
    }

    if (entity instanceof StackV2) {
       const insights = this.stack.getLogisticalStateInsights();

       const firstProblemIndex = insights?.findIndex(i => i.count > 0);
       return firstProblemIndex === -1;
    }

    return state;
  }

}
