export class ScanResult {

  constructor(value: string) {
    this.value = value;
    this.cycle = 0;
  }

  value: string;
  cycle: number;

}
