import {FileMetaData} from './fileMetaData';
import {FileType} from './fileType';

export class FilePreview {
  Metadata: FileMetaData;
  Type: FileType;
  ShowDownloadArea: boolean;
  DirectDownload: boolean;
  File: any;
  DownloadCounter = 3;
  Name: string;
  HasError: boolean;
  Blob: Blob;
  Parsed: boolean;

  public IsImage(): boolean {
    return this.Type === FileType.Image;
  }
  public IsString(): boolean {
    return this.Type === FileType.Text;
  }
  public IsPdf(): boolean {
    return this.Type === FileType.Pdf;
  }

  public IsParsedBinary(): boolean {
    if (this.Type === FileType.Binary && this.Parsed) {
      return true;
    }

    return false;
  }

  constructor() {
    this.Metadata = new FileMetaData();
  }
}
