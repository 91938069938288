import {ScannerResult} from './scannerResult';
import {LogisticalState} from '../../../../engine/list/list.component';
import {DetectedObject} from './detectedObject';

export class DetectedComponent extends DetectedObject {

  public componentId: string;
  public componentType: string;
  public costCenter: string;
  public componentName: string;

  constructor(scannerResult?: ScannerResult) {
    super();

    if (scannerResult) {
      this._scan = scannerResult;

      this.componentId = this._scan.refId;
      this.selfLink = this._scan.refLink;

      if (this._scan.engine) {
        this.componentName = this._scan.engine.engineCode + '-' + this._scan.engine.engineNumber;
        this.free = this._scan.engine.getLogisticalState() === LogisticalState.FREE;
        this.componentType  = 'engine';
        this.costCenter = this._scan.engine.costCenter;
      }

      if (this._scan.part) {
        this.componentName = this._scan.part.dmc;
        this.free = this._scan.part.getLogisticalState()  === LogisticalState.FREE;
        this.componentType  = 'part';
        this.costCenter = this._scan.part.costCenter;

      }
    }
  }

  public set scan(scannerResult: ScannerResult) {
    this._scan = scannerResult;

    if (this._scan.engine) {
      this.componentName = this._scan.engine.engineCode + '-' + this._scan.engine.engineNumber;
      this.free = this._scan.engine.getLogisticalState() === LogisticalState.FREE;
    }

    if (this._scan.part) {
      this.componentName = this._scan.part.dmc;
      this.free = this._scan.part.getLogisticalState()  === LogisticalState.FREE;
    }
  }

  public get scan(): ScannerResult {
    return  this._scan;
  }
}
