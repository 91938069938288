import {Component, Input, OnInit} from '@angular/core';
import {Stack} from '../../../models/tracking/stack';
import {TrackingService} from '../../../services/tracking.service';

@Component({
  selector: 'op-logistics',
  templateUrl: './logistics.component.html',
  styleUrls: ['./logistics.component.scss']
})
export class LogisticsComponent implements OnInit {
  @Input() componentId: string;
  @Input() selfLink: string;
  @Input() contentContainerId: string;

  stack: Stack;
  private loading: boolean;


  constructor(private trackingService: TrackingService) { }

  ngOnInit(): void {
    this.getTrackingEvents();
  }

  getTrackingEvents() {
    this.loading = true;
    this.trackingService.getEventById(this.componentId)
      .then(result => {
        const latestStackIdentifier = result.find(e => e.eventType === 'STACKING')?.stackIdentifier;
        this.getStack(latestStackIdentifier);
      })
      .finally(() => this.loading = false);
  }

  private getStack(stackIdentifier: string) {
    if (!stackIdentifier) {
      return;
    }
    this.loading = true;
    this.trackingService.getStackById(stackIdentifier)
      .then(result => {
        this.stack = result;
      }).finally(() => this.loading = false);
  }

}
