import {Component, Input, OnInit} from '@angular/core';
import {Engine} from '../../../../modules/engine/models/engine';
import {LabelingType} from './labeling/LabelingType';
import {
  DetailSectionWrapperService
} from '../detail-section-wrapper/detail-section-wrapper.service';
import {ComponentProfileActionType} from './component-profile-action-type';
import {ComponentProfileService} from '../component-profile.service';

@Component({
  selector: 'op-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  @Input() componentId: string;
  @Input() selfLink: string;
  @Input() contentContainerId: string;

  @Input() engine: Engine;

  public loading = false;
  public actionsType = undefined;
  public  isActive: boolean;

  protected readonly ComponentProfileService = ComponentProfileService;
  protected readonly ComponentProfileActionType = ComponentProfileActionType;

  labelingTypes = LabelingType;

  constructor(private detailSectionsWrapperService: DetailSectionWrapperService) { }
  async ngOnInit(): Promise<void> {
    this.detailSectionsWrapperService.toggleActiveAction.subscribe(e => {
      if (!this.isActive) {
        this.isActive = true;
      }

      this.actionsType = e.ActionType;
    })
  }
}

export class FavoriteMenuEventArgs {
  Toggled: boolean;
  Favorite: string;
  ActionType?: ComponentProfileActionType | string ;
}
