import {ThemeType, ThemingCategory} from '../services/theming.service';

export class UserConfig {

  constructor() {
    this.dashboard = new DashboardConfig();
    this.statistics = new StatisticsChartConfig();
    this.tracking = new TrackingConfig()
  }

  dashboard?: DashboardConfig;
  statistics?: StatisticsChartConfig;
  tracking?: TrackingConfig;
}

export class GraphCStyleConfig {
  colorTheme?: BarChartColorTheme;
  chartStyle?: BarChartStyleTheme;
}

export class DashboardConfig {
  constructor() {

    this.chartStyle = BarChartStyleTheme.TwoBars;
    this.colorTheme = BarChartColorTheme.Glossy;
  }
  withZeroValues?: boolean;
  zoomEnabled?: boolean;
  zoomedChartId?: string;
  hiddenCharts?: Array<string>;
  colorTheme?: BarChartColorTheme;
  chartStyle?: BarChartStyleTheme;
  productCenters?: { [key: string]: {
      costCenters?: { [key: string]: {
          hidden: boolean
        }
      },
      hidden: boolean }
  };
}

export class TrackingConfig {

  constructor() {
    this.loadingsOffset = 75;
    this.stackListVisible = false;
  }

  loadingsOffset?: number;
  stackListVisible?: boolean;
}

export class StatisticsChartConfig {

  constructor() {
    this[ThemingCategory.OutputLine] = { [ThemeType.Color]: LineChartColorTheme.Lights };
    this[ThemingCategory.OutputBar] = { [ThemeType.Style]: BarChartStyleTheme.TwoBars, [ThemeType.Color]: BarChartColorTheme.Glossy };
    this[ThemingCategory.OEELine] = { [ThemeType.Color]: LineChartColorTheme.Modern };
  }
  OUTPUTLINE?: { COLOR?: LineChartColorTheme };
  OUTPUTBAR?: { STYLE?: BarChartStyleTheme, COLOR?: BarChartColorTheme };
  OEELINE?: { COLOR?: LineChartColorTheme };
}

export enum BarChartStyleTheme {
  TwoBars = 'TWOBARS',
  FillingBar = 'FILLINGBAR',
  Dash = 'DASHED'
}

export enum BarChartColorTheme {
  Glossy = 'GLOSSY',
  Dim ='DIM'
}

export enum LineChartColorTheme {
  Classic = 'CLASSIC',
  Lights = 'LIGHTS',
  Modern = 'MODERN'
}
