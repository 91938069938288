import {Component, Input} from '@angular/core';

@Component({
  selector: 'op-permissions-gate',
  templateUrl: './permissions-gate.component.html',
  styleUrls: ['./permissions-gate.component.scss']
})
export class PermissionsGateComponent {
  @Input() permission: string;
}
