import {FilterBase} from '../../../shared/models/filterBase';
import {Slice} from '../../../shared/models/slice';

export class ProductCenterFilter extends FilterBase {
  public name = '';
  public shortName = '';
  public slice: Slice;

  constructor() {
    super();

    this.pagingSize = 0;
    this.totalPages = 0;
    this.currentPageIndex = 0;
    this.sort = [];
  }
  public getAllParams() {
  }
}
