import {Component, EventEmitter, Input, isDevMode, OnInit, Output} from '@angular/core';
import {UseCaseFile} from '../useCaseFile';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {FileExplorerService} from '../file-explorer.service';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';


@Component({
  selector: 'op-usecase-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.scss']
})
export class TreeviewComponent implements OnInit {


  constructor(private fileExplorerService: FileExplorerService,
              private translate: TranslateService) { }
  private updateTimeout: NodeJS.Timeout;

  private pagingSize = 250;
  private maxPages = 40;

  private lastPage = false;
  private moreDataAvailable: boolean;

  @Input() usecase: string;
  @Input() usecasePrefix: string;
  @Input() updateTreeviewRequestEvent = new Subject<any>();
  @Input() disabled: boolean;
  @Input() uploadDisabled: boolean;

  @Output() fileSelectedEvent = new EventEmitter<UseCaseFile>();
  @Output() showUploadEvent = new EventEmitter<boolean>();

  public list = new Array<UseCaseFile>();
  public count = 0;
  public countUpdating;
  public loading: boolean;
  public selectedItemName: string;

  public treeViewConfig: TreeviewConfig = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 0
  });

  public treeViewItems = new Array<TreeviewItem>();
  private page = 1;


  private treeViewUpdating: boolean;


  ngOnInit(): void {
    this.updateTreeviewRequestEvent.subscribe(_ => {
      console.log('treeview received update event');
      clearTimeout(this.updateTimeout);
      this.update();
    });
  }

  private loadFiles() {
    if (this.lastPage) {
      return;
    }

    const targetLength = this.maxPages * this.pagingSize - this.pagingSize;
    if (this.list.length >= targetLength) {
      return;
    }

    this.updateTimeout = setTimeout(() => {
      this.update(this.page);
    }, 500);
  }

  /**
   *
   * @param file The file selected be clicking the item in tree.
   *
   * This function sets the selected file and then emits an event for the parent
   * component containing the new file selected.
   */
  public onSelectItemClicked(fileName: string, file: UseCaseFile) {
    this.selectedItemName = fileName;

    // TODO: Check if we can set this in the request when gettin files?!
    file.Name = fileName;

    this.fileSelectedEvent.emit(file);
  }

  public itemIsSelected(fileName: string): boolean {
    return this.selectedItemName === fileName;
  }

  public itemIsFolder(item: TreeviewItem): boolean {
    if (item.text === this.translate.instant('files.tree.binaries')) {
      return true;
    }

    if (item.text === this.translate.instant('files.tree.images')) {
      return true;
    }

    if (item.text === this.translate.instant('files.tree.text')) {
      return true;
    }

    if (item.text === this.translate.instant('files.tree.other')) {
      return true;
    }

    return !!item.children; // return true if item has children and false otherwise
  }

  public itemIsCollapsed(item: TreeviewItem): boolean {
    return item.collapsed;
  }

  public onShowUploadClicked() {
    if (this.disabled) {
      return;
    }

    this.showUploadEvent.emit(true);
  }

  private update(page: number = 1) {
    if (this.treeViewUpdating) {
      return;
    } else {
      this.treeViewUpdating = true;
    }

    if (isDevMode()) {
      console.log(new Date().toLocaleString() +  ': Update TreeView called. ')
    }

    if (page === 1) {
      this.page = 1;
      this.list = new Array<UseCaseFile>();
      this.treeViewItems = new Array<any>();
      this.count = 0;
      this.loading = true;
      this.countUpdating = true;
    }
    this.lastPage = false;
    this.moreDataAvailable = true;

    this.fileExplorerService.GetFilesForUseCase(this.usecase, page, this.pagingSize, false, this.usecasePrefix)
      .then(result => {
        if (result.length < this.pagingSize ) {
          clearTimeout(this.updateTimeout);
          this.lastPage = true;
          this.moreDataAvailable = false;
        }
        // this can lead to duplications with race-conditions
         this.list = this.list.concat(result)
        this.buildTreeViewUseCase(result);
        this.page = page + 1;
      }).catch(err => {
        if (isDevMode()) {
          console.log(err);
        }
    })
      .finally(() => {
        this.count = this.list.length;
        this.countUpdating = false;
        this.loading = false;
        this.treeViewUpdating = false;
        this.loadFiles();
      });
  }

  private buildTreeViewUseCase(newItems: Array<any>) {

    newItems.forEach(file => {
      // Get Folders
      const pathGroups = file.s3Key.split('/');


      // Get File
      const fileName = pathGroups.pop();
      let parentGroup =  this.treeViewItems;
      pathGroups.forEach((pathGroup, index) => {
        if ((index === 0 && pathGroup === 'data') || (this.usecasePrefix && pathGroup === this.usecasePrefix)) {
          return;
        }

        if (parentGroup.findIndex(_ => _.text === pathGroup) === -1) {
          const newItem = new TreeviewItem({text: pathGroup, value: pathGroup });

          // When the overall item size is > we want the created folders to be collapsed
          if (this.list.length > 50) {
            newItem.collapsed = true;
          }


          // This dummy is needed - otherwise the code will silently fail in ngx-treeview
          newItem.children = Array<TreeviewItem>(new TreeviewItem ({text: '', value: ''}));

          parentGroup.push(newItem);
          parentGroup = newItem.children;
          return;

        } else {
          parentGroup = parentGroup.find(_ => _.text === pathGroup).children;
        }
      });


      parentGroup.push(new TreeviewItem({text: fileName, value: file}));
    });

    // Sort makes folders go up (but only on first layer)
    // TODO: Go down the tree and although order folders to top in subtree items
    // tslint:disable-next-line:max-line-length
    // TODO: Disabled for now as when collapsed it leads to folders switching orders when loading new pages into tree - Needs to be refactrored

    /*this.treeViewItems.sort(value => {
      return value.children ? -1 : 1;
    });*/
  }
}
