<div *opRequiredClaimByName="'news-show'" [class.cpp-page-defaults]="!kioskMode" class="container-fluid"
     style="min-height: 100%">

  <div class="pt-2 pb-2">
    <op-breadcrumb [path]="'Home/Infos'"></op-breadcrumb>
    <!--<div class="float-right" style="cursor: pointer"><i class="fas fa-question" (click)="scrollTo('loading-documentation')"></i></div>-->
  </div>
  <table class="table table-hover">
    <tr class="row pl-3 pr-3">
      <td class="col pb-0 pl-0 nav">
        <ul class="nav">
          <li class="nav-item pr-0">
            <a [routerLink]="['/home/info/list']" [queryParams]="{ activeTab: 'all' }">
              <button type="button" class="btn btn-sm button" [class.button-selected]="isActive('all')" (click)="setActive('all')">Alle <op-count-badge [count]="itemCount"></op-count-badge></button>
            </a>
          </li>
        </ul>
      </td>
      <td class="col text-right pb-0 pr-0">
        <a [routerLink]="['/home/info/list/new']">
          <button type="button" class="btn btn-sm btn-primary" style="margin-top: -5px">{{ 'info.create' | translate }}</button>
        </a>
      </td>
    </tr>
    <tr class="row pl-3 pr-3">
      <td class="col align-middle cpp-input-search">
        <op-guided-filter-input [source]="searchAliasMappings"
                                (criteriaUpdate)="setFilterValues($event)"
                                (triggerSearch)="onSearch($event)"
                                (triggerAbortSearch)="onSearchAbort()"
                                [resetObs]="clearFilterSub.asObservable()"
                                [searching]="searching">
        </op-guided-filter-input>

        <div class="invalid-feedback">
          {{ 'engine.list.invalid-feedback' | translate }}
        </div>
      </td>
    </tr>
    <tr class="row pl-3 pr-3">
      <td class="cpp-column-fixed-width-120 small">
      </td>
      <td class="cpp-column-fixed-width-120 small">

      </td>
      <td class="cpp-column-fixed-width-100 text-center pl-0 pr-3 small d-none d-sm-none d-md-block">
        {{ 'info.showGlobal' | translate }}
      </td>
      <td class="col pl-0 pr-3 small text-overflow-ellipses d-none d-sm-none d-md-block" (click)="onSort('title')">
        {{ 'info.title' | translate }}
        <op-sort-order-indicator class="float-right" [filter]="filter" [target]="'title'"></op-sort-order-indicator>
      </td>
      <td class="cpp-column-fixed-width-100 text-center pl-0 pr-3 small text-right d-none d-sm-none d-md-block" (click)="onSort('type')">
        {{ 'info.type' | translate }}
        <op-sort-order-indicator class="float-right" [filter]="filter" [target]="'type'"></op-sort-order-indicator>
      </td>
      <td class="cpp-column-fixed-width-100 text-center pl-0 pr-3 small d-none d-sm-none d-md-block">
        {{ 'procedure.actions' | translate }}
      </td>
      <td class="cpp-column-fixed-width-175 small" (click)="onSort('modifiedAt')">
        {{ 'procedure.modifiedAt' | translate }}
        <op-sort-order-indicator class="float-right" [filter]="filter" [target]="'modifiedAt'"></op-sort-order-indicator>
      </td>
      <td class="cpp-column-fixed-actions">

      </td>
    </tr>
    <tbody infinite-scroll
           [infiniteScrollDistance]="2"
           [infiniteScrollUpDistance]="2"
           [infiniteScrollThrottle]="50"
           (scrolled)="onDown()"
           (scrolledUp)="onUp()"
           opEnsureListSufficientlyFilled
           (loadMoreItems)="onDown()"
           [existingItems]="infos.length">
    <tr *ngIf="infos.length === 0 && !listUpdating" class="row pl-3 pt-3 pb-3 pr-3" >
      <div class="col pl-0 text-center">
        <small> {{ 'global.nothing-to-show' | translate }}</small>
      </div>
    </tr>
    <tr class="d-flex" *ngFor="let element of infos; let i = index">
      <td class="cpp-column-fixed-width-120 small">
        <op-component-state-badge  [name]="element.isActive ? 'ACTIVE' : 'INACTIVE'"></op-component-state-badge>
      </td>
      <td class="cpp-column-fixed-width-100 text-center pl-0 pr-3 small text-right d-none d-sm-none d-md-block">
        <i  class="fas" style="color:darkgoldenrod;font-size: 1.25em; cursor: pointer" [class.fa-eye]="element.isPublished" [class.fa-eye-slash]="!element.isPublished" [tooltip]="publishedToolTip"></i>
      </td>
      <td class="cpp-column-fixed-width-100 text-center pl-0 pr-3 small text-right d-none d-sm-none d-md-block">
        <i *ngIf="element.showGlobal" class="fas fa-globe" style="color:darkgreen;font-size: 1.25em; cursor: pointer" [tooltip]="globalToolTip"></i>
        <i *ngIf="element.costCenters.length >= 1 && !element.showGlobal" class="fas fa-filter" style="color:darkblue; font-size: 1.25em; cursor: pointer" [tooltip]="filteredToolTip"></i>
      </td>
      <td class="col pl-0 pr-3 text-monospace small d-none d-sm-none d-md-block">
        {{ element.title }}
      </td>
      <td class="cpp-column-fixed-width-100 text-center small">
        <i *ngIf="element.informationType === 'NEWS'" class="fas fa-newspaper" style="color: darkgray; font-size: 1.25em;"></i>
        <i *ngIf="element.informationType === 'ALERT'" class="fas fa-bell" style="color: darkred; font-size: 1.25em;"></i>
        <i *ngIf="element.informationType === 'STOPNOTIFICATION'" class="fas fa-ban" style="color: lightcoral; font-size: 1.25em;"></i>
        <i *ngIf="element.informationType === 'EMPLOYEEINFO'" class="fas fa-user" style="color: lightskyblue; font-size: 1.25em;"></i>
        <i *ngIf="element.informationType === 'SUPPORTREQUEST'" class="fas fa-headset" style="color: darkorange; font-size: 1.25em;"></i>
        <i *ngIf="element.informationType === 'WORKPLAN'" class="fas fa-toolbox" style="color: darkgreen; font-size: 1.25em;"></i>
      </td>
      <td class="cpp-column-fixed-width-100 text-center">
        <i class="cpp-icon-delete" [tooltip]="toolTipDelete"  (click)="delete(element.id)"></i>
      </td>
      <td class="cpp-column-fixed-width-175 small">
        <op-datetime-show [dateTime]="element.modifiedAt"></op-datetime-show>
      </td>
      <td class="cpp-column-fixed-actions">
        <a [routerLink]="'./' + element.id"><i class="cpp-icon-open-details" [tooltip]="toolTipOpen" ></i></a>
      </td>
    </tr>
    <tr *ngIf="listUpdating" class="row">
      <td class="col-12 text-center"><div class="spinner-border spinner-border-sm"></div></td>
    </tr>
    </tbody>
  </table>
</div>

<ng-template #toolTipDelete>
  Deletes the selected info <div class="font-weight-bold d-inline">Immediately</div>.<br>
  This <div class="font-weight-bold d-inline">cannot be undone</div>!
</ng-template>

<ng-template #toolTipOpen>
  Opens the info details.
</ng-template>

<ng-template #globalToolTip>
  Diese Info wird global angezeigt!
</ng-template>

<ng-template #publishedToolTip>
  Ist hier ein geöffnetes Auge zu sehen, wurde die Info bereits veröffentlich.
</ng-template>


<ng-template #filteredToolTip>
  Diese Info wird für bestimmte Kostenstellen bzw. Arbeitsfolgen angzeigt.<br>
</ng-template>
