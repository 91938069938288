import {Component, Input, OnInit} from '@angular/core';
import {TelegramReceiverService} from '../../services/backend/telegram-receiver.service';
import {TelegramResponse, TelegramType} from '@cstx/volkswagen-mqs-telegram-receiver-service-client';
import {EngineService} from '../../services/backend/engine.service';
import {EngineBlockedState, EngineResponse, EngineReworkState, EngineTestState} from '@cstx/volkswagen-mqs-engine-service-client';
import {TrackingService} from '../../services/backend/tracking.service';
import {StackResponse} from '@cstx/volkswagen-mqs-tracking-service-client';
import {ActivatedRoute} from '@angular/router';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import moment from "moment";

@Component({
  selector: 'op-before-stacking-engine-order-tracing',
  templateUrl: './before-stacking-engine-order-tracing.component.html',
  styleUrls: ['./before-stacking-engine-order-tracing.component.scss']
})
export class BeforeStackingEngineOrderTracingComponent implements OnInit{


  constructor(private telegramReceiverService: TelegramReceiverService,
              private engineService: EngineService,
              private trackingService: TrackingService,
              private route: ActivatedRoute) {

  }


  @Input() costCenter = '71381';

  public telegrams = new Array<TelegramResponse>();
  public engineTracingObjects = new Array<EngineTracingObject>();
  public updatePending: boolean;
  public lastWorkSequence: string;

  protected readonly EngineBlockedState = EngineBlockedState;
  protected readonly EngineTracingState = EngineTracingState;
  protected readonly LocationType = LocationType;

  primaryColor = true;




  public async ngOnInit(): Promise<void> {
    this.route.queryParamMap.subscribe(params => {
      this.costCenter = params.get('costCenter') ? params.get('costCenter') : this.costCenter;
      this.lastWorkSequence = params.get('lastWorkSequence');
    });

      await this.fillUpLine();

      setInterval(async () => {
          await this.fillUpLine();
      }, 20000);
  }

  checkKeyCodeChanged(index: number): boolean {
    if (index === 0) {
      return false;
    }

    const otherKeyCodeOnLeft = this.engineTracingObjects[index-1]?.keyCode === '' ? false : this.engineTracingObjects[index]?.keyCode !== this.engineTracingObjects[index-1]?.keyCode;

    if (otherKeyCodeOnLeft) {
      return false;
    }
  }

  getKeyCodeColoring(i: number): boolean {
    if (i === 0) {
      return this.primaryColor;
    }

    const otherKeyCodeOnLeft = this.engineTracingObjects[i-1]?.keyCode === '' ? false : this.engineTracingObjects[i]?.keyCode !== this.engineTracingObjects[i-1]?.keyCode;

    if (otherKeyCodeOnLeft) {
      this.primaryColor = !this.primaryColor;
    }

    return this.primaryColor;
  }

  private async fillUpLine() {
    LoggingService.logDebug(LoggingSource.CORE, 'Trying to build up line content started.');

    if (this.updatePending) {
      LoggingService.logDebug(LoggingSource.CORE, 'Update still in progress. Aborting update request.');
      return;
    }
    this.updatePending = true;

    const telegrams = await this.telegramReceiverService.getTelegramsForCostCenter(this.costCenter);
    if (telegrams.length <= 0) {
      LoggingService.logDebug(LoggingSource.CORE, 'No telegrams found. Aborting.');
      return;
    }

    const workSequenceSet = this.getUniqueWorkSequencesSet(telegrams);
    if (workSequenceSet.length <= 0) {
      LoggingService.logDebug(LoggingSource.CORE, 'Could not determine a distinct work sequences list. Aborting.');
      return;
    }


    LoggingService.logDebug(LoggingSource.CORE, 'Preserve existing tracing objects after last workSequence if existing.');
    let tracingObjectsToKeep = new Array<EngineTracingObject>();
    if (this.engineTracingObjects.length >= 0) {
      const lastWorkSequence = workSequenceSet[workSequenceSet.length -1 ];
      const tracingObjectIndexToPreserve = this.engineTracingObjects.findIndex(t => t.lastLocation === lastWorkSequence);
      tracingObjectsToKeep = this.engineTracingObjects.slice(tracingObjectIndexToPreserve);

      tracingObjectsToKeep.forEach(t => {
        t.preservedItem = true;
      });
    }

    LoggingService.logDebug(LoggingSource.CORE, 'Iterate over workSequence list.');
    const tracingObjectsSum = new Array<EngineTracingObject>();
    if (workSequenceSet.length > 0) {
      for (const workSequence of workSequenceSet) {
        LoggingService.logDebug(LoggingSource.CORE, `Work on telegrams for work Sequence ${workSequence}.`);

        const telegramsPerWorkSequence = telegrams.filter(t =>
            t.costCenter === this.costCenter && t.workSequence === workSequence && t.telegramType === TelegramType.Workload);


        LoggingService.logDebug(LoggingSource.CORE, `Try find current telegram for ${workSequence}.`);
        const currentTelegramForWorkSequence = this.tryGetCurrentTelegramForWorkSequence(telegramsPerWorkSequence);
        this.removeExistingPreviousWorkSequences(currentTelegramForWorkSequence, tracingObjectsSum);

        if (currentTelegramForWorkSequence) {
          const tracingObject = new EngineTracingObject();
          tracingObject.mapFromTelegramResponse(currentTelegramForWorkSequence);
          tracingObject.currentLocation = currentTelegramForWorkSequence.workSequence;
          tracingObject.locationType = LocationType.WORKSEQUENCE;

          tracingObjectsSum.push(tracingObject);
        }

        LoggingService.logDebug(LoggingSource.CORE, `Try find older telegrams for ${workSequence}.`);
        const olderTelegramsForWorkSequence = this.tryGetOlderTelegramForWorkSequence(telegramsPerWorkSequence, currentTelegramForWorkSequence);
        olderTelegramsForWorkSequence.forEach(telegram => {
          this.removeExistingPreviousWorkSequences(telegram, tracingObjectsSum);

          const tracingObject = new EngineTracingObject();
          tracingObject.mapFromTelegramResponse(telegram);
          tracingObject.lastLocation = telegram.workSequence;
          tracingObject.locationType = LocationType.BUFFER;

          if (tracingObject.state !== EngineTracingState.ERROR) {
            tracingObjectsSum.push(tracingObject);
          }
        })
      }

      if (this.lastWorkSequence && this.lastWorkSequence) {
        let existingEndOfLineIndex =  this.engineTracingObjects.findIndex(l => l.currentLocation === this.lastWorkSequence && l.isEndOfline);
        tracingObjectsSum.splice(existingEndOfLineIndex, 1);

        existingEndOfLineIndex =  tracingObjectsSum.findIndex(l => l.currentLocation === this.lastWorkSequence && l.isEndOfline);
        tracingObjectsSum.splice(existingEndOfLineIndex, 1);

        const lastWorkSequenceTracingObjectIndex = tracingObjectsSum.findIndex(l => l.currentLocation === this.lastWorkSequence);
        const lastWorkSequenceTracingObjectNext = tracingObjectsSum[lastWorkSequenceTracingObjectIndex + 1];

        lastWorkSequenceTracingObjectNext.isEndOfline = true;
      }
    }


    LoggingService.logDebug(LoggingSource.CORE, `Re-integrate preserved existing tracing objects.`);
    tracingObjectsToKeep.forEach(t => {
      const existingIndex = tracingObjectsSum.findIndex(o => o.name === t.name && o.lastLocation === t.lastLocation);

      if (existingIndex !== -1) {
        tracingObjectsSum.splice(existingIndex, 1);
      }
    });

    tracingObjectsSum.push(...tracingObjectsToKeep)
    LoggingService.logDebug(LoggingSource.CORE, `Re-added ${tracingObjectsToKeep.length} preserved to existing tracing objects.`);


    const componentNames = new Array<string>();
    for (const t of tracingObjectsSum) {

      if (t.state !== EngineTracingState.ERROR) {
        // t.engine = await this.engineService.getEngineByComponentName(t.name);
        componentNames.push(t.name);
      }

      const currentIndex = tracingObjectsSum.findIndex(o => t.name === o.name && t.time === o.time && t.keyCode === o.keyCode);
      t.keyCodeChanged = this.checkKeyCodeChanged(currentIndex);

      const currentDate = moment.utc().local();
      const tracingDate = moment(new Date(t.startTime.substring(0,19)))


      const durationBetweenDates = moment.duration(currentDate.diff(tracingDate));
      const durationBetweenDatesInMinutes = durationBetweenDates.asMinutes();



      if (durationBetweenDatesInMinutes > 35 && !t.preservedItem) {
        t.timeInvalid = true;

        if (t.locationType === LocationType.WORKSEQUENCE) {
          t.name = '';
          t.keyCode = '';
          t.markedForDeletion = true
        }
      }
    }
    LoggingService.logDebug(LoggingSource.CORE, `Found ${componentNames.length} to get details for.`);


    const engines = await this.engineService.getEngineByComponentNameList(componentNames);
    LoggingService.logDebug(LoggingSource.CORE, `Found ${engines.length} details.`);

    engines.forEach(e => {
      const t = tracingObjectsSum.find(o => o.name === e.componentName);
      if (t) {
        t.engine = e;
      }
    });






    this.engineTracingObjects = tracingObjectsSum;
    const engineIds = engines.map(e => e.id);
    const stacks = await this.trackingService.getStacksByComponentIDList(engineIds);

    stacks.forEach(s => {
      s.components.forEach(c => {
        const engineSearchResult = tracingObjectsSum.find(e => e.engine?.id === c.componentId);

        if (engineSearchResult) {

          if (engineSearchResult.preservedItem || engineSearchResult.time < s.stackedAt)
            engineSearchResult.stack = s;

          if (engineSearchResult.lastLocation === this.lastWorkSequence) {
            engineSearchResult.locationType = LocationType.REMOVAL;
          }
        }
      });
    });

    // await this.checkState(tracingObjectsSum);

    const index1 = this.engineTracingObjects.findIndex(t => t.isEndOfline);
    console.log(index1);
    if (index1 > 0) {
      const start = index1 + 1;
      const index2 = this.engineTracingObjects.findIndex(t => t.stack && t.lastLocation === this.lastWorkSequence);
      const stop = index2 - 1;


      console.log(index2);
      const onLifter = this.engineTracingObjects.slice(start, stop);

     console.log(onLifter);

      onLifter.forEach(t => {
        t.locationType = LocationType.LIFTER;
        t.isEndOfline = false;
      })
    }

    this.updatePending = false;
  }

  private getUniqueWorkSequencesSet(telegrams: TelegramResponse[]): string[] {
    let workSequences = new Array<string>();

    try {
      const array = telegrams.map(r => r.workSequence);
      workSequences = [...new Set(array.map((item) => item))];
    }
    catch (error) {
      console.log(error);
    }

    return workSequences;
  }

  private tryGetCurrentTelegramForWorkSequence(telegramsPerWorkSequence: TelegramResponse[]): TelegramResponse {
    const result = telegramsPerWorkSequence.sort((a,b) => b.modifiedAt.localeCompare(a.modifiedAt));
    if (result.length >= 0) {
      return result[0];
    } else {
      return null;
    }
  }

  private tryGetOlderTelegramForWorkSequence(telegramsPerWorkSequence: TelegramResponse[], currentTelegramForWorkSequence: TelegramResponse): TelegramResponse[] {
    const olderTelegrams = telegramsPerWorkSequence.filter(t => t.id !== currentTelegramForWorkSequence.id && !t.stopTime.startsWith('0001'));
    // const result = [...new Set(olderTelegrams.map((item) => item))];

    const result = olderTelegrams;

    if (result.length >= 0) {
      return result;
    } else {
      return null;
    }
  }

  private removeExistingPreviousWorkSequences(currentTelegramForWorkSequence: TelegramResponse, tracingObjects: EngineTracingObject[]) {
    while (tracingObjects.findIndex(t => t.name === currentTelegramForWorkSequence.componentName && !t.markedForDeletion) !== -1) {
      const indexToFix = tracingObjects.findIndex(t => t.name === currentTelegramForWorkSequence.componentName);

      if (tracingObjects[indexToFix].locationType === LocationType.BUFFER) {
        tracingObjects.splice(indexToFix,1);
      } else {
          //tracingObjects.splice(indexToFix,1);
        tracingObjects[indexToFix].name = '';
        tracingObjects[indexToFix].keyCode = '';
        tracingObjects[indexToFix].markedForDeletion = true

      }
    }
  }

  getBadgeClass(engine: EngineResponse) {
    if (!engine) {
      return 'cpp-badge-unknown';
    }

    if (engine.engineState === 'MANUFACTURED') {
      return 'cpp-badge-manufactured';
    }

    if (engine.engineState === 'IN_PRODUCTION') {
      return 'cpp-badge-in-production';
    }

    if (engine.engineState === 'READY_TO_SHIP') {
      return 'cpp-badge-ready-to-ship';
    }

    return  'cpp-badge-unknown';
  }

  public async refresh() {
   await this.fillUpLine();
  }

  protected readonly LoggingService = LoggingService;
}

export class EngineTracingObject {

  get engine(): EngineResponse {
    return this._engine;
  }

  set engine(value: EngineResponse) {
    this._engine = value;

    this.checkForManufacturingProblem();
  }
  public engineCode: string;
  public engineNumber: string;
  public keyCode: string;
  public name: string;
  public time: string;
  public startTime: string;

  private _engine: EngineResponse = null;
  public stack: StackResponse = null

  public state: EngineTracingState = EngineTracingState.UNKNOWN;



  public lastLocation = '';
  public currentLocation = '';
  public isEndOfline: boolean;

  public locationType: LocationType = LocationType.UNKNOWN;

  public errorCounter: number;
  public markedForDeletion: boolean;

  public orderNumber: string;
  public timeInvalid: boolean;
  public preservedItem: boolean;


  public hasManufacturingProblem: boolean;
  public keyCodeChanged: boolean;

  constructor() {
  }

  public mapFromTelegramResponse(response: TelegramResponse) {
    this.engineCode = response.componentName.substring(0,3) ? response.componentName.substring(0,3) : undefined;
    this.engineNumber = response.componentName.substring(4,10) ? response.componentName.substring(4,10) : undefined;
    this.name = response.componentName && response.componentName.replace('\u0000', '').length === 10 ? response.componentName : undefined;
    this.keyCode = response.keyCode;
    this.time = !response.stopTime.startsWith('0001') ? response.stopTime : response.startTime;
    this.orderNumber = response.orderNumber?.toString();

    this.startTime = response.startTime;
    if (this.name === undefined) {
      this.state = EngineTracingState.ERROR;
    }
  }

  private checkForManufacturingProblem() {
    if (this._engine.engineBlockedState !== 'NONE') {
      this.hasManufacturingProblem = true;
      return;
    }

    if (this._engine.engineReworkState === EngineReworkState.Pending) {
      this.hasManufacturingProblem = true;
      return;
    }

    if (this._engine.engineTestState === EngineTestState.Failed) {
      this.hasManufacturingProblem = true;
      return;
    }
  }
}

export enum EngineTracingState {
  UNKNOWN = 'UNKNOWN',
  ERROR = 'ERRROR',
  EMPTY = 'EMPTY',
  MISSING = 'MISSING',
  NEXT = 'NEXT',
  ONLINE = 'ONLINE',
  SCANNED = 'SCANNED',
  STACKED = 'STACKED'
}

export enum LocationType {
  UNKNOWN = 'UNKNOWN',
  WORKSEQUENCE = 'WORKSEQUENCE',
  BUFFER = 'BUFFER',
  LIFTER = 'LIFTER',
  REMOVAL = 'REMOVAL'
}
