import {Component, Input, OnInit} from '@angular/core';
import {DlqMessage} from '../../../../modules/admin/dlq/dlqMessage';
import {DlqService} from '../../../../modules/admin/dlq/dlq.service';

@Component({
  selector: 'op-dlq-messages',
  templateUrl: './dlq-messages.component.html',
  styleUrls: ['./dlq-messages.component.scss']
})
export class DlqMessagesComponent implements OnInit {
  @Input() componentId: string;
  @Input() selfLink: string;
  @Input() contentContainerId: string;

  messages: Array<DlqMessage> = new Array<DlqMessage>();
  constructor(private dlqService: DlqService) { }

  ngOnInit(): void {
    this.dlqService.getDlqMessageForComponent(this.componentId)
      .then(val => {
        this.messages = val;
      });
  }
}
