import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import {Cell, ExcelExport, ExcelExportFilter, ExcelExportRaw, Row} from './excelExport';
import {ToastrService} from 'ngx-toastr';
import {FilterBase} from '../../models/filterBase';
import {ReportService} from './report.service';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../core/utils/utils';
import {
  GenerateExportRequestParams,
  ReportGeneratorService
} from "@cstx/volkswagen-mqs-report-generator-service-client";

@Component({
  selector: 'op-excel-export',
  templateUrl: './excel-export.component.html',
  styleUrls: ['./excel-export.component.scss']
})
export class ExcelExportComponent implements OnInit {
  @Input() exportDataRaw = new Subject<ExcelExportRaw>();
  @Input() exportData = new Subject<ExcelExport>();
  @Input() exportFilter = new Subject<ExcelExportFilter>();
  @Input() filterBasedReportTrigger = new Subject<FilterBase>();
  @Input() disabled: boolean;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onExportRequested = new EventEmitter();
  @Output() excelExportRunning = new EventEmitter()

  constructor(private reportGeneratorService: ReportGeneratorService,
              private reportService: ReportService,
              private toastrService: ToastrService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.initToastrServiceInstance();

    this.exportDataRaw.subscribe(next => {
      const mapped = this.mapToExcelExport(next);

      if (mapped !== undefined) {
        this.sendToExportService(mapped);
      }
    });

    this.exportData.subscribe(next => {
      this.sendToExportService(next);
    });

    this.exportFilter.subscribe(next => {
      this.sendToFilterExportService(next);
    });

    this.filterBasedReportTrigger.subscribe(next => {
      this.handleFilterBasedReportRequest(next);
    });
  }

  requestExcelExport() {
    if (!this.disabled) {
      this.onExportRequested.emit();
    }
  }

  sendToExportService(excelExport: ExcelExport) {
    const requestParam: GenerateExportRequestParams = {
      exportCreateRequest: {
        title: excelExport.title,
        headers: excelExport.headers,
        rows: excelExport.rows
      }
    };

    this.requestHandling(
      firstValueFrom(this.reportGeneratorService.generateExport(requestParam).pipe( map(val => {return;}))), excelExport.title);
  }

  private mapToExcelExport(exportRaw: ExcelExportRaw): ExcelExport {
    if (exportRaw.data.length > 15000) {
      this.toastrService.warning('Lokaler Excel Export mit '+ exportRaw.data.length + ' Zeilen gestartet. Bitte bleiben Sie auf der aktuellen Seite.');
    }


    if (exportRaw.data.length === 0) {
      this.toastrService.error('Cannot export empty list.');
      return undefined;
    }

    const excelExport = new ExcelExport();

    excelExport.headers = this.getExportHeaders(exportRaw);
    excelExport.title   = exportRaw.title;
    excelExport.ignoredPropertiesList = exportRaw.ignoredPropertiesList;

    exportRaw.data.forEach(r => {
      const excelRow = new Row();

      excelExport.headers.forEach(h => {
        if (!excelExport.ignoredPropertiesList.includes(h)) {
          let value = 'no data';
          if (!isNullOrUndefined(r[h])) {
            value = r[h].toString();
          }

          const excelCell = new Cell(value);
          excelRow.cells.push(excelCell);
        }
      });

      excelExport.rows.push(excelRow);
    });

    return excelExport;
  }

  private initToastrServiceInstance() {
    const currentToastRConfig = this.toastrService.toastrConfig;
    currentToastRConfig.autoDismiss = true;
    currentToastRConfig.progressBar = true;
    currentToastRConfig.enableHtml = true;
    currentToastRConfig.closeButton = true;

    this.toastrService.toastrConfig = currentToastRConfig;
  }

  private handleFilterBasedReportRequest(filter: FilterBase) {
    this.requestHandling(this.reportService.requestReport(filter));
  }

  private sendToFilterExportService(exportFilter: ExcelExportFilter) {
    this.requestHandling(this.reportService.requestFilterReport(exportFilter.filterId, exportFilter.title, exportFilter.description));
  }

  private requestHandling(promise: Promise<void>, title?: string) {
    this.excelExportRunning.emit(true);

    promise.then(() => {
      if (title) {
        this.toastrService.success('<small>' + this.translate.instant( 'excel-export.success.messageWithTitle', {title} ) + '</small>',
          this.translate.instant( 'excel-export.success.title'));
      } else {
        this.toastrService.success('<small>' + this.translate.instant( 'excel-export.success.message') + '</small>',
          this.translate.instant( 'excel-export.success.title'));
      }
    }).catch(reason => {
      this.toastrService.error('<small>' + this.translate.instant( 'excel-export.fail.message' ) + '</small>',
        this.translate.instant( 'excel-export.fail.title'));
    }).finally(() => {
      this.excelExportRunning.emit(false);
    });
  }

  private getExportHeaders(exportRaw: ExcelExportRaw) {
    const headersUnfiltered = Object.getOwnPropertyNames(exportRaw.data[0]);
    const headersFiltered = headersUnfiltered.filter(h => !exportRaw.ignoredPropertiesList.includes(h));

    return headersFiltered;
  }
}
