import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {languages} from '../../config/languages';
import {LocalStorageManagerService} from "../../shared/services/local-storage-manager.service";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  languages = languages;

  constructor(private translate: TranslateService) {

  }

  initLanguage() {
    const browserLang = this.translate.getBrowserLang();
    let lang = LocalStorageManagerService.getItem('lang');
    lang = lang ? lang : browserLang;
    if (!languages.find(value => value.value.includes(lang))) {
      lang = languages[0].value;
    }
    this.translate.use(lang);
    this.modifyLanguageList(lang);
  }

  setLanguage(language: any) {
    this.translate.use(language);
    LocalStorageManagerService.setItem('lang', language);
  }


  private modifyLanguageList(lang: string) {
    const item = languages.find(value => value.value === lang);
    this.languages = languages.filter(value => value.value !== lang);
    this.languages.unshift(item);
  }
}
