import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {DetailSectionWrapperService} from './detail-section-wrapper.service';

@Component({
  selector: 'op-detail-section-wrapper',
  templateUrl: './detail-section-wrapper.component.html',
  styleUrls: ['./detail-section-wrapper.component.scss']
})
export class DetailSectionWrapperComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() description: string;
  @Input() contentTemplate: TemplateRef<any>;
  @Input() hasContent: boolean;
  @Input() loading: boolean;
  @Input() selfLink: string;
  @Input() collapsable = true;
  @Input() contentContainerId = 'noId';
  @Input() betaFeature: boolean;
  @Input() alphaFeature: boolean;
  @Input() initExpanded = false;

  @Input() cancelable: boolean;
  @Output() onCancelAction = new EventEmitter();

  expanded: boolean;
  expandButtonText = 'global.expand';

  private beforePrintExpanded: boolean;

  constructor(private detailSectionWrapperService: DetailSectionWrapperService) { }

  @HostListener('window:beforeprint')
  onBeforePrint() {
    this.beforePrintExpanded = this.expanded;
    this.expanded = true;
  }
  @HostListener('window:afterprint')
  onAfterPrint() {
    this.expanded = this.beforePrintExpanded;
  }

  ngOnInit(): void {
    if (!this.collapsable) {
      this.expanded = true;
    }

    this.detailSectionWrapperService.toggleExpandCollapse.subscribe(e => {
        if (e === this.contentContainerId) {
          if (!this.expanded) {
            this.expand();
          }
        }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initExpanded
      && changes.initExpanded.isFirstChange()
      && changes.initExpanded.currentValue) {
      this.expand();
    }
  }

  expand() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.expandButtonText = 'global.collapse';
    } else {
      this.expandButtonText = 'global.expand';
    }
  }

  public cancel() {
    this.onCancelAction.emit();
  }
}
