import {FilterBase} from '../../../shared/models/filterBase';
import {Slice} from '../../../shared/models/slice';
import {UserInformationType} from '@cstx/volkswagen-mqs-user-configuration-service-client';

export class InfoFilter extends FilterBase {
  public slice: Slice;
  public title: string;
  public description?: string;

  public informationType?: UserInformationType;

  public informationTypes?: Array<UserInformationType>;
  public costCenter?: string;

  public workSequence?: string;

  public isActive?: boolean;
  public isPublished?: boolean;



  constructor() {
    super();

    this.pagingSize = 100;
    this.totalPages = 1;
    this.currentPageIndex = 0;
    this.sort = [];

    this.informationTypes = new Array<UserInformationType>();
  }

  public IsEmpty(): boolean {
    if (this.title && this.title !== '') {
      return false;
    }

    if (this.description && this.description !== '') {
      return false;
    }

    return true;
  }

  public getAllParams() {
    return {...this.getPageableParams(), ...this.getFilterParams()};
  }

  public getFilterParams() {
    if (this.IsEmpty()) {
      return {};
    }
    return {
      title: this.title,
      description: this.description

    };
  }

  public getPageableParams() {
    return {
      page: this.currentPageIndex,
      size: this.pagingSize,
      sort: this.sort,
    };
  }
}
