import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


declare global {
  interface String {
    trimEndCharacter(character: string): string;
  }
}

String.prototype.trimEndCharacter = function (character: string) {
  let d = String(this)

  try {
    d = d.trim();

    while (d.length > 1 && d.charAt(d.length - 1) === character) {
      d = d.substring(0, d.length - 1);
    }

    d = d.trimEnd();
    return d;
  }
  catch {
    return d;
  }
};
