import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'op-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss']
})
export class SelectBoxComponent implements OnInit {
  @Input() options: Array<any>;
  @Input() defaultLabel: string;
  @Input() disabled: boolean;
  @Input() filterDecoration = false;
  @Output() selectEvent = new EventEmitter();

  constructor(private translateService: TranslateService) {
  }

  ngOnInit(): void {
  }

  onSelect(selectEvent: any) {
    this.selectEvent.emit(selectEvent.target.value);
    this.defaultLabel = this.options[selectEvent.target.selectedIndex].label;
  }

  isSelected(defaultLabel: string, currentLabel: string) {
    return defaultLabel === currentLabel;
  }

  getOptionsLabelText(label: string): string {
    const translatedString = this.translateService.instant('select-box.label.' + label?.toLowerCase());

    if (translatedString !== 'select-box.label.' + label?.toLowerCase()) {
      return translatedString;
    }

    return label;
  }
}
