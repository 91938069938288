<ng-container *ngIf="!useExternalScanner">
  <video class="pr-0 mr-0 pt-3" width="100%" id="video"></video>
  <ng-container *ngIf="videoInputDevices.length > 1">
    <op-select-box class="align-content-center" style="width: 100%" (selectEvent)="changeCameraClicked()" [options]="videoInputDevices"></op-select-box>
  </ng-container>
</ng-container>

<ng-container>
  <tr class="row" *ngIf="useExternalScanner">
    <td class="col-12 pb-1">
      <input #result id="scannerResult" name="scannerResult" type="text" [(ngModel)]="scannerResult" (ngModelChange)="resultTextChanged()"  (keydown.enter)="submitText()"  (focusout)="checkFocus($event)" (focus)="checkFocus($event)"  class="form-control input-sm" [disabled]="pauseScanner"/>
    </td>
  </tr>
  <tr class="row" *ngIf="useExternalScanner">
    <td class="col-12 pb-0 pt-1 small">
      <input type="checkbox" [(ngModel)]="autoFocusEnabled" (change)="autoFocusChanged()">
      {{ 'label-scanner.input-receiver.autoFocusToggle' | translate }}
    </td>
  </tr>
  <tr class="row">
    <td class="col-12 pb-0 small">
    <input type="checkbox" [(ngModel)]="useExternalScanner" (change)="selectionChanged()">
      {{ 'scanner.use-external' | translate }}
    </td>
  </tr>
</ng-container>

