export class RefreshConfig {
  private readonly showAutoReload?: boolean;
  private readonly autoReloadIntervalPicker: Array<any>;
  private readonly defaultAutoReloadInterval: string;

  private autoReloadInterval?: number;
  private autoReloadEnabled?: boolean;

  constructor(autoReload: boolean, initialOption?: string) {
    this.autoReloadEnabled  = autoReload;
    this.showAutoReload = autoReload;

    this.autoReloadIntervalPicker = [
      {label: 'Off', value: 'Off' },
      {label: '5s',  value: '5000'},
      {label: '10s', value: '10000'},
      {label: '30s', value: '30000'}
    ];

    let option;
    if (initialOption) {
      option = this.autoReloadIntervalPicker.find(entry => entry.label === initialOption);
    }

    this.defaultAutoReloadInterval = option ? option.label : '10s';
    this.autoReloadInterval = option ? (option.value === 'Off' ? 0 : option.value) : 10000;


  }

  public getAutoReloadEnabled(): boolean {
    return this.autoReloadEnabled;
  }

  public getShowAutoReload(): boolean {
    return this.showAutoReload;
  }

  public getReloadIntervalOptions(): Array<any> {
    return this.autoReloadIntervalPicker;
  }

  public getReloadDefaultIntervalOption(): string {
    return this.defaultAutoReloadInterval;
  }

  public setAutoReloadEnabled(enabled: boolean) {
    this.autoReloadEnabled = enabled;
  }

  public setAutoReloadInterval(interval: number) {
    this.autoReloadInterval = interval;
  }

  public getAutoReloadInterval(): number {
    return this.autoReloadInterval;
  }
}
