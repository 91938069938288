import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guard';
import {LoginGuard} from './core/guards/login.guard';
import {FilePreviewComponent} from './shared/components/file-preview/file-preview.component';
import {VideoPlayerComponent} from './shared/components/video-player/video-player.component';
import {VideoInfoComponent} from './modules/home/infos/video-info/video-info.component';

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 200],
    // enableTracing: true
};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'actions',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/actions/actions.module').then(m => m.ActionsModule)
  },
  {
    path: ':componentType/:componentId/:componentCostCenter/:fileType/:fileName/:parserType',
    canActivate: [AuthGuard],
    component: FilePreviewComponent
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },

  {
    path: 'tracking',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/tracking/tracking.module').then(m => m.TrackingModule)
  },
  {
    path: 'engine',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/engine/engine.module').then(m => m.EngineModule)
  },
  {
    path: 'part',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/parts/parts.module').then(m => m.PartsModule)
  },
  {
    path: 'metadata',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/metadata/metadata.module').then(m => m.MetadataModule)
  },
  {
    path: 'asset',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/assets/assets.module').then(m => m.AssetsModule)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'tools',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/tools/tools.module').then(m => m.ToolsModule)
  },
  {
    path: 'statistics',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/statistics/statistics.module').then(m => m.StatisticsModule)
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'usecases',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/usecases/usecases.module').then(m => m.UsecasesModule)
  },
  {
    path: 'fct',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/fct/fct.module').then(m => m.FctModule)
  },
  {
    path: 'video-player',
    canActivate: [AuthGuard],

    component: VideoPlayerComponent

  },
  {
    path: 'video-info/:id',
    canActivate: [AuthGuard],
    component: VideoInfoComponent
  },
  {
    path: '**',
    redirectTo: 'home/notfound'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
