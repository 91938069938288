import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {LocalStorageManagerService} from "../services/local-storage-manager.service";

@Directive({
  selector: '[opResizeColumn]',
})
export class ResizeColumnDirective implements OnInit {

  @Input('opResizeColumn') resizable: boolean;

  @Input() set index(index: string) {
    this._index = parseInt(index, 10);
  }
  // tslint:disable-next-line:variable-name
  private _index: number;

  private head: HTMLHeadElement;
  private styleSheet: CSSStyleSheet;
  private startX: number;

  private currentWidth: number;

  private column: HTMLElement;
  private table: HTMLElement;
  private thead: HTMLElement;
  private tbody: HTMLElement;
  private tableName: string;

  private pressed: boolean;
  private unlistenMouseMove: () => void;
  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.column = this.el.nativeElement;
  }

  ngOnInit() {
    if (this.resizable) {
      if (document.getElementsByTagName('head').length > 0) {
        this.head = document.getElementsByTagName('head')[0];
      }
      this.styleSheet = Array.from(document.styleSheets).find(item => item.title === 'tableDefs');
      if (!this.styleSheet) {

        const styleSheetElement: HTMLStyleElement = document.createElement('style');
        styleSheetElement.title = 'tableDefs';
        styleSheetElement.type = 'text/css';
        this.head.appendChild(styleSheetElement);
        this.styleSheet = styleSheetElement.sheet as CSSStyleSheet;
      }
      const row = this.renderer.parentNode(this.column);
      this.thead = this.renderer.parentNode(row);
      this.tbody = this.renderer.nextSibling(this.thead);
      this.table = this.renderer.parentNode(this.thead);
      this.tableName = this.table.getAttribute('name');

      const resizer = this.renderer.createElement('div');
      this.renderer.setStyle(resizer, 'cursor', 'col-resize');
      this.renderer.setStyle(resizer, 'width', '11px');
      this.renderer.setStyle(resizer, 'height', '100%');
      this.renderer.setStyle(resizer, 'position', 'absolute');
      this.renderer.setStyle(resizer, 'right', '-5px');
      this.renderer.setStyle(resizer, 'top', '0');
      this.renderer.setStyle(resizer, 'z-index', '1000');
      const vertDivider = this.renderer.createElement('div');
      this.renderer.setStyle(vertDivider, 'height', '100%');
      this.renderer.setStyle(vertDivider, 'border-left', '1px solid #dee2e6');
      this.renderer.setStyle(vertDivider, 'position', 'absolute');
      this.renderer.setStyle(vertDivider, 'left', '5px');
      this.renderer.appendChild(resizer, vertDivider);
      this.renderer.appendChild(this.column, resizer);
      this.renderer.listen(resizer, 'mousedown', this.onMouseDown);

      this.renderer.listen('document', 'mouseup', this.onMouseUp);

      if (this.tableName) {
        const tableString = LocalStorageManagerService.getItem('tableDefs');
        if (tableString) {
          const tableDefs = JSON.parse(tableString);
          if (tableDefs[this.tableName]) {
            if (tableDefs[this.tableName][this._index]) {
              this.currentWidth = parseFloat(tableDefs[this.tableName][this._index]);
              this.refresh();
              return;
            }
          }
        }
      }
      this.initColumn();
    }
  }
  private initColumn() {
    this.currentWidth = parseFloat((this.column.offsetWidth / this.table.offsetWidth * 100).toFixed(2));
    this.refresh();
  }

    onMouseDown = (event: MouseEvent) => {
    this.unlistenMouseMove = this.renderer.listen(this.table, 'mousemove', this.onMouseMove);
    this.pressed = true;
    this.startX = event.pageX;
    this.currentWidth = parseFloat((this.column.offsetWidth / this.table.offsetWidth * 100).toFixed(2));
  }

    onMouseMove = (event: MouseEvent) => {
    const offset = 0;
    if (this.pressed && event.buttons) {
      this.renderer.setStyle(this.table, 'cursor', 'col-resize');
      this.renderer.setStyle(this.table, '-moz-user-select', 'none');
      this.renderer.setStyle(this.table, '-ms-user-select', 'none');
      this.renderer.setStyle(this.table, 'user-select', 'none');

      const width =
        (this.currentWidth + (event.pageX - this.startX - offset) / this.table.offsetWidth * 100).toFixed(2);
      const rule = this.getStyleRule();
      rule.style.flex = `0 0 ${width}%`;
    }
  }

    onMouseUp = (event: MouseEvent) => {
    if (this.unlistenMouseMove) {
      this.unlistenMouseMove();
    }
    if (this.pressed) {
      this.pressed = false;
      this.renderer.removeStyle(this.table, 'cursor');
      this.renderer.removeStyle(this.table, 'moz-user-select');
      this.renderer.removeStyle(this.table, '-ms-user-select');
      this.renderer.removeStyle(this.table, 'user-select');
      this.currentWidth = parseFloat((this.column.offsetWidth / this.table.offsetWidth * 100).toFixed(2));
      if (this.tableName) {
        const tableString = LocalStorageManagerService.getItem('tableDefs');
        let tableDefs = JSON.parse(tableString);
        if (tableDefs) {
          if (!tableDefs[this.tableName]) {
            tableDefs[this.tableName] = {};
          }
        } else {
          tableDefs = {};
          tableDefs[this.tableName] = {};
        }
        tableDefs[this.tableName][this._index] = this.currentWidth;
        LocalStorageManagerService.setItem('tableDefs', JSON.stringify(tableDefs));
      }
    }
  }
  private refresh() {
    const rule = this.getStyleRule();
    rule.style.flex = `0 0 ${this.currentWidth}%`;
  }
  private getStyleRule(): CSSStyleRule {
    const selectorText: string =
      'table[name=' + this.tableName + ']>tbody>tr>td:nth-child(' +  (this._index + 1) + '),table[name=' + this.tableName
      + ']>thead>tr>th:nth-child(' +  (this._index + 1) + ')';
    const rules: CSSRuleList = this.styleSheet.cssRules;
    let rule: CSSStyleRule = Array.from(rules).find(
      element => (element as CSSStyleRule).selectorText === selectorText) as CSSStyleRule;
    if (!rule) {
      const ruleIndex = this.styleSheet.insertRule(selectorText + '{}', rules.length);
      rule = rules[ruleIndex] as CSSStyleRule;
    }
    return rule;
  }
}
