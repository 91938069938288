import {Directive, ElementRef, EventEmitter, Input, OnChanges, Output} from '@angular/core';

@Directive({
  selector: '[opEnsureListSufficientlyFilled]'
})
export class EnsureListSufficientlyFilledDirective implements OnChanges {

  @Input() existingItems: number;
  @Output() loadMoreItems: EventEmitter<void> = new EventEmitter();

  previousExistingItems: number;

  constructor() {
  }

  ngOnChanges(): void {
    if (this.existingItems && this.existingItems !== this.previousExistingItems
      && document.documentElement.clientHeight === document.documentElement.scrollHeight) {
      this.previousExistingItems = this.existingItems;
      this.loadMoreItems.emit();
    }
  }

}
