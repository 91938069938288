import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Stack} from '../../models/tracking/stack';
import {TrackingService} from '../../services/tracking.service';
import {StackInfoService} from './stack-info.service';

@Component({
  selector: 'op-stack-info',
  templateUrl: './stack-info.component.html',
  styleUrls: ['./stack-info.component.scss'],
  providers: [TrackingService]
})
export class StackInfoComponent implements OnInit, OnChanges {
  @Input() stackIdentifier: string;

  // TODO: This ist not needed anymore. Remove with usages.
  @Input() stackDispatchDate: string;

  public stack = new Stack();
  loading = false;

  constructor(private trackingService: TrackingService, public stackInfoService: StackInfoService) { }

  ngOnInit(): void {
    this.getStack();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getStack();
  }

  private getStack() {
    if (!this.stackIdentifier) {
      return;
    }
    this.loading = true;
    this.trackingService.getStackById(this.stackIdentifier)
      .then(result => {
        this.stack = result;
      }).finally(() => this.loading = false);
  }

}
