import {Inject, Injectable, InjectionToken, Optional} from '@angular/core';
import {APP_BASE_HREF, PathLocationStrategy, PlatformLocation} from '@angular/common';
import {Params, UrlSerializer} from '@angular/router';



export const PRESERVE_QUERY_KEYS =
  new InjectionToken<Array<string>>('PRESERVE_QUERY_KEYS');

@Injectable()
export class QueryParamPreservationStrategy extends PathLocationStrategy {
  private readonly preservedKeys: Array<string>;

  private get search(): string {
    return this.platformLocation?.search ?? '';
  }
  constructor(
    private platformLocation: PlatformLocation,
    private urlSerializer: UrlSerializer,
    @Optional() @Inject(APP_BASE_HREF) _baseHref?: string,
    @Optional() @Inject(PRESERVE_QUERY_KEYS) _preserveKeys?: Array<string>,
  ) {
    super(platformLocation, _baseHref);
    this.preservedKeys = _preserveKeys;
  }

  prepareExternalUrl(internal: string): string {
    const path = super.prepareExternalUrl(internal);
    const existingURLSearchParams = new URLSearchParams(this.search);
    const existingQueryParamsList = this.preservedKeys ?
      Array.from(existingURLSearchParams.entries()).filter(entry => this.preservedKeys.findIndex(value => value === entry[0]) > -1) :
      Array.from(existingURLSearchParams.entries());
    const existingQueryParams: Params = {};
    existingQueryParamsList.forEach(param => existingQueryParams[param[0]]=param[1]);
    const urlTree = this.urlSerializer.parse(path);
    const nextQueryParams = urlTree.queryParams;
    urlTree.queryParams = { ...existingQueryParams, ...nextQueryParams };
    return urlTree.toString();
  }
}
