import {Component, Input, OnInit} from '@angular/core';
import {LocationInfoService} from './location-info.service';

@Component({
  selector: 'op-location-info',
  templateUrl: './location-info.component.html',
  styleUrls: ['./location-info.component.scss']
})
export class LocationInfoComponent implements OnInit {
  @Input() componentName: string;

  lastKnownLocation: string;
  loading: boolean;

  constructor(private locationInfoService: LocationInfoService) { }

  ngOnInit(): void {
    this.locationInfoService.TryFindLastLocation(this.componentName).then(_ => {
      if (_ !== '' && !_.startsWith('AFO ')) {
        _ = 'AFO ' + _;
      }

      this.lastKnownLocation = _;
    });
  }

}
