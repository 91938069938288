<op-detail-section-wrapper
  [title]="'audit-v2.title' | translate"
  [description]="'audit-v2.description' | translate"
  [contentTemplate]="contentTemplateRef"
  [hasContent]="componentElement != undefined"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <ng-container *ngIf="componentElement != undefined">
    <div class="container ml-0 mr-0 mb-0">
      <div class="row pb-3">
        <div class="col-md-4 pl-0 small" style="border-top: none; font-weight: bold">ID</div>
        <div class="col-md-8 pl-0 small" style="border-top: none;">
          <div class="input-group" style="max-width: 400px">
            <input type="text" class="form-control" style="font-size: small" name="name"
                   [value]="componentElement.id ? componentElement.id : componentElement.Id" readonly/>
            <div class="input-group-append">
              <span class="input-group-text pr-0" style="font-size: small"><op-copy-to-clipboard-icon
                [content]="componentElement.id ? componentElement.id : componentElement.Id"></op-copy-to-clipboard-icon></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-md-4 pl-0 small" style="border-top: none; font-weight: bold">{{ 'component-profile.audit-v2.createdAt' | translate }}</div>
        <div class="col-md-8 pl-0 small" style="border-top: none;">
          <div class="input-group" style="max-width: 400px;">
            <input type="text" class="form-control" style="font-size: small" name="name"
                   [value]="(componentElement.createdAt ? componentElement.createdAt : componentElement.CreatedAt) | date:'dd.MM.yyyy HH:mm:ss'"
                   readonly/>
            <div class="input-group-append">
              <span class="input-group-text pr-0" style="font-size: small"><op-copy-to-clipboard-icon
                [content]="(componentElement.createdAt ? componentElement.createdAt : componentElement.CreatedAt) | date:'dd.MM.yyyy HH:mm:ss'"></op-copy-to-clipboard-icon></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-md-4 pl-0 small" style="border-top: none; font-weight: bold">{{ 'component-profile.audit-v2.createdBy' | translate }}</div>
        <div class="col-md-8 pl-0 small" style="border-top: none;">
          <div class="input-group" style="max-width: 400px;">
            <input type="text" class="form-control" style="font-size: small" name="name"
                   [value]="componentElement.createdBy ? componentElement.createdBy : componentElement.CreatedBy"
                   readonly/>
            <div class="input-group-append">
              <span class="input-group-text pr-0" style="font-size: small"><op-copy-to-clipboard-icon
                [content]="componentElement.createdBy ? componentElement.createdBy : componentElement.CreatedBy"></op-copy-to-clipboard-icon></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-md-4 pl-0 small" style="border-top: none; font-weight: bold">{{ 'component-profile.audit-v2.updatedAt' | translate }}</div>
        <div class="col-md-8 pl-0 small" style="border-top: none;">
          <div class="input-group" style="max-width: 400px;">
            <input type="text" class="form-control" style="font-size: small" name="name"
                   [value]="(componentElement.modifiedAt ? componentElement.modifiedAt :  componentElement.ModifiedAt) | date:'dd.MM.yyyy HH:mm:ss'"
                   readonly/>
            <div class="input-group-append">
              <span class="input-group-text pr-0" style="font-size: small"><op-copy-to-clipboard-icon
                [content]="(componentElement.modifiedAt ? componentElement.modifiedAt :  componentElement.ModifiedAt) | date:'dd.MM.yyyy HH:mm:ss'"></op-copy-to-clipboard-icon></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-md-4 pl-0 small" style="border-top: none; font-weight: bold">{{ 'component-profile.audit-v2.updatedBy' | translate }}</div>
        <div class="col-md-8 pl-0 small" style="border-top: none">
          <div class="input-group" style="max-width: 400px;">
            <input type="text" class="form-control" style="font-size: small" name="name"
                   [value]="componentElement.modifiedBy ? componentElement.modifiedBy : componentElement.ModifiedBy"
                   readonly/>
            <div class="input-group-append">
              <span class="input-group-text pr-0" style="font-size: small"><op-copy-to-clipboard-icon
                [content]="(componentElement.createdAt ? componentElement.createdAt : componentElement.CreatedAt) | date:'dd.MM.yyyy HH:mm:ss'"></op-copy-to-clipboard-icon></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-md-4 pl-0 small" style="border-top: none; font-weight: bold">{{ 'component-profile.audit-v2.classification' | translate }}</div>
        <div class="col-md-8 pl-0 small" style="border-top: none">
          <div>{{ ksu ? ksu : 'keine Angabe zur KSU Klasse' }}</div>
        </div>
        <div class="col-md-4 d-none d-md-block" style="border-top: none;">
        </div>
        <div class="col-md-8 pl-0" style="border-top: none;">
          <img src="/assets/img/internalEN_DE_dark.svg" alt="" class="d-inline-block align-top">
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-md-4 pl-0 small" style="border-top: none; font-weight: bold">{{ 'component-profile.audit-v2.systemlink' | translate }}</div>
        <div class="col-md-8 pl-0" style="border-top: none; margin-top: -20px">
          <op-entity-label style="margin-left: -20px;" [value]="selfLink" width="128" height="128" labelType="url"></op-entity-label>
        </div>
      </div>
      <ng-container *ngIf="componentElement.source">
        <div class="row pb-3">
          <div class="col-md-4 pl-0 small" style="border-top: none; font-weight: bold">{{ 'component-profile.audit-v2.source' | translate }}</div>
          <div class="col-md-8 pl-0 small" style="border-top: none">
            <div class="input-group" style="max-width: 400px;">
              <input type="text" class="form-control" style="font-size: small" name="name"
                     [value]="componentElement.source" readonly/>
              <div class="input-group-append">
                <span class="input-group-text pr-0" style="font-size: small"><op-copy-to-clipboard-icon
                  [content]="componentElement.source"></op-copy-to-clipboard-icon></span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="componentElement.creationType">
        <div class="row pb-3">
          <div class="col-md-4 pl-0 small" style="border-top: none; font-weight: bold">Creation Type</div>
          <div class="col-md-8 pl-0 small" style="border-top: none">
            <div class="input-group" style="max-width: 400px;">
              <input type="text" class="form-control" style="font-size: small" name="name"
                     [value]="componentElement.creationType" readonly/>
              <div class="input-group-append">
                <span class="input-group-text pr-0" style="font-size: small"><op-copy-to-clipboard-icon
                  [content]="componentElement.creationType"></op-copy-to-clipboard-icon></span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
