<!--<op-upload-v2 (onNewFileSelected)="newFileLoaded($event)" [acceptedExtensions]="extenions" ></op-upload-v2>-->

<ng-container *ngIf="currentVideo" xmlns="http://www.w3.org/1999/html">
  <ng-container *ngIf="!previewMode; else previewModeTemplate">
    <video [src]="currentVideo.video"
           controls
           type="video/*"
           [id]="elementId"
           [height]="videoHeight"
           [width]="videoWidth"
    >
    </video>
  </ng-container>
  <ng-template #previewModeTemplate>
    <div (click)="videoSelected(currentVideo)" [class.pointer]="currentVideo">

      <div class="d-flex flex-column pl-0 pr-0 justify-content-center align-items-center videoBanner"
           style="position: absolute; top: 15px; left: 0; right: 0; z-index: 300;">
        <div style="position: relative; margin: auto">
          {{ currentVideo.name }}
        </div>
      </div>

      <ng-container *ngIf="VideoPlayerService.isVideoPlaying(currentVideo.name)">
      <div style="position: absolute; bottom: 10px; right: 10px; z-index: 400" [tooltip]="playingToolTipTemplate">
        <span class="spinner-grow spinner-grow-sm" style="color: #33ce4f"></span>
      </div>
      </ng-container>

      <ng-container *ngIf="currentVideo.videoState === VideoState.PAUSED">
        <div style="position: absolute; bottom: 10px; right: 10px; z-index: 400" [tooltip]="playingToolTipTemplate">
          <i class="fas fa-pause" style="color: darkred"></i>
        </div>
      </ng-container>

    <video [src]="currentVideo.videoPreview
                  ? currentVideo.videoPreview
                  : currentVideo.video"
           style="background-color: black"
           type="video/*"
           [id]="elementId"
           [height]="videoHeight"
           [width]="videoWidth">
    </video>

    </div>

  </ng-template>
</ng-container>

<ng-template #playingToolTipTemplate>
  {{ 'video-player.playing' | translate }}
</ng-template>
