import { Injectable } from '@angular/core';
import {TelegramResponse, TelegramService} from '@cstx/volkswagen-mqs-telegram-receiver-service-client';

@Injectable({
  providedIn: 'root'
})
export class LocationInfoService {

  constructor(public telegramReceiverService: TelegramService) { }

  public TryFindLastLocation(componentName: string): Promise<string> {
    return new Promise<string>((resolve, reject) => this.telegramReceiverService.getTelegrams({
      componentName,
      size: 100
    }).toPromise()
      .then(_ => {
        if (!_.content || _.content.length === 0) {
          resolve('');
        } else {
          _.content.sort((a, b) => {
            if (a.modifiedAt < b.modifiedAt) {
              return 1;
            }
            if (a.modifiedAt > b.modifiedAt) {
              return -1;
            }

            // names must be equal
            return 0;
          });

          resolve(_.content[0].workSequence);
        }
  }));
  }
}
