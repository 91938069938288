<op-detail-section-wrapper
  [title]="'manufactoring-process.label.processData' | translate"
  [description]="'manufactoring-process.description' | translate"
  [contentTemplate]="assemblyTemplate"
  [hasContent]="processSets != undefined && processSets.length >= 1"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId"
  [loading]="loading">
</op-detail-section-wrapper>

<ng-template #assemblyTemplate>
  <ng-container *ngIf="processSets != undefined && processSets.length >= 1">
    <div class="row pb-3">
      <div class="col pl-2">
        <op-excel-export [exportDataRaw]="onDataExport" (onExportRequested)="exportRequestRaised()"></op-excel-export>

        <small>Zum Exportieren der Messdaten das Icon klicken. Export wird in der Inbox bereitgestellt.</small>
      </div>
    </div>

    <div class="row">
      <div class="col pl-3">
    <ngx-treeview class="cpp-treeview-h-100"
                  [itemTemplate]="treeViewItemTemplate"
                  [config]="treeViewConfig"
                  [items]="treeViewItems">
    </ngx-treeview>
      </div>
    </div>
  </ng-container>
</ng-template>


<ng-template #treeViewItemTemplate let-treeViewItem="item" let-onCollapseExpand="onCollapseExpand">

    <ng-container *ngIf="treeViewItem.children; else noChildrenTemplate">
      <div class="p-0 m-0 row" style="line-height: 19px" [class.border-bottom]="viewSizeLG" [class.mb-1]="viewSizeLG">

        <ng-container *ngIf="(treeViewItem.text === 'partialWorkSequence'); else defaultFolderTemplate">
          <div class="col p-0 d-flex text-overflow-ellipses">
          <i *ngIf="treeViewItem.children"
             (click)="onCollapseExpand()"
             aria-hidden="true"
             class="pr-1 pb-1 pointer"
             [ngSwitch]="treeViewItem.collapsed">

            <i *ngSwitchCase="false" class="fas fa-stream"></i>
            <i *ngSwitchCase="true" class="fas fa-bars"></i>
          </i>
          <label class="cpp-treeview-label treeViewItemGroup text-overflow-ellipses" (click)="onCollapseExpand()"> TAF {{ treeViewItem.value.name }}</label>
          </div>
        </ng-container>


        <ng-template #defaultFolderTemplate>
          <div class="col p-0 d-flex text-overflow-ellipses">
          <i *ngIf="treeViewItem.children"
             (click)="onCollapseExpand()"
             aria-hidden="true"
             class="pr-1 pb-1 pointer"
             [ngSwitch]="treeViewItem.collapsed">

            <i *ngSwitchCase="false" class="fas fa-folder-open"></i>
            <i *ngSwitchCase="true" class="fas fa-folder"></i>
          </i>
          <label class="cpp-treeview-label treeViewItemGroup text-overflow-ellipses" (click)="onCollapseExpand()"> {{ treeViewItem.text }}</label>
      </div>
        </ng-template>
      </div>

    </ng-container>

  <ng-template #noChildrenTemplate>
    <ng-container *ngIf="!treeViewItem.children
    && treeViewItem.text !== 'workSequenceDetails'
    && treeViewItem.text !== 'partialWorkSequenceDetails';
     else workSequenceDetailsTemplate">

      <div class="p-0 m-0 row" style="line-height: 19px" [class.border-bottom]="viewSizeLG" [class.mb-1]="viewSizeLG">
        <div class="col p-0 d-flex text-overflow-ellipses">
          <label  class="cpp-treeview-label form-check-label">
            {{treeViewItem.text}}
          </label>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #workSequenceDetailsTemplate>
    <ng-container *ngIf="treeViewItem.text === 'workSequenceDetails'">
      <div class="pb-3">
      <div class="row" *ngIf="treeViewItem.value.startedAt && treeViewItem.value.startedAt !== '0001-01-01T00:00:00Z'">
        <div class="col">Startzeit:</div>
        <div class="col">
          <op-datetime-show [dateTime]="treeViewItem.value.startedAt" noLocalTimeConversion="true">
          </op-datetime-show>
        </div>
      </div>
      <div class="row" *ngIf="treeViewItem.value.finishedAt && treeViewItem.value.finishedAt !== '0001-01-01T00:00:00Z'">
        <div class="col">Endzeit:</div>
        <div class="col">
          <op-datetime-show [dateTime]="treeViewItem.value.finishedAt" noLocalTimeConversion="true">
          </op-datetime-show>
        </div>
      </div>
        <ng-container *ngIf="treeViewItem.value.detail">
          <div class="row" *ngIf="treeViewItem.value.detail.status">
            <div class="col">Status:</div>
            <div class="col">
              {{ treeViewItem.value.detail.status }}
            </div>
          </div>
          <div class="row" *ngIf="treeViewItem.value.detail.status">
            <div class="col">Beschreibung:</div>
            <div class="col">
              {{ treeViewItem.value.detail.description }}
            </div>
          </div>
        </ng-container>

      </div>
    </ng-container>

    <ng-container *ngIf="treeViewItem.text === 'partialWorkSequenceDetails'">
      <div class="row">
        <div class="col">Status:</div>
        <div class="col">
          {{ treeViewItem.value.statusValue }}
        </div>
      </div>
      <div class="row">
        <div class="col">Arbeitsprogramm:</div>
        <div class="col">
          {{ treeViewItem.value.workProgramNumber }}
        </div>
      </div>
      <div class="row">
        <div class="col">Werkzeugnummer:</div>
        <div class="col">
          {{ treeViewItem.value.toolNumber }}
        </div>
      </div>
    </ng-container>


  </ng-template>

</ng-template>
