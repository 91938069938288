<op-detail-section-wrapper
  [title]="'component-profile-v2.dlq.title' | translate"
  [description]="'component-profile-v2.dlq.description'  | translate"
  [contentTemplate]="contentTemplateRef"
  [hasContent]="messages.length >= 1"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <table class="table table-hover">
    <tr class="row pl-3 pr-3">
    <td class="cpp-column-fixed-date pl-0 pr-3 small text-overflow-ellipses d-none d-sm-none d-md-block" >
      {{ 'dlq.sentAt' | translate}}
    </td>
    <td class="col pl-0 small d-none d-sm-none d-md-block" >
      {{ 'dlq.errorType' | translate}}
    </td>
    <td class="col-2 pl-0 small d-none d-sm-none d-md-block" >
      {{ 'dlq.serviceName' | translate}}
    </td>
    <td class="col-1 pl-0 small d-none d-sm-none d-md-block">{{ 'dlq.actions' | translate }}</td>
  <tr class="row d-flex pl-3 pr-3" *ngFor="let element of messages; let i = index">
    <td class="cpp-column-fixed-date pl-0 pr-3 text-monospace small d-none d-sm-none d-md-block"><i class="fas fa-clock pr-1 d-inline"></i>{{ element.sendAt | date:'dd.MM.yyyy HH:mm:ss' }}</td>
    <td class="col small pl-0 d-none d-sm-none d-md-block">
      {{ element.errorType }}
    </td>
    <td class="col-2 pl-0 small d-none d-sm-none d-md-block">{{ element.service }}</td>
    <td class="col-1 pl-0 small d-none d-sm-none d-md-block">
      <a [routerLink]="'/admin/dlq/' + element.id" [queryParams]="{ messageTraceId: element.traceId }"><i class="fas fa-window-maximize pr-1"></i></a>
    </td>

    <td class="col pl-0 small text-overflow-ellipses d-md-none d-lg-none">
      <i class="fas fa-clock pr-1 d-inline"></i>{{ element.sendAt | date:'dd.MM.yyyy HH:mm:ss' }}
      <br> {{ element.errorType }}
      <br> {{ element.service }}
      <br> {{ element.channel }}
      <br> <a [routerLink]="'/admin/dlq/' + element.id" [queryParams]="{ messageTraceId: element.traceId }"><i class="fas fa-window-maximize pr-1"></i></a>
    </td>
  </tr>
  </table>
</ng-template>
