import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'op-spinner-small',
  templateUrl: './spinner-small.component.html',
  styleUrls: ['./spinner-small.component.scss']
})
export class SpinnerSmallComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
