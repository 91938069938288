import { Injectable } from '@angular/core';
import {firstValueFrom, Observable} from 'rxjs';;
import {HttpEvent} from '@angular/common/http';
import {FileControllerService, StatusControllerService} from '@cstx/volkswagen-mqs-file-handling-service-client';
import {BackendService} from './models/backend-service';
import {ConfigService} from '../../../core/services/config.service';
import {BackendStatus} from './models/BackendStatus';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';

@Injectable({
  providedIn: 'root'
})
export class FileHandlingService extends BackendService{

  constructor(private fileControllerService: FileControllerService,
              private statusControllerService: StatusControllerService) {
    super('file-handling-service', ConfigService.configuration.fhsApiUrl)
  }

  public async getStatus(): Promise<BackendStatus> {
    let backendStatus: BackendStatus = BackendStatus.UNKNOWN;

    try {
      const response = await firstValueFrom(this.statusControllerService.getStatus());

      if (response.status.toString() === 'OK/Running') {
        backendStatus = BackendStatus.UP;
      } else {
        backendStatus = BackendStatus.FAILED;
      }
    } catch (error) {
      LoggingService.logError(LoggingSource.FILE_HANDLING_SERVICE,
        `Backend error occurred when trying get status from service.`, error);
    }

    return backendStatus;
  }

  public uploadUseCaseAsync(useCaseName: string,
                            file: File,
                            s3Key: string,
                            preserveFileName: boolean = true,
                            origin: string = 'SZONLINEv2',
                            source: string = 'PORTAL'): Observable<HttpEvent<any>>
  {

    try {
      const result = this.fileControllerService.uploadGeneric({
        useCase: useCaseName,
        source,
        file,
        origin,
        s3Key,
        ogName: preserveFileName
        // regExS3KeyPattern: this.getRegExUploadPattern(useCase)
      }, 'events', true);

      return result;
    }
    catch (error) {
      console.log('error');
    }
  }
}
