import { Component } from '@angular/core';

@Component({
  selector: 'op-infos',
  templateUrl: './infos.component.html',
  styleUrls: ['./infos.component.scss']
})
export class InfosComponent {

}
