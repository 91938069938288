import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ComponentBlockWizardService} from './component-block-wizard.service';
import {ComponentBlockStatus} from './component-block';
import {QualityManagementService} from '../../services/backend/quality-management.service';
import {ComponentBlockWizardActionType} from './component-block-wizard-action-type';
import {BlockingAction} from '../../services/backend/models/blockingAction';
import {BlockingActionResponse} from '@cstx/volkswagen-mqs-quality-management-service-client';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';

@Component({
  selector: 'op-component-block-wizard',
  templateUrl: './component-block-wizard.component.html',
  styleUrls: ['./component-block-wizard.component.scss']
})
export class ComponentBlockWizardComponent implements OnInit {
  @Input() cancelHidden: boolean;

  @Output() onClose = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  public blocking: boolean;
  public invalid: boolean;
  public extend: boolean = false;
  public options: Array<any> = [{ label: 'Keine Daten', value: '' }];

  public set blockingReason(value: string) {
    this._blockingReason = value;
    ComponentBlockWizardService.componentBlock.blockingReason = this._blockingReason;
    this.validate();
  }

  public get blockingReason(): string {
    return this._blockingReason;
  }

  private _blockingReason: string;

  protected readonly ComponentBlockWizardService = ComponentBlockWizardService;
  protected readonly ComponentBlockStatus = ComponentBlockStatus;

  constructor(private qualityManagementService: QualityManagementService) {
  }

  public ngOnInit() {
    ComponentBlockWizardService.getExtendableBlockingActions(this.qualityManagementService).then(success => {
        if (success) {
          this.options = new Array<any>();
          this.options.push({label: 'Bitte Sperraktion auswählen...', value: undefined });

          ComponentBlockWizardService.listOfExtendableBlockingActions.forEach(extendableBlockingAction => {
            this.options.push({label: extendableBlockingAction.blockingActionNr, value: extendableBlockingAction.blockingActionNr});
          });
        }
    });

    this.validate();
  }

  public validate() {
    if (this.extend) {
      this.invalid =
        !ComponentBlockWizardService.componentBlock.blockingActionNr
        || ComponentBlockWizardService.componentBlock.componentNames.size < 1
        || !ComponentBlockWizardService.componentBlock.blockingAction;
    } else {
      this.invalid =
        ComponentBlockWizardService.componentBlock.blockingReason === undefined
        || ComponentBlockWizardService.componentBlock.blockingReason?.length < 4
        || ComponentBlockWizardService.componentBlock.componentNames.size < 1;
    }

    LoggingService.logTrace(LoggingSource.COMPONENT_BLOCK_WIZARD_COMPONENT,
      `Current block type extend [${this.extend}]. IsValid: [${!this.invalid}]`);

    LoggingService.logTrace(LoggingSource.COMPONENT_BLOCK_WIZARD_COMPONENT,
      `[${ ComponentBlockWizardService.componentBlock.componentNames.size}] components prepared to be blocked.`);
  }

  public cancel() {
    this.onCancel.emit();
    ComponentBlockWizardService.componentBlock.state = ComponentBlockStatus.None;
    ComponentBlockWizardService.onBlockAbort.emit();
  }

  public onActionTypeChanged() {
    this.extend = !this.extend;
    this.blockingActionSelected(undefined);
  }


  public close() {
    this.onClose.emit();
    ComponentBlockWizardService.componentBlock.state = ComponentBlockStatus.None;
    ComponentBlockWizardService.onBlockFinished.emit();
  }

  public async blockSelected() {
    this.blocking = true;
    ComponentBlockWizardService.componentBlock.state = ComponentBlockStatus.Initiated;

    let result: BlockingActionResponse;
    if (ComponentBlockWizardService.componentBlock.type === ComponentBlockWizardActionType.ExtendBlockingAction) {
      /**
       * Extend the given blockingAction with the given component(s)
       */

      ComponentBlockWizardService
        .componentBlock
        .blockingAction
        .componentIdentifiersExtension = new Array<string>();

      ComponentBlockWizardService
        .componentBlock
        .blockingAction
        .componentIdentifiersExtension
        .push(...Array.from(ComponentBlockWizardService.componentBlock.componentNames));

      result=  await this.qualityManagementService
        .extendBlockingActionV2(ComponentBlockWizardService.componentBlock.blockingAction);

    } else {
      /**
       * Create a new blockingAction with the given component(s) and reason
       */

      const blockingAction = new BlockingAction();
            blockingAction.blockingReason = ComponentBlockWizardService.componentBlock.blockingReason;
            blockingAction.description = 'Erstellt über Komponentenauswahl.';

            blockingAction.componentIdentifiers = new Array<string>();
            blockingAction.componentIdentifiers
              .push(...Array.from(ComponentBlockWizardService.componentBlock.componentNames));

            result = await this.qualityManagementService.createOrUpdateBlockingActionV2(blockingAction);
    }

    if (result) {
      ComponentBlockWizardService.componentBlock.state = ComponentBlockStatus.Done;
    } else {
      ComponentBlockWizardService.componentBlock.state = ComponentBlockStatus.Failed;
    }

    this.blocking = false;
  }

  public blockingActionSelected(blockingActionNr: string) {
    ComponentBlockWizardService.componentBlock.blockingActionNr = blockingActionNr;

    if (!blockingActionNr) {
      ComponentBlockWizardService.componentBlock.blockingAction = undefined;
      return;
    }

    ComponentBlockWizardService.componentBlock.blockingAction
      = ComponentBlockWizardService.getBlockingActionFromList(blockingActionNr);

    // TODO: Move to setter of blockingAction(Nr)
    ComponentBlockWizardService.componentBlock.type
      = ComponentBlockWizardActionType.ExtendBlockingAction;

    this.validate();
  }
}
