import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {PreviewRequest} from './previewRequest';
import {ComponentFile} from './componentFile';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

@Component({
  selector: 'op-file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.scss']
})
export class FileExplorerComponent implements OnInit {
  @Input() componentId: string;
  @Input() componentType: string;
  @Input() componentCostCenter: string;
  @Input() selfLink: string;
  @Input() contentContainerId: string;

  @Input() collapsable = true;
  @Input() sectionTitle : string;
  @Input() sectionDescription: string;
  @Input() componentName: string;
  @Input() filePreSelectionFilterValue: string = undefined;

  previewRequestEvent = new Subject<PreviewRequest>();
  triggerTreeviewReload = new Subject<any>();
  showUploadRequestEvent = new Subject<boolean>();
  uploadRequested: boolean;
  public viewSizeSM: boolean;
  public showPreview: boolean;

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.breakpointObserver
    .observe(['(max-width: 768px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.viewSizeSM = true;
      } else {
        this.viewSizeSM = false;
      }
    });
  }

  fileSelected(componentFile: ComponentFile) {
    const previewRequest = new PreviewRequest();

    previewRequest.componentCostCenter = this.componentCostCenter;
    previewRequest.componentType = this.componentType;
    previewRequest.componentId = this.componentId;
    previewRequest.fileName = componentFile.Name;
    previewRequest.parserType = componentFile.ParserType;
    previewRequest.fhsFileType = componentFile.FhsFileType;
    previewRequest.fileSize = componentFile.Size;


    this.previewRequestEvent.next(previewRequest);

    if (typeof componentFile !== 'string') {
      this.showPreview = true;
    }
  }

  showUpload() {
    this.uploadRequested = true;
    this.showUploadRequestEvent.next(true);
  }

  onUploadRequestEnd(uploaded: boolean) {
    if (uploaded) {
      this.triggerTreeviewReload.next(null);
    }

    this.uploadRequested = false;
  }
}
