
export class Slice {
  first?: boolean;
  last?: boolean;
  pageNumber?: number;
  size?: number;
  empty?: boolean;

  constructor(first: boolean, last: boolean, pageNumber: number, size: number, empty: boolean) {
    this.first = first;
    this.last = last;
    this.pageNumber = pageNumber;
    this.size = size;
    this.empty = empty;
  }
}
