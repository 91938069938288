<form *ngIf="EnterpriseRoleProviderService.hasRequiredClaimByName('news-create', false)"
      class="container-fluid pl-3 pb-3 cpp-page-defaults" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="pt-2 pb-2">
    <op-breadcrumb [path]="'Home/Infos/' + (id ? id : ('info.createInfo' | translate))"
                   [lastItemAlias]="info?.title"></op-breadcrumb>
    <div *ngIf="loading" class="spinner-border spinner-border-sm float-right" style="margin-top: 4px"></div>
  </div>
  <div class="row pt-3 pb-3 pr-3">
    <div class="col mt-auto border-bottom">
      <h3>{{ (!createMode ? 'info.editInfo' : 'info.createInfo') | translate }}</h3>
    </div>
    <div *ngIf="!createMode" class="col-2 mt-0 pt-1 text-right border-bottom">
      <op-component-state-badge  [name]="info.isActive ? 'ACTIVE' : 'INACTIVE'"></op-component-state-badge>
    </div>
  </div>
  <div class="row pt-3 pb-3 pr-3">
    <div class="col">
      {{ 'info.form.type.label' | translate }}
      <br>
      <small>{{ 'info.form.type.description' | translate }}</small>
    </div>
    <div class="col pl-3">
      <ng-container *ngIf="info.informationType !== UserInformationType.Supportrequest && info.informationType !== UserInformationType.Alert; else typeAsTextTemplate">
      <op-select-box class="pl-2" [options]="typeOptions"
                     [defaultLabel]="typeSelectedOption"
                     (selectEvent)="selectedTypeChanged($event)">
      </op-select-box>
      </ng-container>
      <ng-template #typeAsTextTemplate>
        <b>{{ info.informationType }}</b>
      </ng-template>
    </div>
  </div>
  <div class="row pt-3 pb-3 pr-3">
    <div class="col">
      {{ 'info.form.published.label' | translate }}
      <br>
      <small>{{ 'info.form.published.description' | translate }}</small>
    </div>
    <div class="col pl-3 ">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" formControlName="isPublished">
      </div>
    </div>
  </div>
  <div class="row pt-3 pb-3 pr-3">
    <div class="col">
      {{ 'info.form.title' | translate }}
      <br>
      <small>{{ 'info.form.description.title' | translate }}</small>
    </div>
    <div class="col">
      <input type="text" class="form-control" name="title" formControlName="title"/>
    </div>
  </div>
  <div class="row pt-3 pb-3 pr-3">
    <div class="col">
      {{ 'info.form.description.label' | translate }}
      <br>
      <small>{{ 'info.form.description.help' | translate }}</small>
    </div>
    <div class="col mt-auto">
      <textarea rows="3" id="description" class="form-control" type="text" formControlName="description"></textarea>
    </div>
  </div>
  <div class="row pt-3 pb-3 pr-3">
    <div class="col">
      {{ 'info.form.activeFrom.label' | translate}}
      <br>
      <small>{{ 'info.form.activeFrom.description' | translate}}</small>
    </div>
    <div class="col mt-auto">
      <input class="col-sm form-control" type="text"
             placeholder="{{ 'global.input.select.date' | translate }}" bsDatepicker formControlName="activeFrom"
             [bsConfig]="bsConfig">
    </div>
  </div>
  <div class="row pt-3 pb-3 pr-3">
    <div class="col">
      {{ 'info.form.activeUntil.label' | translate}}
      <br>
      <small>{{ 'info.form.activeUntil.description' | translate}}</small>
    </div>
    <div class="col mt-auto">
      <input class="col-sm form-control" type="text"
             placeholder="{{ 'global.input.select.date' | translate }}" bsDatepicker formControlName="activeUntil"
             [bsConfig]="bsConfig">
    </div>
  </div>

  <div class="row pt-3 pb-3 pr-3">
    <div class="col">
      {{ 'info.form.bigscreen.area.label' | translate }}
      <br>
      <small>{{ 'info.form.bigscreen.area.description' | translate }}</small>
    </div>
    <div class="form-check col pl-3">
      <op-select-box class="pl-2" [options]="bigScreenAreaOptions"
                     [defaultLabel]="bigScreenAreaSelectedOption"
                     (selectEvent)="selectedBigScreenAreaChanged($event)">
      </op-select-box>
    </div>
  </div>

  <!-- To make a information show on all info targets is currently only allowed on dev as this should be heavily secured before releasing !!! -->
  <div *ngIf="currentStage === 'develop' || currentStage === 'development' || currentStage === 'localhost'" class="row pt-3 pb-3 pr-3">
    <div class="col">
      {{ 'info.form.showGlobal.label' | translate }}
      <br>
      <small>{{ 'info.form.showGlobal.description' | translate }}</small>
    </div>
    <div class="form-check col pl-3">
      <input class="form-check-input ml-0" type="checkbox" value="showGlobal" id="showGlobal" formControlName="showGlobal">
      <label class="form-check-label pl-3" for="showGlobal">
        {{'info.form.showGlobal.label' | translate}}
      </label>
    </div>
  </div>

  <div class="row" id="channelManagement" [class.disabled]="info.showGlobal">
    <div class="col-6">
      {{ 'info.form.costCenterSelection.label' | translate }}
      <br>
      <small>{{ 'info.form.costCenterSelection.description' | translate }}</small>
    </div>

    <div class="col">
  <div class="overflow-auto border mb-1" style="max-height: 300px; min-height: 300px">
    <table id="targetedSubs" class="table mb-0">
      <thead style="position: sticky; top:0; z-index: 100; background-color: white">
      <tr class="d-flex">
        <th class="col">Ausgewählte {{ 'global.word.costcenter' | translate }}</th>
        <th class="fixed"></th>
      </tr>
      </thead>
      <tbody>
      <tr class="d-flex"
          *ngFor="let selectedEntry of selectedEntries, index as selectedIndex"
          [class.marked]="markedSelectedEntries.has(selectedEntry)"
          [class.pointer]="!info.showGlobal"
          (click)="markCheck(selectedIndex, $event, markedSelectedEntries, selectedEntries)"
          (contextmenu)="markCheck(selectedIndex, $event, markedSelectedEntries, selectedEntries)"
          (dblclick)="deselectEntry(selectedEntry)">
        <td class="py-2 col">
          {{ selectedEntry }}
        </td>

        <td class="fixed pl-0 pr-2 py-2">
          <i *ngIf="getNewSelectedEntries().has(selectedEntry)" class="fas fa-plus-circle" aria-hidden="true"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <button class="btn btn-primary"
          type="button"
          [disabled]="markedSelectedEntries.size === 0"
          (click)="markedSelectedEntriesRemove()">
    {{ 'info.removeEntry' | translate}}
  </button>
</div>
    <div class="col">
      <div class="overflow-auto border mb-1" style="max-height: 300px; min-height: 300px">
        <table id="availableEntries" class="table mb-0">
          <thead style="position: sticky; top:0; z-index: 100; background-color: white">
          <tr class="d-flex">
            <th class="col">Verfügbare {{ 'global.word.costcenter' | translate }}</th>
            <th class="fixed"></th>
          </tr>
          </thead>
          <tbody>
          <tr class="d-flex"
              *ngFor="let entry of availableEntries, index as i"
              [class.marked]="markedAvailableEntries.has(entry)"
              [class.pointer]="!info.showGlobal"
              (click)="markCheck(i, $event, markedAvailableEntries, availableEntries)"
              (contextmenu)="markCheck(i, $event, markedAvailableEntries, availableEntries)"
              (dblclick)="markedAvailableEntries.clear(); selectEntry(entry)">

            <td class="py-2 col">
              {{ entry }}
            </td>

            <td class="fixed pl-0 pr-2 py-2">
              <i *ngIf="getNewUnselectedEntries().has(entry)" class="fas fa-plus-circle" aria-hidden="true"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <button class="btn btn-primary"
              type="button"
              [disabled]="markedAvailableEntries.size === 0"
              (click)="markedAvailableEntriesSelect()">
        {{ 'info.add' | translate}}
      </button>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col">

    </div>
    <div class="col">
      <small
        class="form-text text-muted">{{ 'profile.description.channelManagement' | translate}}
      </small>
    </div>
  </div>

  <div class="row pb-3" id="workSequenceList" [class.disabled]="info.showGlobal || this.selectedEntries.length <= 0">
    <div class="col-6">
      {{ 'info.form.workSequenceSelection.label' | translate }}
      <br>
      <small>{{ 'info.form.workSequenceSelection.description' | translate }}</small>
    </div>
    <div class="col">
      <div class="row pt-3 pb-3">
        <div class="col">
          <div class="input-group">
          <input *ngIf="!info.showGlobal && this.selectedEntries.length > 0" type="text" class="form-control" formControlName="workSequence"/>
           <input *ngIf="info.showGlobal || this.selectedEntries.length <= 0" [disabled]="true" type="text" class="form-control" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="addWorkSequence()" [disabled]="info.showGlobal || this.selectedEntries.length <= 0">{{ 'info.add' | translate }}</button>
          </div>
          </div>
        </div>
      </div>
      <div class="overflow-auto border mb-1" style="max-height: 300px; min-height: 300px">
        <table id="availableEntries2" class="table mb-0">
          <thead style="position: sticky; top:0; z-index: 100; background-color: white">
          <tr class="d-flex">
            <th class="col">{{ 'info.selectedWorkSequences' | translate }}</th>
            <th class="fixed"></th>
          </tr>
          </thead>
          <tbody>
          <tr class="d-flex"
              *ngFor="let entry of workSequenceList, index as i"
              [class.marked]="markedWorkSequenceEntries.has(entry)"
              [class.pointer]="!info.showGlobal || workSequenceList.length > 0"
              (click)="markCheck(i, $event, markedWorkSequenceEntries, workSequenceList)"
              (contextmenu)="markCheck(i, $event, markedWorkSequenceEntries, workSequenceList)"
              (dblclick)="markedWorkSequenceEntries.clear(); removeWorkSequence(entry)">

            <td class="py-2 col">
              {{ entry }}
            </td>

            <td class="fixed pl-0 pr-2 py-2">
              <i *ngIf="getNewSelectedWorkSequences().has(entry)" class="fas fa-plus-circle" aria-hidden="true"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <button class="btn btn-primary"
              type="button"
              [disabled]="markedWorkSequenceEntries.size === 0"
              (click)="removeMarkedWorkSequences()">
        {{ 'info.removeEntry' | translate}}
      </button>
  </div>
  </div>

  <!-- Uploadbereich -->
  <div class="row pb-3">
    <div class="col-6">
      {{ 'info.detail.upload.area.title' | translate }}
      <br>
      <small>{{ 'info.detail.upload.description' | translate }}</small>
    </div>

    <div class="col">
      <op-upload-v2
        (onNewFilesSelected)="addFiles($event)"
        (onInvalidExtension)="invalidFileExtension()"
        [multiFileUpload]="true"
        [acceptedExtensions]="['*']"
        (onUploadSubmit)="saveFiles($event)"
        (onFileUploaded)="fileUploaded()"
        [useEmbeddedFileList]="false"
        [uploadV2Configuration]="uploadV2Configuration">
      </op-upload-v2>


    </div>
  </div>

  <div class="row pl-4 pb-3 pr-3">
    <div class="col-6"></div>
    <div class="col">
      <op-upload-v2-list>

      </op-upload-v2-list>
    </div>
  </div>
  <!-- Uploadbereich Ende -->


  <div class="row pl-3 pb-3 pr-3" *ngIf="id !== 'new'">
    <div class="col">
  <op-detail-section-wrapper
                             [title]="'Anhänge'"
                             [description]="'Anhänge können je nach Benachrichtungstyp an unterschiedlichen Stellen verlinkt oder visualisiert sein! - WICHTIG - Aktuell muss die Info inital erstellt werden, BEVOR es möglich ist eine Datei hochzuladen!'"
                             [contentTemplate]="contentTemplateRef"
                             [hasContent]="true"
                             [selfLink]=""
                             [collapsable]="false"
                             [contentContainerId]="'fileExplorerContainer'">
  </op-detail-section-wrapper>
  <ng-template #contentTemplateRef>
    <ng-container>
      <op-usecase-file-explorer
        usecase="infos"
        [usecasePrefix]="id"
        [searchDisabled]="true"
        [uploadDisabled]="true"
        [disabled]="this.createMode"
        [triggerReload]="reloadFileExplorerTrigger">
      </op-usecase-file-explorer>


    </ng-container>
  </ng-template>
    </div>
  </div>
    <div class="row pl-3 pb-3 pr-3">
      <div class="col-6 text-left">
        <button type="button" class="btn btn-sm btn-secondary" (click)="onCancel()">
          {{ 'global.word.abort' | translate }}</button>
      </div>
      <ng-container *ngIf="!info.hasChanged; else submit">
        <div class="col-6 text-right">
          <button type="button" class="btn btn-sm btn-primary" (click)="onClose()">
            {{ 'global.word.close' | translate }}</button>
        </div>
      </ng-container>
      <ng-template #submit>
        <div class="col-6 text-right">
          <button type="submit" class="btn btn-sm btn-primary"
                  [disabled]="form.invalid || storing || !EnterpriseRoleProviderService.hasRequiredClaimByName('news-create')">
            <span *ngIf="storing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            {{ 'global.word.save' | translate }}</button>
        </div>
      </ng-template>
    </div>
</form>
