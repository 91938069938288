<op-detail-section-wrapper
  [title]="'component-profile.measurements.title' | translate"
  [description]="'component-profile.measurements.description' | translate"
  [contentTemplate]="measurementsTemplate"
  [hasContent]="measurements != undefined && measurements.length >= 1"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId">
</op-detail-section-wrapper>

<ng-template #measurementsTemplate>
  <ng-container *ngIf="measurements != undefined && measurements.length >= 1">
    <div class="row pb-3">
      <div class="col pl-2">
        <op-excel-export [exportDataRaw]="onDataExport" (onExportRequested)="exportRequestRaised()"></op-excel-export>

        <small>Zum Exportieren der Messdaten das Icon klicken. Export wird in der Inbox bereitgestellt.</small>
      </div>
    </div>

    <div class="row">
      <div class="col pl-3">
        <ngx-treeview class="cpp-treeview-h-100"
                      [itemTemplate]="treeViewItemTemplate"
                      [config]="treeViewConfig"
                      [items]="measurementTreeViewItems">
        </ngx-treeview>
      </div>
    </div>
  </ng-container>
</ng-template>



<ng-template #treeViewItemTemplate let-treeViewItem="item" let-onCollapseExpand="onCollapseExpand">
  <div class="p-0 m-0 row" style="line-height: 19px" [class.border-bottom]="viewSizeLG" [class.mb-1]="viewSizeLG">
    <div class="col-lg-8 p-0 d-flex">
      <i *ngIf="treeViewItem.children"
         (click)="onCollapseExpand()"
         aria-hidden="true"
         class="pr-1 pb-1"
         [ngSwitch]="treeViewItem.collapsed">

        <i *ngSwitchCase="false" class="fas fa-folder-open"></i>
        <i *ngSwitchCase="true" class="fas fa-folder"></i>
      </i>

      <label *ngIf="treeViewItem.children" class="cpp-treeview-label treeViewItemGroup text-overflow-ellipses" (click)="onCollapseExpand()"> {{ treeViewItem.text }}</label>
      <label *ngIf="!treeViewItem.children" class="cpp-treeview-label form-check-label text-overflow-ellipses">
        <i class="fab fa-buromobelexperte" style="color: darkblue"></i> {{treeViewItem.text}}
      </label>
    </div>
  </div>
</ng-template>
