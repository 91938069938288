<op-detail-section-wrapper
  [title]="'component-approval-v2.title' | translate"
  [description]="'component-approval-v2.description'  | translate"
  [contentTemplate]="contentTemplateRef"
  [hasContent]="approvalSets != undefined && approvalSets.length >= 1"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <table class="table table-hover">
    <tr class="row pl-3 pr-3">
      <td class="cpp-column-fixed-width-100 p-2"></td>
      <td [class.col]="viewSizeSM" [class.cpp-column-fixed-width-270]="!viewSizeSM" class="p-2 small">
        {{'component-approval-v2.type' | translate}}
      </td>
      <td class="cpp-column-fixed-width-200 p-2 d-none d-md-inline-block small">
        {{'component-approval-v2.number' | translate}}
      </td>
      <td class="col p-2 small d-none d-xl-inline-block">
        {{'component-approval-v2.approved-parts' | translate}}
      </td>
    </tr>
    <tbody>
    <op-loading-spinner *ngIf="loading"></op-loading-spinner>
    <tr *ngIf="approvalSets.length === 0 && !loading" class="row pt-3 pb-3" >
      <div class="col pl-0 text-center">
        <small> {{ 'global.nothing-to-show' | translate }}</small>
      </div>
    </tr>
    <tr class="row pl-3 pr-3" *ngFor="let element of approvalSets">
      <td class="cpp-column-fixed-width-100 p-2 small">
        <op-component-state-badge [name]="element.InstalledPartApprovalRate"></op-component-state-badge>
      </td>
      <td [class.col]="viewSizeSM" [class.cpp-column-fixed-width-270]="!viewSizeSM" class="p-2 small">
        {{ element.ActualPartType }}
      </td>
      <td class="cpp-column-fixed-width-200 p-2 d-none d-md-inline-block small">
        {{ element.ActualPartNumber }}
      </td>
      <td class="col p-2 small d-none d-xl-inline-block" style="font-family: monospace">
        <ng-container *ngIf="element.PrimaryPartNumber">
          <span [class.cpp-component-approval-v2-selected]="isSelected(element.ActualPartNumber, element.PrimaryPartNumber)">[{{ fillMissingChars(element.PrimaryPartNumber) }}</span>
          ]</ng-container>
        <ng-container *ngFor="let a of element.AlternativePartNumbers">
        <span [class.cpp-component-approval-v2-selected]="isSelected(element.ActualPartNumber, a)"> [{{ fillMissingChars(a) }}]</span>
        </ng-container>
      </td>
    </tr>
    </tbody>
  </table>
</ng-template>
