import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UptimeServiceService {
  public static uptimeCalculationTimeout: NodeJS.Timer;
  public static startDate = new Date();
  public static upTimeInMinutes: number;
  public static upTimeInMs: number;

  public static reset() {
    clearTimeout(UptimeServiceService.uptimeCalculationTimeout);
    UptimeServiceService.startDate = new Date();
    UptimeServiceService.upTimeInMs = -1;
    UptimeServiceService.upTimeInMinutes = -1;
  }

  public static calculateUpTime() {
    UptimeServiceService.uptimeCalculationTimeout = setTimeout(() => {
      const currentDate = new Date();
      const diffInMs = currentDate.getTime() - UptimeServiceService.startDate.getTime();


      const diffInMinutes = diffInMs / 1000 / 60;
      UptimeServiceService.upTimeInMinutes = Math.round(diffInMinutes);
      UptimeServiceService.upTimeInMs = diffInMs;

      UptimeServiceService.calculateUpTime();
    }, 5000);
  }

  public static start() {
    UptimeServiceService.calculateUpTime();
  }
}
