import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {OAuthModule} from 'angular-oauth2-oidc';
import {SZO_API_MODULES, SZO_API_PROVIDERS} from '../config/szo-clients';
import { SidebarComponent } from './sidebar/sidebar.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import {NavbarTopComponent} from './navbar-top/navbar-top.component';
import {FooterComponent} from './footer/footer.component';
import {LayoutModule} from '@angular/cdk/layout';
import {FormsModule} from '@angular/forms';
import {OverlayModule} from '@angular/cdk/overlay';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { LoggingComponent } from './logging/logging/logging.component';
import { PermissionMissingComponent } from './component/permission-missing/permission-missing.component';

@NgModule({
  declarations: [SidebarComponent, NavbarTopComponent, FooterComponent, LoggingComponent, PermissionMissingComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        OAuthModule.forRoot(),
        SZO_API_MODULES,
        RouterModule,
        TranslateModule,
        SharedModule,
        LayoutModule,
        FormsModule,
        OverlayModule,
        TooltipModule
    ],
  exports: [
    SidebarComponent,
    NavbarTopComponent,
    FooterComponent,
    LoggingComponent,
    PermissionMissingComponent
  ],
  providers: [
    SZO_API_PROVIDERS
  ]
})
export class CoreModule {
}
