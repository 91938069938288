import {Component, Input, OnInit} from '@angular/core';
import {Measurement} from '@cstx/volkswagen-mqs-parsing-service-client';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {MeasurementControllerService, MeasurementResponse} from '@cstx/volkswagen-mqs-work-sequence-service-client';
import {ManufacturingProcessServiceService} from '../../../services/manufacturing-process-service.service';
import {TranslateService} from '@ngx-translate/core';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Subject} from 'rxjs';
import {ExcelExportRaw} from '../../excel-export/excelExport';

@Component({
  selector: 'op-measurements',
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.scss']
})
export class MeasurementsComponent implements OnInit {
  @Input() componentId: string;
  @Input() selfLink: string;
  @Input() contentContainerId: string;


  public treeViewConfig: TreeviewConfig;
  public loading = false;
  public viewSizeLG: boolean;
  public measurements: MeasurementResponse[];
  public measurementTreeViewItems = new Array<TreeviewItem>();
  public onDataExport =  new Subject<ExcelExportRaw>();

  constructor(private manufacturingProcessService: ManufacturingProcessServiceService,
              private measurementService: MeasurementControllerService,
              private translate: TranslateService, private breakpointObserver: BreakpointObserver) { }

  async ngOnInit(): Promise<void> {
    this.breakpointObserver
      .observe(['(max-width: 992px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.viewSizeLG = true;
        } else {
          this.viewSizeLG = false;
        }
      });

    this.treeViewConfig = this.createTreeViewConfig();
    this.loading = true;


    this.measurementService.getMeasurementsByComponentId(
      {
        id: this.componentId
      }
    ).toPromise().then(result => {
      this.measurements = result;

      this.createMeasurementsTree();
    }).finally(() => {
      this.loading = false;
    });
  }


  private createTreeViewConfig() {
    return    TreeviewConfig.create({
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
    });
  }


  private createMeasurementsTree() {
    this.measurements.forEach(response => {
      const treeViewItem = this.createTreeViewItem(response);

      if (treeViewItem !== null) {
        this.measurementTreeViewItems.push(treeViewItem);
      }
    });
  }

  private createTreeViewItem(measurementResponse: MeasurementResponse, parent?: TreeviewItem): TreeviewItem {
    let item: TreeviewItem;
    if (parent === null || parent === undefined) {
      item = new TreeviewItem( { text: measurementResponse.origin, value: measurementResponse });
    }

    let childItem: TreeviewItem;
    measurementResponse.measurements.forEach(measurement => {

      if (measurement.station === null) {
        return;
      }

      childItem = this.createChildTreeViewItems(item, this.trimZeros(measurement.station));

      childItem = this.createChildTreeViewItems(childItem, this.trimZeros(measurement.measurementGroup));
      childItem = this.createChildTreeViewItems(childItem, this.trimZeros(measurement.measurementSubGroup));

      childItem = this.createChildTreeViewItems(childItem, measurement.measurementName + ': ' + measurement.measurementValue);
    });

    if (!item.children) {
      return null;
    }

    return item;
  }

  private createChildTreeViewItems(parent: TreeviewItem, childItemText: string): TreeviewItem {
    let child = new TreeviewItem({ text: childItemText, value: childItemText });

    if (parent.children === undefined) {
      parent.children = Array<TreeviewItem>(child);
    }
    else {

      if (parent.children.findIndex(i => i.text === childItemText) === -1) {
        parent.children.push(child);
      }
      else {
        child = parent.children.find(i => i.text === childItemText);
      }
    }

    return child;
  }

  private trimZeros(value: string): string {
    return value.replace(/ 0+/, ' ')
  }

  public exportRequestRaised() {
    const rawData = new ExcelExportRaw();
    rawData.title = 'Measurements';

    const combined = new Array<MeasurementWithComponentName>();
    this.measurements.forEach(m => {
      m.measurements.forEach(mt => {
        combined.push(new MeasurementWithComponentName(m.componentName, mt));
      });
    });

    rawData.data = combined;
    this.onDataExport.next(rawData);
  }
}

export class MeasurementWithComponentName implements Measurement {
  componentName: string;
  processingTimestamp?: string | null;
  processingName?: string | null;
  station?: string | null;
  measurementGroup?: string | null;
  measurementSubGroup?: string | null;
  measurementName?: string | null;
  measurementValue?: string | null;

  constructor(componentName: string, measurement: Measurement) {
    this.componentName = componentName;

    this.processingTimestamp = measurement.processingTimestamp;
    this.processingName = measurement.processingName;
    this.station = measurement.station;
    this.measurementGroup = measurement.measurementGroup;
    this.measurementSubGroup = measurement.measurementSubGroup;
    this.measurementName = measurement.measurementName;
    this.measurementValue = measurement.measurementValue;

  }
}
