import {FilterBase} from '../../../shared/models/filterBase';
import {Slice} from '../../../shared/models/slice';
import {DestinationSystem} from "@cstx/volkswagen-mqs-onpremise-procedure-service-client/model/destinationSystem";
import {SourceService} from "@cstx/volkswagen-mqs-onpremise-procedure-service-client/model/sourceService";
import {ProcedureState} from "@cstx/volkswagen-mqs-onpremise-procedure-service-client";

export class OpsFilter extends FilterBase {
  procedureId: string;
  public slice: Slice;
  procedureName?: string;
  destinationSystem?: DestinationSystem;
  sourceService?: SourceService;

  procedureRoutingInfo?: string;

  state?: ProcedureState;

  constructor() {
    super();

    this.pagingSize = 100;
    this.totalPages = 1;
    this.currentPageIndex = 0;
    this.sort = [];
  }

  public IsEmpty(): boolean {
    if (this.procedureName && this.procedureName !== '') {
      return false;
    }

    if (this.destinationSystem) {
      return false;
    }

    if (this.sourceService) {
      return false;
    }

    if (this.procedureRoutingInfo && this.procedureRoutingInfo !== '') {
      return false;
    }

    if (this.state) {
      return false;
    }

    if (this.procedureId) {
      return false;
    }

    return true;
  }

  public getAllParams() {
    return {...this.getPageableParams(), ...this.getFilterParams()};
  }

  public getFilterParams() {
    if (this.IsEmpty()) {
      return {};
    }
    return {
      procedureName: this.procedureName,
      destinationSystem: this.destinationSystem,
      sourceService: this.sourceService,
      procedureRoutingInfo: this.procedureRoutingInfo,
      state: this.state,
      procedureState: this.state,
      procedureId: this.procedureId

    };
  }

  public getPageableParams() {
    return {
      page: this.currentPageIndex,
      size: this.pagingSize,
      sort: this.sort,
    };
  }
}
