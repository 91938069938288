import {Injectable} from '@angular/core';
import {FileControllerService, UseCaseForm} from '@cstx/volkswagen-mqs-file-handling-service-client';
import {Subject} from 'rxjs';
import {InboxEntry} from '../../modules/user/inbox/inboxEntry';
import {ToastrService} from 'ngx-toastr';
import {ConfigService} from '../../core/services/config.service';
import {AuthService} from '../../core/services/auth.service';
import {VisibilityService} from '../../core/services/visibility.service';
import {LocalStorageManagerService} from "./local-storage-manager.service";

@Injectable({
  providedIn: 'root'
})
export class InboxService {

  constructor(private fileControllerService: FileControllerService, private toastr: ToastrService, private configService: ConfigService,
              private auth: AuthService, private visibilityService: VisibilityService) {
  }

  public onInboxItemsChanged = new Subject<any>();
  public onUnReadCounterChanged = new Subject<number>();

  private inbox = new Array<InboxEntry>();

  private isUpdating: boolean;
  private windowVisible: boolean;
  private updateTimeout: any;
  private unReadCount = 0;

  private readonly GET_INBOX_INTERVAL = 15000;

  public static setLastInboxVisit() {
    LocalStorageManagerService.setItem('lastInboxVisit', new Date().toISOString());
  }
  private static getLastInboxVisit(): Date {
    let lastInboxVisit = new Date(LocalStorageManagerService.getItem('lastInboxVisit'));

    if (!lastInboxVisit) {
      lastInboxVisit = new Date();
      InboxService.setLastInboxVisit();

    }

    return lastInboxVisit;
  }
  private static isUnread(receivedAt: string) {
    const received = new Date(receivedAt);
    const visited = InboxService.getLastInboxVisit();

    return received.toISOString() > visited.toISOString();


  }
  private static getInboxEntryIsMessageOnly(S3Key: string) {
    const extension = S3Key.split('.').pop();
    return extension === 'txt';
  }
  private static getInboxFromLocalStorage(): InboxEntry[] {
    let localInbox = JSON.parse(LocalStorageManagerService.getItem('inbox'));

    if (localInbox === null) {
      localInbox = new Array<InboxEntry>();
    }

    return localInbox;
  }
  private static setInboxToLocalStorage(inbox: InboxEntry[]) {
    LocalStorageManagerService.setItem('inbox', JSON.stringify(inbox));
  }

  public syncInbox() {
    this.update();
  }

  public init() {
    if (this.auth.isApplicationUser) {
      return;
    }

    this.visibilityService.pageVisible$.subscribe(visibility => this.windowVisible = visibility);

    this.auth.isAuthorized().then(() => {
      this.update();
    });

    this.initToastrServiceInstance();

    this.updateTimeout = setInterval(() => {
         if (this.windowVisible) {
         this.update();
       }}, this.GET_INBOX_INTERVAL);
  }

  public getInbox() {
    return InboxService.getInboxFromLocalStorage();
  }

  public markRead(s3key: string) {
    // Check if an update is currently in progress.
    if (this.isUpdating) {
      setTimeout(() => {
        this.deleteItem(s3key);
      }, 1000);
    }

    this.isUpdating = true;
    const item = this.inbox.find(i => i.S3Key === s3key);

    if (!item) { return; }
    item.unRead = false;

    InboxService.setInboxToLocalStorage(this.inbox);
    this.isUpdating = false;
    this.onInboxItemsChanged.next(null);

    this.unReadCount = 0;
    this.inbox.forEach(i => {
      if (i.unRead) { this.unReadCount = this.unReadCount + 1; }
    });

    this.onInboxItemsChanged.next(null);
    this.onUnReadCounterChanged.next(this.unReadCount);
  }
  public deleteItem(s3key: string) {
    const itemIndex = this.inbox.findIndex(i => i.S3Key === s3key);
    if (itemIndex === -1 ) {
      return;
    }

    // Check if an update is currently in progress.
    if (this.isUpdating) {
      setTimeout(() => {
        this.deleteItem(s3key);
      }, 200);
    }

    // Start inbox update
    this.isUpdating = true;
    this.fileControllerService.deleteFileV2({
        usecase: 'inbox',
        s3key
      }).toPromise()
        .then(() => {
          this.inbox.splice(this.inbox.findIndex(i => i.S3Key === s3key), 1);

          InboxService.setInboxToLocalStorage(this.inbox);

          this.onInboxItemsChanged.next(null);
        })
        .finally(() => {
          // Stop update after tried deletion and then update the whole inbox
          this.isUpdating = false;
        });
    }

  private update() {
    if (!this.auth.currentUser) { return; }
    if (this.isUpdating) { return; }
    if (this.auth.isApplicationUser) { return; }

    this.isUpdating = true;
    this.inbox = InboxService.getInboxFromLocalStorage();

    this.fileControllerService.getFilesForUseCase({
      useCase: 'inbox'
    })
      .toPromise()
      .then(inboxResponse => {
        const remoteInbox = new Array<InboxEntry>();

        inboxResponse.Contents.forEach(remoteEntry => {
          const localEntryIndex = this.inbox.findIndex(localEntry => localEntry.S3Key === remoteEntry.S3Key);

          if (localEntryIndex === -1) {
              remoteInbox.push(this.mapToInboxItem(remoteEntry));
          }
        });

        this.inbox = remoteInbox.concat(this.inbox);

        this.inbox.sort((a, b) => {
          if (a.Received > b.Received) {
            return -1;
          } else {
            return 1;
          }
        });

        InboxService.setInboxToLocalStorage(this.inbox);

        this.getUnreadCount();

        this.onInboxItemsChanged.next(null);
        this.onUnReadCounterChanged.next(this.unReadCount);

      })
      .finally(() => {
      this.isUpdating = false;
    });
  }
  private getDownloadLink(item: UseCaseForm): string {
    let fhsUrl = this.configService.getFhsApiUrl();
    const apiSuffix = '/v2/public/file?usecase=inbox&s3key=' + encodeURIComponent(item.S3Key) + '&token=' + item.Metadata.Token;

    fhsUrl = fhsUrl + apiSuffix;
    return fhsUrl;
  }
  private mapToInboxItem(form: UseCaseForm): InboxEntry {
    const entry = new InboxEntry();

    entry.Received      = form.LastModified;
    entry.S3Key         = form.S3Key;
    entry.DownloadLink  = this.getDownloadLink(form);
    entry.IsMessageOnly = InboxService.getInboxEntryIsMessageOnly(form.S3Key);

    entry.unRead        = InboxService.isUnread(entry.Received);

    if (entry.unRead) {
      this.unReadCount = this.unReadCount + 1;
    }

    // Map Metadata
    entry.Origin      = form.Metadata.Origin;
    entry.Source      = form.Metadata.Source;
    entry.Description = form.Metadata.Description;
    entry.Subject     = form.Metadata.Subject;


    return entry;
  }
  private getUnreadCount() {
    this.unReadCount = 0;
    this.inbox.forEach(i => {
      if (i.unRead) { this.unReadCount = this.unReadCount + 1; }
    });
  }
  private initToastrServiceInstance() {
    const currentToastRConfig = this.toastr.toastrConfig;
    currentToastRConfig.autoDismiss = true;
    currentToastRConfig.progressBar = true;
    currentToastRConfig.enableHtml = true;
    currentToastRConfig.closeButton = true;

    this.toastr.toastrConfig = currentToastRConfig;
  }
}
