<div class="table-borderless">
  <div class="row small" *ngIf="uploadV2Service.files.length > 0">
    <div class="col font-weight-bold">
      {{ 'upload-v2-list.title' | translate }}
    </div>
  </div>
  <ng-container *ngFor="let file of uploadV2Service.files; index as i">
    <div class="row small">
      <div class="col" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
        {{ file.name }}
      </div>
      <div class="col" *ngIf="file.uploadProgress > 0">

        <div class="progress position-relative bg-secondary">
          <div class="progress-bar text-center" role="progressbar"
               [ngStyle]="{'width.%': file.uploadProgress, 'background-color':  'bg-primary' }">
            <div class="justify-content-center d-flex position-absolute w-100" [ngStyle]=" { 'color': 'white','font-weight': 'bold' }">
              <div class="col text-monospace" style="padding-right: 15px">
                {{ file.uploadProgress + ' %'}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cpp-column-fixed-width-140">
        {{ file.size }}
      </div>
      <div class="cpp-column-fixed-width-50">
        <ng-container *ngIf="file.uploadFinished; else uploadPendingTemplate">
          <i class="fas"
             [class.fa-check-circle]="!file.uploadFailed"
             [class.fa-exclamation-circle]="file.uploadFailed"
             [ngStyle]="{'color': file.uploadFailed ? 'darkred' : 'darkgreen'}"></i>
        </ng-container>
        <ng-template #uploadPendingTemplate>
          <i class="far fa-hourglass" [class.fa-spin]='file.uploadProgress > 0' style="color: darkgray"></i>
        </ng-template>
      </div>
      <div class="cpp-column-fixed-width-50">
        <i *ngIf="!file.uploadFinished && !uploadV2Service.config.autoUpload"
           class="fas fa-trash-alt pointer"
           (click)="uploadV2Service.removeFile(i)">
        </i>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="uploadV2Service.config.debug && !uploadV2Service.config.autoUpload" (click)="upload()">
  <i class="fas fa-upload pointer"></i>
</div>
