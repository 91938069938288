import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'op-delete-entry-with-confirmation',
  templateUrl: './delete-entry-with-confirmation.component.html',
  styleUrls: ['./delete-entry-with-confirmation.component.scss']
})
export class DeleteEntryWithConfirmationComponent {
  @Input() securityQuestion: string;
  @Input() errorMessage: string;
  @Input() trashIconClasses: string;
  @Input() disabled = false;
  @Input() hidden = false;

  @Output() onCancellation = new EventEmitter();
  @Output() onConfirmation = new EventEmitter();
  @Output() onDelete = new EventEmitter();


  public deletePending: boolean;

  public iconClass(): string {
    if (this.trashIconClasses) {
      return this.trashIconClasses + (this.disabled ? ' text-muted no-pointer' : '');
    }

    return 'fas fa-trash small'+ (this.disabled ? ' text-muted no-pointer' : '');
  }

  onDeleteClick() {
    if (this.disabled) {
      return;
    }
    this.onDelete.emit();
    this.deletePending = true;
    this.errorMessage = undefined;
  }
}
