
<ng-container *ngIf="!version2 && pathElements && pathElements.length > 0; else pathElementsV2Template">
<ng-container *ngFor="let element of pathElements; let i = index">
  <img *ngIf="i === 0" src="assets/img/logo.png" height="12" width="12" alt="VWLOGO">

  <small class="text-overflow-ellipses pre-white">


    <a *ngIf="i < pathElements.length - 1" class="text-overflow-ellipses  pl-1" [routerLink]="getRouterLink(i)">
      {{ itemAliases?.length > i ? itemAliases[i] : element }}
    </a>

    <ng-container *ngIf="i === pathElements.length - 1">
      {{ lastItemAlias ? lastItemAlias : element }}
    </ng-container>

    <i *ngIf="i < pathElements.length - 1" class="fas fa-angle-right pl-1 pr-1"></i>
  </small>
</ng-container>
</ng-container>


<ng-template #pathElementsV2Template>
  <ng-container *ngIf="version2 && pathElementsV2 && pathElementsV2.length > 0">
    <ng-container *ngFor="let element of pathElementsV2">
      <img *ngIf="element.index === 0" src="assets/img/logo.png" height="12" width="12" alt="VWLOGO">

      <small class="text-overflow-ellipses pre-white">



      <a *ngIf="element.index < pathElements.length - 1" class="text-overflow-ellipses  pl-1" [routerLink]="element.link">
        {{ element.translation ? element.translation : element.name }}
      </a>

      <ng-container *ngIf="element.index === pathElements.length - 1">
        {{ element.translation ? element.translation : element.name }}
      </ng-container>

      <i *ngIf="element.index < pathElements.length - 1" class="fas fa-angle-right pl-1 pr-1"></i>
      </small>


  </ng-container>
    <ng-container *ngIf="loadingEvent">
      <div class="float-right small text-monospace loading" [class.failed]="loadingEvent.timeOutOccurred" [class.success]="loadingEvent.loadingDone && !loadingEvent.timeOutOccurred">
        <ng-container *ngIf="!loadingEvent.loadingDone">{{ 'breadcrumb.loading' | translate }} {{  loadingEvent.getDurationInSeconds() }}s</ng-container>

        <ng-container *ngIf="loadingEvent.loadingDone && loadingEvent.timeOutOccurred">{{ 'breadcrumb.loading-timeout' | translate }} {{  loadingEvent.getTimeOutInSeconds() }}s</ng-container>
        <ng-container *ngIf="loadingEvent.loadingDone && !loadingEvent.timeOutOccurred">{{ 'breadcrumb.loaded' | translate }} {{  loadingEvent.getDurationInSeconds() }}s</ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
