import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {ConfigService, PORTALMODE} from "../../core/services/config.service";

@Directive({
  selector: '[opCpoOnly]'
})
export class CpoOnlyDirective implements OnInit{

  @Input() opCpoOnly: boolean;
  isVisible = false;

  constructor(private viewContainerRef: ViewContainerRef,
              private templateRef: TemplateRef<any>,
              private configService: ConfigService) { }


  ngOnInit() {
      if (this.opCpoOnly) {

        if (this.configService.getPortalMode() === PORTALMODE.CPO) {
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
          this.isVisible = false;
          this.viewContainerRef.clear();
        }
      }
  }
}
