import {EventEmitter, Injectable} from '@angular/core';
import {FavoriteMenuEventArgs} from './actions/actions.component';
import {EngineService} from '../../services/backend/engine.service';
import {ComponentReleaseWizardService} from '../component-release-wizard/component-release-wizard.service';
import {BlockingDetail} from '../component-state-check/component-state-check.component';
import {BlockingActionResponse} from '@cstx/volkswagen-mqs-engine-service-client';
import {PartService} from '../../services/backend/part.service';
import {ComponentBlockWizardService} from '../component-block-wizard/component-block-wizard.service';
import {ComponentProfileActionType} from './actions/component-profile-action-type';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {BackendService} from '../../services/backend/models/backend-service';

@Injectable({
  providedIn: 'root'
})
export class ComponentProfileService {
  public static blockingActions: BlockingActionResponse[];

  public static onBlockingActionsLoaded = new EventEmitter();
  public static onComponentActionExecuted = new EventEmitter<ComponentProfileActionType>();
  public static onComponentActionCanceled = new EventEmitter<ComponentProfileActionType>();
  public static onComponentReloadRequest = new EventEmitter();

  private static currentComponentId: string;
  private static currentComponentName: string;
  private static currentComponentService: BackendService;

  public onExpandCollapse = new EventEmitter<FavoriteMenuEventArgs>();
  public toggleExpandCollapse = new EventEmitter<string>()
  public toggleActiveAction = new EventEmitter<FavoriteMenuEventArgs>()


  constructor() {
    this.onExpandCollapse.subscribe(e => {
      if (e.Favorite === 'actions') {
        this.toggleActiveAction.emit(e);
      }

      this.toggleExpandCollapse.emit(e.Favorite);
    });

    ComponentProfileService.onComponentActionExecuted.subscribe(async action => {
      if (action === ComponentProfileActionType.RELEASE
        || action === ComponentProfileActionType.BLOCK) {

        await ComponentProfileService
          .loadBlockingActions(
            ComponentProfileService.currentComponentId,
            ComponentProfileService.currentComponentName,
            ComponentProfileService.currentComponentService
          );

        ComponentProfileService.onComponentReloadRequest.emit();
      }
    });

    ComponentProfileService.onComponentActionCanceled.subscribe(async action => {

    })
  }

  public static async componentActionExecuted(action: ComponentProfileActionType, waitInMs: number = 2500) {
    LoggingService.logDebug(LoggingSource.COMPONENT_PROFILE_SERVICE,
      `A action for component [${ComponentProfileService.currentComponentName}] of type [${action}] was executed.`);

    setTimeout(() => {
      ComponentProfileService.onComponentActionExecuted.emit(action);
    }, waitInMs);
  }

  public static async componentActionCanceled(action: ComponentProfileActionType, waitInMs: number = 2500) {
    LoggingService.logDebug(LoggingSource.COMPONENT_PROFILE_SERVICE,
      `A action for component [${ComponentProfileService.currentComponentName}] of type [${action}] was canceled.`);

    ComponentProfileService.onComponentActionCanceled.emit(action);
  }

  public static async loadBlockingActions (componentId: string, componentName: string, service: BackendService): Promise<boolean> {
    if (!service) {
      return;
    }

    ComponentProfileService.reset();

    ComponentBlockWizardService
      .addComponentToBlock(componentName);

    ComponentProfileService.currentComponentId = componentId;
    ComponentProfileService.currentComponentName = componentName;
    ComponentProfileService.currentComponentService = service;

    LoggingService.logDebug(LoggingSource.COMPONENT_PROFILE_SERVICE,
      `Blockings for component [${ComponentProfileService.currentComponentName}] will be loaded.`);


    let result = false;
    if (service instanceof PartService) {
        result =  await ComponentProfileService.loadPartBlockingActions(componentId, componentName, service);
    }

    if (service instanceof EngineService) {
      result = await ComponentProfileService.loadEngineBlockingActions(componentId, componentName, service);
    }

    LoggingService.logDebug(LoggingSource.COMPONENT_PROFILE_SERVICE,
      `Blockings for component [${ComponentProfileService.currentComponentName}] has been loaded.`);

    ComponentProfileService.onBlockingActionsLoaded.emit();

    return result;
  }

  private static async loadEngineBlockingActions (componentId: string, componentName: string, service: EngineService): Promise<boolean> {
    const response = await service.getBlockingActions(componentId);

    if (response && response.length > 0) {
      ComponentReleaseWizardService.reset();

      ComponentProfileService.blockingActions = response;
      const blockingDetails
        = response.map(blockingResponse => new BlockingDetail(blockingResponse));

      ComponentReleaseWizardService
        .addBlocking(blockingDetails, componentName);

      return true;
    }

    return false;
  }

  private static async loadPartBlockingActions (componentId: string, componentName: string, service: PartService): Promise<boolean> {
    const response = await service.getBlockingActions(componentId);

    if (response && response.length > 0) {
      ComponentReleaseWizardService.reset();

      ComponentProfileService.blockingActions = response;

      const blockingDetails
        = response.map(blockingResponse => new BlockingDetail(blockingResponse));

      ComponentReleaseWizardService
        .addBlocking(blockingDetails, componentName);

      return true;
    }

    return false;
  }

  private static reset() {
    ComponentProfileService.currentComponentId = undefined;
    ComponentProfileService.currentComponentName = undefined;
    ComponentProfileService.currentComponentService = undefined;
    ComponentProfileService.blockingActions = undefined;

    LoggingService.logDebug(LoggingSource.COMPONENT_PROFILE_SERVICE, 'ComponentProfileService reset occurred.');
  }
}
