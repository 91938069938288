import {EventEmitter, Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {AuthService} from './auth.service';
import {ConfigService} from './config.service';
import {ApplicationControllerService} from '@cstx/volkswagen-mqs-application-management-service-client';
import {NotificationService} from '../../shared/services/backend/notification.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketsService {
  public static onConnect = new EventEmitter<any>();
  public static onDisconnect = new EventEmitter<any>();
  public static onError = new EventEmitter<Event>();
  public static onMessageReceived = new EventEmitter<WebSocketMessage>();

  public webSocketClients = new Array<string>();
  public webSocketClientsUpdating =  false;

  private socket: WebSocket;

  public constructor(private notificationService: NotificationService,
                     private authService: AuthService,
                     private configService: ConfigService,
                     private applicationControllerService: ApplicationControllerService) {}

  public send(data: string) {
    this.socket.send(data);
  }

  public close() {
    this.socket.close();
  }



  public async open() {
    if (this.authService.isApplicationUser) {
      firstValueFrom(this.applicationControllerService.getSelf({})).then(res => {
        if ( res.scopes.findIndex(scope => scope.service === 'NOTIFICATION_SERVICE' && scope.canRead ) > -1 ) {
          this.initSocket();
        }
      });
    } else {
      await this.initSocket();
    }

    this.getActiveWebSocketClients();
    setInterval(() => {
      this.getActiveWebSocketClients();
    }, 5000 )
  }

  public getActiveWebSocketClients() {
    if (this.webSocketClientsUpdating) {
      return;
    }

    this.webSocketClientsUpdating = true;

    this.notificationService
      .getWebSocketClients()
      .then(response => {
      if (response) {

        response
          .sort((a, b) => a.localeCompare(b));

        this.webSocketClients = response;
      }

        this.webSocketClientsUpdating = false;

      });
  }

  private async initSocket() {
    const token = await this.notificationService.getWebSocketToken()

    const nnsUrl = this.configService.getNnsApiUrl().replace('http', 'ws') + '/ws';
    this.socket = new WebSocket(nnsUrl);

    this.socket.onopen = event => {
      const initializationMessage = this.authService.currentUser?.email + '_' + token;
      this.send(initializationMessage);

      WebsocketsService.onConnect.emit();
    };
    this.socket.onclose = event => {
      WebsocketsService.onDisconnect.emit();

    };
    this.socket.onmessage = event => {
      let message: WebSocketMessage;
      message = JSON.parse(event.data);

      WebsocketsService.onMessageReceived.emit(message);
    };

    this.socket.onerror = event => {
      WebsocketsService.onError.emit(event);
    }

    /**
     * This is currently to prevent self shutdown of the connection
     * as the used server has ping/pong handler function, there could by a better
     * way of achieving this.
     */
    setInterval(() => {
      if (this.socket.readyState === 1)
        this.send('ping_' + this.authService.currentUser?.email);
      }, 5000);
  }
}

export class WebSocketMessage {
  public subject?: string;
  public message?: string;
  public origin?: string;
  public receivedAt?: string;
}
