
import {ProductCenterResponse} from '@cstx/volkswagen-mqs-metadata-service-client';
import {CostCenter} from "./cost-center";

export class ProductCenter {
  public id: string;
  public name: string;
  public shortName: string;
  public modifiedDate: string;
  public modifiedBy: string;
  public isDeactivated: boolean;

  public costCenters: CostCenter[] = []; // TODO: Replace with new costCenter model after merging costCenter Modells

  public get isNew() {
    if (this.id) {
      return false;
    }

    return true;
  }
  constructor(productCenterResponse?: ProductCenterResponse) {
    if (productCenterResponse) {
      this.mapFromProductCenterResponse(productCenterResponse);
    }
  }

  public mapFromProductCenterResponse(productCenterResponse: ProductCenterResponse): ProductCenter {
    this.id = productCenterResponse.id;
    this.name         = productCenterResponse.name;
    this.modifiedDate = productCenterResponse.modifiedAt;
    this.shortName    = productCenterResponse.shortName;
    this.modifiedBy   = productCenterResponse.modifiedBy;
    this.isDeactivated = productCenterResponse.isDeactivated;

    return this;
  }
}
