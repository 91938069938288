import {PartResponse, TargetPartResponse} from '@cstx/volkswagen-mqs-part-service-client';
import {InstalledPartApprovalRate} from './InstalledPartApprovalRate';

export class ComponentApprovalPartSet {
  constructor() {
     this.InstalledPartApprovalRate = InstalledPartApprovalRate.UNKNOWN_PART;
  }


  PrimaryPartNumber: string;
  ActualPartNumber: string;
  ActualPartType: string;
  InstalledPartApprovalRate: InstalledPartApprovalRate;
  AlternativePartNumbers = new Array<string>();

}
