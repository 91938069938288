<select [ngClass]="{
    'text-light': !filterDecoration,
    'bg-dark': !filterDecoration,
    'form-control': filterDecoration,
    'form-select': filterDecoration,
    'input-group': filterDecoration}" (change)="onSelect($event)" [disabled]="disabled">
  <ng-container *ngFor="let option of options">
    <option [value]="option.value" [disabled]="isSelected(defaultLabel, option.label)" [selected]="isSelected(defaultLabel, option.label)">{{ getOptionsLabelText(option.label) }}</option>
  </ng-container>
</select>
