import {Injectable, isDevMode} from '@angular/core';
import {Info} from './info';
import {
  UserInformationCreateRequest,
  UserInformationResponse,
  UserInformationResponseSliceResponse,
  UserInformationService,
  UserInformationType
} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {InfoFilter} from './infoFilter';
import {firstValueFrom} from 'rxjs';
import {PdfTemplateType} from '../../../shared/services/pdf-creation/PdfTemplateType';
import {PdfCreatorService} from '../../../shared/services/pdf-creation/pdf-creator.service';
import {FileExplorerService} from '../../../shared/components-merged/usecase-file-explorer/file-explorer.service';
import {ToastrService} from 'ngx-toastr';
import {ErrorHandler} from '../../../shared/services/error-handler/error-handler';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';

@Injectable({
  providedIn: 'root'
})
export class InfosService {
  private filter = new InfoFilter();

  constructor(private userInformationService: UserInformationService,
              private pdfCreatorService: PdfCreatorService,
              private fileExplorerService: FileExplorerService,
              private toastrService: ToastrService) { }



  /**
   *
   * @param id The id for the information we want to load from backend.
   * @public
   * @async
   *
   * The function is trying to load the information for the provided id.
   *
   * @return The function is returning an awaitable promise of type UserInformationResponse.
   *
   */
  public async getInfoById(id: string): Promise<UserInformationResponse> {
    const result: UserInformationResponse = await firstValueFrom(this.userInformationService.getUserInformation({
      id
    }));

    return result;
  }

  /**
   *
   * @param filter A filter to only find the needed Infos in backend service.
   * @public
   * @async
   *
   * The function is trying to load all the infos fitting the provided filter.
   *
   * @return The function is returning an awaitable promise of type UserInformationResponseSliceResponse.
   * Paging is supported but currently NOT IMPLEMENTED!
   *
   */
  public async getInfos(filter?: InfoFilter): Promise<UserInformationResponseSliceResponse> {
    try {
      if (filter === undefined || filter === null) {
        filter = this.filter;
      }

      const result: UserInformationResponseSliceResponse
        = await firstValueFrom(this.userInformationService.getUserInfos(filter.getAllParams()));

      return result;
    } catch (error) {
      LoggingService.logError(LoggingSource.CORE, `Error occurred requesting infos.`, error)
    }

    return null;
  }

  /**
   *
   * @param infos An infos array which needs to be filtered furter.
   * @param filter A filter to filter the given array of Infos
   * @public
   * @async
   *
   * The function is filtering an array of Infos locally. It is needed as long as the backend is not capable of filtering
   * on all criteria needed.
   *
   * @return The function returns a new Info Array only containing all not filtered Infos.
   *
   */
  public filterLocal(infos: Info[], filter: InfoFilter): Info[] {
    if (filter.isActive) {
      infos = infos.filter(i => i.isActive === filter.isActive);
    }

    if (filter.isPublished) {
      infos = infos.filter(i => i.isPublished === filter.isPublished);
    }

    if (filter.informationType) {
      infos = infos.filter(i => i.informationType === filter.informationType);
    } else if (filter.informationTypes.length > 0) {

      let typedInfos = new Array<Info>();
      filter.informationTypes.forEach(type => {
        typedInfos = typedInfos.concat(infos.filter(e => e.informationType === type));
      });

      infos = typedInfos;
    }

    if (filter.costCenter && filter.costCenter !== '') {
      infos = infos.filter(i => i.costCenters.includes(filter.costCenter) || i.showGlobal);
    }

    if (filter.workSequence && filter.workSequence !== '') {
      infos = infos.filter(i => i.workSequences.includes(filter.workSequence));
    }

    return infos;
  }

  /**
   *
   * @param info The info we want to be created or updated.
   * @public
   * @async
   *
   * The function is creating or updating the info provide in the backend.
   *
   *
   */
  public async createOrUpdateInfo(info: Info): Promise<UserInformationResponse> {
    try {
      let request: UserInformationCreateRequest;
      let result: UserInformationResponse;
      if (info.id) {
        request = info.mapToUserUpdateRequest();

        result = await firstValueFrom(this.userInformationService.updateUserInfo({
          userInformationUpdateRequest: request
        }));

      } else {
        request = info.mapToUserCreateRequest();

        result = await firstValueFrom(this.userInformationService.createUserInfo({
          userInformationCreateRequest: request
        }));
      }

      return result;

    } catch (ex) {
      if (isDevMode()) {
        console.log(new Date().toLocaleString() + ': Error occurred on createOrUpdateInfo: ' + ex);
      }

      return null;
    }
  }

  /**
   *
   * @param id The id for the information we want to delete from backend.
   * @public
   * @async
   *
   * The function is trying to delete the information for the provided id.
   *
   * @return The function is returning an awaitable promise of type boolean, indicating operation success or failure.
   *
   */
  public async deleteInfo(id: string): Promise<boolean> {
    const result: boolean = await firstValueFrom(this.userInformationService.deleteUserInformation({
      id
    }));

    const infoFileList =
      await this.fileExplorerService.GetFilesForUseCase('infos', 1, 240, false, id);

    for (const file of infoFileList) {

      LoggingService.logInfo(LoggingSource.CORE, `Deleting [${file.s3Key}] for info [${id}].`)
      const deleted = await this.fileExplorerService.DeleteFromUseCase(file.s3Key, 'infos');

      if (!deleted) {
        LoggingService.logError(LoggingSource.CORE, `File deletion for [${file.s3Key}] failed.`)
      }
    }


    return result;
  }


  /**
   *
   * @param info The information a pdf should be created for
   * @public
   * @async
   *
   * This function create a pdf based on the info and the informationType.
   * The function is returning a blob with blobParts and options (mimetpe)
   *
   */
  public async makePdfAsync(info: Info): Promise<Blob> {
    try {

      let templateType = PdfTemplateType.UNKNOWN;

      if (info.informationType === UserInformationType.Stopnotification) {
        templateType = PdfTemplateType.STOP_NOTIFICATION_INFO;

        const filePromise   = await this.fileExplorerService.GetUseCaseFile('infos', info.fileList[0].s3Key);
        const buffer        = await new Response(filePromise).arrayBuffer();
        const bufferString  = this.arrayBufferToString(buffer)

        const args = [
          info,
          bufferString
        ]

        const file = await this.pdfCreatorService.createPdfAsync(templateType, args);
        return new Blob([file.pdf], {type: 'application/pdf'});
      }

    } catch (error) {

      LoggingService.logError(LoggingSource.INFO_SERVICE,
        `Error detected in creating pdf for stop-sign info.`, error);
      return null;
    }
  }

  /**
   *
   * @param info The information a pdf should be created for
   * @public
   * @async
   *
   * This function create a pdf based on the info and the informationType.
   * After creation, it will be provided as download directly.
   *
   */
  public async downloadPdfAsync(info: Info) {
    this.toastrService.info('PDF wird generiert!')

    const file = await this.makePdfAsync(info);

    this.fileExplorerService.provideFileAsDownload(file, info.title + '.pdf');
  }

  private arrayBufferToString(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
