import {Component, Input} from '@angular/core';
import {
  ShopfloorManagementBoardStatusService,
  ShopfloorManagementBoardStatusType
} from '@cstx/volkswagen-mqs-quality-management-service-client';
import {PerformanceState, SfmBoardViewModel, SfmState} from '../sfm-board.component';
import {
  UpdateShopfloorManagementBoardStatusRequestParams
} from '@cstx/volkswagen-mqs-quality-management-service-client/api/shopfloorManagementBoardStatus.service';
import {firstValueFrom} from 'rxjs';
import {PermissionType, RolesService} from '../../../../core/services/roles.service';
import {
  EffectiveUserClaim,
  EnterpriseRoleProviderService
} from '../../../../core/services/enterprise-role-provider.service';

@Component({
  selector: 'op-sfm-card',
  templateUrl: './sfm-card.component.html',
  styleUrls: ['./sfm-card.component.scss']
})
export class SfmCardComponent {


    constructor(private shopfloorManagementBoardStatusService: ShopfloorManagementBoardStatusService,
                public roles: RolesService) {
    }
    @Input() sfm: SfmBoardViewModel;
    @Input() kioskMode: boolean;
    @Input() treeMode = true;
    @Input() showInactive: boolean;

    protected readonly ShopfloorManagementBoardStatusType = ShopfloorManagementBoardStatusType;
    protected readonly SfmState = SfmState;

    protected readonly PerformanceState = PerformanceState;
    private unrestrictedMode = true;

    protected readonly EnterpriseRoleProviderService = EnterpriseRoleProviderService;

    public async setStateUnRestricted(sfmBoardViewModel: SfmBoardViewModel, stateName: string) {
      if (stateName === 'logistics') {
        if (sfmBoardViewModel.LogisticState === SfmState.UNKNOWN || sfmBoardViewModel.LogisticState === SfmState.BAD) {
          sfmBoardViewModel.LogisticState = SfmState.GOOD;
        } else {
          sfmBoardViewModel.LogisticState = SfmState.BAD;
        }
      }

      if (stateName === 'technical') {
        if (sfmBoardViewModel.TechnicalState === SfmState.UNKNOWN || sfmBoardViewModel.TechnicalState ===SfmState.BAD) {
          sfmBoardViewModel.TechnicalState = SfmState.GOOD;
        } else {
          sfmBoardViewModel.TechnicalState = SfmState.BAD;
        }
      }
      if (stateName === 'humanResources') {
        if (sfmBoardViewModel.HumanResourcesState === SfmState.UNKNOWN || sfmBoardViewModel.HumanResourcesState === SfmState.BAD) {
          sfmBoardViewModel.HumanResourcesState = SfmState.GOOD;
        } else {
          sfmBoardViewModel.HumanResourcesState = SfmState.BAD;
        }
      }
      if (stateName === 'quality') {
        if (sfmBoardViewModel.QualityState === SfmState.UNKNOWN || sfmBoardViewModel.QualityState  === SfmState.BAD) {
          sfmBoardViewModel.QualityState = SfmState.GOOD;
        } else {
          sfmBoardViewModel.QualityState = SfmState.BAD;
        }
      }
      if (stateName === 'material') {
        if (sfmBoardViewModel.MaterialState === SfmState.UNKNOWN || sfmBoardViewModel.MaterialState === SfmState.BAD) {
          sfmBoardViewModel.MaterialState = SfmState.GOOD;
        } else {
          sfmBoardViewModel.MaterialState = SfmState.BAD;
        }
      }
      if (stateName === 'workSecurity') {
        if (sfmBoardViewModel.WorkSecurityState === SfmState.UNKNOWN || sfmBoardViewModel.WorkSecurityState === SfmState.BAD) {
          sfmBoardViewModel.WorkSecurityState = SfmState.GOOD;
        } else {
          sfmBoardViewModel.WorkSecurityState = SfmState.BAD;
        }
      }

      await this.updateSfmStatus(sfmBoardViewModel);
    }

    public async setState(sfmBoardViewModel: SfmBoardViewModel, stateName: string) {

        if (!EnterpriseRoleProviderService.hasRequiredClaimByName('sfm-board-set-status')) {
          return;
        }

        if (this.unrestrictedMode) {
          await this.setStateUnRestricted(sfmBoardViewModel, stateName);
          return;
        }

        let stateChanged = false;

        if (stateName === 'logistics') {
            if (sfmBoardViewModel.LogisticState === SfmState.UNKNOWN && !stateChanged) {
                sfmBoardViewModel.LogisticState = SfmState.GOOD;

                stateChanged = true;
            }

            if (sfmBoardViewModel.LogisticState === SfmState.GOOD && !stateChanged) {
                sfmBoardViewModel.LogisticState = SfmState.BAD;
                stateChanged = true;
            }
        }

        if (stateName === 'technical') {
            if (sfmBoardViewModel.TechnicalState === SfmState.UNKNOWN && !stateChanged) {
                sfmBoardViewModel.TechnicalState = SfmState.GOOD;
                stateChanged = true;
            }

            if (sfmBoardViewModel.TechnicalState === SfmState.GOOD && !stateChanged) {
                sfmBoardViewModel.TechnicalState = SfmState.BAD;
                stateChanged = true;
            }
        }

        if (stateName === 'humanResources') {
            if (sfmBoardViewModel.HumanResourcesState === SfmState.UNKNOWN && !stateChanged) {
                sfmBoardViewModel.HumanResourcesState = SfmState.GOOD;
                stateChanged = true;
            }

            if (sfmBoardViewModel.HumanResourcesState === SfmState.GOOD && !stateChanged) {
                sfmBoardViewModel.HumanResourcesState = SfmState.BAD;
                stateChanged = true;
            }
        }

        if (stateName === 'quality') {
            if (sfmBoardViewModel.QualityState === SfmState.UNKNOWN && !stateChanged) {
                sfmBoardViewModel.QualityState = SfmState.GOOD;
                stateChanged = true;
            }

            if (sfmBoardViewModel.QualityState === SfmState.GOOD && !stateChanged) {
                sfmBoardViewModel.QualityState = SfmState.BAD;
                stateChanged = true;
            }
        }

        if (stateName === 'material') {
            if (sfmBoardViewModel.MaterialState === SfmState.UNKNOWN && !stateChanged) {
                sfmBoardViewModel.MaterialState = SfmState.GOOD;
                stateChanged = true;
            }

            if (sfmBoardViewModel.MaterialState === SfmState.GOOD && !stateChanged) {
                sfmBoardViewModel.MaterialState = SfmState.BAD;
                stateChanged = true;
            }
        }

        if (stateName === 'workSecurity') {
            if (sfmBoardViewModel.WorkSecurityState === SfmState.UNKNOWN && !stateChanged) {
                sfmBoardViewModel.WorkSecurityState = SfmState.GOOD;
                stateChanged = true;
            }

            if (sfmBoardViewModel.WorkSecurityState === SfmState.GOOD && !stateChanged) {
                sfmBoardViewModel.WorkSecurityState = SfmState.BAD;
                stateChanged = true;
            }
        }

        if (stateChanged) {
          await this.updateSfmStatus(sfmBoardViewModel);
        }
    }

  private async updateSfmStatus(sfmBoardViewModel: SfmBoardViewModel) {
    let req: UpdateShopfloorManagementBoardStatusRequestParams;

    req = {
      shopfloorManagementBoardStatusUpdateRequest: {
        id: sfmBoardViewModel.Id,
        commonName: sfmBoardViewModel.CommonName,
        workSecurityStateOk: sfmBoardViewModel.WorkSecurityState === SfmState.GOOD,
        qualityStateOk: sfmBoardViewModel.QualityState === SfmState.GOOD,
        humanResourcesStateOk: sfmBoardViewModel.HumanResourcesState === SfmState.GOOD,
        technicalStateOk: sfmBoardViewModel.TechnicalState === SfmState.GOOD,
        logisticStateOk: sfmBoardViewModel.LogisticState === SfmState.GOOD,
        materialStateOk: sfmBoardViewModel.MaterialState === SfmState.GOOD,
        statusType: sfmBoardViewModel.SfmStatusType
      }
    }

    await firstValueFrom(this.shopfloorManagementBoardStatusService.updateShopfloorManagementBoardStatus(req));
  }
}
