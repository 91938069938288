import {LoggingSource} from './loggingSource';
import {LogLevel} from './logLevel';
import {deserializeError, ErrorObject, serializeError} from 'serialize-error';

export class LogEntry {
  get error(): Error {
    return this._error;
  }

  set error(value: Error) {
    this._error = value;
  }

  public errorMessage: string;

  public eventTimeString: string;
  public level: LogLevel;
  public source: LoggingSource;
  public clientId: string;
  public message: string;
  private _error: Error;
  public errorObject: ErrorObject;
  public duplicateCounter = 0;
  public appVersion: string;
  public viewed?: boolean;

  constructor(source: LoggingSource, level: LogLevel, message: string, error?: Error) {
    this.source = source;
    this.level = level;
    this.message = message;

    if (error) {
      this.errorObject = serializeError(error);
      this._error = error;
    }

    this.eventTimeString = new Date().toISOString()
  }
}

