import {Component, Input, OnChanges, Output} from '@angular/core';
import { ParseDMCService } from '@cstx/volkswagen-mqs-parsing-service-client';
import { DMCResult, StringDMCValue } from './models/dmcResult';
import {DatePipe} from '@angular/common';
import {Subject} from 'rxjs';
import {RolesService} from "../../../core/services/roles.service";
import {EnterpriseRoleProviderService} from "../../../core/services/enterprise-role-provider.service";

@Component({
  selector: 'op-dmc-parser',
  templateUrl: './dmc-parser.component.html',
  styleUrls: ['./dmc-parser.component.scss']
})
export class DmcParserComponent {
  @Input()  onParsingFinished = new Subject<DMCResult>();

  result: DMCResult;
  parsing: boolean;
  dmcList = '';
  constructor(private parseDMCService: ParseDMCService, private datePipe: DatePipe, public roles: RolesService) { }

  parseDmc(dmc: string) {
    if (dmc === '') {
      this.result = undefined;
      return;
    }

    this.parsing = true;
    this.parseDMCService.parseDmcPost({ dmc })
      .toPromise()
      .then(response => {
        this.result = response;
        this.result.dmc = dmc;
        this.parsing = false;
        this.onParsingFinished.next(this.result);
      });
  }

  renderExtraData(values: StringDMCValue[]) {
    const strings = [];
    values.forEach(value => {
      strings.push(value.value + '\n');
    });

    return strings.toString();
  }

  parseDmcBulk() {
    const dmcArray = this.dmcList.split('\n');

    dmcArray.forEach(dmc => {
      this.parseDmc(dmc);
    });

    this.dmcList = '';
  }

  resetTextArea() {
    this.dmcList = '';
  }

    protected readonly EnterpriseRoleProviderService = EnterpriseRoleProviderService;
}
