import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VideoPlayerService} from './video-player.service';
import {VideoPlayerEntity, VideoState} from './video-player-entity';

@Component({
  selector: 'op-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  @Input() fixedVideo: VideoPlayerEntity = null;
  @Input() elementId = 'currentVideo';
  @Input() previewMode: boolean;
  @Input() autoPlay: boolean;
  @Input() videoHeight = 200;
  @Input() videoWidth = 300;


  @Output() onVideoPreviewSelected = new EventEmitter<VideoPlayerEntity>();


  public currentVideo: VideoPlayerEntity = null;

  protected readonly VideoPlayerService = VideoPlayerService;




  public start() {
    setTimeout(() => {
      const myVideo: HTMLVideoElement = document.getElementById(this.elementId) as HTMLVideoElement;

      if (!this.previewMode || this.autoPlay) {
        myVideo.play();

        myVideo.onended = (event) => {
          VideoPlayerService.videoPlaybackEnded(this.currentVideo.name);

          if (this.autoPlay) {
            this.currentVideo = VideoPlayerService.getNext(this.currentVideo.name);
            this.start();
          }

        };

        myVideo.onpause = (event) => {
          VideoPlayerService.videoPlaybackPaused(this.currentVideo.name);
        };

        myVideo.onplay = (event) => {
          VideoPlayerService.videoPlaybackStart(this.currentVideo.name);
        };

        myVideo.onfullscreenchange = (event) => {

        }

        VideoPlayerService.playVideo(this.currentVideo.name);
      }
    }, 500);
  }

  private videoEnded() {
    if (this.fixedVideo) {
      this.start();
    } else {
      this.currentVideo = VideoPlayerService.getNext(this.currentVideo.name);
      this.start();
    }
  }

  public ngOnInit(): void {
    if (this.fixedVideo) {
      this.currentVideo = this.fixedVideo;
      this.start();
    }

    /*
    VideoPlayerService.onNewVideoAdded.subscribe(video => {
      if (!this.fixedVideo && !this.videoPlaying) {
        this.currentVideo = video;
        this.start();
      }
    });

     */
  }

  public videoSelected(currentVideo: VideoPlayerEntity) {
    this.onVideoPreviewSelected.emit(currentVideo);
  }

  protected readonly VideoState = VideoState;
}
