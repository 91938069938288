import {Component, Input, OnInit} from '@angular/core';
import {TrackingEvent} from '../../models/tracking/trackingEvent';
import {EventType, ReferenceType} from '@cstx/volkswagen-mqs-tracking-service-client';
import {ColdTestResponse, HotTestResponse, TestResult} from '@cstx/volkswagen-mqs-engine-service-client';


@Component({
  selector: 'op-event-tracking-additional-properties',
  templateUrl: './event-tracking-additional-properties.component.html',
  styleUrls: ['./event-tracking-additional-properties.component.scss']
})
export class EventTrackingAdditionalPropertiesComponent implements OnInit {
  @Input() event: TrackingEvent;
  hotTestResult = TestResult;
  coldTestResult = TestResult;
  eventTypes = EventType;
  constructor() { }

  ngOnInit(): void {
  }

}
