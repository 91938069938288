import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'op-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @Input()
  get options() {
    return this._options;
  };
  set options(options: Map<string, Option>) {
    if (typeof options === "undefined") {
      return;
    }

    this._options = options;
    this.buildAllOptions();
  }

  @Input() 
  get selectedOptionId() {
    return this._selectedOptionId;
  }
  set selectedOptionId(id: string) {
    this._selectedOptionId = id;
  }

  @Input() placeholderSearch?: string;
  @Input() allowFreeText: boolean = false;  // If true the user can select the searchInput text

  @Output() selectedOption: EventEmitter<string> = new EventEmitter();

  @ViewChild('selectButton') selectButton: ElementRef;

  allOptions: Map<string, Option>;

  search: string = '';

  private _options: Map<string, Option>;
  private _selectedOptionId: string;
  constructor() { }

  toggleMenu(): void {
    this.search = "";
    this.selectButton.nativeElement.click();
  }

  private buildAllOptions(): void {
      let allOptions: Map<string, Option> = new Map;

      this.options.forEach((v, k) => {
        allOptions.set(k, v);
        allOptions = new Map([...allOptions.entries(), ...this.getSuboptions(v).entries()]);
      });

      this.allOptions = allOptions;
  }

  private getSuboptions(option: Option): Map<string, Option> {
    let suboptions: Map<string, Option> = new Map;

    option.suboptions?.forEach(suboption => {
      suboptions.set(suboption.id, suboption);
      suboptions = new Map([...suboptions.entries(), ...this.getSuboptions(suboption).entries()]);
    });

    return suboptions;
  }

  selectSetValue(value: string): void {
    if (!this.allowFreeText) {
      if (!this.allOptions?.has(value)) {
        return;
      }
    }

    this.selectedOption.emit(value);
    this.selectedOptionId = value;

    this.toggleMenu();
  }
}

export interface Option {
  id: string;
  name?: string;
  config?: OptionConfig;
  suboptions?: Map<string, Option>;
}

export interface OptionConfig {
  color: string;
}