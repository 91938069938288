import {Component, HostListener, Input, OnInit} from '@angular/core';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {ComponentProfile} from '../component-profile.component';
import {EngineControllerService, HotTestResponse, TestCategory, TestResult} from '@cstx/volkswagen-mqs-engine-service-client';
import {ColdTest} from '../../../../modules/engine/list/engine';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {TranslateService} from '@ngx-translate/core';
import {ComponentProfileService} from '../component-profile.service';
import {FavoriteMenuEventArgs} from '../actions/actions.component';
import {ComponentType} from '../component-type';

@Component({
  selector: 'op-tests',
  templateUrl: './tests.component.html',
  styleUrls: ['./tests.component.scss']
})
export class TestsComponent implements OnInit {
  @Input() componentProfile: ComponentProfile;
  @Input() selfLink: string;
  @Input() contentContainerId: string;

  protected readonly ComponentType = ComponentType;

  constructor(private engineController: EngineControllerService,
              private breakpointObserver: BreakpointObserver,
              private translate: TranslateService,
              private componentProfileService: ComponentProfileService) { }


  tests = new Array<Test>();
  viewSizeXS: boolean;

  treeViewItems = new Array<TreeviewItem>();
  treeViewConfig: TreeviewConfig;

  private beforePrintCollapsed: Map<TreeviewItem,boolean> = new Map<TreeviewItem, boolean>();

  private static createTreeViewConfig() {
    return    TreeviewConfig.create({
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 0
    });
  }

  private static mapColdTestToTest(coldTest: ColdTest): Test {
    const t = new Test();

    t.testCategory = coldTest.testCategory;
    t.costCenter = coldTest.costCenter;
    t.testBench = coldTest.testBench;
    t.testDate = coldTest.testDate;
    t.testRuns = coldTest.testRuns;
    t.testResult = coldTest.testResult;

    t.errorCode = coldTest.errorCode;

    t.results.push({key: 'testBench', value: coldTest.testBench });
    t.results.push({key: 'errorCode', value: coldTest.errorCode });
    t.results.push({key: 'employee', value: coldTest.employee });
    t.results.push({key: 'errorMessage', value: coldTest.errorMessage });
    t.results.push({key: 'testResult', value: coldTest.testResult });

    return t;
  }

  private static mapHotTestToTest(hotTest: HotTestResponse): Test {
    const t = new Test();

    t.testCategory = 'HOT_TEST';
    t.testBench = hotTest.testBench;
    t.testDate = hotTest.testDate;
    t.testResult = hotTest.testResult;
    t.testRuns = 1;

    t.errorCode = hotTest.testResult === 'OK' ? '0' : '1';


    if (hotTest.error1) {
      t.results.push({key: 'error1', value: hotTest.error1 });
    }

    if (hotTest.error2) {
      t.results.push({key: 'error1', value: hotTest.error2 });
    }

    if (hotTest.error3) {
      t.results.push({key: 'error1', value: hotTest.error3 });
    }

    if (hotTest.error4) {
      t.results.push({key: 'error1', value: hotTest.error4 });
    }

    t.results.push({key: 'testBench', value: hotTest.testBench });
    t.results.push({key: 'employee', value: hotTest.employee });
    t.results.push({key: 'testResult', value: hotTest.testResult });

    return t;
  }

  @HostListener('window:beforeprint')
  private onBeforePrint() {
    this.beforePrintCollapsed.clear();
    this.copyTreviewStateAndExpandAll(this.treeViewItems);
  }

  @HostListener('window:afterprint')
  private onAfterPrint() {
    this.recoverTreviewState(this.treeViewItems);
  }

  private copyTreviewStateAndExpandAll(items: Array<TreeviewItem>) {
    items.forEach(item => {
      this.beforePrintCollapsed.set(item, item.collapsed);
      item.collapsed = false;
      if (item.children && item.children.length > 0) {
        this.copyTreviewStateAndExpandAll(item.children);
      }
    })
  }

  private recoverTreviewState(items: Array<TreeviewItem>) {
    items.forEach(item => {
      const previousValue = this.beforePrintCollapsed.get(item);
      item.collapsed = previousValue === undefined ? true : previousValue
      if (item.children && item.children.length > 0) {
        this.recoverTreviewState(item.children);
      }
    })
  }

  async ngOnInit(): Promise<void> {
    this.breakpointObserver
      .observe(['(max-width: 576px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.viewSizeXS = true;
        } else {
          this.viewSizeXS = false;
        }
      });

    this.treeViewConfig = TestsComponent.createTreeViewConfig();

    await this.getColdTests(this.componentProfile.Id);
    await this.getHotTests(this.componentProfile.Id);

    this.tests.sort((a, b) => {
      if (a.testDate > b.testDate) {
        return -1;
      } else {
        return 1;
      }
    });

    this.populateTreeRecursive();
  }

  private populateTreeRecursive() {
      this.tests.forEach(t => {
        const subItem = new TreeviewItem({text: t.testDate, value: t});

        subItem.children = this.createTestResultSubItems(t);
        subItem.collapsed = true;

        this.treeViewItems.push(subItem);
      });
  }

  private async getColdTests(id: string) {
    if (this.componentProfile.componentType !== ComponentType.ENGINE) {
      return;
    }

    await this.engineController.getColdTests({ id }).toPromise()
      .then(response => {
        response.forEach(item => {
            this.tests.push(TestsComponent.mapColdTestToTest(item));
        });
      });
  }

  private async getHotTests(id: string) {
    if (this.componentProfile.componentType !== ComponentType.ENGINE) {
      return;
    }

    await this.engineController.getHotTests({ id }).toPromise()
      .then(response => {
        response.forEach(item => {
          this.tests.push(TestsComponent.mapHotTestToTest(item));
        });
      });
  }


  private createTestResultSubItems(t: Test): TreeviewItem[] {
    let items: TreeviewItem[];
    t.results.forEach((item, index) => {
      if (index === 0) {
        items = new Array<TreeviewItem>();
      }

      const tryTranslateKey = this.translate.instant('component-profile.tests.' + item.key);
      items.push(new TreeviewItem({text: tryTranslateKey === 'component-profile.tests.' + item.key ? item.key : tryTranslateKey, value: item.value}));
    });

    return items;

  }

  invalidatedTestsPresent(): boolean {
    return this.treeViewItems.some(item => {
      return item.value.testResult === 'INVALIDATED';
    })
  }

  handleOpenFragment(source: string, action?: string) {

    const args = new FavoriteMenuEventArgs();
    args.Favorite = source;
    args.ActionType = action;
    args.Toggled = true;

    this.componentProfileService.onExpandCollapse.emit(args);
  }
}

export class Test {
  isTestObjectType = true;
  costCenter?: string;
  employee?: string;
  errorCode?: string;
  errorMessage?: string;
  errorType?: string;
  testBench?: string;
  testDate?: string;
  testRuns?: number;
  testResult?: TestResult;
  testCategory?: TestCategory | null;

  results = new Array<KeyValuePair>();
}

interface KeyValuePair {
  key: string;
  value: string;
}

