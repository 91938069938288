<div class="container-fluid pb-3" style="min-height: 100px">
  <tr class="row border-top pt-3 pb-3">
    <td class="col">
      <div class="d-flex">
        <div class="col pl-0" style="vertical-align: middle;display: inherit;">
          <i class="fas fa-fw cpp-icon-size-sidebar" style="padding-top: 5px" [class.fa-cog]="componentType === 'PART'" [class.fa-cogs]="componentType === 'ENGINE'"></i>
          <span class="text-overflow-ellipses pl-3 pre-white" style="font-size: 1.5em; font-weight: 500">{{ componentProfile.Identifier }}</span>
          <span class="text-right" id="lockMarker"><i class="fas fa-lock cpp-icon-size" [class.fa-lock]="componentProfile.Blocked"
                                      [class.fa-lock-open]="!componentProfile.Blocked"
                                      [style.color]="componentProfile.Blocked ? 'darkred' : 'darkgreen'" style="padding-top: 8px; padding-left: 8px"></i></span>

          <span class="text-right pointer" id="logisticalStateMarker"
                *ngIf="componentProfile.engine?.getLogisticalState() === LogisticalState.UNFREE"
                [tooltip]="logisticalStateTooltipTemplate" placement="bottom">
            <i class="fas fa-shipping-fast cpp-icon-size"
               style="padding-top: 10px; padding-left: 8px; color:darkred;"></i>

            <ng-template #logisticalStateTooltipTemplate>
              <op-logistical-state-tooltip [engine]="componentProfile.engine"></op-logistical-state-tooltip>
            </ng-template>
          </span>


        </div>
      </div>
      <div class="row">
        <div class="col">
          <span class="small"><op-component-state-badge [name]="componentProfile.Status"></op-component-state-badge></span>
          <span class="small pl-1"><op-location-info *ngIf="componentProfile.Status === 'IN_PRODUCTION' || componentProfile.Status === 'INCOMPLETE'" [componentName]="componentProfile.Identifier"></op-location-info></span>
        </div>
      </div>
      <ng-container *ngIf="componentProfile.engine &&
       (ConfigService.currentStage ===  STAGE.DEVELOP
      || ConfigService.currentStage === STAGE.LOCALHOST)">
        <div class="row">
          <div class="col">
            <a class="small" [href]="'https://app.int.volkswagen-mqs.de/engine/' + componentProfile.engine?.engineCode + '/' + componentProfile.engine?.engineNumber" target="_blank">Engine on Integration</a>
            <br>
            <a class="small" [href]="'https://app.volkswagen-mqs.de/engine/' + componentProfile.engine?.engineCode + '/' + componentProfile.engine?.engineNumber" target="_blank">Engine on Production</a>
          </div>
        </div>
      </ng-container>
    </td>
    <td class="col-4 pl-0 text-right d-none d-lg-inline">
    <div class="pt-1" [class.cpp-unfree-background-color]="componentProfile.engine?.getLogisticalState() === LogisticalState.UNFREE">
      <op-entity-label
        [width]="componentType === 'PART' ? '90' : '0'"
        [height]="componentType === 'PART' ? '90' : '0'"
        [value]="componentProfile.BarcodeValue"
        [labelType]="componentType === 'PART' ? 'dmc' : 'engine'">
      </op-entity-label>

      <div class="text-center font-weight-bolder pt-1" *ngIf="componentProfile.engine?.getLogisticalState() === LogisticalState.UNFREE">
        {{ 'logistic.state.title' | translate }}
      </div>
      <div class="text-center small" *ngIf="componentProfile.engine?.getLogisticalState() === LogisticalState.UNFREE">
        {{ 'logistic.state.description' | translate }}
      </div>
    </div>
    </td>
  </tr>

  <ng-container *ngIf="componentProfile.Manufacturer">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.manufacturer' | translate }}
      </td>
      <td class="col-md-8  pt-2 pb-2" style="border-top: none">
        <op-copy-to-clipboard-icon style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="componentProfile.Manufacturer"></op-copy-to-clipboard-icon>{{ componentProfile.Manufacturer }}
      </td>
    </tr>
  </ng-container>

  <ng-container *ngIf="componentProfile.PartAssemblyGroup">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.part-assembly-group' | translate }}
      </td>
      <td class="col-md-8  pt-2 pb-2" style="border-top: none">
        <op-copy-to-clipboard-icon style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="componentProfile.PartAssemblyGroup"></op-copy-to-clipboard-icon>{{ componentProfile.PartAssemblyGroup }}
      </td>
    </tr>
  </ng-container>

  <ng-container *ngIf="componentProfile.PartNumber">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.part-number' | translate }}
      </td>
      <td class="col-md-8  pt-2 pb-2" style="border-top: none">
        <op-copy-to-clipboard-icon  style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="componentProfile.PartNumber"></op-copy-to-clipboard-icon>{{ componentProfile.PartNumber }}
      </td>
    </tr>
  </ng-container>

  <ng-container *ngIf="componentProfile.KeyCode">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.keycode' | translate }}
      </td>
      <td class="col-md-8  pt-2 pb-2" style="border-top: none">
        <op-copy-to-clipboard-icon style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="componentProfile.KeyCode"></op-copy-to-clipboard-icon>
        {{ componentProfile.KeyCode }}
        <ng-container *ngIf="(stageEnum === 0 || stageEnum === 1)">
          <a [routerLink]="['/metadata/bill-of-materials/', componentProfile.KeyCode]">
            <i class="ml-1 fas fa-map text-secondary d-inline pointer"></i>
          </a>
        </ng-container>
      </td>
    </tr>
  </ng-container>

  <ng-container *ngIf="componentProfile.Label">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.label' | translate }}
      </td>
      <td class="col-md-8  pt-2 pb-2" style="border-top: none">
        <op-copy-to-clipboard-icon style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="componentProfile.Label"></op-copy-to-clipboard-icon>{{ componentProfile.Label}}
      </td>
    </tr>
  </ng-container>

  <ng-container *ngIf="componentProfile.CostCenter">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.cost-center' | translate }}
      </td>
      <td class="col-md-8  pt-2 pb-2" style="border-top: none">
        <op-copy-to-clipboard-icon style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="componentProfile.CostCenter"></op-copy-to-clipboard-icon>{{ componentProfile.CostCenter}}
      </td>
    </tr>
  </ng-container>

  <ng-container *ngIf="componentProfile.Line">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.line' | translate }}
      </td>
      <td class="col-md-8  pt-2 pb-2" style="border-top: none">
        <op-copy-to-clipboard-icon style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="componentProfile.Line.toString()"></op-copy-to-clipboard-icon>
        {{ componentProfile.Line }} <ng-container *ngIf="componentProfile.PlantLineIndex">(Werkslinienindex: {{ componentProfile.PlantLineIndex }})</ng-container>
      </td>
    </tr>
  </ng-container>

  <ng-container *ngIf="componentProfile.ProductionDate">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.production-date' | translate }}
      </td>
      <td class="col-md-8  pt-2 pb-2" style="border-top: none">
        <op-datetime-show class="pl-1" [customIconClass]="'cpp-force-pr-2'" [dateTime]="componentProfile.ProductionDate" [noLocalTimeConversion]="false"></op-datetime-show>
      </td>
    </tr>
  </ng-container>
  <ng-container *ngIf="componentProfile.ProductionNumber">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.production-number' | translate }}
      </td>
      <td class="col-md-8  pt-2 pb-2" style="border-top: none">
        <op-copy-to-clipboard-icon style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="componentProfile.ProductionNumber"></op-copy-to-clipboard-icon>{{ componentProfile.ProductionNumber }}
      </td>
    </tr>
  </ng-container>
  <ng-container *ngIf="componentProfile.Identifier">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.component' | translate }}
      </td>
      <div class="col-md-8 pt-2 pb-2 text-overflow-ellipses d-inline-block pre-white" style="border-top: none;">
        <op-copy-to-clipboard-icon  style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="componentProfile.Identifier"></op-copy-to-clipboard-icon>{{ componentProfile.Identifier }}
      </div>
    </tr>
  </ng-container>
  <ng-container *ngIf="componentProfile.typeApprovalLabel">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.typeApprovalLabel' | translate }}
      </td>
      <td class="col-md-8  pt-2 pb-2" style="border-top: none">
        <op-copy-to-clipboard-icon style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="componentProfile.typeApprovalLabel"></op-copy-to-clipboard-icon>{{ componentProfile.typeApprovalLabel }}
      </td>
    </tr>
  </ng-container>
  <ng-container *ngIf="componentProfile.PreviousIdentifiers?.length > 0">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.alternativeNames' | translate }}
      </td>
      <div class="col-md-8 pt-2 pb-2 text-overflow-ellipses d-inline-block pre-white" style="border-top: none;">
        <ng-container *ngFor="let identifier of componentProfile.PreviousIdentifiers">
          <div><op-copy-to-clipboard-icon  style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="identifier"></op-copy-to-clipboard-icon>{{ identifier }}</div>
        </ng-container>
      </div>
    </tr>
  </ng-container>
  <ng-container *ngIf="componentProfile.DevelopmentNumber">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.development-number' | translate }}
      </td>
      <div class="col-md-8 pt-2 pb-2 text-overflow-ellipses d-inline-block pre-white" style="border-top: none;">
        <op-copy-to-clipboard-icon  style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="componentProfile.DevelopmentNumber"></op-copy-to-clipboard-icon>
        {{ componentProfile.DevelopmentNumber }}
      </div>
    </tr>
  </ng-container>


  <ng-container *ngIf="componentProfile.displacementInLiter">
    <tr class="row" *ngFor="let entry of componentProfile.displacementInLiter; index as i">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">

        {{ i === 0 ? ('component-profile.displacementInLiter' | translate) : '' }}

      </td>
      <td class="col-md-8  pt-2 pb-2" style="border-top: none">
        <op-copy-to-clipboard-icon style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="entry"></op-copy-to-clipboard-icon>{{ entry }} Liter
      </td>
    </tr>
  </ng-container>

  <ng-container *ngIf="componentProfile.powerInKilowatts">
    <tr class="row" *ngFor="let entry of componentProfile.powerInKilowatts; index as i">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ i === 0 ? ('component-profile.powerInKilowatts' | translate) : '' }}
      </td>
      <td class="col-md-8  pt-2 pb-2" style="border-top: none">
        <op-copy-to-clipboard-icon style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="entry"></op-copy-to-clipboard-icon>{{ entry }} ({{ (entry * 136 / 100) | number:'1.0-0' }})
      </td>
    </tr>
  </ng-container>

  <ng-container *ngIf="componentProfile.aggregate">
    <tr class="row" *ngFor="let entry of componentProfile.aggregate; index as i">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none" >
        {{ i === 0 ? ('component-profile.aggregate' | translate) : '' }}
      </td>
      <td class="col-md-8  pt-2 pb-2" style="border-top: none">
        <op-copy-to-clipboard-icon style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="entry"></op-copy-to-clipboard-icon>{{ entry }}
      </td>
    </tr>
  </ng-container>

  <ng-container *ngIf="componentProfile.LotSize !== undefined">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.lot-size' | translate }}
      </td>
      <div class="col-md-8 pt-2 pb-2 text-overflow-ellipses d-inline-block pre-white" style="border-top: none;">
        <op-copy-to-clipboard-icon  style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="componentProfile.LotSize"></op-copy-to-clipboard-icon>
        {{ componentProfile.LotSize }}
      </div>
    </tr>
  </ng-container>

  <ng-container *ngIf="componentProfile.LotNumber !== undefined">
    <tr class="row">
      <td class="col-md-4  pt-2 pb-2" style="font-weight: 500; border-top: none">
        {{ 'component-profile.lot-number' | translate }}
      </td>
      <div class="col-md-8 pt-2 pb-2 text-overflow-ellipses d-inline-block pre-white" style="border-top: none;">
        <op-copy-to-clipboard-icon  style="font-size: 0.8em;" [customIconClass]="'cpp-force-pr-2'" [content]="componentProfile.LotNumber"></op-copy-to-clipboard-icon>
        {{ componentProfile.LotNumber }}
      </div>
    </tr>
  </ng-container>
</div>

