import {
  BlockedActionState, BlockedActionType,
  BlockingActionResponse,
  ColdTestResponse, EngineBlockedState,
  EngineResponse, EngineReworkState, EngineTestState,
  HotTestResponse,
  KeyCodeHistoryResponse,
  EngineState,
  ReworkResponse, ReworkType, Source, TestCategory, TestResult,ReworkState
} from '@cstx/volkswagen-mqs-engine-service-client';
import {DataFile} from '@cstx/volkswagen-mqs-file-handling-service-client';
import {TestInheritance} from '@cstx/volkswagen-mqs-engine-service-client/model/testInheritance';
import {LogisticalState} from '../list/list.component';

export class Engine implements EngineResponse {
  id: string;
  engineCode: string;
  engineNumber: string;
  keyCode?: string;
  productionNumber: string;
  buildDate: string;
  costCenter: string;
  engineState: EngineState;
  engineBlockedState: EngineBlockedState;
  createdBy?: string;
  modifiedBy?: string;
  createdAt?: string;
  modifiedAt?: string;

  hasBlockingActions: boolean;
  hasColdTests: boolean;
  hasHotTests: boolean;
  hasKeyCodeHistory: boolean;
  hasReworks: boolean;

  componentName?: string;
  developmentNumber?: string;
  typeApprovalLabel?: string;
  labelIdentifier?: string;
  engineReworkState: EngineReworkState;
  engineTestState: EngineTestState;
  lotNumber?: string;
  lotSize?: number;
  orderNumber?: string;
  partNumber?: string;
  productionStartDate: string;
  source: Source;

  files?: DataFile[] = [];
  keycodeHistory?: KeycodeHistory[] = [];
  hotTests?: HotTest[] = [];
  coldTests?: ColdTest[] = [];
  reworks?: Rework[] = [];
  blockingActions?: BlockingAction[] = [];

  constructor(item?: EngineResponse) {
    if (item !== undefined) {
      this.id = item.id;
      this.engineCode = item.engineCode;
      this.engineNumber = item.engineNumber;
      this.keyCode = item.keyCode;
      this.productionNumber = item.productionNumber;
      this.buildDate = item.buildDate;
      this.costCenter = item.costCenter;
      this.engineState = item.engineState;
      this.engineBlockedState = item.engineBlockedState;
      this.createdBy = item.createdBy;
      this.createdAt = item.createdAt;
      this.modifiedBy = item.modifiedBy;
      this.modifiedAt = item.modifiedAt;
      this.hasBlockingActions = item.hasBlockingActions;
      this.hasColdTests = item.hasColdTests;
      this.hasHotTests = item.hasHotTests;
      this.hasKeyCodeHistory = item.hasKeyCodeHistory;
      this.hasReworks = item.hasReworks;
      this.componentName = item.componentName;
      this.developmentNumber = item.developmentNumber;
      this.engineReworkState = item.engineReworkState;
      this.engineTestState = item.engineTestState;
      this.lotNumber = item.lotNumber;
      this.lotSize = item.lotSize;
      this.orderNumber = item.orderNumber;
      this.partNumber = item.partNumber;
      this.productionStartDate = item.productionStartDate;
      this.source = item.source;
      this.typeApprovalLabel = item.typeApprovalLabel;
      this.labelIdentifier = item.labelIdentifier;
    }
  }

  public getLogisticalState(): LogisticalState {
    if (this.engineState === EngineState.InProduction || this.engineState === EngineState.Disassembled) {
      return LogisticalState.UNDETERMINED;
    }


    if (this.engineBlockedState !== 'NONE') {
      return LogisticalState.UNFREE;
    }

    if (this.engineReworkState === 'PENDING') {
      return LogisticalState.UNFREE;
    }

    if (this.engineTestState !== 'OK') {
      return LogisticalState.UNFREE;
    }

    if (this.engineState === EngineState.Unknown) {
      return LogisticalState.UNFREE;
    }

    return LogisticalState.FREE;
  }
}

export class HotTest implements HotTestResponse {
  createdAt?: string;
  createdBy?: string;
  employee: string | null;
  error1?: string | null;
  error2?: string | null;
  error3?: string | null;
  error4?: string | null;
  id: string;
  modifiedAt?: string;
  modifiedBy?: string;
  okStatus: boolean;
  qualityAssuranceEmployee: string | null;
  source: Source;
  testBench: string;
  testDate: string;
  testResult: TestResult;
}

export class ColdTest implements ColdTestResponse {
  costCenter: string;
  createdAt?: string;
  createdBy?: string;
  employee: string;
  errorCode: string;
  errorMessage?: string;
  errorType?: string;
  id: string;
  modifiedAt?: string;
  modifiedBy?: string;
  source: Source
  testBench: string;
  testDate: string;
  testRuns: number;
  testResult: TestResult;
  testCategory?: TestCategory | null;
}

export class Rework implements ReworkResponse {
  analysis?: string;
  complaint?: string;
  createdAt?: string;
  createdBy?: string;
  deliveredAt: string;
  dispatchedAt: string | null;
  employee: string;
  id: string;
  modifiedAt?: string;
  modifiedBy?: string;
  qualityAssuranceCheckedAt?: string | null;
  qualityAssuranceEmployee: string | null;
  rework?: string;
  reworkState?: ReworkState | null;
  reworkType?: ReworkType | null;
  signature?: string;
  source: Source;

}

export class BlockingAction implements BlockingActionResponse {
  action: string;
  blockedAt: string;
  blockedBy: string;
  createdAt?: string;
  createdBy?: string;
  id: string;
  modifiedAt?: string;
  modifiedBy?: string;
  releasedAt?: string;
  releasedBy?: string;
  source: Source;
  blockingReason: string;
  releaseReason: string;
  blockedActionState?: BlockedActionState;
  blockedActionType?: BlockedActionType;
  childComponentId: string;
}

export class KeycodeHistory implements KeyCodeHistoryResponse {
  createdAt?: string;
  createdBy?: string;
  id: string;
  keyCodeChangedAt: string;
  keyCodeNew: string;
  keyCodeOld: string;
  modifiedAt?: string;
  modifiedBy?: string;
  source: Source;
  coldTestInheritance: TestInheritance;
  hotTestInheritance: TestInheritance;
}
