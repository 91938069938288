import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {UserConfigurationService} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {UserService} from '../../modules/user/user.service';
import {
  BarChartColorTheme,
  BarChartStyleTheme,
  GraphCStyleConfig,
  StatisticsChartConfig, TrackingConfig,
  UserConfig
} from '../models/userConfig';
import {ThemingCategory} from './theming.service';
import {AuthService} from '../../core/services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class UserConfigService {
  private configSource: BehaviorSubject<UserConfig>;
  public config$: Observable<UserConfig>;
  private userConfig: UserConfig;

  constructor(private ucService: UserConfigurationService,
              private userService: UserService,
              private authService: AuthService) {

    const defaultConf: UserConfig = {
      dashboard: {
        colorTheme: BarChartColorTheme.Glossy,
        chartStyle: BarChartStyleTheme.FillingBar,
        withZeroValues: false
      },
      statistics: new StatisticsChartConfig(),
      tracking: new TrackingConfig()
    };

    this.configSource  = new BehaviorSubject<UserConfig>(defaultConf);
    this.config$  = this.configSource.asObservable();

    this.authService.isAuthorized().then(val => {
      if (val) {
        this.GetConfig();
      }
    });
  }

  pushConfig(configurationPatch: UserConfig) {
    const configurationCurrent = this.configSource.value;

    const productCenters: Set<string> = configurationCurrent.dashboard?.productCenters ?
      new Set<string>(Object.keys(configurationCurrent.dashboard?.productCenters)) : new Set<string>();

    if (configurationPatch.dashboard?.productCenters ) {
      Object.keys(configurationPatch.dashboard?.productCenters).forEach(key => productCenters.add(key));
    }

    const productCentersConfig = productCenters.size > 0 ? {} : undefined ;

    productCenters.forEach(key => productCentersConfig[key]
      = configurationPatch.dashboard?.productCenters && configurationPatch.dashboard?.productCenters[key] ?
      {...(configurationCurrent.dashboard?.productCenters ? configurationCurrent.dashboard?.productCenters[key] : {}),
        ...configurationPatch.dashboard?.productCenters[key]} : configurationCurrent.dashboard?.productCenters[key] )

    const configurationNew: UserConfig = {
      dashboard: {
        ...configurationCurrent.dashboard,
        ...configurationPatch.dashboard,
      },
      statistics: configurationCurrent.statistics ? {
        [ThemingCategory.OEELine]: {...configurationCurrent.statistics[ThemingCategory.OEELine],
          ...(configurationPatch.statistics ? configurationPatch.statistics[ThemingCategory.OEELine] : undefined) },
        [ThemingCategory.OutputBar]: {...configurationCurrent.statistics[ThemingCategory.OutputBar],
          ...(configurationPatch.statistics ? configurationPatch.statistics[ThemingCategory.OutputBar] : undefined) },
        [ThemingCategory.OutputLine]: {...configurationCurrent.statistics[ThemingCategory.OutputLine],
          ...(configurationPatch.statistics ? configurationPatch.statistics[ThemingCategory.OutputLine] : undefined) },
      } : {...configurationPatch.statistics},
      tracking: {
        ...configurationCurrent.tracking,
        ...configurationPatch.tracking
      }
    };
    if (productCentersConfig) {
      configurationNew.dashboard.productCenters = productCentersConfig;
    }

    this.configSource.next(configurationNew);
    this.userConfig = configurationNew;

    this.saveToUcs(JSON.stringify(configurationNew))
  }

  saveToUcs(configJSON: string, area: string = 'portal', configurationVersion: string = '1') {
    const email = this.userService.getUserEmail();

    this.ucService.createUserConfiguration({
      userConfigurationCreateRequest: {
        clientId: email,
        area,
        configurationVersion,
        configuration: configJSON
      }
    }).toPromise();
  }

  async GetConfig(area: string = 'portal'): Promise<UserConfig> {
    await this.userService.getUserData();

    if (this.userConfig !== undefined) {
      return this.userConfig;
    } else {
      const email = this.userService.getUserEmail();
      const resultObservable = this.ucService.getUserConfigurations({
        clientId: email,
        area
      });

      try {
        const result = await firstValueFrom(resultObservable);
        if (result.content.length <= 0) {
          this.userConfig = new UserConfig();
        } else {

          let sc: UserConfig;
          sc = JSON.parse(result.content[0]?.configuration);
          this.userConfig = sc;
        }
      } catch (e) {
        if (!this.userConfig) {
          this.userConfig = new UserConfig();
        }
      }

      this.configSource.next(this.userConfig);
      return this.userConfig;
    }
  }

  async GetConfigString(area: string = 'portal'): Promise<string> {
    const email = this.userService.getUserEmail();
    const resultObservable = this.ucService.getUserConfigurations({
      clientId: email,
      area
    });

    const result = await firstValueFrom(resultObservable);

    if (result.content.length <= 0) {
      return undefined;
    } else {

      return result.content[0]?.configuration;
    }
  }

  updateDashboardConfig(styleConfig: GraphCStyleConfig) {
    this.userConfig.dashboard.chartStyle = styleConfig.chartStyle;
    this.userConfig.dashboard.colorTheme = styleConfig.colorTheme;

    this.configSource.next(this.userConfig);
    this.saveToUcs(JSON.stringify(this.userConfig));
  }
}
