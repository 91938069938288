import {FileType} from './fileType';
import {FileTypeEnum} from '@cstx/volkswagen-mqs-file-handling-service-client';

export class FilePreview {
  LoadingComplete = false;

  parentId: string;
  parentType: string;
  parentCostCenter: string;

  parserType: string;

  fileType: FileType;
  fhsFileType: FileTypeEnum;
  File: any;
  HasError: boolean;
  Blob: Blob;
  Parsed: boolean;
  OriginalName?: string;
  Source?: string;
  Origin?: string;
  Size: string;
  Uploaded?: string;
  directLink: string;
  maximized: boolean;
  private _fileName: string;
  extension: string;


  public IsImage(): boolean {
    return this.fileType === FileType.Image;
  }
  public IsString(): boolean {
    return this.fileType === FileType.Text;
  }
  public IsPdf(): boolean {
    return this.fileType === FileType.Pdf;
  }

  public IsParsedBinary(): boolean {
    if (this.fileType === FileType.Binary && this.Parsed) {
      return true;
    }

    return false;
  }

  public IsParsedOther(): boolean {
    if (this.fileType === FileType.Other && this.Parsed) {
      return true;
    }

    return false;
  }

  public set fileName(value: string) {
    this._fileName = value;
    this.extension = this.getFileExtension(this._fileName);
  }

  public get fileName(): string {
    return this._fileName;
  }

  getFileExtension(filename){
    // get file extension
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
    return extension;
  }

  constructor() {
  }
}
