import {Component, EventEmitter, Output} from '@angular/core';
import {UploadV2Service} from '../upload-v2.service';

@Component({
  selector: 'op-upload-v2-list',
  templateUrl: './upload-v2-list.component.html',
  styleUrls: ['./upload-v2-list.component.scss']
})
export class UploadV2ListComponent {
  @Output() onFileRemoved = new EventEmitter<any>();
  @Output() onFileListEmpty = new EventEmitter<any>();

  constructor(public uploadV2Service: UploadV2Service) {
    this.uploadV2Service.onFileRemoved.subscribe(count => {
      this.onFileRemoved.emit();
      if (count === 0) {
        this.onFileListEmpty.emit();
      }
    });
  }

  public upload() {
    this.uploadV2Service.upload();
  }
}
