import {EventEmitter, Injectable} from '@angular/core';
import {
  AddFeedbackRequestParams,
  BuildAbilityAnalysesService,
  BuildAbilityBlackListResponse,
  BuildAbilityBlackListService,
  BuildAbilityCheckJobResponse,
  BuildAbilityCheckReport,
  BuildProgramResponse,
  BuildProgramService,
  CreateBuildProgramRequestParams,
  DailyConsumptionForPartGroupSliceResponse,
  DailyConsumptionService,
  DispatcherTaskResponse,
  DispatcherTasksService, GetBuildAbilityCheckReportRequestParams,
  GetCostCenterWhitelistRequestParams,
  GetLatestBuildAbilityCheckReportRequestParams,
  GetLoadOnProgrammViewRequestParams,
  GetRepackTasksRequestParams,
  RepackTaskService,
  StockMovementService,
  WarehouseManagementResponseSliceResponse,
  WarehouseManagementService
} from '@cstx/volkswagen-mqs-logistics-data-service-client';
import {BuildProgramFilter} from './models/filter/build-program-filter';
import {BuildProgram, BuildProgramEntry, BuildProgramSet} from './models/build-program-set';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {firstValueFrom} from 'rxjs';
import {WarehouseFilter} from './models/filter/warehouse-filter';
import {DailyConsumptionFilter} from './models/filter/daily-consumption-filter';
import {
  GetDispatcherTasksRequestParams
} from '@cstx/volkswagen-mqs-logistics-data-service-client/api/dispatcherTasks.service';
import {
  GetBuildAbilityCheckJobByIdRequestParams,
  GetBuildAbilityCheckReportsRequestParams
} from '@cstx/volkswagen-mqs-logistics-data-service-client/api/buildAbilityAnalyses.service';
import {
  BuildAbilityBlackListCreateRequest
} from '@cstx/volkswagen-mqs-logistics-data-service-client/model/buildAbilityBlackListCreateRequest';
import {
  BlacklistEntriescreateRequestParams,
  BlacklistEntriesdeleteRequestParams,
  BlacklistEntriesgetRequestParams
} from '@cstx/volkswagen-mqs-logistics-data-service-client/api/buildAbilityBlackList.service';
import {
  BillOfMaterialControllerService,
  GetBillOfMaterialsRequestParams
} from '@cstx/volkswagen-mqs-engine-service-client';
import {
  RepackTaskResponseSliceResponse
} from '@cstx/volkswagen-mqs-logistics-data-service-client/model/repackTaskResponseSliceResponse';
import {
  BuildAbilityCheckProgramProblem
} from '@cstx/volkswagen-mqs-logistics-data-service-client/model/buildAbilityCheckProgramProblem';
import {TaskState} from '@cstx/volkswagen-mqs-logistics-data-service-client/model/taskState';

@Injectable({
  providedIn: 'root'
})
export class LogisticsDataService {
  public static onBuildProgramReceived = new EventEmitter();
  public static onBuildProgramEntryDeleted = new EventEmitter<string>();

  constructor(private buildProgramService: BuildProgramService,
              private dailyConsumptionService: DailyConsumptionService,
              private stockMovementService: StockMovementService,
              private warehouseManagementService: WarehouseManagementService,
              private dispatcherTasksService: DispatcherTasksService,
              private buildAbilityAnalysesService: BuildAbilityAnalysesService,
              private buildAbilityBlacklistService: BuildAbilityBlackListService,
              private billOfMaterialControllerService: BillOfMaterialControllerService,
              private repackTaskService: RepackTaskService) { }


  /**
   *
   */
  public async getWareHouseEntries(filter: WarehouseFilter): Promise<WarehouseManagementResponseSliceResponse> {
    try {
      const result =
        await firstValueFrom(this.warehouseManagementService.getWarehouseManagementEntities(filter.getAllParams()));

      return result;

    } catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred when trying to get warehouse entries.', error)
    }

    return null;
  }

  /**
   * Returns a pre-processed build program data set, matching the given filter.
   * @param filter The filter we use to get the wanted build programs.
   */
  public async getBuildProgram(filter: BuildProgramFilter): Promise<BuildProgramSet> {
    try {
      const buildProgramResponses = new Array<BuildProgramResponse>()
      let loadMore = true;

      while (loadMore) {
        const response
          = await firstValueFrom(this.buildProgramService.getBuildPrograms(filter.getAllParams()));

        if (response.content.length > 0) {
          buildProgramResponses.push(...response.content)
        }

        if (response.last) {
          loadMore = false;
        } else {
          filter.currentPageIndex = filter.currentPageIndex + 1;
        }
      }


      LogisticsDataService.onBuildProgramReceived.emit();
      return this.createBuildProgramSet(buildProgramResponses);

    } catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred when trying to get buildprograms.', error)
    }

    return null;
  }

  /**
   * This deletes all entries by a list of ids.
   * @param ids The list of ids.
   * @return The method returns false, if one of the entries failed to delete.
   * It returns true if all entries were deleted successfully.
   */
  public async deleteBuildProgramEntries(ids: string[]): Promise<boolean> {
    let overallResult = true;

    for (const id of ids) {
      const result = await this.deleteBuildProgramEntry(id);

      if (!result) {
        overallResult = false;
      }
    }

    return overallResult;
  }

  /**
   * This deletes a build program by id.
   * @param id The id of the build program.
   * @return The method returns true if deleted or false if not.
   */
  public async deleteBuildProgramEntry(id: string): Promise<boolean> {
    try {
      const result =  await firstValueFrom(this.buildProgramService.deleteBuildProgramById({id}));

      if (result) {
        LogisticsDataService.onBuildProgramEntryDeleted.emit(id);
        return true;
      }

    } catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred when trying to delete build-program-entry.', error)
    }

    return false;
  }

  /**
   * This method creates or updates a given build program entry.
   * @param entry The build program entry we want to create or update
   * @return It returns the updated entry.
   */
  public async createOrUpdateBuildProgramEntry(entry: BuildProgramEntry): Promise<BuildProgramEntry> {
    try {
      const params: CreateBuildProgramRequestParams = {
        buildProgramCreateRequest: entry.getBuildProgramCreateRequest()
      }

      const response
        = await firstValueFrom(this.buildProgramService.createBuildProgram(params))

      if (response) {
        return new BuildProgramEntry(response);
      }

    } catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred when trying to create or update build-program-entry.', error)
    }

    return null;
  }

  private createBuildProgramSet(entries: Array<BuildProgramResponse>) {
    const set = new BuildProgramSet();

    /**
     * 1. Map to list of BuildProgramEntries
     */
    const programEntries = entries.map(entry => new BuildProgramEntry(entry));


    /**
     * 2. Create a BuildProgram for each distinct costCenter / calendarWeek combination
     */
    programEntries.forEach(programEntry => {

      const existingProgramIndex =
        set.programs.findIndex(existingProgram =>
          existingProgram.costCenter === programEntry.costCenter &&
          existingProgram.calendarWeek === programEntry.calendarWeek)

      let program: BuildProgram;
      if (existingProgramIndex === -1) {
        program = new BuildProgram(programEntry.costCenter, programEntry.calendarWeek, programEntry.year);
        set.programs.push(program);
      } else {
        program = set.programs[existingProgramIndex];
      }

      program.entries.push(programEntry);
    })

    return set;
  }

  public async getWareHouseEntriesCount(filter: WarehouseFilter): Promise<number> {
    try {
      const result =
        await firstValueFrom(this.warehouseManagementService.getWarehouseManagementEntityCount(filter.getAllParams()));

      return result;

    } catch (error) {

    }

    return null;
  }

  public async getDailyConsumptions(filter: DailyConsumptionFilter): Promise<DailyConsumptionForPartGroupSliceResponse>
  {
    try {
      const result =
        await firstValueFrom(this.dailyConsumptionService.getDailyConsumptions(filter.getAllParams()));

      return result;
    }
    catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred on getting daily consumption.', error)
    }

    return null;
  }

  public async getDispatcherTask(year: string, calendarWeek: string, partNumber: string): Promise<DispatcherTaskResponse> {
    try {

      const params: GetDispatcherTasksRequestParams = {
        partNumber,
        year,
        calendarWeek
      };


      const result =
        await firstValueFrom(this.dispatcherTasksService.getDispatcherTasks(params));


      if (result.content.length > 0) {
        return result.content[0];
      }

    }
    catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred on getting dispatcher task.', error)
    }

    return null;
  }

  public async getBuildAbilityReports(year: string, calendarWeek: string): Promise<Array<BuildAbilityCheckReport>> {
    try {

      const params: GetBuildAbilityCheckReportsRequestParams = {
        year,
        calendarWeek,
        size: 100,
        sort: [ 'modifiedAt,desc']
      }


      const result =
        await firstValueFrom(this.buildAbilityAnalysesService.getBuildAbilityCheckReports(params));


      if (result.content.length > 0) {

        result.content
          .sort((a, b) => a.id < b.id ? 1 : -1);

        return result.content;
      }

    }
    catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred on getting dispatcher task.', error)
    }

    return null;
  }

  public async createBlacklistEntry(partNumber: string, costCenters:  string[] = null): Promise<Array<BuildAbilityBlackListResponse>> {
    try {

      const requests = new Array<BuildAbilityBlackListCreateRequest>();

      if (costCenters) {
        costCenters.forEach(costCenter => {
          const request: BuildAbilityBlackListCreateRequest = {
            costCenter,
            partNumber
          }

          requests.push(request);
        });
      } else {
        const request: BuildAbilityBlackListCreateRequest = {
          partNumber
        }

        requests.push(request);
      }

      const params: BlacklistEntriescreateRequestParams = {
        buildAbilityBlackListCreateRequest: requests
      }

      return await firstValueFrom(this.buildAbilityBlacklistService
        .blacklistEntriescreate(params));

    } catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred on creating one or more blacklist entries.', error)
    }

    return null;
  }

  public async getBlackListEntries(costCenter?: string): Promise<BuildAbilityBlackListResponse[]> {
    try {

      const params: BlacklistEntriesgetRequestParams = {
        size: 250,
        page: 0,
        costCenter
      }

      const result = await firstValueFrom(this.buildAbilityBlacklistService.blacklistEntriesget(params));

      return result.content;


    } catch (error) {

    }

    return null;
  }


  public async deleteBlacklistEntry(id: string): Promise<boolean> {
    try {

      const params:  BlacklistEntriesdeleteRequestParams = {
        id
      };

      return await firstValueFrom(this.buildAbilityBlacklistService.blacklistEntriesdelete(params))

    } catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred on deleting blacklist entry.', error)
    }

    return false;
  }

  public async getBuildAbilityWhitelist(costCenter: string): Promise<Array<string>> {
    try {

      const params: GetCostCenterWhitelistRequestParams = {
        costCenter
      }


      return await firstValueFrom(this.stockMovementService.getCostCenterWhitelist(params));
    }
    catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred on getting dispatcher task.', error)
    }

    return null;
  }

  public async getCostCenters(): Promise<Array<string>> {
    try {
      return await firstValueFrom(this.buildAbilityAnalysesService.activeCostCenters());

    } catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred on getting costCenters', error)
    }

    return null;
  }

  public async getBuildAbilityCheckJob(id: string): Promise<BuildAbilityCheckJobResponse> {
    try {
      const params: GetBuildAbilityCheckJobByIdRequestParams = {
        id
      };

      return await firstValueFrom(this.buildAbilityAnalysesService.getBuildAbilityCheckJobById(params));

    } catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred on getting costCenters', error)
    }

    return null;
  }

  public async getLoadOnProgram(): Promise<any> {
    try {

      const now = new Date().toISOString();
      const params: GetLoadOnProgrammViewRequestParams = {
        from: now
      }

      const result
        = await firstValueFrom(this.buildAbilityAnalysesService.getLoadOnProgrammView(params));

      return result;

    } catch (error)
    {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred while getting bill of materials.', error)
    }
  }

  async getBillOfMaterials(costCenter?: string, keyCode?: string, page?: number, size?: number, sort?: string[]) {
    try {
      const request: GetBillOfMaterialsRequestParams = {
        costCenter,
        keyCode,
        page,
        size,
        sort
      }
      const response = await firstValueFrom(this.billOfMaterialControllerService.getBillOfMaterials(request));
      return response;

    } catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred while getting bill of materials.', error)
    }
    return null;
  }

  public async getLatestBuildAbilityCheckReport(calendarWeek: string, year: string): Promise<BuildAbilityCheckReport> {
    const request: GetLatestBuildAbilityCheckReportRequestParams = {
      calendarWeek,
      year
    }
    try {
      const response = await firstValueFrom(this.buildAbilityAnalysesService.getLatestBuildAbilityCheckReport(request));
      return response;
    } catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred while getting buildability check report.', error)
    }
    return null;
  }

  public async getDispatcherTaskWithProblems(calendarWeek: string, year: string): Promise<ListedDispatcherTaskWithProblemsResponse> {
    const request: GetLatestBuildAbilityCheckReportRequestParams = {
      calendarWeek,
      year
    }
    try {
      const response = await firstValueFrom(this.buildAbilityAnalysesService.getDispatcherTaskWithProblemsResponse(request));
      return response;
    } catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred while getting dispatcher tasks with problems.', error)
    }
    return null;
  }

  public async getBuildAbilityCheckReportsByJobId(jobId: string): Promise<BuildAbilityCheckReport[]> {
    try {

      return await firstValueFrom(this.buildAbilityAnalysesService.buildAbilityCheckReports({ jobId }));


    } catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred while getting build-ability-check-reports.', error)
    }

    return null;
  }

  async getRepackTasks(calendarWeek?: string,
                        year?: string,
                        partNumber?: string,
                        day?: string,
                        size?: number,
                        page?: number,
                        sort?: Array<string>): Promise<RepackTaskResponseSliceResponse> {

    const request: GetRepackTasksRequestParams = { calendarWeek, year, partNumber, day, size, page, sort };
    try {
      const response = await firstValueFrom(this.repackTaskService.getRepackTasks(request));
      return response;
    } catch (error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred while getting repacking tasks.', error)
    }
    return null;
  }

  public async getBuildAbilityCheckReport(id: number): Promise<BuildAbilityCheckReport> {
    try {

      const params: GetBuildAbilityCheckReportRequestParams = {
        id
      }

      const response =
        await firstValueFrom(this.buildAbilityAnalysesService.getBuildAbilityCheckReport(params))

      return response;

    } catch(error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred while getting build-ability-check-report.', error)
    }

    return null;
  }


  async setDispatcherTask(id: string, amountValidUntil?: string, amount?: number, comment?: string, state?: TaskState): Promise<boolean> {
    try {
      const request: AddFeedbackRequestParams = {
        id,
        dispatcherFeedbackCreateRequest: {
          amountValidUntil,
          amount,
          comment,
          state
        }
      };
      await firstValueFrom(this.dispatcherTasksService.addFeedback(request));
      return true;
    } catch(error) {
      LoggingService.logError(LoggingSource.LOGISTICS_DATA_SERVICE,
        'Error occurred while setting dispatcher task feedback.', error);
      return false;
    }
  }
}

export class ListedDispatcherTaskWithProblemsResponse {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          [key: string]: Array<BuildAbilityCheckProgramProblem>;
        };
      };
    };
  };
}
