import {Component, isDevMode, OnInit} from '@angular/core';
import {KioskModeService} from '../../../../core/services/kiosk-mode.service';
import {Info} from '../info';
import {UserInformationResponse} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {Slice} from '../../../../shared/models/slice';
import {InfosService} from '../infos.service';
import {InfoFilter} from '../infoFilter';
import {FileExplorerService} from '../../../../shared/components-merged/usecase-file-explorer/file-explorer.service'
import {ConfigService} from '../../../../core/services/config.service';
import {ActivatedRoute} from '@angular/router';
import {FilterValue} from '../../../../shared/components/guided-filter-input/guided-filter-input.component';
import {EnterpriseRoleProviderService} from '../../../../core/services/enterprise-role-provider.service';

@Component({
  selector: 'op-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {
  public previewMode: boolean;
  public infos = new Array<Info>();
  public listUpdating: boolean;
  public searching: boolean;

  public costCenter: string;
  public workSequence: string;

  public filter = new InfoFilter();

  protected readonly EnterpriseRoleProviderService = EnterpriseRoleProviderService;
  protected readonly KioskModeService = KioskModeService;

  constructor(private infoService: InfosService,
              private fileExplorerService: FileExplorerService,
              private route: ActivatedRoute) {}


  public update(reset: boolean = false) {
    this.listUpdating = true;
    const items = new Array<UserInformationResponse>();

    if (reset) {
      this.filter.currentPageIndex = 0;
      this.filter.sort = [ 'modifiedAt,desc'];
      this.filter.costCenter = this.costCenter;
      this.filter.workSequence = this.workSequence;
      this.filter.isActive = true;
    }

    if (!this.previewMode) {
      this.filter.isPublished = true;
    }

    this.infoService.getInfos(this.filter)
      .then(r => {
        r.content.forEach(response => {
          items.push(response);
        })

        let infos: Array<Info>;
        if (reset) {
          infos = items.map<Info>(i => (new Info().mapFromUserInformationResponse(i)));
        } else {
          infos = this.infos.concat(items.map<Info>(i => (new Info().mapFromUserInformationResponse(i))));
        }


        this.infos = this.infoService.filterLocal(infos, this.filter);


        this.filter.slice = new Slice(
          r.first, r.last, r.number, r.size, r.empty
        );

        this.filter.currentPageIndex++;


      })
      .catch(error => {
        console.log(error);
      }).finally(() => {
      this.listUpdating = false;
      this.searching = false;

      this.fileExplorerService.SetFilter(new Array<FilterValue>());
      this.infos.forEach(i => {
        this.loadFiles(i);
      });
    });
  }


  private loadFiles(info: Info, page: number = 1) {
    this.fileExplorerService.GetFilesForUseCase('infos', 1, 50, true, info.id)
      .then(result => {
        result.forEach(r => {
          info.fileList.push(r);
        });

      }).catch(err => {
      if (isDevMode()) {
        console.log(err);
      }
    })
      .finally(() => {
        // this.count = this.list.length;
        // this.countUpdating = false;
        // this.loading = false;
        // this.loadFiles();
      });
  }



  public ngOnInit(): void {
    ConfigService.headerTitle = 'Neuigkeiten';

    this.route.queryParamMap.subscribe(params => {
      this.previewMode = params.get('previewMode') === 'true';
      this.costCenter =  params.get('costCenter');

      if (this.costCenter && this.costCenter !== '') {
        ConfigService.headerTitle = ConfigService.headerTitle + ' ' + this.costCenter;
      }

      this.workSequence =  params.get('workSequence');

      if (this.workSequence && this.workSequence !== '') {
        ConfigService.headerTitle = ConfigService.headerTitle + ' ' + this.workSequence;
      }
    });

    if (!EnterpriseRoleProviderService.rolesLoaded) {
      EnterpriseRoleProviderService.onPermissionsLoaded.subscribe(() => {
        this.update(true);
      });
    } else {
      this.update(true);
    }
  }
}
