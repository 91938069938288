import {Injectable, isDevMode} from '@angular/core';
import {LogEntry} from './logEntry';
import {LogLevel} from './logLevel';
import {LoggingSource} from './loggingSource';
import {LogFilter} from './logFilter';
import {deserializeError} from 'serialize-error';
import {BehaviorSubject, Observable, Subject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import moment from 'moment/moment';
import {QueryParamsService} from '../services/query-params.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  public static log = new Array<LogEntry>();
  public static clientId =  'Unauthorized';
  public static appVersion: string;
  private static logFilter = new LogFilter();

  private static logUnfiltered = new Array<LogEntry>();
  private static initialized: boolean;
  private static deleting: boolean;

  private static logThresholdInMinutes = 30240; // 21 weeks
  private static logThresholdInLines = 10000;
  private static logMinimalSizeBeforeGarbageCollection = 3000;

  private static newLogMessageIndicator = new BehaviorSubject<LogLevel | null>(null);
  private static newMessageIndicationInterrupter = new Subject<void>();
  private static newMessageIndicationIntervalInMilliseconds = 60000;

  constructor() {

  }

  private static initialize() {
    if (!LoggingService.initialized) {
      LoggingService.loadLogsFromLocalStorage();
      if (LoggingService.logUnfiltered.length > 0) {
        const lastMessage = LoggingService.logUnfiltered[LoggingService.logUnfiltered.length - 1];
        if (moment().isBefore(moment(lastMessage.eventTimeString)
            .add(LoggingService.newMessageIndicationIntervalInMilliseconds, 'millisecond'))
          && (lastMessage.level === LogLevel.WARNING || lastMessage.level === LogLevel.ERROR)
          && !lastMessage.viewed) {
          LoggingService.newMessageIndicationInterrupter.next();
          LoggingService.newLogMessageIndicator.next(lastMessage.level);
          timer(LoggingService.newMessageIndicationIntervalInMilliseconds)
            .pipe(takeUntil(LoggingService.newMessageIndicationInterrupter))
            .subscribe(() => LoggingService.newLogMessageIndicator.next(null));
        }
      }
      LoggingService.filter(new LogFilter());
      LoggingService.initialized = true;
      LoggingService.logDebug(LoggingSource.CORE, 'Application log loaded and initialized');

      setInterval(() => {
        LoggingService.saveLogsToLocalStorage();
      }, 3000);
    }
  }

  private static loadLogsFromLocalStorage() {
    try {
      // LocalStorageManager service is not used here, to avoid circular dependencies!
      const rawLogs = localStorage.getItem('applicationLogs');
      const logs: Array<LogEntry> = JSON.parse(rawLogs);

      logs.forEach(l => {
        if (l.errorObject) {
          l.error = deserializeError(l.errorObject);
          l.errorMessage = `${ l.error.message} (${ l.error.name})`;
        }

      })

      if (logs && logs.length > 0) {
        logs.push(...LoggingService.logUnfiltered);
        LoggingService.logUnfiltered = logs;
      }

    } catch (error) {
      console.log('Could not load logs from local storage.', error);
    }
  }

  private static saveLogsToLocalStorage() {
    if (LoggingService.deleting) {
      return;
    }
    try {
      LoggingService.initialize();
      LoggingService.removeLogs();

      // LocalStorageManager service is not used here, to avoid circular dependencies!
      localStorage.setItem('applicationLogs', JSON.stringify(LoggingService.log));
    } catch (error) {
      console.log(error);
    }
  }

  public static deleteLogs() {
    LoggingService.initialize();

    LoggingService.deleting = true;
    LoggingService.log = new Array<LogEntry>();
    LoggingService.logUnfiltered = new Array<LogEntry>();
    LoggingService.deleting = false;

    LoggingService.saveLogsToLocalStorage();
  }

  public static logDebug(source: LoggingSource, message: string) {
    LoggingService.initialize();

    if (!isDevMode()) {
      return;
    }

    if (QueryParamsService.isStarted && QueryParamsService.consoleLogOnly) {
      console.log(`[DEBUG] - [${new Date().toISOString()}] - [${source}] --- ${message}`);
      return;
    }

    const log = new LogEntry(source, LogLevel.DEBUG, message);
    log.clientId = LoggingService.clientId;
    log.appVersion = LoggingService.appVersion;

    if (!LoggingService.hasDuplicate(log)) {
      LoggingService.logUnfiltered.push(log);
    }

    LoggingService.filter();
  }

  public static logTrace(source: LoggingSource, message: string) {
    LoggingService.initialize();

    if (!QueryParamsService.isStarted || !QueryParamsService.traceLogEnabled) {
      return;
    }

    if (QueryParamsService.consoleLogOnly) {
        console.log(`[TRACE] - [${new Date().toISOString()}] - [${source}] --- ${message}`);
      return;
    }

    const log = new LogEntry(source, LogLevel.TRACE, message);
    log.clientId = LoggingService.clientId;
    log.appVersion = LoggingService.appVersion;

    if (!LoggingService.hasDuplicate(log)) {
      LoggingService.logUnfiltered.push(log);
    }

    LoggingService.filter();
  }

  public static logInfo(source: LoggingSource, message: string) {
    LoggingService.initialize();

    if (QueryParamsService.isStarted && QueryParamsService.consoleLogOnly) {
      console.log(`[INFO] - [${new Date().toISOString()}] - [${source}] --- ${message}`);
      return;
    }

    const log = new LogEntry(source, LogLevel.INFO, message);
    log.clientId = LoggingService.clientId;
    log.appVersion = LoggingService.appVersion;

    if (!LoggingService.hasDuplicate(log)) {
      LoggingService.logUnfiltered.push(log);
    }

    LoggingService.filter();
  }
  public static logWarning(source: LoggingSource, message: string, error?: Error) {
    LoggingService.initialize();

    if (QueryParamsService.isStarted && QueryParamsService.consoleLogOnly) {
      console.log(`[WARNING] - [${new Date().toISOString()}] - [${source}] --- ${message}`);

      if (error) {
        console.log(`[WARNING] - [${new Date().toISOString()}] - [${source}] --- ${error.message}`);
      }

      return;
    }

    if (this.newLogMessageIndicator.value !== LogLevel.ERROR) {
      LoggingService.newMessageIndicationInterrupter.next();
      LoggingService.newLogMessageIndicator.next(LogLevel.WARNING);
      timer(LoggingService.newMessageIndicationIntervalInMilliseconds)
        .pipe(takeUntil(LoggingService.newMessageIndicationInterrupter))
        .subscribe(() => LoggingService.newLogMessageIndicator.next(null));
    }

    const log = new LogEntry(source, LogLevel.WARNING, message, error);
    log.clientId = LoggingService.clientId;
    log.appVersion = LoggingService.appVersion;

    if (!LoggingService.hasDuplicate(log)) {
      LoggingService.logUnfiltered.push(log);
    }

    LoggingService.filter();
  }

  public static logError(source: LoggingSource, message: string, error?: Error) {
    LoggingService.initialize();

    LoggingService.newMessageIndicationInterrupter.next();
    LoggingService.newLogMessageIndicator.next(LogLevel.ERROR);

    timer(LoggingService.newMessageIndicationIntervalInMilliseconds)
      .pipe(takeUntil(LoggingService.newMessageIndicationInterrupter))
      .subscribe(() => LoggingService.newLogMessageIndicator.next(null));


    if (QueryParamsService.isStarted && QueryParamsService.consoleLogOnly) {
      console.log(`[ERROR] - [${new Date().toISOString()}] - [${source}] --- ${message}`);

      if (error) {
        console.log(`[ERROR] - [${new Date().toISOString()}] - [${source}] --- ${error.message}`);
      }

      return;
    }


    const log = new LogEntry(source, LogLevel.ERROR, message, error);
          log.clientId = LoggingService.clientId;
          log.appVersion = LoggingService.appVersion;


    if (!LoggingService.hasDuplicate(log)) {
      LoggingService.logUnfiltered.push(log);
    }

    LoggingService.filter();
  }

  public static createExportFile(lineLimit: number = null): Blob {
    try {
      LoggingService.initialize();

      if (!LoggingService.logUnfiltered || !LoggingService.logUnfiltered.length) {
        return;
      }

      let logsToExport: LogEntry[];
      if (lineLimit) {
        logsToExport = LoggingService.logUnfiltered.slice(LoggingService.logUnfiltered.length - lineLimit, LoggingService.logUnfiltered.length);
      } else {
        logsToExport = LoggingService.logUnfiltered;
      }

      const separator = ';';

      const keys = new Array<string>();
      keys.push('eventTimeString');
      keys.push('level');
      keys.push('message');
      keys.push('errorMessage');
      keys.push('clientId');
      keys.push('appVersion');
      keys.push('duplicateCounter');

      const textFileContentContent =
        keys.join(separator) +
        '\n' +
        logsToExport.map(row => {
          return keys.map(k => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k];
            cell = cell instanceof Date
              ? cell.toLocaleString()
              : cell.toString().replace(/"/g, '""');
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          }).join(separator);
        }).join('\n');

      return new Blob([textFileContentContent], { type: 'text/plain' });
      // return new Blob([textFileContentContent], { type: 'text/text;charset=utf-8;' });
    } catch (error) {
      LoggingService.logError(LoggingSource.CORE, 'Error occurred exporting logfile.', error);
    }
  }

  public static export() {
    try {
      const blob = LoggingService.createExportFile();
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'cpp-applicationlog-' + (new Date()).getTime() + '.txt');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      LoggingService.logError(LoggingSource.CORE, 'Error occurred exporting logfile.', error);
    }
    }

  private static hasDuplicate(logEntry: LogEntry): boolean{
    const existingLogEntryIndex = LoggingService.logUnfiltered.findIndex(l =>
      l.eventTimeString === logEntry.eventTimeString &&
      l.clientId === logEntry.clientId &&
      l.source === logEntry.source &&
      l.level === logEntry.level &&
      l.message === logEntry.message);

    if (existingLogEntryIndex === -1) {
      return false;
    } else {
      LoggingService.logUnfiltered[existingLogEntryIndex].duplicateCounter++;
      return true;
    }
  }

  private static removeLogs() {
    try {
      if (LoggingService.logUnfiltered.length <= LoggingService.logMinimalSizeBeforeGarbageCollection) {
        return;
      }

      if (LoggingService.logUnfiltered.length > LoggingService.logThresholdInLines) {
        const linesOverThreshold = LoggingService.logUnfiltered.length - LoggingService.logThresholdInLines;

        LoggingService.logUnfiltered.splice(0, linesOverThreshold);
      }

      let checkDate: Date;
      checkDate = new Date();
      checkDate.setMinutes(checkDate.getMinutes() - LoggingService.logThresholdInMinutes);

      const currentLogs = LoggingService.log;
      LoggingService.logUnfiltered = currentLogs.filter(l => l.eventTimeString > checkDate.toISOString());

      const difference = currentLogs.length - LoggingService.log.length;
      if (difference > 0) {
        LoggingService.logInfo(LoggingSource.CORE,
          'Removed ' + difference + ' old log entries. Threshold currently: ' + LoggingService.logThresholdInMinutes + ' minutes');
      }

    } catch(error) {
      LoggingService.logError(LoggingSource.CORE, 'Error occurred cleaning up old logs!', error);
    }
  }

  public static filter(listFilter?: LogFilter) {
    if (listFilter !== null && listFilter !== undefined) {
      LoggingService.logFilter = listFilter;
    }

    let filtered = LoggingService.logUnfiltered;
    if (LoggingService.logFilter.source) {
      filtered = filtered.filter(l => l.source?.includes(LoggingService.logFilter.source));
    }

    if (LoggingService.logFilter.message) {
      filtered = filtered.filter(l => l.message?.includes(LoggingService.logFilter.message));
    }

    LoggingService.log = filtered;
  }

  public static getNewMessageIndicator(): Observable<LogLevel | null> {
    return LoggingService.newLogMessageIndicator.asObservable();
  }

  public static clearMessageIndication(){
    if (LoggingService.logUnfiltered.length > 0) {
      const lastMessage: LogEntry = LoggingService.logUnfiltered[LoggingService.logUnfiltered.length - 1];
      if (lastMessage) {
        lastMessage.viewed = true;
      }
    }
    LoggingService.newMessageIndicationInterrupter.next();
    LoggingService.newLogMessageIndicator.next(null);
  }
}
