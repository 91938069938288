<ng-template #contentTemplate>
<div class="wrapper" xmlns="http://www.w3.org/1999/html">
  <!-- Navbar Top -->
  <op-navbar-top [isLoggedIn]="loggedIn"
                 [currentUser]="currentUser"
                 [kioskMode]="kioskMode"
                 (logoutClickEvent)="onLogout()"
  ></op-navbar-top>

  <!-- Sidebar  -->
  <op-sidebar *ngIf="!kioskMode" [currentUser]="currentUser" [loggedIn]="loggedIn" (onSidebarCollapsed)="sidebarCollapsed = $event"></op-sidebar>
  <!-- Page Content  -->
  <div id="content" [class.w-100]="kioskMode" [class.login]="!loggedIn" [class.active]="sidebarCollapsed">
    <router-outlet></router-outlet>

    <ng-container *ngIf="EnterpriseRoleProviderService.claimMissing">
      <op-permission-missing></op-permission-missing>
    </ng-container>
  </div>
  <!-- Footer -->
  <op-footer [PortalVersion]=portalVersion [NewPortalVersion]="newPortalVersion" [kioskMode]="kioskMode" (toggleKioskMode)="toggleKioskMode()" (toggleLogMode)="toggleLogMode()"></op-footer>
</div>
</ng-template>

<ng-container *ngIf="pageMode === PageMode.WELCOME_SCREEN || pageMode === PageMode.LANDING_ZONE; else contentTemplate">
<div id="fullScreenContent" [class.w-100]="kioskMode" [class.login]="!loggedIn">
  <router-outlet></router-outlet>
</div>
</ng-container>

<ng-container *ngIf="logMode">
  <op-logging></op-logging>
</ng-container>
