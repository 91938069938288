<nav class="navbar navbar-expand navbar-dark bg-dark fixed-top pt-0 pb-0">
  <!-- Navbar Logo und Title -->
  <div class="navbar-header">
    <a class="navbar-brand" [href]="kioskMode ? initiallyCalledUrl : getHomeUrl()">
      <img *opChristmas="true" src="../../../assets/img/santa.png" width="24px" height="24px" style="transform:rotate(315deg); margin-top: -32px; margin-right: -10px"/>
      <img src="../../../assets/img/logo_white.png" alt="" class="d-inline-block"
                                           width="30" height="30">
      <div class="d-none d-sm-none d-md-inline d-lg-inline d-xl-inline">
        {{ portalMode === 'SZO' ? 'SZOnline 2.0' : ( 'navbar.title.cpo' | translate ) }}</div>
    </a>
  </div>

  <!-- Show a identifier for the stage after login -->
  <ng-container *ngIf="!isProductionStage() && currentUser && !kioskMode">
    <div class="d-none d-sm-none d-md-block font-weight-bold" [class.cpp-stage-label-develop]="currentStage === 'develop' || currentStage === 'localhost'"
           [class.cpp-stage-label-integration]="currentStage === 'integration'">
        {{ currentStage.toUpperCase() }}</div>
  </ng-container>


  <!-- Navbar Collapse open toggle -->
  <ng-container *ngIf="currentUser && !kioskMode">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"
            aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  </ng-container>

  <div class="container-fluid pr-0">
    <ng-container *ngIf="isLoggedIn">
      <div class="collapse navbar-collapse" id="navbar">
        <ul class="navbar-nav mr-auto">
          <li *ngIf="!kioskMode" class="navbar-item align-self-center" >
            <div class="d-inline d-lg-none">
              <a [href]="getHomeUrl()"><i class="fas fa-home cpp-icon-size nav-link"></i></a>
            </div>
            <div class="d-none d-lg-inline">
            <a class="nav-link" [href]="getHomeUrl()">{{ 'navbar.dropdown.home.name' | translate }}<span
              class="sr-only">(current)</span></a>
            </div>
          </li>
          <li *ngIf="!kioskMode && !deviceLocation?.IsValid" class="navbar-item align-self-center">
            <a class="nav-link d-flex" *opRequiredRole="'role_admin'" [routerLink]="'/admin/home'"><i
              class="fas fa-wrench cpp-icon-size align-self-center" title="Admin Area"></i></a>
          </li>
          <li *ngIf="!kioskMode" class="navbar-item">
            <op-select-box class="nav-link text-light" (selectEvent)="languageSelected($event)"
                           [options]="languages"></op-select-box>
          </li>
          <span *ngIf="!kioskMode && !deviceLocation?.IsValid" class="navbar-item d-none d-lg-block pl-5">
              <img src="../../../assets/img/internalEN_DE.svg" alt="" class="d-inline-block">
          </span>

          <ng-container *ngIf="currentUser && !kioskMode && !deviceLocation?.IsValid">
            <div class="d-none d-xl-inline small pl-5" *opRequiredRole="'role_admin'">
              <div *ngIf="tokenCheckFinished">
              <div style="color: darkgoldenrod; font-weight: bold">{{ tokenAboutToExpire }} {{ 'navbar.tokenAboutToExpire' | translate }}</div>
              <div style="color: indianred; font-weight: bold">{{ tokenExpired }} {{ 'navbar.tokenExpired' | translate }}</div>
              </div>
              <div *ngIf="!tokenCheckFinished">
                <div style="color: indianred; font-weight: bold">{{ 'navbar.tokenCheckPending' | translate }}</div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="kioskMode && initiallyCalledUrl">
              <a [href]="initiallyCalledUrl">
                <i class="fas fa-home align-bottom" style="font-size: larger"></i>
              </a>
          </ng-container>

            <ng-container *ngIf="deviceLocation?.IsValid">
              <button class="btn btn-danger ml-3 pt-1 pb-1 d-none d-xl-inline" (click)="callForHelp()" [class.helpCallActive]="helpCalled">H I L F E R U F</button>
            </ng-container>

            <ng-container *ngIf="deviceLocation?.IsValid">
              <button class="btn btn-warning ml-3 pt-1 pb-1 d-none d-xl-inline" (click)="callForSupervisor()" [class.superVisorCallActive]="supervisorCalled">Unterstützung anfordern</button>
            </ng-container>

        </ul>
        <div *ngIf="kioskMode" class="d-none d-lg-inline pr-3">
          <div style="color: white; font-size: larger">{{ ConfigService.headerTitle }}</div>
        </div>

        <div class="pl-1 pr-1">
          <button class="btn btn-danger btn-sm" (click)="createErrorReport()" [tooltip]="errorReportTooltip">
            <i class="fas fa-times"></i>
          </button>
        </div>

        <ng-template #errorReportTooltip>
          Hier klicken um einen Problembericht zu erstellen!
        </ng-template>

        <div *ngIf="EnterpriseRoleProviderService.hasEffectiveRoles">
          <a href="https://volkswagengroup.sharepoint.com/sites/SZ-Online2.0/SitePages/Startseite.aspx" target="_blank">
            <i [class.pr-0]="!currentUser.applicationUser" class="far fa-question-circle navbar-text cpp-icon-size nav-link" ></i>
          </a>
        </div>

        <span *ngIf="!currentUser.applicationUser && EnterpriseRoleProviderService.hasEffectiveRoles">
            <div>
              <a [routerLink]="'/user/inbox'">
              <i class="fas fa-envelope navbar-text pr-3 cpp-icon-size nav-link"></i>
              <span class="badge badge-pill badge-danger small"
                    style="position: absolute; margin-left: -25px; margin-top: 5px; z-index: 100; font-weight: lighter">{{ unReadMessageCounter !== 0 ? unReadMessageCounter : '' }}</span>
                </a>
            </div>
          </span>

        <!-- User information area -->
        <span class="navbar-text">
        <ng-container *ngIf="currentUser">
          <ul class="navbar-nav mr-auto">
            <li *ngIf="!kioskMode" class="navbar-item dropdown">
              <a class="nav-link" [class.dropdown-toggle]="!currentUser.applicationUser" href="#" id="navbarDropdownUser" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">

                <div class="d-inline d-lg-none"><i class="fas fa-user cpp-icon-size"></i></div>
                <div class="pl-1 d-none d-lg-inline">{{ currentUser.name }}</div>
              </a>
              <div *ngIf="!currentUser.applicationUser" class="dropdown-menu" aria-labelledby="navbarDropdownUser">
                  <a class="dropdown-item" style="color: black" [routerLink]="'user/inbox'">
                     {{ 'navbar.dropdown.user.inbox' | translate }}
                  </a>
                  <a class="dropdown-item" style="color: black" [routerLink]="'/user/profile'">
                    {{ 'navbar.dropdown.user.profile' | translate }}
                  </a>
              </div>
            </li>

            <li *ngIf="!kioskMode && !deviceLocation?.IsValid" class="navbar-item">
              <div class="d-inline d-lg-none">
                <i class="fas fa-sign-out-alt navbar-text cpp-icon-size nav-link" style="padding-top: 10px" (click)="onLogoutClick()"></i>
              </div>
              <div class="d-none d-lg-inline">
                <button (click)="onLogoutClick()" class="btn btn-primary">{{ 'button.logout' | translate }}
                </button>
              </div>
            </li>



            <div *ngIf="kioskMode">
                <div class="d-inline d-lg-none">
                  <i class="fas fa-user cpp-icon-size nav-link mr-1"></i>
                </div>
                <div class="pl-1 d-none d-lg-inline">
                  {{ currentUser.name }}
                </div>
            </div>
          </ul>
        </ng-container>
      </span>
      </div>
    </ng-container>
  </div>
</nav>
