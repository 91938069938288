import {Injectable, isDevMode, OnDestroy} from '@angular/core';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {ConfigService} from './config.service';
import {NavigationExtras, Router} from '@angular/router';
import {User} from '../models/user';
import {TokenData} from '../models/token';
import {Subject} from 'rxjs';
import {ErrorHandler} from '../../shared/services/error-handler/error-handler';
import {LocalStorageManagerService} from '../../shared/services/local-storage-manager.service';
import {EnterpriseRoleProviderService} from './enterprise-role-provider.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  constructor(private oauthService: OAuthService,
              private configService: ConfigService,
              private router: Router){
  }

  public static userName: string;
  currentUser: User;
  isApplicationUser: boolean;

  private authorized: Promise<boolean>;
  public loggedIn = new Subject<boolean>();

  public configureAuth(): Promise<boolean> {
    if (this.configService.getApplicationToken()) {
      this.isApplicationUser = true;
      EnterpriseRoleProviderService.isApplication = true;

      this.authorized = this.applicationLogin().then(() => {
        this.loggedIn.next(true);
        return Promise.resolve(true);
      });
    } else {
      this.oauthService.configure(this.getAuthConfig());
      this.oauthService.setStorage(localStorage);
      this.authorized = this.oauthService.tryLoginCodeFlow()
        .then(() => {
          if (!this.oauthService.hasValidAccessToken() || !this.oauthService.hasValidIdToken()) {
            this.loggedIn.next(false);
            return Promise.resolve(false);
          } else {
            const result = this.initUserAndCheckAuth();
            this.loggedIn.next(result);
            return Promise.resolve(result);
          }
        })
        .then(isAuth => {
          if (isAuth && !this.isApplicationUser) {
            const authState = this.oauthService.state;
            if (authState) {
              const s = decodeURIComponent(authState);
              const querieString = LocalStorageManagerService.getItem('storedQueries');
              const anchorString = LocalStorageManagerService.getItem('storedAnchor');
              const navExtras: NavigationExtras = {};
              if (querieString) {
                const queries = {};
                querieString.split('&').forEach(entry => {
                  const querie = entry.split('=');
                  if (querie.length === 2) {
                    queries[querie[0]] = querie[1];
                  }
                });
                navExtras.queryParams = queries;
                LocalStorageManagerService.removeItem('storedQueries');
              }
              if (anchorString) {
                navExtras.fragment = anchorString;
                LocalStorageManagerService.removeItem('storedAnchor');
              }
              this.router.navigate([s], navExtras);
            }
          }

          this.oauthService.setupAutomaticSilentRefresh();
          this.oauthService.events
            .subscribe(event => {
              if (isDevMode()) {
                console.log(new Date().toLocaleString() + ': Following oAuth Event occurred: ' + event.type);
              }
            });

          this.loggedIn.next(isAuth);
          return Promise.resolve(isAuth);
        });
    }

    this.loggedIn.next(false);
    return Promise.resolve(false);
  }

  public getToken(): string {
    return this.oauthService.getAccessToken();
  }

  public isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken() &&
      this.oauthService.getIdentityClaims() !== null && this.oauthService.getIdentityClaims() !== undefined;
  }
  public isAuthorized(): Promise<boolean> {
    return this.authorized;
  }

  public logout() {
    if (this.isApplicationUser) {
      LocalStorageManagerService.removeItem('appName');
      LocalStorageManagerService.removeItem('appToken');
      const params = new URLSearchParams(location.search);
      params.delete('appName');
      params.delete('appToken');
      if (params.toString() !== '') {
        window.history.replaceState({}, '', `${location.pathname}?${params}`);
      } else {
        window.history.replaceState({}, '', `${location.pathname}`);
      }
      location.reload();
    } else {
      this.oauthService.logOut();
    }
  }

  public login(redirectPath: string) {
    this.oauthService.initCodeFlow(redirectPath);
  }

  ngOnDestroy(): void {
    this.oauthService.ngOnDestroy();
  }

  private initUserAndCheckAuth() {
    const identityClaims = this.oauthService.getIdentityClaims() as any;
    EnterpriseRoleProviderService.tokenGroups = identityClaims?.groups as Array<string>;

    this.currentUser = new User(TokenData.of(identityClaims));
    AuthService.userName = this.currentUser.username;


    if (!EnterpriseRoleProviderService.tokenGroups || EnterpriseRoleProviderService.tokenGroups.length < 1) {
      return false;
    }

    return true;
  }

  private getAuthConfig(): AuthConfig {
    return {
      // Url of the Identity Provider
      issuer: this.configService.getAuthUrl(),

      // URL of the SPA to redirect the user to after login
      redirectUri: origin,

      postLogoutRedirectUri: origin,

      // The SPA's id. The SPA is registered with this id at the auth-server
      clientId: this.configService.getClientId(),

      // set the scope for the permissions the client should request
      // The first three are defined by OIDC. The 4th is a usecase-specific one
      scope: 'openid offline_access',

      responseType: 'code',

      // we need to this, in order to be able to use PKI authentication in Firefox. Use "discover"
      // does not work, because of SSL reuse in Firefox
      tokenEndpoint: this.configService.getAuthUrl() + '/protocol/openid-connect/token',
      loginUrl: this.configService.getAuthUrl() + '/protocol/openid-connect/auth',
      logoutUrl: this.configService.getAuthUrl() + '/protocol/openid-connect/logout',
    };
  }

  async applicationLogin() {
    const appName = this.configService.getApplicationName();
    this.currentUser = new User(new TokenData(
        appName + '@cpp', appName, appName), true)

    this.currentUser.applicationUser = true;

    AuthService.userName = this.currentUser.username;

    this.isApplicationUser = true;

    fetch(this.configService.getAmsApiUrl() + '/api/v1/application/self', {
      headers: {
        accept: 'application/json',
        authorization: 'Basic ' + this.configService.getApplicationToken(),
      }
    }).then(_ => {
      _.json().then(() => {
        this.authorized = Promise.resolve(true);
        this.loggedIn.next(true);
      }).catch(error => {
         ErrorHandler.printError(error);

        this.authorized = Promise.resolve(false);
        this.loggedIn.next(false);
        this.currentUser = undefined;
        this.configService.removeApplicationCredentials();
        this.router.navigate(['login']);
      });
    });
  }
}
