import {LabelType} from './labelType';
import {Engine} from '../../../../../shared/services/backend/models/engine';
import {Part} from '../../../../../shared/services/backend/models/part';
import {DmcParseResult} from '../../parse/dmcParseResult';
import {StackV2} from '../../../../../shared/services/backend/models/stackv2';

export class ScannerResult {
  public value: string;
  public labelType: LabelType;
  public labelFormat: string;
  public refId: string;
  public refLink: string;

  public engine: Engine;
  public stack: StackV2;
  public part: Part;
  public dmcResult: DmcParseResult;
  public blocked: boolean;
  public selected: boolean;
  public entityLoaded: boolean;
}
