<form [formGroup]="editDispatcherTask" xmlns="http://www.w3.org/1999/html" (ngSubmit)="onSubmit()">
  <div style="height: calc(100vh - 420px); overflow: scroll">
    <div class="form-group row no-gutters mt-2 mr-3">
      <label for="commentInput" class="col-4 col-form-label" style="font-weight: 500;">
        {{ 'i18n.metadata.buildabiltity-report.dispatch-form.valid-until' | translate }}
      </label>
      <div [id]="inputId + 'Par'" class="col">
        <button class="btn btn-primary form-control"
                type="button"
                [id]="inputId"
                [disabled]="blocked">
          <ng-container *ngIf="!editDispatcherTask.get('amountValidUntil').getRawValue()">
            {{ 'i18n.metadata.buildabiltity-report.dispatch-form.select-date'| translate }}
          </ng-container>
          <ng-container *ngIf="editDispatcherTask.get('amountValidUntil').getRawValue()">
            {{ editDispatcherTask.get('amountValidUntil').getRawValue().toDate() | date: 'dd.MM.yyyy' }}
          </ng-container>
        </button>
      </div>
    </div>
    <div class="form-group row no-gutters mr-3">
      <label for="commentInput" class="col-4 col-form-label" style="font-weight: 500;">
        {{ 'i18n.metadata.buildabiltity-report.dispatch-form.amount' | translate }}
      </label>
      <div class="col">
          <input class="form-control"
                 id="amountInput"
                 formControlName="amount">
      </div>
    </div>
    <div class="form-group row no-gutters mr-3">
      <label for="commentInput" class="col-4 col-form-label" style="font-weight: 500;">
        {{ 'i18n.metadata.buildabiltity-report.dispatch-form.comment' | translate }}
      </label>
      <div class="col">
          <textarea class="form-control"
                    id="commentInput"
                    formControlName="comment"
                    rows="3">
          </textarea>
      </div>
    </div>
    <div class="form-group row no-gutters mr-3">
      <label for="stateInput" class="col-4 col-form-label" style="font-weight: 500;">
        {{ 'i18n.globals.state' | translate }}
      </label>
      <div class="col">
          <select class="form-control pointer"
                  id="stateInput"
                  formControlName="state">
            <option disabled value="New">{{ 'i18n.metadata.buildabiltity-report.dispatch-form.states.new' | translate }}</option>
            <option value="InProgress">{{ 'i18n.metadata.buildabiltity-report.dispatch-form.states.in-progress' | translate }}</option>
            <option value="Resolved">{{ 'i18n.metadata.buildabiltity-report.dispatch-form.states.resolved' | translate }}</option>
            <option disabled value="Closed">{{ 'i18n.metadata.buildabiltity-report.dispatch-form.states.closed' | translate }}</option>
            <option disabled value="Reopened">{{ 'i18n.metadata.buildabiltity-report.dispatch-form.states.reopened' | translate }}</option>
            <option value="NotResolvable">{{ 'i18n.metadata.buildabiltity-report.dispatch-form.states.not-resolvable' | translate }}</option>
          </select>
      </div>
    </div>

    <div class="form-group row no-gutters mr-3">
      <label for="alternativePartnumberInput" class="col-4 col-form-label" style="font-weight: 500;">
        {{ 'i18n.metadata.partnumberdefinitions.alternativePartnumbers' | translate }}
      </label>
      <div class="col">
        <select class="form-control pointer"
                id="alternativePartnumberInput"
                formControlName="alternativePartnumber">
          <option [value]="partNumber">{{ partNumber }}</option>
          <option *ngFor="let alternative of alternativePartNumbers" [value]="alternative">
            {{alternative}}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="form-group row no-gutters">
    <div class="col">
      <button class="btn btn-success float-right" [disabled]="blocked || editDispatcherTask.invalid" type="submit">{{ 'i18n.globals.actions.save' | translate  }}</button>
      <button class="btn btn-danger float-right mr-2" (click)="abortEdit()">{{ 'i18n.globals.actions.abort' | translate  }}</button>
    </div>
  </div>
</form>

