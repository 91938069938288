import { Injectable } from '@angular/core';
import {LoadingEvent} from '../models/loadingEvent';


@Injectable({
  providedIn: 'root'
})
export class LoadingEventReceiverService {

  public static loadingEventReceivers: { id: string, loadingEvent: LoadingEvent } [];

  constructor() { }

  public static addEvent(id: string, loadingEvent: LoadingEvent) {
    if (!LoadingEventReceiverService.loadingEventReceivers) {
      LoadingEventReceiverService.loadingEventReceivers = [{ id, loadingEvent }];
    } else {
      const existingEvent = LoadingEventReceiverService.loadingEventReceivers?.find(o => o.id === id);

      if (existingEvent) {
        existingEvent.loadingEvent = loadingEvent;
      } else {
        LoadingEventReceiverService.loadingEventReceivers.push({ id, loadingEvent });
      }
    }
  }

  public static getEvent(id: string) : LoadingEvent {
    return LoadingEventReceiverService.loadingEventReceivers?.find(o => o.id === id)?.loadingEvent;
  }
}
