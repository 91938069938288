import {FilterBase} from '../../../../models/filterBase';
import {Slice} from '../../../../models/slice';
import {PartStatus, QuickTimeSelector} from '@cstx/volkswagen-mqs-part-service-client';


// import PartStatusEnum = PartResponse.PartStatusEnum;

export class PartFilter extends FilterBase {
  private _dmc = '';
  public partNumber: Array<string> = [];
  public manufacturerCode: Array<string> = [];
  public parentId = '';
  public partType = '';
  public productionDateFrom?;
  public productionDateTo?;
  public costCenter: Array<string> = [];
  public slice: Slice;
  public partState = '';
  public sequenceNumberFrom?: number;
  public sequenceNumberTo?: number;
  public partBlockedState?: 'NONE' | 'BLOCKED';
  public humanReadableId: string;
  public quickTimeSelector?: QuickTimeSelector;

  public buildDateFrom?;
  public buildDateTo?;

  constructor() {
    super();

    this.pagingSize = 50;
    this.totalPages = 1;
    this.currentPageIndex = 0;
    this.sort = [ 'modifiedAt,desc' ];
  }

  get dmc(): string {
    return this._dmc.trimEndCharacter('_');
  }
  set dmc(dmc: string) {
    this._dmc = dmc;
  }

  get rawDmc(): string {
    return this._dmc;
  }

  public IsEmpty(): boolean {
    if (this.dmc !== '' ) { return false; }
    if (this.costCenter.length !== 0 ) { return false; }
    if (this.partNumber.length !== 0 ) { return false; }
    if (this.manufacturerCode.length !== 0) { return false; }
    if (this.parentId !== '' ) { return false; }
    if (this.partType !== '' ) { return false; }
    if (this.productionDateFrom || this.productionDateTo ) { return false; }
    if (this.buildDateFrom || this.buildDateTo ) { return false; }
    if (this.partState !== '' ) { return false; }
    if (this.sequenceNumberFrom !== undefined) {return false; }
    if (this.sequenceNumberTo !== undefined) {return false; }
    if (this.humanReadableId !== undefined) {return false; }

    return true;
  }

  public getAllParams() {
    return {...this.getPageableParams(), ...this.getFilterParamsNew()};
  }

  public getMappedPartState(){
    switch (this.partState) {
      case 'COMPLETE':
        return PartStatus.Complete;
      case 'PARTIAL':
        return PartStatus.Partial;
      case 'INCOMPLETE':
        return PartStatus.Incomplete;
      default:
        return undefined
    }
  }

  public getFilterParamsNew() {
    return {
      parentId: this.parentId,
      partNumber: this.partNumber,
      manufacturerCode: this.manufacturerCode,
      dmc: this.dmc,
      partType: this.partType,
      productionDateFrom: this.productionDateFrom,
      productionDateTo: this.productionDateTo,
      buildDateFrom: this.buildDateFrom,
      buildDateTo: this.buildDateTo,
      costCenter: this.costCenter,
      partState: this.partState ? this.partState as any : undefined,
      sequenceNumberFrom: this.sequenceNumberFrom,
      sequenceNumberTo: this.sequenceNumberTo,
      partBlockedState: this.partBlockedState,
      humanReadableId: this.humanReadableId
    };
  }

  public getFilterParams() {
    return {
      parentId: this.parentId,
      partNumber: this.partNumber.pop(),
      manufacturerCode: this.manufacturerCode.pop(),
      dmc: this.dmc,
      partType: this.partType,
      productionDateFrom: this.productionDateFrom,
      productionDateTo: this.productionDateTo,
      costCenter: this.costCenter.pop(),
      partState: this.partState ? this.partState as any : undefined,
      sequenceNumberFrom: this.sequenceNumberFrom,
      sequenceNumberTo: this.sequenceNumberTo,
      partBlockedState: this.partBlockedState,
      partBlockedState2: this.partBlockedState,
      partBlockedState3: this.partBlockedState,
      buildDateFrom: this.buildDateFrom,
      buildDateTo: this.buildDateTo,
    };
  }

  public getPageableParams() {
    return {
      page: this.currentPageIndex,
      size: this.pagingSize,
      sort: this.sort,
    };
  }
}
