<div class="container-fluid pl-3 pb-3 cpp-page-defaults">
  <div class="pt-2 pb-2">
    <op-breadcrumb version2="true" [path]="'Home/message-create'"></op-breadcrumb>
  </div>

  <div class="table pt-3">
    <div class="row pb-3">
      <div class="col font-weight-bold" style="font-size: 2em">
        {{ 'send-message.new-message' | translate  }}
      </div>
    </div>
    <div class="row">
      <div class="col">
      <ng-container *ngIf="!messageGenerating && SendMessageService.messageReady && messageType === MessageType.PROBLEM_REPORT">
        <op-message
          [attachments]="attachments"
          [fixedRecipients]="fixedRecipients"
          [closeIconEnabled]="false"
          [addNoteEnabled]="true"
          [defaultMessageContent]="body"
          [subject]="subject"
          [noteRowsCount]="35"
          [optionalProvideSendMeCopy]="!EnterpriseRoleProviderService.isApplication"
          (onMessageSend)="messageSend()"
          [createJiraTicket]="ConfigService.currentStage === STAGE.INTEGRATION || ConfigService.currentStage === STAGE.PRODUCTION"
          (onCancel)="messageSendAborted()">
        </op-message>
      </ng-container>


      <ng-container *ngIf="messageType === MessageType.DEFAULT || !SendMessageService.messageReady">
        <op-message
          [closeIconEnabled]="false"
          [addNoteEnabled]="true"
          [noteRowsCount]="10"
          [fixedRecipients]="fixedRecipients"
          [defaultMessageContent]="body"
          [subject]="subject"
          [optionalProvideSendMeCopy]="!EnterpriseRoleProviderService.isApplication"
          (onMessageSend)="messageSend()"
          (onCancel)="messageSendAborted()">
        </op-message>
      </ng-container>

      </div>
    </div>
  </div>
</div>
