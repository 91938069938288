<div class="input-group">
  <div id="select" class="dropdown">
      <button #selectButton class="btn-sm btn-outline-dark" type="button" data-toggle="dropdown" aria-expanded="false" (click)="search = ''">
          <span *ngIf="allOptions?.get(selectedOptionId)?.config?.color" class="color-point mr-1"
              [style.background-color]="allOptions?.get(selectedOptionId)?.config?.color"></span>
          {{ allOptions?.get(selectedOptionId)?.name ?? selectedOptionId }}
          <span class="fa fa-chevron-down"></span>
      </button>
      <div class="dropdown-menu">
          <input type="text" class="form-control select-search" [(ngModel)]="search" [placeholder]="placeholderSearch" (keyup.enter)="selectSetValue(search)" />
          <ng-container *ngFor="let option of options | keyvalue">
              <ng-container
                  *ngTemplateOutlet="optionEntry; context: {option: option.value, level: 0.75}"></ng-container>
          </ng-container>
      </div>
  </div>
</div>


<ng-template #optionEntry let-option="option" let-level="level">
  <div *ngIf="option.name.toLowerCase().includes(search.toLowerCase())" class="dropdown-item" (click)="selectSetValue(option.id)" [style.padding-left]="level + 'rem'">
      <span *ngIf="option.config?.color" class="color-point mr-1" [style.background-color]="option.config?.color"></span>
      <span>{{ option.name }}</span>
  </div>
  <ng-container *ngFor="let suboption of option.suboptions | keyvalue">
      <ng-container
          *ngTemplateOutlet="optionEntry; context: {option: suboption.value, level: level + 1}"></ng-container>
  </ng-container>
</ng-template>