import {Component, EventEmitter, Input, isDevMode, OnDestroy, OnInit, Output} from '@angular/core';
import {firstValueFrom, Subject, Subscription} from 'rxjs';
import {FilePreview} from '../filePreview';
import {LoggingService} from '../../../../core/logging/logging.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ComponentTypeEnum, DataFile, FileTypeEnum} from '@cstx/volkswagen-mqs-file-handling-service-client';
import {FileType} from '../fileType';
import {PreviewRequest} from '../previewRequest';
import {FileExplorerService} from '../file-explorer.service';
import {editor} from 'monaco-editor';
import {ErrorHandler} from '../../../services/error-handler/error-handler';
import {PreviewService} from './preview.service';
import {document, window} from 'ngx-bootstrap/utils';
import fileSize from 'filesize';
import {takeUntil} from 'rxjs/operators';
import {LoggingSource} from '../../../../core/logging/loggingSource';
import {ParseAndPreviewRequest} from '../parseAndPreviewRequest';
import {DfqParserService} from '../../../components/dfq-parser/dfq-parser.service';
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;

@Component({
  selector: 'op-usecase-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit, OnDestroy {
  constructor(private fileExplorerService: FileExplorerService,
              private sanitizer: DomSanitizer,
              private DfqParser: DfqParserService,) {
  }

  @Input() usecase: string;
  @Input() disabled: boolean;
  @Input() previewHeader = true;
  @Input() newPreviewRequest = new Subject<PreviewRequest>();
  @Input() newFilePreview = new Subject<FilePreview>();
  @Input() useCloseBtn = false;
  @Input() extendedPdfViewer: boolean;

  @Output() fileLoadedEvent: EventEmitter<FilePreview> = new EventEmitter<FilePreview>();
  @Output() triggerPreviewClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteRequestEndEvent = new EventEmitter<boolean>();

  protected readonly FileType = FileType;

  private previewRequest = new PreviewRequest();
  private onComponentDestroyed: Subject<boolean> = new Subject<boolean>();

  public filePreview = new FilePreview();
  public loading: boolean;
  public monacoEditorOptions: IStandaloneEditorConstructionOptions = {
    theme: 'vs-light',
    language: 'text/plain',
    readOnly: true,
    scrollBeyondLastLine: false,
    minimap: {enabled: false}
  };


  private static canUseMonacoEditor(fileName: string): boolean {
    if (!fileName) {
      return false;
    }

    let fileExtension = fileName.split('.').pop();
    fileExtension = fileExtension.toLowerCase();

    switch (fileExtension) {
      case 'csv':
      case 'txt':
      case 'xml':
      case 'json':
        return true;

      default:
        return false;
    }
  }



  resetEditorOptions() {
    this.monacoEditorOptions = {
      value: null,
      theme: 'vs-light',
      language: 'text/plain',
      readOnly: true,
      scrollBeyondLastLine: false,
      minimap: {enabled: false}
    };
  }

  public ngOnInit(): void {
    /**
     * New method using a static service as intermediate data storage
     */
    PreviewService.onPreviewEvent
      .pipe(takeUntil(this.onComponentDestroyed))
      .subscribe(preview => {
        try {
          if (preview instanceof FilePreview) {
            this.showExistingItem(preview);
          }

          if (preview instanceof PreviewRequest) {
            this.showNewItem(preview);
          }
        } catch (error) {
          ErrorHandler.printError(error, this.filePreview);
        }
      })


    /**
     * Deprecated method without services. Problem is, that subscriptions
     * will be reset once the component is re-initialized-
     */
    this.newPreviewRequest
      .pipe(takeUntil(this.onComponentDestroyed))
      .subscribe(request => {
        this.showNewItem(request);
      });

    this.newFilePreview
      .pipe(takeUntil(this.onComponentDestroyed))
      .subscribe(request => {
        this.showExistingItem(request);
      });
  }


  /**
   *
   * @param request The preview request containing all information needed to show a preview
   * @private
   *
   * This function takes the previewRequest and maps it to the view model. Then it decides based on the parserType
   * if we try to get a parsed result of the requested file or if we directly present the file.
   *
   */
  private runPreview(request: PreviewRequest) {
    this.loading = true;
    this.filePreview = new FilePreview();
    this.previewRequest = request;

    if (this.previewRequest.s3Key) {
      this.mapToUseCaseViewModel();
      this.processUseCaseFile();
    } else {
      this.mapToViewModel();
      this.processFile();
    }
  }

  private processUseCaseFile() {
    this.fileExplorerService.GetUseCaseFile(this.usecase, this.previewRequest.s3Key)
      .then(result => {
        this.filePreview.Blob = result;

        switch (this.filePreview.fileType) {
          case FileType.Image:
            this.processImageFile(result);
            break;
          case FileType.Text:
            this.processTextFile(result);
            break;
          case FileType.Pdf:
            this.processPdfFile(result);
            break;
          case FileType.Json:
            this.DfqParser.parseDFQ(result).then(translationResult => {
              this.processJSONFile(translationResult);
            })
            break;
          default:
            this.processOther(result);
        }
      })
      .catch(error => {
        ErrorHandler.printError(error);

        LoggingService.logError(LoggingSource.FILE_PREVIEW_USECASE,
          'Error occurred when downloading file: ' + this.filePreview.fileName,
          error);
        this.filePreview.HasError = true;

      })
      .finally(() => {
        this.filePreview.loadingComplete = true;
        this.loading = false;

        this.fileLoadedEvent.next(this.filePreview);
      });
  }

  private processJSONFile(file: JSON) {
    this.resetEditorOptions();
    this.filePreview.File = JSON.stringify(file, null, 2);
    this.monacoEditorOptions.value = this.filePreview.File;
    this.monacoEditorOptions.wordWrap = 'on';
    this.monacoEditorOptions.language = 'application/json';
  }

  private processFile() {
    this.fileExplorerService.GetFile(this.filePreview.parentId, this.filePreview.fileName,
      this.filePreview.parentType as ComponentTypeEnum, this.mapToFileHandlingFileType())
      .then(result => {
        this.filePreview.Blob = result;
        switch (this.filePreview.fileType) {
          case FileType.Image:
            this.processImageFile(result);
            break;
          case FileType.Text:
            this.processTextFile(result);
            break;
          case FileType.Pdf:
            this.processPdfFile(result);
            break;
          default:
            this.processOther(result);
        }
      })
      .catch(error => {
        ErrorHandler.printError(error);

        LoggingService.logError(LoggingSource.FILE_PREVIEW_USECASE,
          'Error occurred when downloading file: ' + this.filePreview.fileName,
          error);
        this.filePreview.HasError = true;
      })
      .finally(() => {
        this.filePreview.loadingComplete = true;
        this.loading = false;
      });
  }

  private processPdfFile(file: Blob) {
    this.filePreview.file = file;
  }

  private processTextFile(file: Blob) {
    this.resetEditorOptions();
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);

    fileReader.onloadend = () => {
      this.filePreview.File = fileReader.result;
      this.monacoEditorOptions.language = file.type;
      this.monacoEditorOptions.value = this.filePreview.File;
    };
  }

  private processImageFile(file: Blob) {
    this.filePreview.file = file;
    this.filePreview.objectUrl = this.sanitizer.bypassSecurityTrustUrl(this.filePreview.objectUrl as string);
    this.loading = false;
  }

  private processOther(file: Blob) {
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);

    fileReader.onloadend = () => {
      this.filePreview.File = fileReader.result;

      if (PreviewComponent.canUseMonacoEditor(this.filePreview.fileName)) {
        this.monacoEditorOptions.value = this.filePreview.File;
        this.monacoEditorOptions.language = file.type;
        this.filePreview.fileType = FileType.Text;
      } else {
        this.filePreview.HasError = true;
      }
    };
  }

  private processParsedFile(file: string) {
    if (this.filePreview.parserType === 'kaitai') {
      this.filePreview.File = JSON.stringify(file, null, 2);
      this.monacoEditorOptions.value = this.filePreview.File;
      this.monacoEditorOptions.language = 'application/json';

    } else if (this.filePreview.parserType === 'text') {
      this.filePreview.File = file;
      this.filePreview.Blob = new Blob([file], {
        type: 'text/plain'
      });
      this.filePreview.OriginalName = 'Steckbrief_' + this.filePreview.fileName + '.txt';
      this.monacoEditorOptions.value = this.filePreview.File;
      this.monacoEditorOptions.language = 'text/plain';
    }

    this.filePreview.Parsed = true;
  }


  /**
   *
   * @param file The file object we want to present for download
   * @private
   * This method triggers the browser file download dialog to be shown.
   * It provides the file already downloaded be client as download.
   */
  private openFileForDownload(file: Blob) {
    const objectUrl = window.URL.createObjectURL(file);
    const objectLinkElement = document.createElement('a');
    objectLinkElement.href = objectUrl;
    objectLinkElement.download = this.filePreview.OriginalName;
    objectLinkElement.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

    setTimeout(() => {
      window.URL.revokeObjectURL(objectUrl);
      objectLinkElement.remove();
    }, 100);
  }

  /**
   *
   * @private
   * This function maps the given FileType to the File-Handling-Service File-Type-Enum.
   */
  private mapToFileHandlingFileType() {
    if (this.filePreview.fileType === FileType.Image) {
      return FileTypeEnum.Image;
    }

    if (this.filePreview.fileType === FileType.Binary) {
      return FileTypeEnum.Bin;
    }

    if (this.filePreview.fileType === FileType.Text) {
      return FileTypeEnum.Txt;
    }

    return FileTypeEnum.Other;
  }

  /**
   *
   * @private
   * This function maps the previewRequest model to the view model.
   */
  private mapToViewModel() {
    this.filePreview.parentId = this.previewRequest.componentId;
    this.filePreview.parentType = this.previewRequest.componentType;
    this.filePreview.parentCostCenter = this.previewRequest.componentCostCenter;
    this.filePreview.fileName = this.previewRequest.fileName?.replace('Steckbrief_', '').replace('.txt', '');
    this.filePreview.parserType = this.previewRequest.parserType;

    this.filePreview.fileType = this.fileExplorerService.GetFileTypeBasedOnFileExtension(this.filePreview.fileName);
    this.filePreview.directLink = this.getDirectLink();

    this.fileExplorerService.GetFileMetadata(this.filePreview.parentId, this.filePreview.fileName,
      this.filePreview.parentType as ComponentTypeEnum, this.mapToFileHandlingFileType())
      .then(result => {

        const resultData = result as DataFile;

        this.filePreview.Size = resultData.fileSize;
        this.filePreview.Origin = resultData.origin;
        this.filePreview.Source = resultData.source;
        this.filePreview.OriginalName = resultData.sourceFilename;
        this.filePreview.Uploaded = resultData.modifiedDate;
      })
      .catch(error => {
        ErrorHandler.printError(error);
        LoggingService.logError(LoggingSource.FILE_PREVIEW_USECASE,
          'Error requesting additional metadata for file: ' + this.filePreview.fileName + '.',
          error);
      });
  }

  /**
   *
   * @private
   * This function builds up a direct link to this file, encodes the link as uri
   * and sets the value at filePreview object.
   */
  private getDirectLink(): string {
    let url: string;
    if (window
      && 'location' in window
      && 'protocol' in window.location
      && 'pathname' in window.location
      && 'host' in window.location) {
      url = window.location.protocol + '//'
        + window.location.host + '/' + this.filePreview.parentType + '/'
        + this.filePreview.parentId + '/'
        + this.filePreview.parentCostCenter + '/'
        + this.filePreview.fileName + '/'
        + this.filePreview.parserType;
    }

    return encodeURI(url);
  }

  /**
   *
   * @param $event The event object containing the view element on which the event was emitted.
   * This function sets the maximized attribute on the filePreview object to true.
   */
  public onMaximizedClicked($event: any) {
    if ($event.target.id === 'MaximizePreviewIcon') {
      this.filePreview.maximized = true;
    }
  }

  /**
   *
   * @param $event The event object containing the view element on which the event was emitted.
   * This function sets the maximized attribute on the filePreview object to false.
   */
  public onMinimizeClicked($event: any) {
    if ($event.target.id === 'PreviewContentArea') {
      this.filePreview.maximized = false;
    }
  }

  /**
   * This function is called when a user clicks on the download button.
   * It triggers the process to provide the user the file previewed as download.
   */
  public onDownloadClicked() {
    if (this.filePreview.Blob) {
      this.openFileForDownload(this.filePreview.Blob);
    } else {
      this.processFile();
    }
  }

  private mapToUseCaseViewModel() {
    this.filePreview.fileName = this.previewRequest.fileName;
    this.filePreview.OriginalName = this.previewRequest.fileName;
    if (this.previewRequest instanceof ParseAndPreviewRequest) {
      this.filePreview.fileType = FileType.Json;
    } else {
      this.filePreview.fileType = this.fileExplorerService.GetFileTypeBasedOnFileExtension(this.filePreview.fileName);
    }
    this.filePreview.Uploaded = this.previewRequest.createdAt;
    this.filePreview.Size = fileSize(this.previewRequest.size as unknown as number);


    this.fileExplorerService.GetUseCaseFileMetadata(this.usecase, this.previewRequest.s3Key)
      .then(result => {
        this.filePreview.directLink = this.fileExplorerService.getUseCaseDirectLink(result.Token, this.previewRequest.s3Key, this.usecase);
      })
      .catch((error) => {
        // tslint:disable-next-line:max-line-length
        LoggingService.logError(LoggingSource.FILE_PREVIEW_USECASE,
          'Error requesting additional metadata for file: ' + this.filePreview.fileName,
          error);
      });
  }


  onItemDeleteClicked() {
    this.fileExplorerService.DeleteFromUseCase(this.previewRequest.s3Key, this.usecase)
      .then(() => {
        LoggingService.logDebug(LoggingSource.FILE_PREVIEW_USECASE, 'File successfully deleted: ' + this.previewRequest.s3Key);
        this.filePreview.loadingComplete = false;
        this.deleteRequestEndEvent.emit(true);
      })
      .catch((error) => {
        LoggingService.logError(LoggingSource.FILE_PREVIEW_USECASE, 'File failed to delete: ' + this.previewRequest.s3Key, error);
      });
  }

  onCloseClicked() {
    this.triggerPreviewClose.emit();
  }

  private showExistingItem(preview: FilePreview) {
    try {
      this.filePreview = preview;
      switch (this.filePreview.fileType) {
        case FileType.Image:
          this.processImageFile(this.filePreview.Blob);
          break;
        case FileType.Text:
          this.processTextFile(this.filePreview.Blob);
          break;
        case FileType.Pdf:
          this.processPdfFile(this.filePreview.Blob);
          break;
        default:
          this.processOther(this.filePreview.Blob);
      }

      this.filePreview.loadingComplete = true;
      this.loading = false;
    } catch (error) {
      ErrorHandler.printError(error, this.filePreview);
    }
  }

  private showNewItem(preview: PreviewRequest) {
    try {
      if (preview.fileName === undefined) {
        this.filePreview.loadingComplete = false;
      } else {
        this.runPreview(preview);
      }
    } catch (error) {
      ErrorHandler.printError(error, this.filePreview);
    }
  }

  public ngOnDestroy(): void {
    if (isDevMode()) {
      console.log('Destroying preview component');
    }

    this.filePreview = null;
    this.previewRequest = null;

    this.onComponentDestroyed.next(true)
    this.onComponentDestroyed.complete();
  }


}



