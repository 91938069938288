import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileType} from './models/fileType';
import {ActivatedRoute} from '@angular/router';
import {FilePreviewService} from './file-preview.service';
import {ComponentTypeEnum, DataFile, FileTypeEnum} from '@cstx/volkswagen-mqs-file-handling-service-client';
import {LoggingService} from '../../../core/logging/logging.service';
import {FilePreview} from './models/filePreview';
import {ClipboardService} from 'ngx-clipboard';
import {DomSanitizer} from '@angular/platform-browser';
import {editor} from 'monaco-editor';
import {ErrorHandler} from '../../services/error-handler/error-handler';
import {LoggingSource} from '../../../core/logging/loggingSource';
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;


@Component({
  selector: 'op-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  providers: [ FilePreviewService, ClipboardService ]
})
export class FilePreviewComponent implements OnInit {
  @Input() private componentId: string;
  @Input() private componentType: string;
  @Input() private componentCostCenter: string;
  @Input() private fileName: string;
  @Input() private fileType: FileTypeEnum;
  @Input() private parserType: string;
  @Input() modal: boolean;

  @Output() operationFinishedEvent = new EventEmitter<any>();

  isLoading: boolean;
  filePreview = new FilePreview();
  errorMessage: string;
  errorOccurred: boolean;

  copiedToClipboard: boolean;

  monacoEditorOptions: IStandaloneEditorConstructionOptions = {
    theme:    'vs-light',
    language: 'text/plain',
    readOnly: true,
    scrollBeyondLastLine: false,
    minimap: {enabled: false }
  };

  // TODO: map correct Origin instead of source when implemented in File Handling Service
  // TODO: implement i18n in FilePreviewComponent
  // TODO: Fix bug with twice loaded pdf.js  (PDF not loading after usage of monaco editor to show json)

  constructor(private activatedRouteService: ActivatedRoute,
              private filePreviewService: FilePreviewService,
              private clipboardService: ClipboardService,
              private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if (!this.modal) {
       this.getQueryParams();
    }

    this.mapToViewModel();
    this.previewFile();
  }

  private previewFile() {
        if (this.parserType !== 'none') {
          this.tryParse();
        } else {
          this.downloadFile();
        }
  }

  tryParse() {
    this.isLoading = true;
    this.filePreviewService.TryParse(this.filePreview.Metadata.Id,
      this.filePreview.Name,
      this.filePreview.Metadata.ComponentType === ComponentTypeEnum.Engine ? 'Engine' : 'Part',
      this.filePreview.Metadata.CostCenter, this.parserType)
      .then(result => {
          this.errorOccurred = false;
          this.processParsedBinary(result);
      })
      .catch((error) => {
        this.errorOccurred = true;
        if (error) {
          if (error.error) {
            if (error.error.message) {

              this.errorMessage = JSON.stringify(error.error.message);
            } else {
              this.errorMessage = JSON.stringify(error.error);
            }
          }  else {
            this.errorMessage = JSON.stringify(error);
          }
        } else {
          this.errorMessage = '';
        }

        this.isLoading = false;
        LoggingService.logError(LoggingSource.FILE_PREVIEW, 'Parsing of binary: ' + this.filePreview.Name + ' failed', error);
        this.downloadFile();
      });
  }

  /* Visualization helper */
  getFilePath(): string {
    const folderName = this.mapToFileHandlingFileType();
    return '/' + folderName + '/' + this.filePreview.Metadata.OriginalName;
  }

  /* Event Handling */
  downloadFilePressed() {
      this.downloadFile();
  }
  closeClicked() {
    this.operationFinishedEvent.emit();
  }
  /* Event Handling end */

  /* Page flow helper */
  private processPdfFile(file: Blob) {
    LoggingService.logDebug(LoggingSource.FILE_PREVIEW,'Processing new Pdf with name: ' + this.filePreview.Name);
    this.isLoading = true;

    this.filePreview.File = file;
    this.isLoading = false;
  }
  private processTextFile(file: Blob) {
    LoggingService.logDebug(LoggingSource.FILE_PREVIEW, 'Processing new TextFile with name: ' + this.filePreview.Name);

    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);

    fileReader.onloadend = () => {
      this.filePreview.File = fileReader.result;
      this.monacoEditorOptions.value = this.filePreview.File;
      this.monacoEditorOptions.language = file.type;
      this.isLoading = false;
    };

    setTimeout(() => {
      if (this.isLoading) {
        fileReader.abort();
        this.handleError();
      }
    }, 10000);

  }
  private processImageFile(file: Blob) {
    LoggingService.logDebug(LoggingSource.FILE_PREVIEW, 'Processing new ImageFile with name: ' + this.filePreview.Name);

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onloadend = () => {
      if (typeof fileReader.result === 'string') {
        this.filePreview.File = this.sanitizer.bypassSecurityTrustUrl(fileReader.result);
      }
      this.isLoading = false;
    };

    setTimeout(() => {
      if (this.isLoading) {
        fileReader.abort();
        this.handleError();
      }
    }, 10000);
  }
  private processParsedBinary(file: string) {
    if (this.parserType === 'kaitai') {
      this.filePreview.File = JSON.stringify(file, null, 2);
      this.monacoEditorOptions.value = this.filePreview.File;
      this.monacoEditorOptions.language = 'application/json';
    } else if (this.parserType === 'text') {
      this.filePreview.File = file;
      this.monacoEditorOptions.value = this.filePreview.File;
      this.monacoEditorOptions.language = 'text/plain';
    }

    this.filePreview.Parsed = true;
    this.isLoading = false;
  }

  private downloadFile() {
    this.filePreviewService.GetFile(this.componentId, this.filePreview.Name,
      this.componentType as ComponentTypeEnum, this.fileType)
      .then(result => {
        this.filePreview.Blob = result;
        switch (this.filePreview.Type) {
          case FileType.Image:
            this.processImageFile(result);
            break;
          case FileType.Text:
            this.processTextFile(result);
            break;
          case FileType.Pdf:
            this.processPdfFile(result);
            break;
          default:
            this.provideDownloadedFile(result);
        }
      })
      .catch(error => {
        ErrorHandler.printError(error);

        LoggingService.logError(LoggingSource.FILE_PREVIEW,'Error occurred when downloading file: ' + this.filePreview.Name, error);
        this.filePreview.HasError = true;
      });
  }
  private provideDownloadedFile(file: Blob) {
    LoggingService.logDebug(LoggingSource.FILE_PREVIEW, 'Providing client with downloaded file');

    if (!this.filePreview.DirectDownload) {
      this.filePreview.ShowDownloadArea = true;
      this.downloadTimer(file);
    } else {
      this.openFileForDownload(file);
    }

  }
  private openFileForDownload(file: Blob, emitEvent: boolean = false) {
    /*
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, this.filePreview.Name);
      return;
    }*/

    const objectUrl               = window.URL.createObjectURL(file);
    const objectLinkElement       = document.createElement('a');
    objectLinkElement.href        = objectUrl;
    objectLinkElement.download    = this.filePreview.Metadata.OriginalName;
    objectLinkElement.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

    setTimeout(() => {
      window.URL.revokeObjectURL(objectUrl);
      objectLinkElement.remove();

      if (emitEvent) { this.operationFinishedEvent.emit(); }
    }, 100);
  }
  private downloadTimer(file: Blob) {
    const intervalId = setInterval(() => {
      this.filePreview.DownloadCounter = this.filePreview.DownloadCounter - 1;

      if (this.filePreview.DownloadCounter === 0) {
        this.openFileForDownload(file, true);
        clearInterval(intervalId);
      }
    }, 1000);
  }
  private mapToFileHandlingFileType() {
    if (this.filePreview.Type === FileType.Image) {
      return FileTypeEnum.Image;
    }

    if (this.filePreview.Type === FileType.Binary) {
      return FileTypeEnum.Bin;
    }

    return FileTypeEnum.Other;
  }
  /* Page flow helper end */

  /* Prepare ViewModel */
  private getQueryParams() {
    this.activatedRouteService.paramMap.subscribe(p => {
      this.componentId         = p.get('componentId');
      this.componentType       = p.get('componentType');
      this.componentCostCenter = p.get('componentCostCenter');
      this.fileName            = p.get('fileName');
      this.fileType            = p.get('fileType') as FileTypeEnum;
      this.parserType          = p.get('parserType');
    });
  }
  private mapToViewModel() {
    this.filePreview.Type                   = this.filePreviewService.GetFileTypeBasedOnFileExtension(this.fileName);
    this.filePreview.Metadata.Id            = this.componentId;
    this.filePreview.Metadata.ComponentType = this.componentType;
    this.filePreview.Metadata.CostCenter    = this.componentCostCenter;
    this.filePreview.Name                   = this.fileName;

    this.fileType = this.mapToFileHandlingFileType();
    this.getAdditionalMetadata();
  }
  private getAdditionalMetadata() {
    this.filePreviewService.GetFileMetadata(this.filePreview.Metadata.Id, this.filePreview.Name,
      this.filePreview.Metadata.ComponentType as ComponentTypeEnum, this.fileType)
      .then(result => {
        const resultData = result as DataFile;
        this.filePreview.Metadata.Size          = resultData.fileSize;
        this.filePreview.Metadata.Origin        = resultData.origin;
        this.filePreview.Metadata.Source        = resultData.source;
        this.filePreview.Metadata.OriginalName  = resultData.sourceFilename;
        this.filePreview.Metadata.Uploaded      = resultData.modifiedDate;
      })
      .catch(error => {
        ErrorHandler.printError(error);
        LoggingService.logError(LoggingSource.FILE_PREVIEW,
          'Error requesting additional metadata for file: ' + this.filePreview.Name,
          error);

        this.filePreview.Metadata.Size          = 'Error';
        this.filePreview.Metadata.Origin        = 'Error';
        this.filePreview.Metadata.OriginalName  = 'Error';
        this.filePreview.Metadata.Uploaded      = 'Error';
      });
  }
  /* Prepare ViewModel end */


  private handleError() {
    this.isLoading = false;
    this.filePreview.HasError = true;
    LoggingService.logError(LoggingSource.FILE_PREVIEW,'Timeout waiting for file ' + this.filePreview.Name + ' to load');
  }

  downloadClicked() {
    if (this.filePreview.Blob) {
      this.openFileForDownload(this.filePreview.Blob, false);
    } else {
      this.filePreview.DirectDownload = true;
      this.downloadFile();
    }
  }

  copyToClipboardClicked() {
    this.clipboardService.copy(this.getCurrentAbsoluteSiteUrl());
    this.copiedToClipboard = true;
  }

  // TODO: Check if there is a more Typescript fitting solution.
  getCurrentAbsoluteSiteUrl(): string {
    let url: string;
    if (!this.modal) {
      if (window
        && 'location' in window
        && 'protocol' in window.location
        && 'pathname' in window.location
        && 'host' in window.location) {
        url = window.location.protocol + '//' + window.location.host + window.location.pathname;
      }
    } else {
      if (window
        && 'location' in window
        && 'protocol' in window.location
        && 'pathname' in window.location
        && 'host' in window.location) {
        url =  window.location.protocol + '//' +
          window.location.host + window.location.pathname + '/' +
          this.filePreview.Metadata.CostCenter +  '/' +
          this.filePreview.Name + '/' +
          this.parserType;
      }
    }

    return encodeURI(url);
  }

  showComponentClicked() {

  }

  reParseFile() {
    this.filePreviewService.triggerAsyncParse(this.filePreview.Metadata.Id,
      this.filePreview.Name,
      this.filePreview.Metadata.ComponentType === ComponentTypeEnum.Engine ? 'Engine' : 'Part',
      this.filePreview.Metadata.CostCenter);

  }
}
