import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Engine} from '../../services/backend/models/engine';
import {Part} from '../../services/backend/models/part';
import {DetectedComponent} from '../../../modules/tools/dmc/scan/models/detectedComponent';
import {
  BlockingActionResponse,
  EngineBlockedState,
  EngineReworkState,
  ReworkResponse,
  ReworkState,
  ReworkType
} from '@cstx/volkswagen-mqs-engine-service-client';
import {LogisticalState} from '../../../modules/engine/list/list.component';
import {PartBlockedState} from '@cstx/volkswagen-mqs-part-service-client';
import {TrackingService} from '../../services/backend/tracking.service';
import {EngineService} from '../../services/backend/engine.service';
import {PartService} from '../../services/backend/part.service';
import {
  ComponentReleaseWizardService
} from '../component-release-wizard/component-release-wizard.service';
import {ComponentBlockWizardService} from '../component-block-wizard/component-block-wizard.service';

@Component({
  selector: 'op-component-state-check',
  templateUrl: './component-state-check.component.html',
  styleUrls: ['./component-state-check.component.scss']
})
export class ComponentStateCheckComponent implements OnInit {
  @Input() detectedComponent: DetectedComponent;
  @Input() noRemove: boolean;
  @Input() noLogistics: boolean;
  @Output() onDeleteRequested = new EventEmitter<string>();

  public componentCheckResult = new ComponentCheckResult();

  protected readonly EngineBlockedState = EngineBlockedState;
  protected readonly EngineReworkState = EngineReworkState;
  protected readonly LogisticalState = LogisticalState;

  public detailsExtended: boolean;
  public hover: boolean;

  constructor(private trackingService: TrackingService,
              private engineService: EngineService,
              private partService: PartService) {
  }

  public ngOnInit(): void {
    if (this.detectedComponent) {
      this.componentCheckResult = new ComponentCheckResult(this.detectedComponent);

      if (this.componentCheckResult.componentType === 'engine') {
          this.trackingService.getStacksByComponentIDList([ this.componentCheckResult.engine.id ])
            .then(stackResponse => {
              this.componentCheckResult.stackIdentifier = stackResponse[0]?.stackIdentifier;

              if (stackResponse[0]?.customer) {
                this.componentCheckResult.stackLocation = stackResponse[0]?.customer;
              } else if (stackResponse[0]?.storagePlace !== null) {
                this.componentCheckResult.stackLocation =
                  stackResponse[0]?.storageArea + ' ' +
                  stackResponse[0]?.storageGroup + ' ' +
                  stackResponse[0]?.storageZone + ' ' +
                  stackResponse[0]?.storagePlace;
              }
            });

        if (this.componentCheckResult.engine.hasReworks) {
            this.engineService.getReworks(this.componentCheckResult.engine.id).then(reworks => {
                this.componentCheckResult.details.reworkDetails = reworks.map(reworkResponse => new ReworkDetail(reworkResponse));
            });
        }

        if (this.componentCheckResult.engine.hasBlockingActions) {
          this.engineService.getBlockingActions(this.componentCheckResult.engine.id).then(blockings => {
            this.componentCheckResult.details.blockingDetails
              = blockings.map(blockingResponse => new BlockingDetail(blockingResponse));

            if (this.componentCheckResult.engine.engineBlockedState === EngineBlockedState.Blocked) {
              ComponentReleaseWizardService
                .addBlocking(this.componentCheckResult.details.blockingDetails,
                  this.componentCheckResult.engine.componentName);
            }
          });
        }

        ComponentBlockWizardService.addComponentToBlock(this.componentCheckResult.engine.componentName);
      }

      if (this.componentCheckResult.componentType === 'part') {
        this.trackingService.getStacksByComponentIDList([ this.componentCheckResult.part.id ])
          .then(stackResponse => {
            this.componentCheckResult.stackIdentifier = stackResponse[0]?.stackIdentifier;

            if (stackResponse[0]?.customer) {
              this.componentCheckResult.stackLocation = stackResponse[0]?.customer;
            } else if (stackResponse[0]?.storagePlace !== null) {
              this.componentCheckResult.stackLocation =
                stackResponse[0]?.storageArea + ' ' +
                stackResponse[0]?.storageGroup + ' ' +
                stackResponse[0]?.storageZone + ' ' +
                stackResponse[0]?.storagePlace;
            }
          });

        if (this.componentCheckResult.part.hasBlockingActions) {
          this.partService.getBlockingActions(this.componentCheckResult.part.id)
            .then(blockings => {
              this.componentCheckResult.details.blockingDetails
                = blockings.map(blockingResponse => new BlockingDetail(blockingResponse));

              if (this.componentCheckResult.part.partBlockedState === PartBlockedState.Blocked) {
                ComponentReleaseWizardService
                  .addBlocking(this.componentCheckResult.details.blockingDetails, this.componentCheckResult.part.dmc);
              }
          });
        }

        ComponentBlockWizardService.addComponentToBlock(this.componentCheckResult.part.dmc);
      }
    }
  }

  public toggleDetailsSections() {
    if (this.componentCheckResult.details.isEmpty) {
      return;
    }

    this.detailsExtended = !this.detailsExtended;
  }

  public setHover(hover: boolean) {
    this.hover = hover;
  }
}

export class ComponentCheckResult {
    public blocked: boolean = true;
    public componentName: string;
    public componentType: string;
    public engine?: Engine;
    public part?: Part;

    public stackIdentifier?: string;
    public stackLocation?: string;

    public details = new ComponentCheckResultDetails();
    public selfLink: string;

    public selfManufactured: boolean;
    public manufacturer: string;

    constructor(detectedComponent?: DetectedComponent) {
      if (detectedComponent) {
        this.componentName = detectedComponent.componentName;
        this.componentType = detectedComponent.componentType;

        if (detectedComponent.scan.engine) {
            this.selfManufactured = true;
            this.manufacturer = detectedComponent.scan.engine.costCenter;

            this.engine = detectedComponent.scan.engine;
            this.blocked = detectedComponent.scan.engine.getLogisticalState() !== LogisticalState.FREE;

            this.selfLink = detectedComponent.scan.engine.selfLink;
        }

        if (detectedComponent.scan.part) {
          this.selfManufactured = !!detectedComponent.scan.part.costCenter;
          this.manufacturer = detectedComponent.scan.part.costCenter;

          this.part = detectedComponent.scan.part;
          this.blocked = detectedComponent.scan.part.partBlockedState === PartBlockedState.Blocked;

          this.selfLink = detectedComponent.scan.part.selfLink;
        }
      }
    }
}

export class ComponentCheckResultDetails {
  public reworkDetails?: ReworkDetail[];
  public blockingDetails?: BlockingDetail[];

  public get isEmpty(): boolean {
    if (this.reworkDetails && this.reworkDetails.length > 0) {
      return false;
    }

    if (this.blockingDetails && this.blockingDetails.length > 0) {
      return false;
    }

    return true;
  }

}

class ReworkDetail {
  public modifiedAt: string;
  public problem: string;
  public analysis: string;
  public rework: string;

  public done: boolean;
  public inLine: boolean;

  public response?: ReworkResponse;

  constructor(reworkResponse?: ReworkResponse) {
    if (reworkResponse) {
      this.analysis = reworkResponse.analysis;
      this.problem = reworkResponse.complaint;
      this.rework = reworkResponse.rework;
      this.modifiedAt = reworkResponse.modifiedAt;
      this.inLine = reworkResponse.reworkType === ReworkType.Inline;

      if (this.inLine) {
        this.done = reworkResponse.reworkState === ReworkState.Closed;
      } else {
        this.done = reworkResponse.reworkState === ReworkState.Approved;
      }

      this.response = reworkResponse;
    }
  }
}

export class BlockingDetail {
  public blockedAt: string;
  public released: boolean;
  public reason: string;
  public response?: BlockingActionResponse;
  public blockingActionNr: string;
  public blockingActionId: string;

  constructor(blockingResponse?: BlockingActionResponse) {
    if (blockingResponse) {
     if (blockingResponse.releasedAt) {
       this.released = true;
     } else {
       this.released = false;
     }


      this.blockedAt = blockingResponse.blockedAt;
      this.blockingActionNr = blockingResponse.humanReadableId;
      this.blockingActionId = blockingResponse.qmsBlockingActionId;

      if (blockingResponse.source === 'SZONLINEV1') {
        this.reason = blockingResponse.action;
      } else {
        this.reason = blockingResponse.blockingReason;
      }

      this.response = blockingResponse;
    }
  }
}
