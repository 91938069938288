import {PdfCreatorConstants} from './pdf-creator-constants';

export class PdfStatisticsTemplates {
  private static OUTPUT_STATISTIC_GRAPH_TEMPLATE = {
    statisticGraph: {
      type: 'image',
      position: {
        x: 12,
        y: 25
      },
      width: 160,
      height: 120
    }
  }

  private static STOP_NOTIFICATION_CONTENT_TEMPLATE = {
    stopImage: {
      type: 'image',
      position: {
        x: 23,
        y: 34
      },
      width: 65,
      height: 65,
      alignment: 'left',
      fontSize: 13,
      characterSpacing: 0,
      lineHeight: 1
    },
    descriptionHeader: {
      type: 'text',
      position: {
        x: 105,
        y: 35
      },
      width: 86.33,
      height: 9.38,
      alignment: 'center',
      fontSize: 15,
      characterSpacing: 0,
      lineHeight: 2,
      backgroundColor: '#7a7a7a',
      fontColor: '#ffffff'
    },
    descriptionSpacer: {
      type: 'text',
      position: {
        x: 105,
        y: 45.45
      },
      width: 86.32,
      height: 6.21,
      alignment: 'center',
      fontSize: 1,
      characterSpacing: 0,
      lineHeight: 2,
      backgroundColor: '#b8ccdb',
      fontColor: '#b8ccdb'
    },
    descriptionContent: {
      type: 'text',
      position: {
        x: 105,
        y: 52.92
      },
      width: 86.33,
      height: 45.36,
      alignment: 'center',
      fontSize: 15,
      characterSpacing: 0,
      lineHeight: 1,
      backgroundColor: '#f2f2f2',
    },
    exampleImage: {
      type: 'image',
      position: {
        x: 197.91,
        y: 35.19
      },
      width: 79.98,
      height: 63.05,
      alignment: 'left',
      fontSize: 13,
      characterSpacing: 0,
      lineHeight: 1
    },
    orderContent: {
      type: 'text',
      position: {
        x: 105.3,
        y: 117.74
      },
      width: 171,
      height: 74.46,
      alignment: 'center',
      fontSize: 20,
      characterSpacing: 1,
      lineHeight: 1,
      backgroundColor: '#e6eedd'
    },
    orderHeader: {
      type: 'text',
      position: {
        x: 105.22,
        y: 106.91
      },
      width: 171.26,
      height: 9.38,
      alignment: 'center',
      fontSize: 15,
      characterSpacing: 0,
      lineHeight: 2,
      backgroundColor: '#094601',
      fontColor: '#ffffff'
    },
    creatorLabel: {
      type: 'text',
      position: {
        x: 20,
        y: 107
      },
      width: 30,
      height: 8,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 2,
      backgroundColor: '#e3e3e3'
    },
    createdAtLabel: {
      type: 'text',
      position: {
        x: 20,
        y: 116
      },
      width: 30,
      height: 8,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 2,
      backgroundColor: '#e3e3e3'
    },
    costCenterLabel: {
      type: 'text',
      position: {
        x: 20,
        y: 125
      },
      width: 30,
      height: 8,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 2,
      backgroundColor: '#e3e3e3'
    },
    postingStartLabel: {
      type: 'text',
      position: {
        x: 20,
        y: 134
      },
      width: 30,
      height: 8,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 2,
      backgroundColor: '#e3e3e3'
    },
    postingEndLabel: {
      type: 'text',
      position: {
        x: 20,
        y: 143
      },
      width: 30,
      height: 8,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 2,
      backgroundColor: '#e3e3e3'
    },
    creator: {
      type: 'text',
      position: {
        x: 52,
        y: 107
      },
      width: 45,
      height: 8,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 2,
      backgroundColor: '#f2f2f2'
    },
    createdAt: {
      type: 'text',
      position: {
        x: 52,
        y: 116
      },
      width: 45,
      height: 8,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 2,
      backgroundColor: '#f2f2f2'
    },
    costCenterStopSign: {
      type: 'text',
      position: {
        x: 52,
        y: 125
      },
      width: 45,
      height: 8,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 2,
      backgroundColor: '#f2f2f2'
    },
    postingStart: {
      type: 'text',
      position: {
        x: 52,
        y: 134
      },
      width: 45,
      height: 8,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 2,
      backgroundColor: '#f2f2f2'
    },
    postingEnd: {
      type: 'text',
      position: {
        x: 52,
        y: 143
      },
      width: 45,
      height: 8,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 2,
      backgroundColor: '#f2f2f2'
    }
  }

  static OUTPUT_STATISTIC_TEMPLATE = {
    ...PdfCreatorConstants.TEMPLATE_HEADER,
    ...PdfCreatorConstants.TEMPLATE_FOOTER,
    ...PdfStatisticsTemplates.OUTPUT_STATISTIC_GRAPH_TEMPLATE
  }

  static STOP_NOTIFICATION_TEMPLATE = {
    ...PdfCreatorConstants.TEMPLATE_HEADER_LANDSCAPE,
    ...PdfStatisticsTemplates.STOP_NOTIFICATION_CONTENT_TEMPLATE,
    ...PdfCreatorConstants.TEMPLATE_FOOTER_LANDSCAPE
  }

}
