import {Injectable} from '@angular/core';
import {ComponentTypeEnum, DataFile, FileControllerService, FileTypeEnum} from '@cstx/volkswagen-mqs-file-handling-service-client';
import {ComponentFile} from './componentFile';
import {LoggingService} from '../../../core/logging/logging.service';
import {ComponentType, ParseService, ParseXmlControllerV2Service, TextTemplate} from '@cstx/volkswagen-mqs-parsing-service-client';
import {map} from 'rxjs/operators';
import {FileType} from '../file-preview/models/fileType';
import {FileUpload} from './fileUpload';
import {FilePreview} from './filePreview';
import {ErrorHandler} from '../../services/error-handler/error-handler';
import {LoggingSource} from '../../../core/logging/loggingSource';

@Injectable({
  providedIn: 'root'
})
export class FileExplorerService {


  constructor(private fileControllerService: FileControllerService,
              private parseControllerService: ParseService,
              private parseControllerV2Service: ParseXmlControllerV2Service) { }

  public GetTextTemplates(): Promise<TextTemplate[]> {
    return new Promise<TextTemplate[]>((resolve, reject) => {
      let textTemplates = new Array<TextTemplate>();

      this.parseControllerService.parseTexttemplateGet().toPromise()
        .then(response => {
          textTemplates = response;
          resolve(textTemplates);
        })
        .catch(error => {
          LoggingService.logError(LoggingSource.FILE_EXPLORER_SERVICE,'Error occurred when requesting Text Templates', error);
          ErrorHandler.printError(error);
          reject();
        });
    });
  }

  public GetFiles(id: string, componentType: string): Promise<ComponentFile[]> {
    return new Promise<ComponentFile[]>((resolve, reject) => {
        const files = new Array<ComponentFile>();

        this.fileControllerService.getFiles({
          componentType,
          id })
          .toPromise()
          .then(response => {
            response = this.sort(response);
            response.forEach(item => {
              // files.push(this.mapToComponentFile(item));
              files.push(new ComponentFile(item));
            });

            LoggingService.logDebug(LoggingSource.FILE_EXPLORER_SERVICE,files.length + ' files found for item: ' + id);
            resolve(files);
            }
          )
          .catch(error => { throw new Error('Error getting files.'); });
        });
  }

  public GetFile(id: string, fileName: string, componentType: ComponentTypeEnum,  fileType: FileTypeEnum): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      this.fileControllerService.getFile({
        componentType,
        id,
        fileName,
        fileType
      })
      .pipe(map(value => value as Blob))
      .toPromise()
        .then(response => {
          resolve(response);
        })
        .catch(reason => {
          reject(reason);
        });
    });
  }


  public Upload(fileUploadRequest: FileUpload): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.fileControllerService.upload({
        id:             fileUploadRequest.componentId,
        costCenter:     fileUploadRequest.costCenter,
        fileType:       this.checkFileType(fileUploadRequest.file),
        componentType:  fileUploadRequest.componentType,
        source:         fileUploadRequest.source,
        file:           fileUploadRequest.file,
        fileName:       fileUploadRequest.fileName
      }).toPromise()
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  private sort(response: Array<DataFile>) {
    return response.sort((a, b) => {
      if (a.modifiedDate < b.modifiedDate) {
        return 1;
      }

      if (a.modifiedDate > b.modifiedDate) {
        return -1;
      }

      return 0;
    });
  }

  public GetFileMetadata(id: string, fileName: string, componentType: ComponentTypeEnum,  fileType: FileTypeEnum) {
    return new Promise<any>((resolve, reject) => {
      this.fileControllerService.getMetadata({
        componentType,
        id,
        fileName,
        fileType
      }).toPromise()
        .then(response => {
          resolve(response);
        })
        .catch(reason => {
          reject(reason);
        });
    });
  }
  public triggerAsyncParse(id: string, fileName: string, componentType: ComponentType, costCenter: string) {
    this.parseControllerService.parseKaitaiPublishPost({
      id,
      fileName,
      componentType,
      costCenter,
    }).toPromise();
  }

  public parseCsvAsync(dmc: string, file: FilePreview ) {

    const request = this.parseControllerV2Service.parseComponentFileDirect({
      id: file.parentId,
      componentType: file.parentType.toLowerCase() === 'part' ? ComponentType.Part : ComponentType.Engine,
      costCenter: file.parentCostCenter,
      content: file.Blob,
      fileName: file.fileName
    }).toPromise();

    request.then(() => {
      console.log('done');
    })
  }

  // tslint:disable-next-line:max-line-length
  public TryParse(id: string, fileName: string, componentType: ComponentType, costCenter: string, parserType: string = 'kaitai'): Promise<string> {

    if (parserType === 'kaitai') {
      return new Promise<string>((resolve, reject) => {
        this.parseControllerService.parseKaitaiPost({
          id,
          fileName,
          componentType,
          costCenter,
        }).toPromise()
          .then(response => {
            resolve(response);
          })
          .catch(reason => {
            reject(reason);
          });
      });
    } else if (parserType === 'text') {
      return new Promise<string>((resolve, reject) => {
        this.parseControllerService.parseTexttemplatePost({
          id,
          fileName,
          componentType,
          costCenter,
        }).toPromise()
          .then(response => {
            resolve(response);
          })
          .catch(reason => {
            reject(reason);
          });
      });
    }
  }

  // This filetype is only for visualization purposes
  // and no copy of the file-handling-service FileTypeEnum
  GetFileTypeBasedOnFileExtension(fileName: string): FileType {
    if (!fileName) {
      return;
    }

    let fileExtension =  fileName.split('.').pop();
    fileExtension     = fileExtension.toLowerCase();

    switch (fileExtension) {
      case 'img':
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'svg':
        return FileType.Image;

      case 'txt':
      case 'json':
      case 'mnr':
        return FileType.Text;

      case 'pdf':
        return FileType.Pdf;

      case 'bin':
      case 'rmb':
      case 'dat':
        return FileType.Binary;
    }

    return FileType.Other;
  }

  private checkFileType(file: any): FileTypeEnum {

    let fileType = file.type;
    if (typeof fileType === 'string' && fileType.includes('image')) {fileType = 'image'; }

    switch (fileType) {
      case 'text/plain':
        return 'txt';
      case 'application/octet-stream':
        return 'bin';
      case 'image':
        return 'image';
      default:
        return this.checkFileBasedOnExtension(file.name);
    }
  }

  private checkFileBasedOnExtension(fileName): FileTypeEnum {
    if (!fileName) {
      return;
    }

    let fileExtension =  fileName.split('.').pop();
    fileExtension     = fileExtension.toLowerCase();

    switch (fileExtension) {
      case 'img':
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'svg':
        return 'image';

      case 'txt':
      case 'xml':
      case 'json':
      case 'csv':
        return 'txt';

      case 'bin':
      case 'rmb':
      case 'dat':
        return 'bin';
    }

    return 'other';
  }

  triggerComponentUpdate(id: string, fileName: string, componentType: ComponentType, costCenter: string) {
    this.parseControllerService.parseKaitaiPublishPost({
      id,
      fileName,
      componentType,
      costCenter,
    }).toPromise();
  }
}


