import {FilterBase} from '../../../shared/models/filterBase';
import {Slice} from '../../../shared/models/slice';

export class ProductUnitFilter extends FilterBase {
  public name = '';
  public slice: Slice;

  constructor() {
    super();

    this.pagingSize = 0;
    this.totalPages = 0;
    this.currentPageIndex = 0;
    this.sort = [];
  }
  public getAllParams() {
    return {...this.getPageableParams(), ...this.getFilterParams()};
  }

  public getPageableParams() {
    return {
      page: this.currentPageIndex,
      size: this.pagingSize,
      sort: this.sort,
    };
  }

  public getFilterParams() {
    if (!this.name) {
      return {};
    }
    return {
      name: this.name
    };
  }
}
