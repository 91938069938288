export class TokenData {
  jti: string;
  exp: number;
  nbf: number;
  iat: number;
  iss: string;
  sub: string;
  typ: string;
  azp: string;
  nonce: string;
  authTime: number;
  sessionState: string;
  acr: string;
  allowedOrigins: string[];
  scope: string;
  emailVerified: boolean;
  name: string;
  preferredUsername: string;
  givenName: string;
  familyName: string;
  corporation: string;
  email: string;

  constructor(email: string, name: string, username: string) {
    this.email = email;
    this.name = name;
    this.preferredUsername = username;
  }

  public static of(identityClaims: any) {
    return new TokenData(identityClaims.email, identityClaims.name, identityClaims.preferred_username);
  }
}
