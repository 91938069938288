import {Injectable} from '@angular/core';
import {
  ProcedureResponse,
  ProcedureResponseSliceResponse,
  ProcedureService,
  ProcedureState
} from '@cstx/volkswagen-mqs-onpremise-procedure-service-client';
import {firstValueFrom} from 'rxjs';
import {OpsFilter} from './opsFilter';
import {DestinationSystem} from '@cstx/volkswagen-mqs-onpremise-procedure-service-client/model/destinationSystem';
import {SourceService} from '@cstx/volkswagen-mqs-onpremise-procedure-service-client/model/sourceService';

@Injectable({
  providedIn: 'root'
})
export class OpsService {

  constructor(private procedureService: ProcedureService) { }

  public async GetProcedures(filter: OpsFilter, reset = false): Promise<ProcedureResponseSliceResponse> {
   const result = await firstValueFrom(this.procedureService.getProcedures(filter.getAllParams()));

   return result;
  }

  public DeleteProcedure(id: string) {
    this.procedureService.deleteProcedure({
      id
    }).toPromise().then(r => {
      console.log(r);
    });
  }

  public async GetProcedure(id: string): Promise<ProcedureResponse> {
    const request = this.procedureService.getById({
      procedureId: id
    });

    return await firstValueFrom(request);
  }

  public async SetProcedureState(id: string, state: ProcedureState = ProcedureState.New): Promise<boolean> {
    const result = this.procedureService.setProcedureState({
      procedureId: id,
      newState: state
    });

    const done = await firstValueFrom(result);

    console.log(done);
    return !!done;
  }

  public async CreateProcedure(
    procedureName: string,
    procedureValue: string,
    destinationSystem: DestinationSystem,
    procedureRoutingInfo: string,
    sourceService: SourceService
  ): Promise<ProcedureResponse> {
    const request = {
      procedureCreateRequest: {
        procedureName,
        procedureValue,
        destinationSystem,
        procedureRoutingInfo,
        sourceService
      }
    }

    return await firstValueFrom(this.procedureService.createProcedure(request));
  }
}
