import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {DailyManufacturingStatisticControllerService, DailyManufacturingStatisticResponse} from '@cstx/volkswagen-mqs-manufacturing-monitor-service-client';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'op-productcenter-weekly',
  templateUrl: './productcenter-weekly.component.html',
  styleUrls: ['./productcenter-weekly.component.scss']
})
export class ProductcenterWeeklyComponent implements OnInit, OnChanges {


  constructor(private datePipe: DatePipe,
              private monitorService: DailyManufacturingStatisticControllerService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  showModal: boolean;
  showSpinner: boolean;
  currentWeek: number;
  currentYear: number;
  @Input() productCenterId: string;
  productCenterName = '';

  stats: CostCenterWeeklyStatistic[] = [];

  ngOnInit(): void {
    this.setCurrentWeekAndYear();
    this.doStatistics();
  }

  ngOnChanges(): void {
    this.setCurrentWeekAndYear();
    this.doStatistics();
  }

  private setCurrentWeekAndYear() {
    this.currentYear = Number(this.datePipe.transform(new Date(), 'y'));
    this.currentWeek = Number(this.datePipe.transform(new Date(), 'w'));
  }

  private doStatistics() {
    if (this.productCenterId === undefined) {
      this.route.paramMap.subscribe(p => {
        const id = p.get('id');

        this.productCenterId = id;
        this.getData();
      });
    } else {
      this.getData();
    }
  }

  private getData() {
    if (this.productCenterId !== null && this.productCenterId !== '') {
      this.showSpinner = true;
      this.stats = [];
      this.monitorService.getDailyManufacturingStatistics({ productCenter: this.productCenterId,
        calendarWeek: this.currentWeek,
        year: this.currentYear })
        .toPromise()
        .then(response => {
          const keys = Object.keys(response);
          keys.sort((a, b) => {
            const mydateA = new Date(a);
            const mydateB = new Date(b);

            return mydateA.getTime() - mydateB.getTime();
          });

          keys.forEach(key => {
            this.createOrUpdateCostCenterWeeklyStatistics(response[key]);
          });

          this.showSpinner = false;
          this.stats.forEach(stat => {
            if (stat.actuals.length < 7) {
              const diff = 7 - stat.actuals.length;
              for (let i = 0; i < diff; i++) {
                stat.actuals.unshift(0);
                stat.targets.unshift(0);
              }
            }
          });
        });
    }
  }

  private createOrUpdateCostCenterWeeklyStatistics(item: Array<DailyManufacturingStatisticResponse>) {
    if (item.length > 0) {
      item.forEach(i => {
        this.createOrUpdateCostCenterWeeklyStatistic(i);
      });
    } else {
      this.fillEmptyDays();
    }
  }

  private createOrUpdateCostCenterWeeklyStatistic(i: DailyManufacturingStatisticResponse) {
    const existingStatistic = this.stats.find(s => s.costCenter === i.costCenter);
    if (existingStatistic === undefined) {
      if (this.productCenterName === '') { this.productCenterName = i.productCenterName; }
      const weeklyStat: CostCenterWeeklyStatistic = { costCenter: i.costCenter,
        costCenterName: i.costCenterName,
        actuals: [ i.actualValue ],
        targets: [ i.targetValue ]};
      this.stats.push(weeklyStat);
    } else {
      existingStatistic.actuals.push(i.actualValue);
      existingStatistic.targets.push(i.targetValue);
    }
  }

  private fillEmptyDays() {
    this.stats.forEach(e => {
      e.actuals.push(0);
      e.targets.push(0);
    });
  }

  getSummary(numbers: number[]): number {
    let result = 0;

    numbers.forEach(nr => {
      result = result + nr;
    });
    return result;
  }

  getCostCenterLabel(stat) {
    if (stat.costCenterName === undefined) {
      return '';
    } else {
      if (stat.costCenterName.length > 20) {
        return stat.costCenterName.substring(0, 20) + '...';
      } else {
        return stat.costCenterName;
      }
    }
  }

  getProductCenterLabel() {
    let label;

    if (this.productCenterName === undefined) {
      // return this.productCenterId;
      return '';
    } else {
      if (this.productCenterName.length >= 15) {
         label = this.productCenterName.substring(0, 15) + '...';
      } else {
        label = this.productCenterName;
      }

      // label = label + ' [' + this.productCenterId + ']';
      return label;
    }
  }

  getBGColorClass(i: number): string {
    if (i < 0) {
      return 'negativ';
    } else if (i === 0) {
      return 'neutral';
    } else {
      return 'positiv';
    }

  }

  notCurrentWeek() {
    if (this.currentWeek === Number(this.datePipe.transform(new Date(), 'w'))) {
      return false;
    }

    return true;
  }

  goBack() {
    if (this.currentWeek !== 1) {
      this.currentWeek = this.currentWeek - 1;
      this.getData();
    } else {
      this.currentWeek = 52;
      this.currentYear = this.currentYear - 1;
      this.getData();
    }
  }

  goForward() {
    if (this.currentYear === Number(this.datePipe.transform(new Date(), 'y'))
        && this.currentWeek < Number(this.datePipe.transform(new Date(), 'w'))) {

      this.currentWeek = this.currentWeek + 1;
      this.getData();
    } else if (this.currentYear !== Number(this.datePipe.transform(new Date(), 'y'))) {
      if (this.currentWeek === 52) {
        this.currentWeek = 1;
        this.currentYear = this.currentYear + 1;
      } else {
        this.currentWeek = this.currentWeek + 1;
      }

      this.getData();
    }
  }
}

export class CostCenterWeeklyStatistic {
  costCenter: string;
  costCenterName: string;
  actuals?: number[];
  targets?: number[];
}
