<div class="pr-3 pl-3"
     (mouseenter)="setHover(true)"
     (mouseleave)="setHover(false)"
     style="position: relative;">

  <div class="d-flex flex-column justify-content-center align-items-center pointer"
       style="position: absolute; top: 1px; right: 2px; bottom: 0; left: -10px; background-color: pink;"

       [ngStyle]="{ 'background-color': hover ? '#B8DAFF' : ''}"
  >
  </div>

  <div class="d-flex flex-column justify-content-center align-items-center pointer"
       style="position: absolute; top: 1px; right: 0; height: 50px; width: 50px; background-color: lightgray;"
       (click)="onDeleteRequested.emit(stackCheckResult.identifier)">
    <i class="cpp-icon-delete-2"></i>
  </div>

  <div class="d-flex flex-column justify-content-center align-items-center"
       style="position: absolute; top: 53px; right: 0; height: 50px; width: 50px; background-color: lightgray;">

    <a [href]="stackCheckResult?.selfLink" target="_blank">
      <i class="cpp-icon-link"></i>
    </a>

  </div>

  <div class="d-flex flex-column justify-content-center align-items-center"

       [class.pointer]="stackCheckResult && !stackCheckResult.isEmpty"
       [class.detailsEmpty]="stackCheckResult && stackCheckResult.isEmpty"

       style="position: absolute; top: 105px; right: 0; height: 50px; width: 50px; background-color: lightgray;"
       (click)="toggleDetailsSections()">

    <i [ngClass]="[detailsExtended ? 'cpp-icon-extend-details' : 'cpp-icon-collapsed-details']"
       [class.detailsEmpty]="stackCheckResult && stackCheckResult.isEmpty"
    ></i>
  </div>

  <div class="row pr-5">
    <div class="cpp-column-fixed-width-8 border border-white"
         [ngStyle]="{ 'background-color': hover ? '#B8DAFF' : ''}"></div>
    <div class="col d-flex flex-column justify-content-center align-items-center border border-white"
         style="min-height: 155px;"
         [tooltip]="logisticalStateTooltipTemplate"
         [ngStyle]=" { 'background-color': stackCheckResult.blocked ? 'lightcoral' : 'lightgreen' } ">


      <ng-template #logisticalStateTooltipTemplate>
        <op-logistical-state-tooltip [stack]="stackCheckResult.stack"></op-logistical-state-tooltip>
      </ng-template>

      <ng-container *ngIf="stackCheckResult"
                    [ngSwitch]="stackCheckResult.stack.logisticalState">

        <ng-container *ngSwitchCase="LogisticalState.FREE">
          <i class="fab fa-stack-overflow"
             style="color: darkgreen; font-size: 5em; font-weight: bolder">
          </i>
        </ng-container>
        <ng-container *ngSwitchCase="LogisticalState.UNFREE">
          <i class="fab fa-stack-overflow" style="color: darkred; font-size: 5em; font-weight: bolder">

          </i>
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ stackCheckResult.stack?.logisticalState }}
        </ng-container>

        <div style="font-weight: bolder; font-size: 0.9em">
          {{ stackCheckResult.stack.costCenter ? stackCheckResult.stack.costCenter : '' }}
        </div>
      </ng-container>
    </div>
    <div class="col-8">
      <div class="row" style="min-height: 90px;">
        <div
          class="col-6 d-flex flex-column justify-content-center align-items-center border border-white text-overflow-ellipses"
          style="background-color: #00e5ff; font-weight: bolder; white-space: nowrap"


          [ngStyle]="{ 'font-size': stackCheckResult.identifier?.length > 14 ? '0.8em' : '1.3em'}"
        >


          {{ stackCheckResult.identifier }}

        </div>
        <div class="col-6 border border-white align-items-center"
             style="background-color: #00e5ff">
          <ng-container *ngIf="stackCheckResult.stack">
            <div class="row" [ngStyle]="{'height': stackCheckResult.stack.storagePlace ? '50%' : '100%'}">
              <div class="col d-flex flex-column justify-content-center align-items-center ">
                <div *ngIf="stackCheckResult.stack" style="font-size: 1.3em; font-weight: bold">
                  {{
                    stackCheckResult.stack.getStackPartNumbers().length > 1
                      ? ('stack.multiplePartNumbersError' | translate)
                      : stackCheckResult.stack.getStackPartNumbers().length === 0
                        ? ('stack.noPartNumbersError' | translate)
                        : stackCheckResult.stack.getStackPartNumbers()[0]
                  }}
                </div>
              </div>
            </div>
            <div class="row" style="height: 50%" *ngIf="stackCheckResult.stack.storagePlace">
              <div class="col d-flex flex-column justify-content-center align-items-center  border-top border-white">
                <div *ngIf="stackCheckResult.stack" style="font-size: 1.3em; font-weight: bold">
                  {{ stackCheckResult.stack.storagePlace }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="row" style="min-height: 90px">

        <div class="col d-flex flex-column justify-content-center align-items-center border border-white pointer"
             style="background-color: #eeeeee"
             [tooltip]=""
        >
           <span class="position-relative mr-2 d-inline-block" style="font-size: 3em; font-weight: bold">
             {{ stackCheckResult.stack.componentsRaw.length }}
          </span>
          <small>{{ 'overall' | translate }}</small>
        </div>

        <div class="col d-flex flex-column justify-content-center align-items-center border border-white pointer"
             style="background-color: lightgreen"
             [tooltip]="">
<span class="position-relative mr-2 d-inline-block" style="font-size: 3em; font-weight: bold">
            {{ stackCheckResult.componentFreeCount }}
</span>
          <small>{{ 'free' | translate }}</small>


        </div>
        <div class="col d-flex flex-column justify-content-center align-items-center border border-white pointer"
             style="background-color: lightcoral" [tooltip]="">
          <span class="position-relative mr-2 d-inline-block" style="font-size: 3em; font-weight: bold">
          {{ stackCheckResult.componentUnFreeCount }}
          </span>
          <small>{{ 'unFree' | translate }}</small>
        </div>

        <div class="col-6 align-items-center border border-white pointer" style="background-color: #eeeeee"
             [tooltip]="">
          <ng-container *ngIf="stackCheckResult.stack">
            <div class="row" style="height: 50%">
              <div class="col d-flex flex-column justify-content-center align-items-center ">
                <div style="font-size: 1.3em; font-weight: bold">
                  {{ stackedAtDate(stackCheckResult.stack.stackedAt) }}
                </div>
              </div>
            </div>
            <div class="row" style="height: 50%">
              <div class="col d-flex flex-column justify-content-center align-items-center  border-top border-white">
                <div style="font-size: 1.3em; font-weight: bold">
                  {{ stackedAtTime(stackCheckResult.stack.stackedAt) }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="d-flex">
  <div class="col pr-0 ml-3">
    <div
      style="position: absolute; top: 0; left: -6px; bottom: 0; width: 30px; background-color: lightblue;z-index: 200">
    </div>
    <ng-container *ngIf="stackCheckResult && !stackCheckResult.isEmpty">
      <div [hidden]="!detailsExtended">
      <op-component-state-check
        [detectedComponent]="component"
        [noRemove]="true"
        [noLogistics]="true"
        *ngFor="let component of stackCheckResult.components">
      </op-component-state-check>
      </div>
    </ng-container>
  </div>
</div>
