import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../models/user';
import {AuthService} from '../services/auth.service';
import {ConfigService} from '../services/config.service';
import {LoggingService} from '../logging/logging.service';
import {LogLevel} from '../logging/logLevel';
import {LoggingSource} from '../logging/loggingSource';
import {UserStatisticsProviderService} from '../services/user-statistics-provider.service';

@Component({
  selector: 'op-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private authService: AuthService,
              public configService: ConfigService) {
    this.messages = [];
  }
  @Input() PortalVersion: string;
  @Input() NewPortalVersion: string;
  @Input() kioskMode = false;

  @Output() toggleKioskMode = new EventEmitter<void>();
  @Output() toggleLogMode = new EventEmitter<void>();

  public messages: Array<any>;
  public logMode: boolean;

  public currentUser: User;
  public currentStage: string;
  public currentYear = new Date().getFullYear();

  protected readonly LoggingService = LoggingService;
  protected readonly LogLevel = LogLevel;
  protected readonly LoggingSource = LoggingSource;
  protected readonly UserStatisticsProviderService = UserStatisticsProviderService;


  protected readonly ConfigService = ConfigService;

  public ngOnInit(): void {
    this.authService.loggedIn.subscribe(loggedIn => {
      this.currentUser = this.authService.currentUser;
    });
  }
}
