import {Injectable, isDevMode} from '@angular/core';
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ViewSizeService {

  private viewWide: BehaviorSubject<ViewWide> = new BehaviorSubject<ViewWide>(undefined);
  public viewWide$: Observable<ViewWide> = this.viewWide.asObservable();

  private viewHeight: BehaviorSubject<ViewHeight> = new BehaviorSubject<ViewHeight>(undefined);
  public viewHeight$: Observable<ViewHeight> = this.viewHeight.asObservable();





  constructor(private breakpointObserver: BreakpointObserver) {

    this.breakpointObserver
      .observe([
        '(max-width: 576px)',
        '(min-width: 577px) and (max-width: 768px)',
        '(min-width: 769px) and (max-width: 992px)',
        '(min-width: 993px) and (max-width: 1600px)',
        '(min-width: 1601px)',
        '(max-height: 874px)',
        '(min-height: 875px)'
      ])
      .subscribe((state: BreakpointState) => {
        this.breakpointWidthChanged();
        this.breakpointHeightChanged();
      });

  }

  private breakpointWidthChanged() {
    if (this.breakpointObserver.isMatched('(max-width: 576px)')) {
      this.viewWide.next(ViewWide.XS);

      if (isDevMode()) {
        console.log('Breakpoint Width XS: ' + this.breakpointObserver.isMatched('(max-width: 576px)'));
      }

      return;
    }

    if (this.breakpointObserver.isMatched('(min-width: 577px) and (max-width: 768px)')) {
      this.viewWide.next(ViewWide.SM);

      if (isDevMode()) {
        console.log('Breakpoint Width SM: ' + this.breakpointObserver.isMatched('(min-width: 577px) and (max-width: 768px)'));
      }

      return;
    }

    /*
    if (this.breakpointObserver.isMatched('')) {
      this.viewWide.next(ViewWide.MD);
      return;
    }
     */

    if (this.breakpointObserver.isMatched('(min-width: 769px) and (max-width: 992px)')) {
      this.viewWide.next(ViewWide.LG);

      if (isDevMode()) {
        console.log('Breakpoint Width LG: ' + this.breakpointObserver.isMatched('(min-width: 769px) and (max-width: 992px)'));
      }

      return;
    }

    if (this.breakpointObserver.isMatched('(min-width: 993px) and (max-width: 1600px)')) {
      this.viewWide.next(ViewWide.XL);

      if (isDevMode()) {
        console.log('Breakpoint Width XL: ' + this.breakpointObserver.isMatched('(min-width: 993px) and (max-width: 1600px)'));
      }

      return;
    }

    if (this.breakpointObserver.isMatched('(min-width: 1601px)')) {
      this.viewWide.next(ViewWide.XXL);

      if (isDevMode()) {
        console.log('Breakpoint Width XXL: ' + this.breakpointObserver.isMatched('(min-width: 1601px)'));
      }

      return;
    }
  }

  private breakpointHeightChanged() {
    if (this.breakpointObserver.isMatched('(max-height: 874px)')) {
      this.viewHeight.next(ViewHeight.LG);

      if (isDevMode()) {
        console.log('Breakpoint Height LG: ' + this.breakpointObserver.isMatched('(max-height: 874px)'));
      }

      return;
    }

    if (this.breakpointObserver.isMatched('(min-height: 875px)')) {
      this.viewHeight.next(ViewHeight.XL);

      if (isDevMode()) {
        console.log('Breakpoint Height XL: ' + this.breakpointObserver.isMatched('(min-height: 875px)'));
      }

      return;
    }
  }
}

export enum ViewWide {
  XS = 0,
  SM = 1,
  MD = 2,
  LG = 3,
  XL = 4,
  XXL = 5
}

export enum ViewHeight {
  XS = 0,
  SM = 1,
  MD = 2,
  LG = 3,
  XL = 4,
}
