export class ErrorHandler {
  // tslint:disable-next-line:no-unnecessary-initializer
  public static printError(error: any, relatedObject?: any) {
    console.log(new Date().toLocaleString() + ': Following error was received:');
    console.log(error);

    if (relatedObject !== undefined) {
      console.log(new Date().toLocaleString() + ': The error was related to the following object instance:');
      console.log(relatedObject);
    }

    console.log('=============================================================')
  }
}
