import { Injectable } from '@angular/core';
import {UserConfigurationService} from './backend/user-configuration.service';
import {CostCenterConfiguration} from '../../modules/metadata/costcenter-profile/models/CostCenterConfiguration';
import {Info} from '../../modules/home/infos/info';
import {UserInformationResponse, UserInformationType} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {ErrorHandler} from './error-handler/error-handler';
import {DeviceLocation} from '../../core/services/location.service';
import {InfosService} from '../../modules/home/infos/infos.service';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class LocationAwareCallSystemService {
  public costCenterConfiguration = new CostCenterConfiguration();

  private supportRequested: boolean;
  private alertRaised: boolean;

  private activeSupportRequest: UserInformationResponse;
  private activeAlert: UserInformationResponse;

  private deviceLocation: DeviceLocation;

  constructor(private userConfigurationService: UserConfigurationService,
              private infoService: InfosService,
              private toastrService: ToastrService) { }

  public async Init(deviceLocation: DeviceLocation) {
      this.deviceLocation = deviceLocation;

    if (!deviceLocation.IsValid) {
      return;
    }

      const response = await this.userConfigurationService.getCostCenterConfiguration(this.deviceLocation.CostCenter + this.deviceLocation.Line);

      if (response) {
        this.costCenterConfiguration.mapFromResponse(response);
      }
  }

  public createAlert() {
    if (!this.alertRaised) {
      const info = new Info();
      const current = new Date();

      info.informationType = UserInformationType.Alert;
      info.title = 'Hilferuf ausgelöst in Linie ' + this.deviceLocation.CostCenter + this.deviceLocation.Line + ' an ' + this.deviceLocation.WorkSequence + '.';
      info.description = 'Dies ist ein automatisch erzeugter Hilferuf! Er wurde ausgelöst für ' + this.deviceLocation.CostCenter + this.deviceLocation.Line + ' an ' + this.deviceLocation.WorkSequence + '!';
      info.activeFrom = this.getActiveFrom(current);
      info.activeUntil = this.getActiveUntil(current, info.informationType);
      info.showGlobal = false;
      info.costCenters = [this.deviceLocation.CostCenter + this.deviceLocation.Line];
      info.workSequences = [this.deviceLocation.WorkSequence];

      this.infoService.createOrUpdateInfo(info)
        .then(r => {
          this.toastrService.success('<small>' + 'Standort: ' + 'Kostenstelle ' + this.deviceLocation.CostCenter + ' / Linie ' + this.deviceLocation.Line + ' an Arbeitsfolge ' + this.deviceLocation.WorkSequence + '!' + '</small>', 'HILFERUF ausgelöst!');
          this.alertRaised = true;
          this.activeAlert = r;
        }).catch(error => {
        this.toastrService.error('Hilferuf konnte nicht ausgelöst werden!', 'ACHTUNG! Hilferuf nicht erfolgreich!');
        ErrorHandler.printError(error);
      });


    } else {
      this.infoService.deleteInfo(this.activeAlert.id).finally(() => {
        this.alertRaised = false;
        this.activeAlert = undefined;
      });
    }

  }

  public createSupportRequest() {
    if (!this.supportRequested) {
      const info = new Info();
      const current = new Date();

      info.informationType = UserInformationType.Supportrequest;
      info.title = 'Unterstützung angefordert in Linie ' + this.deviceLocation.CostCenter + this.deviceLocation.Line + ' an ' + this.deviceLocation.WorkSequence + '.';
      info.description = 'Dies ist ein automatisch erzeugter Unterstützungs-Ruf! Er wurde ausgelöst für ' + this.deviceLocation.CostCenter + this.deviceLocation.Line + ' an ' + this.deviceLocation.WorkSequence + '!';
      info.activeFrom = this.getActiveFrom(current);
      info.activeUntil = this.getActiveUntil(current, info.informationType);
      info.showGlobal = false;
      info.costCenters = [this.deviceLocation.CostCenter + this.deviceLocation.Line];
      info.workSequences = [this.deviceLocation.WorkSequence];

      this.infoService.createOrUpdateInfo(info)
        .then(r => {
          this.toastrService.success('<small>' + 'Standort: ' + 'Kostenstelle ' + this.deviceLocation.CostCenter + ' / Linie ' + this.deviceLocation.Line + ' an Arbeitsfolge ' + this.deviceLocation.WorkSequence + '!' + '</small>', 'Unterstützungs-Ruf ausgelöst!');
          this.supportRequested = true;
          this.activeSupportRequest = r;
        }).catch(error => {
        this.toastrService.error('Unterstützungs-Ruf konnte nicht ausgelöst werden!', 'ACHTUNG! Unterstützungs-Ruf nicht erfolgreich!');
        ErrorHandler.printError(error);
      });
    } else {
      this.infoService.deleteInfo(this.activeSupportRequest.id).finally(() => {
        this.supportRequested = false;
        this.activeSupportRequest = undefined;
      });
    }
  }

  private getActiveFrom(current: Date): string {
    return (new Date(current.getTime() - 2 * 60000)).toISOString();
  }

  private getActiveUntil(current: Date, userInformationType: UserInformationType): string {
    let timeToAdd = 5;
    if (userInformationType === UserInformationType.Alert) {
      timeToAdd = this.costCenterConfiguration.alertDurationInMinutes;
    }

    if (userInformationType === UserInformationType.Supportrequest) {
      timeToAdd = this.costCenterConfiguration.supportRequestDurationInMinutes;
    }


    return (new Date(current.getTime() + timeToAdd * 60000)).toISOString();
  }
}
