import {
  UserInformationCreateRequest,
  UserInformationResponse,
  UserInformationType, UserInformationUpdateRequest
} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {BigScreenAppearance} from '@cstx/volkswagen-mqs-user-configuration-service-client/model/bigScreenAppearance';
import {BigScreenArea} from '@cstx/volkswagen-mqs-user-configuration-service-client/model/bigScreenArea';
import {UseCaseFile} from '../../../shared/components-merged/usecase-file-explorer/useCaseFile';
import {isDevMode} from '@angular/core';


export class Info {
  private _isPublished: boolean;
  public get isPublished(): boolean {
    return this._isPublished;
  }

  public set isPublished(value: boolean) {
    this._isPublished = value;
  }
  get bigScreenAppearance(): BigScreenAppearance {
    return this._bigScreenAppearance;
  }

  set bigScreenAppearance(value: BigScreenAppearance) {
    this._bigScreenAppearance = value;
    this.hasChanged = true;

    if (isDevMode()) {
      console.log('bigScreenAppearance changed');
    }
  }
  get bigScreenArea(): BigScreenArea {
    return this._bigScreenArea;
  }

  set bigScreenArea(value: BigScreenArea) {
    this._bigScreenArea = value;
    this.hasChanged = true;

    if (isDevMode()) {
      console.log('bigScreenArea changed');
    }
  }
  get workSequences(): string[] {
    return this._workSequences;
  }

  set workSequences(value: string[]) {
    this._workSequences = value;
    this.hasChanged = true;

    if (isDevMode()) {
      console.log('workSequences changed');
    }
  }
  get costCenters(): string[] {
    return this._costCenters;
  }

  set costCenters(value: string[]) {
    this._costCenters = value;
    this.hasChanged = true;

    if (isDevMode()) {
      console.log('costCenters changed');
    }
  }
  get showGlobal(): boolean {
    return this._showGlobal;
  }

  set showGlobal(value: boolean) {
    if (this._showGlobal !== value) {
      this._showGlobal = value;
      this.hasChanged = true;

      if (isDevMode()) {
        console.log('showGlobal changed');
      }
    }
  }
  get activeUntil(): string {
    return this._activeUntil;
  }

  set activeUntil(value: string) {
    this._activeUntil = value;
    this.hasChanged = true;

    if (isDevMode()) {
      console.log('activeUntil changed');
    }
  }
  get activeFrom(): string {
    return this._activeFrom;
  }

  set activeFrom(value: string) {
    this._activeFrom = value;
    this.hasChanged = true;

    if (isDevMode()) {
      console.log('activeFrom changed');
    }
  }
  get informationType(): UserInformationType {
    return this._informationType;
  }

  set informationType(value: UserInformationType) {
    this._informationType = value;
    this.hasChanged = true;

    if (isDevMode()) {
      console.log('informationType changed');
    }
  }
  get description(): string {
    return this._description;
  }

  set description(value: string) {
    if (this._description !== value) {
      this._description = value;
      this.hasChanged = true;

      if (isDevMode()) {
        console.log('description changed');
      }
    }
  }
  get title(): string {
    return this._title;
  }

  set title(value: string) {
    if (this._title !== value) {
      this._title = value;
      this.hasChanged = true;

      if (isDevMode()) {
        console.log('title changed');
      }
    }
  }

  public hasChanged: boolean;
  public id: string;
  private _title: string;
  private _description: string;
  public image: any;
  private _informationType: UserInformationType;
  private _activeFrom: string;
  private _activeUntil: string;

  private _showGlobal: boolean;


  public pdf: any;
  public get isActive(): boolean {
    if (!this._activeFrom || !this._activeUntil) {
      return false;
    }

    const from = Date.parse(this._activeFrom);
    const until = Date.parse(this._activeUntil)
    const now = new Date().getTime();


    if (from <= until) {
    } else {
      return false;
    }

    if (from <= now && until >= now)  {
      return true;
    } else {
      return false;
    }
  }
  private _costCenters: string[];

  private _workSequences: string[];

  public fileList = new Array<UseCaseFile>();

  public createdBy: string;
  public createdAt: string;
  public modifiedBy: string;
  public modifiedAt: string;
  private _bigScreenAppearance: BigScreenAppearance;
  private _bigScreenArea: BigScreenArea;

  constructor() {
    this._title = 'New';

    this.createdAt = new Date().toLocaleString();
    this._costCenters = new Array<string>();
    this._workSequences = new Array<string>();
    this._informationType = UserInformationType.News;
    this._showGlobal = false;
  }

  public mapFromUserInformationResponse(userInformationResponse: UserInformationResponse): Info {
    this.id = userInformationResponse.id;
    this._title  = userInformationResponse.title;
    this._description = userInformationResponse.description;
    this._informationType = userInformationResponse.informationType;
    this._showGlobal = userInformationResponse.showGlobal;

    this._activeFrom = userInformationResponse.activeFrom;
    this._activeUntil = userInformationResponse.activeUntil;

    this._bigScreenAppearance = userInformationResponse.bigScreenAppearance;
    this._bigScreenArea = userInformationResponse.bigScreenArea;
    this._isPublished = userInformationResponse.isPublished;

    const costCenters = userInformationResponse.costCenterList?.split(';');
    costCenters?.forEach(c => {
      if (c !== undefined && c !== '') {
        this._costCenters.push(c);
      }
    })

    const workSequences = userInformationResponse.workSequenceList?.split(';');
    workSequences?.forEach(c => {
      if (c !== undefined && c !== '') {
        this._workSequences.push(c);
      }
    })

    this.createdAt = userInformationResponse.createdAt;
    this.createdBy = userInformationResponse.createdBy;
    this.modifiedAt = userInformationResponse.modifiedAt;
    this.modifiedBy = userInformationResponse.modifiedBy;

    return this;
  }

  mapToUserCreateRequest(): UserInformationCreateRequest {
   return {
     title: this._title,
     description: this._description,
     informationType: this._informationType,
     showGlobal: this._showGlobal,
     isPublished: this._isPublished,
     bigScreenAppearance: this._bigScreenAppearance,
     bigScreenArea: this._bigScreenArea,
     activeFrom: this._activeFrom,
     activeUntil: this._activeUntil,
     costCenterList: this._costCenters.length >= 1 ? this.flattenList(this._costCenters)  : null,
     workSequenceList: this._workSequences.length >= 1 ? this.flattenList(this._workSequences)  : null
   }
  }

    mapToUserUpdateRequest(): UserInformationUpdateRequest {
     return {
        id: this.id,
        title: this._title,
        description: this._description,
        informationType: this._informationType,
        showGlobal: this._showGlobal,
        isPublished: this._isPublished,
        bigScreenAppearance: this._bigScreenAppearance,
        bigScreenArea: this._bigScreenArea,
        activeFrom: this._activeFrom,
        activeUntil: this._activeUntil,
        costCenterList: this._costCenters.length >= 1 ? this.flattenList(this._costCenters)  : null,
        workSequenceList: this._workSequences.length >= 1 ? this.flattenList(this._workSequences)  : null

    }
  }

  private flattenList(list: string[]): string {
    let flatList = '';
    list.forEach(element => {
      flatList = element + ';' + flatList;
    })

    return flatList;
  }
}
