import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren} from '@angular/core';
import {BrowserMultiFormatReader} from '@zxing/library';
import {Subject} from 'rxjs';
import {ScanResult} from './scanResult';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-scanner2',
  templateUrl: './scanner2.component.html',
  styleUrls: ['./scanner2.component.scss']
})

export class Scanner2Component implements OnInit, OnDestroy, AfterViewInit {
  @Input() pauseScanner: boolean;
  @Input() scannerResult: string;
  @Input()  resetEventReceiver: Subject<string>;
  @Input() toggleAutoFocus: Subject<boolean>;

  @Input() toggleUseCamera: Subject<boolean>;
  @Output() scannerResultEvent = new EventEmitter();

  @Output() onAutoFocusChanged = new EventEmitter<boolean>();
  @Output() onUseCameraChanged = new EventEmitter<boolean>();

  @ViewChildren('result') resultViewChild;

  useExternalScanner       = true;
  browserMultiFormatReader = new BrowserMultiFormatReader();

  videoInputDevices = new Array<MediaDeviceInfo>();
  selectedVideoInputDevices: MediaDeviceInfo;
  multipleVideoInputDevices: boolean;

  scannerResultSubject = new Subject<string>();
  foundCode: boolean;
  autoFocusEnabled = true;

  lastResults = new Array<ScanResult>();
  interval: any;

  constructor() {}


  ngOnInit(): void {
    this.interval = setInterval(() => {
      this.checkResults();
      }, 1000);

  }

  checkResults() {
    clearInterval(this.interval);

    const _ = new Array<ScanResult>();
    this.lastResults.forEach(r => {
      if (r.cycle < 3) {
        r.cycle++;
        _.push(r);
      }
    });

    this.lastResults = _;
    this.interval = setInterval(() => {
      this.checkResults();
    }, 1000);
  }

  ngAfterViewInit() {
    this.resultViewChild.first.nativeElement.focus();
    this.initEventReceiver();
  }

  ngOnDestroy(): void {
    this.browserMultiFormatReader.reset();
  }
  /* Lifecycle handling end */

  private initDeviceList() {
    this.browserMultiFormatReader
      .listVideoInputDevices()
      .then(videoInputDevices => {
        if (videoInputDevices.length >= 1) {
          this.videoInputDevices          = videoInputDevices;
          this.multipleVideoInputDevices  = this.videoInputDevices.length > 1;
          this.selectedVideoInputDevices  = this.videoInputDevices[0];
          this.attachToDevice();

        } else {
          LoggingService.logWarning(LoggingSource.SCAN_COMPONENT, 'No videoInputDevices found.');
          this.useExternalScanner = true;
        }
      }).catch(err => {
        LoggingService.logError(LoggingSource.SCAN_COMPONENT, 'Error when detecting videoInputDevices', err);
        this.useExternalScanner = true;
      });
  }
  private attachToDevice() {
   this.browserMultiFormatReader.decodeFromVideoDevice(this.selectedVideoInputDevices.deviceId, 'video', (result, err) => {
     if (result) {
        this.scannerResult = result.getText();

        const _ = this.lastResults.find(r => r.value === this.scannerResult);
        if (_ === undefined) {
         this.lastResults.push(new ScanResult(this.scannerResult));
         this.submitText();
       }
       }
   });
  }
  /* Init section */


  /* Click Event handling */
  cancelClicked() {
    this.cancel();
  }

  changeCameraClicked() {
    this.changeCamera();
  }
  resetClicked() {
    this.reset();
    this.attachToDevice();
  }

  /* Processing */


  reset() {
    this.browserMultiFormatReader.reset();
    this.scannerResult      = '';
    this.foundCode          = false;
  }

  cancel() {
    this.reset();
  }

  changeCamera() {
    if (this.selectedVideoInputDevices.deviceId === this.videoInputDevices[0].deviceId) {
      this.selectedVideoInputDevices = this.videoInputDevices[1];
    } else {
      this.selectedVideoInputDevices = this.videoInputDevices[0];
    }

    this.reset();
    this.attachToDevice();
  }

  selectionChanged() {
    if (this.useExternalScanner) {
      this.browserMultiFormatReader.reset();
    } else {
      this.initDeviceList();
    }

    this.onUseCameraChanged.emit(!this.useExternalScanner);
  }

  resultTextChanged() {
    if  (this.scannerResult.length > 1) {
      this.scannerResultSubject.next(this.scannerResult);
    }
  }

  submitText() {
    this.scannerResultEvent.emit(this.scannerResult);
    this.scannerResult = undefined;
  }

  private initEventReceiver() {
    if (this.resetEventReceiver) {
      this.resetEventReceiver.subscribe(value => {
      if (!value) {
        this.scannerResult = undefined;
      } else {
        this.scannerResult = value;
      }

      this.retakeFocus();
      });
    }

    if (this.toggleAutoFocus) {
     this.toggleAutoFocus.subscribe(value => {
        this.autoFocusEnabled = value;

        if (this.autoFocusEnabled) {
          this.retakeFocus();
        }
     });
    }

    if (this.toggleUseCamera) {
      this.toggleUseCamera.subscribe(value => {
        this.useExternalScanner = !value;

        if (!this.useExternalScanner) {
          this.initDeviceList();
        }
      });
    }
  }

  checkFocus(event: any) {
      if (event.type === 'focusout') {
        this.retakeFocus();
      }
  }

  private retakeFocus() {
    if (this.autoFocusEnabled) {
      setTimeout(() => this.resultViewChild.first.nativeElement.focus(), 300);
    }
  }


  autoFocusChanged() {
    this.onAutoFocusChanged.emit(this.autoFocusEnabled);
    this.retakeFocus();

  }
}
