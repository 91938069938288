<div class="row pl-0 pr-0">
  <div class="col-sm-10">
    <textarea class="form-control" type="text" rows="6" [(ngModel)]="dmcList"></textarea>
  </div>
  <div class="col-sm-2">
    <button class="btn btn-sm btn-block btn-primary"
            [disabled]="!EnterpriseRoleProviderService.hasRequiredClaimByName('tools-dmc-parse-bulk') || dmcList === ''"
            (click)="parseDmcBulk()">Parse</button>
    <span class="small">
      <br>
      {{ 'page.parser.ParseComponent.description' | translate }}
    </span>
  </div>
</div>

