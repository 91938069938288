import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AbsoluteSiteUrlService {

  constructor() { }


  public static getCurrentSiteUrl(): string {
    let url: string;
    if (window
        && 'location' in window
        && 'protocol' in window.location
        && 'pathname' in window.location
        && 'host' in window.location) {


      url = window.location.protocol + '//' + window.location.host;
    }

    return encodeURI(url);
  }

  public static getCurrentAbsoluteSiteUrl(withSearch: boolean = false): string {
    let url: string;
    if (window
      && 'location' in window
      && 'protocol' in window.location
      && 'pathname' in window.location
      && 'host' in window.location) {


      url = window.location.protocol + '//' + window.location.host + window.location.pathname;

      if (withSearch && 'search' in window.location) {
        url = url + window.location.search;
      }
    }

    return encodeURI(url);
  }

}
