import {EventEmitter, Injectable, Input, Output} from '@angular/core';
import {FavoriteMenuEventArgs} from '../actions/actions.component';

@Injectable({
  providedIn: 'root'
})
export class DetailSectionWrapperService {
  @Input() onExpandCollapse = new EventEmitter<FavoriteMenuEventArgs>();

  @Output() toggleExpandCollapse = new EventEmitter<string>()
  @Output() toggleActiveAction = new EventEmitter<FavoriteMenuEventArgs>()

  constructor() {
    this.onExpandCollapse.subscribe(e => {
      if (e.Favorite === 'actions') {
        this.toggleActiveAction.emit(e);
      }

      this.toggleExpandCollapse.emit(e.Favorite);
    });
  }
}
