<div>
  <h6> {{ 'component-release-wizard.blocking.release.title' | translate }}</h6>
  <small>
    {{ 'component-release-wizard.blocking.release.description' | translate }}
  </small>
</div>

<div class="row">
  <div class="col">
    <div class="pt-3">
  <textarea
    [(ngModel)]="releaseReason"
    class="form-control"
    aria-describedby="releaseReasonHelp"
    rows="3"
    required
    [disabled]="releaseState !== ReleaseStatus.None">
  </textarea>
      <op-form-help-text ariaId="releaseReasonHelp" helpText="form.actions.blocking-action.help.release"></op-form-help-text>
  </div>
  </div>
    <div class="col">
      <div class="row pl-3 pt-3">
        <div class="cpp-column-fixed-width-25">
          <input type="checkbox" (click)="selectAll($event)" [disabled]="releaseState !== ReleaseStatus.None">
        </div>
        <div class="col">
          {{ 'actions-wizard.label.blockingReason' | translate }}
        </div>
        <div class="col text-center">
          {{ 'component-profile-v2.events.origin' | translate }}
        </div>
        <div class="cpp-column-fixed-width-25">

        </div>
      </div>
      <div class="row pl-3 small"
           *ngFor="let release of ComponentReleaseWizardService.componentReleases">
        <div class="cpp-column-fixed-width-25">
          <input type="checkbox"
                 [(ngModel)]="release.selectedForRelease"
                 (ngModelChange)="validate()"
                 [disabled]="release.isLegacyBlocking || releaseState !== ReleaseStatus.None">
        </div>
        <div class="col">
          {{ release.blockingReason }}
        </div>
        <div class="col text-center">
          <op-component-state-badge *ngIf="release.isLegacyBlocking" [name]="'SZOnline 1.0'" [tooltip]="legacyBlockingTooltip">
          </op-component-state-badge>

          <a [href]="['/actions/blockings/edit/' + release.blockingActionNr]" target="_blank" *ngIf="!release.isLegacyBlocking">
            {{ release.blockingActionNr }}
          </a>
        </div>
        <div class="cpp-column-fixed-width-25">
          <i *ngIf="releaseState === ReleaseStatus.Initiated && release.state === ReleaseStatus.None && release.selectedForRelease" class="far fa-hourglass" style="color: darkgray"></i>
          <i *ngIf="release.state === ReleaseStatus.Initiated" class="far fa-hourglass fa-spin" style="color: darkgray"></i>
          <i *ngIf="release.state === ReleaseStatus.Done" class="fas fa-check-circle" style="color: darkgreen"></i>
          <i *ngIf="release.state === ReleaseStatus.Failed" class="fas fa-times-circle" style="color: darkred"></i>
        </div>
    </div>
      <op-form-help-text ariaId="blockingActionSelectionHelp" helpText="component-release-wizard.blockingActionSelectionHelp"></op-form-help-text>
    </div>
</div>
<div class="row pl-3 pt-3">
  <div class="col">

  </div>
  <div class="col text-right">
    <button *ngIf="!cancelHidden" class="btn btn-sm btn-secondary mr-3" (click)="cancel()"
            [disabled]="releasing">{{ 'button.cancel' | translate }}
    </button>

    <button *ngIf="releaseState !== ReleaseStatus.Done"
            class="btn btn-sm btn-primary"
            (click)="releaseSelected()"
            [disabled]="releasing || invalid ">
      {{ 'button.save' | translate }}
    </button>

    <button *ngIf="releaseState === ReleaseStatus.Done"
            class="btn btn-sm btn-primary"
            (click)="close()">
      {{ 'button.close' | translate }}
    </button>

    <div class="d-inline pl-3">
      <i *ngIf="releaseState === ReleaseStatus.Initiated" class="far fa-hourglass fa-spin" style="color: darkgray"></i>
      <i *ngIf="releaseState === ReleaseStatus.Done" class="fas fa-check-circle" style="color: darkgreen"></i>
      <i *ngIf="releaseState === ReleaseStatus.Failed" class="fas fa-times-circle" style="color: darkred"></i>
    </div>
  </div>
</div>


<ng-template #legacyBlockingTooltip>
  {{ 'component-release-wizard.legacy-blocking.tooltip' | translate }}
</ng-template>
