import {CostCenterResponse} from '@cstx/volkswagen-mqs-metadata-service-client';
import {CostCenterUpdateRequest} from '@cstx/volkswagen-mqs-metadata-service-client/model/costCenterUpdateRequest';

export class CostCenter{
  public productUnitName: string;
  public get productCenterId(): string {
    return this._productCenterId;
  }

  public set productCenterId(value: string) {
    this._productCenterId = value;
    this.validate();
  }

  public get number(): string {
    return this._nr;
  }

  public set number(value: string) {
    this._nr = value;
    this.validate();
  }
  public get nr(): string {
    return this._nr;
  }

  public set nr(value: string) {
    this._nr = value;
    this.validate();
  }

  private _nr: string;
  private _productCenterId: string;


  public id: string;
  public name: string;
  public productCenterName: string;
  public productCenterShortName: string;

  public productUnit: string;
  public productUnitId: string;

  public modifiedAt: string;
  public modifiedBy: string;
  public createdAt: string;
  public createdBy: string;

  public isSelectedForDeletion: boolean;
  public isValid: boolean;
  public isDeactivated: boolean = false;

  public get isNew() {
    return !this.id;
  }

  constructor(costCenterResponse?: CostCenterResponse) {
    if (costCenterResponse) {
      this.map(costCenterResponse);
    }

  }

  private validate() {
    let valid = true;

    if (!this._nr) {
      valid = false;
    }

    if (this._nr?.length < 3) {
      valid = false;
    }

    if (this._nr?.length >= 10) {
      valid = false;
    }

    if (!this._productCenterId || this._productCenterId === 'none' ) {
      valid = false;
    }

    this.isValid = valid;
  }

  public update(costCenter: CostCenter) {
    this.map(costCenter);
  }

  private map(costCenterResponse: CostCenterResponse) {
    this.id = costCenterResponse.id;
    this._nr = costCenterResponse.nr;
    this.name = costCenterResponse.name;
    this.createdAt = costCenterResponse.createdAt;
    this.createdBy = costCenterResponse.createdBy;
    this.modifiedAt = costCenterResponse.modifiedAt;
    this.modifiedBy = costCenterResponse.modifiedBy;

    this.productCenterShortName = costCenterResponse.productCenterShortName;
    this.productCenterName = costCenterResponse.productCenterName;
    this.productUnitName = costCenterResponse.productUnitName;


    this.productUnitId = costCenterResponse.productUnitId;
    this.productCenterId = costCenterResponse.productCenterId;

    this.isDeactivated = costCenterResponse.isDeactivated;
  }

  public mapToUpdateRequest(): CostCenterUpdateRequest {
    return {
      nr: this.nr,
      name: this.name,
      productUnitId: this.productUnitId,
      productCenterId: this.productCenterId,
      isDeactivated: this.isDeactivated
    }
  }

}
