import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'op-edit-entry-with-confirmation',
  templateUrl: './edit-entry-with-confirmation.component.html',
  styleUrls: ['./edit-entry-with-confirmation.component.scss']
})
export class EditEntryWithConfirmationComponent {
  @Input() errorMessage: string;
  @Input() disabled = false;
  @Input() hidden = false;

  @Output() onCancellation = new EventEmitter();
  @Output() onConfirmation = new EventEmitter();
  @Output() onEdit = new EventEmitter();

  public editPending: boolean;

  onEditClick() {
    if (this.disabled) {
      return;
    }
    this.onEdit.emit();
    this.editPending = true;
    this.errorMessage = undefined;
  }
}
