import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'op-copy-to-clipboard-icon',
  templateUrl: './copy-to-clipboard-icon.component.html',
  styleUrls: ['./copy-to-clipboard-icon.component.scss']
})
export class CopyToClipboardIconComponent implements OnInit {
  @Input() content: string;
  @Input() customIconClass: string;
  @Input() customIconColor: string;
  displayNotification: boolean;
  @Input() customIconStyle: any;

  constructor() { }

  ngOnInit(): void {
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.content).then(() => {
      this.displayNotification = true;
      setTimeout(() => this.displayNotification = false, 3000);
    });
  }
}
