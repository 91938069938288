import {DataFile, FileTypeEnum} from '@cstx/volkswagen-mqs-file-handling-service-client';

export class ComponentFile {
  public Id: string;
  public Name: string;
  public CreatedAt: string;
  public OriginalName: string;
  public Source: string;
  public Origin: string;
  public Size?: string;
  public s3Key?: string;
  public FhsFileType: FileTypeEnum;
  public FileType: string;
  public ComponentType: string;
  public ParserType: string;

  constructor(source: DataFile = null) {
    if (source !== null) {
      this.Id            = source.id;
      this.Name         = source.fileName;
      this.CreatedAt    = source.modifiedDate;
      this.OriginalName = source.sourceFilename;
      this.Source       = source.source;
      this.Origin       = source.origin;
      this.FhsFileType  = source.fileType;
      this.FileType     = this.GetFileTypeBasedOnFileExtension(this.Name);
      this.s3Key        = source.S3Key;
      this.Size         = source.fileSize;
      this.ComponentType = source.componentType;
    }

    this.ParserType = 'none';
  }

  GetFileTypeBasedOnFileExtension(fileName: string): string {
    if (!fileName) {
      return;
    }

    let fileExtension =  fileName.split('.').pop();
    fileExtension     = fileExtension.toLowerCase();

    switch (fileExtension) {
      case 'img':
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'svg':
        return 'image';

      case 'txt':
      case 'json':
      case 'mnr':
        return 'txt';

      case 'pdf':
        return 'protocols';

      case 'bin':
      case 'rmb':
      case 'dat':
        return 'bin';
    }

    return 'other';
  }
}
