import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QualityManagementService} from '../../services/backend/quality-management.service';
import {ComponentReleaseWizardService, ReleaseStatus} from './component-release-wizard.service';

@Component({
  selector: 'op-component-release-wizard',
  templateUrl: './component-release-wizard.component.html',
  styleUrls: ['./component-release-wizard.component.scss']
})
export class ComponentReleaseWizardComponent implements OnInit {
  @Input() cancelHidden: boolean;

  @Output() onClose = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  public releasing: boolean;
  public releaseState: ReleaseStatus = ReleaseStatus.None;
  public invalid = true;

  public set releaseReason(value: string) {
    this._releaseReason = value;
    this.validate();
  }
  public get releaseReason(): string {
    return this._releaseReason;
  }

  private _releaseReason: string;

  protected readonly ComponentReleaseWizardService = ComponentReleaseWizardService;
  protected readonly ReleaseStatus = ReleaseStatus;

  constructor(private qualityManagementService: QualityManagementService) {

  }

  public async ngOnInit() {
    ComponentReleaseWizardService.releaseEvent.subscribe(async () => {
      await this.releaseSelected();
    });
  }

  public async releaseSelected() {
    this.releasing = true;
    this.releaseState = ReleaseStatus.Initiated;

    for (const release of ComponentReleaseWizardService.componentReleases) {
      if (release.selectedForRelease && release.blockingActionNr) {
        release.state = ReleaseStatus.Initiated;
        release.releaseReason = this.releaseReason;

        /**
         * To give the async jobs in the backend a little time we wait here.
         * There is a problem, when releasing different blocking action on the same component on the same time,
         * which could lead to components being blocked but not having active blockings.
         * This needs to be addressed in backend in the future! The problem can still occur but should be not that often.
         * 18.06.2024 bkn
         */
        await new Promise(f => setTimeout(f, 2000));


        const result =
          await this.qualityManagementService.partialReleaseBlockingAction(release.blockingActionId, Array.from(release.componentNames), release.releaseReason);

        if (result) {
          release.state = ReleaseStatus.Done;
        } else {
          release.state = ReleaseStatus.Failed;
        }
      }
    }

    this.releasing = false;
    if (ComponentReleaseWizardService.hasFailedReleases()) {
      this.releaseState = ReleaseStatus.Failed;
    } else {
      this.releaseState = ReleaseStatus.Done;
    }
  }

  public selectAll(element: any) {
    ComponentReleaseWizardService.componentReleases.forEach(release => {
      if (!release.isLegacyBlocking) {
        release.selectedForRelease = element.srcElement.checked;
      }
    })

    this.validate();
  }

  public cancel() {
    this.onCancel.emit();
    this.releaseState = ReleaseStatus.None;
    ComponentReleaseWizardService.onReleaseAbort.emit();
  }

  public validate() {
    this.invalid =
      !(this.releaseReason?.length >= 4 && ComponentReleaseWizardService.selectedToReleaseCount() > 0);
  }



  public close() {
    this.onClose.emit();
    ComponentReleaseWizardService.onReleaseFinished.emit();
  }
}
