import {AfterViewInit, Component, EventEmitter, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {PreviewRequest} from './previewRequest';
import {UseCaseFile} from './useCaseFile';
import {ConfigService} from '../../../core/services/config.service';
import {ActivatedRoute} from '@angular/router';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {FileExplorerService} from './file-explorer.service';
import {
  AttributeEntry,
  FilterValue,
  InputType
} from '../../components/guided-filter-input/guided-filter-input.component';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'op-usecase-file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.scss']
})
export class FileExplorerComponent implements OnInit, AfterViewInit {
  @Input() usecase: string;
  @Input() usecasePrefix: string;
  @Input() searchDisabled: boolean;
  @Input() disabled: boolean;
  @Input() triggerReload: EventEmitter<any>;
  @Input() uploadDisabled: boolean;

  previewRequestEvent = new Subject<PreviewRequest>();
  triggerTreeviewReload = new Subject<any>();
  showUploadRequestEvent = new Subject<boolean>();
  uploadRequested: boolean;

  defaultUseCase: 'none';
  public viewSizeSM: boolean;
  showPreview: boolean;

  searching = false;
  /**
   * TODO: Like many other things, this should be part of search generalization
   * @param searching Is used to disable the input and visualize that we are searching
   * @param searchInvalid Triggers the invalid class on the input search field
   * @param searchString Holds the string we use for searching
   * @param searchBuildDateRange Holds the production date range we use for searching
   * @param searchAliasMappings Alias table for mapping human readable attributes to object attributes
   */
  public clearFilterSub: Subject<void> = new Subject<void>();
  public searchOptionSource: Subject<Array<AttributeEntry>> = new Subject<Array<AttributeEntry>>();
  public searchAliasMappings: Array<AttributeEntry> = [];
  groupsLoaded: boolean;

  constructor(private configService: ConfigService,
              private route: ActivatedRoute,
              private breakpointObserver: BreakpointObserver,
              private fileExplorerService: FileExplorerService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.prepareUseCaseMatchGroupsForSearch();

    this.breakpointObserver
    .observe(['(max-width: 768px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.viewSizeSM = true;
      } else {
        this.viewSizeSM = false;
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.searchDisabled) {
      this.triggerTreeviewReload.next(null);
    }

    if (this.triggerReload) {
      this.triggerReload.subscribe(() => {
        console.log('fileExplore event received.')
        this.triggerTreeviewReload.next(null);
        console.log('Event forwarded to treeview');
      });
    }
  }

  private prepareUseCaseMatchGroupsForSearch() {
    this.fileExplorerService.GetSearchGroupForUseCase(this.usecase).then(r => {
      r.forEach(group => {
        const e = new AttributeEntry();
        e.inputType = InputType.String;

        const groupName = group.replace('>', '').replace('<', '');
        e.key = this.tryTranslate('cpp.useCase.group.' + groupName, groupName);
        e.value = e.key;

        this.searchAliasMappings.push(e);
      })

      const f = new AttributeEntry();
      f.inputType = InputType.String;
      f.key = this.tryTranslate('cpp.useCase.group.fileName', 'File');
      f.value = 'File';
      this.searchAliasMappings.push(f);
    }).finally(() => {
      this.groupsLoaded = true;
    });
  }

  fileSelected(file: UseCaseFile) {
    const previewRequest = new PreviewRequest();

    previewRequest.fileName = file.Name;
    previewRequest.s3Key = file.s3Key;
    previewRequest.createdAt = file.CreatedAt;
    previewRequest.size = file.Size;

    if (typeof file !== 'string') {
      this.showPreview = true;
    }
    this.previewRequestEvent.next(previewRequest);
  }

  showUpload() {
    this.uploadRequested = true;
    this.showUploadRequestEvent.next(true);
  }

  onUploadRequestEnd(uploaded: boolean) {
    if (uploaded) {
      this.triggerTreeviewReload.next(null);
    }

    this.uploadRequested = false;
  }

  onDeleteRequestEnd(deleted: boolean) {
    if (deleted) {
      this.triggerTreeviewReload.next(null);
    }
  }

  /**
   *
   * @param filterValues The value filterValues contains optional search values.
   * @private
   * The method builds up a new filter from currently set filter values or given new filter values,
   * potentially set sort options and a potentially set date range. Afterwards it triggers the list
   * update with the constructed filter.
   */
  public onSearch(filterValues?: Array<FilterValue>) {
    this.fileExplorerService.SetFilter(filterValues);
    this.triggerTreeviewReload.next(null);
  }

  public setFilterValues(filterValues: Array<FilterValue>) {
    this.fileExplorerService.SetFilter(filterValues);
  }

  public onSearchAbort() {
    this.fileExplorerService.SetFilter(new Array<FilterValue>());
    this.triggerTreeviewReload.next(null);
  }

  private tryTranslate(key: string, alternative: string) {
    const translated = this.translate.instant(key);

    if (translated !== key) {
      return translated;
    }

    return alternative;
  }

}
