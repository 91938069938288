import {Component, OnDestroy, OnInit} from '@angular/core';
import {WebSocketMessage, WebsocketsService} from '../../../core/services/websockets.service';
import {KioskModeService} from '../../../core/services/kiosk-mode.service';
import {CheckResult} from '@cstx/volkswagen-mqs-faulty-component-tracking-service-client';


@Component({
  selector: 'op-websocket-connections-tester',
  templateUrl: './websocket-connections-tester.component.html',
  styleUrls: ['./websocket-connections-tester.component.scss']
})
export class WebsocketConnectionsTesterComponent implements OnInit, OnDestroy {


  constructor(public websocketService: WebsocketsService) {
    WebsocketsService.onConnect.subscribe(() => {
      this.isConnected = true;
      this.connectedAt = new Date();
    });

    WebsocketsService.onDisconnect.subscribe(() => {
      this.isConnected = false;
      this.connectedAt = undefined;
    });

    WebsocketsService.onError.subscribe(errorEvent => {
      console.log(errorEvent);

      this.isConnected = false;
      this.connectedAt = undefined;
    });

    WebsocketsService.onMessageReceived.subscribe(messageEvent => {
      if (messageEvent.subject === undefined) {
        this.count('Rejected');
        return;
      }

      this.lastMessageReceivedAt = new Date();
      this.messages.push(messageEvent);
      this.count('Overall');

      if (messageEvent.subject === 'ComponentCreated') {
        const msg = new ComponentCreatedMessage(messageEvent);
        this.componentCreatedMessages.push(msg)

        this.count(messageEvent.subject );
        this.countPerCostCenter(msg.costCenter);

        this.addToCostCenterFilter(msg);
        this.addToPartNumbersFilter(msg);


      } else if (messageEvent.subject === 'FaultCreated') {
        const msg = new FaultCreatedMessage(messageEvent);

        this.faultCreatedMessages.push(msg)

        this.count(messageEvent.subject);
        this.addToCostCenterFilter(msg);
        this.addToPartNumbersFilter(msg);

      } else if (messageEvent.subject === 'notification.event.v1.generic') {

        const msg = new GenericMessage(messageEvent);
        this.genericMessages.push(msg);

        messageEvent.subject = 'GenericMessage';
        this.count(messageEvent.subject);
        this.addToCostCenterFilter(msg);

      }  else if (messageEvent.subject === 'notification.event.v1.workshift') {
        const msg = new WorkShiftMessage(messageEvent);
        this.workShiftMessages.push(msg);

        messageEvent.subject = 'WorkShiftMessage';
        this.count(messageEvent.subject );
        this.addToCostCenterFilter(msg);
      }
      else {
        this.count('Other');
      }

      this.subjects.add(messageEvent.subject);
    });
  }

  public isConnected = false;
  public counters = new Array<WebSocketCounter>()
  public costCenterCounters = new Array<CostCenterCounter>();

  public messages = new Array<WebSocketMessage>();
  public componentCreatedMessages = new Array<ComponentCreatedMessage>();
  public faultCreatedMessages = new Array<FaultCreatedMessage>();
  public genericMessages = new Array<GenericMessage>();
  public workShiftMessages = new Array<WorkShiftMessage>();

  public costCenters = new Set<string>();
  public subjects = new Set<string>();
  public partNumbers = new Set<string>();

  public hiddenCostCenter = new Set<string>();
  public hiddenSubjects = new Set<string>();
  public hiddenPartNumbers = new Set<string>();
  public filtered = false;

  public tabActive = 'raw';
  public connecting = false;

  public plantCycleTime: number;

  public connectedAt: Date;
  public lastMessageReceivedAt: Date;

  protected readonly KioskModeService = KioskModeService;

  public async ngOnInit(): Promise<void> {

  }


  public ngOnDestroy() {
    this.close();
  }

  public async open() {
    this.connecting = true;
    await this.websocketService.open();
    this.connecting = true;

  }

  public close() {
    this.websocketService.close();
  }

  public isActiveTab(tab: string) {
    return this.tabActive === tab;
  }

  public setActive(tab: string) {
    this.tabActive = tab;
  }

  public count(category: string) {
    let counter = this.counters.find(c => c.category === category);

    if (!counter) {
      counter = new WebSocketCounter(category);
      this.counters.push(counter);
    }

    counter.messageCount = counter.messageCount + 1;
  }

  public countPerCostCenter(costCenter: string) {
    let counter =
      this.costCenterCounters
        .find(c => c.costCenter === costCenter);

    if (!counter) {
      counter = new CostCenterCounter(costCenter);
      this.costCenterCounters.push(counter);

      this.costCenterCounters
        .sort((a, b) => a.costCenter.localeCompare(b.costCenter));
    }

    counter.count = counter.count + 1;

    if (counter.count >= 5) {
      const secondsCurrent = (new Date()).getTime() / 1000;
      const secondsSinceStart = this.connectedAt.getTime() / 1000;
      const secondsDifference = secondsCurrent - secondsSinceStart;

      counter.cycleTimeCurrent = secondsDifference / counter.count;
    }

    this.calculatePlantCycleTime();
  }

  private calculatePlantCycleTime() {
    const cycleTimes = this.costCenterCounters
      .filter(cc => cc.cycleTimeCurrent && cc.cycleTimeCurrent > 0)
      .map(cc => cc.cycleTimeCurrent);

    if (cycleTimes.length > 0) {
      this.plantCycleTime = cycleTimes.reduce((sum, current) => sum + current) / cycleTimes.length;
    }
  }

  public toggleCostCenterVisibility(value: string) {
    if (this.hiddenCostCenter.has(value)) {
      this.hiddenCostCenter.delete(value);


      if (!this.hasActiveFilters()) {
        this.filtered = false;
      }

    } else {
      this.hiddenCostCenter.add(value);
      this.filtered = true;
    }
  }

  public toggleSubjectVisibility(value: string) {
    if (this.hiddenSubjects.has(value)) {
      this.hiddenSubjects.delete(value);

      if (!this.hasActiveFilters()) {
        this.filtered = false;
      }
    } else {
      this.hiddenSubjects.add(value);
      this.filtered = true;
    }
  }

  public togglePartNumberVisibility(value: string) {
    if (this.hiddenPartNumbers.has(value)) {
      this.hiddenPartNumbers.delete(value);

      if (!this.hasActiveFilters()) {
        this.filtered = false;
      }
    } else {
      this.hiddenPartNumbers.add(value);
      this.filtered = true;
    }
  }

  private hasActiveFilters(): boolean {
    if (this.hiddenPartNumbers.size > 0) {
      return true;
    }

    if (this.hiddenCostCenter.size > 0) {
      return true;
    }

    if (this.hiddenSubjects.size >0 ) {
      return true;
    }

    return false;
  }

  private addToCostCenterFilter(message: MessageBase) {
    if (!message.costCenter) {
      message.costCenter = '00000';
    }

    if (this.filtered &&
      !this.costCenters.has(message.costCenter)) {
      this.hiddenCostCenter.add(message.costCenter)
    }

    this.costCenters.add(message.costCenter);
    const costCentersArray
      = Array.from(this.costCenters);

    costCentersArray.sort((a, b) => a.localeCompare(b));
    this.costCenters = new Set(costCentersArray);
  }

  private addToPartNumbersFilter(message: ComponentMessageBase) {
    if (this.filtered && !this.partNumbers.has(message.partNumber)) {
      this.hiddenPartNumbers.add(message.partNumber)
    }

    const partNumbers = this.partNumbers;
    partNumbers.add(message.partNumber);

    const partNumberArray
      = Array.from(partNumbers);

    partNumberArray.sort((a, b) => a.localeCompare(b));
    this.partNumbers = new Set(partNumberArray);
  }

  public isHidden(msg: MessageBase): boolean {
    if (msg instanceof ComponentMessageBase) {
      if (this.hiddenCostCenter.has(msg.costCenter)) {
        return true;
      }

      if (this.hiddenPartNumbers.has(msg.partNumber)) {
        return true;
      }
    }

    if (msg instanceof FaultCreatedMessage) {
      if (this.hiddenCostCenter.has(msg.costCenter)) {
        return true;
      }

      if (this.hiddenPartNumbers.has(msg.partNumber)) {
        return true;
      }
    }

    return false;
  }

  public resetCycleTimes() {
    this.costCenterCounters = new Array<CostCenterCounter>();
  }

  public clearFilters() {
    this.hiddenPartNumbers.clear();
    this.hiddenSubjects.clear();
    this.hiddenCostCenter.clear();

    this.filtered = false;
  }
}

export class MessageBase {
  public rawMessage?: any;
  public rawMessageJson?: string;
  public receivedAt?: Date;
  public costCenter?: string;

  constructor(raw: WebSocketMessage) {
    this.rawMessage = JSON.parse(raw.message);
    this.rawMessageJson = raw.message;
    this.costCenter = this.rawMessage.costCenter;

    this.receivedAt = new Date();
  }
}


export class GenericMessage extends MessageBase {
  public subject: string;
  public message: string;
  public event: string;


  constructor(raw: WebSocketMessage) {
    super(raw);

    this.event = this.rawMessage.event;
    this.subject = this.rawMessage.subject;
    this.message = this.rawMessage.message;
  }
}

export class WorkShiftMessage extends MessageBase {
  public shift: string;
  public shiftEvent: string;
  public actual: string;
  public target: string


  constructor(raw: WebSocketMessage) {
    super(raw);

    this.costCenter = this.rawMessage.costCenter;
    this.shift = this.rawMessage.shift;
    this.shiftEvent = this.rawMessage.event;
    this.actual = this.rawMessage.actual;
    this.target = this.rawMessage.target;

  }
}

export class ComponentMessageBase extends MessageBase {
  public componentName?: string;
  public componentType?: string;
  public partNumber?: string


  constructor(raw: WebSocketMessage) {
    super(raw);
  }
}

export class ComponentCreatedMessage extends ComponentMessageBase {
  public componentCreationDate?: Date

  constructor(raw: WebSocketMessage) {
    //  {"value":1,"componentName":"VWS081124160233104L105401A","componentType":"PART","buildDate":"2024-11-08T14:59:15Z","costCenter":"71151","keyCode":null,"partNumber":"04L105401A"}

    super(raw)

    this.componentName = this.rawMessage.componentName;
    this.costCenter = this.rawMessage.costCenter;
    this.componentType = this.rawMessage.componentType;
    this.partNumber = this.rawMessage.partNumber ? this.rawMessage.partNumber : this.rawMessage.keyCode;
    this.componentCreationDate = new Date(this.rawMessage.buildDate);

  }
}

export class FaultCreatedMessage extends ComponentMessageBase {
  public checkResult?: CheckResult;
  public rawPartNumber?: string;
  public reason?: string;
  public createdAt?: Date;

  constructor(raw: WebSocketMessage) {
    super(raw);

    this.checkResult = this.rawMessage.checkResult;
    this.partNumber = this.rawMessage.componentType.typeId;
    this.rawPartNumber = this.rawMessage.componentType.rawTypeId;
    this.reason = this.rawMessage.faultType.description;
    this.createdAt = new Date(this.rawMessage.createdAt);
    this.componentType = 'PART';

    const componentId = this.rawMessage.componentId as string;
    const componentIdParts = componentId.split('_');
    this.componentName = componentIdParts[0];
  }
}


export class WebSocketCounter {
  constructor(category: string) {
    this.category = category;
  }

  public category: string;
  public messageCount = 0;
}

export class CostCenterCounter {
  constructor(costCenter: string) {
    this.costCenter = costCenter;
  }

  public costCenter: string;
  public count = 0;
  public cycleTimeCurrent = 0;
}




