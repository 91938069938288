import {EventEmitter, Injectable} from '@angular/core';
import {Engine, KeycodeHistory, Rework} from '../models/engine';
import {EngineFilter} from '../models/enginefilter';
import {
  BulkCreateRequestKeyCodeHistoryCreateRequest,
  EngineBulkControllerService,
  EngineControllerService,
  EngineControllerV2Service,
  EngineIdentifier,
  EngineResponse,
  KeyCodeHistoryCreateRequest,
  KeyCodeHistoryUpdateRequest,
  PartNumberDefinitionControllerService,
  Source
} from '@cstx/volkswagen-mqs-engine-service-client';
import {map, takeUntil} from 'rxjs/operators';
import {firstValueFrom, Subject} from 'rxjs';
import {ReportService} from '../../../shared/components/excel-export/report.service';
import {
  BulkCreateKeyCodeHistoryRequestParams
} from '@cstx/volkswagen-mqs-engine-service-client/api/engineBulkController.service';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';

@Injectable()
export class EngineService {
  engines: Array<EngineResponse> = new Array<EngineResponse>();
  engineFilter = new EngineFilter();
  enginesCount: number;
  filteredEnginesCount: number;
  showSpinner = false;
  showCountSpinner = false;
  requestPending: boolean;
  loadingFinished = new EventEmitter<boolean>();

  private pendingEnginesGetRequests$ = new Subject<void>();


  constructor(private engineControllerService: EngineControllerService,
              private engineControllerV2: EngineControllerV2Service,
              private engineBulkControllerService: EngineBulkControllerService,
              private partNumberDefinitionService: PartNumberDefinitionControllerService,
              private reportService: ReportService) {
  }

  public getEngine(id?: string, code?: string, nr?: string): Promise<EngineResponse> {
    if (id !== null) {
      return this.engineControllerService.getEngineById({id}).toPromise();
    } else {
      if (code !== null && nr !== null) {
        return firstValueFrom(this.engineControllerService.getEngineByCodeAndNumber({engineCode: code, engineNumber: nr}));
      } else {
        LoggingService.logError(LoggingSource.ENGINE_SERVICE,
          'Missing parameter to get engine: ' + (code === null ? 'code' : '') + (nr === null ? (code === null ? ', nr' : 'nr') : ''));
      }
    }
  }

  public updateEngine(engine: Engine): Promise<Engine> {
    const request = {
      id: engine.id,
      engineUpdateRequest: {
        buildDate: engine.buildDate,
        componentName: engine.componentName,
        costCenter: engine.costCenter,
        engineCode: engine.engineCode,
        engineNumber: engine.engineNumber,
        keyCode: engine.keyCode,
        lotNumber: engine.lotNumber,
        lotSize: engine.lotSize,
        orderNumber: engine.orderNumber,
        productionNumber: engine.productionNumber,
        productionStartDate: engine.productionStartDate,
        source: engine.source
      }
    }

    return firstValueFrom(this.engineControllerService.updateEngine(request).pipe(
      map(response => new Engine(response))
    ));
  }

  public createRework(rework: Rework, engineId: string): Promise<Rework> {
    const request = {
      id: engineId,
      reworkCreateRequest: {
        analysis: rework.analysis,
        complaint: rework.complaint,
        deliveredAt: rework.deliveredAt,
        dispatchedAt: rework.dispatchedAt,
        employee: rework.employee,
        qualityAssuranceCheckedAt: rework.qualityAssuranceCheckedAt,
        qualityAssuranceEmployee: rework.qualityAssuranceEmployee,
        rework: rework.rework,
        reworkType: rework.reworkType,
        signature: rework.signature,
        source: rework.source
      }
    }

    return firstValueFrom(this.engineControllerService.createRework(request).pipe(map(r => r as Rework)))
  }

  public getKeyCodesByPartNumber(partNumber: string): Promise<string[]> {
    return firstValueFrom(this.partNumberDefinitionService.getAllPartNumberDefinitionsByPartNumber({partNumber})
    .pipe(map(response => response.map(entry => entry.keyCode))));
  }

  public async getKeyCodesByEngineCode(engineCode: string, keyCode: string): Promise<string[]> {
    const result = await firstValueFrom(this.partNumberDefinitionService.getAllPartNumberDefinitionsByEngineCode({engineCode}));
    const definitions =  result.filter(p => p.removalDate === null);

    const keyCodes  = definitions.map(entry => entry.keyCode).sort((a, b) => a.localeCompare(b));
    return keyCodes.filter(k => k !== keyCode);
  }

  public changeKeyCode(engine: EngineResponse, keyCodeNew: string, keepColdTests: boolean, keepHotTests: boolean): Promise<KeycodeHistory> {
    let engineIdentifier: EngineIdentifier;
    engineIdentifier = {
      componentName: engine.componentName,
      engineCode: engine.engineCode,
      engineNumber: engine.engineNumber
    };
    let keyCodeHistoryCreateRequest: KeyCodeHistoryCreateRequest;
    keyCodeHistoryCreateRequest = {
      coldTestInheritance: keepColdTests ? 'KEEP' : 'INVALIDATE',
      hotTestInheritance: keepHotTests ? 'KEEP' : 'INVALIDATE',
      keyCodeChangedAt: (new Date()).toISOString(),
      keyCodeNew,
      keyCodeOld: engine.keyCode,
      source: Source.Szonlinev2
    }
    let bulkCreateRequestKeyCodeHistoryCreateRequest: BulkCreateRequestKeyCodeHistoryCreateRequest;
    bulkCreateRequestKeyCodeHistoryCreateRequest = {engineIdentifier, createRequests: [keyCodeHistoryCreateRequest]}
    let bulkCreateKeyCodeHistoryRequestParams: BulkCreateKeyCodeHistoryRequestParams;
    bulkCreateKeyCodeHistoryRequestParams =
      {bulkCreateRequestKeyCodeHistoryCreateRequest: new Array(bulkCreateRequestKeyCodeHistoryCreateRequest)}

    return firstValueFrom(this.engineBulkControllerService.bulkCreateKeyCodeHistory(bulkCreateKeyCodeHistoryRequestParams).pipe(map(r => {
      if (r.length > 0) {
        return r[0] as KeycodeHistory;
      }
    })));
  }

  public updateKeyCodeHistoryEntry(
    engineId: string,
    entryId: string,
    keyCodeOld: string,
    keyCodeNew: string,
    keyCodeChangedAt: string,
    keepColdTests: boolean,
    keepHotTests: boolean,
    source: Source): Promise<KeycodeHistory> {
    let keyCodeHistoryUpdateRequest: KeyCodeHistoryUpdateRequest;
    keyCodeHistoryUpdateRequest = {
      keyCodeOld,
      keyCodeNew,
      keyCodeChangedAt,
      coldTestInheritance: keepColdTests ? 'KEEP' : 'INVALIDATE',
      hotTestInheritance: keepHotTests ? 'KEEP' : 'INVALIDATE',
      source
    }
    const request = {
      id: engineId,
      keyCodeHistoryId: entryId,
      keyCodeHistoryUpdateRequest
    }

    return firstValueFrom(this.engineControllerService.updateKeyCodeHistoryEntry(request).pipe(map(r => r as KeycodeHistory)));
  }

  public downloadReportFile() {
    this.reportService.requestReport(this.engineFilter);
  }

  private onCancelPendingRequests() {
    return this.pendingEnginesGetRequests$.asObservable();
  }

  private cancelGetEngines() {
    this.pendingEnginesGetRequests$.next();
  }

  private fetchCounts() {
    this.showCountSpinner = true;
    this.filteredEnginesCount = undefined;
    this.enginesCount = undefined;
    const emptyFilter = this.engineFilter.IsEmpty();
    this.fetchCount(emptyFilter);
    if (!emptyFilter) {
      this.fetchCount(!emptyFilter, this.engineFilter.getFilterParams());
    }
  }

  private fetchCount(cancelSpinner: boolean, param?: any) {
    this.engineControllerV2.getEnginesCount(param ? param : {})
    .pipe(takeUntil(this.onCancelPendingRequests()))
    .toPromise()
    .then(response => {
      if (param) {
        this.filteredEnginesCount = response;
      } else {
        this.enginesCount = response;
      }
    })
    .finally(() => {
      if (cancelSpinner) {
        this.showCountSpinner = false;
      }
    });
  }
}
