<i class="fas fa-chevron-circle-left pointer pr-1" *ngIf=!slice.first aria-hidden="true"
   (click)="goBack()"></i>

<ng-container>{{'global.word.page'| translate}} {{ slice.pageNumber + 1 }}</ng-container>

<i class="fas fa-chevron-circle-right pointer pl-1" *ngIf=!slice.last aria-hidden="true"
   (click)="goForward()"></i>

<op-select-box class="pl-2" [options]="pagingOptions"
               [defaultLabel]="defaultLabel"
               (selectEvent)="selectedItemChanged($event)">
</op-select-box>
