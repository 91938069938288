import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DetectedStack} from '../../../modules/tools/dmc/scan/models/detectedStack';
import {DetectedComponent} from '../../../modules/tools/dmc/scan/models/detectedComponent';
import {StackV2} from '../../services/backend/models/stackv2';
import {LogisticalState} from '../../../modules/engine/list/list.component';
import {Engine} from '../../services/backend/models/engine';
import {Part} from '../../services/backend/models/part';
import {ScannerResult} from '../../../modules/tools/dmc/scan/models/scannerResult';
import {LabelType} from '../../../modules/tools/dmc/scan/models/labelType';
import {DatePipe} from '@angular/common';
import {ComponentBlockWizardService} from '../component-block-wizard/component-block-wizard.service';

@Component({
  selector: 'op-stack-state-check',
  templateUrl: './stack-state-check.component.html',
  styleUrls: ['./stack-state-check.component.scss']
})
export class StackStateCheckComponent implements OnInit{
  @Input() detectedStack: DetectedStack;
  @Output() onDeleteRequested = new EventEmitter<string>();

  public stackCheckResult = new StackCheckResult();
  public detailsExtended: boolean;
  public hover: boolean;

  protected readonly LogisticalState = LogisticalState;

  constructor(private datePipe: DatePipe) {

  }

  public toggleDetailsSections() {
    if (this.stackCheckResult.isEmpty) {
      return;
    }

    this.detailsExtended = !this.detailsExtended;
  }

  public setHover(hover: boolean) {
    this.hover = hover;
  }

  public ngOnInit(): void {
    if (this.detectedStack) {
      this.stackCheckResult = new StackCheckResult(this.detectedStack);
    }
  }

  public stackedAtDate(dateTime: string) {
    return this.datePipe.transform(dateTime, 'dd.MM.yyy');
  }
  public stackedAtTime(dateTime: string) {
    return this.datePipe.transform(dateTime, 'HH:mm:ss');
  }
}

export class StackCheckResult {
  public identifier: string;
  public stack = new StackV2();
  public components = new Array<DetectedComponent>();
  public selfLink: string;
  public blocked = true;

  public componentFreeCount: number = 0;
  public componentUnFreeCount: number = 0;

  constructor(detectedStack?: DetectedStack) {
    if (detectedStack) {
      this.identifier = detectedStack.stackIdentifier;
      this.selfLink = detectedStack.Scan.stack.selfLink;
      this.blocked = detectedStack.Scan.stack.logisticalState !== LogisticalState.FREE;

      this.stack = detectedStack.Scan.stack;

      this.components = this.mapToDetectedComponent(detectedStack.Scan.stack.enginesV2)

      this.components.forEach(detectedComponent => {
        ComponentBlockWizardService.addComponentToBlock(detectedComponent.componentName);
      });
    }
  }

  public get isEmpty(): boolean {
    if (this.components.length <= 0) {
      return true;
    }

    return false;
  }

  private mapToDetectedComponent(engines?: Engine[], parts?: Part[]): Array<DetectedComponent> {
    const detectedComponents = new Array<DetectedComponent>();
    engines.forEach(engine => {
      const dC = new DetectedComponent();

      dC.componentId = engine.id;
      dC.costCenter = engine.costCenter;
      dC.componentType = 'engine';

      dC.scan = this.mapToScannerResult(engine);
      detectedComponents.push(dC);
    })

    this.componentUnFreeCount = detectedComponents.filter(c => !c.free).length;
    this.componentFreeCount = detectedComponents.filter(c => c.free).length;

    return detectedComponents;
  }

  private mapToScannerResult(engine: Engine): ScannerResult {
    const scannerResult = new ScannerResult();
    scannerResult.engine = engine;
    scannerResult.value = '';
    scannerResult.labelType = LabelType.ENGINE;
    scannerResult.blocked = engine.engineBlockedState !== 'NONE';
    scannerResult.refId = engine.id;

    return scannerResult;
  }
}
