import {Component, EventEmitter, Input, isDevMode, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {FilePreview} from '../filePreview';
import {ActivatedRoute, Router} from '@angular/router';
import {LoggingService} from '../../../../core/logging/logging.service';
import {ClipboardService} from 'ngx-clipboard';
import {DomSanitizer} from '@angular/platform-browser';
import {ComponentTypeEnum, DataFile, FileTypeEnum} from '@cstx/volkswagen-mqs-file-handling-service-client';
import {FileType} from '../fileType';
import {PreviewRequest} from '../previewRequest';
import {FileExplorerService} from '../file-explorer.service';
import {editor} from 'monaco-editor';
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;
import {ComponentType} from '@cstx/volkswagen-mqs-parsing-service-client';
import fileSize from 'filesize';
import {ToastrService} from 'ngx-toastr';
import {ErrorHandler} from '../../../services/error-handler/error-handler';
import {LoggingSource} from '../../../../core/logging/loggingSource';

@Component({
  selector: 'op-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  constructor(private activatedRouteService: ActivatedRoute,
              private router: Router,
              private fileExplorerService: FileExplorerService,
              private clipboardService: ClipboardService,
              private sanitizer: DomSanitizer,
              private toastrService: ToastrService) { }
  @Input() newPreviewRequest = new Subject<PreviewRequest>();
  @Input() useCloseBtn = false;
  @Output() triggerPreviewClose: EventEmitter<void> = new EventEmitter<void>();

  private previewRequest = new PreviewRequest();

  public filePreview = new FilePreview();
  public loading: boolean;

  public monacoEditorOptions: IStandaloneEditorConstructionOptions = {
    theme:    'vs-light',
    language: 'text/plain',
    readOnly: true,
    scrollBeyondLastLine: false,
    minimap: {enabled: false },
  };


  refreshSpinnerSpin: boolean;

  private static canUseMonacoEditor(fileName: string): boolean {
    if (!fileName) {
      return false;
    }

    let fileExtension =  fileName.split('.').pop();
    fileExtension     = fileExtension.toLowerCase();

    switch (fileExtension) {
      case 'csv':
      case 'txt':
      case 'xml':
      case 'json':
      case 'zert':
        return true;

      default:
        return false;
    }
  }

  ngOnInit(): void {
    this.newPreviewRequest.subscribe(request => {
      this.runPreview(request);
    });
  }

  /**
   *
   * @param request The preview request containing all information needed to show a preview
   * @private
   *
   * This function takes the previewRequest and maps it to the view model. Then it decides based on the parserType
   * if we try to get a parsed result of the requested file or if we directly present the file.
   *
   */
  private runPreview(request: PreviewRequest) {
    this.loading = true;
    this.filePreview = new FilePreview();

    this.previewRequest = request;

    this.mapToViewModel();

    if (this.filePreview.parserType !== 'none') {
      this.tryParse();
    } else {
      this.processFile();
    }
  }

  private tryParse() {
    this.fileExplorerService.TryParse(this.filePreview.parentId,
      this.filePreview.fileName,
      this.filePreview.parentType === ComponentTypeEnum.Engine ? 'Engine' : 'Part',
      this.filePreview.parentCostCenter, this.filePreview.parserType)
      .then(result => {
        this.processParsedFile(result);
      })
      .catch(error => {
        ErrorHandler.printError(error, this.filePreview);
        LoggingService.logError(LoggingSource.PREVIEW, 'Parsing of binary: ' + this.filePreview.fileName + ' failed', error);
        this.processFile();
      })
      .finally(() => {
        this.filePreview.LoadingComplete = true;
        this.loading = false;
      });
  }
  private processFile() {
    this.fileExplorerService.GetFile(this.filePreview.parentId, this.filePreview.fileName,
      this.filePreview.parentType as ComponentTypeEnum, this.filePreview.fhsFileType)
      .then(result => {
        this.filePreview.Blob = result;
        switch (this.filePreview.fileType) {
          case FileType.Image:
            this.processImageFile(result);
            break;
          case FileType.Text:
            this.processTextFile(result);
            break;
          case FileType.Pdf:
            this.processPdfFile(result);
            break;
          default:
            this.processOther(result);
        }
      })
      .catch(error => {
        ErrorHandler.printError(error, this.filePreview);

        LoggingService.logError(LoggingSource.PREVIEW,'Error occurred when downloading file: ' + this.filePreview.fileName, error);
        this.filePreview.HasError = true;
      })
      .finally(() => {
        this.filePreview.LoadingComplete = true;
        this.loading = false;
      });
  }
  private processPdfFile(file: Blob) {
    this.filePreview.File = file;
  }
  private processTextFile(file: Blob) {
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);

    fileReader.onloadend = () => {
      this.filePreview.File = fileReader.result;
      this.monacoEditorOptions.value = this.filePreview.File;
      this.monacoEditorOptions.language =  file.type;
    };
  }
  private processImageFile(file: Blob) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onloadend = () => {
      if (typeof fileReader.result === 'string') {
        this.filePreview.File = this.sanitizer.bypassSecurityTrustUrl(fileReader.result);
      }
      this.loading = false;
    };
  }
  private processOther(file: Blob) {
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);

    fileReader.onloadend = () => {
      this.filePreview.File = fileReader.result;
      if (PreviewComponent.canUseMonacoEditor(this.filePreview.fileName)) {
        this.monacoEditorOptions.value = this.filePreview.File;
        this.monacoEditorOptions.language =  file.type;
        this.filePreview.fileType = FileType.Text;
      } else {
        this.filePreview.HasError = true;
      }
    };
  }
  private processParsedFile(file: string) {
    if (this.filePreview.parserType === 'kaitai') {

      this.filePreview.File = JSON.stringify(file, null, 2);
      this.monacoEditorOptions.value = this.filePreview.File;
      this.monacoEditorOptions.language =  'application/json';
      this.fileExplorerService.GetFile(this.filePreview.parentId, this.filePreview.fileName,
        this.filePreview.parentType as ComponentTypeEnum, this.filePreview.fhsFileType)
        .then(result => this.filePreview.Blob = result as Blob);

    } else if (this.filePreview.parserType === 'text') {
      this.filePreview.File = file;
      this.filePreview.Blob = new Blob([file], {
        type: 'text/plain'
      });
      this.filePreview.OriginalName = 'Steckbrief_' + this.filePreview.fileName + '.txt';
      this.monacoEditorOptions.value = this.filePreview.File;
      this.monacoEditorOptions.language =  'text/plain';
    }

    this.filePreview.Parsed = true;
  }



  /**
   *
   * @param file The file object we want to present for download
   * @private
   * This method triggers the browser file download dialog to be shown.
   * It provides the file already downloaded be client as download.
   */
  private openFileForDownload(file: Blob) {
    /*if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, this.filePreview.fileName);
      return;
    }*/

    const objectUrl               = window.URL.createObjectURL(file);
    const objectLinkElement       = document.createElement('a');
    objectLinkElement.href        = objectUrl;
    objectLinkElement.download    = this.filePreview.OriginalName;
    objectLinkElement.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

    setTimeout(() => {
      window.URL.revokeObjectURL(objectUrl);
      objectLinkElement.remove();
    }, 100);
  }

  /**
   *
   * @private
   * This function maps the previewRequest model to the view model.
   */
  private mapToViewModel() {
    this.filePreview.parentId         = this.previewRequest.componentId;
    this.filePreview.parentType       = this.previewRequest.componentType;
    this.filePreview.parentCostCenter = this.previewRequest.componentCostCenter;
    this.filePreview.fhsFileType      = this.previewRequest.fhsFileType;

    // tslint:disable-next-line:radix
    const size = parseInt(this.previewRequest.fileSize)
    this.filePreview.Size             = fileSize(isNaN(size) ? 0 : size);

    this.filePreview.fileName         = this.getNormalizedFileName(this.previewRequest.fileName);
    this.filePreview.parserType       = this.previewRequest.parserType;

    this.filePreview.fileType         = this.fileExplorerService.GetFileTypeBasedOnFileExtension(this.filePreview.fileName);
    this.filePreview.directLink       = this.getDirectLink();

    if (this.filePreview.fhsFileType !== undefined) {
      this.fileExplorerService.GetFileMetadata(this.filePreview.parentId, this.filePreview.fileName,
        this.filePreview.parentType as ComponentTypeEnum, this.filePreview.fhsFileType)
        .then(result => {
          const resultData = result as DataFile;
          this.filePreview.Origin        = resultData.origin;
          this.filePreview.Source        = resultData.source;
          this.filePreview.OriginalName  = resultData.sourceFilename;
          this.filePreview.Uploaded      = resultData.modifiedDate;
        })
        .catch(error => {

          ErrorHandler.printError(error, this.filePreview);
          LoggingService.logError(LoggingSource.PREVIEW,
            'Error requesting additional metadata for file: ' + this.filePreview.fileName,
            error);
        });
    }
  }

  /**
   *
   * @private
   * This function builds up a direct link to this file, encodes the link as uri
   * and sets the value at filePreview object.
   */
  private getDirectLink(): string {
    let url: string;
    if (window
      && 'location' in window
      && 'protocol' in window.location
      && 'pathname' in window.location
      && 'host' in window.location) {
      url = window.location.protocol + '//'
        + window.location.host + '/' + this.filePreview.parentType + '/'
        + this.filePreview.parentId + '/'
        +  this.filePreview.parentCostCenter + '/'
        +  this.filePreview.fhsFileType + '/'
        + this.filePreview.fileName + '/'
        +  this.filePreview.parserType;
    }

    return encodeURI(url);
  }

  /**
   *
   * @param $event The event object containing the view element on which the event was emitted.
   * This function sets the maximized attribute on the filePreview object to true.
   */
  public onMaximizedClicked($event: any) {
    if ($event.target.id === 'MaximizePreviewIcon') {
      this.filePreview.maximized = true;
    }
  }

  /**
   *
   * @param $event The event object containing the view element on which the event was emitted.
   * This function sets the maximized attribute on the filePreview object to false.
   */
  public onMinimizeClicked($event: any) {
    if ($event.target.id === 'PreviewContentArea') {
      this.filePreview.maximized = false;
    }
  }

  /**
   * This function is called when a user clicks on the download button.
   * It triggers the process to provide the user the file previewed as download.
   */
  public onDownloadClicked() {
    if (this.filePreview.Blob) {
      this.openFileForDownload(this.filePreview.Blob);
    } else {
      this.processFile();
    }
  }

  onCloseClicked() {
    this.triggerPreviewClose.emit();
  }

  triggerComponentUpdate() {
    this.refreshSpinnerSpin = true;
    this.fileExplorerService.triggerComponentUpdate(this.previewRequest.componentId, this.filePreview.fileName,
      this.previewRequest.componentType as unknown as ComponentType, this.previewRequest.componentCostCenter);

    setTimeout(() => this.refreshSpinnerSpin = false, 1000, undefined);
  }

  private getNormalizedFileName(fileName: string) {
    if (fileName.startsWith('Steckbrief_')) {
      fileName = fileName.replace('Steckbrief_', '');
      fileName = fileName.replace('.txt', '');
    }

    return fileName;
  }

  public triggerCsvCreation() {
    this.toastrService.info('Messwerte Export gestartet!');
    this.fileExplorerService.parseCsvAsync('', this.filePreview);
  }
}


