import {Component, Input, OnInit} from '@angular/core';
import {ComponentProfile} from '../component-profile.component';
import {ConfigService} from '../../../../core/services/config.service';
import {STAGE} from '../../../../core/models/stage';
import {LogisticalState} from '../../../../modules/engine/list/list.component';
import {EngineService} from '../../../services/backend/engine.service';

@Component({
  selector: 'op-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent implements OnInit {
  @Input() componentType: string;
  @Input() componentProfile = new ComponentProfile();

  protected readonly ConfigService = ConfigService;
  protected readonly STAGE = STAGE;
  protected readonly LogisticalState = LogisticalState;
  stageEnum: STAGE;

  constructor(private engineService: EngineService,
              private configService: ConfigService) {
    this.stageEnum = this.configService.getStageEnum();
  }

  public ngOnInit(): void {
    if (this.componentProfile && this.componentProfile.engine) {
      this.getAdditionalPartNumberInfos(this.componentProfile.engine.partNumber);
    }
  }

  private async getAdditionalPartNumberInfos(partNumber: string) {
    const response = await this.engineService.getPartNumberDefinitionsByPartNumber(partNumber);

    if (response) {
      response.forEach(partNumberDefinition => {
        if (partNumberDefinition.displacementInLiter) {
          this.componentProfile.displacementInLiter = new Array<number>();
          this.componentProfile.displacementInLiter.push(partNumberDefinition.displacementInLiter);
        }

        if (partNumberDefinition.powerInKiloWatt) {
          this.componentProfile.powerInKilowatts = new Array<number>();
          this.componentProfile.powerInKilowatts.push(partNumberDefinition.powerInKiloWatt);
        }

        if (partNumberDefinition.aggregate) {
          this.componentProfile.aggregate = new Array<string>();
          this.componentProfile.aggregate.push(partNumberDefinition.aggregate);
        }
      });
    }
  }
}
