import {Component, Input, OnInit} from '@angular/core';
import {PlatformLocation} from '@angular/common';

@Component({
  selector: 'op-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit {
  @Input() componentType: string;
  @Input() componentId: string;

  constructor(private location: PlatformLocation) { }

  ngOnInit(): void {
  }

  buildQRCodeData() {
    const url = this.location.protocol + '//' + this.location.hostname + ':' + this.location.port + '/' + this.componentType + '/' + this.componentId;
    return url;
  }
}
