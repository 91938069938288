import {Component, Input, OnInit} from '@angular/core';
import {BackendStatus} from '../../services/backend/models/BackendStatus';
import {KeyValue} from '@angular/common';
import {BackendService} from '../../services/backend/models/backend-service';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {CheckBackendStatusConfiguration} from './check-backend-status-configuration';

@Component({
  selector: 'op-check-backend-status',
  templateUrl: './check-backend-status.component.html',
  styleUrls: ['./check-backend-status.component.scss']
})
export class CheckBackendStatusComponent implements OnInit{
  @Input() configuration: CheckBackendStatusConfiguration;


  public ngOnInit() {
    for (const service of this.configuration.serviceList) {
      this.workServiceStatus(service);

      setInterval(async () => {
        this.workServiceStatus(service);

      }, this.configuration.checkIntervalInSeconds * 1000);
    }
  }

  private async workServiceStatus(service: KeyValue<BackendService, BackendStatus>) {
    service.value = BackendStatus.CHECKING;

    let status: BackendStatus;
    status = await service.key.getStatus();
    service.value =  status;

    if (status !== BackendStatus.UP) {
      LoggingService.logError(LoggingSource.CORE,
        `Backend status for service [${service.key.name}] is currently [${service.value}]`)
    }
  }
}

