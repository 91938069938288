import {ToolCorrectionProcessState} from '@cstx/volkswagen-mqs-report-generator-service-client';
import {FilterBase} from 'src/app/shared/models/filterBase';
import {Slice} from 'src/app/shared/models/slice';


export class ToolCorrectionFilter extends FilterBase {
  costCenter = '';
  workSequence = '';
  processingCenter = '';
  spindle = '';
  partNumber = '';
  state?: ToolCorrectionProcessState;
  slice: Slice;

  constructor() {
    super();

    this.pagingSize = 50;
    this.totalPages = 1;
    this.currentPageIndex = 0;
    this.sort = ['modifiedAt,desc'];
  }

  public getAllParams() {
    return { ...this.getPageableParams(), ...this.getFilterParams() };
  }
  public getFilterParams() {
    return {
      costCenter: this.costCenter,
      workSequence: this.workSequence,
      processingCenter: this.processingCenter,
      spindle: this.spindle,
      partNumber: this.partNumber,
      state: this.state
    };
  }

  public getPageableParams() {
    return {
      page: this.currentPageIndex,
      size: this.pagingSize,
      sort: this.sort,
    };
  }

  public IsEmpty(): boolean {
    if (this.costCenter !== '') {
      return false;
    }
    if (this.workSequence !== '') {
      return false;
    }
    if (this.processingCenter !== '') {
      return false;
    }
    if (this.spindle !== '') {
      return false;
    }
    if (this.partNumber !== '') {
      return false;
    }
    if (this.state) {
      return false;
    }
    return true;
  }
}
