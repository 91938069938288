import {Component, Input, OnChanges, OnInit} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'op-datetime-show',
  templateUrl: './datetime-show.component.html',
  styleUrls: ['./datetime-show.component.scss']
})
export class DatetimeShowComponent implements OnInit, OnChanges {
  @Input() dateTime: string;
  @Input() noLocalTimeConversion: boolean;
  @Input() customIconClass: string;
  @Input() noPlaceHolder = false;

  dateTimeString = '';


  constructor() { }

  ngOnInit(): void {
    this.dateTimeString = this.checkDateTimeValidity() ? this.dateTime : '';
   }

  ngOnChanges(): void {
      this.dateTimeString = this.checkDateTimeValidity() ? this.dateTime : '';
  }

  private checkDateTimeValidity(): boolean {
    if (this.dateTime === null || this.dateTime === undefined) {
      return false;
    }
    const dateTime = moment(this.dateTime);
    return dateTime.isValid() && dateTime.isAfter(moment('1950-01-01'));
  }

}
