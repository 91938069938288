import {FilterBase} from '../../../shared/models/filterBase';
import {Slice} from '../../../shared/models/slice';
import {TelegramType} from '@cstx/volkswagen-mqs-telegram-receiver-service-client';

export class TelegramFilter extends FilterBase {
  public slice: Slice;

  costCenter?: string | null;
  componentName?: string | null;
  productionNumber?: string | null;
  orderNumber?: string;
  workSequence?: string | null;
  telegramType?: TelegramType | null;
  // startTime?: string | null;
  // stopTime?: string | null;
  constructor() {
    super();

    this.pagingSize = 50;
    this.totalPages = 1;
    this.currentPageIndex = 0;
    this.sort = ['modifiedAt,desc'];
  }

  public IsEmpty(): boolean {
    if (this.costCenter) { return false; }
    if (this.componentName) { return false; }
    if (this.productionNumber) { return false; }
    if (this.orderNumber) { return false; }
    if (this.workSequence) { return false; }
    if (this.telegramType) { return false; }
    // if (this.startTime) { return false; }
    // if (this.stopTime) { return false; }

    return true;
  }

  public getAllParams() {
    return {...this.getPageableParams(), ...this.getFilterParams()};
  }

  public getFilterParams() {
    if (this.IsEmpty()) {
      return {};
    }

    return {
      orderNumber: this.orderNumber,
      productionNumber: this.productionNumber,
      costCenter: this.costCenter,
      componentName: this.componentName,
      workSequence: this.workSequence,
      telegramType: this.telegramType
    };
  }

  public getPageableParams() {
    return {
      page: this.currentPageIndex,
      size: this.pagingSize,
      sort: this.sort,
    };
  }
}
