import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, ContentChildren, Directive, EventEmitter, HostListener, Input, OnInit, Output, QueryList, TemplateRef } from '@angular/core';


@Directive({
  selector: '[opKanbanColumn]'
})
export class KanbanColumnDirective {
  @Input() id: string;
  @Input() title: string;  
  @Input() items?: Array<unknown>;
  @Input() itemTemplate?: TemplateRef<unknown>;
  @Input() width?: string;
  @Input() color?: string;
  @Input() titleStyleClasses?: string;
  @Input() showCounter: boolean = false;
  @Input() count?: number;
  @Input() draggableItems: boolean = true;

  constructor(public templateRef: TemplateRef<unknown>) { }
}


@Component({
  selector: 'op-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.scss']
})
export class KanbanComponent implements OnInit {
  @Input() minWidthForColumns?: string;
  @Input() maxWidthForMobileView?: number;
  @Input() startViewColumnId?: string;
  @Input() translateKeyForMoreElements?: string = "";
  @Input() moreElementsLoadable?: boolean = true;

  @Output() clickedItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() droppedItem: EventEmitter<KanbanDroppedItemEvent> = new EventEmitter<KanbanDroppedItemEvent>;
  @Output() loadMoreElements: EventEmitter<string> = new EventEmitter<string>;

  @ContentChildren(KanbanColumnDirective) columns!: QueryList<KanbanColumnDirective>;

  screenWidth: number = 0;
  screenHeight: number = 0;

  mobileColumnIndex = 0;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  constructor() { }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  ngAfterContentInit() {
    this.mobileColumnIndex = typeof this.startViewColumnId !== "undefined" ? this.columns.toArray().findIndex(x => x.id === this.startViewColumnId) : 0; 
  }

  calcGridTemplateColumns(): string {
    if (typeof this.columns === "undefined") {
      return "";
    }

    let str: string = "2px ";
    let minWidth: string = this.minWidthForColumns ?? "150px";

    this.columns.forEach(item => {
      if (typeof item.width === "undefined") {
        str += "minmax(" + minWidth + ", 1fr) 2px ";
      }
      else {
        str += item.width + " ";
      }
    });

    return str;
  }

  onClickedItem(item: unknown): void {
    this.clickedItem.emit(item);
  }

  drop(event: CdkDragDrop<unknown[]>) {
    this.droppedItem.emit(
      {
        event: event,
        fromColumnId: event.previousContainer.id.slice(7),
        toColumnId: event.container.id.slice(7)
      }
    );
  }

  nextColumnIndex(): void {
    this.mobileColumnIndex += 1;
  }

  previousColumnIndex(): void {
    this.mobileColumnIndex -= 1;
  }

  onClickedLoadMoreElements(columnId: string): void {
    this.loadMoreElements.emit(columnId);
  }
}


export interface KanbanDroppedItemEvent {
  event: CdkDragDrop<unknown[]>;
  fromColumnId: string;
  toColumnId: string;
}