<op-card title="{{ 'stacks.title' | translate }}">
  <op-spinner-small *ngIf="loading"></op-spinner-small>
  <ng-container *ngIf="!loading">
    <table class="table table-borderless table-striped p-0 mb-0">
      <tbody>
      <ng-container *ngIf="stack; else stackEmpty">
        <tr class="d-flex pl-3 pr-3">
          <th class="col-sm-2">      {{ 'stacks.stackIdentifier' | translate }}</th>
          <td class="col-sm-4 text-right"> <a *ngIf="stack.stackIdentifier" [routerLink]="['/tracking/' + 'stacks/' + stack.stackIdentifier]" target="_blank">
            <i class="fas fa-link"></i>
          </a> {{ stack.stackIdentifier }}</td>
          <th class="col-sm-2">      {{ 'stacks.stackIdentifierType' | translate }}</th>
          <td class="col-sm-4 text-right">{{ stackInfoService.getStackIdentifierType(stackIdentifier)}}</td>
        </tr>
        <tr class="d-flex pl-3 pr-3">
          <th class="col-sm-2">{{ 'stacks.stackedAt' | translate }}</th>
          <td class="col-sm-4 text-right">{{ stack.stackedAt | date:'dd.MM.yyyy HH:mm:ss' }}</td>
          <th class="col-sm-2">{{ 'stacks.dispatchedAt' | translate }}</th>
          <td class="col-sm-4 text-right">{{ stack.dispatchedAt ? (stack.dispatchedAt | date:'dd.MM.yyyy HH:mm:ss') : '-' }}</td>
        </tr>
        <tr class="d-flex pl-3 pr-3">
          <th class="col-sm-2">{{ 'stacks.transportUnit' | translate }}</th>
          <td class="col-sm-4 text-right">{{ stack.transportUnit ? stack.transportUnit : '-'  }}</td>
          <th class="col-sm-2">{{ 'stacks.rfid' | translate }}</th>
          <td class="col-sm-4 text-right">{{ stack.rfid ? stack.rfid : '-' }}</td>
        </tr>
        <tr class="d-flex pl-3 pr-3">
          <th class="col-sm-2">{{ 'stacks.transportDestination' | translate }}</th>
          <td class="col-sm-4 text-right">{{ stack.transportDestination ? stack.transportDestination : '-'  }}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </ng-container>
</op-card>

<ng-template #stackEmpty>
  <tr class="d-flex">
    <td class="col-sm text-center">{{ 'global.nothing-to-show' | translate }}</td>
  </tr>
</ng-template>
