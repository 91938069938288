import {Component, Input, isDevMode, OnDestroy, OnInit, ViewChildren} from '@angular/core';
import {LabelingType} from './LabelingType';
import {VirtualEngineLabel} from './VirtualEngineLabel';
import {
  ComponentsService,
  InjectorVendor
} from '@cstx/volkswagen-mqs-quality-management-service-client';
import {Engine, Rework} from '../../../../../modules/engine/models/engine';
import {firstValueFrom, interval, Subject} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {CheckDigitService, CheckDigitType} from '../../../../services/check-digit.service';
import {PdfCreatorService} from '../../../../services/pdf-creation/pdf-creator.service';
import {Canvg} from 'canvg';
import {PdfTemplateType} from '../../../../services/pdf-creation/PdfTemplateType';
import {ToastrService} from 'ngx-toastr';
import {TrackingService} from '../../../../services/tracking.service';
import {BeepService} from '../../../../services/beep.service';
import {EventType} from '@cstx/volkswagen-mqs-tracking-service-client';
import {DomSanitizer} from '@angular/platform-browser';
import {OpsService} from '../../../../../modules/admin/ops/ops.service';
import {OpsFilter} from '../../../../../modules/admin/ops/opsFilter';
import {takeUntil} from 'rxjs/operators';
import {Printer} from './Printer';
import {TranslateService} from '@ngx-translate/core';
import {EngineService} from '../../../../../modules/engine/services/engine.service';
import {AuthService} from '../../../../../core/services/auth.service';
import {PartService} from '../../../../services/backend/part.service';
import {LoggingService} from '../../../../../core/logging/logging.service';
import {LoggingSource} from '../../../../../core/logging/loggingSource';
import {ProcedureState} from '@cstx/volkswagen-mqs-onpremise-procedure-service-client';
import {UserService} from '../../../../../modules/user/user.service';
import {UserConfigurationService} from '../../../../services/backend/user-configuration.service';
import {
  EnterpriseRoleProviderService
} from '../../../../../core/services/enterprise-role-provider.service';
import {
  LabelEncoderControllerV2Service
} from "@cstx/volkswagen-mqs-report-generator-service-client";

@Component({
  selector: 'op-labeling',
  templateUrl: './labeling.component.html',
  styleUrls: ['./labeling.component.scss']
})
export class LabelingComponent implements OnInit, OnDestroy {
  @Input() labelingType: LabelingType;
  @Input() engine: Engine;

  @ViewChildren('cppQrCodeArea') labelViewChild;

  physicalEngineVerificationActive = true;
  partDMCs: string[];
  scannerEngineVerification = false;
  engineVerified = false;
  engineVerificationFailed = false;
  engineVerificationSkipped = false;

  labelingTypes = LabelingType;

  public isLoading = false;
  public engineLabel = new VirtualEngineLabel();
  public printJobSubmitted = false;
  public ProcedureStates = ProcedureState;

  TYPE_APPROVAL_LABEL_COST_CENTERS = ['7137', '7138'];

  printJobRunning = false;

  public reasons = [
    {label: 'Bitte wählen ...', value: undefined},
    {label: 'Keine Nacharbeit', value: 'noRework'},
    {label: 'Label niO von Linie', value: 'lineLabelDamaged'},
    {label: 'Label falsch', value: 'labelFaulty'},
    {label: 'Label fehlt',  value: 'labelMissing'},
    {label: 'Injektortausch', value: 'injectorChanged'},
    {label: 'Zahnriemenschutz defekt', value: 'timingBeltDamaged'},
    {label: 'Zahnriemenschutz falsch', value: 'timingBeltFaulty'},
    {label: 'Motornummer falsch', value: 'engineNumberFaulty'},
    {label: 'Neue Offsetdaten', value: 'newOffsetData'}
  ];
  reason: string;
  reasonInvalid = false;

  keyCodes = [
    {label: 'Bitte wählen ...', value: undefined},
  ];
  keyCode: string;
  keyCodeChangeInProgress = false;
  keyCodeChangeError = false;
  keepHotTests = false
  keepColdTests = false

  public createBlock = false;
  public timingBeltImage: any = '';
  private groupLabelImage: any = '';
  private offSetLabelImage: any = '';
  private injector1LabelImage: any = '';
  private injector2LabelImage: any = '';
  selfLink = '';
  private selfLinkImage: any;
  private delphiOffSetLabelImage: any = '';
  private delphiInjectorLabelImage: any = '';
  calibrationLabelImage: any = '';

  private CALIBRATION_STRING = '1/-+Y';

  public printers = Printer.printers.map(p => p.getSelectionValues());

  private selectedPrinter = '';
  printingProcedureId: string;
  printingProcedureState: string;
  printMonitorAttempts = 0;
  abortPrintMonitoring = new Subject<void>();
  PRINTER_CONFIG_AREA = 'reworkPrinter';

  public withLabelValidation = true;
  public labelValidations = new Array<LabelValidationObject>();
  nextLabelValidation = -1;
  labelValidationSuccessful = false;
  labelValidationAborted = false;
  toggleAutoFocus: any;
  toggleUseCamera: any;
  resetEmitter: any;
  helpMessage: string;
  failedScans = 0;
  showCalibrationImage = false;

  public editLabelMode: boolean;
  private labelIdentifierBackup: string;

  constructor(private qmsComponentService: ComponentsService,
              private engineService: EngineService,
              private partService: PartService,
              private trackingService: TrackingService,
              private opService: OpsService,
              private checkDigitService: CheckDigitService,
              private pdfCreatorService: PdfCreatorService,
              private toastrService: ToastrService,
              private authService: AuthService,
              private userService: UserService,
              private userConfigurationService: UserConfigurationService,
              private translate: TranslateService,
              private labelEncoder: LabelEncoderControllerV2Service,
              private sanitizer: DomSanitizer,
              private beep: BeepService) {
  }

  async ngOnInit(): Promise<void> {
    if (this.labelingType === LabelingType.KEYCODECHANGE) this.reason = 'keycodeChange';
    if (this.engine !== undefined ) {
      if (this.engine.partNumber !== undefined && this.engine.partNumber !== null) {
        this.engineService.getKeyCodesByEngineCode(this.engine.engineCode, this.engine.keyCode).then(response => {
          this.keyCodes = this.keyCodes.concat(response.map(keyCode => ({label: keyCode, value: keyCode})));
          LoggingService.logDebug(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Fetched available keycodes.`)
        })
      }
      if (this.physicalEngineVerificationActive) {
        this.partService.getAssemblyById(this.engine.id).then(assembly => {
          this.partDMCs = assembly.assemblyParts.map(part => part.part.dmc);
        });
      }

      this.userConfigurationService.loadClientConfiguration(this.PRINTER_CONFIG_AREA, this.userService.getUserEmail()).then(response => {

        if (response) {
          const printer = JSON.parse(response.configuration);

          if (this.printers.some(p => p.value === printer)) {
            this.selectedPrinter = printer;
          } else {
            LoggingService.logError(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Could not find printer from configuration: ${printer}`);
          }
        } else {
          LoggingService.logWarning(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] No printer configuration found. Probably never printed before.`);
        }
      });

      this.engineLabel = new VirtualEngineLabel();
      this.engineLabel.keyCode      = this.engine.keyCode;
      this.engineLabel.engineNumber = this.engine.engineNumber;
      this.engineLabel.engineCode   = this.engine.engineCode;

      await this.GetLabelInformation();

      this.selfLink = this.getCurrentAbsoluteSiteUrl();

      firstValueFrom(this.labelEncoder.getEncodedLabelImageV2({
        labelType: 'ENGINE',
        value: this.CALIBRATION_STRING,
        valueContainsCheckDigit: true
      })).then(response => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(response);

          fileReader.onloadend = () => {
            if (typeof fileReader.result === 'string') {
              this.calibrationLabelImage = this.sanitizer.bypassSecurityTrustUrl(fileReader.result);
            }
          }
      });
    }

    if (EnterpriseRoleProviderService.hasRequiredClaimByName('engine-label-keycode-change-and-print')) {
      this.reasons.push(
        {label: 'Umschlüsselung', value: 'keycodeChange'});
    }
  }

  ngOnDestroy() {
    this.abortPrintMonitoring.next();
  }

  getCurrentAbsoluteSiteUrl(): string {
    let url: string;
    if (window
      && 'location' in window
      && 'protocol' in window.location
      && 'pathname' in window.location
      && 'host' in window.location) {
      url = window.location.protocol + '//' + window.location.host + window.location.pathname;
    }

    return encodeURI(url);
  }

  public async submit() {
    if (!this.reason || this.reason.length === 0) {
      this.reasonInvalid = true;
      return;
    }
    if (this.reason === 'keycodeChange') {
      this.keyCodeChangeInProgress = true;
      await this.handleChangeKeyCode();
      this.keyCodeChangeInProgress = false;
    }
    this.reasonInvalid = false;
    this.setUpLabelValidation();

    this.printJobSubmitted = true;
    this.printJobRunning = true;
    this.toastrService.info(this.translate.instant('component-profile.actions.reprint.print-started'));

    this.qmsComponentService.createAndPrintComponentLabel({
      componentId: this.engine.id,
      componentType: 'Engine',
      printer: this.selectedPrinter,
      additionalInfo: this.reason,
      labelIdentifier: this.labelIdentifierBackup ? this.engine.labelIdentifier : null
    }).toPromise()
      .then(r => {
        this.printingProcedureId = undefined;
        this.printingProcedureState = undefined;
        this.printMonitorAttempts = 0;
        interval(2000).pipe(takeUntil(this.abortPrintMonitoring)).subscribe(() => {
          this.monitorPrintJob();
          this.printMonitorAttempts++;
        });
        LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Label print job submitted.`)
        LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] PRINTER: ${this.selectedPrinter}.`)
        LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] REASON: ${this.reason}.`)

        const email = this.userService.getUserEmail();
        const printerConfig = JSON.stringify(this.selectedPrinter);
        this.userConfigurationService.saveClientConfiguration(this.PRINTER_CONFIG_AREA, email, printerConfig);
        // const rework = this.composeRework();
        // this.engineService.createRework(rework, this.engine.id).then().catch(e => {
        //   this.toastrService.error('Fehler beim Erstellen der Nacharbeit.')
        // });
    })
      .catch(e => {
        this.toastrService.error(this.translate.instant('component-profile.actions.reprint.print-failed'));
        LoggingService.logError(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
          `[${this.engine.componentName}] Error on printing label.`, e);
        this.printingProcedureState = ProcedureState.Failed;
        this.printJobRunning = false;
      });

    this.engine.labelIdentifier = this.labelIdentifierBackup;
    this.labelIdentifierBackup = null;
  }

  monitorPrintJob() {
    if (this.printingProcedureId) {
      this.opService.GetProcedure(this.printingProcedureId).then(response => {
        this.printingProcedureState = response.state;
      });
    } else {
      const filter = new OpsFilter();
      filter.destinationSystem = 'LabelPrinting';
      filter.procedureName = this.engine.componentName;
      filter.sort = ['modifiedAt,desc'];
      filter.pagingSize = 1;
      this.opService.GetProcedures(filter, true).then(response => {
        if (response.content && response.numberOfElements > 0) {
          this.printingProcedureId = response.content[0].id;
          this.printingProcedureState = response.content[0].state;
        }
      });
    }
    if (this.printingProcedureState === ProcedureState.Done || this.printingProcedureState === ProcedureState.Failed) {
      this.abortPrintMonitoring.next();
      this.printJobRunning = false;
    }
  }

  composeRework(): Rework {
    const rework = new Rework();
    rework.analysis = 'Label nachgedruckt';
    rework.complaint = 'Label nachgedruckt' + ' - ' + this.reasons.find(rs => rs.value === this.reason).label;
    rework.deliveredAt = (new Date()).toISOString();
    rework.employee = this.authService.currentUser.username;
    rework.rework = 'Duplikat erzeugt';
    rework.reworkState = 'CLOSED';
    rework.reworkType = 'OFFLINE';
    rework.source = 'SZONLINEV2';
    return rework;
  }

  public createBlockChanged() {

  }

  private async GetLabelInformation() {
    this.isLoading = true;

    try {
      const result = await this.qmsComponentService.getComponentLabelInformation({
        componentId: this.engine.id,
        componentType: 'Engine'
      });

      this.engineLabel.costCenter = this.engine.costCenter;
      this.engineLabel.lotSize = this.engine.lotSize?.toString();
      this.engineLabel.lotNumber = this.engine.lotNumber?.toString();
      this.engineLabel.buildDate = new Date(this.engine.buildDate);

      if (this.engine.typeApprovalLabel && this.engine.typeApprovalLabel !== '') {
        const typeApprovalStartsAt = this.engine.typeApprovalLabel.indexOf('02E');
        if (typeApprovalStartsAt !== -1) {
          this.engineLabel.countryQualifier = this.engine.typeApprovalLabel.substring(0, typeApprovalStartsAt);
          this.engineLabel.typeApprovalLabel = '02E ' + this.engine.typeApprovalLabel.substring(typeApprovalStartsAt + 3);
        } else {
          LoggingService.logError(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Type approval label present but malformed: ${this.engine.typeApprovalLabel}.`);
        }
      }
      const awaitedResult = await firstValueFrom(result);
      LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Loaded label values.`)

      const offsetLabel = awaitedResult.label.find(l => l.type === 'OffSet');
      if (offsetLabel) {
        this.engineLabel.offsetLabelValue = offsetLabel.content + offsetLabel.checkDigit;
        LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
          `[${this.engine.componentName}] Offset label value: ${this.engineLabel.offsetLabelValue}`);
      }

      const groupLabel = awaitedResult.label.find(l => l.type === 'Group');
      if (groupLabel) {
        this.engineLabel.groupLabelValue = groupLabel.content + groupLabel.checkDigit;
        LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
          `[${this.engine.componentName}] Group label value: ${this.engineLabel.groupLabelValue}`);
      }

      const timingBeltLabel = awaitedResult.label.find(l => l.type === 'TimingBelt');
      if (timingBeltLabel) {
        this.engineLabel.timingBeltLabelValue = timingBeltLabel.content + timingBeltLabel.checkDigit;
        LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
          `[${this.engine.componentName}] Timing belt label value: ${this.engineLabel.timingBeltLabelValue}`);
      }

      this.engineLabel.plant = awaitedResult.plant;
      if (awaitedResult.offSetValues.length > 0) {
        this.engineLabel.offSetList = awaitedResult.offSetValues;
        LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
          `[${this.engine.componentName}] Offset list values: ${this.engineLabel.offSetList}`);
      }

      if (awaitedResult.injectors.length > 0) {
        this.engineLabel.injectors = awaitedResult.injectors;
        LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
          `[${this.engine.componentName}] Injector label values: ${this.engineLabel.offsetLabelValue}`);
      }

      if (isDevMode()) {
        console.log(new Date().toLocaleString() + 'Done loading labeling information.');
        console.log(this.engineLabel);
      }
      LoggingService.logDebug(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Labels successfully loaded.`)

    } catch (error) {
      if (isDevMode()) {
        if (error instanceof HttpErrorResponse) {
          console.log(new Date().toLocaleString() + 'Http error occurred loading labeling information.');
          console.log(error);
        } else {
          console.log(error);
        }
      }
      LoggingService.logError(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
        `[${this.engine.componentName}] Error while loading labels.`, error)
    }

    this.isLoading = false;
  }

  async makePdf() {
    try {
      this.toastrService.info(this.translate.instant('component-profile.actions.reprint.preview-creating'));

      const timingBeltLabelPNG = await this.convertSVGtoPNG(this.timingBeltImage.toString(), 800, 170);
      const groupLabelPNG = await this.convertSVGtoPNG(this.groupLabelImage.toString(), 1000, 190);

      let offSetLabel: any;

      if (this.engineLabel.offSetLabelIsReady) {
        offSetLabel = await this.convertSVGtoPNG(this.offSetLabelImage.toString(), 500, 500);
      }

      let injector1LabelImage: any;
      let injector2LabelImage: any;
      if (this.injector1LabelImage && this.injector2LabelImage) {
        injector1LabelImage =  await this.convertSVGtoPNG(this.injector1LabelImage.toString(), 1000, 190);
        injector2LabelImage =  await this.convertSVGtoPNG(this.injector2LabelImage.toString(), 1000, 190);
      }

      let delphiInjectorLabelImage: any;
      let delphiOffSetLabelImage: any;
      if (this.engineLabel.hasInjectors && this.engineLabel.injectorManufacturer === InjectorVendor.Delphi) {
        delphiInjectorLabelImage =  await this.convertSVGtoPNG(this.delphiInjectorLabelImage.toString(), 500, 500);
        delphiOffSetLabelImage =  await this.convertSVGtoPNG(this.delphiOffSetLabelImage.toString(), 500, 500);
      }

      let selfLinkImage: any;
      if (this.selfLinkImage) {
        selfLinkImage = await this.convertSVGtoPNG(this.selfLinkImage.toString(), 200, 200);
      }

      const args = [
        this.engineLabel,
        timingBeltLabelPNG,
        groupLabelPNG,
        offSetLabel,
        injector1LabelImage,
        injector2LabelImage,
        selfLinkImage,
        delphiInjectorLabelImage,
        delphiOffSetLabelImage
      ]

      const templateType = this.detectTemplateType();
      await this.pdfCreatorService.createPdf(templateType, args);



    } catch (ex) {
      this.toastrService.error(this.translate.instant('component-profile.actions.reprint.preview-creation-failed'));
      LoggingService.logError(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Error on pdf creation.`, ex)
    }
  }

  base64ToSvgDocument(value: string) {
    let svg = atob(value.substring(value.indexOf('base64,') + 7));
    svg = svg.substring(svg.indexOf('<svg'));
    const parser = new DOMParser();
    return parser.parseFromString(svg, 'image/svg+xml');
  }

  private async convertSVGtoPNG(svg: string, width: number, height: number): Promise<string> {
    let png = '';

    try {
      const svgDoc = this.base64ToSvgDocument(svg);

      const data = (new XMLSerializer()).serializeToString(svgDoc);
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      const result = await Canvg.fromString(ctx, data);

      result.start();
      png = canvas.toDataURL('img/png');
    } catch(exception) {
      if (isDevMode()) {
        console.log(exception);
      }
      LoggingService.logError(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
        `[${this.engine.componentName}] Error in image conversion.`, exception)
    }

    return png;
  }

  onTimingBeltImageLoaded(event: any) {
    this.timingBeltImage = event;
  }

  onGroupLabelImageLoaded(event: any) {
    this.groupLabelImage = event;
  }

  onOffSetLabelImageLoaded(event: any) {
    this.offSetLabelImage = event;
  }

  onInjectorLabelImageLoaded(event: any, position: number) {
    if (position === 1) {
      this.injector1LabelImage = event;
    }

    if (position === 2) {
      this.injector2LabelImage = event;
    }
  }

  onDelphiOffSetLabelImageLoaded(event: any) {
    this.delphiOffSetLabelImage = event;
  }

  onDelphiInjectorLabelImageLoaded(event: any) {
    this.delphiInjectorLabelImage = event;
  }

  onSelfLinkImageLoaded(event: any) {
    this.selfLinkImage = event;
  }

  private detectTemplateType(): PdfTemplateType {
    if (this.engineLabel.hasInjectors && this.engineLabel.hasOffSet && this.engineLabel.injectorManufacturer === InjectorVendor.Bosch) {
      if (this.engineLabel.hasTypeApprovalLabel()) {
        return PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET_VECTO
      } else {
        return PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET;
      }
    } else if (this.engineLabel.hasInjectors && this.engineLabel.injectorManufacturer === InjectorVendor.Bosch){
      if (this.engineLabel.hasTypeApprovalLabel()) {
        return PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET_VECTO;
      } else {
        return PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET;
      }
    } else if (this.engineLabel.hasInjectors && this.engineLabel.injectorManufacturer === InjectorVendor.Delphi){
      if (this.engineLabel.hasTypeApprovalLabel()) {
        return PdfTemplateType.DIESEL_ENGINE_LABEL_DELPHI_VECTO;
      } else {
        return PdfTemplateType.DIESEL_ENGINE_LABEL_DELPHI;
      }
    }
    else if (this.engineLabel.hasOffSet && this.engineLabel.groupLabelIsReady && this.engineLabel.timingBeltLabelIsReady) {
      return PdfTemplateType.PETROL_ENGINE_LABEL;
    }

    return null;
  }

  printerChanged(event: string) {
    this.selectedPrinter = event;
  }

  getSelectedPrinterOption(): {label: string; value: string; } {
    return this.printers.find(p => p.value === this.selectedPrinter);
  }

  reasonChanged(event: string) {
    this.reason = event;
    this.reasonInvalid = !this.reason || this.reason.length === 0;
  }

  keyCodeChanged(event: string) {
    this.keyCode = event;
  }

  async handleChangeKeyCode() {
    this.isLoading = true;
    this.keyCodeChangeError = false;
    await this.engineService.changeKeyCode(this.engine, this.keyCode, this.keepColdTests, this.keepHotTests)
    .catch(e => {
      this.keyCodeChangeError = true;
      LoggingService.logError(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
        `[${this.engine.componentName}] Error on changing key code.`, e)
    });
    if (!this.keyCodeChangeError) {
      LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Changed key code to ${this.keyCode}.`)
      const response = await this.engineService.getEngine(this.engine.id);
      this.engine = new Engine(response);

      this.engineLabel = new VirtualEngineLabel();
      await this.GetLabelInformation();
    }
    this.isLoading = false;
  }

  protected setUpLabelValidation() {
    if (!this.withLabelValidation) {
      this.skipLabelValidation();
      return;
    }
    this.labelValidationSuccessful = false;
    this.labelValidationAborted = false;
    this.helpMessage = '';
    this.failedScans = 0;
    this.showCalibrationImage = false;

    this.labelValidations.push(new LabelValidationObject('timingBeltLabel', LabelValidationState.PENDING));
    this.labelValidations.push(new LabelValidationObject('groupLabel', LabelValidationState.PENDING));

    this.nextLabelValidation = 0;

    if (this.engineLabel.hasOffSet
      && this.engineLabel.injectorManufacturer !== InjectorVendor.Delphi
      && this.engineLabel.offSetLabelIsReady) {
      this.labelValidations.push(new LabelValidationObject('offSetLabel', LabelValidationState.PENDING));
    }

    if (this.engineLabel.hasInjectors && this.engineLabel.injectorManufacturer === InjectorVendor.Delphi) {
      this.labelValidations.push(new LabelValidationObject('delphiOffSetLabel', LabelValidationState.PENDING));
      this.labelValidations.push(new LabelValidationObject('delphiInjectorLabel', LabelValidationState.PENDING));
    }

    if (this.injector1LabelImage && this.injector2LabelImage) {
      this.labelValidations.push(new LabelValidationObject('injector1Label', LabelValidationState.PENDING));
      this.labelValidations.push(new LabelValidationObject('injector2Label', LabelValidationState.PENDING));
    }

    LoggingService.logDebug(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Label validation set up.`)
  }

  checkLabelValidationState(labelName: string, state: string) {
    const element = this.labelValidations.find(e => e.LabelName === labelName);

    if (element) {
      if (element.State.toString() === state) {
        return true;
      }
    }

    return false;
  }

  getCurrentExpectedLabelValue(current: LabelValidationObject): string {
    let expectedValue = '';

    switch (current.LabelName) {
      case 'timingBeltLabel':
        expectedValue = this.engineLabel.timingBeltLabelValue;
        break;
      case 'groupLabel':
        expectedValue =  this.engineLabel.groupLabelValue;
        break;
      case 'offSetLabel':
        expectedValue =  this.engineLabel.offsetLabelValue;
        break;
      case 'injector1Label':
        expectedValue =  this.engineLabel.injectorLabel1
          + this.checkDigitService.GenerateCheckDigit(this.engineLabel.injectorLabel1, CheckDigitType.Modulo43, false);
        break;
      case 'injector2Label':
        expectedValue =  this.engineLabel.injectorLabel2
          + this.checkDigitService.GenerateCheckDigit(this.engineLabel.injectorLabel2, CheckDigitType.Modulo43, false);
        break;
      case 'delphiOffSetLabel':
        expectedValue =  this.engineLabel.offsetLabelValue;
        break;
      case 'delphiInjectorLabel':
        expectedValue =  this.engineLabel.injectorLabel1 + this.engineLabel.injectorLabel2;
        break;
    }

    return expectedValue;
  }

  inputEventReceiver(scannerResult: string) {
    if (this.scannerEngineVerification) {
      if (this.partDMCs.some(dmc => dmc === scannerResult)) {
        this.engineVerified = true;
        this.scannerEngineVerification = false;
        this.engineVerificationFailed = false;
        this.beep.beep();
      } else {
        this.engineVerificationFailed = true;
        this.beep.beep(200, 220);
      }
      return;
    }

    const currentValidation = this.labelValidations[this.nextLabelValidation];
    const processedResult = scannerResult.endsWith('=') ? scannerResult.substring(0, scannerResult.length - 1) : scannerResult;

    const targetResult = this.getCurrentExpectedLabelValue(currentValidation);
    const processedTargetResult = targetResult.endsWith('=') ? targetResult.substring(0, targetResult.length -1) : targetResult;

     if (processedTargetResult === processedResult) {
       currentValidation.State = LabelValidationState.SUCCESS;
       this.failedScans = 0;
       this.helpMessage = '';
       this.showCalibrationImage = false;

       this.beep.beep();
       LoggingService.logDebug(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Validated ${currentValidation.LabelName}.`)
       this.nextLabelValidation++;

       if (this.nextLabelValidation === this.labelValidations.length) {
         this.endLabelValidation(true);
       }
     } else {
       if (this.showCalibrationImage) {
         if (this.CALIBRATION_STRING === processedResult) {
           this.helpMessage = this.translate.instant('component-profile.actions.reprint.scanner-ok');
           this.beep.beep();
         } else {
           this.helpMessage = this.translate.instant('component-profile.actions.reprint.scanner-nok');
           this.beep.beep(200, 220);

           console.log('Label verification failed.');
           console.log('[TARGET] ' + this.CALIBRATION_STRING);
           console.log('[ACTUAL] ' + processedResult);
           LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
             `[${this.engine.componentName}] Label verification failed.`);
           LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
             `[${this.engine.componentName}] TARGET: ${this.CALIBRATION_STRING}`);
           LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
             `[${this.engine.componentName}] ACTUAL: ${processedResult}`);
         }
       } else {
         currentValidation.State = LabelValidationState.FAILED;
         this.failedScans++;
         this.helpMessage = this.getScannerValueResponsiveHelpMessage(processedResult, processedTargetResult);

         this.beep.beep(200, 220);

         console.log('Label verification failed.');
         console.log('[TARGET] ' + targetResult);
         console.log('[ACTUAL] ' + processedResult);
         LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
           `[${this.engine.componentName}] Label verification failed.`);
         LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
           `[${this.engine.componentName}] TARGET: ${targetResult}`);
         LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK,
           `[${this.engine.componentName}] ACTUAL: ${processedResult}`);
       }
     }
  }

  endLabelValidation(successful: boolean) {
    this.nextLabelValidation = -1;
    this.labelValidations = [];
    this.failedScans = 0;
    this.helpMessage = '';
    this.showCalibrationImage = false;
    let eventType: EventType;
    if (successful) {
      this.labelValidationSuccessful = true;
      eventType = EventType.LabelValidated;
      LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Label validated successfully.`)
    } else {
      this.labelValidationAborted = true;
      eventType = EventType.LabelValidationSkipped;
      LoggingService.logWarning(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Label validation skipped.`)
    }

    this.trackingService.createTrackingEvent(
      this.engine.id,
      this.engine.componentName,
      'ENGINE',
      'ENGINE',
      (new Date()).toISOString(),
      eventType,
      'Portal',
      '').then(() => {
        LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Created tracking event.`)
    });
  }

  skipLabelValidation() {
    this.nextLabelValidation = -1;
    this.labelValidations = [];
    this.failedScans = 0;
    this.helpMessage = '';
    this.showCalibrationImage = false;

    this.trackingService.createTrackingEvent(
      this.engine.id,
      this.engine.componentName,
      'ENGINE',
      'ENGINE',
      (new Date()).toISOString(),
      EventType.LabelValidationSkipped,
      'Portal',
      '').then(() => {
      LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Created tracking event: ${EventType.LabelValidationSkipped}.`)
    });
  }

  onAutoFocusChanged($event: boolean) {

  }

  onUseCameraChanged($event: boolean) {

  }

  getScannerValueResponsiveHelpMessage(value: string, targetValue: string): string {
    if (value.replace('y','z').replace('Y','Z') === targetValue
    || value.replace('z','y').replace('Z','Y') === targetValue) {
      this.failedScans = 0;
      return this.translate.instant('component-profile.actions.reprint.scanner-wrong-keyboard-layout');
    }
    if (targetValue.includes(value)) {
      this.failedScans = 0;
      return this.translate.instant('component-profile.actions.reprint.barcode-partially-read');
    }
    if (this.labelValidations.map(lv => this.getCurrentExpectedLabelValue(lv)).some(val => val === value)) {
      this.failedScans = 0;
      return this.translate.instant('component-profile.actions.reprint.different-barcode-read');
    }
    return '';
  }

  mockStartValidation() {
    this.setUpLabelValidation();
  }

  mockValidationSuccess() {
    this.labelValidations[this.nextLabelValidation].State = LabelValidationState.SUCCESS;
    this.beep.beep();
    console.log('validated ' + JSON.stringify(this.labelValidations[this.nextLabelValidation]))
    console.log(this.getCurrentExpectedLabelValue(this.labelValidations[this.nextLabelValidation]))
    this.nextLabelValidation++;
    if (this.nextLabelValidation === this.labelValidations.length) {
      this.endLabelValidation(true);
    }
  }

  mockValidationFailure() {
    this.labelValidations[this.nextLabelValidation].State = LabelValidationState.FAILED;
    this.beep.beep(200, 220);
    console.log(this.getCurrentExpectedLabelValue(this.labelValidations[this.nextLabelValidation]))
  }

  getIsDevMode() {
    return isDevMode();
  }

  skipEngineVerification() {
    this.engineVerificationSkipped = true;
    this.scannerEngineVerification = false;
    this.trackingService.createTrackingEvent(
      this.engine.id,
      this.engine.componentName,
      'ENGINE',
      'ENGINE',
      (new Date()).toISOString(),
      EventType.LabelEngineValidateSkip,
      'Portal',
      '').then(() => {
      LoggingService.logInfo(LoggingSource.COMPONENT_PROFILE_ACTION_REWORK, `[${this.engine.componentName}] Created tracking event: ${EventType.LabelEngineValidateSkip}.`)
    });
  }

  public editLabelIdentifier() {
    this.editLabelMode = true;
    this.labelIdentifierBackup = this.engine.labelIdentifier;
  }

  public setLabelIdentifier() {
    this.editLabelMode = false;
  }

  public resetLabelIdentifier() {
    this.editLabelMode = false;
    this.engine.labelIdentifier = this.labelIdentifierBackup;
    this.labelIdentifierBackup = null;
  }
}

export enum LabelValidationState {
  NONE = 'NONE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}
export class LabelValidationObject {

  constructor(labelName: string, state: LabelValidationState) {
    this.LabelName = labelName;
    this.State = state;
  }


  LabelName: string;
  State: LabelValidationState;
}


