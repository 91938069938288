<div class="container-fluid p-1 " [class.cpp-page-defaults]="!kioskMode">
  <div class="row">
  <div class="col center pl-1 border-light border-bottom"
       [style.width]="getVideoPlayerWidth() + 'px'"
       [style.height]="getVideoPlayerHeight(30)+ 'px'"
       style="background-color: black">

    <ng-container *ngIf="selectedVideo">
      <op-video-player
        [fixedVideo]="selectedVideo"
        [elementId]="'video_selected'"
        [previewMode]="false"
        [autoPlay]="autoPlay"
        [videoHeight]="getVideoPlayerHeight(0, 30)"
        [videoWidth]="getVideoPlayerWidth()"
      >
      </op-video-player>
    </ng-container>

  </div>
</div>
<div class="row">
  <ng-container *ngFor="let tab of tabSetActive; index as i">
      <div class="col-sm border-light d-flex flex-column pl-0 pr-0 justify-content-center align-items-center"
           style="width: 300px; height: 210px; background-color: black;"


           [class.border-left]="i !== 0">

      <ng-container *ngIf="isLoaded(tab); else notLoadedOrEmptyTemplate">
        <op-video-player
          [fixedVideo]="getVideoEntity(tab)"
          [elementId]="'video_' + i"
          [previewMode]="true"
          (onVideoPreviewSelected)="videoSelected($event)"
          [videoHeight]="200"
          [videoWidth]="300"
        >
        </op-video-player>
      </ng-container>

        <div *ngIf="i === 0 && tabSetStartIndex > 0" (click)="tabSetBack()"
             [class.pointer]="tab"
             style="position: absolute; bottom: 110px; left:15px; height: 20px; z-index: 500">

          <i class="fas fa-chevron-left fa-3x">

          </i>
        </div>

        <div *ngIf="i === 3 && moreTabSetsPresent()" (click)="tabSetForward()"
             [class.pointer]="tab"
             style="position: absolute; bottom: 110px; right: 15px; height: 20px; z-index: 500">

          <i class="fas fa-chevron-right fa-3x">

          </i>
        </div>

      <ng-template #notLoadedOrEmptyTemplate>
        <ng-container *ngIf="tab; else emptyTabTemplate">

          <div class="text-overflow-ellipses" style="color:white; z-index: 200;">
            {{ tab }}
          </div>

          <div class="small" style="color: white; z-index: 200;">
            {{ getFormattedFileSize(tab)}}
          </div>

          <div *ngIf="isAlreadyInitiated(tab)"
               class="justify-content-center d-flex position-absolute w-100"
               style="position: absolute; z-index: 100">

              <span class="spinner-border"
                    style="width: 5rem; height: 5rem; color: darkslategray" role="status" aria-hidden="true">
              </span>
          </div>


          <div style="position: absolute; bottom: 0; left:0; right: 0; height: 20px;" (click)="loadFile(tab)" [class.pointer]="tab && !autoLoad">

            <div class="progress position-relative bg-secondary">
              <div class="progress-bar text-center" role="progressbar"
                   [ngStyle]="{'width.%': getProgess(tab), 'background-color':  'bg-primary' }">

                <div class="justify-content-center d-flex position-absolute w-100" [ngStyle]=" { 'color': 'white','font-weight': 'bold' }">
                  <div class="col text-monospace" style="padding-right: 15px">


                    {{  getProgess(tab) ?
                        getProgess(tab) + ' %' :
                    (isAlreadyInitiated(tab) ?  ('video-info.download-initiated' | translate) :
                      ( autoLoad ? ('video-info.auto-load-active' | translate): ('video-info.click-to-load' | translate))) }}


                  </div>
                </div>



              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #emptyTabTemplate>
            <i class="fas fa-2x fa-film" style="color: white; font-weight: bold"></i>
            <div style="color: white;">
              {{ 'video-info.no-video' | translate }}
            </div>
        </ng-template>


      </ng-template>
      </div>
    </ng-container>

</div>
</div>
