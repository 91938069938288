import {EventEmitter, Injectable, isDevMode} from '@angular/core';
import {FileHandlingService} from '../../services/backend/file-handling.service';
import {HttpEventType} from '@angular/common/http';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {UploadConfiguration} from './uploadConfiguration';
import {UploadEntity} from './uploadEntity';

@Injectable({
  providedIn: 'root'
})

export class UploadV2Service {
  public files = new Array<UploadEntity>();
  public config = new UploadConfiguration();

  public onFileUploaded = new EventEmitter<UploadEntity>();
  public onFileUploadFailed = new EventEmitter<UploadEntity>();
  public onFilesUploaded =new EventEmitter<UploadEntity[]>();


  /**
   * This event is fired, whenever a file is remove from the files list.
   * It contains a number representing the count of the files still in the list.
   */
  public onFileRemoved = new EventEmitter<number>();

  constructor(private fileHandlingService: FileHandlingService) {}

  /**
   * Resets the service data back to the initiation state.
   */
  public reset() {
    this.files = new Array<UploadEntity>();
  }

  /**
   * Removes the file on the given index from the list of files.
   * @param index the index of the file in the list to be removed.
   */
  public removeFile(index: number) {
    this.files.splice(index, 1);
    this.onFileRemoved.emit(this.files.length);
  }

  /**
   * This triggers the upload based on the given upload-configuration object.
   * Each file entity has properties and events, to see progress and result of the upload.
   */
  public upload(useCaseS3PrefixOverride?: string) {
    const useCasePrefix = useCaseS3PrefixOverride ? useCaseS3PrefixOverride : this.config.useCaseS3Prefix;

    if (this.files.length <= 0) {

      LoggingService.logWarning(LoggingSource.UPLOAD_V2_SERVICE,
        `Nothing to upload. Add files first.`);

      return;
    }


    this.files.forEach(file => {
      if (!file.uploadFinished) {
        const s3Key  =  (useCasePrefix ? useCasePrefix + '/' : '')  + file.name;

        this.fileHandlingService.uploadUseCaseAsync(this.config.useCaseName, file.file, s3Key)
          .subscribe(httpEvent => {
            if ( httpEvent.type === HttpEventType.UploadProgress ) {

              const intermediate = httpEvent.loaded / httpEvent.total * 100;
              file.uploadProgress = Math.round((intermediate + Number.EPSILON) * 100) / 100

            }

            if (httpEvent.type === HttpEventType.Response) {
              file.uploadFinished = true;

              if (httpEvent.status !== 200) {
                file.uploadFailed = true;

                this.onFileUploadFailed.emit(file);


                LoggingService.logError(LoggingSource.UPLOAD_V2_SERVICE,
                  `Upload failed with status code [${httpEvent.status}] and error [${httpEvent.statusText}]`);
              } else {
                this.onFileUploaded.emit(file);
                console.log('onFileUploadedEvent fired.');

              }

            }
          });
      }
    })

    const timer = setInterval(() => {
      if (this.files.filter(f => !f.uploadFinished).length > 0) {
        console.log('still uploading.');
      } else {
        this.onFilesUploaded.emit(this.files);
        console.log('uploadfinished.');
        clearInterval(timer);
      }
    }, 500);

  }

}




