import {FilterBase} from '../filterBase';
import {Slice} from '../slice';

export class TrackingEventFilter extends FilterBase {
  public origin = '';
  public line = '';
  public eventType?: 'INLINE' | 'STACKING' | 'UNSTACKING' | 'DISPATCH' | 'RETURN' | 'IN_PRODUCTION'
    | 'MANUFACTURED' | 'IMPORTED' | 'INVENTORIED' | 'IN_REVIEW' | 'BLOCKED' | 'RELEASED' | 'DONE'
    | 'PART_INSTALLED' | 'PART_REMOVED' | 'COLD_TEST_FAILED' | 'COLD_TEST_PASSED' | 'HOT_TEST_FAILED'
    | 'HOT_TEST_PASSED' | 'REWORK_OPEN' | 'REWORK_APPROVED' | 'REWORK_CLOSED' | 'READY_TO_SHIP' | 'UNKNOWN';
  public eventTimeTo = '';
  public eventTimeFrom = '';
  public componentId = '';
  public componentName = '';
  public stackIdentifier = '';
  public invalidEntries = false;
  public slice: Slice;

  constructor() {
    super();

    this.pagingSize = 50;
    this.totalPages = 1;
    this.currentPageIndex = 0;
    this.sort = ['eventTime,desc'];
  }

  public getAllParams() {
    return {...this.getPageableParams(), ...this.getFilterParams()};
  }

  public getFilterParams() {
    return {
      origin: this.origin,
      line: this.line,
      eventType: this.eventType,
      eventTimeTo: this.eventTimeTo,
      eventTimeFrom: this.eventTimeFrom,
      componentId: this.componentId,
      componentComponentName: this.componentName,
      stackStackIdentifier: this.stackIdentifier,
      invalidEntries: this.invalidEntries
    };
  }

  public getPageableParams() {
    return {
      page: this.currentPageIndex,
      size: this.pagingSize,
      sort: this.sort,
    };
  }
}
