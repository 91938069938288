import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {EffectiveUserClaim, EnterpriseRoleProviderService} from '../../core/services/enterprise-role-provider.service';

@Directive({
  selector: '[opRequiredClaimByName]'
})
export class RequiredClaimByNameDirective implements OnInit {
  @Input('opRequiredClaimByName') name: string;
  public isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {}

  public ngOnInit() {
    EnterpriseRoleProviderService.onPermissionsLoaded.subscribe(() => {
      this.evaluateClaim();
    })

    this.evaluateClaim();
  }

  private evaluateClaim() {
    if (EnterpriseRoleProviderService.effectiveUserClaims?.length < 1) {
      this.viewContainerRef.clear();
    }

    if (EnterpriseRoleProviderService.hasRequiredClaimByName(this.name)) {
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.isVisible = false;
      this.viewContainerRef.clear();
    }
  }

}
