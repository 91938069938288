import { Injectable } from '@angular/core';
import {StackIdentifierType} from '../../models/tracking/stackIdentifierType';

@Injectable({
  providedIn: 'root'
})
export class StackInfoService {

  constructor() { }

  public getStackIdentifierType(value: string): StackIdentifierType {
    const gtlTemplate: RegExp = /^(?=.{22}$)([(1-6)(J)]{2})([A-Z]{2})([0-9]{9})([0-9]{9})$/;
    const mbkTemplate: RegExp = /^(?=.{13})([1]{1})([0-9]{13})$/;

    if (gtlTemplate.test(value)) {
      return StackIdentifierType.GTL;
    }

    if (mbkTemplate.test(value)) {
      return StackIdentifierType.MBK;
    }

    return StackIdentifierType.Unknown;
  }
}
