import {Injectable} from '@angular/core';
import {LoggingService} from '../../core/logging/logging.service';
import {LoggingSource} from '../../core/logging/loggingSource';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageManagerService {

  constructor() { }

  /**
   * SetItem Wrapper method.
   *
   * The setItem() method of the Storage interface, when passed a key name and value,
   * will add that key to the given Storage object, or update that key's value if it already exists
   *
   * @param keyName A string containing the name of the key you want to set the value of.
   * @param keyValue A string containing the value for the key you want to set.
   * @param noLog Disables all logging. Could be necessary to avoid presenting confidential actions in application logging.
   * @returns True is successfully save, false if not.
   */
  public static setItem(keyName: string, keyValue: string, noLog: boolean = true): boolean {
    try {
      if (!noLog) {
        LoggingService.logDebug(LoggingSource.LOCAL_STORAGE_MANAGER, `Storing local storage content for key: ${keyName}`);
      }

      localStorage.setItem(keyName, keyValue);
      return true;

    } catch (error) {
      if (!noLog) {
        LoggingService.logError(LoggingSource.LOCAL_STORAGE_MANAGER, `An error occurred when trying to save to local storage for key: ${keyName}`, error);
      }

      return false;
    }
  }

  /**
   * GetItem Wrapper method
   *
   * The getItem() method of the Storage interface, when passed a key name,
   * will return that key's value, or null if the key does not exist, in the given Storage object.
   * @param keyName A string containing the name of the key you want to retrieve the value of.
   * @param noLog Disables all logging. Could be necessary to avoid presenting confidential actions in application logging.
   * @returns A string containing the value of the key. If the key does not exist, null is returned.
   */
  public static getItem(keyName: string, noLog: boolean = true): string {
    try {
      if (!noLog) {
        LoggingService.logDebug(LoggingSource.LOCAL_STORAGE_MANAGER, `Loading local storage content for key: ${keyName}`);
      }

      return localStorage.getItem(keyName);

    } catch (error) {
        LoggingService.logError(LoggingSource.LOCAL_STORAGE_MANAGER, `An error occurred when trying to access local storage item with key: ${keyName}`, error);
        return null;
    }
  }

  /**
   * RemoveItem Wrapper method
   *
   * The removeItem() method of the Storage interface, when passed a key name, will remove that key from the given Storage object if it exists. The Storage interface of the Web Storage API  provides access to a particular domain's session or local storage.
   * If there is no item associated with the given key, this method will do nothing.
   *
   * @param keyName A string containing the name of the key you want to remove the value of.
   * @param noLog Disables all logging. Could be necessary to avoid presenting confidential actions in application logging.
   */
  public static removeItem(keyName: string, noLog: boolean = true): void {
    try {
      if (!noLog) {
        LoggingService.logDebug(LoggingSource.LOCAL_STORAGE_MANAGER, `Loading local storage content for key: ${keyName}`);
      }

      localStorage.removeItem(keyName);

    } catch (error) {
      LoggingService.logError(LoggingSource.LOCAL_STORAGE_MANAGER, `An error occurred when trying to remove local storage item with key: ${keyName}`, error);
    }
  }
}
