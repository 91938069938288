import {
  AfterViewInit,
  Component, DoCheck,
  ElementRef,
  Input, KeyValueDiffer, KeyValueDiffers,
  OnInit,
  ViewChild
} from '@angular/core';
import {MonacoEditorService} from './monaco-editor.service';
import {first} from 'rxjs/operators';
import {editor} from 'monaco-editor';
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;

declare var monaco: any;

@Component({
  selector: 'op-monaco-editor',
  templateUrl: './monaco-editor.component.html',
  styleUrls: ['./monaco-editor.component.scss']
})
export class MonacoEditorComponent implements OnInit, DoCheck, AfterViewInit {
  @Input() options: IStandaloneEditorConstructionOptions;

  @ViewChild('editorContainer', { static: true }) editorContainer: ElementRef;

  public editor: IStandaloneCodeEditor;
  private optionsDiffer: KeyValueDiffer<string, any>;
  constructor(private monacoEditorService: MonacoEditorService,
              private keyValueDiffers: KeyValueDiffers) {
    monacoEditorService.load();
  }

  ngOnInit(): void {
  }
  private initMonaco(): void {
    if (!this.monacoEditorService.loaded) {
      this.monacoEditorService.loadingFinished.pipe(first()).subscribe(() => {
        this.initMonaco();
      });
      return;
    }
    this.editor = (monaco as any).editor.create(
      this.editorContainer.nativeElement,
      this.options
    );
  }

  ngDoCheck(): void {
    if (this.optionsDiffer) {
      const optChanges = this.optionsDiffer.diff(this.options);
      if (optChanges && this.editor && this.options) {
        this.editor.updateOptions(this.options);
        this.editor.setValue(this.options.value);
      }
    }
  }

  ngAfterViewInit(): void {
    this.optionsDiffer = this.keyValueDiffers.find(this.options).create<string, any>();
    this.initMonaco();
  }
}
