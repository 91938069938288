<div class="chart-container" style="max-height: 90%;"
     [class.chart-container-collapsed]="useAnimation && graphToggleHidden"
     [class.chart-container-expanded]="!graphToggleHidden && !needsScrollOffset"
     [class.chart-container-expanded-with-scroll-offset]="!graphToggleHidden && needsScrollOffset">
    <canvas baseChart
            [data]="shownData"
            [type]="chartType"
            [options]="options"
            [plugins]="barChartPlugins">
    </canvas>
    <div class="options-toggle"
         [class.options-toggle-collapsed]="useAnimation && graphToggleHidden"
         [class.options-toggle-expanded]="!graphToggleHidden && !needsScrollOffset"
         [class.options-toggle-expanded-with-scroll-offset]="!graphToggleHidden && needsScrollOffset" >
      <i class="fas text-secondary pointer"
         [class.fa-chevron-left]="graphToggleHidden"
         [class.fa-chevron-right]="!graphToggleHidden"
         (click)="toggleCostcenterDisplay()"></i>
    </div>
    <div style="height: 100%"
         [id]="'ccContainer_' + groupID"
         [class.options-container-collapsed]="useAnimation && graphToggleHidden"
         [class.options-container-expanded]="!graphToggleHidden && !needsScrollOffset"
         [class.options-container-expanded-with-scroll-offset]="!graphToggleHidden && needsScrollOffset"
         class="options-container">
        <div style="margin-left: 20px; white-space: nowrap" *ngFor="let cc of displayedGraphs, index as i">
          <span class="badge pointer small text-monospace text-center" style="width: 50px"
                (click)="toggleChart(i)"
                [class.badge-info]="cc.shown"
                [class.badge-warning]="!cc.shown">
            {{ cc.id }}
          </span>

          <a class="pl-1" target="_blank"
             [routerLink]="'/tools/bigscreen/' + cc.id"
             [queryParams]="{ kioskMode: 'true' }" title="Großbildanzeige in neuem Tab öffnen">
            <i class="fas fa-binoculars" style="color: black"></i>
          </a>
          <a class="pl-1" target="_blank" *ngIf="cc.costCenterId"
             [routerLink]="'/metadata//cost-center/' + cc.costCenterId"
             title="Einstellungen öffnen">
            <i class="fas fa-cog" style="color: black"></i>
          </a>

          <i class="fas fa-question-circle pl-1 pointer" [tooltip]="statisticTooltip" placement="bottom"></i>
          <ng-template #statisticTooltip>
            <ng-container *ngIf="cc.statistic; else noStatistics">
              <div class="row small">
                <div class="col-12 font-weight-bold">IST</div>
                <div class="col-12"> {{ cc.statistic.actualValue ?  cc.statistic.actualValue : '-' }} (v1)</div>
                <div class="col-12"> {{ cc.statistic.actualValueV2 ? cc.statistic.actualValueV2 : '-' }} (v2)</div>
              </div>
              <div class="row small">
                <div class="col-12 font-weight-bold">SOLL v1</div>
                <div class="col-12"> {{ cc.statistic.targetValue ? cc.statistic.targetValue : '-'  }}</div>
                <div class="col-12"> ({{ cc.statistic.shiftTargetValue ? cc.statistic.shiftTargetValue : '-' }})</div>
              </div>
              <div class="row small">
                <div class="col-12 font-weight-bold">SOLL v2</div>
                <div class="col-12"> {{ cc.statistic.targetValueV2 ? cc.statistic.targetValueV2 : '-' }}</div>
                <div class="col-12"> ({{ cc.statistic.shiftTargetValueV2 ? cc.statistic.shiftTargetValueV2 : '-' }})</div>
              </div>
            </ng-container>
          </ng-template>
          <ng-template #noStatistics>
            <div class="row small">
              <div class="col-12">
                <i class="far fa-hourglass fa-spin mr-3"></i>  Lädt...
              </div>
            </div>
          </ng-template>
        </div>
    </div>
</div>
