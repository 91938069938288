import {Component, Input, OnInit, Type} from '@angular/core';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {
  ManufacturingProcessControllerV2Service,
  ManufacturingProcessDataSetResponse,
} from '@cstx/volkswagen-mqs-work-sequence-service-client';
import {firstValueFrom, Subject} from 'rxjs';
import {ExcelExportRaw} from '../../excel-export/excelExport';

@Component({
  selector: 'op-manufacturing-process',
  templateUrl: './manufacturing-process.component.html',
  styleUrls: ['./manufacturing-process.component.scss']
})
export class ManufacturingProcessComponent implements OnInit {
  @Input() componentId: string;
  @Input() selfLink: string;
  @Input() contentContainerId: string;

  processSets: Array<ManufacturingProcessDataSetResponse>;

  treeViewItems = new Array<TreeviewItem>();
  treeViewConfig: TreeviewConfig;

  loading = false;

  expanded: boolean;
  viewSizeLG: boolean;
  expandButtonText = 'manufacturing-process.expand';

  public onDataExport =  new Subject<ExcelExportRaw>();


  constructor(private breakpointObserver: BreakpointObserver,
              private manufacturingProcessControllerV2Service: ManufacturingProcessControllerV2Service) { }

  public ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 992px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.viewSizeLG = true;
        } else {
          this.viewSizeLG = false;
        }
      });

    this.treeViewConfig = this.createTreeViewConfig();
    this.loading = true;
    firstValueFrom(this.manufacturingProcessControllerV2Service.getAllManufacturingProcessDataSetByComponentId({id: this.componentId}))
      .then(response => {
        this.processSets = response;

        this.processSets.forEach(set => {
          set.workSequences.sort((a, b) => {
            const aSplit = a.name.split(' ');
            const bSplit = b.name.split(' ');
            if (aSplit.length >=2
              && !isNaN(parseInt(aSplit[1], 10))
              && bSplit.length >=2
              && !isNaN(parseInt(bSplit[1], 10))) {
              return parseInt(aSplit[1], 10) - parseInt(bSplit[1], 10)
            }
            return a.name.localeCompare(b.name);
          });

          const item = new TreeviewItem({ value: set, text: set.origin });

          if (set.workSequences && set.workSequences.length > 0) {
            set.workSequences.forEach(ws => {

              const worksequence = new TreeviewItem({
                value: ws,
                text: ws.name
              });

              worksequence.children = new Array<TreeviewItem>(new TreeviewItem(
                {
                  value: ws,
                  text: 'workSequenceDetails'
                }
              ));

              worksequence.collapsed = true;

              if (ws.partialWorkSequences && ws.partialWorkSequences.length > 0) {
                ws.partialWorkSequences
                  .sort((a,b) =>
                    (!isNaN(parseInt(a.name, 10)) && !isNaN(parseInt(a.name, 10))) ?
                      parseInt(a.name, 10) - parseInt(b.name, 10) : a.name.localeCompare(b.name))
                  .forEach(pws => {
                    if (pws.name === '0') {
                      return;
                    }

                    const partialWorksequence = new TreeviewItem({
                      value: pws,
                      text: 'partialWorkSequence',

                    });

                    partialWorksequence.children = new Array<TreeviewItem>(new TreeviewItem(
                      {
                        value: pws,
                        text: 'partialWorkSequenceDetails'
                      }
                    ));

                    if (worksequence.children) {
                      worksequence.children.push(partialWorksequence);
                    } else {
                      worksequence.children = new Array<TreeviewItem>(partialWorksequence);
                    }
                  });
              }


              if (item.children) {
                item.children.push(worksequence);
              } else {
                item.children = new Array<TreeviewItem>(worksequence);
              }
            });
          }
          this.treeViewItems.push(item);
        });
      }).finally(() => {
        this.loading = false;
    });
  }

  private createTreeViewConfig() {
    return    TreeviewConfig.create({
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
    });
  }

  isWorkSequence(value) {
    if ('workSequences' in value) { return false; }
    if ('workProgramNumber' in value) { return false; }
    return true;
  }

  public exportRequestRaised() {
    const rawData = new ExcelExportRaw();
    rawData.title = 'Process data';

    const combined = new Array<ProcessWithComponentName>();
    this.processSets.forEach(p => {
      p.workSequences.forEach(wS => {
          wS.partialWorkSequences.forEach(pwS => {

            const processWithComponentName = new ProcessWithComponentName(p.componentName);
            processWithComponentName.workSequence = wS.name;
            processWithComponentName.partialWorkSequence = pwS.name;
            processWithComponentName.tool = pwS.toolNumber;
            processWithComponentName.workProgramNumber = pwS.workProgramNumber;
            processWithComponentName.status = pwS.statusValue;
            processWithComponentName.origin = p.origin

            combined.push(processWithComponentName);
          });
      });
    });

    rawData.data = combined;
    this.onDataExport.next(rawData);
  }

  expand() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.expandButtonText = 'manufacturing-process.collapse';
    } else {
      this.expandButtonText = 'manufacturing-process.expand';
    }
  }
}

export class ProcessWithComponentName {
  componentName: string;
  workSequence: string;
  partialWorkSequence: string;
  tool: string;
  status: string;
  workProgramNumber: string;
  origin: string;

  constructor(componentName: string) {
    this.componentName = componentName;
  }
}
