import {CppItemBase} from '../../../models/cppItemBase';
import {
  EngineBlockedState,
  EngineResponse,
  EngineReworkState,
  EngineState,
  EngineTestState,
  Source
} from '@cstx/volkswagen-mqs-engine-service-client';
import {LogisticalState} from '../../../../modules/engine/list/list.component';
import {LoggingService} from '../../../../core/logging/logging.service';
import {LoggingSource} from '../../../../core/logging/loggingSource';
import {StackResponse} from '@cstx/volkswagen-mqs-tracking-service-client';

export class Engine extends CppItemBase implements EngineResponse {
    buildDate: string;
    componentName?: string;
    costCenter: string;
    createdAt?: string;
    createdBy?: string;
    developmentNumber?: string;
    engineBlockedState: EngineBlockedState;
    engineCode: string;
    engineNumber: string;
    engineReworkState: EngineReworkState;
    engineState: EngineState;
    engineTestState: EngineTestState;
    hasBlockingActions: boolean;
    hasColdTests: boolean;
    hasHotTests: boolean;
    hasKeyCodeHistory: boolean;
    hasReworks: boolean;
    id: string;
    keyCode?: string;
    labelIdentifier?: string;
    lotNumber?: string;
    lotSize?: number;
    modifiedAt?: string;
    modifiedBy?: string;
    orderNumber?: string;
    partNumber?: string;
    productionNumber: string;
    productionStartDate: string;
    source: Source;

    private _isDirectRunner: boolean;
    private _logisticalState: LogisticalState = LogisticalState.UNDETERMINED;

    public logisticalQuickInfo: LogisticalQuickInfo;

    constructor(engineResponse?: EngineResponse) {
      super();

      if (engineResponse) {
        this.buildDate = engineResponse.buildDate;
        this.componentName = engineResponse.componentName;
        this.costCenter = engineResponse.costCenter;
        this.createdAt = engineResponse.createdAt;
        this.createdBy = engineResponse.createdBy;
        this.developmentNumber = engineResponse.developmentNumber;
        this.engineBlockedState = engineResponse.engineBlockedState;
        this.engineCode = engineResponse.engineCode;
        this.engineNumber = engineResponse.engineNumber;
        this.engineReworkState = engineResponse.engineReworkState;
        this.engineState = engineResponse.engineState;
        this.engineTestState = engineResponse.engineTestState;
        this.hasBlockingActions = engineResponse.hasBlockingActions;
        this.hasColdTests = engineResponse.hasColdTests;
        this.hasHotTests = engineResponse.hasHotTests;
        this.hasKeyCodeHistory = engineResponse.hasKeyCodeHistory;
        this.hasReworks = engineResponse.hasReworks;
        this.id = engineResponse.id;
        this.keyCode = engineResponse.keyCode;
        this.labelIdentifier = engineResponse.labelIdentifier;
        this.lotNumber = engineResponse.lotNumber;
        this.lotSize = engineResponse.lotSize;
        this.modifiedAt = engineResponse.modifiedAt;
        this.modifiedBy = engineResponse.modifiedBy;
        this.orderNumber = engineResponse.orderNumber;
        this.partNumber = engineResponse.partNumber;
        this.productionNumber = engineResponse.productionNumber;
        this.productionStartDate = engineResponse.productionStartDate;
        this.source = engineResponse.source;

        this._logisticalState = this.getLogisticalState();
        this._isDirectRunner = this.isDirectRunner(true);
      }
    }

    public getLogisticalState(recheck: boolean = true): LogisticalState {
      if (recheck === false) {
        return this._logisticalState;
      }


    if (this.engineState === EngineState.InProduction || this.engineState === EngineState.Disassembled) {
      return LogisticalState.UNDETERMINED;
    }


    if (this.engineBlockedState !== 'NONE') {
      return LogisticalState.UNFREE;
    }

    if (this.engineReworkState === 'PENDING') {
      return LogisticalState.UNFREE;
    }

    if (this.engineTestState !== 'OK') {
      return LogisticalState.UNFREE;
    }

    if (this.engineState === EngineState.Unknown) {
      return LogisticalState.UNFREE;
    }

    return LogisticalState.FREE;
  }

  public isDirectRunner(recheck?: boolean): boolean {
      if (!recheck) {
        return this._isDirectRunner;
      }
    /**
     * TODO: Hier muss die Anzahl der Kalttests, bzw. ob fehlgeschlagene Kalttests vorlagen noch berücksichtigt werden.
     */

    if (this.getLogisticalState() === LogisticalState.FREE
        && !this.hasHotTests
        && !this.hasReworks
        && !this.hasKeyCodeHistory
        && !this.hasBlockingActions) {

        LoggingService.logDebug(LoggingSource.ENGINE, `Engine [${this.componentName}] is a direct runner.)`);
        return true;
      }

    LoggingService.logDebug(LoggingSource.ENGINE, `Engine [${this.componentName}] is not a direct runner.)`);
    return false;
  }

  public get selfLink(): string {
    return '/engine/' + this.id;
  }

  public set selfLink(value: string) {
    super.selfLink = value;
  }


}

export class LogisticalQuickInfo {
  public stackIdentifier: string;
  public customer: string;
  public deliveryNote: string;
  public location: string;

  constructor(stackResponse: StackResponse) {
    this.stackIdentifier = stackResponse.stackIdentifier;

    if (stackResponse.customer) {
      this.customer = stackResponse.customer;
      this.deliveryNote = stackResponse.deliveryNote;
    }

    if (stackResponse.transportDestination && !stackResponse.customer) {
        this.customer = stackResponse.transportDestination;
    }

    if (!stackResponse.transportDestination &&
        !stackResponse.customer &&
         stackResponse.storagePlace) {

      this.location = stackResponse.storagePlace;
    }
  }
}
