import {FileTypeEnum} from '@cstx/volkswagen-mqs-file-handling-service-client';

export class PreviewRequest {
  public componentId: string;
  public componentType: string;
  public componentCostCenter: string;
  public fhsFileType: FileTypeEnum;
  public fileName: string;
  public fileSize: string;
  public parserType: string;
}
