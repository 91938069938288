import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ComponentFile} from '../componentFile';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {FileExplorerService} from '../file-explorer.service';
import {TextTemplate} from '@cstx/volkswagen-mqs-parsing-service-client';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import fileSize from 'filesize';
import {ErrorHandler} from '../../../services/error-handler/error-handler';

@Component({
  selector: 'op-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.scss']
})
export class TreeviewComponent implements OnInit {
  @Input() componentId: string;
  @Input() componentType: string;
  @Input() componentCostCenter: string;
  @Input() updateTreeviewRequestEvent = new Subject<any>();

  @Output() fileSelectedEvent = new EventEmitter<ComponentFile>();
  @Output() showUploadEvent = new EventEmitter<boolean>();

  @Input() componentName: string = undefined;
  @Input() filePreSelectionFilterValue: string = undefined;

  public overAllFileSize = 0;
  public overAllFileSizeText: string;
  public list = new Array<ComponentFile>();
  public count = 0;
  public countUpdating;
  public loading: boolean;
  public selectedItemName: string;
  public treeViewItems = new Array<TreeviewItem>();

  public treeViewConfig: TreeviewConfig = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    // maxHeight: 800 // TODO: check if we can do this dynamically based on surrounding element
  });

  private filePreSelectedGroupIndex = -1;
  private filePreSelectionSucceed: boolean;
  private txtParserValidExtensions = [ 'bin', 'rmb', 'dat', 'xml' ];
  private textTemplates = new Array<TextTemplate>();
  private static getFileExtension(fileName: string): string {
    const fileExtension =  fileName.split('.').pop();
    return fileExtension.toLowerCase();
  }


  constructor(private fileExplorerService: FileExplorerService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.initTextTemplates();

    this.updateTreeviewRequestEvent.subscribe(_ => {
       this.update();
    });
  }

  /**
   *
   * @param file The file selected be clicking the item in tree.
   *
   * This function sets the selected file and then emits an event for the parent
   * component containing the new file selected.
   */
  public onSelectItemClicked(file: ComponentFile) {
    this.selectedItemName = file.Name;
    this.fileSelectedEvent.emit(file);
  }

  public itemIsSelected(file: ComponentFile): boolean {
    return this.selectedItemName === file.Name;
  }

  public itemIsFolder(item: TreeviewItem): boolean {
    if (item.text === this.translate.instant('files.tree.binaries')) {
      return true;
    }

    if (item.text === this.translate.instant('files.tree.images')) {
      return true;
    }

    if (item.text === this.translate.instant('files.tree.text')) {
      return true;
    }

    if (item.text === this.translate.instant('files.tree.other')) {
      return true;
    }

    return !!item.children; // return true if item has children and false otherwise
  }

  public itemIsCollapsed(item: TreeviewItem): boolean {
    return item.collapsed;
  }

  public onShowUploadClicked() {
    this.showUploadEvent.emit(true);
  }

  private initTextTemplates() {
   this.fileExplorerService.GetTextTemplates()
      .then(templates => {
        this.textTemplates = templates;
      })
     .finally(() => {
        this.update();
   });
  }

  private update() {
    this.countUpdating = true;
    this.loading = true;

    this.fileExplorerService.GetFiles(this.componentId, this.componentType)
      .then(result => {
        this.list = result;
        this.buildTreeView();
      })
      .finally(() => {
        this.count = this.list.length;
        this.countUpdating = false;
        this.loading = false;
      });
  }

  private getTreeViewGroups() {
    return {
      bin:   this.translate.instant('files.tree.binaries'),
      image: this.translate.instant('files.tree.images'),
      txt:   this.translate.instant('files.tree.text'),
      other: this.translate.instant('files.tree.other'),
      protocols: this.translate.instant('files.tree.protocols')
    };
  }

  private buildTreeView() {
    try {
      const groups = this.getTreeViewGroups();

      this.list.forEach(file => {
        // tslint:disable-next-line:radix
        this.overAllFileSize = parseInt(file.Size) + this.overAllFileSize;

        const groupName = groups[file.FileType] || groups.other;
        let groupIndex = this.treeViewItems.findIndex(_ => _.text === groupName);

        groupIndex = this.createGroupIfNotExists(groupIndex, groupName);
        this.addChildrenToGroup(groupIndex, file);

        if (file.FileType === 'bin') {
          file.ParserType = 'kaitai';
        }

        if (this.txtParserValidExtensions.includes(TreeviewComponent.getFileExtension(file.Name))) {
          let txtGroupIndex = this.treeViewItems.findIndex(_ => _.text === groups.txt);
          txtGroupIndex = this.createGroupIfNotExists(txtGroupIndex, groups.txt);

          const fakeFile = this.createFakeFileObject(file);
          if ( fakeFile !== undefined) {
            this.addChildrenToGroup(txtGroupIndex, fakeFile);
          }
        }

        groupIndex = this.createGroupIfNotExists(groupIndex, groupName);
        this.addChildrenToGroup(groupIndex, file);
      });

      this.overAllFileSizeText = fileSize(this.overAllFileSize);

      // If preselection filter value is set and a match was detected, we are expanding the parent group of the
      // selected item here, because it does not work in place when selecting the matched item.
      if (this.filePreSelectionSucceed && this.filePreSelectedGroupIndex !== -1) {
        this.treeViewItems[this.filePreSelectedGroupIndex].collapsed = false;
      }
    }
    catch (error) {
      ErrorHandler.printError(error);
    }
  }

  private createFakeFileObject(sourceFile: ComponentFile): ComponentFile {
    const template = this.getTemplatePath(sourceFile);
    if (template) {
      const fakeFile = new ComponentFile();
      fakeFile.FileType       = sourceFile.FileType;
      fakeFile.ComponentType  = sourceFile.ComponentType;
      fakeFile.Name           = 'Steckbrief_' + sourceFile.Name + '.txt';
      fakeFile.OriginalName   = 'Steckbrief.txt';
      fakeFile.ParserType     = 'text';
      fakeFile.CreatedAt      = sourceFile.CreatedAt;

      fakeFile.Source = template;
      return fakeFile;

    } else {
      return undefined;
    }
  }

  private createGroupIfNotExists(index: number, name: string ): number {
    if (index === -1) {
      this.treeViewItems.push(new TreeviewItem({text: name, value: name}));
      index = this.treeViewItems.length - 1;
    }

    // If we have a filePreSelectionFilterValue set, the user is expecting a specific kind of file in a specific place.
    // Therefore, we are collapsing all groups and in case of a filter value match, we expand the parent group of the
    // matched item.
    if (this.filePreSelectionFilterValue !== undefined) {
      this.treeViewItems[index].setCollapsedRecursive(false);
      this.treeViewItems[index].collapsed = true;
    }

    return index;
  }

  private addChildrenToGroup(index: number, file: ComponentFile) {
    if (this.treeViewItems[index].children === undefined) {
      this.treeViewItems[index].children = Array<TreeviewItem>(new TreeviewItem({
        text: file.Name,
        value: file
      }));
    } else {
      // Add item if not already in tree (will only work as long we do not have nested groups)
      const itemIndex = this.treeViewItems[index].children.findIndex(_ => _.text === file.Name);
      if (itemIndex === -1) {
        this.treeViewItems[index].children.push(new TreeviewItem({text: file.Name, value: file}));
      }
    }

    if (this.filePreSelectionFilterValue && !this.filePreSelectionSucceed) {
      const expr = new RegExp(this.filePreSelectionFilterValue);
      if (expr.test(file.OriginalName)) {
        this.onSelectItemClicked(file);
        this.filePreSelectionSucceed = true;

        // We store the index of the parent group for set collapsed false on the end of the treeView building.
        // Set the parent group collapsed false in place does not work.
        this.filePreSelectedGroupIndex = index;
      }
    }
  }

  private getTemplatePath(file: ComponentFile): string {
    let textTemplate: TextTemplate;

    this.textTemplates.forEach(template => {
      if (template.costCenter === this.componentCostCenter) {
        if (template.fileExtensions.includes(TreeviewComponent.getFileExtension(file.Name))) {
          if (template.fileNameFilters !== null) {
            template.fileNameFilters.forEach(filter => {
              if (file.OriginalName.includes(filter)) {
                textTemplate = template;
              }
            });
          } else {
            textTemplate = template;
          }
        }
      }
    });

    if (textTemplate) {
      return textTemplate.templatePath;
    }

    return undefined;
  }

  /**
   *
   * @param name Takes the given name value of the treeView Item (fileName)
   *
   * @return string Returns a string representing an optimized, more human-readable filename if possible.
   * In case no optimization was possible it returns the value of the input param.
   *
   */
  public getFancyName(name: string): string {
    // Remove the component name if given from the filename. As the file is visually related to the component,
    // it is obvious to which component this item belongs.
    const originalFileName = name;
    const componentNameRegEx =  /\*/g;
    let fixedComponentName: string;
    fixedComponentName = this.componentName?.replace(componentNameRegEx, '~');
    name = name.replace(fixedComponentName, '');

    // Remove a trailing underscore, f.e. caused by other optimizations above
    if (name[0] === '_') {
      name = name.substring(1);
    }

    const fileNameWithoutExtension = name.split('.')[0];
    if (fileNameWithoutExtension.length === 0) {
      name = originalFileName;
    }

    return name;
  }
}
