<div class="text-center p-1" >
    <ng-template #performanceTooltip>
        <div class="row" *ngIf="sfm.Statistics" style="min-width: 220px;">
            <div class="col-12">{{ sfm.Statistics?.workShift | translate }}</div>
            <div class="col-8 text-overflow-ellipses pre-white">{{ 'page.sfm.actualValue' | translate }}</div>
            <div class="col-4">{{ sfm.Statistics?.actualValue }}</div>
            <div class="col-8 text-overflow-ellipses pre-white">{{ 'page.sfm.targetValue' | translate }}</div>
            <div class="col-4">{{ sfm.Statistics?.targetValue }}</div>
            <div class="col-8 text-overflow-ellipses pre-white">{{ 'page.sfm.shiftTarget' | translate }}</div>
          <div class="col-4">{{ sfm.Statistics?.shiftTargetValue }}</div>
          <ng-container *ngIf="sfm.PerformanceState !== PerformanceState.UNKNOWN; else productionWithoutTargetTemplate">
            <div class="col-8 text-overflow-ellipses pre-white">{{ 'page.sfm.difference' | translate }}</div>
            <div class="col-4">{{ sfm.differenceBetweenActualAndTargetInPercent() }} %</div>
          </ng-container>

          <ng-template #productionWithoutTargetTemplate>
            <div class="col-12 pt-3">{{ 'page.sfm.productionWithoutTarget' | translate }}</div>
          </ng-template>
        </div>
    </ng-template>

    <div [class.float-left]="!treeMode">
        <div class="black-bordered rounded text-center mb-1 pb-3 cpp-sfm-board-cost-center-card pt-1 m-auto" [class.cpp-sfm-board-treeview-card]="treeMode"   style="background-color: #fafafa" >
            <div class="row pl-3 pr-3" *ngIf="sfm.SfmStatusType === ShopfloorManagementBoardStatusType.CostCenter">
                <div class="col-4">
                    <ng-container *ngIf="sfm.CostCenter.ProductCenter?.Description">
                        <op-component-state-badge [name]="sfm.CostCenter.ProductCenter?.Description "></op-component-state-badge>
                    </ng-container>
                </div>
                <div class="col-4">

                </div>
                <div class="col-4">
                    <ng-container *ngIf="sfm.CostCenter.ProductUnit">
                        <op-component-state-badge [name]="sfm.CostCenter.ProductUnit?.Name"></op-component-state-badge>
                    </ng-container>
                </div>
            </div>


            <a *ngIf="sfm.SfmStatusType === ShopfloorManagementBoardStatusType.ProductCenter" [routerLink]="['./']" [queryParams]="{ activeTab: 'costCenterView', pcActive: sfm.RefId, showInactiveShifts: showInactive ? 'true' : 'false' }">
            <h4 class="font-weight-bold d-inline" style="color: #525252; z-index: 200">{{ sfm.Description }}
            </h4>
            </a>

                    <h4 *ngIf="sfm.SfmStatusType !== ShopfloorManagementBoardStatusType.ProductCenter" class="font-weight-bold d-inline" style="color: #525252; z-index: 200">{{ sfm.SfmStatusType !== ShopfloorManagementBoardStatusType.ProductCenter ? sfm.CommonName : sfm.Description }}
                    </h4>


            <div class="small text-overflow-ellipses pre-white pl-1 pr-1"  style="height: 25px; color: #525252">{{ sfm.SfmStatusType !== ShopfloorManagementBoardStatusType.ProductCenter ? sfm.Description : sfm.CommonName }}</div>
            <div class="row pl-3 pr-3 pt-3" *ngIf="!treeMode">
                <div class="col text-center">
               <span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
                     [class.cpp-override-pointer-cursor]="!EnterpriseRoleProviderService.hasRequiredClaimByName('sfm-board-set-status')"

                     (click)="setState(sfm, 'technical')"


                     [class.badge-danger]="sfm.TechnicalState === SfmState.BAD"
                     [class.badge-secondary]="sfm.TechnicalState === SfmState.UNKNOWN"
                     [class.badge-success]="sfm.TechnicalState === SfmState.GOOD">Techn. Störung</span>

                </div>
            </div>

            <div class="row pl-3 pr-3 pt-3" *ngIf="!treeMode">
                <div class="col text-center">
               <span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
                     [class.cpp-override-pointer-cursor]="!EnterpriseRoleProviderService.hasRequiredClaimByName('sfm-board-set-status')"

                     (click)="setState(sfm, 'humanResources')"
                     [class.badge-danger]="sfm.HumanResourcesState === SfmState.BAD"
                     [class.badge-secondary]="sfm.HumanResourcesState === SfmState.UNKNOWN"
                     [class.badge-success]="sfm.HumanResourcesState === SfmState.GOOD">Personal</span>

                </div>
            </div>

            <div class="row pl-3 pr-3 pt-3" *ngIf="!treeMode">
                <div class="col text-center">
               <span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
                     [class.cpp-override-pointer-cursor]="!EnterpriseRoleProviderService.hasRequiredClaimByName('sfm-board-set-status')"

                     (click)="setState(sfm, 'logistics')"
                     [class.badge-danger]="sfm.LogisticState === SfmState.BAD"
                     [class.badge-secondary]="sfm.LogisticState === SfmState.UNKNOWN"
                     [class.badge-success]="sfm.LogisticState === SfmState.GOOD">Logistik</span>

                </div>
            </div>

            <div class="row pl-3 pr-3 pt-3" *ngIf="!treeMode">
                <div class="col text-center">
               <span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
                     [class.cpp-override-pointer-cursor]="!EnterpriseRoleProviderService.hasRequiredClaimByName('sfm-board-set-status')"

                     (click)="setState(sfm, 'material')"
                     [class.badge-danger]="sfm.MaterialState === SfmState.BAD"
                     [class.badge-secondary]="sfm.MaterialState === SfmState.UNKNOWN"
                     [class.badge-success]="sfm.MaterialState === SfmState.GOOD">Versorgungssicherheit</span>

                </div>
            </div>

            <div class="row pl-3 pr-3 pt-3" *ngIf="!treeMode">
                <div class="col text-center">
               <span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
                     [class.cpp-override-pointer-cursor]="!EnterpriseRoleProviderService.hasRequiredClaimByName('sfm-board-set-status')"

                     (click)="setState(sfm, 'quality')"
                     [class.badge-danger]="sfm.QualityState === SfmState.BAD"
                     [class.badge-secondary]="sfm.QualityState === SfmState.UNKNOWN"
                     [class.badge-success]="sfm.QualityState === SfmState.GOOD">Qualität</span>

                </div>
            </div>

            <div class="row pl-3 pr-3 pt-3" *ngIf="!treeMode">
                <div class="col text-center">
               <span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
                     [class.cpp-override-pointer-cursor]="!EnterpriseRoleProviderService.hasRequiredClaimByName('sfm-board-set-status')"

                     (click)="setState(sfm, 'workSecurity')"
                     [class.badge-danger]="sfm.WorkSecurityState === SfmState.BAD"
                     [class.badge-secondary]="sfm.WorkSecurityState === SfmState.UNKNOWN"
                     [class.badge-success]="sfm.WorkSecurityState === SfmState.GOOD">Arbeitssicherheit</span>

                </div>
            </div>

            <div class="row pl-3 pr-3 pt-3"  *ngIf="!treeMode">
                <div class="col text-center" [tooltip]="sfm.SfmStatusType === ShopfloorManagementBoardStatusType.CostCenter ? performanceTooltip : ''">
               <span class="badge badge-pill border w-100 cpp-sfm-board-cost-center-badge"
                     [class.badge-warning]="sfm.PerformanceState === PerformanceState.POOR"
                     [class.badge-danger]="sfm.PerformanceState === PerformanceState.BAD"
                     [class.badge-secondary]="sfm.PerformanceState === PerformanceState.UNKNOWN"
                     [class.badge-success]="sfm.PerformanceState === PerformanceState.GOOD">Performance

               <ng-container *ngIf="sfm.PerformanceState === PerformanceState.UNKNOWN && sfm.IsActive">
                      <i class="fas fa-exclamation-triangle" style="color: yellow"></i>
               </ng-container>

               </span>

                </div>
            </div>

        </div>
    </div>
    <div id="cardLink"  class="float-left" *ngIf="!treeMode
          && sfm.SfmStatusType === ShopfloorManagementBoardStatusType.CostCenter
          && sfm.CommonName
          && EnterpriseRoleProviderService.hasRequiredClaimByName('sfm-board-show-links')">

        <div class="text-center border"  style="height: 30px; width: 30px;">
            <a [routerLink]="['/metadata/cost-center/', sfm.RefId]"
               [queryParams]="{ kioskMode: kioskMode }" target="_blank">
                <i class="fas fa-cog"></i>
            </a>
        </div>
        <div  class="text-center border pointer" style="height: 30px; width: 30px;">
            <a [routerLink]="['/tools/bigscreen/', sfm.CommonName]"
               [queryParams]="{ kioskMode: 'true' }" target="_blank">
                <i class="fas fa-binoculars"></i>
            </a>
        </div>
        <div  class="text-center border" style="height: 30px; width: 30px;">
            <a [routerLink]="['/statistics/output/']"
               [queryParams]="{ chartConfig: sfm.OutputChartConfig, collapsed: true, kioskMode: true }" target="_blank">
                <i class="fas fa-chart-line"></i>
            </a>
        </div>

        <div  class="text-center border" style="height: 30px; width: 30px;">
            <a [routerLink]="['/statistics/oee/']"
               [queryParams]="{ chartConfig: sfm.OeeChartConfig, collapsed: true, kioskMode: true }" target="_blank">
                <i class="fas fa-percent"></i>
            </a>
        </div>
    </div>
</div>
