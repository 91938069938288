import fileSize from 'filesize';

export class UploadEntity {
  public name: string;
  public file: File;
  public size: string;
  public uploadProgress = 0;
  public uploadFinished: boolean;
  public uploadFailed: boolean;

  constructor(file: File) {
    this.name = file.name;
    this.file = file;

    this.size = fileSize(file.size);
  }
}
