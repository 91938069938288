<ng-container>
  <tr class="d-flex" >
    <td class="col-4 d-inline">
      {{ 'component-profile.actions.reprint.title' | translate }}
      <ng-container *ngIf="engineLabel.timingBeltLabelIsReady">
        <i class="pl-2 far fa-file-pdf" style="cursor: pointer" (click)="makePdf()"></i>
      </ng-container>
      <div *ngIf="nextLabelValidation > -1 || (scannerEngineVerification && !engineVerified)" class="input-container-hidden">
        <app-scanner2 class="input-receiver-hidden" #scannerComponent (scannerResultEvent)="inputEventReceiver($event)"
                      [resetEventReceiver]="resetEmitter"
                      [pauseScanner]="false"
                      (onAutoFocusChanged)="onAutoFocusChanged($event)"
                      (onUseCameraChanged)="onUseCameraChanged($event)"></app-scanner2>
        <!--[toggleAutoFocus]="true" [toggleUseCamera]="false"-->
      </div>
    </td>
    <td class="col">
      <table #labelArea>
        <tr class="row border" style="height: 60px;">
          <td style="width: 87px;">
            <img style="margin-top: -5px" src="../../../../assets/img/manualIco.png" height="60px">
          </td>
          <td [style.width]="engineLabel.typeApprovalLabel ? '160px' : '200px'" >
            <div style="font-family: Monospaced,monospace">
              *{{ engine.engineCode }}  {{ engine.engineNumber.substring(0,
              3) }}  {{ engine.engineNumber.substring(3, 6) }}*
            </div>
            <div *ngIf="engineLabel.sznwdata" style="font-family: Monospaced,monospace">
              *{{ engineLabel.sznwdata }}*
            </div>
          </td>
          <td [style.width]="engineLabel.typeApprovalLabel ? '90px' : '100px'" style="border-radius: 10%; border: 2px #212529 solid;">
            <div style="font-family: Monospaced,monospace" class="pl-2 d-inline">
              {{ engine.lotNumber ? engine.lotNumber : '0' }}
            </div>
            <div style="font-family: Monospaced,monospace" class="pl-3 d-inline">
              {{ engine.lotSize ? engine.lotSize : '0' }}
            </div>
          </td>
          <td class="text-center pt-1" style="width: 120px;">
            <div
              style="font-family: Monospaced,monospace">{{ engine.buildDate | date:'dd-MM-yy' }}</div>
            <div
              style="font-family: Monospaced,monospace">{{ engine.buildDate | date:'HH:mm:ss' }}</div>
          </td>
          <td *ngIf="engineLabel.typeApprovalLabel" class="col pr-3 pt-1" style="width: 90px;">
            <div
              style="font-family: Monospaced,monospace; font-size: x-large; font-weight: bold">{{ engine.keyCode }}</div>
            <div
              style="font-family: Monospaced,monospace; margin-top: -8px">{{ engineLabel.plant }}
              -{{ engineLabel.engineLabelCheckDigit }}</div>
          </td>
          <td *ngIf="engineLabel.typeApprovalLabel" class="pt-1" style="width: 120px;">
            <div class="row justify-content-center" style="font-family: Monospaced,monospace">
              {{engineLabel.countryQualifier}}
            </div>
            <div class="row justify-content-center" style="font-family: Monospaced,monospace">
              {{engineLabel.typeApprovalLabel}}
            </div>
          </td>
        </tr>
        <tr class="row pt-1 pb-1" style="height: 2px;">
          <td [style.width]="engineLabel.typeApprovalLabel ? '660px' : '520px'" style="height: 2px; background-color: black">
          </td>
        </tr>
        <ng-container
          *ngIf="engine.engineState !== 'UNKNOWN' && engine.engineState !== 'IN_PRODUCTION'; else noLabelToPrint">
          <tr class="row border" [style.height]="engineLabel.typeApprovalLabel ? '102px;' : '92px;'"
              [class.labeling-needs-validation]="checkLabelValidationState('timingBeltLabel', 'PENDING')"
              [class.labeling-failed-validation]="checkLabelValidationState('timingBeltLabel', 'FAILED')"
              [class.labeling-passed-validation]="checkLabelValidationState('timingBeltLabel', 'SUCCESS')"
          >
            <td class="col-9 m-auto text-center" style="width: 400px;">
              <ng-container *ngIf="engineLabel.timingBeltLabelIsReady; else spinner">
                <op-entity-label [width]="'0'" [height]="'0'"
                                 [value]="engineLabel.timingBeltLabelValue"
                                 [valueHasCheckDigit]="true"
                                 (onImageLoaded)="onTimingBeltImageLoaded($event)"
                                 [labelType]="'engine'" [showSpinner]="true"></op-entity-label>
                <div *ngIf="labelValidations[nextLabelValidation]?.LabelName === 'timingBeltLabel'"
                     class="scanLight scanLight0"></div>
              </ng-container>
            </td>
            <td *ngIf="engineLabel.typeApprovalLabel" class="col text-center pr-3 pt-1" style="width: 120px;">
              <div class="pt-2">
              <img src="../../../assets/img/logo_black_lg.png" alt="" class="d-inline-block"
                   width="50" height="50"></div>
              <div class="pt-2"
                style="font-family: Monospaced,monospace; margin-top: -8px">Volkswagen AG</div>
            </td>
            <td *ngIf="!engineLabel.typeApprovalLabel" class="col text-right pr-3 pt-1" style="width: 120px;">
              <div
                style="font-family: Monospaced,monospace; font-size: xx-large; font-weight: bold">{{ engine.keyCode }}</div>
              <div
                style="font-family: Monospaced,monospace; margin-top: -8px">{{ engineLabel.plant }}
                -{{ engineLabel.engineLabelCheckDigit }}</div>
            </td>
          </tr>
          <tr class="row pt-1 pb-1" style="height: 20px;">
            <td style="width: 520px; height: 20px;">
            </td>
          </tr>
          <ng-container *ngIf="engineLabel.groupLabelIsReady">
            <tr class="row border-left border-right border-top"
                style="margin-bottom: -8px"
                [style.height]="engineLabel.typeApprovalLabel ? '100px' : '80px'"
                [class.labeling-needs-validation]="checkLabelValidationState('groupLabel', 'PENDING')"
                [class.labeling-failed-validation]="checkLabelValidationState('groupLabel', 'FAILED')"
                [class.labeling-passed-validation]="checkLabelValidationState('groupLabel', 'SUCCESS')">
              <td class="col m-auto text-center" style="width: 520px;">
                <op-entity-label [width]="'0'" [height]="'0'" [value]="engineLabel.groupLabelValue"
                                 [valueHasCheckDigit]="true"
                                 (onImageLoaded)="onGroupLabelImageLoaded($event)"
                                 [labelType]="'engine'" [showSpinner]="true"></op-entity-label>
                <div *ngIf="labelValidations[nextLabelValidation]?.LabelName === 'groupLabel'"
                     class="scanLight scanLight1"></div>
              </td>
            </tr>
            <tr class="row border-left border-right border-bottom mt-1" style="height: 40px;"
                *ngIf="engineLabel.groupLabelValue != undefined"
                [class.labeling-needs-validation]="checkLabelValidationState('groupLabel', 'PENDING')"
                [class.labeling-failed-validation]="checkLabelValidationState('groupLabel', 'FAILED')"
                [class.labeling-passed-validation]="checkLabelValidationState('groupLabel', 'SUCCESS')">
              <td class="col text-center"
                  style="width: 520px; font-family: monospace; font-size: xx-large;">
                *{{ engineLabel.groupLabelValue }}*
              </td>
            </tr>
            <tr class="row pt-1 pb-1" style="height: 20px;">
              <td style="width: 520px; height: 20px;">
              </td>
            </tr>
          </ng-container>

          <ng-container *ngIf="engineLabel.offSetLabelIsReady && !engineLabel.injectorLabelIsReady">
            <tr class="row border" [style.height]="engineLabel.typeApprovalLabel ? '180px' : '145px'"
                *ngIf="engineLabel.offsetLabelValue != undefined && engineLabel.offsetPairList.length !== 0"
                [class.labeling-needs-validation]="checkLabelValidationState('offSetLabel', 'PENDING')"
                [class.labeling-failed-validation]="checkLabelValidationState('offSetLabel', 'FAILED')"
                [class.labeling-passed-validation]="checkLabelValidationState('offSetLabel', 'SUCCESS')">
              <td class="col-4 m-auto text-center" style="width: 145px;">
                <op-entity-label [width]="'0'" [height]="'0'" [value]="engineLabel.offsetLabelValue"
                                 (onImageLoaded)="onOffSetLabelImageLoaded($event)"
                                 [labelType]="'dmc'" [showSpinner]="true"></op-entity-label>
                <div *ngIf="labelValidations[nextLabelValidation]?.LabelName === 'offSetLabel'"
                     class="scanLight scanLightOffset"></div>
              </td>
              <td class="col" [style.margin-top]="engineLabel.typeApprovalLabel ? '17px' : '7px'">
                <table style="font-family: monospace; font-size: smaller; font-weight: bold">
                  <tr class="row">
                    <td class="col">*{{ engineLabel.groupLabelValue }}*</td>
                  </tr>
                  <tr class="row">
                    <td class="col">*{{ engineLabel.sznwdata }}*</td>
                  </tr>
                  <tr class="row" *ngFor="let entry of engineLabel.offsetPairList">
                      <td class="col" *ngIf="entry[0]">*{{ entry[0] }}*</td>
                      <td class="col" *ngIf="entry[1]">*{{ entry[1] }}*</td>
                  </tr>
                </table>
              </td>
            </tr>
          </ng-container>

          <ng-container
            *ngIf="engineLabel.injectorManufacturer === 'Bosch' && !engineLabel.hasOffSet && engineLabel.injectorLabelIsReady">
            <tr class="row border-left border-right border-top pb-3" style="margin-bottom: -20px"
                [class.labeling-needs-validation]="checkLabelValidationState('injector1Label', 'PENDING')"
                [class.labeling-failed-validation]="checkLabelValidationState('injector1Label', 'FAILED')"
                [class.labeling-passed-validation]="checkLabelValidationState('injector1Label', 'SUCCESS')">
              <td class="col text-left"
                  style="padding-left: 20px; font-size: x-large; font-family: monospace">
                Motornummer:
              </td>
              <td class="col text-right" style="font-size: x-large; font-family: monospace">
                *{{ engineLabel.engineCode }}  {{ engineLabel.engineNumber}}*
              </td>
            </tr>
            <tr class="row border-left border-right"
                style="margin-bottom: -20px"
                [style.height]="engineLabel.typeApprovalLabel ? '140px' : '120px'"
                [class.labeling-needs-validation]="checkLabelValidationState('injector1Label', 'PENDING')"
                [class.labeling-failed-validation]="checkLabelValidationState('injector1Label', 'FAILED')"
                [class.labeling-passed-validation]="checkLabelValidationState('injector1Label', 'SUCCESS')">
              <td class="col" style="width: 520px;" *ngIf="engineLabel.injectorLabel1 != undefined">
                <op-entity-label [width]="'0'" [height]="'0'" [value]="engineLabel.injectorLabel1"
                                 (onImageLoaded)="onInjectorLabelImageLoaded($event, 1)"
                                 [labelType]="'engine'" [showSpinner]="true"></op-entity-label>
                <div *ngIf="labelValidations[nextLabelValidation]?.LabelName === 'injector1Label'"
                     class="scanLight scanLight2"></div>
              </td>
            </tr>
            <tr class="row border-left border-right border-bottom"
                [class.labeling-needs-validation]="checkLabelValidationState('injector1Label', 'PENDING')"
                [class.labeling-failed-validation]="checkLabelValidationState('injector1Label', 'FAILED')"
                [class.labeling-passed-validation]="checkLabelValidationState('injector1Label', 'SUCCESS')">
              <td class="col text-left"
                  style="padding-left: 20px; font-size: x-large; font-family: monospace">
                {{ engineLabel.getInjectorOffSet(1)}}
              </td>
              <td class="col text-right pr-4" style="font-size: x-large; font-family: monospace">
                {{ engineLabel.getInjectorOffSet(2)}}
                <!-- Needs static replacement: {{ getCheckDigit(engineLabel.injectorLabel1) }} -->
              </td>
            </tr>
            <tr class="row pt-1 pb-1" style="height: 20px;">
              <td style="width: 520px; height: 20px;">
              </td>
            </tr>
            <tr class="row border-left border-right border-top pb-3" style="margin-bottom: -20px"
                [class.labeling-needs-validation]="checkLabelValidationState('injector2Label', 'PENDING')"
                [class.labeling-failed-validation]="checkLabelValidationState('injector2Label', 'FAILED')"
                [class.labeling-passed-validation]="checkLabelValidationState('injector2Label', 'SUCCESS')">
              <td class="col text-left"
                  style="padding-left: 20px; font-size: x-large; font-family: monospace">
                Motornummer:
              </td>
              <td class="col text-right" style="font-size: x-large; font-family: monospace">
                *{{ engineLabel.engineCode }}  {{ engineLabel.engineNumber}}*
              </td>
            </tr>
            <tr class="row border-left border-right"
                style="margin-bottom: -20px"
                [style.height]="engineLabel.typeApprovalLabel ? '140px' : '120px'"
                [class.labeling-needs-validation]="checkLabelValidationState('injector2Label', 'PENDING')"
                [class.labeling-failed-validation]="checkLabelValidationState('injector2Label', 'FAILED')"
                [class.labeling-passed-validation]="checkLabelValidationState('injector2Label', 'SUCCESS')">
              <td class="col" style="width: 520px;" *ngIf="engineLabel.injectorLabel2 != undefined">
                <op-entity-label [width]="'0'" [height]="'0'" [value]="engineLabel.injectorLabel2"
                                 (onImageLoaded)="onInjectorLabelImageLoaded($event, 2)"
                                 [labelType]="'engine'" [showSpinner]="true"></op-entity-label>
                <div *ngIf="labelValidations[nextLabelValidation]?.LabelName === 'injector2Label'"
                     class="scanLight scanLight2"></div>
              </td>
            </tr>
            <tr class="row border-left border-right border-bottom"
                [class.labeling-needs-validation]="checkLabelValidationState('injector2Label', 'PENDING')"
                [class.labeling-failed-validation]="checkLabelValidationState('injector2Label', 'FAILED')"
                [class.labeling-passed-validation]="checkLabelValidationState('injector2Label', 'SUCCESS')">
              <td class="col text-left"
                  style="padding-left: 20px; font-size: x-large; font-family: monospace">
                {{ engineLabel.getInjectorOffSet(3)}}
              </td>
              <td class="col text-right pr-4" style="font-size: x-large; font-family: monospace">
                {{ engineLabel.getInjectorOffSet(4)}}
                <!-- Needs static replacement: {{ getCheckDigit(engineLabel.injectorLabel2) }} -->
              </td>
            </tr>
            <tr class="row pt-1 pb-1" style="height: 20px;">
              <td style="width: 520px; height: 20px;">
              </td>
            </tr>
          </ng-container>

          <ng-container
            *ngIf="engineLabel.injectorManufacturer === 'Bosch' && engineLabel.hasOffSet && engineLabel.injectorLabelIsReady">
            <tr class="row border" style="height: 175px;"
                *ngIf="engineLabel.offsetLabelValue != undefined && engineLabel.offsetPairList.length !== 0"
                [class.labeling-needs-validation]="checkLabelValidationState('offSetLabel', 'PENDING')"
                [class.labeling-failed-validation]="checkLabelValidationState('offSetLabel', 'FAILED')"
                [class.labeling-passed-validation]="checkLabelValidationState('offSetLabel', 'SUCCESS')">
              <td class="col-4 m-auto text-center" style="width: 175px; margin-top: -10px">
                <op-entity-label [width]="'0'" [height]="'0'"
                                 [value]="engineLabel.offsetLabelValue + '='"
                                 (onImageLoaded)="onOffSetLabelImageLoaded($event)"
                                 [labelType]="'dmc'" [showSpinner]="true"></op-entity-label>
                <div *ngIf="labelValidations[nextLabelValidation]?.LabelName === 'offSetLabel'"
                     class="scanLight scanLightOffset"></div>
              </td>
              <td style="width: 20px">

              </td>
              <td class="col" style="margin-top: 7px">
                <table
                  style="font-family: Monospaced,monospace; font-size: smaller; font-weight: bold">
                  <tr class="row">
                    <td class="col">*{{ engineLabel.groupLabelValue }}*</td>
                  </tr>
                  <tr class="row">
                    <td class="col">*{{ engineLabel.sznwdata }}*</td>
                  </tr>
                  <tr class="row" *ngFor="let entry of engineLabel.offsetPairList">
                    <td class="col" *ngIf="entry[0]">*{{ entry[0] }}*</td>
                  </tr>
                  <tr class="row" *ngFor="let injector of engineLabel.injectors">
                    <td class="col" *ngIf="injector.bgn && injector.offset">*{{ injector.bgn }}{{ injector.offset }}*</td>
                  </tr>
                </table>
              </td>
            </tr>
          </ng-container>

          <ng-container
            *ngIf="engineLabel.injectorManufacturer === 'Delphi' && engineLabel.offSetLabelIsReady && engineLabel.hasInjectors">
            <tr class="row border" style="height: 175px;"
                *ngIf="engineLabel.offsetLabelValue != undefined"
                [class.labeling-needs-validation]="checkLabelValidationState('delphiOffSetLabel', 'PENDING')"
                [class.labeling-failed-validation]="checkLabelValidationState('delphiOffSetLabel', 'FAILED')"
                [class.labeling-passed-validation]="checkLabelValidationState('delphiOffSetLabel', 'SUCCESS')">
              <td class="col-4 m-auto text-center" style="width: 175px; margin-top: -10px">
                <op-entity-label [width]="'0'" [height]="'0'"
                                 [value]="engineLabel.offsetLabelValue + '='"
                                 (onImageLoaded)="onDelphiOffSetLabelImageLoaded($event)"
                                 [labelType]="'dmc'" [showSpinner]="true"></op-entity-label>
                <div
                  *ngIf="labelValidations[nextLabelValidation]?.LabelName === 'delphiOffSetLabel'"
                  class="scanLight scanLightOffset"></div>
              </td>
              <td class="col" style="width: 20px">

              </td>
              <td class="col-6" style="margin-top: 7px">
                <table
                  style="font-family: Monospaced,monospace; font-size: smaller; font-weight: bold">
                  <tr class="row pt-2">
                    <td class="col">*{{ engineLabel.groupLabelValue }}*</td>
                  </tr>
                  <tr *ngFor="let injector of engineLabel.injectors" class="row">
                    <td class="col" *ngIf="injector.bgn && injector.offset">*{{ injector.bgn }}{{ injector.offset }}*</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr class="row pt-1 pb-1" style="height: 20px;">
              <td style="width: 520px; height: 20px;">
              </td>
            </tr>
            <tr class="row border" style="height: 175px;"
                *ngIf="engineLabel.injectorLabelIsReady != undefined"
                [class.labeling-needs-validation]="checkLabelValidationState('delphiInjectorLabel', 'PENDING')"
                [class.labeling-failed-validation]="checkLabelValidationState('delphiInjectorLabel', 'FAILED')"
                [class.labeling-passed-validation]="checkLabelValidationState('delphiInjectorLabel', 'SUCCESS')">
              <td class="col-4 m-auto text-center" style="width: 175px; margin-top: -10px">
                <op-entity-label [width]="'0'" [height]="'0'"
                                 [value]="engineLabel.injectorLabel1 + engineLabel.injectorLabel2 + '='"
                                 (onImageLoaded)="onDelphiInjectorLabelImageLoaded($event)"
                                 [labelType]="'dmc'" [showSpinner]="true"></op-entity-label>
                <div
                  *ngIf="labelValidations[nextLabelValidation]?.LabelName === 'delphiInjectorLabel'"
                  class="scanLight scanLightOffset"></div>
              </td>
              <td class="col" style="width: 20px">

              </td>
              <td class="col-6" style="margin-top: 7px">
                <table
                  style="font-family: Monospaced,monospace; font-size: smaller; font-weight: bold">
                  <tr class="row">
                    <td class="col font-weight-bolder pb-3 pt-2" style="font-size: large">MOTORNUMMER
                      *{{ engineLabel.engineCode }} {{ engineLabel.engineNumber}}*
                    </td>
                  </tr>
                  <tr *ngFor="let injector of engineLabel.injectors" class="row">
                    <td class="col" *ngIf="injector.bgn && injector.offset">{{ injector.bgn }}{{ injector.offset }}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </table>
    </td>
    <td *ngIf="helpMessage && helpMessage.length > 0 && failedScans < 3" class="col text-center text-danger">
      <i class="fas fa-exclamation-triangle"></i><br>
      {{helpMessage | translate}}
    </td>
    <td *ngIf="failedScans > 2" class="col text-center text-danger">
      <i class="fas fa-exclamation-triangle"></i><br>
      {{'Möglicherweise liegt ein Problem mit dem Scanner vor.'}}
      <button *ngIf="!showCalibrationImage" type="button" class="btn btn-secondary my-2" (click)="showCalibrationImage = true" tabindex="-1">{{'component-profile.actions.reprint.show-calibration-barcode' | translate}}</button>
      <button *ngIf="showCalibrationImage" type="button" class="btn btn-secondary my-2" (click)="showCalibrationImage = false" tabindex="-1">{{'component-profile.actions.reprint.hide-calibration-barcode' | translate}}</button>
      <div *ngIf="showCalibrationImage">
        <img width="100%" height="100%" [src]="calibrationLabelImage" alt=""/>
      </div>
      <span *ngIf="helpMessage && helpMessage.length > 0 && showCalibrationImage" class="text-body mt-2">{{helpMessage | translate}}</span>
    </td>
  </tr>
  <!--<tr class="d-flex">
    <td class="col-4">
      {{ 'component-profile.actions.reprint.block' | translate }}
    </td>
    <td class="col pl-0">
      <input type="checkbox" [ngModel]="createBlock" (change)="createBlockChanged()">
    </td>
  </tr-->

  <ng-container *opRequiredClaimByName="'engine-label-print'">
  <tr *ngIf="physicalEngineVerificationActive" class="row align-items-center">
    <td class="col-4">
      Motorvalidierung
    </td>
    <td class="col pl-1">
      <span *ngIf="!engineVerified && !engineVerificationFailed && !scannerEngineVerification && !engineVerificationSkipped">Verifizieren Sie, dass Sie den passenden Motor bearbeiten.</span>
      <span *ngIf="!engineVerified && !engineVerificationFailed && scannerEngineVerification">Bitte einen beliebigen DMC des Motors scannen.</span>
      <span *ngIf="engineVerificationFailed" class="text-danger">Der gescannte DMC passt zu keinem registrierten Bauteil.</span>
      <span *ngIf="engineVerified" class="text-success">Motor verifiziert!</span>
      <span *ngIf="engineVerificationSkipped" class="text-warning">Motorverifizierung übersprungen.</span>
    </td>
    <td class="col">
      <button *ngIf="scannerEngineVerification === true && !engineVerified" class="btn btn-sm btn-primary" (click)="skipEngineVerification()">Überspringen</button>
      <button *ngIf="scannerEngineVerification === false && !engineVerified" class="btn btn-sm btn-primary" (click)="scannerEngineVerification = true; engineVerificationSkipped = false">Verifikation starten</button>
    </td>
  </tr>
  <tr *ngIf="physicalEngineVerificationActive" class="row align-items-center">
    <td class="col-4"></td>
    <td class="col-5">
      <div *ngIf="scannerEngineVerification" class="row mt-2">
        <div class="col text-center">
          <i class="fas fa-qrcode fa-3x"></i>
          <div class="scanLightEngineCheck"></div>
        </div>
      </div>
    </td>
  </tr>
  <tr class="row align-items-center">
    <td class="col-4">
      {{'component-profile.actions.reprint.select-printer' | translate}}
    </td>
    <td class="col pl-1">
      <op-select-box class="nav-link text-light pl-0" (selectEvent)="printerChanged($event)"
                     [options]="printers"
                     [defaultLabel]="getSelectedPrinterOption()?.label"></op-select-box>
    </td>
  </tr>
  <tr class="row align-items-center">
    <td class="col-4">
      {{ 'component-profile.actions.reprint.reason' | translate }}
    </td>
    <td class="col pl-1">
      <op-select-box class="nav-link text-light pl-0" (selectEvent)="reasonChanged($event)"
                     [options]="reasons"
                     [defaultLabel]="labelingType === labelingTypes.KEYCODECHANGE ? 'Umschlüsselung' : undefined"
                     [class.is-invalid]="reasonInvalid">
      </op-select-box>
      <div class="invalid-feedback">Bitte einen Grund auswählen.</div>
    </td>
  </tr>
    <tr class="row align-items-center" *ngIf="engine.labelIdentifier">
      <td class="col-4">
        {{ 'component-profile.actions.print.template' | translate }}
      </td>
      <td class="col pl-1 align-content-center align-middle">

    <ng-container *ngIf="!editLabelMode">
      <div class="pt-1 d-inline">
        {{ engine.labelIdentifier }}
      </div>

    </ng-container>

    <ng-container *ngIf="editLabelMode">
      <div>
        <input type="text" class="d-inline form-control pt-0 pb-0 mr-3"
               style="width: 100px; height: 22px;"
               [(ngModel)]="engine.labelIdentifier">

        <small>
          Diese Änderung ist temporär und gilt nur für den nächsten Ausdruck!
        </small>
      </div>

    </ng-container>

    <op-edit-entry-with-confirmation
      (onEdit)="editLabelIdentifier()"
      (onConfirmation)="setLabelIdentifier()"
      (onCancellation)="resetLabelIdentifier()"
    >

    </op-edit-entry-with-confirmation>
      </td>
    </tr>
  <tr *ngIf="reason === 'keycodeChange'" class="row align-items-center">
    <td class="col-4">
      {{ 'Neue Schlüsselnummer' | translate }}
    </td>
    <td class="col pl-1">
      <op-select-box class="nav-link text-light pl-0" (selectEvent)="keyCodeChanged($event)"
                     [options]="keyCodes">
      </op-select-box>
    </td>
    <!--td class="col pl-1">
      <button type="button" class="btn btn-sm btn-primary"
              [disabled]="!keyCode || keyCode === engine.keyCode"
              (click)="handleChangeKeyCode()">Umschlüsseln</button>
    </td-->
  </tr>
  <tr *ngIf="reason === 'keycodeChange'" class="row align-items-center">
    <td class="col-4">
    </td>
    <td class="col pl-1">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="keepColdTests"
               [(ngModel)]="keepColdTests">
        <label class="form-check-label" for="keepColdTests">
          {{'Kalttests behalten' | translate}}
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="keepHotTests"
               [(ngModel)]="keepHotTests">
        <label class="form-check-label" for="keepHotTests">
          {{'Hottests behalten' | translate}}
        </label>
      </div>
    </td>
    <td class="col pl-1">
    </td>
  </tr>
  <tr class="row pt-2">
    <td class="col-4"></td>
    <td class="col pl-1">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="validateLabel"
               [(ngModel)]="withLabelValidation">
        <label class="form-check-label" for="validateLabel">
          {{'component-profile.actions.reprint.validate-label' | translate}}
        </label>
      </div>
    </td>
    <td class="col small">
      <span *ngIf="labelValidationSuccessful" class="text-success"><i class="fas fa-check px-2"></i>{{'global.word.validated' | translate}}</span>
      <span *ngIf="labelValidationAborted" class="text-danger"><i class="fas fa-times px-2"></i>{{'component-profile.actions.reprint.validation-aborted' | translate}}</span>
    </td>
  </tr>
  <tr class="d-flex row pt-2">
    <td class="col-4">
    </td>
    <td class="col pl-0">
      <button *ngIf="reason !== 'keycodeChange'" (click)="submit()" type="button" class="btn btn-sm btn-primary"
              [disabled]="(!engineLabel.timingBeltLabelIsReady || engine.engineState === 'UNKNOWN' || engine.engineState === 'IN_PRODUCTION') || (physicalEngineVerificationActive && !(engineVerified || engineVerificationSkipped))">
        {{'global.word.print' | translate}}
      </button>
      <button *ngIf="reason === 'keycodeChange'" (click)="submit()" type="button" class="btn btn-sm btn-primary"
              [disabled]="(!engineLabel.timingBeltLabelIsReady || engine.engineState === 'UNKNOWN' || engine.engineState === 'IN_PRODUCTION'
               || !keyCode || keyCode === 'undefined' || keyCode === engine.keyCode || keyCodeChangeInProgress) || (physicalEngineVerificationActive && !(engineVerified || engineVerificationSkipped))">
        {{'component-profile.actions.reprint.print-and-keycode-change' | translate}}
      </button>

      <ng-container *opRequiredClaimByName="'engine-label-export-pdf'">
      <button *ngIf="reason !== 'keycodeChange'" type="button" (click)="makePdf()" class="btn btn-sm btn-warning ml-1"
              [disabled]="!engineLabel.timingBeltLabelIsReady || engine.engineState === 'UNKNOWN' || engine.engineState === 'IN_PRODUCTION'">
        <i class="fas fa-file-pdf"></i> {{'global.word.preview' | translate}}
      </button>
      </ng-container>

      <button *ngIf="nextLabelValidation > -1" type="button" class="btn btn-secondary btn-sm ml-1" (click)="endLabelValidation(false)">{{'component-profile.actions.reprint.abort-validation' | translate}}</button>
      <button *ngIf="labelValidationAborted" type="button" class="btn btn-secondary btn-sm ml-1" (click)="setUpLabelValidation()">{{'component-profile.actions.reprint.restart-validation' | translate}}</button>
      <ng-container *ngIf="printJobSubmitted">
        <div class="my-2">
          <span *ngIf="printingProcedureState !== ProcedureStates.Done && printingProcedureState !== ProcedureStates.Failed && printMonitorAttempts < 5">
            {{'component-profile.actions.reprint.print-job-started' | translate}}
            <span class="spinner-border spinner-border-sm"></span>
          </span>
          <span *ngIf="printingProcedureState !== ProcedureStates.Done && printingProcedureState !== ProcedureStates.Failed && printMonitorAttempts > 4"
            class="text-warning">
            {{'component-profile.actions.reprint.print-job-waiting' | translate}}
          </span>
          <span *ngIf="printingProcedureState === ProcedureStates.Done">{{'component-profile.actions.reprint.print-job-finished' | translate}}</span>
          <span *ngIf="printingProcedureState === ProcedureStates.Failed">{{'component-profile.actions.reprint.print-job-failed' | translate}}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="keyCodeChangeInProgress">
        <div class="my-2">
          <span class="spinner-border spinner-border-sm"></span>
          <span>{{'component-profile.actions.reprint.keycode-change-in-progress' | translate}}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="keyCodeChangeError">
        <div class="my-2">
          <span class="text-danger">{{'component-profile.actions.reprint.keycode-change-error' | translate}}</span>
        </div>
      </ng-container>
    </td>
  </tr>
  </ng-container>

  <tr *ngIf="getIsDevMode()" class="d-flex border mt-2 p-2">
    <td class="col-4">
      Debug
    </td>
    <td class="col pl-0">
      <button (click)="mockStartValidation()" type="button" class="btn btn-sm btn-primary">Mock
        Start Validation
      </button>
      <button type="button" (click)="mockValidationSuccess()" class="btn btn-sm btn-success ml-1">
        Mock Success
      </button>
      <button type="button" (click)="mockValidationFailure()" class="btn btn-sm btn-danger ml-1">
        Mock Failure
      </button>
    </td>
  </tr>
</ng-container>

<ng-template #noLabelToPrint>
  <tr class="row border" style="height: 364px">
    <td class="col pl-0 text-center">
      <small>{{'component-profile.actions.reprint.label-creation-not-yet-possible' | translate}}</small>
    </td>
  </tr>
</ng-template>

<ng-template #spinner>
  <div class="spinner-border spinner-border-sm"></div>
</ng-template>

<ng-container *ngIf="selfLink">
  <div [hidden]="true">
    <op-entity-label id="cppQrCodeArea" #cppQrCodeArea style="margin-left: -20px;"
                     (onImageLoaded)="onSelfLinkImageLoaded($event)" [value]="selfLink" width="128"
                     height="128" labelType="url"></op-entity-label>
  </div>
</ng-container>

