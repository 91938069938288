import { Injectable } from '@angular/core';
import {UserConfigService} from './user-config.service';
import {
  BarChartColorTheme,
  BarChartStyleTheme,
  GraphCStyleConfig,
  LineChartColorTheme,
  UserConfig
} from '../models/userConfig';
import {NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {KeyValue} from '@angular/common';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
  deps: [UserConfigService]
})

export class ThemingService {

  private themingSettingSubject: BehaviorSubject<ThemingSetting> = new BehaviorSubject<ThemingSetting>(new ThemingSetting());
  private selectedThemeSubject: BehaviorSubject<ThemeSelection> = new BehaviorSubject<ThemeSelection>(DEFAULT_CONF);

  constructor(private userConfigService: UserConfigService,
              private router: Router) {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
          this.setThemeCategory(ThemingCategory.None);
      }});

    this.userConfigService.config$.pipe(map(value => {
      const conf = {
        ...this.selectedThemeSubject.getValue(),
        ...value.statistics,
      };
      if (value.dashboard) {
        if (conf[ThemingCategory.Dashboard]) {
          conf[ThemingCategory.Dashboard][ThemeType.Style]
            = value.dashboard.chartStyle ? value.dashboard.chartStyle : conf[ThemingCategory.Dashboard][ThemeType.Style];
          conf[ThemingCategory.Dashboard][ThemeType.Color]
            = value.dashboard.colorTheme ? value.dashboard.colorTheme : conf[ThemingCategory.Dashboard][ThemeType.Color];
        } else {
          conf[ThemingCategory.Dashboard] = {
            [ThemeType.Style]: value.dashboard?.chartStyle,
            [ThemeType.Color]: value.dashboard?.colorTheme
          };
        }
      }
      return conf
    })).subscribe(config => {
      this.selectedThemeSubject.next(config)
    });
  }

  setThemeCategory(category: ThemingCategory) {
    const setting = new ThemingSetting();
    setting.category = category;
    switch (category) {
      case ThemingCategory.Dashboard:
        setting.themes.push({ type: {key: 'navbar.dropdown.settings.dashboard.chartStyle.name', value: ThemeType.Style},
          options: [{ key: 'navbar.dropdown.settings.dashboard.chartStyle.twoBars', value: BarChartStyleTheme.TwoBars},
                    { key: 'navbar.dropdown.settings.dashboard.chartStyle.fillingBar', value: BarChartStyleTheme.FillingBar}]});
        setting.themes.push({ type: {key: 'navbar.dropdown.settings.dashboard.colorTheme.name', value: ThemeType.Color},
          options: [{ key: 'navbar.dropdown.settings.dashboard.colorTheme.glossy', value: BarChartColorTheme.Glossy},
                    { key: 'navbar.dropdown.settings.dashboard.colorTheme.dim', value: BarChartColorTheme.Dim}]});
        break;
      case ThemingCategory.DashboardColorOnly:
        setting.themes.push({ type: {key: 'navbar.dropdown.settings.dashboard.colorTheme.name', value: ThemeType.Color},
          options: [{ key: 'navbar.dropdown.settings.dashboard.colorTheme.glossy', value: BarChartColorTheme.Glossy},
            { key: 'navbar.dropdown.settings.dashboard.colorTheme.dim', value: BarChartColorTheme.Dim}]});
        setting.category = ThemingCategory.Dashboard;
        break;
      case ThemingCategory.OutputLine:
        setting.themes.push({ type: {key: 'navbar.dropdown.settings.dashboard.colorTheme.name', value: ThemeType.Color},
          options: [{ key: 'navbar.dropdown.settings.dashboard.colorTheme.classic', value: LineChartColorTheme.Classic},
            { key: 'navbar.dropdown.settings.dashboard.colorTheme.lights', value: LineChartColorTheme.Lights}]});
        break;
      case ThemingCategory.OutputBar:
        setting.themes.push({ type: {key: 'navbar.dropdown.settings.dashboard.chartStyle.name', value: ThemeType.Style},
          options: [{ key: 'navbar.dropdown.settings.dashboard.chartStyle.twoBars', value: BarChartStyleTheme.TwoBars},
            { key: 'navbar.dropdown.settings.dashboard.chartStyle.fillingBar', value: BarChartStyleTheme.FillingBar}]});
        setting.themes.push({ type: {key: 'navbar.dropdown.settings.dashboard.colorTheme.name', value: ThemeType.Color},
          options: [{ key: 'navbar.dropdown.settings.dashboard.colorTheme.glossy', value: BarChartColorTheme.Glossy},
            { key: 'navbar.dropdown.settings.dashboard.colorTheme.dim', value: BarChartColorTheme.Dim}]});
        break;
      case ThemingCategory.OEELine:
        setting.themes.push({ type: {key: 'navbar.dropdown.settings.dashboard.colorTheme.name', value: ThemeType.Color},
          options: [{ key: 'navbar.dropdown.settings.dashboard.colorTheme.modern', value: LineChartColorTheme.Modern},
            { key: 'navbar.dropdown.settings.dashboard.colorTheme.classic', value: LineChartColorTheme.Classic},
            { key: 'navbar.dropdown.settings.dashboard.colorTheme.lights', value: LineChartColorTheme.Lights}]});
        break;
      case ThemingCategory.None:
      default:
    }
    this.themingSettingSubject.next(setting);
  }

  public saveThemeForUser(sc: GraphCStyleConfig) {
    this.userConfigService.updateDashboardConfig(sc);
  }

  public loadThemeForUser() {

  }

  getThemingSource(): Observable<ThemingSetting> {
    return this.themingSettingSubject.asObservable();
  }
  getSelectionSource(): Observable<ThemeSelection> {
    return this.selectedThemeSubject.asObservable();
  }

  setTheme(category: ThemingCategory, type: ThemeType, value: string) {
    const update: UserConfig = new UserConfig();
    switch (category) {
      case ThemingCategory.Dashboard:
        update.dashboard = {};
        switch (type) {
          case ThemeType.Color:
            update.dashboard.colorTheme = value as BarChartColorTheme;
            break;
          case ThemeType.Style:
            update.dashboard.chartStyle = value as BarChartStyleTheme;
            break;
          default:
        }
        break;
      default:
        update.statistics = {};
        update.statistics = {[category]: { [type]: value }};
      }
    this.userConfigService.pushConfig(update);
  }
}

export enum ThemingCategory {
  None = 'NONE',
  Dashboard = 'DASHBOARD',
  DashboardColorOnly = 'DASHBOARDCOLORONLY',
  OutputBar = 'OUTPUTBAR',
  OutputLine = 'OUTPUTLINE',
  OEELine = 'OEELINE'
}

export enum ThemeType {
  Style = 'STYLE',
  Color = 'COLOR'
}

export class ThemingSetting {
  constructor() {
    this.category = ThemingCategory.None
    this.themes = new Array<{
      type: KeyValue<string, ThemeType>;
      options: Array<KeyValue<string, string>>}>();
  }
  category: ThemingCategory;
  themes: Array<{ type: KeyValue<string, ThemeType>,
                  options: Array<KeyValue<string, string>> }>;
}
export class ThemeSelection {
  [ThemingCategory.Dashboard]?: {
    [ThemeType.Style]?: BarChartStyleTheme,
    [ThemeType.Color]?: BarChartColorTheme,
  }
  [ThemingCategory.OutputBar]?: {
    [ThemeType.Style]?: BarChartStyleTheme,
    [ThemeType.Color]?: BarChartColorTheme
  }
  [ThemingCategory.OutputLine]?: {
    [ThemeType.Color]?: LineChartColorTheme
  }
  [ThemingCategory.OEELine]?: {
    [ThemeType.Color]?: LineChartColorTheme
  }
}
const DEFAULT_CONF: ThemeSelection = {
  [ThemingCategory.Dashboard]: {
    [ThemeType.Style]: BarChartStyleTheme.FillingBar,
    [ThemeType.Color]: BarChartColorTheme.Glossy,
  },
  [ThemingCategory.OutputBar]: {
    [ThemeType.Style]: BarChartStyleTheme.FillingBar,
    [ThemeType.Color]: BarChartColorTheme.Glossy,
  },
  [ThemingCategory.OutputLine]: {
    [ThemeType.Color]: LineChartColorTheme.Lights
  },
  [ThemingCategory.OEELine]: {
    [ThemeType.Color]: LineChartColorTheme.Modern
  }
};
