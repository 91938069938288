import {BlockedActionState, BlockedActionType, BlockingActionResponse, Source} from '@cstx/volkswagen-mqs-engine-service-client';
import {PartService} from '../../../services/backend/part.service';

export class Blocking {
  blockedActionState?: BlockedActionState | null;
  blockedActionType?: BlockedActionType | null;
  blockedAt: string;
  blockedBy: string;
  blockingReason: string;
  createdAt?: string;
  createdBy?: string;
  id: string;
  modifiedAt?: string;
  modifiedBy?: string;
  releaseReason: string;
  releasedAt?: string | null;
  releasedBy?: string | null;
  source: Source;

  public details = new Array<KeyValuePair>();
  public expanded: boolean;

  public blockingActionNr: string;
  public childComponentId: string;
  public childComponentName: string;

  constructor(blockingAction?: BlockingActionResponse, partService?: PartService) {
    if (blockingAction) {
      this.blockingActionNr = blockingAction.humanReadableId;
      this.childComponentId = blockingAction.childComponentId;

      if (this.childComponentId !== '00000000-0000-0000-0000-000000000000' && partService) {
        partService.getPartByID(this.childComponentId).then(part => {
          this.childComponentName = part?.dmc;
        });
      }
    }

  }
}


interface KeyValuePair {
  key: string;
  value: string;
}
