import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TrackingEvent} from '../../models/tracking/trackingEvent';
import {TrackingService} from '../../services/tracking.service';
import {EventType} from '@cstx/volkswagen-mqs-tracking-service-client';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'op-event-tracking',
  templateUrl: './event-tracking.component.html',
  styleUrls: ['./event-tracking.component.scss'],
  providers: [TrackingService]
})
export class EventTrackingComponent implements OnInit {
  @Input() componentId: string;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onStackIdentifierDetected = new EventEmitter<string>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onStackDispatchDateDetected = new EventEmitter<string>();

  public trackingEvents = new Array<TrackingEvent>();
  public displayOption = 'table';
  loading = false;

  constructor(private trackingService: TrackingService, private ts: TranslateService) { }

  ngOnInit(): void {
    if (this.componentId) {
      this.getTrackingEvents();
    }
  }

  public getTrackingEvents() {
    this.loading = true;
    this.trackingService.getEventById(this.componentId)
      .then(result => {
        this.trackingEvents = result;
        this.checkForStackIdentifier();
        this.checkForDispatchDate();
      })
      .finally(() => this.loading = false);
  }

  onRefreshEvent() {
    if (this.trackingService !== undefined) {
        this.getTrackingEvents();
    }
  }

  private checkForStackIdentifier() {
    // TODO: Check for correct sorting - latest stacking event required for detection
    const latestStackIdentifier = this.trackingEvents.find(e => e.eventType === 'STACKING')?.stackIdentifier;
    this.onStackIdentifierDetected.emit(latestStackIdentifier);
  }

  // This is a temporary workaround to get / find the dispatch date based on latest / newest dispatch
  private checkForDispatchDate() {
    const latestDispatchDate = this.trackingEvents.find(e => e.eventType === 'DISPATCH')?.eventTime;
    this.onStackDispatchDateDetected.emit(latestDispatchDate);
  }

  getTimelineStyle(eventType: EventType): string {
    /**
     * for later if differentiated styles may be wanted
     */
    switch (eventType) {
      case 'MANUFACTURED':
      case 'DONE' :
        // return 'manufacturedEvent';
      case 'BLOCKED':
        // return 'blockedEvent';
      case 'IN_REVIEW':
        // return 'inReviewEvent';
      case 'INVENTORIED':
      case 'DISPATCH':
      case 'STACKING':
          // return 'dispatchEvent';
      default:
        return 'unknownEvent';
    }
  }
}
