export class PreviewRequest {
  public componentId?: string;
  public componentType?: string;
  public componentCostCenter?: string;
  public fileName: string;
  public parserType?: string;
  public s3Key?: string;
  public size?: string;
  public createdAt?: string;

}
