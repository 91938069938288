<div class="container-fluid pl-3 pb-3 cpp-page-defaults">
  <div class="pt-2 pb-2">
    <op-breadcrumb [path]="'Actions/Info'"></op-breadcrumb>
  </div>
  <table class="table table-hover">
    <tr class="row pl-3 pr-3">
      <td class="col-9 pl-0">
        <h4>Infoseite</h4>
        <small>
          <p>
          ...
          </p>
        </small>
      </td>
      <td class="col-3 pl-0 text-right">
        <a [routerLink]="['/home/info/show']">
          <button type="button" class="btn btn-sm btn-outline-dark">Öffnen</button>
        </a>
      </td>
    </tr>
    <tr class="row pl-3 pr-3">
      <td class="col-9 pl-0">
        <h4>Informationen verwalten</h4>
        <small>
          <p>
            ...
          </p>
        </small>
      </td>
      <td class="col-3 pl-0 text-right">
        <a [routerLink]="['/home/info/list']">
          <button type="button" class="btn btn-sm btn-outline-dark">Öffnen</button>
        </a>
      </td>
    </tr>
  </table>
</div>
