import {BuildProgramCreateRequest, BuildProgramResponse} from '@cstx/volkswagen-mqs-logistics-data-service-client';
import {WeekDay} from './filter/week-day';
import {KeyValue} from '@angular/common';
import {ConfigService} from '../../../../core/services/config.service';
import moment from 'moment';
import {Shift} from "@cstx/volkswagen-mqs-workshift-service-client";


export class BuildProgramSet {
  public programs = new Array<BuildProgram>();
}

/**
 * A build-program is a combined view on all entries matching
 * the combination from costCenter, year and week.
 */
export class BuildProgram {
  public get calendarWeek(): string {
    return this._calendarWeek;
  }
  public set calendarWeek(value: string) {
    this._calendarWeek = value;

    if (parseInt(this._calendarWeek) === ConfigService.calendarWeek) {
      this.status = BuildProgramStatus.CURRENT;
    }
    if (parseInt(this._calendarWeek) > ConfigService.calendarWeek) {
      this.status = BuildProgramStatus.UPCOMING;
    }
    if (parseInt(this._calendarWeek) < ConfigService.calendarWeek) {
      this.status = BuildProgramStatus.DONE;
    }
  }
  public costCenter: string;
  public year: string;
  public entries = new Array<BuildProgramEntry>();
  public status: BuildProgramStatus = BuildProgramStatus.UNKNOWN;

  public get lastModifiedBy(): string {
    this.entries.sort((a, b) => {
      if (a.modifiedAt > b.modifiedAt) {
        return 1;
      }

      if (a.modifiedAt < b.modifiedAt) {
        return -1;
      }

      return 0;
    });


    return this.entries[0]?.modifiedBy;
  }
  public get lastModifiedAt(): string {
    this.entries.sort((a, b) => {
      if (a.modifiedAt > b.modifiedAt) {
        return 1;
      }

      if (a.modifiedAt < b.modifiedAt) {
        return -1;
      }

      return 0;
    });


    return this.entries[0]?.modifiedAt;
  }
  public get week(): BuildProgramDay[] {
    this.entries.forEach(entry => {
      const day = this._week.find(d => d.day === entry.weekDay);

      if (day && !day.alreadyAddedPositions.includes(entry.position)) {

        day.listOfProductTypes.add(entry.partNumber);
        // tslint:disable-next-line:radix
        day.calculatedAmount = day.calculatedAmount + parseInt(entry.targetAmount);
        day.activePositions = day.activePositions + 1;
        day.alreadyAddedPositions.push(entry.position);
      }
    });


    return this._week;
  }
  public get statistics(): BuildProgramStatistic {
    if (this._statistics) {
      return this._statistics;
    }
    const statistic = new BuildProgramStatistic();

    this.entries.forEach(entry => {
      const existingTypeIndex
        = statistic.amountPerType.findIndex(a => a.key === entry.partNumber);

      let typeStatistic: KeyValue<string, number>;
      if (existingTypeIndex !== -1) {
        typeStatistic = statistic.amountPerType[existingTypeIndex];
        // tslint:disable-next-line:radix
        typeStatistic.value = typeStatistic.value + parseInt(entry.targetAmount);

      } else {
        // tslint:disable-next-line:radix
        typeStatistic = {key: entry.partNumber, value: parseInt(entry.targetAmount) };
        statistic.amountPerType.push(typeStatistic);
      }

      const existingDayAndShiftIndex
        = statistic.amountPerDayAndShift.findIndex(a => a.key === entry.weekDay + '_' + entry.shift);

      let dayAndShifttatistic: KeyValue<string, number>;
      if (existingDayAndShiftIndex !== -1) {
        dayAndShifttatistic = statistic.amountPerDayAndShift[existingDayAndShiftIndex];
        // tslint:disable-next-line:radix
        dayAndShifttatistic.value = dayAndShifttatistic.value + parseInt(entry.targetAmount);

      } else {
        // tslint:disable-next-line:radix
        dayAndShifttatistic = {key: entry.weekDay + '_' + entry.shift, value: parseInt(entry.targetAmount) };
        statistic.amountPerDayAndShift.push(dayAndShifttatistic);
      }
    });

    this._statistics = statistic
    return this._statistics;
  }

  private _statistics: BuildProgramStatistic;
  private _week = new Array<BuildProgramDay>();
  private _calendarWeek: string;

  constructor(costCenter: string, calendarWeek: string, year: string) {
    this.costCenter = costCenter;
    this.calendarWeek = calendarWeek;
    this.year = year;

    this._week.push(new BuildProgramDay(WeekDay.MONDAY, this.getDate(WeekDay.MONDAY)));
    this._week.push(new BuildProgramDay(WeekDay.TUESDAY, this.getDate(WeekDay.TUESDAY)));
    this._week.push(new BuildProgramDay(WeekDay.WEDNESDAY, this.getDate(WeekDay.WEDNESDAY)));
    this._week.push(new BuildProgramDay(WeekDay.THURSDAY, this.getDate(WeekDay.THURSDAY)));
    this._week.push(new BuildProgramDay(WeekDay.FRIDAY, this.getDate(WeekDay.FRIDAY)));
    this._week.push(new BuildProgramDay(WeekDay.SATURDAY, this.getDate(WeekDay.SATURDAY)));
    this._week.push(new BuildProgramDay(WeekDay.SUNDAY, this.getDate(WeekDay.SUNDAY)));
  }

  private getDate(weekDay: WeekDay): moment.Moment {
    const year = parseInt(this.year);
    const week = parseInt(this.calendarWeek);
    const day = weekDay.toString().toLowerCase();

    const currentDate = moment().year(year).isoWeek(week).isoWeekday(day);
    return currentDate;
  }

  public addEntries(entries: BuildProgramEntry[]) {

  }

  public addEntry(entry: BuildProgramEntry) {

  }

  public dayEntries(day: WeekDay): Array<BuildProgramEntry> {
    const entries =  this.entries.filter(e => e.weekDay === day);

    entries.sort((a, b) => {
      // tslint:disable-next-line:radix
      const aPositionAsInt = parseInt(a.position);
      // tslint:disable-next-line:radix
      const bPositionAsInt = parseInt(b.position);

      if (aPositionAsInt > bPositionAsInt) {
        return 1;
      }

      if (aPositionAsInt < bPositionAsInt) {
        return -1;
      }

      return 0;
    });

    return entries;
  }

  public shiftEntries(day: WeekDay, shift: Shift.WorkShiftEnum): Array<BuildProgramEntry> {
    const entries =  this.entries.filter(e => e.weekDay === day && e.shift === shift);

    entries.sort((a, b) => {
      // tslint:disable-next-line:radix
      const aPositionAsInt = parseInt(a.position);
      // tslint:disable-next-line:radix
      const bPositionAsInt = parseInt(b.position);

      if (aPositionAsInt > bPositionAsInt) {
        return 1;
      }

      if (aPositionAsInt < bPositionAsInt) {
        return -1;
      }

      return 0;
    });

    return entries;
  }
}

export class BuildProgramEntry {
  public costCenter: string;
  public calendarWeek: string;
  public weekDay: string;
  public shift: string;

  public set position(value: string) {
    this._position = value;
  }

  public get position(): string {
    return this._position?.padStart(3, '0');
  }

  public partNumber: string;
  public targetAmount: string;

  public get targetAmountFixed(): string {
    return this.targetAmount?.padStart(3, '0');
  }

  public year: string;
  public modifiedAt: string;
  public modifiedBy: string;

  public sourceObject?: BuildProgramResponse;

  private _position: string;

  constructor(response?: BuildProgramResponse) {
    if (response) {
      this.costCenter = response.costCenter;
      this.calendarWeek = response.calendarWeek;
      this.weekDay = response.dayOfWeek;
      this.shift = response.shiftIdentifier;
      this.position = response.programPosition;
      this.partNumber = response.productTypeIdentifier;
      this.targetAmount = response.targetAmount;
      this.year = response.year;

      this.modifiedBy = response.modifiedBy;
      this.modifiedAt = response.modifiedAt;

      this.sourceObject = response;
    }
  }

  public getBuildProgramCreateRequest(): BuildProgramCreateRequest {
    return {
      costCenter: this.costCenter,
      dayOfWeek: this.weekDay,
      calendarWeek: this.calendarWeek,
      shiftIdentifier: this.shift,
      programPosition: this.position,
      productTypeIdentifier: this.partNumber,
      targetAmount: this.targetAmount,
      year: this.year
    }
  }
}


export class BuildProgramDay {
  public day: WeekDay;
  public currentDate: moment.Moment;
  public calculatedAmount: number;

  public get calculatedAmountFixedLength(): string {
    return this.calculatedAmount?.toString().padStart(3,'0');
  }

  public listOfProductTypes = new Set<string>();
  public activePositions: number;
  public expanded = false;
  public alreadyAddedPositions = new Array<string>();

  constructor(day: WeekDay, currentDate: moment.Moment) {
    this.day = day;
    this.calculatedAmount = 0;
    this.activePositions = 0;
    this.currentDate = currentDate;
  }

  public expand() {
    if (this.calculatedAmount > 0) {
      this.expanded = true;
    }

  }

  public collapse() {
    this.expanded = false;
  }
}

export class BuildProgramStatistic {
  public amountPerType = new Array<KeyValue<string, number>>();
  public amountPerDayAndShift = new Array<KeyValue<string, number>>();
}

export enum BuildProgramStatus {
  UNKNOWN = 'Unknown',
  DONE = 'Done',
  CURRENT = 'Current',
  UPCOMING = 'Upcoming'
}
