export class FileMetaData {
  OriginalName?: string;
  Source?: string;
  Origin?: string;
  Uploaded?: string;
  Size: string;
  Id: string;
  ComponentType: string;
  CostCenter: string;
}
