import {Injectable} from '@angular/core';
import {LoggingService} from '../../../core/logging/logging.service';
import {EngineFilter} from '../../../modules/engine/list/enginefilter';
import {FilterBase} from '../../models/filterBase';
import {PartFilter} from '../../services/backend/models/filter/partfilter';
import {firstValueFrom} from 'rxjs';
import {
  ToolCorrectionFilter
} from 'src/app/modules/actions/toolcorrection/components/list/models/toolcorrectionfilter';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {
  EngineState,
  ExportQMSPartListV3RequestParams,
  PartFilterCriteriaV3,
  ReportGeneratorService,
  SZOnlineEngineQuickTimeSelector
} from "@cstx/volkswagen-mqs-report-generator-service-client";
import {
  EngineFilterCriteriaV3
} from "@cstx/volkswagen-mqs-report-generator-service-client/model/engineFilterCriteriaV3";
import {QuickTimeSelector as FilterEngineQTS} from '@cstx/volkswagen-mqs-engine-service-client';
import {QuickTimeSelector as FilterPartQTS} from '@cstx/volkswagen-mqs-part-service-client';


@Injectable({
  providedIn: 'root'
})
export class ReportService {


  constructor(private reportController: ReportGeneratorService) {
  }

  public requestReport(filterBase: FilterBase): Promise<any> {
    if (filterBase instanceof EngineFilter) {
     return this.generateEngineReport(filterBase);
    } else if (filterBase instanceof PartFilter) {
     return this.generatePartReport(filterBase);
    }
    else if (filterBase instanceof ToolCorrectionFilter) {
      return this.generateToolCorrectionReport(filterBase);
     }
    return Promise.reject({error: 'not applicable filter' });
  }

  public requestFilterReport(filterId: string, title: string, description: string): Promise<void> {
    return this.generateQMSReport(filterId, title, description);
  }

  private generatePartReport(filterBase: PartFilter): Promise<void> {

  const partFilterCriteriaV3: PartFilterCriteriaV3 ={
      parentId: filterBase.parentId,
      manufacturerCodeList: filterBase.manufacturerCode,
      partNumberList: filterBase.partNumber,
      partType: filterBase.partType,
      dmc: filterBase.dmc,
      costCenterList: filterBase.costCenter,
      productionLine: null,
      sequenceNumberFrom: filterBase.sequenceNumberFrom,
      sequenceNumberTo: filterBase.sequenceNumberTo,
      productionDateFrom: filterBase.productionDateFrom,
      productionDateTo: filterBase.productionDateTo,
      partStatus:  filterBase.getMappedPartState(),
      partInstallationStatus: null,
      modifiedAtFrom: null,
      modifiedAtTo: null,
      buildDateFrom:null,
      buildDateTo:null,
      partBlockedState: filterBase.partBlockedState,
      humanreadableId: null,
      productionDateQuickTimeSelector: this.mapQuicktimePart(filterBase.quickTimeSelector),
    }
    const exportQMSPartListV3RequestParams :ExportQMSPartListV3RequestParams= {
      partReportCreateRequestV3:{
        title: '',
        timezone: '',
        outputType: 'Excel',
        partFilterCriteriaV3
      }
    }

    return new Promise((resolve, reject) => {
      firstValueFrom(this.reportController.exportQMSPartListV3(
        exportQMSPartListV3RequestParams,'response'))
      .then(() => {
        LoggingService.logDebug(LoggingSource.REPORT_SERVICE, 'Successful request part list report file');
        resolve();
      })
      .catch((reason) => {
        LoggingService.logError(LoggingSource.REPORT_SERVICE, 'Error occurred when downloading report file', reason);
        reject(reason);
      });
    });
  }

  private generateEngineReport(filterBase: EngineFilter): Promise<void> {
    const param : EngineFilterCriteriaV3 ={
      componentName: filterBase.componentName,
      timingBeltLabel: filterBase.timingBeltLabel,
      engineState: EngineState[filterBase.engineState],
      engineCodeList:  filterBase.engineCode,
      engineNumberList:  filterBase.engineNumber,
      engineNumberFrom:  filterBase.engineNumberFrom,
      engineNumberTo:  filterBase.engineNumberTo,
      partNumberList:  filterBase.partNumber,
      developmentNumber:  filterBase.developmentNumber,
      lotNumber:  null,
      orderNumber:  filterBase.orderNumber,
      keyCodeList:  filterBase.keyCode,
      costCenterList:  filterBase.costCenter,
      buildDateFrom:  filterBase.buildDateFrom,
      buildDateTo:  filterBase.buildDateTo,
      engineIds: filterBase.engineIds,
      engineBlockedState: filterBase.engineBlockedState,
      humanreadableId: filterBase.humanReadableId,
      quickTimeSelector:this.mapQuicktimeEngine(filterBase.quickTimeSelector),
      engineStateList: null,
      engineCodeFrom:  null,
      engineCodeTo:  null,
    }

    return new Promise(((resolve, reject) => {
      firstValueFrom(this.reportController.exportQMSEngineListV3({
        engineReportCreateRequestV3: {
          title: '',
          timezone: '',
          outputType: 'Excel',
          engineFilterCriteriaV3: param
        }
      }, 'response'))
        .then(() => {
          LoggingService.logDebug(LoggingSource.REPORT_SERVICE, 'Successful request engine list report file');
          resolve();
        })
        .catch((reason) => {
          LoggingService.logError(LoggingSource.REPORT_SERVICE, 'Error occurred when downloading report file', reason);
          reject(reason);
        });
    }));
  }

  private mapQuicktimeEngine(e: FilterEngineQTS): SZOnlineEngineQuickTimeSelector{
    switch (e) {
      case "lastHour":
        return "LastHour"
      case "lastNinetyDays":
        return "LastNinetyDays"
      case "lastSevenDays":
        return "LastSevenDays"
      case "lastThirtyDays":
        return "LastThirtyDays"
      case "lastTwelveHours":
        return "LastTwelveHours"
      case "lastTwentyFourHours":
        return "LastTwentyFourHours"
      case "olderThenNinetyDays":
        return "OlderThenNinetyDays"
      case "olderThenOneHundredEightyDays":
        return "OlderThenOneHundredEightyDays"
      case "olderThenOneHundredFiftyDays":
        return "OlderThenOneHundredFiftyDays"
      case "olderThenOneHundredTwentyDays":
        return "OlderThenOneHundredTwentyDays"
      case "olderThenSixtyDays":
        return "OlderThenSixtyDays"
      case "olderThenThirtyDays":
        return "OlderThenThirtyDays"
      case "olderThenThreeHundredSixtyDays":
        return "OlderThenThreeHundredSixtyDays"
      case "thisDay":
        return "ThisDay"
      case "thisMonth":
        return "ThisMonth"
      case "thisWeek":
        return "ThisWeek"
      default:
        return null
    }
  }

  private mapQuicktimePart(e: FilterPartQTS): SZOnlineEngineQuickTimeSelector{
    switch (e) {
      case "lastHour":
        return "LastHour"
      case "lastNinetyDays":
        return "LastNinetyDays"
      case "lastSevenDays":
        return "LastSevenDays"
      case "lastThirtyDays":
        return "LastThirtyDays"
      case "lastTwelveHours":
        return "LastTwelveHours"
      case "lastTwentyFourHours":
        return "LastTwentyFourHours"
      case "olderThenNinetyDays":
        return "OlderThenNinetyDays"
      case "olderThenOneHundredEightyDays":
        return "OlderThenOneHundredEightyDays"
      case "olderThenOneHundredFiftyDays":
        return "OlderThenOneHundredFiftyDays"
      case "olderThenOneHundredTwentyDays":
        return "OlderThenOneHundredTwentyDays"
      case "olderThenSixtyDays":
        return "OlderThenSixtyDays"
      case "olderThenThirtyDays":
        return "OlderThenThirtyDays"
      case "olderThenThreeHundredSixtyDays":
        return "OlderThenThreeHundredSixtyDays"
      case "thisDay":
        return "ThisDay"
      case "thisMonth":
        return "ThisMonth"
      case "thisWeek":
        return "ThisWeek"
      default:
        return null
    }
  }

  private generateToolCorrectionReport(filterBase: ToolCorrectionFilter): Promise<void> {
    return new Promise(((resolve, reject) => {
      firstValueFrom(this.reportController.exportQMSToolCorrectionList({
        toolCorrectionReportCreateRequest: {
          title: '',
          timezone: '',
          outputType: 'Excel',
          toolCorrectionFilterCriteria: filterBase.getFilterParams()
        }
      }, 'response'))
        .then(() => {
          LoggingService.logDebug(LoggingSource.REPORT_SERVICE, 'Successful request toolcorrection list report file');
          resolve();
        })
        .catch((reason) => {
          LoggingService.logError(LoggingSource.REPORT_SERVICE, 'Error occurred when downloading report file', reason);
          reject(reason);
        });
    }));
  }

  private generateQMSReport(filterId: string, title: string, description: string): Promise<void> {
    return new Promise(((resolve, reject) => {
      firstValueFrom(this.reportController.exportQMSFilterResults({
        exportQMSFilterResultsCreateRequest: {
          filterId,
          title,
          outputType: 'Excel',
        }
      })).then(response => {
        LoggingService.logDebug(LoggingSource.REPORT_SERVICE, 'GenerateQMSReport successfully done.');
        resolve();
      })
        .catch((reason) => {
          LoggingService.logError(LoggingSource.REPORT_SERVICE, 'GenerateQMSReport failed.', reason);
          reject(reason)
        });
    }));
  }
}
