import {EventEmitter, Injectable} from '@angular/core';
import {BlockingAction} from '../../services/backend/models/blockingAction';
import {ComponentBlock} from './component-block';
import {QualityManagementService} from '../../services/backend/quality-management.service';
import {BlockingActionFilter} from '../../../modules/actions/models/blockingactionsfilter';
import {BlockingActionState} from '@cstx/volkswagen-mqs-quality-management-service-client';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {LoggingService} from '../../../core/logging/logging.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentBlockWizardService {
  public static listOfExtendableBlockingActions = new Array<BlockingAction>();
  public static componentBlock = new ComponentBlock()

  public static onBlockAbort = new EventEmitter<any>();
  public static onBlockFinished= new EventEmitter<any>();

  constructor() { }

  public static addComponentToBlock(componentName: string) {
    LoggingService.logDebug(LoggingSource.COMPONENT_BLOCK_WIZARD_SERVICE,
      `Adding [${componentName}] to components prepared to be blocked.`);

    ComponentBlockWizardService.componentBlock.componentNames.add(componentName);

    LoggingService.logDebug(LoggingSource.COMPONENT_BLOCK_WIZARD_SERVICE,
      `Currently [${ComponentBlockWizardService.componentBlock.componentNames.size}] components are prepared to be blocked.`);
  }

  public static removeComponent(componentName: string)
  {
    LoggingService.logDebug(LoggingSource.COMPONENT_BLOCK_WIZARD_SERVICE,
      `Removing [${componentName}] to components prepared to be blocked.`);

    ComponentBlockWizardService.componentBlock.componentNames.delete(componentName);

    LoggingService.logDebug(LoggingSource.COMPONENT_BLOCK_WIZARD_SERVICE,
      `Currently [${ComponentBlockWizardService.componentBlock.componentNames.size}] components are prepared to be blocked.`);
  }

  public static reset() {
    LoggingService.logDebug(LoggingSource.COMPONENT_BLOCK_WIZARD_SERVICE,
      `Resetting service.`);

    ComponentBlockWizardService.listOfExtendableBlockingActions = new Array<BlockingAction>();
    ComponentBlockWizardService.componentBlock = new ComponentBlock();
  }

  /**
   * TODO: Select only extendable BlockingActions but respect paging !!! Needs to be refactored - but works for now
   * @param qualityManagementService
   */
  public static async getExtendableBlockingActions(qualityManagementService: QualityManagementService): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const filter = new BlockingActionFilter();
      filter.blockingActionState = BlockingActionState.Blocked;
      filter.pagingSize = 250;

      qualityManagementService.getBlockingActionsV2(filter)
        .then(result => {
          ComponentBlockWizardService.listOfExtendableBlockingActions = result.content.map(response => new BlockingAction(response));
          resolve(true);
        }).catch(() => {
          resolve(false);
      });
    });
  }

  public static getBlockingActionFromList(blockingActionNr: string) {
    const existingIndex = ComponentBlockWizardService.listOfExtendableBlockingActions
      .findIndex(action => action.blockingActionNr === blockingActionNr);

    if (existingIndex === -1) {
      return null;
    }

    return ComponentBlockWizardService.listOfExtendableBlockingActions[existingIndex];
  }
}
