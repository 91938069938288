import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {OAuthService} from 'angular-oauth2-oidc';
import {Router} from '@angular/router';
import {LoggingService} from '../logging/logging.service';
import {LoggingSource} from '../logging/loggingSource';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;

  constructor(private oauthService: OAuthService, private router: Router) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
    .pipe(
        catchError((response: HttpResponse<any>) => {
          if (response && response.status === 401) {
            if (this.refreshTokenInProgress) {
              // If refreshTokenInProgress is true, we will wait until token is silently refreshed
              // which means the new token is ready and we can retry the request
              return this.oauthService.events.pipe(
                  filter(result => result.type === 'silently_refreshed'),
                  take(1),
                  switchMap(() => next.handle(this.getAuthRequest(req)))
              );
            } else {
              this.refreshTokenInProgress = true;

              return from(this.oauthService.silentRefresh())
              .pipe(
                  switchMap(() => next.handle(this.getAuthRequest(req))),
                  catchError(error => {
                    this.initImplicitFlow();
                    return throwError(error);
                  }),
                  // When the call to silentRefresh completes we reset the refreshTokenInProgress to false
                  // for the next time the token needs to be refreshed
                  finalize(() => this.refreshTokenInProgress = false)
              );
            }
          } else if (response && response.status === 403) {
            LoggingService.logError(LoggingSource.ROLE_MANAGEMENT,
              'Missing permission for '+ response.url + '. Access from ' + window.location.toString());

            /*
              // TODO: Is this a good solution?
              this.router.navigate(['home', 'forbidden']);

             */

            }/* else if (response && response.status === 404) {
            LoggingService.logError(LoggingSource.OTHER, 'Could not find resource '+ response.url);
          }*/

          return throwError(response);
        })
    );
  }

  private getAuthRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.oauthService.getAccessToken()
      }
    });
  }

  private initImplicitFlow(): void {
    const encodedUri = encodeURIComponent(this.router.url);
    this.oauthService.initImplicitFlow(encodedUri);
  }
}
