import {TokenData} from './token';

export class User {
  constructor(tokenData: TokenData, isApplication?: boolean) {
    this.email = tokenData.email;
    this.name = tokenData.name;
    this.username = tokenData.preferredUsername;
    this.applicationUser = isApplication;
  }

  public name: string;
  public email: string;
  public username: string;
  public applicationUser: boolean;
}
