  <div class="d-flex">
      <div class="col pt-3">
        <div class="row">
          <div class="col">
            <h4 class="d-inline">{{ entry.title }}</h4>
            <div class="pl-1 d-inline small align-top" *ngIf="entry.bigScreenArea !== 'None'">
            <op-component-state-badge [tooltip]="bigscreenAreaTooltip" [name]="entry.bigScreenArea"></op-component-state-badge>
            </div>
          </div>
          <div class="col-2" >
            <i *ngIf="entry.showGlobal" class="fas fa-globe fa-2x" style="color:darkgreen;cursor: pointer" [tooltip]="globalToolTip"></i>
            <i *ngIf="entry.costCenters.length >= 1 && !entry.showGlobal" class="fas fa-filter fa-2x" style="color:darkblue; cursor: pointer" [tooltip]="filteredToolTip"></i>
          </div>
        </div>
          <div class="row">
          <div class="col pt-4 pb-4" style="height: max-content;">
            <div [innerHTML]="entry.description"></div>
          </div>
        </div>
        <ng-container *ngIf="entry.informationType !== 'STOPNOTIFICATION' && entry.fileList?.length >= 1">
          <div class="row" *ngFor="let file of entry.fileList">
            <a [href]="getDirectLink(file.Token, file.s3Key)" download>
            <div class="col">
              <i class="fas fa-file"></i> {{ encode(file.OriginalName) }}
            </div>
            </a>
          </div>
        </ng-container>

        <ng-container *ngIf="entry.informationType === 'STOPNOTIFICATION'">
          <div class="row">
              <div class="col" (click)="makePdf(entry)" style="cursor: pointer">
                <i class="fas fa-download"></i> Stoppschild herunterladen
              </div>
          </div>
        </ng-container>



        <ng-container *ngIf="devMode">
          <ng-container *ngFor="let cc of entry.costCenters">
            <div class="row pt-1 pb-1" *ngFor="let ws of entry.workSequences">
              <div class="col">
                <a [href]="'home/info/show?kioskMode=true&costCenter=' + cc + '&workSequence=' + ws " target="_blank">Neuigkeiten {{ cc }} - {{ ws }}</a>
                <br>
                <a [href]="'home/info/employeeInfo?kioskMode=true&costCenter=' + cc + '&workSequence=' + ws " target="_blank">MitarbeiterInfo {{ cc }} - {{ ws }}</a>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <div class="row pt-1">
          <div class="col">
            <op-datetime-show [dateTime]="entry.modifiedAt" ></op-datetime-show>

          </div>
          <div class="col-2">
            {{ entry.createdBy  }}
          </div>
        </div>

      </div>
      <div class="col-4 pt-2 text-right">
        <i *ngIf="entry.informationType === 'NEWS'" class="fas fa-newspaper fa-4x" style="color: darkgray;" [tooltip]="infoTypeToolTip"></i>
        <i *ngIf="entry.informationType === 'ALERT'" class="fas fa-bell fa-4x" style="color: darkred;" [tooltip]="infoTypeToolTip"></i>
        <i *ngIf="entry.informationType === 'STOPNOTIFICATION'" class="fas fa-ban fa-4x" style="color: lightcoral;" [tooltip]="infoTypeToolTip"></i>
        <i *ngIf="entry.informationType === 'EMPLOYEEINFO'" class="fas fa-user fa-4x" style="color: lightskyblue;" [tooltip]="infoTypeToolTip"></i>
        <i *ngIf="entry.informationType === 'SUPPORTREQUEST'" class="fas fa-headset fa-4x" style="color: darkorange;" [tooltip]="infoTypeToolTip"></i>
        <i *ngIf="entry.informationType === 'WORKPLAN'" class="fas fa-toolbox fa-4x" style="color: darkgreen;" [tooltip]="infoTypeToolTip"></i>
      </div>
    </div>



  <ng-template #bigscreenAreaTooltip>
    Diese Info wird<br>
    in der Großbilandzeige im folgenden Bereich dargestellt: {{ entry.bigScreenArea }}.
  </ng-template>

  <ng-template #infoTypeToolTip>
    Dies ist ein Inforation vom Typ {{ entry.informationType }}.
  </ng-template>

  <ng-template #globalToolTip>
    Diese Info wird global angezeigt!
  </ng-template>

    <ng-template #filteredToolTip>
      Diese Info wird nur für folgende Kostenstellen bzw. Arbeitsfolgen angezeigt: <br>
      <ng-container *ngFor="let c of entry.costCenters">
        {{ c }} <br>
      </ng-container>
      <ng-container *ngFor="let w of entry.workSequences">
      {{ w }} <br>
      </ng-container>
    </ng-template>



