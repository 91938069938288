<ng-container *ngIf="EnterpriseRoleProviderService.hasRequiredClaimByName('news-show', false)">
<div [class.cpp-page-defaults]="!KioskModeService.kioskMode" class="container-fluid"
     style="min-height: 100%">

  <div class="row pl-4 pt-4">
    <div class="col" style="vertical-align: middle;display: inherit;">
      <i class="fas fa-info-circle fa-fw" style="padding-top: 6px; font-size: 2em;"></i>
      <span class="text-overflow-ellipses pl-3 pre-white" style="font-size: 1.8em; font-weight: 800">{{ 'navbar.dropdown.info' | translate }}
        <op-component-state-badge class="align-top" style="font-size: 0.45em" [name]="costCenter" [customBadgeClass]="'badge-success'"></op-component-state-badge>
        <op-component-state-badge class="align-top pl-1" style="font-size: 0.45em" [name]="workSequence" [customBadgeClass]="'badge-warning'"></op-component-state-badge>
      </span>




    </div>
    <ng-container *ngIf="previewMode">
      <div class="col text-right pr-3">
        <div style="font-size: 2em; font-weight: bolder; color:#dc3545;">VORSCHAU</div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="infos.length > 0; else noInfosTemplate">
  <div class="row mr-0 border-top pb-3" style="margin-left: 0" *ngFor="let info of infos">
    <div class="col">
      <op-show-entry [entry]="info"></op-show-entry>
    </div>
  </div>
  </ng-container>
</div>
</ng-container>

<ng-template #noInfosTemplate>
  <div class="row border-top pb-3 pt-5 pl-5">

    <div class="col pl-5">
      <h2>{{'global.nothing-to-show' | translate }}</h2>
    </div>
  </div>
</ng-template>
