<div>
  <div class="row p-0">
    <div class="pl-1" [ngClass]="isModal ? 'col-sm-12' : 'col-sm-8'">
      <table class="table table-borderless w-auto pl-0">
        <tbody>
        <tr>
          <td class="footer-header-col">
            Id:
          </td>
          <td class="footer-value-col overflow-hidden text-overflow-ellipses">
            {{ componentElement.id ? componentElement.id : componentElement.Id}}
          </td>
        </tr>
        <tr>
          <td class="footer-header-col">
            {{ 'modal.footer.created' | translate }}:
          </td>
          <td class="footer-value-col">
            {{ (componentElement.createdAt ? componentElement.createdAt : componentElement.CreatedAt) | date:'dd.MM.yyyy HH:mm:ss'  }}
          </td>
          <td class="footer-header-col">
            {{ 'modal.footer.creator' | translate }}:
          </td>
          <td class="footer-value-col">
            {{ componentElement.createdBy ? componentElement.createdBy : componentElement.CreatedBy }}
          </td>
        </tr>
        <tr>
          <td class="footer-header-col">
            {{ 'modal.footer.modified' | translate }}:
          </td>
          <td class="footer-value-col">
            {{ (componentElement.modifiedAt ? componentElement.modifiedAt :  componentElement.ModifiedAt) | date:'dd.MM.yyyy HH:mm:ss'  }}
          </td>
          <td class="footer-header-col">
            {{ 'modal.footer.modifier' | translate }}:
          </td>
          <td class="footer-value-col">
            {{ componentElement.modifiedBy ? componentElement.modifiedBy : componentElement.ModifiedBy}}
          </td>
        </tr>
        <tr>
          <ng-container *ngIf="componentElement.source">
            <td class="footer-header-col">
              {{ 'modal.footer.source' | translate }}:
            </td>
            <td class="footer-value-col">
              {{ componentElement.source }}
            </td>
          </ng-container>
          <ng-container *ngIf="componentElement.creationType">
            <td class="footer-header-col">
              {{ 'modal.footer.type' | translate }}:
            </td>
            <td class="footer-value-col">
              {{ componentElement.creationType }}
            </td>
          </ng-container>
        </tr>
        </tbody>
      </table>
    </div>
    <ng-container *ngIf="!isModal">
      <div class="col-sm-2">
        <table class="table table-borderless">
          <tbody>
          <tr>
            <td class="text-right p-0">
              <img src="/assets/img/internalEN_DE_dark.svg" alt="" class="d-inline-block align-top">
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-2 text-right">
        <table class="table table-borderless">
          <tbody>
          <tr>
            <td class="text-right p-0">
              <a href="#"><img src="/assets/img/logo_black.png" alt="" class="d-inline-block align-top" width="40"
                               height="40"></a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>


