import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {EffectiveUserClaim, EnterpriseRoleProviderService} from '../../core/services/enterprise-role-provider.service';

@Directive({
  selector: '[opRequiredRole]'
})
export class RequiredRoleDirective implements OnInit{
  @Input('opRequiredRole') role: string;

  public isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {}

  public ngOnInit() {
    EnterpriseRoleProviderService.onPermissionsLoaded.subscribe(() => {
      this.evaluateRole();
    })

    this.evaluateRole();
  }

  private evaluateRole() {
    if (EnterpriseRoleProviderService.effectiveEnterpriseRoles?.length < 1) {
      this.viewContainerRef.clear();
    }

    if (EnterpriseRoleProviderService.hasRequiredRole(this.role)) {
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.isVisible = false;
      this.viewContainerRef.clear();
    }
  }
}
