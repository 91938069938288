<div class="container-fluid class.cpp-page-defaults">
  <div class="row h-75">
    <div class="col d-flex flex-column align-items-center justify-content-center pt-5" *ngIf="EnterpriseRoleProviderService.lastClaimChecked">


      Sie versuchen auf <br>
      <b>{{ currentRoute }}</b>
      zuzugreifen.
      <br>
      <br>

      <i class="fas fa-lock fa-5x pb-3" style="color: #4B4453"></i>
        Leider fehlt ihnen Berechtigung
            <b>{{ EnterpriseRoleProviderService.lastClaimChecked.functionalResource }}
              [{{ EnterpriseRoleProviderService.lastClaimChecked.writePermission ? 'Write' : 'Read' }}]
            </b> um diesen Bereich zu öffnen!
    </div>

    <div class="col d-flex flex-column align-items-center justify-content-center pt-5" *ngIf="!EnterpriseRoleProviderService.lastClaimChecked">
      <i class="fas fa-lock fa-5x pb-3" style="color: #4B4453"></i>
      Ein Fehler im Portal ist aufgetreten. Die notwendigen Rechte für
      <b>{{ EnterpriseRoleProviderService.lastClaimNameRequested }}
      </b> konnen nicht ermittelt werden.
    </div>
  </div>

  <ng-container *ngIf="EnterpriseRoleProviderService.effectiveEnterpriseRoles && EnterpriseRoleProviderService.effectiveEnterpriseRoles.length > 0; else noRolesTemplate">
<div class="small">
  <div class="row pt-3">
    <div class="col d-flex flex-column align-items-center justify-content-center font-weight-bold">
      Effektive Rollen
    </div>
  </div>
  <div class="row" *ngFor="let effectiveRole of EnterpriseRoleProviderService.effectiveEnterpriseRoles">
    <div class="col d-flex flex-column align-items-center justify-content-center">
      {{ effectiveRole }}
    </div>
  </div>
  <div class="row pt-3">
    <div class="col d-flex flex-column align-items-center justify-content-center font-weight-bold">
      Effektive Rechte
    </div>
  </div>
  <div class="row" *ngFor="let effectiveUserClaim of EnterpriseRoleProviderService.effectiveUserClaims">
    <div class="col d-flex flex-column align-items-center justify-content-center">
      {{ effectiveUserClaim.functionalResource }} [{{effectiveUserClaim.writePermission ? 'Write' : 'Read' }}]
    </div>
  </div>
</div>
  </ng-container>
</div>


<ng-template #noRolesTemplate>
  <div class="small">
    <div class="row pt-3">
      <div class="col d-flex flex-column align-items-center justify-content-center font-weight-bold">
        Keine Rollen und Rechte vorhanden!
      </div>
    </div>
    <div class="row">
      <div class="col d-flex flex-column align-items-center justify-content-center">
        Ihr Benutzer verfügt über keine Rollen und Rechte. Arbeiten im System ist so nicht möglich.
      </div>
    </div>

  </div>
</ng-template>
