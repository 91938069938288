import {AfterViewInit, Component, EventEmitter, Input, isDevMode, Output} from '@angular/core';
import {FileUpload} from '../fileUpload';
import {FileExplorerService} from '../file-explorer.service';
import {Subject} from 'rxjs';
import {ErrorHandler} from '../../../services/error-handler/error-handler';

@Component({
  selector: 'op-usecase-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements AfterViewInit {
  @Input() onShowUploadOverlay = new Subject<boolean>();
  @Input() useCase = 'none';
  @Input() usecasePrefix: string;
  @Output() uploadRequestEndEvent = new EventEmitter<boolean>();

  public uploading = false;
  public showUploadOverlay = false;

  constructor(private fileExplorerService: FileExplorerService) { }


  ngAfterViewInit(): void {
    this.onShowUploadOverlay.subscribe(next => {
      this.showUploadOverlay = next;
    });
  }

  private uploadMultiple(uploadRequestList: FileUpload[]){
    Promise.all(uploadRequestList.map(uploadRequest => this.fileExplorerService.UploadToUseCase(uploadRequest, this.useCase)))
    .then(_ => {
      if (isDevMode()) {
        console.log(new Date().toLocaleString() + ': Upload finished successfully for file [' + uploadRequestList.map(x=> x.fileName) + '] and useCase [' + this.useCase + ']');
      }
    })
    .catch(error => {
      ErrorHandler.printError(error, uploadRequestList);
    })
    .finally(() => {
      this.uploading = false;
      this.uploadRequestEndEvent.emit(true);
    });

  }

  public onUploadRequested(event: any) {
    if (event) {
      let  filesToUpload: FileList;
      filesToUpload = event.target.files;

      if (filesToUpload.length === 0) {
        return;
      }

      const fileCount = filesToUpload.length;
      const mappedFileList: FileUpload[] = []
      for (let i = 1; i <= fileCount; i++) {
        mappedFileList.push(this.mapToUploadRequest(filesToUpload[i - 1]));
      }
      this.uploadMultiple(mappedFileList)
    } else {
      return;
    }
  }

  public onDrop(event: any) {
    event.preventDefault();
    const fileCount = event.dataTransfer.items.length;
    const mappedFileList: FileUpload[] = []
    for (let i = 1; i <= fileCount; i++ ) {
      let fileToUpload = File;
      fileToUpload = event.dataTransfer.items[i - 1].getAsFile();

      mappedFileList.push(this.mapToUploadRequest(fileToUpload));
    }
    this.uploadMultiple(mappedFileList);
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  public onUploadAbort() {
    this.uploadRequestEndEvent.emit(false);
  }

  private mapToUploadRequest(fileToUpload: any): FileUpload {
    const uR = new FileUpload();

    uR.file = fileToUpload;
    uR.fileName = fileToUpload.name;
    uR.source = 'Portal';

    if (this.usecasePrefix) {
      uR.s3Key  =  this.usecasePrefix + '/' + uR.fileName;
    } else {
      uR.s3Key  =  uR.fileName;
    }


    return uR;
  }

}
