import {Component, Input, OnInit} from '@angular/core';
import {TelegramResponse, TelegramService} from '@cstx/volkswagen-mqs-telegram-receiver-service-client';
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";

@Component({
  selector: 'op-telegram-list',
  templateUrl: './telegram-list.component.html',
  styleUrls: ['./telegram-list.component.scss']
})
export class TelegramListComponent implements OnInit {
  @Input() componentName: string;
  @Input() selfLink: string;
  @Input() contentContainerId: string;

  telegramList = new Array<TelegramResponse>();
  loading = false;
  viewSizeSM: boolean;
  viewSizeXS: boolean;


  constructor(private breakpointObserver: BreakpointObserver,
              public telegramReceiverService: TelegramService) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.viewSizeSM = true;
        } else {
          this.viewSizeSM = false;
        }
      });

    this.breakpointObserver
      .observe(['(max-width: 576px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.viewSizeXS = true;
        } else {
          this.viewSizeXS = false;
        }
      });

    this.GetTelegramList(this.componentName);
  }


  public GetTelegramList(componentName: string) {
    this.telegramReceiverService.getTelegrams({
      componentName,
      size: 250
    }).toPromise()
      .then(_ => {
        this.telegramList = _.content.filter(o => o.stopTime !== '0001-01-01T01:01:01.0000000Z');
      });
  }
}
