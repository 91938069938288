import {Injectable} from '@angular/core';
import {TelegramResponse, TelegramResponsePageResponse, TelegramService} from '@cstx/volkswagen-mqs-telegram-receiver-service-client';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {TelegramFilter} from '../../../modules/admin/telegram/telegramFilter';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TelegramReceiverService {

  constructor(private telegramReceiverControllerService: TelegramService) { }

  /**
   * This method returns the latest. telegrams for a given workSequence and cosCenter.
   * It is a wrapper around getTelegrams.
   * @param workSequenceName
   * @param costCenter
   * @returns Promise<TelegramResponse[]>
   */

  public async getTelegramsByCostCenterAndWorkSequenceDescending(costCenter: string, workSequenceName: string, maxItems: number = 40): Promise<TelegramResponse[]> {
    let result = new Array<TelegramResponse>();

    try {
      const filter = new TelegramFilter();
      filter.costCenter = costCenter;
      filter.workSequence = workSequenceName;

      const response = await this.getTelegrams(filter);
      if (response && response.content) {
        result = response.content.slice(0,maxItems);
      }

    } catch (error) {
      LoggingService.logError(LoggingSource.TELEGRAM_RECEIVER_SERVICE, 'Error occurred trying to get telegrams from telegram-receiver-backend.', error);
    }

    return result;
  }

  /**
   * This method returns the telegrams for a given filter.
   * @param filter
   * @returns Promise<TelegramResponsePageResponse>
   */
  public async getTelegrams(filter: TelegramFilter): Promise<TelegramResponsePageResponse> {
    let result: TelegramResponsePageResponse = null;
    try {
      const response = await firstValueFrom(this.telegramReceiverControllerService.getTelegrams(filter.getAllParams()));

      if (response) {
        result = response;
      }

    } catch (error) {
      LoggingService.logError(LoggingSource.TELEGRAM_RECEIVER_SERVICE, 'Error occurred trying to get telegrams from telegram-receiver-backend.', error);
    }

    return result;
  }

    public async getWorkSequencesPerCostCenter(costCenter: string): Promise<string[]> {
      let result = new Array<string>();
      try {
        result = await firstValueFrom(this.telegramReceiverControllerService.getWorkSequencePerCostCenter({costCenter}));

      } catch(error) {
        LoggingService.logError(LoggingSource.TELEGRAM_RECEIVER_SERVICE, 'Error occurred trying to get workSequences from telegram-receiver-backend.', error);
      }

      return result;
    }

  public async getTelegramsForCostCenter(costCenter: string): Promise<TelegramResponse[]> {
    let result = new Array<TelegramResponse>();
    try {
      result = await firstValueFrom(this.telegramReceiverControllerService.getTelegramsForCostCenter({costCenter}));

    } catch(error) {
      LoggingService.logError(LoggingSource.TELEGRAM_RECEIVER_SERVICE, 'Error occurred trying to get workSequences from telegram-receiver-backend.', error);
    }

    return result;
  }
}
