import {Component, OnInit} from '@angular/core';
import {ProductCenterFilter} from '../../metadata/models/productCenterFilter';
import {firstValueFrom, Subject} from 'rxjs';
import {CostCenterFilter} from '../../metadata/models/costCenterFilter';
import {KioskModeService} from '../../../core/services/kiosk-mode.service';
import {takeUntil} from 'rxjs/operators';
import {
  ManufacturingStatisticControllerService,
  ManufacturingStatisticResponse
} from '@cstx/volkswagen-mqs-manufacturing-monitor-service-client';
import {
  ShopfloorManagementBoardStatusService,
  ShopfloorManagementBoardStatusType
} from '@cstx/volkswagen-mqs-quality-management-service-client';
import {ProductUnitFilter} from '../../metadata/models/productUnitFilter';
import {ActivatedRoute} from '@angular/router';
import {Location, PlatformLocation, ViewportScroller} from '@angular/common';
import {MetadataV2Service} from '../../metadata/services/metadata-v2.service';
import {
  EnterpriseRoleProviderService
} from '../../../core/services/enterprise-role-provider.service';

@Component({
  selector: 'op-sfm-board',
  templateUrl: './sfm-board.component.html',
  styleUrls: ['./sfm-board.component.scss']
})
export class SfmBoardComponent implements OnInit{
  constructor(private metadataV2Service: MetadataV2Service,
              private manufacturingStatisticsControllerService: ManufacturingStatisticControllerService,
              private kioskModeService: KioskModeService,
              private shopfloorManagementBoardStatusService: ShopfloorManagementBoardStatusService,
              private viewportScroller: ViewportScroller,
              private route: ActivatedRoute,
              private location: Location,
              private platformLocation: PlatformLocation) {

    this.kioskModeService.kioskMode$.pipe(takeUntil(this.unsubscribe))
      .subscribe(kioskMode => this.kioskMode = kioskMode);

  }

  public loading: boolean;
  public pcActive = 'pcAll';
  public puActive = 'puAll';
  public activeTab = 'all';

  private offSetFromTargetPerformancePoor = 5;
  private offSetFromTargetPerformanceBad = 20;

  public productCenters = new Array<SimpleProductCenter>();
  public productUnits: SimpleProductUnit[];
  public productUnitList = new Array<SimpleProductUnit>();

  public costCenters = new Array<SimpleCostCenter>();
  private statistics = new Array<ManufacturingStatisticResponse>();

  public sfmBoardViewModelList = new Array<SfmBoardViewModel>();
  public sfmBoardViewModelListFiltered = new Array<SfmBoardViewModel>();

  public kioskMode: boolean;
  public  showInactive: boolean;

  private productCenterFilter = new ProductCenterFilter();
  private unsubscribe: Subject<void> = new Subject<void>();


  protected readonly EnterpriseRoleProviderService = EnterpriseRoleProviderService;

  public async ngOnInit() {
    this.loading = true;

    await this.getProductUnits();
    await this.getProductCenters();
    await this.getCostCenters();
    await this.getManufacturingStatistics();
    await this.getShopfloorManagementBoardStatus();

    this.route.queryParamMap.subscribe((p => {
      const activeTab = p.get('activeTab');
      this.activeTab = activeTab ? activeTab : this.activeTab;

      const pcActive = p.get('pcActive');
      this.pcActive = pcActive ? pcActive : this.pcActive;
      this.setPcActive(this.pcActive);

      const puActive = p.get('puActive');
      this.puActive = puActive ? puActive : this.puActive;

      const showInactiveShifts = p.get('showInactiveShifts') === 'true';
      this.showInactive = showInactiveShifts ? showInactiveShifts : this.showInactive;


      this.setActive(this.activeTab);
      this.loading = false;
    }));
  }

  public isActive(tab: string) {
    return this.activeTab === tab;
  }

  public setActive(tab: string) {
    this.activeTab = tab;

    let filtered = this.sfmBoardViewModelList;
    filtered =  filtered.filter(sfm => !sfm.isDeactivated)

    if (tab === 'all') {
      filtered = filtered.filter(sfm => sfm.SfmStatusType === ShopfloorManagementBoardStatusType.ProductCenter);
    }

    if (tab === 'costCenterView') {
      filtered = filtered.filter(sfm => sfm.SfmStatusType === ShopfloorManagementBoardStatusType.CostCenter);
    }

    if (tab === 'costCenterView' && this.pcActive !== 'pcAll') {
      filtered = filtered.filter(sfm => sfm.CostCenter.ProductCenter?.Id === this.pcActive);
    }

    if (tab === 'costCenterView' && this.puActive !== 'puAll') {
      filtered = filtered.filter(sfm => sfm.CostCenter.ProductUnit?.Id === this.puActive);
    }

    if (tab !== 'all' && tab !== 'productUnitView') {
        const indexFound = filtered.findIndex(sfm => sfm.IsActive);
        if (indexFound === -1) {
            this.showInactive = true;
        }

        if (!this.showInactive) {
            filtered = filtered.filter(sfm => sfm.IsActive);
        }
    }

    filtered.sort((a, b) => a.CommonName.localeCompare(b.CommonName));
    this.sfmBoardViewModelListFiltered = filtered;
  }


  private async getProductCenters() {
    const result = await this.metadataV2Service.getProductCenters(this.productCenterFilter);
    const productCenters = new Array<SimpleProductCenter>();

    result.forEach(pc => {
      productCenters.push(new SimpleProductCenter(pc.id, pc.shortName, pc.name, pc.isDeactivated));
    })

    productCenters.sort((a, b) => a.Number.localeCompare(b.Number));
    this.productCenters = productCenters;
  }

  private async getCostCenters() {
    const result = await this.metadataV2Service.getCostCenters(new CostCenterFilter());

    result.items.forEach(cc => {
      if (cc.nr !== '' && cc.nr !== undefined && cc.nr !== null && cc.nr !== '00000') {
        const sCc = new SimpleCostCenter(cc.nr, cc.name, cc.isDeactivated);
        sCc.Id = cc.id;
        sCc.ProductCenter = this.productCenters.find(p => p.Id === cc.productCenterId);
        sCc.ProductUnit = this.productUnits.find(p => p.Id === cc.productUnitId);

        this.costCenters.push(sCc);
      }
    });
  }

  private async getManufacturingStatistics() {
    this.statistics = await firstValueFrom(this.manufacturingStatisticsControllerService.getManufacturingStatistics());
  }

  private getProductCenterPerformance(productCenterId: string): PerformanceState {
    const state = PerformanceState.UNKNOWN;

    let goodCounter = 0;
    let otherCounter = 0;
    let unknownCounter = 0;

    const filteredList =
      this.sfmBoardViewModelList.filter(sfm => sfm.SfmStatusType === ShopfloorManagementBoardStatusType.CostCenter &&
          sfm.CostCenter?.ProductCenter?.Id === productCenterId && sfm.IsActive);

    filteredList.forEach(sfm => {
      if (sfm.PerformanceState === PerformanceState.GOOD) {
        goodCounter++;
      } else if (sfm.PerformanceState === PerformanceState.UNKNOWN) {
        unknownCounter++;
      }
      else {
        otherCounter++;
      }
    })


    if (goodCounter === 0 && otherCounter >= 1) {
      return PerformanceState.BAD;
    }

    if (goodCounter < otherCounter) {
      return PerformanceState.BAD;
    }

    const diff = (filteredList.length - otherCounter ) / filteredList.length * 100;
    if (diff > 10) {
      return  PerformanceState.POOR
    }

    return PerformanceState.GOOD;
  }

  public toggleShowInactive() {
    const params = new URLSearchParams(this.platformLocation.search);
    params.set('showInactiveShifts', this.showInactive.toString());
    this.location.replaceState(this.platformLocation.pathname, params.toString(), this.location.getState());

    this.setActive(this.activeTab);
  }
  public hasActiveItems(): boolean {
    return this.sfmBoardViewModelListFiltered.filter(sfm => sfm.IsActive).length > 0;
  }

  // TODO: Code should be simplified!
  private async getShopfloorManagementBoardStatus() {
    const status = new Array<SfmBoardViewModel>();


    const plantStatus = new SfmBoardViewModel(ShopfloorManagementBoardStatusType.ProductCenter);
    plantStatus.CommonName = 'Volkswagen Croup Components'
    plantStatus.Description = 'Werk Salzgitter';

    status.push(plantStatus);

    const result = await firstValueFrom(this.shopfloorManagementBoardStatusService.getShopfloorManagementBoardStatus());

    result.forEach(r => {
      if (r.commonName !== '' && r.commonName !== undefined && r.commonName !== null && r.commonName !== '00000') {
      const sfmBoardViewModel = new SfmBoardViewModel(r.statusType);
            sfmBoardViewModel.CommonName = r.commonName;
            sfmBoardViewModel.Id = r.id;
            sfmBoardViewModel.TechnicalState = r.technicalStateOk ? SfmState.GOOD : SfmState.BAD;
            sfmBoardViewModel.QualityState = r.qualityStateOk ? SfmState.GOOD : SfmState.BAD;
            sfmBoardViewModel.WorkSecurityState = r.workSecurityStateOk ? SfmState.GOOD : SfmState.BAD;
            sfmBoardViewModel.LogisticState = r.logisticStateOk ? SfmState.GOOD : SfmState.BAD;
            sfmBoardViewModel.HumanResourcesState = r.humanResourcesStateOk ? SfmState.GOOD : SfmState.BAD;
            sfmBoardViewModel.MaterialState = r.materialStateOk ? SfmState.GOOD : SfmState.BAD;
            sfmBoardViewModel.Description = this.getDescription(sfmBoardViewModel.CommonName, sfmBoardViewModel.SfmStatusType);

            if (sfmBoardViewModel.SfmStatusType === ShopfloorManagementBoardStatusType.CostCenter) {
              sfmBoardViewModel.Statistics = this.getCostCenterStatistics(sfmBoardViewModel.CommonName);
              sfmBoardViewModel.IsActive   = sfmBoardViewModel.Statistics?.status === ManufacturingStatisticResponse.StatusEnum.Active;
              sfmBoardViewModel.PerformanceState = this.calculateCostCenterPerformanceState(sfmBoardViewModel);

              sfmBoardViewModel.ParentObject = this.costCenters.find(cC => cC.Number === sfmBoardViewModel.CommonName)?.ProductCenter?.Id;
              sfmBoardViewModel.CostCenter = this.costCenters.find(cC => cC.Number === sfmBoardViewModel.CommonName);

              if (!sfmBoardViewModel.CostCenter) {
                return;
              }

              sfmBoardViewModel.RefId = this.costCenters.find(cC => cC.Number === sfmBoardViewModel.CommonName)?.Id;
              sfmBoardViewModel.RefObject = this.costCenters.find(cC => cC.Number === sfmBoardViewModel.CommonName);

              this.setProductUnitOnProductCenter(sfmBoardViewModel.CostCenter.ProductCenter, sfmBoardViewModel.CostCenter.ProductUnit);
              this.setProductCostCenterOnProductUnit(sfmBoardViewModel.CostCenter.ProductUnit, sfmBoardViewModel.CostCenter);
            }

            status.push(sfmBoardViewModel);
      }
    });


    this.costCenters.forEach(c => {
      const existingIndex = status.findIndex(i => i.CommonName === c.Number);

      if (existingIndex === -1) {
        const sfmBoardViewModel = new SfmBoardViewModel(ShopfloorManagementBoardStatusType.CostCenter);
        sfmBoardViewModel.CommonName = c.Number;
        sfmBoardViewModel.Description = c.Description;
        sfmBoardViewModel.RefId = c.Id;
        sfmBoardViewModel.Statistics = this.getCostCenterStatistics(sfmBoardViewModel.CommonName);
        sfmBoardViewModel.IsActive   = sfmBoardViewModel.Statistics?.status === ManufacturingStatisticResponse.StatusEnum.Active;
        sfmBoardViewModel.PerformanceState = this.calculateCostCenterPerformanceState(sfmBoardViewModel);
        // sfmBoardViewModel.ParentObject = c.ProductCenter?.Id;
        sfmBoardViewModel.CostCenter = c;
        sfmBoardViewModel.isDeactivated = c.IsDeactivated;


        this.setProductUnitOnProductCenter(c.ProductCenter, c.ProductUnit);
        this.setProductCostCenterOnProductUnit(c.ProductUnit, c);

        status.push(sfmBoardViewModel);
      }
    });


    this.productUnits.forEach(spu => {
      const existingIndex = status.findIndex(i => i.Id === spu.Id);

      if (existingIndex === -1) {
        const sfmBoardViewModel = new SfmBoardViewModel(ShopfloorManagementBoardStatusType.ProductGroup);
        sfmBoardViewModel.CommonName = spu.Name;
        sfmBoardViewModel.Description = spu.Description;
        sfmBoardViewModel.isDeactivated = spu.IsDeactivated;

        sfmBoardViewModel.RefId = spu.Id;
        sfmBoardViewModel.RefObject = spu;

        status.push(sfmBoardViewModel);
      }
    });



    this.productCenters.forEach(spc => {
      const existingIndex = status.findIndex(i => i.CommonName === spc.Number);

      if (existingIndex === -1) {
        const sfmBoardViewModel = new SfmBoardViewModel(ShopfloorManagementBoardStatusType.ProductCenter);
        sfmBoardViewModel.CommonName = spc.Number;
        sfmBoardViewModel.Description = spc.Description;
        sfmBoardViewModel.RefId = spc.Id;
        sfmBoardViewModel.RefObject = spc;
        sfmBoardViewModel.isDeactivated = spc.IsDeactivated;

        status.push(sfmBoardViewModel);
      } else {
        status[existingIndex].RefId = spc.Id;

        /**
         * TODO: Refactoring needed. We need to check, why this is even a possible outcome in the flow.
         */
        status[existingIndex].RefObject = spc;
        status[existingIndex].isDeactivated = spc.IsDeactivated;
      }
    });


    this.sfmBoardViewModelList = status;
    this.calculateProductCenterPerformanceState();
  }

  private getDescription(commonName: string, sfmStatusType: ShopfloorManagementBoardStatusType) {
    if (sfmStatusType === ShopfloorManagementBoardStatusType.CostCenter) {
      return this.costCenters.find(c => c.Number === commonName)?.Description;
    }

    if (sfmStatusType === ShopfloorManagementBoardStatusType.ProductCenter) {
      return this.productCenters.find(c => c.Number === commonName)?.Description;
    }

    return '';
  }
  private getCostCenterStatistics(commonName: string) {
    return this.statistics.find(s => s.costCenter === commonName);
  }
  private calculateCostCenterPerformanceState(sfmBoardViewModel: SfmBoardViewModel): PerformanceState {
    if (sfmBoardViewModel.Statistics && sfmBoardViewModel.Statistics.status === ManufacturingStatisticResponse.StatusEnum.Active) {

      // const diff = (statistics.targetValue - statistics.actualValue ) / statistics.targetValue * 100;
      const diff = sfmBoardViewModel.differenceBetweenActualAndTargetInPercent();

      if (diff === undefined) {
        return PerformanceState.UNKNOWN;
      }


      if (diff >= this.offSetFromTargetPerformanceBad) {
        return  PerformanceState.BAD;
      }

      if (diff > this.offSetFromTargetPerformancePoor) {
        return PerformanceState.POOR;
      }

      if (diff <= this.offSetFromTargetPerformancePoor) {
        return PerformanceState.GOOD;
      }
    }

    return PerformanceState.UNKNOWN;
  }

  public getExistingProductUnits(): Array<SimpleProductUnit> {
    if (this.isPcActive('pcAll')) {
      return;
    }

    const productUnitList = new Array<SimpleProductUnit>()
    this.sfmBoardViewModelListFiltered.forEach(sfm => {
      if (sfm.SfmStatusType === ShopfloorManagementBoardStatusType.CostCenter) {
        const productUnit = sfm.CostCenter?.ProductUnit;

        if (productUnit && !productUnitList.includes(productUnit)) {
          productUnitList.push(productUnit);
        }
      }
    });

    productUnitList.sort((a, b) => a.Name.localeCompare(b.Name));
    this.productUnitList = productUnitList;
    return productUnitList;
  }


  private async getProductUnits() {
    const filter = new ProductUnitFilter();

    const result = await this.metadataV2Service.getProductUnits(filter);
    const productUnits = new Array<SimpleProductUnit>();

    result.forEach(p => {
      productUnits.push(new SimpleProductUnit(p.id, p.name, p.description, p.isDeactivated));
    })

    productUnits.sort((a, b) => a.Name.localeCompare(b.Name));
    this.productUnits = productUnits;
  }


  public setPcActive(pc: string) {
    this.puActive = 'puAll';
    this.pcActive = pc;

    this.setActive(this.activeTab);
    this.getExistingProductUnits();
  }

  public isPcActive(pc: string): boolean {
    return this.pcActive === pc;
  }

  public setPuActive(pu: string) {
    // this.pcActive = 'pcAll'
    this.puActive = pu;

    this.setActive(this.activeTab);
  }

  public isPuActive(pu: string): boolean {
    return this.puActive === pu;
  }

  private setProductUnitOnProductCenter(productCenter: SimpleProductCenter, productUnit: SimpleProductUnit) {
    const x = this.productCenters.find(p => p?.Id === productCenter?.Id);

    if (x && productUnit) {
      x.ProductUnits.push(productUnit);
    }

  }

  private setProductCostCenterOnProductUnit(productUnit: SimpleProductUnit, costCenter: SimpleCostCenter) {
    const x = this.productUnits.find(u => u.Id === productUnit?.Id);

    if (x && costCenter) {
      x.CostCenters.push(costCenter);
    }
  }

  private calculateProductCenterPerformanceState() {
      const filtered = this.sfmBoardViewModelList.filter(s => s.SfmStatusType === ShopfloorManagementBoardStatusType.ProductCenter);

      filtered.forEach(s => {
        s.PerformanceState = this.getProductCenterPerformance(s.RefId);
      })
  }

  public scrollTo(loadingDocumentation: string) {
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor(loadingDocumentation);
    }, 50);
  }
}



export enum PerformanceState {
  UNKNOWN = 'UNKNOWN',
  GOOD ='GOOD',
  POOR = 'POOR',
  BAD = 'BAD'
}
export enum SfmState {
  UNKNOWN = 'UNKNOWN',
  GOOD ='GOOD',
  BAD = 'BAD'
}

export class SfmBoardViewModel {
  public get CommonName(): string {
    return this._CommonName;
  }

  public set CommonName(value: string) {
    this._CommonName = value;

    if (this.SfmStatusType === ShopfloorManagementBoardStatusType.CostCenter) {
      this.OeeChartConfig = this.getOeeChartConfig(this._CommonName);
      this.OutputChartConfig = this.getOutputChartConfig(this._CommonName);
    }
  }

  private _CommonName: string;

  public Id: string;
  public Description: string;
  public IsActive: boolean;

  /**
   * This state represents the actual customer decision, if an entity is in use or not.
   * It is based on property of costCenter, productUnit or productCenter.
   */
  public isDeactivated: boolean;

  public PerformanceState: PerformanceState = PerformanceState.UNKNOWN;

  public QualityState: SfmState = SfmState.GOOD;
  public LogisticState: SfmState = SfmState.GOOD;
  public HumanResourcesState: SfmState = SfmState.GOOD;
  public MaterialState: SfmState = SfmState.GOOD;
  public WorkSecurityState: SfmState = SfmState.GOOD;
  public TechnicalState: SfmState = SfmState.GOOD;

  public Statistics: ManufacturingStatisticResponse;

  public OutputChartConfig: string;
  public OeeChartConfig: string;

  public SfmStatusType: ShopfloorManagementBoardStatusType;


  public CostCenter: SimpleCostCenter;
  /**
   * Deprecated. Not in use currently.
   */
  // public ProductUnit: SimpleProductUnit;
  // public ProductCenter: SimpleProductCenter;

  public ParentObject: string = null;

  public RefId: string;
  public RefObject: SimpleMetadataObject;


  constructor(sfmStatusType: ShopfloorManagementBoardStatusType) {
    this.SfmStatusType = sfmStatusType;
  }


  /**
   * Returns the current difference between the target amount of produced components
   * and the actual amount on the current time in the active shift.
   */
  public differenceBetweenActualAndTargetInPercent(): number {
    if (this.Statistics.targetValue <= 0 &&  this.Statistics.actualValue > 0) {
      return undefined;
    }

    const difference =  (this.Statistics.targetValue - this.Statistics.actualValue ) / this.Statistics.targetValue * 100;

    return Math.round(difference);
  }

  private getOeeChartConfig(costCenter: string) {
    const now = new Date();
    const nowString = now.toISOString();


    return '{ "cc":"' + costCenter + '","cum":true,"view":true,"date":"' + nowString + '"}';
  }
  private  getOutputChartConfig(costCenter: string) {
    const now = new Date();

    const yesterday = new Date();
    yesterday.setHours(-24);

    const nowString = now.toISOString();
    const yesterdayString = yesterday.toISOString();

    return '{"td":false,"kk":false,"cc":"' + costCenter + '","cum":true,"unit":21,"range":"' + yesterdayString + '_' + nowString + '"}';
  }
}
export class SimpleCostCenter implements SimpleMetadataObject {
  public Number: string;
  public Description: string;
  public ProductCenter: SimpleProductCenter;
  public ProductUnit: SimpleProductUnit;
  public IsDeactivated: boolean;
  public Id: string;

  constructor(_number: string, _description: string, _isDeactivated: boolean) {
      this.Number = _number;
      this.Description = _description;
      this.IsDeactivated = _isDeactivated;
  }
}
export class SimpleProductCenter implements SimpleMetadataObject {
  public Id: string; // AKA: Guid
  public Number: string // AKA: ShortName
  public Description: string; // AKA: Name
  public IsDeactivated: boolean;

  public ProductUnits: Array<SimpleProductUnit>;


  constructor(id: string, nr: string, _description: string, isDeactivated: boolean = false) {
    this.Id = id;
    this.Number = nr;

    this.Description = _description;
    this.ProductUnits = new Array<SimpleProductUnit>();
    this.IsDeactivated = isDeactivated;
  }
}

export class SimpleProductUnit implements SimpleMetadataObject{
  public Id: string;
  public Name: string;
  public Description: string;
  public IsDeactivated: boolean;

  public CostCenters: Array<SimpleCostCenter>;

  constructor(id: string, name: string, description: string, isDeactivated: boolean = false) {
    this.Id = id;
    this.Name = name;
    this.Description = description;
    this.IsDeactivated = isDeactivated;
    this.CostCenters = new Array<SimpleCostCenter>();
  }
}

export interface SimpleMetadataObject {
  Id: string;
  Name?: string;
  Number?: string;
  Description: string;
}
