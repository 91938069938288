import {EventEmitter, Injectable} from '@angular/core';
import {AbsoluteSiteUrlService} from './absolute-site-url.service';
import {ConfigService} from '../../core/services/config.service';
import {LoggingService} from '../../core/logging/logging.service';
import {LoggingSource} from '../../core/logging/loggingSource';
import {ErrorHandler} from './error-handler/error-handler';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckerService {
  private deployedVersion = new Version();
  private currentVersion = new Version();

  private startUpTime = new Date();

  public newVersionAvailableEvent = new EventEmitter<Versions>();
  private initiated: boolean;
  constructor(private configService: ConfigService) {
  }

  public getNewVersionFile() {
    try {
      const absoluteUrl = AbsoluteSiteUrlService.getCurrentSiteUrl();
      const request = new XMLHttpRequest();

      request.open('GET', absoluteUrl + '/assets/version.json' , true);
      request.responseType = 'blob';


      request.onload = () => {
        const reader = new FileReader();
        reader.readAsText(request.response);

        reader.onload =  () => {

          try {
          let jsonString : string;
          jsonString = reader.result as string;
          this.deployedVersion = JSON.parse(jsonString);

          this.checkVersions();
          } catch (error) {
            ErrorHandler.printError(error);
            LoggingService.logError(LoggingSource.VERSION_CHECKER, 'Invalid version file or version file missing.', error);
          }
        };
      };

      request.send();
    }
    catch (error) {
      ErrorHandler.printError(error);
      LoggingService.logError(LoggingSource.VERSION_CHECKER, 'Error occurred on checking for a new version file!', error);
    }

  }

  public init() {
    if (this.initiated) {
      return;
    }

    setInterval(() => this.getNewVersionFile(), 10000);

    this.currentVersion.version = this.configService.getVersion();
    this.startUpTime = new Date();

    this.initiated = true;
  }

  public getUptimeInHours(): number {
    const now = new Date();

    const num = Math.abs(now.getTime() - this.startUpTime.getTime()) / 1000 / 60 / 60;
    return Math.round((num + Number.EPSILON) * 100) / 100
  }

  public getUptimeInMinutes(): number {
    const now = new Date();

    const num = Math.abs(now.getTime() - this.startUpTime.getTime()) / 1000 / 60;
    return Math.round((num + Number.EPSILON) * 100) / 100
  }

  public getUptimeInSeconds(): number {
    const now = new Date();

    const num = Math.abs(now.getTime() - this.startUpTime.getTime()) / 1000;
    return Math.round((num + Number.EPSILON) * 100) / 100
  }

  private checkVersions() {
    if (this.currentVersion.version < this.deployedVersion.version) {
      this.newVersionAvailableEvent.emit(new Versions(this.currentVersion, this.deployedVersion));
      LoggingService.logDebug(LoggingSource.VERSION_CHECKER, 'New app version ' + this.deployedVersion.version + ' found.')
    }
  }
}

export class Version {
  public version: string;
}

export class Versions {
  public currentVersion: Version;
  public deployedVersion: Version;

  constructor(current: Version, deployed: Version) {
    this.currentVersion = current;
    this.deployedVersion = deployed;
  }

}
