import { Component, OnInit } from '@angular/core';
import {RoutingWatcherService} from '../../../../shared/services/routing-watcher.service';

@Component({
  selector: 'op-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  constructor(public watcher: RoutingWatcherService) { }

  ngOnInit(): void {
  }

}
