import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HomeRoutingModule} from './home-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {HomeComponent} from './home.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {ProductcenterWeeklyComponent} from './statistics/productcenter-weekly/productcenter-weekly.component';
import { PageNotFoundComponent } from './pages/not-found/page-not-found.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { InfosComponent } from './infos/infos.component';
import { ListComponent } from './infos/list/list.component';
import { DetailComponent } from './infos/detail/detail.component';
import { ShowComponent } from './infos/show/show.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { ShowEntryComponent } from './infos/show/show-entry/show-entry.component';
import { EmployeeInfoComponent } from './infos/employee-info/employee-info.component';
import { SfmBoardComponent } from './sfm-board/sfm-board.component';
import { SfmCardComponent } from './sfm-board/sfm-card/sfm-card.component';
import { LandingZoneComponent } from './landing-zone/landing-zone.component';
import { VideoInfoComponent } from './infos/video-info/video-info.component';
import { NotAllowedComponent } from './pages/not-allowed/not-allowed.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
      HomeComponent,
      DashboardComponent,
      ProductcenterWeeklyComponent,
      PageNotFoundComponent,
      InfosComponent,
      ListComponent,
      DetailComponent,
      ShowComponent,
      ShowEntryComponent,
      EmployeeInfoComponent,
      SfmBoardComponent,
      SfmCardComponent,
      LandingZoneComponent,
      VideoInfoComponent,
      NotAllowedComponent
  ],
    imports: [
        CommonModule,
        HomeRoutingModule,
        SharedModule,
        FormsModule,
        InfiniteScrollModule,
        TooltipModule,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        PdfViewerModule,
    ]
})
export class HomeModule { }
