<op-spinner *ngIf=isLoading></op-spinner>
<div class="modal-content">
  <div class="modal-header file-style">
    <div class="align-middle">
      <ng-container *ngIf="!filePreview.ShowDownloadArea" [ngTemplateOutlet]="previewHeaderTemplate"></ng-container>
     <ng-container *ngIf="filePreview.IsParsedBinary()" [ngTemplateOutlet]="parseBinFile"></ng-container>
    </div>
    <ng-container *ngIf="modal; else openComponentBlock">
      <button type="button" class="close" (click)="closeClicked()">&times;</button>
    </ng-container>
    <ng-template #openComponentBlock>
      <a [routerLink]="['/', filePreview.Metadata.ComponentType, filePreview.Metadata.Id]"
         [queryParams]="{ activeTab: 'files' }" target="_blank">
        <button type="button" class="btn btn-secondary btn-sm"
                (click)="showComponentClicked()">{{'filePreview.component.text.showComponent' | translate}}</button>
      </a>
    </ng-template>
  </div>
    <div class="modal-body no-padding">
      <div class="container-fluid">
        <div>
          <ng-container *ngIf="filePreview.IsImage() && !filePreview.HasError"
                        [ngTemplateOutlet]="previewImageAreaTemplate"></ng-container>
          <ng-container *ngIf="(filePreview.IsString() || filePreview.IsParsedBinary()) && !filePreview.HasError"
                        [ngTemplateOutlet]="previewEditorAreaTemplate"></ng-container>
          <ng-container *ngIf="filePreview.IsPdf() && !filePreview.HasError"
                        [ngTemplateOutlet]="previewPdfAreaTemplate"></ng-container>
          <ng-container *ngIf="filePreview.ShowDownloadArea && !filePreview.HasError"
                        [ngTemplateOutlet]="downloadAreaTemplate"></ng-container>
          <ng-container *ngIf="filePreview.HasError" [ngTemplateOutlet]="errorTemplate"></ng-container>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="col-sm text-left">
        <ng-container *ngIf="!filePreview.ShowDownloadArea && !filePreview.HasError"
                      [ngTemplateOutlet]="previewFooterTemplate"></ng-container>
      </div>
      <ng-container *ngIf="modal">
        <div class="col-sm-2 text-right">
          <button type="button" class="btn btn-secondary"
                  (click)="closeClicked()">{{'button.close' | translate}}</button>
        </div>
      </ng-container>
  </div>
</div>

<!-- Templates -->
<!-- previewImageAreaTemplate -->
<ng-template #previewImageAreaTemplate>
  <img class="customImageStyle" [src]="filePreview.File"/>
</ng-template>

<!-- previewPdfAreaTemplate -->
<ng-template #previewPdfAreaTemplate>
  <ngx-extended-pdf-viewer [src]="filePreview.File"
                           useBrowserLocale="true"
                           height="80vh">
  </ngx-extended-pdf-viewer>
</ng-template>

<!-- previewEditorAreaTemplate -->
<ng-template #previewEditorAreaTemplate>
  <div style="height: 85vh">
    <op-monaco-editor [options]="monacoEditorOptions"></op-monaco-editor>
  </div>
</ng-template>

<!-- downloadAreaTemplate -->
<ng-template #downloadAreaTemplate>
  <div *ngIf="errorOccurred">
    <div class="text-center">
    <i class="cpp-icon-warning"></i>
      {{'filePreview.component.text.parsingErrorOccurred' | translate}}
      <i class="cpp-icon-warning"></i>
    </div>
    <ng-container *ngIf="errorMessage">
      <div class="text-center mt-2">
        {{'filePreview.component.text.parsingErrorMessage' | translate}}: <br>
        {{errorMessage}}
      </div>
    </ng-container>

  </div>
  <div class="text-center m-0" style="min-height: 250px">
    <i class="fas fa-download fa-5x customMouseStyle pt-5"
       title="{{'filePreview.component.button.download' | translate}}" (click)="downloadFilePressed()"></i>
    <h4>{{'filePreview.component.button.download' | translate}}</h4>
    <h5>{{'filePreview.component.text.downloadReadyText' | translate}} {{ filePreview.DownloadCounter }} ...</h5>
  </div>
</ng-template>

<!-- previewHeaderTemplate -->
<ng-template #previewHeaderTemplate>
  <i class="fas fa-folder-open customFolderColor"></i><label class="customFolderLabel">{{ getFilePath() }}</label>
  <i class="fas fa-download customDownloadStyle pt-1 pl-1" (click)="downloadClicked()"></i>
  <i class="fas fa-copy customCopyStyle pt-1 pl-1" (click)="copyToClipboardClicked()"></i><label
  *ngIf="copiedToClipboard" class="pl-1 small">Copied</label>
</ng-template>

<!-- previewHeaderTemplate -->
<ng-template #parseBinFile>
    <i class="fas fa-sync-alt customDownloadStyle pt-1 pl-1" (click)="reParseFile()"></i>
</ng-template>

<!-- previewFooterTemplate -->
<ng-template #previewFooterTemplate>
  <div>
    <div class="row p-0">
      <!--[ngClass]="isModal ? 'col-sm-12' : 'col-sm-6'-->
      <div class="pl-1" class="col-sm">
        <table class="table table-borderless w-auto pl-0">
          <tbody>
          <tr>
            <td class="footer-header-col">
              {{'filePreview.component.text.id' | translate}}
            </td>
            <td class="footer-value-col">
              {{ filePreview.Metadata.Id }}
            </td>
            <td class="footer-header-col">
              {{'filePreview.component.text.type' | translate}}
            </td>
            <td class="footer-value-col">
              {{ filePreview.Metadata.ComponentType }}
            </td>
          </tr>
          <tr>
            <td class="footer-header-col">
              {{'filePreview.component.text.costCenter' | translate}}
            </td>
            <td class="footer-value-col">
              {{ filePreview.Metadata.CostCenter }}
            </td>
            <td class="footer-header-col">
              {{'filePreview.component.text.origin' | translate}}
            </td>
            <td class="footer-value-col">
              {{ filePreview.Metadata.Origin ? filePreview.Metadata.Origin : filePreview.Metadata.Source ? filePreview.Metadata.Source : '-' }}
            </td>
          </tr>
          <tr>
            <td class="footer-header-col">
              {{'filePreview.component.text.uploadedAt' | translate}}
            </td>
            <td class="footer-value-col">
              {{ filePreview.Metadata.Uploaded ? (filePreview.Metadata.Uploaded | date:'dd.MM.yyyy HH:mm:ss') : '-' }}
            </td>
            <td class="footer-header-col">
              {{'filePreview.component.text.internalName' | translate}}
            </td>
            <td class="footer-value-col">
              {{ filePreview.Name ? filePreview.Name : '-' }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<!-- errorTemplate -->
<ng-template #errorTemplate>
  <div class="text-center m-0" style="min-height: 250px">
    <i class="fas fa-ban fa-5x customMouseStyle pt-5" (click)="closeClicked()"></i>
    <h4>Error</h4>
    <h5>An unexpected error has occurred...</h5>
  </div>
</ng-template>
