import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'op-element-footer',
  templateUrl: './element-footer.component.html',
  styleUrls: ['./element-footer.component.scss']
})
export class ElementFooterComponent implements OnInit {
  @Input() componentElement: any;
  @Input() isModal: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
