import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private deviceLocation: BehaviorSubject<DeviceLocation> = new BehaviorSubject<DeviceLocation>(undefined);
  public deviceLocation$: Observable<DeviceLocation> = this.deviceLocation.asObservable();

  constructor(private location: Location,
              private route: ActivatedRoute) {

    this.route.queryParamMap.subscribe(params => {
        const deviceLocation = new DeviceLocation();
        deviceLocation.CostCenter = params.get('locationCostCenter');
        deviceLocation.Line = params.get('locationLine');
        deviceLocation.WorkSequence = params.get('locationWorkSequence');
        deviceLocation.PartialWorkSequence = params.get('locationPartialWorkSequence')

        if (deviceLocation.isValidDeviceLocation()) {
          this.deviceLocation.next(deviceLocation);
        }

    });
  }


  public setDeviceLocation(costCenterAndLine: string, workSequence: string, partialWorkSequence?: string ) {
      const deviceLocation = new DeviceLocation();


      deviceLocation.CostCenter = costCenterAndLine.substring(0,4);
      deviceLocation.Line = costCenterAndLine.substring(4,5);
      deviceLocation.WorkSequence = workSequence;
      deviceLocation.PartialWorkSequence = partialWorkSequence;

    if (deviceLocation.isValidDeviceLocation()) {
      this.deviceLocation.next(deviceLocation);
    }
  }
}

export class DeviceLocation {
  CostCenter: string;
  Line: string;
  WorkSequence: string;
  PartialWorkSequence: string;
  public IsValid = false;


  public isValidDeviceLocation(): boolean {
    if (this.CostCenter !== null && this.WorkSequence !== null && this.Line !== null) {
      this.IsValid = true;
      return true;
    }

    return false;
  }

}
