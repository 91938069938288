import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../core/services/auth.service';
import {User} from '../../core/models/user';

@Component({
  selector: 'op-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  currentUser: User;
  sideBarSpace: boolean;

  constructor(private authService: AuthService) {

  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
  }

  onLogout() {
    this.authService.logout();
  }

  toggleSideBar() {
    this.sideBarSpace = !this.sideBarSpace;
  }
}
