<op-detail-section-wrapper
  [title]="'telegram.title' | translate"
  [description]="'telegram.description'  | translate"
  [contentTemplate]="contentTemplateRef"
  [hasContent]="telegramList.length >= 1"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <table class="table table-hover">
    <tr class="row pl-3 pr-3" *ngIf="!viewSizeSM">
      <td class="cpp-column-fixed-width-200 small">
        {{ 'telegram.type' | translate}}
      </td>
      <td class="col d-none d-md-inline-block small">
        {{ 'telegram.workSequence' | translate}}
      </td>
      <td class="cpp-column-fixed-width-200 small d-none d-sm-none d-md-block" >
        {{ 'telegram.orderNumber' | translate}}
      </td>
      <td class="cpp-column-fixed-date pl-0 small">
        {{ 'telegram.dateTime' | translate}}
      </td>
    </tr>
    <tbody>
    <op-loading-spinner *ngIf="loading"></op-loading-spinner>
    <tr *ngIf="telegramList.length === 0 && !loading" class="row pt-3 pb-3" >
      <div class="col pl-0 text-center">
        <small> {{ 'global.nothing-to-show' | translate }}</small>
      </div>
    </tr>
    <tr class="row pl-0 pl-sm-3 pr-3" *ngFor="let element of telegramList">
      <td class="cpp-column-fixed-width-200 small">
        <op-component-state-badge [name]="element.telegramType"></op-component-state-badge>
      </td>
      <td class="col d-none d-md-inline-block small">
        {{ element.workSequence }}
      </td>
      <td class="cpp-column-fixed-width-200 small d-none d-sm-none d-md-block">
        {{ element.orderNumber }}
      </td>
      <td class="cpp-column-fixed-date pl-0 small" [class.cpp-border-top-none]="viewSizeXS">
        <op-datetime-show [noLocalTimeConversion]="true" [dateTime]="element.stopTime">></op-datetime-show>
      </td>
    </tr>
    </tbody>
  </table>
</ng-template>
