import {AuditInformation} from '../../../shared/models/auditInformation';

export class DlqJob {
  public id: string;

  numberOfMessages?: number;
  private _numberOfMessagesDone?: number;
  public get numberOfMessagesDone(): number {
        return this._numberOfMessagesDone;
    }
    public set numberOfMessagesDone(value: number) {
        this._numberOfMessagesDone = value;
    }
  batchSize?: number;
  lastRunAt?: string | null;
  progress: number;
  timeRangeStart: string;
  timeRangeEnd: string;
  channel: string;
  errorType: string;
  // tslint:disable-next-line:variable-name
  private _jobStatus: string;
  public get jobStatus(): string {
        return this._jobStatus;
    }
    public set jobStatus(value: string) {
        this._jobStatus = value;
    }

  auditInfos: AuditInformation;
}
