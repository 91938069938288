import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'op-audit-v2',
  templateUrl: './audit-v2.component.html',
  styleUrls: ['./audit-v2.component.scss']
})
export class AuditV2Component implements OnInit {
  @Input() componentElement: any;
  @Input() ksu: string;
  @Input() selfLink: string;
  @Input() contentContainerId: string;
  @Input() collapsed = true;

  expanded: boolean;
  expandButtonText = 'manufacturing-process.expand';


  constructor() { }

  ngOnInit(): void {
    if (!this.collapsed) {
      this.expanded = true;
    }
  }

  expand() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.expandButtonText = 'manufacturing-process.collapse';
    } else {
      this.expandButtonText = 'manufacturing-process.expand';
    }
  }
}
