<div class="container-fluid pl-3 pb-3 cpp-page-defaults" *ngIf="EnterpriseRoleProviderService.hasRequiredClaimByName('engine-list', false)">
  <div class="pt-2 pb-2"><op-breadcrumb [version2]="true" path="Home/Engines"></op-breadcrumb></div>
  <table class="table table-hover">
    <tr class="row pl-3 pr-3">
      <td class="col pb-0 pl-0 nav">
        <ul class="nav">
          <li class="nav-item pr-0">
            <a [routerLink]="['/engine/list']" [queryParams]="{ activeTab: 'all' }">
              <button type="button" class="btn btn-sm button cpp-button-tab-selector" [class.cpp-button-tab-selected]="isActive('all')" (click)="setActive('all')">
                {{ 'global.word.all' | translate}}
                <op-count-badge *ngIf="!countUpdating" [count]="count"></op-count-badge>
                <span *ngIf="countUpdating" class="spinner-border spinner-border-sm" role="status"></span>
              </button>
            </a>
          </li>
        </ul>
      </td>
      <td class="col text-center d-none d-md-block">
        <div *ngIf="searching">
          <div class="spinner-border spinner-border-sm" ></div>  Searching ...
        </div>
      </td>
      <td class="col text-right pb-0 pr-0">
        <div class="d-inline">
         <i class="fas fa-shipping-fast pointer" (click)="toggleLogisticColumVisibility()"></i>
        </div>
        <div class="d-inline">
          <op-excel-export [disabled]="!EnterpriseRoleProviderService.hasRequiredClaimByName('engine-list-export')"
                           [filterBasedReportTrigger]="triggerFilterBasedReport"
                           (onExportRequested)="requestFilterBasedReport()">
          </op-excel-export>
        </div>
        <div class="d-inline">
          <op-refresh [refreshConfig]="refreshComponentConfig"
                      [triggerActive]="refreshComponentAutoReloadEnabled"
                      [triggerAnimation]="refreshComponentAnimationEnabled"
                      (refreshEvent)="refreshComponentWasClickedEvent()"
                      (autoRefreshEvent)="refreshComponentRefreshIntervalReachedEvent()"
                      (selectedIntervalChanged)="refreshComponentAutoReloadIntervalChangedEvent($event)">
          </op-refresh>
        </div>
      </td>
    </tr>
    <tr class="row pl-3 pr-3">
      <td class="col align-middle cpp-input-search">
          <op-guided-filter-input [source]="searchOptionSource.asObservable()"
                                  (criteriaUpdate)="setFilterValues($event)"
                                  (triggerSearch)="onSearch($event)"
                                  (triggerAbortSearch)="onSearchAbort()"
                                  [resetObs]="clearFilterSub.asObservable()"
                                  [searching]="searching"
                                  [mutuallyExclusives]="mutuallyexclusiveAttributes">
          </op-guided-filter-input>

          <div class="invalid-feedback">
            {{ 'engine.list.invalid-feedback' | translate }}
          </div>
      </td>
    </tr>
    <tr class="row pl-3 pr-3">
      <td class="cpp-column-fixed-width-25 text-center pl-0 pr-0 d-none d-md-block">
      </td>
      <td class="col pl-1 d-none d-md-block">
      </td>
      <td class="cpp-column-fixed-width-120 pl-1 d-xl-inline-block d-none small pointer" (click)="onSort('partNumber')">
        <span class="cpp-table-header-title">
          {{ 'page.engines.table.column.partNumber' | translate}}
        </span>
        <op-sort-order-indicator class="float-right" [filter]="listFilter"
                                 [target]="'partNumber'"></op-sort-order-indicator>
      </td>
      <!-- This will be shown on small devices! -->
      <td class="cpp-column-fixed-width-25 text-center pl-1 pr-0 d-block d-md-none small">
      </td>
      <td class="col d-block d-md-none small" (click)="onSort('engineCode')">
        <span class="cpp-table-header-title">
          {{ 'page.engines.table.column.engine' | translate }}
        </span>
        <op-sort-order-indicator class="float-right" [filter]="listFilter"
                                 [target]="'engineCode'"></op-sort-order-indicator>
      </td>
      <td class="cpp-column-fixed-width-75 col d-block d-md-none small"
          (click)="onSort('costCenter')">
        <span class="cpp-table-header-title">
          {{ 'page.engines.table.column.abbreviation.costCenter' | translate }}
        </span>
        <op-sort-order-indicator class="float-right" [filter]="listFilter"
                                 [target]="'costCenter'"></op-sort-order-indicator>
      </td>
      <td class="col d-none d-md-block pl-1 small text-overflow-ellipses pointer"
          (click)="onSort('engineCode')">
        <span class="cpp-table-header-title">
          {{ 'page.engines.table.column.engineCode' | translate }}
        </span>
        <op-sort-order-indicator class="float-right" [filter]="listFilter"
                                 [target]="'engineCode'"></op-sort-order-indicator>
      </td>
      <td class="col d-none d-md-block pl-1 pr-2 text-overflow-ellipses small pointer"
          (click)="onSort('engineNumber')">
        <span class="cpp-table-header-title">
          {{ 'page.engines.table.column.engineNumber' | translate }}
        </span>
        <op-sort-order-indicator class="float-right" [filter]="listFilter"
                                 [target]="'engineNumber'"></op-sort-order-indicator>
      </td>
      <td class="cpp-column-fixed-width-100 d-none d-sm-block small" (click)="onSort('keyCode')">
        <span class="cpp-table-header-title">
          <i class="fas fa-key"></i> - {{ 'global.word.abbreviation.number' | translate}}
        </span>
        <op-sort-order-indicator class="float-right" [filter]="listFilter"
                                 [target]="'keyCode'"></op-sort-order-indicator>
      </td>
      <td class="cpp-engine-list-column-fixed-build-date d-none d-lg-block small pointer"
          (click)="onSort('buildDate')">
        <span class="cpp-table-header-title">
          {{ 'page.engines.table.column.buildDate' | translate }}
        </span>
        <op-sort-order-indicator class="float-right" [filter]="listFilter"
                                 [target]="'buildDate'"></op-sort-order-indicator>
      </td>
      <td class="cpp-column-fixed-width-120 d-none d-md-block text-overflow-ellipses small pr-2 pointer"
          (click)="onSort('costCenter')">
        <span class="cpp-table-header-title">
          {{ 'page.engines.table.column.costCenter' | translate }}
        </span>
        <op-sort-order-indicator class="float-right" [filter]="listFilter"
                                 [target]="'costCenter'"></op-sort-order-indicator>
      </td>
      <td *ngIf="showLocationColumn" class="cpp-column-fixed-width-130 text-overflow-ellipses small">
        {{ 'i18n.globals.logistics.location' | translate }}
      </td>
      <td class="cpp-engine-list-column-fixed-icons d-none d-sm-none d-md-block small text-right"></td>
      <td class="cpp-engine-list-column-fixed-actions small text-right"></td>
    </tr>
    <tbody infinite-scroll
           [infiniteScrollDistance]="6"
           [infiniteScrollUpDistance]="2"
           [infiniteScrollThrottle]="50"
           (scrolled)="onDown()"
           (scrolledUp)="onUp()"
           opEnsureListSufficientlyFilled
           (loadMoreItems)="onDown()"
           [existingItems]="list.length">
      <tr *ngIf="list.length === 0 && !listUpdating" class="row pl-3 pt-3 pb-3 pr-3" >
        <div class="col pl-0 text-center">
          <small> {{ 'global.nothing-to-show' | translate }}</small>
        </div>
      </tr>
      <tr class="row pl-3 pr-3" *ngFor="let element of list; let i = index">
        <td class="cpp-column-fixed-width-25 text-center pl-0 pr-0 d-none d-md-block small"
            [tooltip]="getLogisticalState(element) === LogisticalState.UNDETERMINED || getLogisticalState(element) === LogisticalState.FREE ? undefined : logisticalStateTooltipTemplate"
            [ngClass]="highlightNewElements(element.modifiedAt)"
              [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
              [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'"
                  >

          <ng-container *ngIf="getLogisticalState(element) === LogisticalState.UNFREE;">
            <i class="cpp-icon-logistical-state d-inline" style="color: darkred;"></i>
          </ng-container>
        </td>

        <ng-template #logisticalStateTooltipTemplate>
          <op-logistical-state-tooltip [engine]="element"></op-logistical-state-tooltip>
        </ng-template>



        <td class="col d-none d-md-block pl-1 small" [ngClass]="highlightNewElements(element.modifiedAt)"  [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
              [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'">
          <op-component-state-badge [name]="element.engineState"></op-component-state-badge>
        </td>

        <!-- This will be shown on small devices! -->
        <td class="cpp-column-fixed-width-120 pl-1 d-xl-block d-none small" [ngClass]="highlightNewElements(element.modifiedAt)"  [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
              [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'">
          {{element.partNumber}}
        </td>
        <td class="cpp-column-fixed-width-25 text-center pl-0 pr-0 d-block d-md-none small" [style.background-color]="getEngineStateColor(element.engineState)"  [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
              [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'">
          <i class="cpp-icon-lock d-inline"
             [class.cpp-engine-list-is-visible]="element.engineBlockedState !== 'BLOCKED'"></i>
        </td>
        <td class="col d-sm-block d-md-none small" [ngClass]="highlightNewElements(element.modifiedAt)"  [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
              [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'">
          {{ element.engineCode }} - {{ element.engineNumber }}
        </td>
        <td class="cpp-column-fixed-width-75 col d-block d-md-none small pr-2" [ngClass]="highlightNewElements(element.modifiedAt)"  [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
              [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'">
          {{ element.costCenter }}
        </td>
        <td class="col pl-1 d-none d-md-block small text-overflow-ellipses"
            [ngClass]="highlightNewElements(element.modifiedAt)"
             [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
              [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'">
          {{ element.engineCode }}
        </td>
        <td class="col pl-1 pr-2 small d-none d-md-block" [ngClass]="highlightNewElements(element.modifiedAt)"  [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
              [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'"> {{ element.engineNumber }}</td>
        <td class="cpp-column-fixed-width-100 d-none d-sm-block small" [ngClass]="highlightNewElements(element.modifiedAt)"  [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
              [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'">{{ element.keyCode }}</td>
        <td class="cpp-engine-list-column-fixed-build-date d-none d-lg-block small text-monospace" [ngClass]="highlightNewElements(element.modifiedAt)"  [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
              [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'"> <op-datetime-show [dateTime]="element.buildDate"></op-datetime-show></td>
        <td class="cpp-column-fixed-width-120 d-none d-md-block small pr-2" [ngClass]="highlightNewElements(element.modifiedAt)"  [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
              [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'">{{ element.costCenter }} </td>
        <td *ngIf="showLocationColumn"
            class="cpp-column-fixed-width-130 text-overflow-ellipses small"
            [ngClass]="highlightNewElements(element.modifiedAt)"  [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
            [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'">{{ element.logisticalQuickInfo?.customer ? element.logisticalQuickInfo?.customer : element.logisticalQuickInfo?.location }}
        </td>
        <td class="cpp-engine-list-column-fixed-icons d-none d-md-block small text-right align-middle" [ngClass]="highlightNewElements(element.modifiedAt)"  [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
              [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'">
          <i *ngIf="element.hasKeyCodeHistory" class="cpp-icon-keycodehistory d-inline"></i>
          <i *ngIf="element.hasColdTests" class="cpp-icon-coldtest d-inline"></i>
          <i *ngIf="element.hasHotTests" class="cpp-icon-hottest d-inline"></i>
          <i *ngIf="element.hasReworks" class="cpp-icon-reworks d-inline"></i>

          <ng-container *ngIf="element.engineBlockedState === 'BLOCKED'; else noActiveBlockingTemplate">
            <i class="cpp-icon-lock d-inline" style="color:darkred;"></i>
          </ng-container>

          <ng-template #noActiveBlockingTemplate>
            <i *ngIf="element.hasBlockingActions" class="cpp-icon-blockings d-inline"></i>
          </ng-template>

        </td >
        <td class="cpp-engine-list-column-fixed-actions small text-right" [ngClass]="highlightNewElements(element.modifiedAt)"  [class.unfree-background-color]="getLogisticalState(element) === LogisticalState.UNFREE"
              [class.blocked-background-color]=" element.engineBlockedState === 'BLOCKED'">
          <a *opRequiredClaimByFunctionalResource="'engines-details-show'" [routerLink]="['../', element.id]" [queryParams]="{ activeTab: 'assembly' }">
            <i class="cpp-icon-open-details float-left d-inline" ></i>
          </a>
        </td>

      </tr>
      <tr *ngIf="listUpdating" class="row">
        <td class="col-12 text-center"><div class="spinner-border spinner-border-sm"></div></td>
      </tr>
    </tbody>
  </table>
</div>

