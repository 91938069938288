import {EventEmitter, Injectable, isDevMode} from '@angular/core';
import {BlockingDetail} from '../component-state-check/component-state-check.component';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';

@Injectable({
  providedIn: 'root'
})
export class ComponentReleaseWizardService {
  public static componentReleases = new Array<ComponentRelease>();
  public static componentSet = new Set<string>();

  public static releaseEvent = new EventEmitter<any>();
  public static onReleaseAbort = new EventEmitter<any>();
  public static onReleaseFinished= new EventEmitter<any>();

  constructor() { }

  public static addBlocking(blockingDetails: BlockingDetail[], componentName: string) {
    ComponentReleaseWizardService.componentSet.add(componentName);

    blockingDetails.forEach(blockingDetail => {
      if (blockingDetail.released) {
        return;
      }

      let existing: number;

      if (blockingDetail.blockingActionNr) {
         existing = ComponentReleaseWizardService.componentReleases
          .findIndex(existingRelease => existingRelease.blockingActionNr === blockingDetail.blockingActionNr);
      } else {
        existing = ComponentReleaseWizardService.componentReleases
          .findIndex(existingRelease => existingRelease.blockingReason === blockingDetail.reason);
      }

        let release: ComponentRelease;
        if (existing === -1) {
          release = new ComponentRelease();
          release.blockingActionId = blockingDetail.blockingActionId;
          release.blockingActionNr = blockingDetail.blockingActionNr;
          release.blockingReason = blockingDetail.reason;
          release.componentNames.add(componentName);

          ComponentReleaseWizardService.componentReleases.push(release);

        } else {
          release = ComponentReleaseWizardService.componentReleases[existing];
          release.componentNames.add(componentName);
        }
    });

    LoggingService.logDebug(LoggingSource.COMPONENT_RELEASE_WIZARD_SERVICE,
      `Currently [${ComponentReleaseWizardService.componentReleases.length}] blocking actions are prepared to be updated by partial release.`);

    LoggingService.logDebug(LoggingSource.COMPONENT_RELEASE_WIZARD_SERVICE,
      `Currently [${ComponentReleaseWizardService.componentSet.size}] components are prepared to be released.`);

  }

  public static removeComponent(componentName: string) {
    ComponentReleaseWizardService.componentSet.delete(componentName);

    const existingComponentReleases
      = ComponentReleaseWizardService
      .componentReleases
      .filter(componentRelease => componentRelease.componentNames.has(componentName));

    const emptyComponentReleases = new Array<ComponentRelease>();
    if (existingComponentReleases.length > 0) {
      existingComponentReleases.forEach(element => {
          element.componentNames.delete(componentName);

          if (element.componentNames.size < 1) {
            emptyComponentReleases.push(element);
          }
      });
    }

    emptyComponentReleases.forEach(emptyElement => {
      const elementIndex = ComponentReleaseWizardService.componentReleases
        .findIndex(toDelete =>  toDelete.blockingActionNr === emptyElement.blockingActionNr);
      ComponentReleaseWizardService.componentReleases.splice(elementIndex, 1)
    });

    LoggingService.logDebug(LoggingSource.COMPONENT_RELEASE_WIZARD_SERVICE,
      `Currently [${ComponentReleaseWizardService.componentSet.size}] components are prepared to be released.`);

    LoggingService.logDebug(LoggingSource.COMPONENT_RELEASE_WIZARD_SERVICE,
      `Currently [${ComponentReleaseWizardService.componentReleases.length}] blocking actions are prepared to be partial released.`);

  }

  public static reset() {
    ComponentReleaseWizardService.componentReleases = new Array<ComponentRelease>();
    ComponentReleaseWizardService.componentSet = new Set<string>();
  }

  public static selectedToReleaseCount(): number {
    const selected =  ComponentReleaseWizardService
      .componentReleases.filter(release => release.selectedForRelease);

    return selected.length;
  }

  public static hasFailedReleases() {
    const failed =  ComponentReleaseWizardService
      .componentReleases.filter(release => release.state === ReleaseStatus.Failed);

    return failed.length > 0;
  }
}

export class ComponentRelease {
  public blockingReason: string;
  public blockingActionNr: string;
  public blockingActionId: string;
  public componentNames = new Set<string>();
  public releaseReason?: string;

  public selectedForRelease: boolean;
  public state: ReleaseStatus = ReleaseStatus.None;


  public get isLegacyBlocking(): boolean {
    return !this.blockingActionNr;
  }
}

export enum ReleaseStatus {
  None = 'NONE',
  Initiated = 'INITIATED',
  Done = 'DONE',
  Failed = 'FAILED'
}
