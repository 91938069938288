import {PdfCreatorConstants} from './pdf-creator-constants';

export class PdfComponentLabelTemplates {
  private static ENGINE_TIMING_BELT_TEMPLATE = {
    engineCodeAndEngineNumber: {
      type: 'text',
      position: {
        x: 68.93,
        y: 65.11
      },
      width: 46.82,
      height: 13.67,
      fontSize: 18,
      fontColor: '#000'
    },
    offsetK: {
      type: 'text',
      position: {
        x: 68.93,
        y: 72.11
      },
      width: 50.82,
      height: 13.67,
      fontSize: 18,
      fontColor: '#000'
    },
    lotNumber: {
      type: 'text',
      position: {
        x: 120,
        y: 66
      },
      width: 15,
      height: 13.67,
      fontSize: 10,
      fontColor: '#000'
    },
    lotSize: {
      type: 'text',
      position: {
        x: 128,
        y: 66
      },
      width: 15,
      height: 13.67,
      fontSize: 10,
      fontColor: '#000'
    },
    timingBeltLabel: {
      type: 'image',
      position: {
        x: 37,
        y: 89
      },
      width: 90,
      height: 25
    },
    pictorgram: {
      type: 'image',
      position: {
        x: 39.11,
        y: 60.98
      },
      width: 27.85,
      height: 26.78,
      alignment: 'left',
      fontSize: 13,
      characterSpacing: 0,
      lineHeight: 1
    },
    date: {
      type: 'text',
      position: {
        x: 133.17,
        y: 65.58
      },
      width: 30.41,
      height: 8.38,
      fontSize: 16,
      fontColor: '#000',
      alignment: 'right'
    },
    time: {
      type: 'text',
      position: {
        x: 133.38,
        y: 72.67
      },
      width: 30.41,
      height: 8.38,
      fontSize: 16,
      fontColor: '#000',
      alignment: 'right'
    },
    keyCode: {
      type: 'text',
      position: {
        x: 133.06,
        y: 89.68
      },
      width: 30.41,
      height: 8.38,
      fontSize: 34,
      fontColor: '#000',
      alignment: 'right'
    },
    plantAndCheckDigit: {
      type: 'text',
      position: {
        x: 132.59,
        y: 101.86
      },
      width: 30.41,
      height: 8.38,
      fontSize: 16,
      fontColor: '#000',
      alignment: 'right'
    },
    horizontalLine: {
      type: 'text',
      position: {
        x: 40,
        y: 85
      },
      width: 124.95,
      height: 0.4,
      alignment: 'left',
      fontSize: 1,
      characterSpacing: 0,
      lineHeight: 0.1,
      fontColor: '#ffffff',
      backgroundColor: '#343a40'
    }
  }

  private static ENGINE_TIMING_BELT_VECTO_TEMPLATE = {
    engineCodeAndEngineNumber: {
      type: 'text',
      position: {
        x: 54.93,
        y: 62.98
      },
      width: 46.82,
      height: 13.67,
      fontSize: 12,
      fontColor: '#000'
    },
    offsetK: {
      type: 'text',
      position: {
        x: 54.93,
        y: 68
      },
      width: 50.82,
      height: 13.67,
      fontSize: 12,
      fontColor: '#000'
    },
    lotNumber: {
      type: 'text',
      position: {
        x: 88.5,
        y: 63.48
      },
      width: 15,
      height: 13.67,
      fontSize: 8,
      fontColor: '#000'
    },
    lotSize: {
      type: 'text',
      position: {
        x: 94.5,
        y: 63.48
      },
      width: 15,
      height: 13.67,
      fontSize: 8,
      fontColor: '#000'
    },
    timingBeltLabel: {
      type: 'image',
      position: {
        x: 37,
        y: 84
      },
      width: 90,
      height: 25
    },
    pictorgram: {
      type: 'image',
      position: {
        x: 39.11,
        y: 60.98
      },
      width: 15.85,
      height: 14.78,
      alignment: 'left',
      fontSize: 13,
      characterSpacing: 0,
      lineHeight: 1
    },
    brandLogo: {
      type: 'image',
      position: {
        x: 137.06,
        y: 86.68
      },
      width: 14,
      height: 14,
      alignment: 'left',
      fontSize: 13,
      characterSpacing: 0,
      lineHeight: 1
    },
    brand: {
      type: 'text',
      position: {
        x: 129.06,
        y: 101.68
      },
      width: 30.82,
      height: 13.67,
      alignment: 'center',
      fontSize: 11,
      fontColor: '#000'
    },
    date: {
      type: 'text',
      position: {
        x: 100.17,
        y: 62.98
      },
      width: 20.41,
      height: 8.38,
      fontSize: 12,
      fontColor: '#000',
      alignment: 'right'
    },
    time: {
      type: 'text',
      position: {
        x: 100.38,
        y: 68.00
      },
      width: 20.41,
      height: 8.38,
      fontSize: 12,
      fontColor: '#000',
      alignment: 'right'
    },
    countyQualifier: {
      type: 'text',
      position: {
        x: 123.17,
        y: 62.98
      },
      width: 62.41,
      height: 8.38,
      fontSize: 12,
      fontColor: '#000',
      alignment: 'center'
    },
    typeApprovalLabel: {
      type: 'text',
      position: {
        x: 123.38,
        y: 68.00
      },
      width: 62.41,
      height: 8.38,
      fontSize: 12,
      fontColor: '#000',
      alignment: 'center'
    },
    keyCode: {
      type: 'text',
      position: {
        x: 110.06,
        y: 61.98
      },
      width: 30.41,
      height: 8.38,
      fontSize: 20,
      fontColor: '#000',
      alignment: 'right'
    },
    plantAndCheckDigit: {
      type: 'text',
      position: {
        x: 109.59,
        y: 69.26
      },
      width: 30.41,
      height: 8.38,
      fontSize: 12,
      fontColor: '#000',
      alignment: 'right'
    },
    horizontalLine: {
      type: 'text',
      position: {
        x: 40,
        y: 75
      },
      width: 124.95,
      height: 0.4,
      alignment: 'left',
      fontSize: 1,
      characterSpacing: 0,
      lineHeight: 0.1,
      fontColor: '#ffffff',
      backgroundColor: '#343a40'
    }
  }

  private static ENGINE_GROUP_LABEL_TEMPLATE = {
    groupLabel: {
      type: 'image',
      position: {
        x: 38,
        y: 121
      },
      width: 125,
      height: 30
    },
    groupLabelValue: {
      type: 'text',
      position: {
        x: 42.41,
        y: 152
      },
      width: 123.02,
      height: 13.67,
      fontSize: 19,
      fontColor: '#000',
      alignment: 'center'
    }
  }

  private static ENGINE_BOSCH_WITH_OFFSET_INJECTOR_TEMPLATE = {
    offSetLabel: {
      type: 'image',
      position: {
        x: 36,
        y: 165
      },
      width: 50,
      height: 50
    },
    groupLabelOffSet: {
      type: 'text',
      position: {
        x: 95,
        y: 171
      },
      width: 71,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    offSetK: {
      type: 'text',
      position: {
        x: 95,
        y: 179
      },
      width: 71,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    offSetW: {
      type: 'text',
      position: {
        x: 95,
        y: 187
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj1: {
      type: 'text',
      position: {
        x: 95,
        y: 195
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj2: {
      type: 'text',
      position: {
        x: 95,
        y: 203
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj3: {
      type: 'text',
      position: {
        x: 95,
        y: 211
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj4: {
      type: 'text',
      position: {
        x: 95,
        y: 219
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    }
  }

  private static ENGINE_BOSCH_WITHOUT_OFFSET_INJECTOR_TEMPLATE = {
    injectorLabel1: {
      type: 'image',
      position: {
        x: 36,
        y: 170
      },
      width: 140,
      height: 30
    },
    injectorLabel2: {
      type: 'image',
      position: {
        x: 36,
        y: 220
      },
      width: 140,
      height: 30
    },
    inj1: {
      type: 'text',
      position: {
        x: 40,
        y: 200
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj2: {
      type: 'text',
      position: {
        x: 135,
        y: 200
      },
      width: 35,
      height: 7,
      alignment: 'right',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    identifier12: {
      type: 'text',
      position: {
        x: 40,
        y: 165
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    engineCodeAndNumberInj12: {
      type: 'text',
      position: {
        x: 135,
        y: 165
      },
      width: 35,
      height: 7,
      alignment: 'right',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    engineCodeAndNumberInj34: {
      type: 'text',
      position: {
        x: 135,
        y: 215
      },
      width: 35,
      height: 7,
      alignment: 'right',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    identifier34: {
      type: 'text',
      position: {
        x: 40,
        y: 215
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj3: {
      type: 'text',
      position: {
        x: 40,
        y: 250
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj4: {
      type: 'text',
      position: {
        x: 135,
        y: 250
      },
      width: 35,
      height: 7,
      alignment: 'right',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    }
  }

  private static ENGINE_DELPHI_INJECTOR_TEMPLATE = {
    injectorOffSetLabel1: {
      type: 'image',
      position: {
        x: 36,
        y: 165
      },
      width: 45,
      height: 45
    },
    groupLabelInjectorOffSet: {
      type: 'text',
      position: {
        x: 88,
        y: 170
      },
      width: 71,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj1: {
      type: 'text',
      position: {
        x: 88,
        y: 178
      },
      width: 120,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj2: {
      type: 'text',
      position: {
        x: 88,
        y: 186
      },
      width: 120,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj3: {
      type: 'text',
      position: {
        x: 88,
        y: 194
      },
      width: 120,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj4: {
      type: 'text',
      position: {
        x: 88,
        y: 202
      },
      width: 120,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    injectorOffSetLabel2: {
      type: 'image',
      position: {
        x: 36,
        y: 218
      },
      width: 45,
      height: 45
    },
    engineCodeAndNumber: {
      type: 'text',
      position: {
        x: 88,
        y: 223
      },
      width: 120,
      height: 7,
      alignment: 'left',
      fontSize: 14,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj1_2: {
      type: 'text',
      position: {
        x: 88,
        y: 231
      },
      width: 120,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj2_2: {
      type: 'text',
      position: {
        x: 88,
        y: 239
      },
      width: 120,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj3_2: {
      type: 'text',
      position: {
        x: 88,
        y: 247
      },
      width: 120,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    inj4_2: {
      type: 'text',
      position: {
        x: 88,
        y: 255
      },
      width: 120,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    }
  }

  private static ENGINE_LABEL_BASE_TEMPLATE = {
    ...PdfCreatorConstants.TEMPLATE_HEADER,
    ...PdfCreatorConstants.TEMPLATE_FOOTER,
    ...PdfComponentLabelTemplates.ENGINE_GROUP_LABEL_TEMPLATE
  };


  static DEFAULT_TEMPLATE = {
    ...PdfComponentLabelTemplates.ENGINE_LABEL_BASE_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_TIMING_BELT_TEMPLATE,
  }

  static DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET_TEMPLATE = {
    ...PdfComponentLabelTemplates.ENGINE_LABEL_BASE_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_TIMING_BELT_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_BOSCH_WITH_OFFSET_INJECTOR_TEMPLATE
  }

  static DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET_VECTO_TEMPLATE = {
    ...PdfComponentLabelTemplates.ENGINE_LABEL_BASE_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_TIMING_BELT_VECTO_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_BOSCH_WITH_OFFSET_INJECTOR_TEMPLATE
  }

  static DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET_TEMPLATE = {
    ...PdfComponentLabelTemplates.ENGINE_LABEL_BASE_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_TIMING_BELT_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_BOSCH_WITHOUT_OFFSET_INJECTOR_TEMPLATE
  }

  static DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET_VECTO_TEMPLATE = {
    ...PdfComponentLabelTemplates.ENGINE_LABEL_BASE_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_TIMING_BELT_VECTO_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_BOSCH_WITHOUT_OFFSET_INJECTOR_TEMPLATE
  }

  static DIESEL_ENGINE_LABEL_DELPHI_TEMPLATE = {
    ...PdfComponentLabelTemplates.ENGINE_LABEL_BASE_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_TIMING_BELT_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_DELPHI_INJECTOR_TEMPLATE
  }

  static DIESEL_ENGINE_LABEL_DELPHI_VECTO_TEMPLATE = {
    ...PdfComponentLabelTemplates.ENGINE_LABEL_BASE_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_TIMING_BELT_VECTO_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_DELPHI_INJECTOR_TEMPLATE
  }

  static PETROL_ENGINE_LABEL_TEMPLATE = {
    ...PdfComponentLabelTemplates.ENGINE_LABEL_BASE_TEMPLATE,
    ...PdfComponentLabelTemplates.ENGINE_TIMING_BELT_TEMPLATE,
    offSetLabel: {
      type: 'image',
      position: {
        x: 36,
        y: 165
      },
      width: 50,
      height: 50
    },
    groupLabelOffSet: {
      type: 'text',
      position: {
        x: 95,
        y: 170.84
      },
      width: 71,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    offSetKW: {
      type: 'text',
      position: {
        x: 95,
        y: 178.72
      },
      width: 71,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    offSetN1: {
      type: 'text',
      position: {
        x: 95,
        y: 187.14
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    offSetN2: {
      type: 'text',
      position: {
        x: 131,
        y: 187.14
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    offSetN3: {
      type: 'text',
      position: {
        x: 95,
        y: 195.29
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    offSetN4: {
      type: 'text',
      position: {
        x: 131,
        y: 195.29
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    offSetW1: {
      type: 'text',
      position: {
        x: 95,
        y: 203.12
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    },
    offSetW2: {
      type: 'text',
      position: {
        x: 131,
        y: 203.12
      },
      width: 35,
      height: 7,
      alignment: 'left',
      fontSize: 11,
      characterSpacing: 0,
      lineHeight: 1
    }
  }


}
