import {ChartData, ChartDataset, ChartOptions, Color} from 'chart.js';



export class SortIndicator {
  name: string;
  productCenter: number;

  constructor(name: string, productCenter: number) {
    this.name = name;
    this.productCenter = productCenter;
  }
}

export class ActualTargetChart {

  public groupID: string;
  public groupName: string;
  public sortIndicator: SortIndicator;
  public barChartOptions: ChartOptions;
  public barChartLabels: string[];
  public barChartData: ChartData<'bar', Array<number>>;
  public showLegend: boolean;
  public isHidden: boolean;
  colors: Array<Color>;

  constructor(GroupID: string,
              BarChartOptions: ChartOptions,
              BarChartLabels: string[] = [],
              BarChartData: ChartData<'bar', Array<number>> = {
                datasets: (new Array<ChartDataset<'bar', Array<number>>>()), labels: new Array<string>()
              },
              ShowLegend: boolean = true,
              IsHidden: boolean = false,
              GroupName: string = '') {

    this.groupID = GroupID;
    this.groupName = GroupID;
    this.barChartOptions = BarChartOptions;
    this.barChartLabels = BarChartLabels;
    this.barChartData = BarChartData;
    this.showLegend = ShowLegend;
    this.isHidden = IsHidden;
  }
}
