import {Injectable} from '@angular/core';
import {generate, Template} from '@pdfme/generator';
import {PdfCreatorConstants} from './pdf-creator-constants';
import {
  VirtualEngineLabel
} from '../../components/component-profile/actions/labeling/VirtualEngineLabel';
import {DatePipe} from '@angular/common';
import {UserService} from '../../../modules/user/user.service';
import {PdfTemplateType} from './PdfTemplateType';
import {PdfComponentLabelTemplates} from './pdf-component-label-templates';
import {PdfComponentLabelCreator} from './pdf-component-label-creator';
import {PdfStatisticsCreator} from './pdf-statistics-creator';
import {PdfStatisticsTemplates} from './pdf-statistics-templates';


@Injectable({
  providedIn: 'root'
})
export class PdfCreatorService {

  constructor(private datePipe: DatePipe, private userService: UserService) { }

  public static getPdfTemplateByType(type: PdfTemplateType): any {
    if (type === PdfTemplateType.PETROL_ENGINE_LABEL) {
      return PdfComponentLabelTemplates.PETROL_ENGINE_LABEL_TEMPLATE;
    }

    if (type === PdfTemplateType.DIESEL_ENGINE_LABEL_DELPHI) {
      return PdfComponentLabelTemplates.DIESEL_ENGINE_LABEL_DELPHI_TEMPLATE;
    }

    if (type === PdfTemplateType.DIESEL_ENGINE_LABEL_DELPHI_VECTO) {
      return PdfComponentLabelTemplates.DIESEL_ENGINE_LABEL_DELPHI_VECTO_TEMPLATE;
    }

    if (type === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET) {
      return PdfComponentLabelTemplates.DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET_TEMPLATE;
    }

    if (type === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET_VECTO) {
      return PdfComponentLabelTemplates.DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET_VECTO_TEMPLATE;
    }

    if (type === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET) {
      return PdfComponentLabelTemplates.DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET_TEMPLATE;
    }

    if (type === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET_VECTO) {
      return PdfComponentLabelTemplates.DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET_VECTO_TEMPLATE;
    }

    if (type === PdfTemplateType.OUTPUT_STATISTIC) {
      return PdfStatisticsTemplates.OUTPUT_STATISTIC_TEMPLATE;
    }

    if (type === PdfTemplateType.STOP_NOTIFICATION_INFO) {
      // TODO: Export to own class
      return PdfStatisticsTemplates.STOP_NOTIFICATION_TEMPLATE;
    }


    return PdfComponentLabelTemplates.DEFAULT_TEMPLATE;
  }

  public async createPdf(templateType: PdfTemplateType = PdfTemplateType.UNKNOWN, args: any) {
    let basePdf = PdfCreatorConstants.BASE_PDF;
    if (templateType === PdfTemplateType.STOP_NOTIFICATION_INFO) {
       basePdf = PdfCreatorConstants.BASE_PDF_LANDSCAPE;
     }


      const template: Template = {
        schemas: [
          {
            ...PdfCreatorService.getPdfTemplateByType(templateType)
          }
        ],
        basePdf
      };

      await this.userService.getUserData();

      let result = {
        fileName: new Date().getTime() + '.pdf',
        pdf: new Uint8Array()
      }


      if (templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET
        || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET_VECTO
        || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET
        || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET_VECTO
        || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_DELPHI
        || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_DELPHI_VECTO
        || templateType === PdfTemplateType.PETROL_ENGINE_LABEL) {


         result = await this.generatePdfComponentLabel(args, templateType, template);
      }

      if (templateType === PdfTemplateType.OUTPUT_STATISTIC) {
        result = await this.generatePdfOutputStatistics(args, templateType, template);
      }

      if (templateType === PdfTemplateType.STOP_NOTIFICATION_INFO) {
        result = await this.generatePdfStopNotificationInfo(args, templateType, template);
      }


      this.downloadFile(result.pdf.buffer, result.fileName);
      // this.openFile(result.pdf.buffer);
    }

  public async createPdfAsync(templateType: PdfTemplateType = PdfTemplateType.UNKNOWN, args: any)  {
    let basePdf = PdfCreatorConstants.BASE_PDF;
    if (templateType === PdfTemplateType.STOP_NOTIFICATION_INFO) {
      basePdf = PdfCreatorConstants.BASE_PDF_LANDSCAPE;
    }


    const template: Template = {
      schemas: [
        {
          ...PdfCreatorService.getPdfTemplateByType(templateType)
        }
      ],
      basePdf
    };

    await this.userService.getUserData();

    let result = {
      fileName: new Date().getTime() + '.pdf',
      pdf: new Uint8Array()
    }


    if (templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET
      || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET_VECTO
      || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET
      || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET_VECTO
      || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_DELPHI
      || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_DELPHI_VECTO
      || templateType === PdfTemplateType.PETROL_ENGINE_LABEL) {


      result = await this.generatePdfComponentLabel(args, templateType, template);
    }

    if (templateType === PdfTemplateType.OUTPUT_STATISTIC) {
      result = await this.generatePdfOutputStatistics(args, templateType, template);
    }

    if (templateType === PdfTemplateType.STOP_NOTIFICATION_INFO) {
      result = await this.generatePdfStopNotificationInfo(args, templateType, template);
    }

    return result;
  }

  downloadFile(data: any, fileName: string): void {
    const blob = new Blob([data], { type: 'application/pdf' });
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  openFile(data: any) {
    const blob = new Blob([data], { type: 'application/pdf' });

    window.open(URL.createObjectURL(blob));
  }

  private async generatePdfComponentLabel(pdfCreatorArgs: any,
                                          templateType: PdfTemplateType, template: any): Promise<{ fileName: string; pdf: Uint8Array; }> {
    let label: VirtualEngineLabel;
    label = pdfCreatorArgs[0];

    const inputs = [
      {
        ...PdfComponentLabelCreator.getTimingBeltLabel(label,
          this.datePipe.transform(label.buildDate,'dd-MM-yy'),
          this.datePipe.transform(label.buildDate,'HH:mm:ss'),
          pdfCreatorArgs[1]),

        ...PdfComponentLabelCreator.getGroupLabel(pdfCreatorArgs[2], label.groupLabelValue),
        ...PdfComponentLabelCreator.getHeader(templateType),


        ...PdfComponentLabelCreator.getOffSetLabel(pdfCreatorArgs[3], label, templateType) ,

        ...PdfComponentLabelCreator.getInjectorLabel(pdfCreatorArgs[4], label, templateType, 1),
        ...PdfComponentLabelCreator.getInjectorLabel(pdfCreatorArgs[5], label, templateType, 2),

        ...PdfComponentLabelCreator.getDelphiInjectorLabel(pdfCreatorArgs[7], label, templateType),
        ...PdfComponentLabelCreator.getDelphiOffSetLabel(pdfCreatorArgs[8], label, templateType),

        ...PdfComponentLabelCreator.getFooter(this.userService.user.firstName + ' ' + this.userService.user.lastName,
          this.datePipe.transform(new Date(),'dd.MM.yy HH:mm:ss'), label.costCenter, pdfCreatorArgs[6])
      }
    ];

    const fileName = label.componentName + '.pdf';
    const pdf = await generate({ template, inputs});

    return {
      fileName,
      pdf
    };
  }

  private async generatePdfOutputStatistics(pdfCreatorArgs: any, templateType: PdfTemplateType, template: any) {
    const inputs = [
      {
        ...PdfComponentLabelCreator.getHeader(templateType),

        ...PdfStatisticsCreator.getStatisticsArea(pdfCreatorArgs[2]),

        ...PdfComponentLabelCreator.getFooter(this.userService.user.firstName + ' ' + this.userService.user.lastName,
          this.datePipe.transform(new Date(),'dd.MM.yy HH:mm:ss'), pdfCreatorArgs[0], pdfCreatorArgs[1])
      }
    ];

    const fileName = 'output.pdf';
    const pdf = await generate({ template, inputs});

    return {
      fileName,
      pdf
    };
  }

  private async generatePdfStopNotificationInfo(pdfCreatorArgs: any, templateType: PdfTemplateType, template: any) {
    const inputs = [
      {
        ...PdfComponentLabelCreator.getHeader(templateType, 'Stoppschild'),
        ...PdfStatisticsCreator.getStopNotificationContent(pdfCreatorArgs[0], pdfCreatorArgs[1]),
        ...PdfComponentLabelCreator.getFooter(this.userService.user.firstName + ' ' + this.userService.user.lastName,
        this.datePipe.transform(new Date(),'dd.MM.yy HH:mm:ss'), '', '')
      }
    ];

    const fileName = 'stop.pdf';
    const pdf = await generate({ template, inputs});

    return {
      fileName,
      pdf
    };
  }
}




