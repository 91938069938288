import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TrackingEvent} from '../../../models/tracking/trackingEvent';
import {TrackingService} from '../../../services/tracking.service';
import {TranslateService} from '@ngx-translate/core';
import {EventType} from '@cstx/volkswagen-mqs-tracking-service-client';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

@Component({
  selector: 'op-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  @Input() componentId: string;
  @Input() selfLink: string;
  @Input() contentContainerId: string;

  trackingEvents = new Array<TrackingEvent>();
  loading = false;
  viewSizeSM: boolean;
  viewSizeXS: boolean;

  constructor(private breakpointObserver: BreakpointObserver,
              private trackingService: TrackingService) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.viewSizeSM = true;
        } else {
          this.viewSizeSM = false;
        }
      });

    this.breakpointObserver
      .observe(['(max-width: 576px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.viewSizeXS = true;
        } else {
          this.viewSizeXS = false;
        }
      });

    if (this.componentId) {
      this.getTrackingEvents();
    }
  }

  getTrackingEvents() {
    this.loading = true;
    this.trackingService.getEventById(this.componentId)
      .then(result => {
        this.trackingEvents = result;
      })
      .finally(() => this.loading = false);
  }

  getTimelineStyle(eventType: EventType): string {
    /**
     * for later if differentiated styles may be wanted
     */
    switch (eventType) {
      case 'MANUFACTURED':
      case 'DONE' :
        return 'manufacturedEvent';
      case 'BLOCKED':
        return 'blockedEvent';
      case 'IN_REVIEW':
        return 'inReviewEvent';
      case 'INVENTORIED':
      case 'DISPATCH':
      case 'STACKING':
        return 'dispatchEvent';
      default:
        return 'unknownEvent';
    }
  }
}
