import {StackV2} from './stackv2';

export class StacksSlice {
  first?: boolean;
  last?: boolean;
  pageNumber?: number;
  size?: number;
  empty?: boolean;

  content?: Array<StackV2>;

  constructor() {
  }
}
