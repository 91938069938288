<div class="container-fluid">
  <div class="row d-flex pt-3">
    <div class="col-3 text-left">
      <i class="fas fa-chevron-circle-left pointer" aria-hidden="true" (click)="goBack()"></i>
    </div>
    <div class="col-6 text-center">
      <h4>{{ 'global.word.week' | translate  }} {{ this.currentWeek }} / {{ this.currentYear }}</h4>
      <div style="font-size: x-small">({{ getProductCenterLabel()? getProductCenterLabel(): "PC " + productCenterId }})</div>
    </div>
    <div class="col-3 text-right align-bottom">
      <i class="fas fa-chevron-circle-right pointer" *ngIf=notCurrentWeek() ria-hidden="true" (click)="goForward()"></i>
    </div>
  </div>
  <div class="row">
    <table class="table">
      <thead>
        <tr class="d-flex">
          <td class="col-sm">{{ 'global.word.costcenter' | translate  }}</td>
          <td class="col-sm"></td>
          <td class="col-sm">{{ 'global.weekday.monday' | translate  }}</td>
          <td class="col-sm">{{ 'global.weekday.tuesday' | translate  }}</td>
          <td class="col-sm">{{ 'global.weekday.wednesday' | translate  }}</td>
          <td class="col-sm">{{ 'global.weekday.thursday' | translate  }}</td>
          <td class="col-sm">{{ 'global.weekday.friday' | translate  }}</td>
          <td class="col-sm">{{ 'global.weekday.saturday' | translate  }}</td>
          <td class="col-sm">{{ 'global.weekday.sunday' | translate  }}</td>
          <td class="col-sm">{{ 'global.word.summary' | translate  }}</td>
        </tr>
      </thead>
      <tbody>
        <div *ngFor="let stat of stats" >
          <tr class="d-flex border-top custom-border-color">
            <td class="col-sm normal">{{ stat.costCenter }}</td>
            <td class="col-sm normal">{{ 'global.word.actual' | translate  }} </td>
            <td *ngFor="let element of stat.actuals" class="col-sm normal">{{ element }}</td>
            <td class="col-sm normal">{{ getSummary(stat.actuals) }}</td>
          </tr>
          <tr class="d-flex">
            <td class="col-sm normal" style="font-size: x-small">{{ getCostCenterLabel(stat) }}</td>
            <td class="col-sm normal">{{ 'global.word.target' | translate  }} </td>
            <td *ngFor="let element of stat.targets" class="col-sm normal">{{ element }}</td>
            <td class="col-sm normal">{{ getSummary(stat.targets) }}</td>
          </tr>
          <tr class="d-flex">
            <td class="col-sm normal"></td>
            <td class="col-sm normal">{{ 'global.abb.difference' | translate  }}</td>
            <td *ngFor="let element of stat.targets; index as i" class="col-sm" [class]="getBGColorClass(stat.actuals[i] - stat.targets[i])">{{ stat.actuals[i] - stat.targets[i] }}</td>
            <td class="col-sm" [class]="getBGColorClass(getSummary(stat.actuals) - getSummary(stat.targets))">{{ getSummary(stat.actuals) - getSummary(stat.targets) }}</td>
          </tr>
        </div>
      </tbody>
    </table>
  </div>
</div>
<op-spinner *ngIf='showSpinner'></op-spinner>
