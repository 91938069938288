export class AuditInformation {
  constructor(info?: any) {
    if (info) {
      try {
        this.modifiedBy = info.modifiedBy;
      } catch {}

      try {
        this.modifiedAt = info.modifiedAt;
      } catch {}

      try {
        this.createdAt = info.createdAt;
      } catch {}

      try {
        this.createdBy = info.createdBy;
      } catch {}
    } else {

      this.modifiedAt = new Date().toLocaleDateString();
      this.createdAt = new Date().toLocaleDateString();
    }
  }

  public createdAt: string;
  public createdBy: string;
  public modifiedAt: string;
  public modifiedBy: string;
}
