<div class="pointer" (click)="copyToClipboard()">
  <label *ngIf="displayNotification" class="px-1 small fade-out notification">{{"notification.copied" | translate }}</label>

<ng-container *ngIf="image && !isLoading && width !== '0' && height !== '0'">
  <img [width]="width" [height]="height" [src]="image"  alt=""/>
</ng-container>
<ng-container *ngIf="image && !isLoading && width === '0' && height === '0'">
  <img width="100%" height="100%" [src]="image" alt=""/>
</ng-container>
</div>

<ng-container *ngIf="isLoading && showSpinner">
  <div class="ml-3 spinner-border spinner-border-sm" ></div>
</ng-container>
