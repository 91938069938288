import {EventEmitter, Injectable} from '@angular/core';
import {
  DLQCleanupJobResponse, DLQCleanupJobResponsePageResponse, DLQCleanupJobService,
  DLQMessageControllerV2Service,
  DLQMessageResponse, DLQMessageResponseSliceResponse,
  DLQMessageService, DLQResendJobResponsePageResponse, DLQResendJobService
} from '@cstx/volkswagen-mqs-dead-letter-queue-service-client';
import {takeUntil} from 'rxjs/operators';
import {firstValueFrom, Subject} from 'rxjs';
import {DlqFilter} from './dlqFilter';
import {DLQResendJobResponse} from '@cstx/volkswagen-mqs-dead-letter-queue-service-client/model/models';
import {DlqResendJobResponse} from './dlqResendJobResponse';
import {DlqMessage} from './dlqMessage';
import {AuditInformation} from '../../../shared/models/auditInformation';
import {DlqDeleteJobsFilter} from './dlqDeleteJobsFilter';
import {DlqDeleteJob} from './dlqDeleteJob';
import {DlqMessagesResponse} from './dlqMessagesResponse';
import {DlqDeleteJobResponse} from './dlqDeleteJobResponse';
import {DlqResendJobsFilter} from './dlqResendJobsFilter';
import {DlqResendJob} from './dlqResendJob';
import {ErrorHandler} from "../../../shared/services/error-handler/error-handler";

// TODO: Handle reject / errors
// TODO: Handle takeUntil / aborts

@Injectable({
  providedIn: 'root'
})
export class DlqService {
  dlqMessagesResponse = new DlqMessagesResponse();
  dlqDeleteJobsResponse = new DlqDeleteJobResponse();
  dlqResendJobsResponse = new DlqResendJobResponse();


  private pendingGetDLQMessages$ = new Subject<void>();
  private pendingGetResendJobs$ = new Subject<void>();
  private pendingGetCleanupJobs$ = new Subject<void>();

  constructor(private dlqServiceControllerV2: DLQMessageControllerV2Service,
              private dlqServiceController: DLQMessageService,
              private dlqResendJobServiceController: DLQResendJobService,
              private dlqDeleteJobServiceController: DLQCleanupJobService) { }

  private onCancelPendingRequests() {
    return this.pendingGetDLQMessages$.asObservable();
  }

  private cancelGetDLQMessages() {
    this.pendingGetDLQMessages$.next();
  }
  private onCancelPendingResendJobsRequests() {
    return this.pendingGetResendJobs$.asObservable();
  }

  private cancelGetResendJobs() {
    this.pendingGetResendJobs$.next();
  }

  private onCancelPendingCleanupJobsRequests() {
    return this.pendingGetCleanupJobs$.asObservable();
  }

  private cancelGetCleanupJobs() {
    this.pendingGetCleanupJobs$.next();
  }

  // Dlq Message Handling
  getDlqMessageCount(filter: DlqFilter): Promise<number> {
    return new Promise<number>((resolve, reject) =>
      this.dlqServiceControllerV2.getDLQMessagesCountV2(filter.getFilterParams())
        .pipe(takeUntil(this.onCancelPendingRequests()))
        .toPromise()
        .then(r => {
          resolve(r);
        })
        .catch(e => {
          reject(e);
        })
    );
  }
  getDlqMessages(filter: DlqFilter, reset: boolean = false): Promise<DlqMessagesResponse> {
    return new Promise<DlqMessagesResponse>((resolve, reject) =>
      this.dlqServiceControllerV2.getDLQMessagesV2(filter.getAllParams())
        .pipe(takeUntil(this.onCancelPendingRequests()))
        .toPromise()
        .then(r => {
          const newDlqMessages = this.mapToDlqMessageArray(r);

          if (reset) {
            this.dlqMessagesResponse.messages = newDlqMessages;
          } else {
            this.dlqMessagesResponse.messages = this.dlqMessagesResponse.messages.concat(newDlqMessages);
          }

          this.dlqMessagesResponse.slice = {
            first: r.first,
            last: r.last,
            pageNumber: r.number,
            empty: r.empty,
            size: r.size
          };

          resolve(this.dlqMessagesResponse);
        })
        .catch(e => {
          reject(e);
        })
    );
  }
  getDlqMessageForComponent(componentId: string): Promise<Array<DlqMessage>> {
    return new Promise<Array<DlqMessage>>((resolve, reject) => {
      firstValueFrom(this.dlqServiceController.getDLQMessagesByComponentId({id: componentId}))
        .then(val => {
          resolve(val.map(message => this.mapToDlqMessage(message)));
        }).catch(e => reject(e));
    });
  }
  getDlqMessage(id: string): Promise<DlqMessage> {
    return new Promise<DlqMessage>((resolve, reject) =>
      this.dlqServiceController.getById({ id })
        .pipe(takeUntil(this.onCancelPendingRequests()))
        .toPromise()
        .then(r => {
          resolve(this.mapToDlqMessage(r));
        })
    );
  }


  // Dlq DeleteJob Handling
  getDlqDeleteJobs(filter: DlqDeleteJobsFilter, reset: boolean = false): Promise<DlqDeleteJobResponse> {{
    return new Promise<DlqDeleteJobResponse>((resolve, reject) => {
      this.dlqDeleteJobServiceController.getDLQCleanupJobs(filter.getAllParams())
        .pipe(takeUntil(this.onCancelPendingRequests()))
        .toPromise()
        .then(r => {
          const newDlqDeleteJobs = this.mapToDlqDeleteJobArray(r);

          if (reset) {
            this.dlqDeleteJobsResponse.jobs = newDlqDeleteJobs;
          } else {
            this.dlqDeleteJobsResponse.jobs = this.dlqDeleteJobsResponse.jobs.concat(newDlqDeleteJobs);
          }

          this.dlqDeleteJobsResponse.slice = {
            first: r.first,
            last: r.last,
            pageNumber: r.number,
            empty: r.empty,
            size: r.size
          };

          resolve(this.dlqDeleteJobsResponse);
        })
        .catch(e => {
          reject(e);
        });
    });
  }}
  createDeleteJob(channel: string, errorType: string, dateRange: Date[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.dlqDeleteJobServiceController.createDLQCleanupJob({ channel,
        errorType, sentAtFrom: dateRange[0].toISOString(), sentAtTo: dateRange[1].toISOString()})
        .pipe()
        .toPromise()
        .then(r => {
          resolve(true);
        }).catch(() => {
        reject (false);
      });
    });
  }

  deleteMessage(message: DlqMessage): Promise<void> {
    return new Promise<void> ((resolve, reject) => {
      this.dlqServiceController.deleteDLQMessage({id: message.id}).toPromise().then( () => {
        resolve();
      }).catch(error => {
        ErrorHandler.printError(error, message);
        reject(error);
      });
    });
  }

  resendMessage(message: DlqMessage): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dlqServiceController.resendDLQMessage({id: message.id}).toPromise()
      .then(() => {
        resolve();
      }).catch(error => {
        ErrorHandler.printError(error, message);
        reject(error);
      });
    });
  }

  // Dlq ResendJob Handling
  getDlqResendJobs(filter: DlqResendJobsFilter, reset: boolean = false): Promise<DlqResendJobResponse> {{
    return new Promise<DlqResendJobResponse>((resolve, reject) => {
      this.dlqResendJobServiceController.getDLQResendJobs(filter.getAllParams())
        .pipe(takeUntil(this.onCancelPendingRequests()))
        .toPromise()
        .then(r => {
          const newDlqResendJobs = this.mapToDlqResendJobArray(r);
          if (reset) {
            this.dlqResendJobsResponse.jobs = newDlqResendJobs;
          } else {
            this.dlqResendJobsResponse.jobs = this.dlqResendJobsResponse.jobs.concat(newDlqResendJobs);
          }

          this.dlqResendJobsResponse.slice = {
            first: r.first,
            last: r.last,
            pageNumber: r.number,
            empty: r.empty,
            size: r.size
          };

          resolve(this.dlqResendJobsResponse);
        })
        .catch(e => {
          reject(e);
        });
    });
  }}
  createResendJob(channel: string, errorType: string, dateRange: Date[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.dlqResendJobServiceController.createDLQResendJob({ channel,
        errorType, sentAtFrom: dateRange[0].toISOString(), sentAtTo: dateRange[1].toISOString()})
        .pipe()
        .toPromise()
        .then(r => {
          resolve(true);
        }).catch(() => {
        reject (false);
      });
    });
  }

  // Mapper
  private mapToDlqMessageArray(response: DLQMessageResponseSliceResponse): DlqMessage[] {
    const dlqMessageArray = new Array<DlqMessage>();

    response.content.forEach(e => {
      dlqMessageArray.push(this.mapToDlqMessage(e));
    });

    return dlqMessageArray;
  }
  private mapToDlqMessage = (response: DLQMessageResponse): DlqMessage => {
    const msg = new DlqMessage();

    msg.id = response.id;
    msg.sendAt = response.sentAt;
    msg.service = response.serviceName;
    msg.channel = response.channel;
    msg.errorType = response.errorType;
    msg.traceId = response.traceId;
    msg.originalMessage = response.message;
    msg.instance = response.instanceName;

    msg.errorMessage = response.errorMessage;
    msg.subject = response.subject;
    msg.sequence = response.sequence;
    msg.stackTrace = response.stackTrace;

    msg.auditInfos = new AuditInformation(response);
    return msg;
  }
  private mapToDlqDeleteJobArray(response: DLQCleanupJobResponsePageResponse): DlqDeleteJob[] {
    const dlqDeleteJobArray = new Array<DlqDeleteJob>();

    response.content.forEach(e => {
      dlqDeleteJobArray.push(this.mapToDlqDeleteJob(e));
    });

    return dlqDeleteJobArray;
  }
  private mapToDlqDeleteJob(response: DLQCleanupJobResponse) {
    const job = new DlqDeleteJob();

    job.id = response.id;
    job.batchSize = response.batchSize;
    job.cleanupJobStatus = response.cleanupJobStatus;
    job.numberOfDeletedMessages = response.numberOfDeleteMessages;
    job.lastRunAt = response.lastRunAt;
    job.numberOfMessages = response.numberOfMessages;
    job.timeRangeStart = response.sentAtFrom;
    job.timeRangeEnd = response.sentAtTo;
    job.channel = response.channel;
    job.errorType = response.errorType;

    job.progress = this.getProgressPercentage(job.numberOfDeletedMessages, job.numberOfMessages);

    job.auditInfos = new AuditInformation(response);

    return job;
  }
  private mapToDlqResendJobArray(response: DLQResendJobResponsePageResponse): DlqResendJob[] {
    const arr = new Array<DlqResendJob>();

    response.content.forEach(e => {
      arr.push(this.mapToDlqResendJob(e));
    });

    return arr;
  }
  private mapToDlqResendJob(response: DLQResendJobResponse): DlqResendJob {
    const job = new DlqResendJob();

    job.id = response.id;
    job.batchSize = response.batchSize;
    job.resendJobStatus = response.resendJobStatus;
    job.numberOfResendMessages = response.numberOfSentMessages;
    job.lastRunAt = response.lastRunAt;
    job.numberOfMessages = response.numberOfMessages;

    job.timeRangeStart = response.sentAtFrom;
    job.timeRangeEnd = response.sentAtTo;
    job.channel = response.channel;
    job.errorType = response.errorType;


    job.progress = this.getProgressPercentage(job.numberOfResendMessages, job.numberOfMessages);

    job.auditInfos = new AuditInformation(response);

    return job;
  }

  // Helper
  private getProgressPercentage(current: number, max: number): number {
    return max > 0 ? current * 100 / max : 0;
  }

}
