import {
  VirtualEngineLabel
} from '../../components/component-profile/actions/labeling/VirtualEngineLabel';
import {PdfCreatorConstants} from './pdf-creator-constants';
import {PdfTemplateType} from './PdfTemplateType';

export class PdfComponentLabelCreator {
  static getFooter(user: string, printDate: string, costCenter: string, itemLinkImage: any, ksu: string = undefined): any {
    return {
      printedBy: user,
      printedAt: printDate,
      costCenter,
      horizontalLine: ' ',
      cppLink: itemLinkImage,
      ksu: ksu === undefined ? 'KSU 0.2' : ksu
    }
  }

  static getHeader(type: PdfTemplateType, headerName?: string): any {
    return {
      pictorgram: PdfCreatorConstants.PICTOGRAM,
      header: ' ',
      headerLogo: PdfCreatorConstants.HEADER_LOGO,
      headerInternalImage: PdfCreatorConstants.HEADER_INTERNAL_LOGO,
      headerName: !headerName ? 'Komponenten-Label' : headerName
    }
  }

  static getTimingBeltLabel(label: VirtualEngineLabel, buildDate: string, buildTime: string, labelImage: any): any {
    return {
      engineCodeAndEngineNumber: '*' + label.engineCode
        + ' ' + label.engineNumber.substring(0, 3) + ' ' + label.engineNumber.substring(3, 6) + '*',
      offsetK: label.sznwdata ? '*' + label.sznwdata + '*' : '',
      date: buildDate,
      time: buildTime,
      plantAndCheckDigit: label.plant + '-' + label.engineLabelCheckDigit,
      keyCode: label.keyCode,
      timingBeltLabel: labelImage,
      lotSize: label.lotSize ? label.lotSize : '',
      lotNumber: label.lotNumber  ? label.lotNumber : '',
      countyQualifier: label.countryQualifier ? label.countryQualifier : '',
      typeApprovalLabel: label.typeApprovalLabel ? label.typeApprovalLabel : '',
      brandLogo: PdfCreatorConstants.BRAND_LOGO,
      brand: PdfCreatorConstants.BRAND,
    }
  }

  static getGroupLabel(labelImage: any, groupLabelText): any {
    return {
      groupLabel: labelImage,
      groupLabelValue: '*' + groupLabelText + '*'
    }
  }

  static getOffSetLabel(labelImage: any, label: VirtualEngineLabel, templateType: PdfTemplateType): any {
    if (templateType === PdfTemplateType.PETROL_ENGINE_LABEL) {
      return {
        offSetLabel: labelImage,
        groupLabelOffSet: label.groupLabelValue,
        offSetKW: label.sznwdata,
        offSetN1: label.offsetPairList[0][0],
        offSetN2: label.offsetPairList[0][1],
        offSetN3: label.offsetPairList[1][0],
        offSetN4: label.offsetPairList[1][1],
        offSetW1: label.offsetPairList[2][0],
        offSetW2: label.offsetPairList[2][1],
      }
    }

    if (templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET
    || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITH_OFFSET_VECTO) {
      return {
        offSetLabel: labelImage,
        groupLabelOffSet: '*' + label.groupLabelValue + '*',
        offSetK: '*' + label.sznwdata + '*',
        offSetW: '*' + label.offsetPairList[0][0] + '*',
        inj1: '*' + label.injectors.find(i => i.position === '1').bgn + label.injectors.find(i => i.position === '1').offset + '*',
        inj2: '*' + label.injectors.find(i => i.position === '2').bgn + label.injectors.find(i => i.position === '2').offset + '*',
        inj3: '*' + label.injectors.find(i => i.position === '3').bgn + label.injectors.find(i => i.position === '3').offset + '*',
        inj4: '*' + label.injectors.find(i => i.position === '4').bgn + label.injectors.find(i => i.position === '4').offset + '*',

      }
    }

    return {};
  }

  static getInjectorLabel(labelImage: any, label: VirtualEngineLabel, templateType: PdfTemplateType, pos: number): any {
    let imageConfig: any;

    if (pos === 1) {
      imageConfig = {
        injectorLabel1: labelImage
      }
    } else {
      imageConfig = {
        injectorLabel2: labelImage
      }
    }

    if (templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET
    || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_BOSCH_WITHOUT_OFFSET_VECTO) {
      return {
        ...imageConfig,
        inj1: label.injectors.find(i => i.position === '1').injectorLabel,
        inj2: label.injectors.find(i => i.position === '2').injectorLabel,
        inj3: label.injectors.find(i => i.position === '3').injectorLabel,
        inj4: label.injectors.find(i => i.position === '4').injectorLabel,
        engineCodeAndNumberInj12: '*' + label.engineCode + ' ' + label.engineNumber + '*',
        engineCodeAndNumberInj34: '*' + label.engineCode + ' ' + label.engineNumber + '*',
        identifier12: 'Motornummer:',
        identifier34: 'Motornummer:'
      }
    }

    return {}
  }

  static getDelphiInjectorLabel(labelImage: any, label: VirtualEngineLabel, templateType: PdfTemplateType) {
    if (templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_DELPHI
    || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_DELPHI_VECTO) {


      return {
        injectorOffSetLabel2: labelImage,
        engineCodeAndNumber: 'MOTORNUMMER: *' + label.engineCode + ' ' + label.engineNumber + '*',
        inj1_2: label.injectors.find(i => i.position === '1').injectorLabel,
        inj2_2: label.injectors.find(i => i.position === '2').injectorLabel,
        inj3_2: label.injectors.find(i => i.position === '3').injectorLabel,
        inj4_2: label.injectors.find(i => i.position === '4').injectorLabel,
      };
    }

    return {};
  }

  static getDelphiOffSetLabel(labelImage: any, label: VirtualEngineLabel, templateType: PdfTemplateType) {
    if (templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_DELPHI
      || templateType === PdfTemplateType.DIESEL_ENGINE_LABEL_DELPHI_VECTO) {

      return {
        injectorOffSetLabel1: labelImage,
        groupLabelInjectorOffSet: '*' + label.groupLabelValue + '*',
        inj1: '*' + label.injectors.find(i => i.position === '1').injectorLabel+ '*',
        inj2: '*' + label.injectors.find(i => i.position === '2').injectorLabel+ '*',
        inj3: '*' + label.injectors.find(i => i.position === '3').injectorLabel+ '*',
        inj4: '*' + label.injectors.find(i => i.position === '4').injectorLabel+ '*',
      };
    }

    return {};
  }

}
