<ng-container *ngIf="!small; else smallModeTemplate">
<span *ngIf="name" class="badge d-inline small" [ngClass]="badgeClass">
    <ng-container *ngIf="prependIcon">
    <i *ngIf="useIconsAndText || useIcons" class="fas fa-fw" [ngClass]="badgeIconClass"></i>
  </ng-container>
  <ng-container *ngIf="useIconsAndText || !useIcons">
    {{ badgeLabel }}
  </ng-container>
  <ng-container *ngIf="!prependIcon">
    <i *ngIf="useIconsAndText || useIcons" class="fas fa-fw" [ngClass]="badgeIconClass"></i>
  </ng-container>
</span>
</ng-container>

<ng-template #smallModeTemplate>
  <span *ngIf="name">
    <div class="pointer"
         style="width: 16px; height: 16px; border-radius: 50%;"
         [ngClass]="badgeClass" [tooltip]="toolTipTemplate"></div>
  </span>
</ng-template>

<ng-template #toolTipTemplate>
  {{ badgeLabel }}
</ng-template>
