<div class="row pt-5">
    <div class="col d-inline pt-3 text-center" style="color: #343a40">
      <img src="../../../assets/img/logo_black.png" alt="" class="d-inline"
           width="48" height="48" style="margin-top: -10px"/>
      <div class="d-inline" style="font-size: xx-large;">
        {{ 'navbar.title.cpo' | translate  }}
    </div>
      <br>
      Landing Zone
    </div>
</div>
<div class="row pt-5">
  <ng-container *ngIf="landingZoneError === LandingZoneError.NO_TARGET_ROUTE_CONFIGURATION">
    <div class="col text-center">
      Keine Zielseite für Applikation {{ client }} gesetzt!
    </div>
  </ng-container>

  <ng-container *ngIf="landingZoneError === LandingZoneError.NO_APPLICATION">
    <div class="col text-center">
      Die Landing Zone funktioniert nur für Applikationen. <br> Benutzern steht dieser Mechanismus aktuell nicht zur Verfügung!
    </div>
  </ng-container>


  <ng-container *ngIf="targetRoute && landingZoneError === LandingZoneError.NONE">
    <div class="col text-center">
        Weiterleitung nach {{ targetRoute }} erfolgt...
    </div>
  </ng-container>
</div>
