<div class="container-fluid pl-0 pl-sm-3 pr-0 pr-sm-3" style="min-height: 100px">
  <table class="table table-hover mb-0">
    <tr class="row">
      <td class="col-8 pl-0">
        <h4 class="d-inline">{{ title }}</h4>

        <ng-container *ngIf="betaFeature">
          <op-beta-flag></op-beta-flag>
        </ng-container>
        <ng-container *ngIf="alphaFeature && !betaFeature">
          <op-alpha-flag></op-alpha-flag>
        </ng-container>


        <ng-container *ngIf="selfLink">
          <small><op-copy-to-clipboard-icon [content]="selfLink" [customIconColor]="'gray'" [customIconClass]="'fa-link'"></op-copy-to-clipboard-icon></small>
        </ng-container>
       <ng-container *ngIf="description">
        <small class="description">
          <p>
          {{ description }}
          </p>
        </small>
       </ng-container>
      </td>
      <td class="col-4 pl-0  text-right">
        <ng-container *ngIf="collapsable">
        <button type="button" class="btn btn-sm btn-outline-dark"
                (click)="expand()">{{ expandButtonText | translate }}</button>
        </ng-container>
        <ng-container *ngIf="cancelable">
          <button type="button" class="btn btn-sm btn-outline-dark"
                  (click)="cancel()">{{ 'button.cancel' | translate }}</button>
        </ng-container>
      </td>
    </tr>
  </table>
  <div id="contentContainer_{{ contentContainerId }}" [hidden]="!expanded" style="height: auto">
    <ng-container [ngTemplateOutlet]="contentTemplateRef"></ng-container>
  </div>
</div>

<ng-template #contentTemplateRef>
  <div *ngIf="!hasContent && !loading" class="col-12 m-0 pb-0 pt-0">
    <div class="col text-center">{{ 'global.nothing-to-show' | translate }}</div>
  </div>
  <div *ngIf="!hasContent && loading" class="col-12 m-0 pb-0 pt-0">
    <div class="col text-center">
      <op-spinner-small *ngIf="loading"></op-spinner-small>
    </div>
  </div>


  <ng-container *ngIf="hasContent" [ngTemplateOutlet]="contentTemplate"></ng-container>
</ng-template>
