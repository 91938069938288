import {OffSetResponse} from '@cstx/volkswagen-mqs-quality-management-service-client';

export class OffSet {
  public get value(): string {
    return this._value;
  }

  public set value(value: string) {
    if (!this.raw) {
      this.raw = value;
    }

    value = value.trimEnd();
    value = value.trimEndCharacter(',');
    value = value.trimEndCharacter('=');

    if (value.startsWith('Injector_')) {
      value = value.replace('Injector_', '');
      this.position = value.substring(0, 1);

      value = value.substring(2);
      if (value.length <=25) {
        this.vendor = 'Bosch';
        this._value = value.substring(12, 19);

      } else {
        this.vendor = 'Delphi';
        this._value = value.substring(12, 20);
      }
    } else {

      if (this.type === 'Injector') {
        if (this.vendor === 'Bosch') {
          this._value = value.substring(12, 19);
        } else if (this.vendor === 'Delphi') {
          this._value = value.substring(12, 20);
        } else {
          this._value = value;
        }
      } else {
          if (this.type === 'Camshaft') {
            if (value.startsWith('XN')) {
              this.position = 'Zylinder ' + value.substring(2,3);
            }

            if (value.startsWith('XW')) {
              this.position = 'Sensor ' + value.substring(2,3);
            }

            this._value = value;
          } else {
            this._value = value;
          }
      }
    }
  }

  public id: string;
  public name: string;
  public raw: string;
  private _value: string;
  public stored: boolean

  public vendor: string;
  public position: string;
  public type: 'Crankshaft' | 'Camshaft' | 'Injector';

  constructor(response?: OffSetResponse, raw?: string) {
    if (response) {
      this.id = response.id;
      this.name = response.type;
      this.type = response.type;

      this.raw = raw ? raw : response.value;
      this.value = response.value;

      this.stored = true;
    }
  }
}
