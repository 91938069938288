import {ChartOptions} from 'chart.js';

export const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 0
  },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'end',
      offset: 0
    },
    legend: {
      display: false
    }
  },
  scales: {
    y: {
      min: 0
    }
  },
  layout: {
    padding: {
      top: 20
    }
  }
};
