import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {ProductcenterWeeklyComponent} from './statistics/productcenter-weekly/productcenter-weekly.component';
import {PageNotFoundComponent} from './pages/not-found/page-not-found.component';
import {DetailComponent} from './infos/detail/detail.component';
import {ListComponent} from './infos/list/list.component';
import {ShowComponent} from './infos/show/show.component';
import {InfosComponent} from './infos/infos.component';
import {EmployeeInfoComponent} from './infos/employee-info/employee-info.component';
import {SfmBoardComponent} from './sfm-board/sfm-board.component';
import {LandingZoneComponent} from './landing-zone/landing-zone.component';
import {NotAllowedComponent} from "./pages/not-allowed/not-allowed.component";
import {SendMessageComponent} from "../../shared/components/send-message/send-message.component";

const routes: Routes = [
  {
    path: '', component: HomeComponent, children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'landing-zone',
        component: LandingZoneComponent
      },
      {
        path: 'sfm-board',
        component: SfmBoardComponent
      },
      {
        path: 'stats/weekly/:id',
        component: ProductcenterWeeklyComponent
      },
      {
        path: 'info/list/:id',
        component: DetailComponent
      },
      {
        path: 'info/list',
        component: ListComponent
      },
      {
        path: 'info/show',
        component: ShowComponent
      },
      {
        path: 'info/employeeInfo',
        component: EmployeeInfoComponent
      },
      {
        path: 'info/home',
        component: InfosComponent
      },
      {
        path: 'notfound',
        component: PageNotFoundComponent
      },
      {
        path: 'forbidden',
        component: NotAllowedComponent
      },
      {
        path: 'send-message/:messageType',
        component: SendMessageComponent
      },
      {
        path: '*',
        redirectTo: 'notfound'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {
}
