import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {LogisticsDataService} from '../../services/backend/logistics-data.service';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {v4 as uuid} from 'uuid';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {MetadataService} from '../../services/backend/metadata.service';

declare let $: any;

@Component({
  selector: 'op-dispatcher-task-form',
  templateUrl: './dispatcher-task-form.component.html',
  styleUrls: ['./dispatcher-task-form.component.scss']
})
export class DispatcherTaskFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input('taskGiver') taskGiver: Observable<TaskIdentifier>;
  @Output('editFinished') editFinished: EventEmitter<boolean> = new EventEmitter<boolean>();

  public editDispatcherTask: UntypedFormGroup;
  public blocked = false;
  public inputId: string;

  public partNumber: string;
  public alternativePartNumbers: Array<string> = new Array<string>();

  private unsubscribe: Subject<void> = new Subject<void>();

  private jqueryAccessor: string;
  private taskId: string;


  constructor(private logisticsDataService: LogisticsDataService,
              private formbuilder: UntypedFormBuilder,
              private translate: TranslateService,
              private metadataService: MetadataService) {
    this.inputId = uuid();
    this.jqueryAccessor = '#' + this.inputId;
  }

  ngOnInit() {
    this.editDispatcherTask = this.formbuilder.group({
      amountValidUntil: [undefined, Validators.required],
      amount: [undefined, [Validators.required, Validators.pattern('^[0-9]*$')]],
      comment: [''],
      alternativePartnumber: [undefined, Validators.required],
      state: [undefined, [Validators.required, noRestrictedValuesValidator]]
    });
    this.initDaterangePicker();
  }

  ngOnDestroy() {
    this.unsubscribe.next()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taskGiver) {
      this.unsubscribe.next();
      this.taskGiver.pipe(takeUntil(this.unsubscribe)).subscribe(task => {
        this.handleNewTask(task);
      });
    }
  }

  private handleNewTask(task: TaskIdentifier) {

    this.editDispatcherTask.patchValue( { amountValidUntil: undefined, amount: undefined, comment: '' });
    this.editDispatcherTask.disable();
    this.blocked = true;

    this.partNumber = task.partNumber;

    this.getData(task).finally(() => {
      this.blocked = false;
      this.editDispatcherTask.enable();
      this.initDaterangePicker();
    });
}

  private async getData(task: TaskIdentifier) {
    const response = await this.logisticsDataService.getDispatcherTask(task.year.toString(10), task.calendarWeek.toString(10), task.partNumber);
    this.editDispatcherTask.get('state').patchValue(response.state);
    this.taskId = response.id;
    this.alternativePartNumbers = new Array<string>()
    if (response.alternativePartNumbers) {
      this.alternativePartNumbers = response.alternativePartNumbers;
    }
    if (response.dispatcherFeedback) {
      this.editDispatcherTask.get('amountValidUntil').patchValue(moment(response.dispatcherFeedback.amountValidUntil));
      this.editDispatcherTask.get('amount').patchValue(response.dispatcherFeedback.amount);
      this.editDispatcherTask.get('comment').patchValue(response.dispatcherFeedback.comment);
      this.editDispatcherTask.get('comment').patchValue(response.dispatcherFeedback.comment);
      this.editDispatcherTask.get('alternativePartnumber').patchValue(response.dispatcherFeedback.alternativePartnumber);
    }
    if (!this.editDispatcherTask.get('alternativePartnumber').getRawValue()) {
      this.editDispatcherTask.get('alternativePartnumber').patchValue(this.partNumber);
    }
    // await this.logisticsDataService.getDispatcherTaskById(this.taskId)
    // console .log(await this.metadataService.getAlternativePartNumbers(task.partNumber))

  }

  onSubmit() {
    if (!this.editDispatcherTask.invalid) {
      this.blocked = true;
      this.editDispatcherTask.disable();
      const value = this.editDispatcherTask.getRawValue();
      this.logisticsDataService.setDispatcherTask(this.taskId, value.amountValidUntil,
        value.amount,
        value.comment,
        value.state).then(saved => {
          if (saved) {
            this.editFinished.emit(true);
          }
      })
    }
  }

  abortEdit() {
    this.editFinished.next(false);
  }

  private initDaterangePicker() {
    $(this.jqueryAccessor).daterangepicker({
      startDate: undefined,
      timePicker: false,
      autoUpdateInput: false,
      timePicker24Hour: true,
      showWeekNumbers: true,
      linkedCalendars: false,
      singleDatePicker: true,
      locale: {
        cancelLabel: this.translate.instant('search.clear'),
        applyLabel: this.translate.instant('search.apply'),
        format: 'DD.MM.YYYY',
        daysOfWeek: [
          this.translate.instant('global.weekday.su'),
          this.translate.instant('global.weekday.mo'),
          this.translate.instant('global.weekday.tu'),
          this.translate.instant('global.weekday.we'),
          this.translate.instant('global.weekday.th'),
          this.translate.instant('global.weekday.fr'),
          this.translate.instant('global.weekday.sa'),
        ],
        monthNames: [
          this.translate.instant('global.month.january'),
          this.translate.instant('global.month.february'),
          this.translate.instant('global.month.march'),
          this.translate.instant('global.month.april'),
          this.translate.instant('global.month.may'),
          this.translate.instant('global.month.june'),
          this.translate.instant('global.month.july'),
          this.translate.instant('global.month.august'),
          this.translate.instant('global.month.september'),
          this.translate.instant('global.month.october'),
          this.translate.instant('global.month.november'),
          this.translate.instant('global.month.december'),
        ],
        firstDay: 1
      }
    });

    $(this.jqueryAccessor).on('apply.daterangepicker', (ev, picker) => {
      this.editDispatcherTask.get('amountValidUntil').patchValue(moment(picker.startDate));
    });
    $(this.jqueryAccessor).on('show.daterangepicker', (ev, picker) => {
    });
    $(this.jqueryAccessor).on('hide.daterangepicker', (ev, picker) => {
    });
    $(this.jqueryAccessor).on('cancel.daterangepicker', (ev, picker) => {
    });
  }

}

export class TaskIdentifier {
  partNumber: string;
  year: number;
  calendarWeek: number;


}

export const noRestrictedValuesValidator: ValidatorFn  = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    return value === 'New' || value === 'Closed' || value === 'Reopened' ? { valuesRestrictedToBackend: {value: control.value } } : null;
}
