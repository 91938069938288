import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../../core/services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {LanguageService} from '../../../../core/services/language.service';
import {ConfigService, PORTALMODE} from '../../../../core/services/config.service';
import {LocalStorageManagerService} from "../../../../shared/services/local-storage-manager.service";


@Component({
  selector: 'op-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  languages: Array<any>;
  userRoleName: string;
  portalMode: PORTALMODE;

  private fwdUrl: string;
  private queries: string;
  private anchor: string;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private languageService: LanguageService,
              private configService: ConfigService) {
  }

  ngOnInit(): void {
    this.fwdUrl = this.route.snapshot.queryParamMap.get('fwdUrl');
    this.queries = this.route.snapshot.queryParamMap.get('queries');
    this.anchor = this.route.snapshot.queryParamMap.get('anchor');
    this.languages = this.languageService.languages;
    this.userRoleName = this.configService.getUserRole();

    this.portalMode = this.configService.getPortalMode();
  }

  onLogin() {
    if (this.queries) {
      LocalStorageManagerService.setItem('storedQueries', this.queries);
    }
    if (this.anchor) {
      LocalStorageManagerService.setItem('storedAnchor', this.anchor);
    }
    this.authService.login(this.fwdUrl);
  }
  onLogout() {
    this.authService.logout();
  }

  languageSelected(value: string) {
    this.languageService.setLanguage(value);
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }
}
