<div class="card h-100">
  <div *ngIf="title" class="card-header pl-2 pb-2 pt-2" [ngClass]="cardErrorMode()">
    <div class="container">
      <div class="row">
        <div class="col-sm pl-0">
          <ng-container [ngTemplateOutlet]="leftHeaderTemplate"></ng-container>
        </div>
        <div class="col-sm">
          <ng-container [ngTemplateOutlet]="midHeaderTemplate"></ng-container>
        </div>
        <div class="col-sm text-right">
          <ng-container [ngTemplateOutlet]="rightHeaderTemplate"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body p-0">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #leftHeaderTemplate>
  <h5 class="m-0">{{ title }}</h5>
</ng-template>
<ng-template #rightHeaderTemplate>
  <div class="col-sm text-right" *ngIf="showActions">
    <span>
    <!-- <ng-container *ngIf="showUpload"><i class="fa fa-cloud-upload file-pointer font-size-large" (click)="onUploadPressed()"></i></ng-container>-->
    <ng-container *ngIf="showRefresh"><i class="fas fa-sync-alt file-pointer" [ngClass]="refreshAnimation()" (click)="onRefreshClicked()"></i></ng-container>
    <ng-container [ngTemplateOutlet]="rightHeaderTemplateExtension"></ng-container>
    </span>
  </div>
  <div *ngIf="bannerText" class="col-sm text-right">
    {{ bannerText }}
  </div>
</ng-template>
