<op-detail-section-wrapper
  [title]="'component-profile-v2.events.title' | translate"
  [description]="'component-profile-v2.events.description'  | translate"
  [contentTemplate]="contentTemplateRef"
  [hasContent]="trackingEvents.length >= 1"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <table class="table table-hover">
    <tr class="row pl-3 pr-3" *ngIf="!viewSizeSM">
      <td class="cpp-column-fixed-width-200 p-2">
      </td>
      <td class="col p-2 small d-none d-md-inline-block ">
        {{'component-profile-v2.events.origin' | translate}}
      </td>
      <td class="cpp-column-fixed-width-200 p-2 small" >
        {{'component-profile-v2.events.datetime' | translate}}
      </td>
    </tr>
    <tbody>
    <op-loading-spinner *ngIf="loading"></op-loading-spinner>
    <tr *ngIf="trackingEvents.length === 0 && !loading" class="row pt-3 pb-3" >
      <div class="col pl-0 text-center">
        <small> {{ 'global.nothing-to-show' | translate }}</small>
      </div>
    </tr>
    <tr class="row pl-0 pl-sm-3 pr-3" *ngFor="let element of trackingEvents">
      <td class="cpp-column-fixed-width-200 p-2 small">
        <op-component-state-badge [name]="element.eventType"></op-component-state-badge>
      </td>
      <td class="col p-2 small d-none d-md-inline-block small">
        {{ element.origin }}
      </td>
      <td class="cpp-column-fixed-width-200 p-2 small" [class.cpp-border-top-none]="viewSizeXS">
        <op-datetime-show [dateTime]="element.eventTime"></op-datetime-show>
      </td>
    </tr>
    </tbody>
  </table>
</ng-template>
