<div *ngIf="showUploadOverlay" class="cpp-upload-area-overly pt-5 h-100" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
  <h2 class="text-center padding-vertical">
    <i class="fas fa-cloud-upload-alt font-size-large-vert-align text-center"></i>
  </h2>
  <h3 class="text-center">{{'files.modal.upload.text' | translate}}</h3>
  <h4 class="text-center">{{'files.modal.upload.or' | translate}}</h4>
  <p class="padding-vertical text-center">
    <button type="button" class="btn btn-secondary" (click)="fileInput.click()">
      <span>{{'button.browse' | translate}}</span>
      <input #fileInput type="file" [hidden]="true" (change)="onUploadRequested($event)" style="display:none;"/>
    </button>
    <button type="button" class="btn btn-secondary ml-2" (click)="onUploadAbort()">
      <span>{{'button.cancel' | translate}}</span>
    </button>
  </p>
</div>
