import {SortOrder} from './sortOrder';

export abstract class FilterBase {
  public pagingSize: number;
  public sort: string[];
  public totalPages: number;
  public currentPageIndex: number;
  public sortOrder = SortOrder.Descending;

  public sortOn(column: string): string[] {
    let cColumn = '';
    let cSortOrder: string = SortOrder.Descending.toLowerCase();
    if (this.sort && this.sort.length > 0) {
      const tmp: Array<string> = this.sort[0].split(',');
      cColumn = tmp[0];
      cSortOrder = tmp.length > 1 ? tmp[1] : SortOrder.Descending.toLowerCase();
    }
    if (cColumn === column && cSortOrder === SortOrder.Descending.toLowerCase()) {
        this.sort = [column + ',' + SortOrder.Ascending.toLowerCase()];
    } else {
      this.sort = [column + ',' + SortOrder.Descending.toLowerCase()];
    }

    return this.sort;
  }

  abstract getAllParams();
}
