import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'op-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})

export class SearchComponent implements OnChanges {
  @Output() searchEvent = new EventEmitter();
  @Input() searchString;
  @Input() clearSearch = true;
  @Input() reset: boolean;
  @Input() disabled: boolean;
  searchStringSubject = new Subject<string>();

  constructor() {
    this.initSubjectObserver();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.reset) {
      this.clearFilter();
    }
  }

  initSubjectObserver() {
    this.searchStringSubject
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        this.searchEvent.emit(value);
      });
  }

  clearFilter(): void {
    this.searchString = '';
    this.searchStringSubject.next(this.searchString);
  }

  onNgModelChange(value: any) {
    if  (value.length > 1 || value.length === 0) {
      this.searchStringSubject.next(value);
    }
  }

  clearSearchString() {
    this.searchString = '';
    this.onNgModelChange('');
  }
}
