<op-detail-section-wrapper
  [title]="sectionTitle ? sectionTitle : 'file-explorer.title' | translate"
  [description]="sectionDescription ? sectionDescription : 'file-explorer.description' | translate"
  [contentTemplate]="contentTemplateRef"
  [hasContent]="true"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId"
  [collapsable]="collapsable">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <div class="row">
    <div class="pr-0" [class.col-4]="!viewSizeSM" [class.col]="viewSizeSM" [hidden]="viewSizeSM && (showPreview || uploadRequested)">
      <op-treeview [componentType]="componentType"
                   [componentId]="componentId"
                   [componentCostCenter]="componentCostCenter"
                   (fileSelectedEvent)="fileSelected($event)"
                   (showUploadEvent)="showUpload()"
                   [updateTreeviewRequestEvent]="triggerTreeviewReload"
                   [componentName]="componentName"
                   [filePreSelectionFilterValue]="filePreSelectionFilterValue"></op-treeview>
    </div>
    <div [hidden]="!uploadRequested" class="col">
      <op-upload [componentType]="componentType"
                 [componentId]="componentId"
                 [componentCostCenter]="componentCostCenter"
                 [onShowUploadOverlay]="showUploadRequestEvent"
                 (uploadRequestEndEvent)="onUploadRequestEnd($event)"></op-upload>
    </div>
    <div [hidden]="uploadRequested || (viewSizeSM && !showPreview)"  [class.col-8]="!viewSizeSM" [class.col]="viewSizeSM" class="pl-0">
      <op-preview [newPreviewRequest]="previewRequestEvent"
                  [useCloseBtn]="viewSizeSM"
                  (triggerPreviewClose)="showPreview=false">
      </op-preview>
    </div>
  </div>
</ng-template>


