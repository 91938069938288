import {CppItemBase} from '../../../models/cppItemBase';
import {ComponentResponse, StackResponse} from '@cstx/volkswagen-mqs-tracking-service-client';
import {StackComponent} from './stackComponent';
import {EngineResponse, EngineState} from '@cstx/volkswagen-mqs-engine-service-client';
import {PartResponse} from '@cstx/volkswagen-mqs-part-service-client';
import {StackIdentifierType} from '../../../models/tracking/stackIdentifierType';
import {LogisticalState} from '../../../../modules/engine/list/list.component';
import {Engine} from './engine';

export class StackV2 extends CppItemBase {
  public id: string;
  public label: string;
  public identifier: string;


  public stackedAt: string;
  public dispatchedAt: string;

  public components = new Array<StackComponent>();


  public engines = new Array<EngineResponse>();

  public enginesV2 = new Array<Engine>();

  public parts = new Array<PartResponse>();
  public componentsRaw = new Array<ComponentResponse>();

  public transportUnit: string;
  public transportDestination: string;
  public deliveryNote: string;
  public customer: string;
  public storageArea: string;
  public storageGroup: string;
  public storagePlace: string;
  public storageZone: string;
  public costCenter: string;
  public rfid: string;

  public get selfLink(): string {
    return '/tracking/stacks/' + this.identifier;
  }

  public set selfLink(value: string) {
    super.selfLink = value;
  }

  private _logisticalState: LogisticalState = undefined;
  public get logisticalState(): LogisticalState{
    if (!this._logisticalState) {
      this._logisticalState = this.getLogisticalState();
    }

    return this._logisticalState;
  }

  public get stackIdentifierType(): StackIdentifierType {
    const gtlTemplate: RegExp = /^(?=.{22}$)([(1-6)(J)]{2})([A-Z]{2})([0-9]{9})([0-9]{9})$/;
    const mbkTemplate: RegExp = /^(?=.{13})([1]{1})([0-9]{13})$/;

    if (gtlTemplate.test(this.identifier)) {
      return StackIdentifierType.GTL;
    }

    if (mbkTemplate.test(this.identifier)) {
      return StackIdentifierType.MBK;
    }

    return StackIdentifierType.Unknown;
  }

  constructor(response?: StackResponse) {
    super();

    if (response) {
      this.id = response.id;
      this.label = response.stackLabel;
      this.identifier = response.stackIdentifier;
      this.stackedAt = response.stackedAt;
      this.dispatchedAt = response.dispatchedAt;
      this.transportDestination = response.transportDestination;
      this.transportUnit = response.transportUnit;
      this.deliveryNote = response.deliveryNote;
      this.componentsRaw = response.components;
      this.storageArea = response.storageArea;
      this.storagePlace = response.storagePlace;
      this.storageGroup = response.storageGroup;
      this.costCenter = response.costCenter;
      this.storageZone = response.storageZone;
      this.customer = response.customer;
      this.rfid = response.rfid;
    }
  }

  public getStackPartNumbers(): string[] {
    const partNumbersOnStack = new Array<string>();

    this.enginesV2.forEach(engine => {
      if (!partNumbersOnStack.includes(engine.keyCode)) {
        partNumbersOnStack.push(engine.keyCode);
      }
    });

    this.parts.forEach(part => {
      if (!partNumbersOnStack.includes(part.partNumber)) {
        partNumbersOnStack.push(part.partNumber);
      }
    });

    return partNumbersOnStack;
  }

  private getLogisticalState() {
    let state = LogisticalState.FREE;

    if (this.enginesV2 && this.enginesV2.length > 0) {
        this.enginesV2.forEach(engine => {
          if (engine.engineBlockedState !== 'NONE' ||
              engine.engineReworkState === 'PENDING' ||
              engine.engineTestState !== 'OK' ||
              engine.engineState === EngineState.Unknown) {

            state = LogisticalState.UNFREE;
          }
        });
    }

    return state;
  }

  public getLogisticalStateInsights(): Array<LogisticalStateInsight> {
    let insights: Array<LogisticalStateInsight>;

    if (this.enginesV2 && this.enginesV2.length > 0) {
      insights = new Array<LogisticalStateInsight>();

      const engineBlockedStateInsight = new LogisticalStateInsight();
      engineBlockedStateInsight.problem = 'engineBlockedState';
      insights.push(engineBlockedStateInsight);

      const engineReworkStateInsight = new LogisticalStateInsight();
      engineReworkStateInsight.problem = 'engineReworkState';
      insights.push(engineReworkStateInsight);


      const engineTestStateInsight = new LogisticalStateInsight();
      engineTestStateInsight.problem = 'engineTestState';
      insights.push(engineTestStateInsight);


      this.enginesV2.forEach(engine => {
        if (engine.engineBlockedState !== 'NONE') {
          insights.find(i => i.problem === 'engineBlockedState').count++;
        }

        if (engine.engineReworkState === 'PENDING') {
          insights.find(i => i.problem === 'engineReworkState').count++;
        }

        if (engine.engineTestState !== 'OK') {
          insights.find(i => i.problem === 'engineTestState').count++;
        }
      });
    }

    return insights;
  }
}

export class LogisticalStateInsight {
  public problem: string;
  public count: number = 0;
}
