<div class="form-group border p-2">
  <div class="row align-items-center">
    <div class="col">
      <h6 *ngIf="title && title.length > 0">{{title}}</h6>
    </div>
    <div class="col text-right" *ngIf="closeIconEnabled">
      <i class="fas fa-times pointer" (click)="canceled()"></i>
    </div>
  </div>
  <ng-container *ngIf="!messageSent">
    <ng-container *ngIf="!fixedRecipients">
    <label for="recipients" class="small">{{'shared.components.message.to' | translate}}</label>
    <div class="input-group input-container pl-2" id="recipients">
      <div *ngIf="recipients.length > 0" class="tag-container pr-0 mr-1">
        <div class="tag" *ngFor="let recipient of recipients; index as i">
          <div class="tag-part tag-left small" [class.tag-invalid]="!validateRecipient(recipient)">
            <span class="m-auto">{{recipient}}</span>
          </div>
          <div class="tag-part tag-right small" [class.tag-invalid]="!validateRecipient(recipient)">
            <i class="fas fa-times pl-1 ml-1 border-left pointer" (click)="removeRecipient(i)"></i>
          </div>
        </div>
      </div>
      <input style="min-width: 200px; height: calc(1.5em + .75rem); margin-right: 1px"
             type="text" class="form-control form-control-sm border-0 pl-0"
             [(ngModel)]="recipientInput"
             (blur)="onBlur()"
             (focus)="onFocus()"
             (keydown.enter)="onEnter()"
             (keydown.escape)="onEscape()"
             (keydown.backspace)="onBackspace($event)"
             (keydown.arrowUp)="onUp($event)"
             (keydown.arrowDown)="onDown($event)"
             (ngModelChange)="filterRecipients()">
      <div [class.d-none]="!useRecipientHistory || !showDropdown || filteredRecipientHistory.length === 0" class="input-dropdown">
        <ul class="list-unstyled mb-0 option-container">
          <li style="white-space: nowrap" class="pointer px-2 py-1"
              (click)="selectOption(option)"
              [class.bg-secondary]="dropdownIndex === i"
              [class.text-white]="dropdownIndex === i"
              *ngFor="let option of filteredRecipientHistory; index as i">
            {{ option }}
          </li>
        </ul>
      </div>
    </div>
    </ng-container>
    <label for="subject" class="small">{{'shared.components.message.subject' | translate}}</label>
    <input type="text" class="form-control form-control-sm" id="subject" [disabled]="!editSubjectEnabled"
           [(ngModel)]="subject" style="height: calc(1.5em + 0.75rem + 5px);">
    <ng-container *ngIf="addNoteEnabled">
      <label for="note" class="small">{{'shared.components.message.note' | translate}}</label>
      <textarea type="text" class="form-control form-control-sm" id="note" [rows]="noteRowsCount" [(ngModel)]="note"></textarea>
    </ng-container>
    <ng-container *ngIf="(attachments && attachments.length > 0) || allowUserAttachments">
      <label for="attachments" class="small">{{'shared.components.message.attachments' | translate}}</label>
      <div id="attachments" class="attachment-container">
        <div class="tag" *ngFor="let attachment of attachments; index as i">
          <div class="tag-part tag-left small">
            <span class="m-auto">{{attachment.name}}</span>
          </div>
          <div class="tag-part tag-right small">
            <i class="fas fa-times pl-1 ml-1 border-left pointer" (click)="removeAttachment(i)"></i>
          </div>
        </div>
        <input #fileInput type="file" [hidden]="true" style="display:none;" (change)="addAttachment($event)">
        <div *ngIf="allowUserAttachments"><i class="fas fa-plus-circle pointer" (click)="onAddAttachment()"></i></div>
      </div>
    </ng-container>
    <button type="button" class="btn btn-sm btn-primary mt-3" (click)="send()"
            [disabled]="messageLoading">{{'button.submit' | translate}}</button>
    <button type="button" class="btn btn-sm btn-warning mt-3 ml-2" (click)="canceled()">{{'button.cancel' | translate}}</button>

    <ng-container *ngIf="optionalProvideSendMeCopy">
      <div class="form-check col small">
        <input class="form-check-input" type="checkbox" value="" id="releaseAll"
               [(ngModel)]="sendCopyToUser">
        <label class="form-check-label" for="releaseAll">
          {{'send-message.send-copy-to-user' | translate}}
        </label>
      </div>
    </ng-container>


    <div class="small text-danger" *ngIf="messageError">{{'logging.share.message-error' | translate}}</div>
  </ng-container>
  <ng-container *ngIf="messageSent">
    <p class="small">{{'logging.share.message-sent' | translate}}</p>
  </ng-container>
</div>
