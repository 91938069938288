import {Injectable} from '@angular/core';
import {AuthService} from '../../../core/services/auth.service';
import {
  ApplicationControllerService,
  ApplicationStatus,
  PerformanceMetric,
  SaveStatusRequestParams
} from '@cstx/volkswagen-mqs-application-management-service-client';
import {firstValueFrom} from 'rxjs';
import {ConfigService} from '../../../core/services/config.service';
import {ProcessError} from '@cstx/volkswagen-mqs-application-management-service-client/model/processError';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {LocalStorageManagerService} from '../local-storage-manager.service';
import {GetActiveAppsRequestParams} from '@cstx/volkswagen-mqs-application-management-service-client/api/applicationController.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationManagementService {
  private readonly identifier: string = null;
  private startUpTime  = new Date();

  constructor(private authService: AuthService,
              private applicationManagementService: ApplicationControllerService,
              private configService: ConfigService) {


    this.identifier = LocalStorageManagerService.getItem('amsProcessIdentifier');
    if (!this.identifier) {

      try {
        this.identifier = crypto.randomUUID();
      }
      catch (error) {
        LoggingService.logError(LoggingSource.APPLICATION_MANAGEMENT_SERVICE, 'Could not generate guid as amsProcessIdentifier.', error);
        this.identifier = new Date().getTime().toString();
      } finally {
        LocalStorageManagerService.setItem('amsProcessIdentifier', this.identifier);
      }
    }
  }

  public async postStatus(metricList: PerformanceMetric[], errorList: ProcessError[]): Promise<void> {
    if (!this.authService.isApplicationUser) {
      LoggingService.logDebug(LoggingSource.APPLICATION_MANAGEMENT_SERVICE, 'Sending current status to application management service aborted. Non application user logged in.');
      return;
    }

    let status: ApplicationStatus;
    status = {
      applicationIdentifier: this.authService.currentUser.username,
      healthState: 'HEALTHY', // TODO: detect state
      recordedAt: new Date().toISOString(),
      processHealth: [
        {
          processName: this.identifier,
          healthState: 'HEALTHY',
          performanceMetricList: [
            {
              metricType: 'date',
              name: 'startUpTime',
              value: this.startUpTime.toISOString()
            },
            {
              metricType: 'string',
              name: 'version',
              value: this.configService.getVersion(),
            },
            {
              metricType: 'string',
              name: 'userAgent',
              value: navigator.userAgent
            },
            ...metricList
          ],

          errors: [
            ...errorList
          ]
        }
      ]
    }

    let params: SaveStatusRequestParams;
    params = {
      applicationStatus: status
    }

    await firstValueFrom(this.applicationManagementService.saveStatus(params));
    LoggingService.logDebug(LoggingSource.APPLICATION_MANAGEMENT_SERVICE, 'Current status successfully send to application management service.');
  }

  async getActiveApps(): Promise<number> {
    let count = 0;

    try {

      const params: GetActiveAppsRequestParams = {
        minutes: 15
      }

      count = await firstValueFrom(this.applicationManagementService.getActiveApps(params));


    } catch (error) {
      LoggingService.logError(LoggingSource.APPLICATION_MANAGEMENT_SERVICE,
        'Receiving active apps count failed with error.', error);
    }

    return count;
  }
}
