import {Component} from '@angular/core';
import {LogLevel} from '../logLevel';
import {LoggingService} from '../logging.service';
import {
  AttributeEntry,
  FilterValue,
  InputType
} from '../../../shared/components/guided-filter-input/guided-filter-input.component';
import {LogFilter} from '../logFilter';
import {NewElementCheckerService} from '../../../shared/services/new-element-checker.service';

@Component({
  selector: 'op-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.scss']
})
export class LoggingComponent {
  public searchAliasMappings: Array<AttributeEntry> = [
    {key: 'source', value: 'source', inputType: InputType.String},
    {key: 'message', value: 'message', inputType: InputType.String}
  ];

  public searching: boolean;
  public showMessageComponent = false;

  private filterValues: Array<FilterValue> = new Array<FilterValue>();
  private listFilter: LogFilter;

  protected readonly LogLevel = LogLevel;
  protected readonly LoggingService = LoggingService;

  protected logExport: {name: string, data: Blob};

  protected readonly NewElementCheckerService = NewElementCheckerService;

  public setFilterValues(filterValues: Array<FilterValue>) {
    this.filterValues = filterValues;
  }

  public async onSearch(filterValues?: Array<FilterValue>) {
    this.searching = true;
    if (!this.listFilter) {
      this.listFilter = new LogFilter();
    }

    if (filterValues) {
      this.setFilterValues(filterValues);
    }

    this.applyFilterValuesToNewFilter();

    LoggingService.filter(this.listFilter);
    this.searching = false;
  }

  private applyFilterValuesToNewFilter() {

    this.listFilter = new LogFilter();

    this.filterValues.forEach(filterValue => {
      this.listFilter[filterValue.attribute] = filterValue.value;
    });
  }

  public onShareLog() {
    if (!this.showMessageComponent) {
      this.showMessageComponent = true;
      this.logExport = {name: `cpp-applicationlog-${(new Date()).getTime()}.txt`, data: LoggingService.createExportFile()};
    } else {
      this.showMessageComponent = false;
    }
  }

  public onSharingCanceled() {
    this.showMessageComponent = false;
  }
}
