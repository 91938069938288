import {FilterBase} from '../filterBase';
import {Slice} from '../slice';

export class StackFilter extends FilterBase {
  public stackIdentifier = '';
  public stackLabel = '';
  public rfid = '';
  public transportUnit = '';
  public transportDestination = '';
  public stackedAtFrom = '';
  public stackedAtTo = '';
  public dispatchedAtFrom = '';
  public dispatchedAtTo = '';
  public storageArea = '';
  public storageGroup = '';
  public storageZone = '';
  public storagePlace = '';
  public deliveryNote = '';
  public costCenter = '';
  public customer = '';

  public slice: Slice;

  constructor() {
    super();

    this.pagingSize = 50;
    this.totalPages = 1;
    this.currentPageIndex = 0;
    this.sort = [ 'stackedAt,desc' ];
  }

  public getAllParams() {
    return {...this.getPageableParams(), ...this.getFilterParams()};
  }
  public getFilterParams() {
    return {

      stackIdentifier: this.stackIdentifier,
      stackLabel: this.stackLabel,
      stackedAtFrom: this.stackedAtFrom,
      stackedAtTo: this.stackedAtTo,
      transportDestination: this.transportDestination,
      transportUnit: this.transportUnit,
      storageArea: this.storageArea,
      storageGroup: this.storageGroup,
      storageZone: this.storageZone,
      storagePlace: this.storagePlace,
      deliveryNote: this.deliveryNote,
      customer: this.customer,
      costCenter: this.costCenter,
      rfid: this.rfid,
    };
  }

  public getPageableParams() {
    return {
      page: this.currentPageIndex,
      size: this.pagingSize,
      sort: this.sort,
    };
  }
}
