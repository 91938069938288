import {ComponentState} from './componentState';
import {ComponentType} from './componentType';

export class Component {
  id: string;
  name: string;
  state: ComponentState;
  type: ComponentType;

  constructor(name: string, type: ComponentType) {
    this.name = name;
    this.type = type;
    this.state = ComponentState.Unknown;
  }
}
