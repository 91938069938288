import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'op-form-help-text',
  templateUrl: './form-help-text.component.html',
  styleUrls: ['./form-help-text.component.scss']
})
export class FormHelpTextComponent implements OnInit {
  @Input() helpText = '';
  @Input() ariaId = 'noAreaIdGiven';
  @Input() formControlInvalid: boolean;

  constructor() { }

  ngOnInit(): void {

  }

}
