import {Component, Input} from '@angular/core';
import {Info} from '../../info';
import {
  FileExplorerService
} from "../../../../../shared/components-merged/usecase-file-explorer/file-explorer.service";
import {ActivatedRoute} from "@angular/router";
import {InfosService} from "../../infos.service";
import {RolesService} from "../../../../../core/services/roles.service";

@Component({
  selector: 'op-show-entry',
  templateUrl: './show-entry.component.html',
  styleUrls: ['./show-entry.component.scss']
})
export class ShowEntryComponent {
  @Input() entry = new Info();
  devMode: any;

  constructor(private fileExplorerService: FileExplorerService,
              private route: ActivatedRoute,
              private infoService: InfosService,
              public roles: RolesService) {

    this.route.queryParamMap.subscribe(params => {
      this.devMode = params.get('devMode') === 'true';
    });
  }

  getDirectLink(token: string, s3Key: string): string {
    return this.fileExplorerService.getUseCaseDirectLink(token, s3Key, 'infos');
  }

  encode(OriginalName: string): string {
    const utf8 = require('utf8');

    if (!OriginalName) {
      return OriginalName;
    }

    return utf8.encode(OriginalName);
  }

  makePdf(entry: Info) {
    // this.infoService.makePdf(entry);
    this.infoService.downloadPdfAsync(entry);
  }
}
