<div *ngIf="showUploadArea">
  <div class="cpp-upload-area-overly pt-5 h-100" (drop)="onFileDropped($event)" (dragover)="onDragOver($event)">
    <h2 class="text-center padding-vertical">
      <i class="fas fa-cloud-upload-alt font-size-large-vert-align text-center"></i>
    </h2>
    <h3 *ngIf="multiFileUpload" class="text-center">{{'files.modal.upload.text' | translate}}</h3>
    <h3 *ngIf="!multiFileUpload" class="text-center">{{'files.modal.upload.text-single' | translate}}</h3>
    <h4 class="text-center">{{'files.modal.upload.or' | translate}}</h4>
    <p class="padding-vertical text-center">
      <button type="button" class="btn btn-secondary" (click)="fileInput.click()">
        <span>{{'button.browse' | translate}}</span>
        <input #fileInput type="file" [accept]="getAcceptedExtensions()" [hidden]="true" (change)="onFileAdded($event)" style="display:none;"/>
      </button>
      <ng-container *ngIf="!uploadV2Configuration.noCancelButton">
        <button type="button" class="btn btn-secondary ml-2" (click)="onUploadAbort()">
          <span>{{'button.cancel' | translate}}</span>
        </button>
      </ng-container>

    </p>
  </div>
</div>


<ng-container *ngIf="useEmbeddedFileList && embeddedFileList.length > 0">
  <div class="row pb-3" *ngFor="let file of embeddedFileList; index as i">
    <div class="col" [class.font-color-red]="!multiFileUpload && i > 0">
      <i class="fas fa-file fa-fw d-inline"></i>
      {{ file.name }} {{ !multiFileUpload && i > 0 ? ' *aktuell ist nur eine Datei erlaubt. Wird verworfen! ' : '' }}
    </div>
    <div class="cpp-column-fixed-actions">
      <i class="cpp-icon-delete" (click)="removeFile(i)"></i>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="acceptedExtensions.length > 0 && invalidExtension">
  <span class="font-color-red">Eine ungültige Dateierweiterung wurde ausgewählt. Erlaubt sind: </span>
  <div *ngFor="let extension of acceptedExtensions">
    {{ extension }}
  </div>
</ng-container>
