export class Printer {

  public static printers = [
    new Printer('-', 'defaultPrinter'),
    new Printer('Büro IT', 'itOffice'),
    new Printer('Sperrteam','blockingTeam'),
    new Printer('Sperrteam 80mm','blockingTeam80mm'),
    new Printer('Zentrale Nacharbeit','centralRework'),
    new Printer('Nacharbeit Meisterbüro','reworkMasterOffice'),
    new Printer('Nacharbeit 7138','Nacharbeit-7138'),
    new Printer('Nacharbeit 7228','Nacharbeit-7228'),
    new Printer('Nacharbeit 7267','Nacharbeit-7267')
  ]

  constructor(
    private name: string,
    private value: string
  ) {}

  getSelectionValues(): {label: string, value: string} {
    return {label: this.name, value: this.value}
  }
}
