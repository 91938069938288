import {Injectable} from '@angular/core';
import {
  ClientsControllerService,
  WebsocketControllerService
} from '@cstx/volkswagen-mqs-notification-service-client';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private webSocketService: WebsocketControllerService,  private clientsControllerService: ClientsControllerService) {

  }


  public async getWebSocketClients(): Promise<string[]> {
    try  {

      const response = await
        firstValueFrom(this.clientsControllerService.getClients());

     const clients = Object.values(response);

     if (clients.length > 0 ) {
       return clients;
     }


    } catch (error) {
      LoggingService.logError(LoggingSource.OTHER, 'Error on getting websocket clients occurred.')
    }

    return null;
  }


  public async getWebSocketToken(): Promise<string> {
    try  {

      return await
        firstValueFrom(this.webSocketService.getWebSocketToken());

    } catch (error) {
      LoggingService.logError(LoggingSource.OTHER, 'Error on getting websocket token occurred.')
    }

    return null;
  }


}
