import {BlockingActionStatsResponse} from '@cstx/volkswagen-mqs-quality-management-service-client/model/blockingActionStatsResponse';

export class BlockingActionStatistic {
  blockingActionState?: string;
  total?: number;
  released?: number;
  pending?: number;
  blocked?: number;
  parts?: number;
  engines?: number;
  statisticLoaded: boolean;
  blockingActionId?: string;

  constructor(response: BlockingActionStatsResponse, blockingActionId: string) {
    this.blockingActionId = blockingActionId;

    this.total = response.total;
    this.released = response.released;
    this.pending = response.pending;
    this.blocked = response.blocked;
    this.engines = response.engines;
    this.parts = response.parts;

    this.statisticLoaded = true;
  }
}
