import {Component, Input, OnInit} from '@angular/core';
import {EnterpriseRoleProviderService} from '../../../core/services/enterprise-role-provider.service';
import {MessageType} from './message-type';
import {ConfigService} from '../../../core/services/config.service';
import {DatePipe} from '@angular/common';
import {SendMessageService} from './send-message.service';
import {Router} from '@angular/router';
import {STAGE} from '../../../core/models/stage';
import {OAuthService} from 'angular-oauth2-oidc';
import {AuthService} from '../../../core/services/auth.service';

@Component({
  selector: 'op-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {
  @Input('messageType') messageType: MessageType = MessageType.PROBLEM_REPORT;

  public get fixedRecipients(): string[] {
    if (this._fixedRecipients && this._fixedRecipients.length > 0) {
      return this._fixedRecipients;
    }
    return [ 'probleme@volkswagen-mqs.de'];
  }
  public get body(): string {
    return this._body;
  }
  public get subject(): string {
    return this._subject;
  }
  public get attachments(): Array<{name: string, data: Blob} > {
    return this._attachments;
  }

  constructor(private datePipe: DatePipe,
              private router: Router) {
  }

  private _subject: string;
  private _body: string;
  private _attachments: Array<{name: string, data: Blob}>;
  private _date: string;
  private _fixedRecipients: string[];
  private _stage: string;
  private _version: string;

  protected readonly EnterpriseRoleProviderService = EnterpriseRoleProviderService;
  protected readonly SendMessageService = SendMessageService;
  protected readonly MessageType = MessageType;

  public messageGenerating = true;

  protected readonly ConfigService = ConfigService;
  protected readonly STAGE = STAGE;

  public ngOnInit(): void {
    this.messageGenerating = true;

    this._stage   = SendMessageService.currentStage;
    this._version = ConfigService.currentVersion;
    this._date    = this.datePipe.transform(new Date(), 'dd.MM.yyyy HH:mm:ss');
    this._subject = SendMessageService.generateSubject(this.messageType, this._date, this._stage, this._version);
    this._body    = SendMessageService.generateBody(this.messageType, this._date, this._stage, this._version);

    if (this.messageType === MessageType.PROBLEM_REPORT) {
      this._fixedRecipients = new Array<string>();
      this._fixedRecipients.push(...ConfigService.getSupportEmail());

      this._attachments = SendMessageService.attachments;
    }

    this.messageGenerating = false;
  }

  public messageSend() {
    this.navigate();
  }

  public messageSendAborted() {
    this.navigate();
  }

  private navigate() {
    if (SendMessageService.previousLocation && SendMessageService.previousLocation !== this.router.url ) {
      const navigateTo = SendMessageService.previousLocation;
      SendMessageService.reset();

      this.router.navigateByUrl(navigateTo, { skipLocationChange: false });
    } else {
      this.router.navigate(['home/info/show']);
    }
  }
}
