import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'op-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {
 @Input() targetAmount: number = 0;
 @Input() currentAmount: number = 0;

 @Input() barColor:'darkblue';
 @Input() textColor:'white';
 @Input() label: string = undefined;
 @Input() disabled = false;

 @Input() loading: boolean = true;

 public progress: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    this.progress = this.calculateProgress();
  }


  private calculateProgress() {

    if (this.loading) {
      return null;
    }

    let progress = 0;

    if (this.currentAmount >= 0 && this.targetAmount > 0) {
      progress = this.currentAmount / this.targetAmount * 100;
    }

    return  Math.round(progress);
  }
}
