import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NewElementCheckerService {

  constructor() { }

  public static getClass(age: string, maxAgeInSeconds: number = 10) {
    const date = new Date(age);
    const currentDate = new Date();
    currentDate.setSeconds(currentDate.getSeconds() - maxAgeInSeconds);

    if (date >= currentDate) {
      return 'cpp-list-is-new';
    } else {
      return 'cpp-list-is-not-new';
    }
  }
}
