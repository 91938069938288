import {Injectable, isDevMode} from '@angular/core';
import {EnterpriseRoleProviderService} from './enterprise-role-provider.service';
import {UserConfigurationService} from '../../shared/services/backend/user-configuration.service';
import {ApplicationManagementService} from '../../shared/services/backend/application-management.service';
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class UserStatisticsProviderService {

  constructor(private userConfigurationService: UserConfigurationService,
              private applicationManagementService: ApplicationManagementService,
              private authService: AuthService) {

    EnterpriseRoleProviderService.onPermissionsLoaded.subscribe(() => {
        this.sendStatistics();
        this.getActiveAppsCount();
    })
  }

  public static activeUserCount = 1;
  public static activeUserIds: string[] = undefined;
  public static activeAppsCount = 0;

  private requestInProgress: boolean;
  private activeAppsRequestInProgress: boolean;

  public async sendStatistics(): Promise<void> {
    if (this.authService.isApplicationUser) {
      return;
    }

    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    await this.userConfigurationService.putUserStats(JSON.stringify(EnterpriseRoleProviderService.effectiveUserClaims));

    const count = await this.userConfigurationService.getUsersCount();
    UserStatisticsProviderService.activeUserCount = count;

    if (EnterpriseRoleProviderService.hasRequiredRole('role_admin')) {
       UserStatisticsProviderService.activeUserIds = await  this.userConfigurationService.getUsers();
    }

    this.requestInProgress = false;

    setTimeout(() => {
      this.sendStatistics();
    }, 120000)
  }

  private async getActiveAppsCount() {
    if (this.authService.isApplicationUser) {
      return;
    }

    if (this.activeAppsRequestInProgress) {
      return;
    }

    this.activeAppsRequestInProgress = true;

    UserStatisticsProviderService.activeAppsCount = await this.applicationManagementService.getActiveApps();
    this.activeAppsRequestInProgress = false;

    setTimeout(() => {
      this.getActiveAppsCount();
    }, 60000)
  }
}
