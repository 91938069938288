import {EventEmitter, Injectable} from '@angular/core';
import {PreviewRequest} from '../previewRequest';
import {FilePreview} from '../filePreview';
import {FileType} from '../fileType';
import {FileExplorerService} from '../file-explorer.service';
import fileSize from 'filesize';

/**
 * TODO: Extend this static service, to provide all necessary functions currently part of
 * TODO: preview.component.ts.
 * TODO: Currently this is ONLY to simplify the employee info pdf showing.
 */

@Injectable({
  providedIn: 'root'
})

export class PreviewService {
  public static createdDataUrls = new Array<string>();
  public static onNewFileLoadedEvent = new EventEmitter<FilePreview>();
  public static onPreviewEvent = new EventEmitter<FilePreview | PreviewRequest>();

  public static getFileType(fileName: string): FileType {
    if (!fileName) {
      return;
    }

    let fileExtension = fileName.split('.').pop();
    fileExtension = fileExtension.toLowerCase();

    switch (fileExtension) {
      case 'img':
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'svg':
      case 'bmp':
        return FileType.Image;

      case 'csv':
      case 'txt':
      case 'json':
      case 'yaml':
        return FileType.Text;

      case 'pdf':
        return FileType.Pdf;

      case 'bin':
      case 'rmb':
      case 'dat':
        return FileType.Binary;
    }

    return FileType.Other;
  }

  public static clear() {
    /**
     * Under all circumstances we need to avoid having orphaned data urls.
     * They block garbage collections of blobs.
     */

    PreviewService.createdDataUrls.forEach(dataUrl => {
      URL.revokeObjectURL(dataUrl);
    })

    PreviewService.createdDataUrls = new Array<string>();
  }

  public static async createFilePreview
  (request: PreviewRequest, useCase: string, fileExplorerService: FileExplorerService): Promise<FilePreview> {

    const response =
      await fileExplorerService.GetUseCaseFile(useCase, request.s3Key);

    // TODO: replace with constructor mapping or getFilePreview from PreviewRequest mapping
    const filePreview = new FilePreview();
    filePreview.fileName = request.fileName;
    filePreview.OriginalName = request.fileName;
    filePreview.fileType = PreviewService.getFileType(filePreview.fileName);
    filePreview.Uploaded = request.createdAt;
    filePreview.Size = fileSize(request.size as unknown as number);

    if (response) {
      filePreview.Blob = response;
    }

    switch (filePreview.fileType) {
      case FileType.Image:
        break;
      case FileType.Text:
        break;
      case FileType.Pdf:
        filePreview.file = filePreview.Blob;
        break;
      default:
        PreviewService.processOther(filePreview);
    }

    return filePreview;
  }

  public static async createFilePreviewFromBlob
  (fileName: string, blob: Blob): Promise<FilePreview> {


    // TODO: replace with constructor mapping or getFilePreview from PreviewRequest mapping
    const filePreview = new FilePreview();
    filePreview.fileName = fileName;
    filePreview.OriginalName = fileName;
    filePreview.fileType = PreviewService.getFileType(filePreview.fileName);

    if (blob) {
      filePreview.Blob = blob;
    }

    switch (filePreview.fileType) {
      case FileType.Image:
        break;
      case FileType.Text:
        break;
      case FileType.Pdf:
        filePreview.file = filePreview.Blob;
        break;
      default:
        PreviewService.processOther(filePreview);
    }

    return filePreview;
  }


  private static processOther(filePreview: FilePreview) {

  }
}
