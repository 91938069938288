<div>
  <div class="d-flex">
    <div class="col-8 small m-auto pl-0">
      <h5 class="form-inline">{{ 'shared.components.file-explorer.treeview.files' | translate }}
        <op-count-badge class="small"  *ngIf="!countUpdating" [count]="count" style="padding-left: 3px"></op-count-badge>
        <op-component-state-badge [name]="overAllFileSizeText" class="small" style="padding-left: 3px"></op-component-state-badge></h5>
    </div>
    <div class="col text-right m-auto">
      <i class="cpp-icon-upload" (click)="onShowUploadClicked()"></i>
    </div>
  </div>
  <ng-container *ngIf="list.length > 0; else noFiles">
    <ngx-treeview class="cpp-treeview-min-h-85"
      [itemTemplate]="treeViewTemplateV2"
      [config]="treeViewConfig"
      [items]="treeViewItems">
    </ngx-treeview>
  </ng-container>
</div>

<ng-template #noFiles>
  <div *ngIf="!loading; else loadingSpinner">
    {{ 'shared.components.file-explorer.treeview.no-files' | translate }}
  </div>
</ng-template>
<ng-template #loadingSpinner>
  <div>
    <op-spinner-small *ngIf='loading'></op-spinner-small>
  </div>
</ng-template>

<!-- TreeView Template V2--->
<ng-template #treeViewTemplateV2 let-treeViewItem="item" let-onCollapseExpand="onCollapseExpand">
  <div class="p-0 m-0" style="display: flex; line-height: 19px">
    <!-- If item is folder, change icon on collapsed/expand -->
    <i *ngIf="itemIsFolder(treeViewItem)" [ngSwitch]="itemIsCollapsed(treeViewItem)" (click)="onCollapseExpand()" aria-hidden="true" >
      <!-- Folder is expanded -->
      <i *ngSwitchCase="false" class="cpp-icon-folder-open"></i>
      <!-- Folder is collapsed --->
      <i *ngSwitchCase="true" class="cpp-icon-folder-closed"></i>
    </i>

    <!-- Label for folder items -->
    <label *ngIf="itemIsFolder(treeViewItem)" class="cpp-file-explorer-treeview-label small pl-2" (click)="onCollapseExpand()">{{ treeViewItem.text }}</label>
    <!-- Item folder handling end -->

    <!-- If item is file -->
    <label *ngIf="!itemIsFolder(treeViewItem)" (click)="onSelectItemClicked(treeViewItem.value)"
           class="cpp-file-explorer-treeview-file-label overflow-hidden text-overflow-ellipses form-check-label small pl-0"
           [class.cpp-file-explorer-treeview-file-label-selected]="itemIsSelected(treeViewItem.value)"
           title="{{ treeViewItem.value.OriginalName }}"><!--{{ treeViewItem.value.OriginalName }} --> {{ getFancyName(treeViewItem.value.OriginalName) }}
    </label>
    <!-- Item is file handling end -->
  </div>
</ng-template>
<!-- TreeView Template --->
