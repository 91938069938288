import {Component, Input, OnInit} from '@angular/core';
import {FavoriteMenuEventArgs} from '../actions/actions.component';
import {DetailSectionWrapperService} from '../detail-section-wrapper/detail-section-wrapper.service';
import {ComponentProfileActionType} from '../actions/component-profile-action-type';
import {ComponentProfileService} from '../component-profile.service';

@Component({
  selector: 'op-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  @Input() componentType: string;
  @Input() parentComponentId: string;
  @Input() hasBlockings: boolean;

  protected readonly ComponentProfileActionType = ComponentProfileActionType;

  constructor(private detailSectionsWrapperService: DetailSectionWrapperService) { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:no-unnecessary-initializer
  public emitEvent(source: string, action: ComponentProfileActionType = undefined) {
    const args = new FavoriteMenuEventArgs();
    args.Favorite = source;
    args.ActionType = action;
    args.Toggled = true;

    this.detailSectionsWrapperService.onExpandCollapse.emit(args);
  }

  public refreshComponent() {
    ComponentProfileService.onComponentReloadRequest.emit();
  }
}
