import {FileType} from './fileType';
import {window} from 'ngx-bootstrap/utils';
import {SafeUrl} from '@angular/platform-browser';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';

export class FilePreview {
  public get objectUrl(): string | SafeUrl {
    return this._objectUrl;
  }

  public set objectUrl(value: string | SafeUrl) {
    this._objectUrl = value;

    LoggingService.logTrace(LoggingSource.FILE_PREVIEW,
      `Object url for file [${this.fileName}] created successfully. [${this._objectUrl}]`);

    this.loadingComplete = true;

    LoggingService.logTrace(LoggingSource.FILE_PREVIEW,
      `Loading for file [${this.fileName}] completed.`);
  }

  public set file(data: any) {
    this._file = data;

    /**
     * Under all circumstances we need to avoid having orphaned data urls.
     * They block garbage collections of blobs.
     */
    if (this._objectUrl) {

      LoggingService.logTrace(LoggingSource.FILE_PREVIEW,
        `object url for file [${this.fileName}] already present.`);

      return;
    }

    try {
      this.objectUrl = window.URL.createObjectURL(data);
    } catch(error) {
      LoggingService.logError(LoggingSource.FILE_PREVIEW,
        `Error occurred creating object url for file [${this.fileName}].`, error);
    }
  }

  public loadingComplete = false;

  parentId: string;
  parentType: string;
  parentCostCenter: string;

  parserType: string;

  fileType: FileType;
  File: any;
  fileName: string;
  HasError: boolean;
  Blob: Blob;
  Parsed: boolean;
  OriginalName?: string;
  Source?: string;
  Origin?: string;
  Size: string;
  Uploaded?: string;
  directLink: string;
  maximized: boolean;

  private _objectUrl: string | SafeUrl;
  private _file: any;

  public IsImage(): boolean {
    return this.fileType === FileType.Image;
  }
  public IsString(): boolean {
    return this.fileType === FileType.Text;
  }
  public IsPdf(): boolean {
    return this.fileType === FileType.Pdf;
  }

  public IsParsedBinary(): boolean {
    if (this.fileType === FileType.Binary && this.Parsed) {
      return true;
    }

    return false;
  }

  public IsParsedOther(): boolean {
    if (this.fileType === FileType.Other && this.Parsed) {
      return true;
    }

    return false;
  }

  isJson(): boolean {
    try {
      JSON.parse(this.File);
      return true;
    } catch (error) {
      return false;
    }
  }

  constructor() {
  }
}
