<op-detail-section-wrapper
  [title]="sectionTitle ? sectionTitle : 'component-profile.blockings.title' | translate"
  [description]="'component-profile.blockings.description' | translate"
  [contentTemplate]="contentTemplateRef"
  [hasContent]="items.length >= 1"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId"
  [collapsable]="collapsable">
</op-detail-section-wrapper>

<ng-template #contentTemplateRef>
  <div class="pl-3" *ngIf="items && items.length > 0">
    <ng-container *ngFor="let blockingAction of items">
    <div class="row pt-2">
      <div class="cpp-column-fixed-width-25 small pointer" (click)="blockingAction.expanded = !blockingAction.expanded">
        <i class="fas fa-sw"
           [class.fa-chevron-right]="!blockingAction.expanded"
           [class.fa-chevron-down]="blockingAction.expanded"></i>
      </div>
      <div class="cpp-column-fixed-width-200 text-overflow-ellipses small">
        <a *ngIf="blockingAction.blockingActionNr"
           [routerLink]="'/actions/blockings/edit/' + blockingAction.blockingActionNr"
           target="_blank">
          <i class="cpp-icon-link"></i>
        </a>
        {{ blockingAction.blockingActionNr ? blockingAction.blockingActionNr + ' ' : ''}}
        {{ blockingAction.blockingReason }}
      </div>
      <div class="cpp-column-fixed-width-75 small">
        <op-component-state-badge [name]="blockingAction.blockedActionState"></op-component-state-badge>
      </div>
    </div>

      <ng-container *ngIf="blockingAction.expanded">
        <div style="padding-left: 36px">
        <div class="row pt-1">
          <div class="col-3 small">
            {{ 'blockings.blockedAt' | translate }}
          </div>
          <div class="col small">
            <op-datetime-show [dateTime]="blockingAction.blockedAt"></op-datetime-show>
          </div>
        </div>
        <div class="row pt-1">
          <div class="col-3 small">
            {{ 'blockings.blockedBy' | translate }}
          </div>
          <div class="col small">
            {{ blockingAction.blockedBy }}
          </div>
        </div>
          <div class="row pt-1">
            <div class="col-3 small">
              {{ 'blockings.source' | translate }}
            </div>
            <div class="col small">
              <op-component-state-badge [name]="blockingAction.source"></op-component-state-badge>
            </div>
          </div>

        <ng-container *ngIf="blockingAction.childComponentId && blockingAction.childComponentId !== '00000000-0000-0000-0000-000000000000'">
        <div class="row pt-1">
          <div class="col-3 small">
            {{ 'blockings.sourceComponent' | translate }}
          </div>
          <div class="col small">
            <a [routerLink]="'/part/' + blockingAction.childComponentId" target="_blank">
              <i class="fas fa-cog"></i>
              {{ blockingAction.childComponentName ? blockingAction.childComponentName : blockingAction.childComponentId }}
            </a>
          </div>
        </div>
        </ng-container>

          <ng-container *ngIf="blockingAction.releasedAt">
            <div class="row pt-1">
              <div class="col-3 small">
                {{ 'blockings.releasedAt' | translate }}
              </div>
              <div class="col small">
                <op-datetime-show [dateTime]="blockingAction.releasedAt"></op-datetime-show>
              </div>
            </div>
            <div class="row pt-1">
              <div class="col-3 small">
                {{ 'blockings.releasedBy' | translate }}
              </div>
              <div class="col small">
                {{ blockingAction.releasedBy }}
              </div>
            </div>
            <div class="row pt-1">
              <div class="col-3 small">
                {{ 'blockings.releaseReason' | translate }}
              </div>
              <div class="col small">
                {{ blockingAction.releaseReason }}
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
