import {AfterViewInit, Component, EventEmitter, Input, isDevMode, OnInit, Output} from '@angular/core';
import {FileUpload} from '../fileUpload';
import {FileExplorerService} from '../file-explorer.service';
import {ComponentTypeEnum} from '@cstx/volkswagen-mqs-file-handling-service-client';
import {Subject} from 'rxjs';
import {ErrorHandler} from '../../../services/error-handler/error-handler';

@Component({
  selector: 'op-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements AfterViewInit {
  @Input() componentId: string;
  @Input() componentType: string;
  @Input() componentCostCenter: string;
  @Input() onShowUploadOverlay = new Subject<boolean>();


  @Output() uploadRequestEndEvent = new EventEmitter<boolean>();

  public uploading = false;
  public showUploadOverlay = false;

  constructor(private fileExplorerService: FileExplorerService) { }


  ngAfterViewInit(): void {
    this.onShowUploadOverlay.subscribe(next => {
      this.showUploadOverlay = next;
    });
  }

  private upload(uploadRequest: FileUpload) {
    this.fileExplorerService.Upload(uploadRequest)
      .then(_ => {
        if (isDevMode()) {
          console.log(new Date().toLocaleString() + ': Upload finished successfully for file [' + uploadRequest.fileName + ']');
        }
      })
      .catch(error => {
        ErrorHandler.printError(error, uploadRequest);
      })
      .finally(() => {
        this.uploading = false;
        this.uploadRequestEndEvent.emit(true);
      });
  }

  public onUploadRequested(event: any) {
    if (event) {
      let  filesToUpload: FileList;
      filesToUpload = event.target.files;

      if (filesToUpload.length === 0) {
        return;
      }

      const fileCount = filesToUpload.length;
      for (let i = 1; i <= fileCount; i++) {
        const uploadRequest = this.mapToUploadRequest(filesToUpload[i - 1]);
        this.upload(uploadRequest);
      }
    } else {
      return;
    }
  }

  public onDrop(event: any) {
    event.preventDefault();
    const fileCount = event.dataTransfer.items.length;

    for (let i = 1; i <= fileCount; i++ ) {
      let fileToUpload = File;
      fileToUpload = event.dataTransfer.items[i - 1].getAsFile();

      const uploadRequest = this.mapToUploadRequest(fileToUpload);
      this.upload(uploadRequest);
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  public onUploadAbort() {
    this.uploadRequestEndEvent.emit(false);
  }

  private mapToUploadRequest(fileToUpload: any): FileUpload {
    const uR = new FileUpload();

    uR.file = fileToUpload;
    uR.componentType = this.componentType as ComponentTypeEnum;
    uR.componentId = this.componentId;
    uR.costCenter = this.componentCostCenter;
    uR.fileName = fileToUpload.name;
    uR.source = 'Portal';

    return uR;
  }

}
