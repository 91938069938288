import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {concat, defer, fromEvent, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService {

  public pageVisible$: Observable<boolean>;

  constructor(@Inject(DOCUMENT) document: any) {
    this.pageVisible$ = concat(
      defer(() => of(!document.hidden)),
      fromEvent(document, 'visibilitychange')
      .pipe(
        map(() => !document.hidden)
      )
    );
  }
}
