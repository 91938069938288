import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';

@Component({
  selector: 'op-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() title: string;
  @Input() showActions: boolean;
  @Input() showUpload: boolean;
  @Input() showRefresh: boolean;
  @Input() bannerText: string;
  @Input() midHeaderTemplate: TemplateRef<any>;
  @Input() rightHeaderTemplateExtension: TemplateRef<any>;
  @Input() errorMode: boolean;
  @Output() uploadPressed = new EventEmitter<string>();
  @Output() refreshEvent = new EventEmitter<any>();

  isRefreshing: boolean;


  constructor() { }

  ngOnInit(): void {
  }


  onRefreshClicked() {
    this.isRefreshing = true;
    this.refreshEvent.emit();

    setTimeout(() => this.isRefreshing = false, 1000 );
  }

  refreshAnimation() {
    if (this.isRefreshing) {
      return 'fa-spin';
    }

    return  '';
  }

  cardErrorMode() {
    if (this.errorMode) {
      return 'errorMode';
    }
  }
}
