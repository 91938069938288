<ng-container *ngIf="!hidden">
  <ng-container *ngIf="!editPending; else editConfirmationTemplate">
    <i class="fas fa-edit small pointer pl-1"
       [class.text-muted]="disabled"
       style="color: #6d7fcc; vertical-align: middle"
       (click)="onEditClick()"></i>
  </ng-container>
</ng-container>

<ng-template #editConfirmationTemplate>
<small>
  <button type="button" class="btn btn-sm btn-danger pt-0 pb-0 small mr-1"
          (click)="onConfirmation.emit(); editPending = false">
    {{ 'button.save' | translate }}
  </button>
  <button type="button" class="btn btn-sm btn-secondary pt-0 pb-0"
          (click)='onCancellation.emit(); editPending = false;'>
    {{ 'button.cancel' | translate }}
  </button>
</small>
</ng-template>

<ng-container *ngIf="errorMessage">
  <div class="pl-1 d-inline" style="color: darkred">{{ errorMessage }}</div>
</ng-container>
