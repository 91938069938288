import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {parse} from './properties-parser';
import {forkJoin, of} from 'rxjs';
import {LoggingService} from '../../logging/logging.service';
import {LoggingSource} from '../../logging/loggingSource';

export class TranslateHttpPropertiesLoader {
  constructor(private http: HttpClient, private prefix: string, private suffix: string) {
    if (typeof prefix !== 'string') {
      prefix = '/assets/i18n/';
    }

    if (typeof suffix !== 'string') {
      suffix = '.json';
    }
  }

  getTranslation(lang) {
    if (this.suffix === '.properties') {
      return forkJoin([
        this.http
          .get('' + this.prefix + lang + this.suffix, { responseType: 'text' })
          .pipe(
            catchError((err) => {
              LoggingService.logError(LoggingSource.TRANSLATION_LOADER, 'An error occurred while loading the primary translation file for language: ' + lang +'\n' + err.message, err);
              return of('');
            }),
            map(this.convertProps.bind(this)),
            catchError((err) => {
              LoggingService.logError(LoggingSource.TRANSLATION_LOADER, 'An error occurred while parsing the primary translation file for language: ' + lang +'\n' + err.message, err);
              return of({});
            })),
        this.http
          .get('' + this.prefix + lang + '.json', { responseType: 'json' })
          .pipe(catchError((err) => {
            LoggingService.logError(LoggingSource.TRANSLATION_LOADER, 'An error occurred while loading the secondary translation file for language: ' + lang +'\n' + err.message, err);
            return of({});
          }))
      ]).pipe(map(arr => {
        let obj = {};
        arr.forEach(entry => {
          obj = this.mergeRecursively(obj, entry);
        });
        return obj;
      }));
    } else {
      return this.http.get('' + this.prefix + lang + this.suffix);
    }
  }

  mergeRecursively(objA: any, objB: any): any {
    const keysA = objA ? Object.keys(objA) : new Array<string>();
    const uniqueKeysB = objB ? Object.keys(objB).filter(key => !keysA.some(entry => entry === key)) : new Array<string>();
    const result = {};
    keysA.forEach(key => {
      if (objB[key] === undefined) {
        result[key] = objA[key];
      } else if (typeof objB[key] !== 'object') {
        result[key] = objB[key];
      } else {
        result[key] = this.mergeRecursively(objA[key], objB[key]);
      }
    });
    uniqueKeysB.forEach(key => result[key] = objB[key]);
    return result;
  }

  convertProps(props) {
    const flatProps = parse(props);
    const inflated = {};

    for (const key in flatProps) {
      if (Object.prototype.hasOwnProperty.call(flatProps, key)) {
        let item = inflated;
        const splitKey = key.split('.');

        for (let i = 0; i < splitKey.length - 1; i++) {
          const part = splitKey[i];

          if (!item[part]) {
            item[part] = {};
          }

          item = item[part];
        }

        const last = splitKey[splitKey.length - 1];

        if (typeof item[last] !== 'undefined') {
          throw new Error(
              'Failed to convert .properties to JSON. ' +
              'Property ' +
              splitKey.join('.') +
              ' is already assigned or contains nested properties.'
          );
        }

        item[last] = flatProps[key];
      }
    }
    return inflated;
  }
}
