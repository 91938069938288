import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cpp-result-indicator',
  templateUrl: './result-indicator.component.html',
  styleUrls: ['./result-indicator.component.scss']
})
export class ResultIndicatorComponent implements OnInit, OnChanges {
  @Input() value: string;
  statusClassList: string;
  statusIconColor: string;


  classMapping = [
    { value: 'created', class: 'far fa-plus-square', color: 'black'},
    { value: 'pending', class: 'fas fa-hourglass-start', color: 'yellow'},
    { value: 'running', class: 'fas fa-spinner fa-pulse', color: 'gray' },
    { value: 'error', class: 'fas fa-exclamation-circle', color: 'red'},
    { value: 'done', class: 'fas fa-check-square', color: 'green' }
  ];
  constructor() { }

  ngOnInit(): void {
    this.Do();
  }



  private Do() {
    const classMapping = this.classMapping.find(mapping => mapping.value.toLowerCase() === this.value?.toLowerCase());
    if (classMapping) {
      this.statusClassList = classMapping.class;
      this.statusIconColor = classMapping.color;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.Do();
  }
}
