import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';
import {DatePipe} from '@angular/common';
import {Subject} from 'rxjs';

declare let $: any;

@Component({
  selector: 'op-date-range-input',
  templateUrl: './date-range-input.component.html',
  styleUrls: ['./date-range-input.component.scss']
})
export class DateRangeInputComponent implements OnInit, AfterViewInit  {
  public inputId: string;
  private jqueryAccessor: string;
  // tslint:disable:variable-name
  private _startDate: Date;
  private _endDate: Date;

  @Output() dateRangeChange: EventEmitter<Date[]> = new EventEmitter<Date[]>();
  @Input() prependIcon = false;
  @Input() disabled = false;
  @Input() isInvalid = false;
  @Input() noPlaceHolder = false;
  @Input() clearValuesEvent = new Subject<any>();
  @Input() set startDate(startDate: Date) {
    this._startDate = startDate;
    this.initDaterangePicker();
  }
  @Input() set endDate(endDate: Date) {
    this._endDate = endDate;
    this.initDaterangePicker();
  }


  constructor(private translate: TranslateService, private date: DatePipe) {
    this.inputId = uuid();
    this.jqueryAccessor = '#' + this.inputId;
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => this.initDaterangePicker());

    this.clearValuesEvent.subscribe(() => {
      this.clear();
      this.dateRangeChange.emit();
    });
  }

  ngAfterViewInit(): void {
    this.initDaterangePicker();
  }

  public clear() {
    $(this.jqueryAccessor).val('');
  }

  private initDaterangePicker() {
      $(this.jqueryAccessor + 'Label').daterangepicker({
        parentEl: '#' + this.inputId + 'Par',
        startDate: this._startDate ? this._startDate : undefined,
        endDate: this._endDate ? this._endDate : undefined,
        timePicker: true,
        autoUpdateInput: false,
        timePicker24Hour: true,
        showWeekNumbers: true,
        linkedCalendars: false,
        locale: {
          cancelLabel: this.translate.instant('search.clear'),
          applyLabel: this.translate.instant('search.apply'),
          format: 'DD.MM.YYYY HH:mm',
          daysOfWeek: [
            this.translate.instant('global.weekday.su'),
            this.translate.instant('global.weekday.mo'),
            this.translate.instant('global.weekday.tu'),
            this.translate.instant('global.weekday.we'),
            this.translate.instant('global.weekday.th'),
            this.translate.instant('global.weekday.fr'),
            this.translate.instant('global.weekday.sa'),
          ],
          monthNames: [
            this.translate.instant('global.month.january'),
            this.translate.instant('global.month.february'),
            this.translate.instant('global.month.march'),
            this.translate.instant('global.month.april'),
            this.translate.instant('global.month.may'),
            this.translate.instant('global.month.june'),
            this.translate.instant('global.month.july'),
            this.translate.instant('global.month.august'),
            this.translate.instant('global.month.september'),
            this.translate.instant('global.month.october'),
            this.translate.instant('global.month.november'),
            this.translate.instant('global.month.december'),
          ],
          firstDay: 1
        }
      });

      $(this.jqueryAccessor  + 'Label').on('apply.daterangepicker', (ev, picker) => {
        $(this.jqueryAccessor).val(picker.startDate.format('DD.MM.YYYY HH:mm') + ' - ' + picker.endDate.format('DD.MM.YYYY HH:mm'));
        this.dateRangeChange.emit([picker.startDate, picker.endDate]);
      });

      $(this.jqueryAccessor  + 'Label').on('show.daterangepicker', (ev, picker) => {
        const displayWidth = document.getElementsByTagName('html')[0].clientWidth;
        const pick = picker.container[0] as HTMLElement;
        pick.style.transform = undefined;
        const pickRight = pick.getBoundingClientRect().right;
        if (displayWidth < pickRight) {
          pick.style.setProperty('--translate-datepicker', (pickRight - displayWidth).toString(10) + 'px');
          pick.classList.add('daterangepicker-move');
          pick.style.transition = 'all .5s';
          pick.style.transform = 'translate(-' + (pickRight - displayWidth) + 'px, 0)';
        }
      });
      $(this.jqueryAccessor  + 'Label').on('hide.daterangepicker', (ev, picker) => {
        const pick = picker.container[0] as HTMLElement;
        pick.style.transform = '';
        if (pick.classList.contains('daterangepicker-move')) {
          pick.classList.remove('daterangepicker-move');
        }
      });
      $(this.jqueryAccessor + 'Label').on('cancel.daterangepicker', (ev, picker) => {
        this.clear();
        this.dateRangeChange.emit(null);
      });

      if (this._startDate && this._endDate) {
      $(this.jqueryAccessor).val(
        this.date.transform(this._startDate , 'dd.MM.yyyy HH:mm')
        + ' - '
        + this.date.transform(this._endDate, 'dd.MM.yyyy HH:mm'));
    }
  }
}
