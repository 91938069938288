import {Injectable, isDevMode} from '@angular/core';
import {KeyValue} from '@angular/common';
import {UserService} from '../../modules/user/user.service';
import {PermissionsDefinitionService} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {firstValueFrom, Observable, Subject} from 'rxjs';
import {EnterpriseRoleProviderService} from './enterprise-role-provider.service';
import {LoggingService} from "../logging/logging.service";
import {LoggingSource} from "../logging/loggingSource";

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  private permissionMap: Array<Claim>;
  private permissionsLoaded : Subject<void>= new Subject<void>();
  private loading = false;
  private rolesInternalMap: Map<string, string>;

  constructor(private user: UserService,
              private permissionsDefinitionService: PermissionsDefinitionService){
  }

  public getPermissionsLoaded():Observable<void> {
    return this.permissionsLoaded.asObservable();
  }

  public initPermissions() {
    if (!this.loading) {
      this.loading = true;
      this.getPermissionDefinitions().then(response => {
        LoggingService.logDebug(LoggingSource.ROLE_MANAGEMENT, 'Permissions retrieved.');

        this.permissionMap = response;
        this.permissionsLoaded.next();

        const roles = new Array<string>();
        EnterpriseRoleProviderService.tokenGroups.forEach(g => {
          const role = this.getApplicationRoleFromEnterpriseRole(g);

          if (role) {
            roles.push(role);
          }
        });

        EnterpriseRoleProviderService.effectiveEnterpriseRoles = roles;
        EnterpriseRoleProviderService.claims = this.permissionMap;

        this.loading = false;
      });
    }
  }

  public async initPermissionsAsync() {
    if (!this.loading) {
      this.loading = true;

      const response = await this.getPermissionDefinitionsAsync();
      LoggingService.logDebug(LoggingSource.ROLE_MANAGEMENT, 'Permissions retrieved.');

      this.permissionMap = response;
      this.permissionsLoaded.next();

      const roles = new Array<string>();
      EnterpriseRoleProviderService.tokenGroups.forEach(g => {
        const role = this.getApplicationRoleFromEnterpriseRole(g);

        if (role) {
          roles.push(role);
        }

        EnterpriseRoleProviderService.effectiveEnterpriseRoles = roles;
        EnterpriseRoleProviderService.claims = this.permissionMap;

        this.loading = false;
      });
    }
  }

  private getPermissionDefinitions() {
    return firstValueFrom(this.permissionsDefinitionService.getAllPermissionsDefinitions())
      .then(response => {
        this.rolesInternalMap = new Map<string, string>();
        response.roleMappings
          .forEach(mapping => this.rolesInternalMap.set(mapping.applicationRole, mapping.enterpriseRole));
        const claims: Array<Claim> = response.resources
          .map(resource => ({name: resource, roles: new Array<KeyValue<string, PermissionType>>()}));
        response.roleDefinitions.forEach(roleDef => {
          claims.forEach(claim => {
            if (roleDef.resourcePermissions[claim.name]
              && this.rolesInternalMap.has(roleDef.applicationRole)
              && !claim.roles.find(val => val.key === this.rolesInternalMap.get(roleDef.applicationRole))) {
              switch (roleDef.resourcePermissions[claim.name]) {
                case 'Read':
                  claim.roles.push({key: this.getEnterpriseRoleFromApplicationRole(roleDef.applicationRole), value: PermissionType.read});
                  break;
                case 'Write':
                  claim.roles.push({key: this.getEnterpriseRoleFromApplicationRole(roleDef.applicationRole), value: PermissionType.readwrite});
                  break;
                default:
                  claim.roles.push({key: this.getEnterpriseRoleFromApplicationRole(roleDef.applicationRole), value: PermissionType.none});
                  break;
              }
            }
          });
        })
        return claims;
      })
  }

  private async getPermissionDefinitionsAsync(): Promise<Claim[]> {
    const response = await firstValueFrom(this.permissionsDefinitionService.getAllPermissionsDefinitions());
    this.rolesInternalMap = new Map<string, string>();

    response.roleMappings
      .forEach(mapping => this.rolesInternalMap.set(mapping.applicationRole, mapping.enterpriseRole));

    const claims: Array<Claim> = response.resources
      .map(resource => ({name: resource, roles: new Array<KeyValue<string, PermissionType>>()}));


    response.roleDefinitions.forEach(roleDef => {
      claims.forEach(claim => {
        if (roleDef.resourcePermissions[claim.name]
          && this.rolesInternalMap.has(roleDef.applicationRole)
          && !claim.roles.find(val => val.key === this.rolesInternalMap.get(roleDef.applicationRole))) {
          switch (roleDef.resourcePermissions[claim.name]) {
            case 'Read':
              claim.roles.push({
                key: this.getEnterpriseRoleFromApplicationRole(roleDef.applicationRole),
                value: PermissionType.read
              });
              break;
            case 'Write':
              claim.roles.push({
                key: this.getEnterpriseRoleFromApplicationRole(roleDef.applicationRole),
                value: PermissionType.readwrite
              });
              break;
            default:
              claim.roles.push({
                key: this.getEnterpriseRoleFromApplicationRole(roleDef.applicationRole),
                value: PermissionType.none
              });
              break;
          }
        }
      });
    });

    return claims;
  }


  public getEnterpriseRoleFromApplicationRole(applicationRole: string): string {
    let result: string;
    if (this.rolesInternalMap) {
      result = this.rolesInternalMap.get(applicationRole)
    }
    return result;
  }
  public getApplicationRoleFromEnterpriseRole(enterpriseRole: string): string {
    let result: string;
    if (this.rolesInternalMap) {
      const mirroredMap = new Map<string, string>();
      this.rolesInternalMap.forEach((val,key) => mirroredMap.set(val, key));
      result = mirroredMap.get(enterpriseRole);
    }
    return result;
  }
}

export enum PermissionType {
  none = 0,
  read = 1,
  readwrite = 2
}

export class Claim {
  name: string;
  roles: Array<KeyValue<string, PermissionType>>
}
