<ng-container *ngIf="event.referenceProperties, else noProp">
  <ng-container [ngSwitch]="event.eventType">
    <ng-container *ngSwitchCase="eventTypes.ColdTestFailed">
      <ng-container *ngIf="event.referenceProperties.testResult, else noProp">
        <ng-container *ngIf="event.referenceProperties.testResult === coldTestResult.Invalidated">
          {{'events.additionalProperties.ColdTestInvalidated' | translate}}
        </ng-container>
        <ng-container *ngIf="event.referenceProperties.testResult === coldTestResult.Failed">
          <ng-container *ngIf="event.referenceProperties.errorMessage">
            {{'events.additionalProperties.ColdTestFailed' | translate}}<br>
            {{event.referenceProperties.errorMessage}}
          </ng-container>
          <ng-container *ngIf="!event.referenceProperties.errorMessage">
            {{'events.additionalProperties.HotTestFailedMissingErrors' | translate}}
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <!--ng-container *ngSwitchCase="eventTypes.ColdTestPassed">

    </ng-container-->
    <ng-container *ngSwitchCase="eventTypes.HotTestFailed">
      <ng-container *ngIf="event.referenceProperties.testResult, else noProp">
        <ng-container *ngIf="event.referenceProperties.testResult === hotTestResult.Invalidated">
          {{'events.additionalProperties.HotTestInvalidated' | translate}}
        </ng-container>
        <ng-container *ngIf="event.referenceProperties.testResult === hotTestResult.Failed">
          <ng-container *ngIf="event.referenceProperties.error1 || event.referenceProperties.error2 ||
            event.referenceProperties.error3 || event.referenceProperties.error4">
            {{'events.additionalProperties.HotTestFailed' | translate}}
          </ng-container>
          <ng-container *ngIf="!(event.referenceProperties.error1 || event.referenceProperties.error2 ||
            event.referenceProperties.error3 || event.referenceProperties.error4)">
            {{'events.additionalProperties.HotTestFailedMissingErrors' | translate}}
          </ng-container>
          <ng-container *ngIf="event.referenceProperties.error1">
            <br>{{event.referenceProperties.error1}}
          </ng-container>
          <ng-container *ngIf="event.referenceProperties.error2">
            <br>{{event.referenceProperties.error2}}
          </ng-container>
          <ng-container *ngIf="event.referenceProperties.error3">
            <br>{{event.referenceProperties.error3}}
          </ng-container>
          <ng-container *ngIf="event.referenceProperties.error4">
            <br>{{event.referenceProperties.error4}}
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <!--ng-container *ngSwitchCase="eventTypes.HotTestPassed">

    </ng-container-->
    <ng-container *ngSwitchCase="eventTypes.ReworkOpen">
      <ng-container *ngIf="event.referenceProperties.analysis">
        {{ 'events.additionalProperties.ReworkOpen' | translate}}<br>
        {{ event.referenceProperties.analysis }}
      </ng-container>
      <ng-container *ngIf="!event.referenceProperties.analysis">
        {{ 'events.additionalProperties.ReworkOpenAnalysisMissing' | translate}}<br>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="eventTypes.ReworkClosed">
      <ng-container *ngIf="event.referenceProperties.rework">
        {{ 'events.additionalProperties.ReworkClosed' | translate}}<br>
        {{ event.referenceProperties.rework }}
      </ng-container>
      <ng-container *ngIf="!event.referenceProperties.analysis">
        {{ 'events.additionalProperties.ReworkClosedReworkMissing' | translate}}<br>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="eventTypes.ReworkApproved">
      <ng-container *ngIf="event.referenceProperties.qualityAssuranceCheckedAt">
        <ng-container *ngIf="event.referenceProperties.qualityAssuranceEmployee">
          {{ 'events.additionalProperties.ReworkApproved' | translate: {
          qualityAssuranceCheckedAtDate: event.referenceProperties.qualityAssuranceCheckedAt | date: 'dd.MM.yyyy',
          qualityAssuranceCheckedAtTime: event.referenceProperties.qualityAssuranceCheckedAt | date: 'HH:mm',
          qualityAssuranceEmployee:  event.referenceProperties.qualityAssuranceEmployee}
          }}
        </ng-container>
        <ng-container *ngIf="!event.referenceProperties.qualityAssuranceEmployee">
          {{ 'events.additionalProperties.ReworkApprovedEmployeeMissing' | translate: {
          qualityAssuranceCheckedAtDate: event.referenceProperties.qualityAssuranceCheckedAt | date: 'dd.MM.yyyy',
          qualityAssuranceCheckedAtTime: event.referenceProperties.qualityAssuranceCheckedAt | date: 'HH:mm'}
          }}
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!event.referenceProperties.qualityAssuranceCheckedAt">
        <ng-container *ngIf="event.referenceProperties.qualityAssuranceEmployee">
          {{ 'events.additionalProperties.ReworkApprovedTimestampMissing' | translate: {
          qualityAssuranceEmployee:  event.referenceProperties.qualityAssuranceEmployee}
          }}
        </ng-container>
        <ng-container *ngIf="!event.referenceProperties.qualityAssuranceEmployee">
          {{ 'events.additionalProperties.ReworkApprovedTimestampEmployeeMissing' | translate }}
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="eventTypes.PartInstalled">
      <ng-container *ngIf="event.referenceProperties.componentName, else noProp">
        {{'events.additionalProperties.PartInstalled' | translate: {
        componentName: event.referenceProperties.componentName }
        }}
        <a *ngIf="event.referenceId" [routerLink]="'/part/' + event.referenceId">
          {{'events.additionalProperties.PartInstalledLink' | translate}}
        </a>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="eventTypes.Blocked">
      <ng-container *ngIf="event.referenceProperties.blockingReason">
        {{'events.additionalProperties.Blocked' | translate: {
        blockingReason: event.referenceProperties.blockingReason }
        }}
      </ng-container>
      <ng-container *ngIf="!event.referenceProperties.blockingReason">
        {{'events.additionalProperties.BlockedReasonMissing' | translate
        }}
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="eventTypes.Released">
      <ng-container *ngIf="event.referenceProperties.releaseReason">
        {{'events.additionalProperties.Released' | translate: {
        release: event.referenceProperties.releaseReason }
        }}
      </ng-container>
      <ng-container *ngIf="!event.referenceProperties.releaseReason">
        {{'events.additionalProperties.ReleasedReasonMissing' | translate
        }}
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="eventTypes.Stacking">
      <ng-container *ngIf="event.referenceProperties.stackIdentifier, else noProp">
        <ng-container *ngIf="event.referenceProperties.stackLabel" >
          {{'events.additionalProperties.Stacking.withLabel' | translate:{
          stackIdentifier: event.referenceProperties.stackIdentifier,
          stackLabel: event.referenceProperties.stackLabel }
          }}
        </ng-container>
        <ng-container *ngIf="!event.referenceProperties.stackLabel" >
          {{'events.additionalProperties.Stacking.withoutLabel' | translate:{
          stackIdentifier: event.referenceProperties.stackIdentifier}
          }}
        </ng-container>
        <a [routerLink]="'/tracking/stacks/' + event.referenceProperties.stackIdentifier">
          {{'events.additionalProperties.StackLink' | translate}}
        </a>
      </ng-container>
      <ng-container *ngSwitchCase="eventTypes.Unstacking">
        <ng-container *ngIf="event.referenceProperties.stackLabel" >
          {{'events.additionalProperties.Unstacking.withLabel' | translate:{
          stackIdentifier: event.referenceProperties.stackIdentifier,
          stackLabel: event.referenceProperties.stackLabel }
          }}
        </ng-container>
        <ng-container *ngIf="!event.referenceProperties.stackLabel" >
          {{'events.additionalProperties.Unstacking.withoutLabel' | translate:{
          stackIdentifier: event.referenceProperties.stackIdentifier}
          }}
        </ng-container>
        <a [routerLink]="'/tracking/stacks/' + event.referenceProperties.stackIdentifier">
          {{'events.additionalProperties.StackLink' | translate}}
        </a>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="eventTypes.Dispatch">
      {{'events.additionalProperties.Dispatch' | translate:{
      transportUnit: event.referenceProperties.transportUnit,
      tansportDestination: event.referenceProperties.tansportDestination }
      }}
    </ng-container>
    <ng-container *ngSwitchCase="eventTypes.Return">
      {{'events.additionalProperties.Return' | translate:{
      transportUnit: event.referenceProperties.transportUnit,
      tansportDestination: event.referenceProperties.tansportDestination }
      }}
    </ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="noProp"></ng-container>
  </ng-container>
</ng-container>
<ng-template #noProp>
  {{ 'events.eventType.' + event.eventType + '.desc' | translate: {Default: ' '} }}<br><br>
  {{'events.origin' | translate}}: {{event.origin}}
</ng-template>
