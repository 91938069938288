// TODO: Check which attributes a really in use and delete the others
export class InboxEntry {
   public Subject: string;
   public Description: string;
   public DownloadLink: string;
   public Received: any;
   public Token: string;
   public S3Key: string;
   public Selected = false;
   public Failed = false;
   public Origin: string;
   public Source: string;

   public IsMessageOnly: boolean;
   public unRead = false;

  constructor() {
  }
}
