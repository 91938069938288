import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Location, PlatformLocation} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class KioskModeService {

  constructor (private location: Location,
               private platformLocation: PlatformLocation) {
    location.onUrlChange( (url, state) => {
      const params = new URLSearchParams(platformLocation.search);
      if (params.get('kioskMode') === 'true') {
        this.kioskMode.next(true);
        KioskModeService.kioskMode = true;
      } else {
        this.kioskMode.next(false);
        KioskModeService.kioskMode = false;
      }
    });
  }

  public static kioskMode: boolean;
  private kioskMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public kioskMode$: Observable<boolean> = this.kioskMode.asObservable();

  toggleKioskMode() {
    this.kioskMode.next(!this.kioskMode.value);
    KioskModeService.kioskMode = !this.kioskMode.value;

    const params = new URLSearchParams(this.platformLocation.search);
    params.set('kioskMode', this.kioskMode.value.toString());
    this.location.replaceState(this.platformLocation.pathname, params.toString(), this.location.getState());
  }

  getCurrentMode(): boolean {
    return this.kioskMode.value;
  }
}
