import {FilterBase} from '../../shared/models/filterBase';

export class LogFilter extends FilterBase {
  public message = '';
  public source = '';

  constructor() {
    super();
  }


  public getAllParams() {
    return {...this.getPageableParams(), ...this.getFilterParams()};
  }

  public IsEmpty(): boolean {
    if (this.message && this.message !== '') {
      return false;
    }
    if (this.source && this.source !== '') {
      return false;
    }

    return true;
  }
  public getFilterParams() {
    if (this.IsEmpty()) {
      return {};
    }
    return {
      source: this.source,
      message: this.message
    };
  }

  public getPageableParams() {
    return {
      page: 0,
      size: 250,
      sort: null,
    };
  }

}
