import {WhatsUpSubscription} from './whatsUpSubscription';

export class UserData {
  firstName: string;
  lastName: string;
  registrationDate: Date;
  groups: Array<string>;
  subscribedChannels: Set<WhatsUpSubscription> = new Set<WhatsUpSubscription>();
  subscribableChannels: Set<WhatsUpSubscription> = new Set<WhatsUpSubscription>();
  email: string;
  phone: string;
  costCenters: Set<string> = new Set<string>();
  notificationMethods: {
    sms: boolean,
    email: boolean,
    app: boolean,
    portal: boolean
  };
}
