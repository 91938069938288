import {Component, Input, OnInit} from '@angular/core';
import {OffSet} from "./offSet";
import {ComponentInfoResponse} from "@cstx/volkswagen-mqs-parsing-service-client";
import {ParsingService} from "../../../services/backend/parsing.service";
import {QualityManagementService} from "../../../services/backend/quality-management.service";
import {EnterpriseRoleProviderService} from "../../../../core/services/enterprise-role-provider.service";
import {OffSetResponse} from "@cstx/volkswagen-mqs-quality-management-service-client";

@Component({
  selector: 'op-offsets',
  templateUrl: './offsets.component.html',
  styleUrls: ['./offsets.component.scss']
})
export class OffsetsComponent implements OnInit {
  @Input() componentId: string;
  @Input() componentName: string;
  @Input() selfLink: string;
  @Input() contentContainerId: string;

  public offSets: Array<OffSet>;
  public offSetDeletePendingId: string;
  public offSetsFound: boolean;
  public loading: boolean;
  public noNewOffSetsFound: boolean;

  constructor(private parsingService: ParsingService,
              private qualityManagementService: QualityManagementService) {
  }

  public async ngOnInit(): Promise<void> {
    await this.initOffSets();
  }

  private async initOffSets() {
    this.loading = true;
    const offSetResponses = await this.qualityManagementService.getOffSetsForComponentId(this.componentId);

    if (offSetResponses) {
      this.offSets = new Array<OffSet>();

      offSetResponses.forEach(offSetResponse => {
        if (offSetResponse.value.includes('&')) {
          const trimmed = offSetResponse.value.trimEndCharacter('=');
          const splitted = trimmed.split('&');

          splitted.forEach(split => {
            const fakeResponse: OffSetResponse =  {
              type: offSetResponse.type,
              value: split,
              id: offSetResponse.id
            }

            this.offSets.push(new OffSet(fakeResponse, offSetResponse.value));
          })

        } else {
          this.offSets.push(new OffSet(offSetResponse))
        }

      })
    }
    this.loading = false;
  }

  public async onOffSetDelete(listIndex: number) {
    const offSetId = this.offSets[listIndex].id;

    if (offSetId) {
      this.offSetDeletePendingId = offSetId;

      const result = await this.qualityManagementService.deleteOffSetById(offSetId);

      if (result) {
        this.offSets.splice(listIndex, 1);
      }

      await this.initOffSets();
      this.offSetDeletePendingId = undefined;
    } else {
      this.offSets.splice(listIndex, 1);
      this.offSetsFound = this.offSets.filter(offSet => !offSet.stored).length > 0;
    }
  }

  public async parseOffSets() {
    this.loading = true;
    const result = await this.parsingService.parseXmlV2(this.componentId, "Engine");

    if (result && result.length > 0) {
      this.tryExtractOffSet(result);
      this.offSetsFound = this.offSets.filter(offSet => !offSet.stored).length > 0;

      if (!this.offSetsFound) {
        this.noNewOffSetsFound = true;
      }
    }

    this.loading = false;
  }

  /**
   *   TODO: Merge into offSet model - Currently we have to almost identical ways of preprocess these offsets
   *   TODO: One is used to process from parsing result, the other to process stored offsets from QMS
   */

  private tryExtractOffSet(result: ComponentInfoResponse[]) {
    result.forEach(componentInfoResponse => {
      if (!componentInfoResponse.additionalSpecs) {
        return;
      }

      const camShaftSpec =
        componentInfoResponse.additionalSpecs.find(additional => additional.name === 'sznwdata');
      if (camShaftSpec && camShaftSpec.value && camShaftSpec.value.length > 10) {

        if (camShaftSpec.value.includes('&')) {
          // Multiple offsets in one...

         const camShaftSpecValue = camShaftSpec.value.trimEndCharacter('='); // CheckDigit ... Offset Label...
          const camShaftOffsets = camShaftSpecValue.split('&');

          camShaftOffsets.forEach(camOffSet => {
            const camShaftOffset = new OffSet();
            camShaftOffset.name = camShaftSpec.name;
            camShaftOffset.type = 'Camshaft';

            if (camOffSet.startsWith('XN')) {
              camShaftOffset.position = 'Zylinder ' + camOffSet.substring(2,3);
              camShaftOffset.value = camOffSet;
              camShaftOffset.raw = camShaftSpec.value;
            }

            if (camOffSet.startsWith('XW')) {
              camShaftOffset.position = 'Sensor ' + camOffSet.substring(2,3);
              camShaftOffset.value = camOffSet;
              camShaftOffset.raw = camShaftSpec.value;
            }

            if (this.offSets.findIndex(offSet =>  offSet.value === camShaftOffset.value) === -1) {
              this.offSets.push(camShaftOffset);
            }
          })
        } else {
          const camShaftOffset = new OffSet();
          camShaftOffset.name = camShaftSpec.name;
          camShaftOffset.value = camShaftSpec.value;

          camShaftOffset.type = 'Camshaft';

          if (this.offSets.findIndex(offSet =>  offSet.value === camShaftOffset.value) === -1) {
            this.offSets.push(camShaftOffset);
          }

        }


      }

      const crankShaftSpec = componentInfoResponse.additionalSpecs.find(additional => additional.name === 'szkwdata');
      if (crankShaftSpec && crankShaftSpec.value && crankShaftSpec.value.length > 10) {
        const crankShaftOffset = new OffSet();
        crankShaftOffset.name = crankShaftSpec.name;
        crankShaftOffset.value = crankShaftSpec.value;
        crankShaftOffset.type = 'Crankshaft';



        if (this.offSets.findIndex(offSet =>  offSet.value === crankShaftOffset.value) === -1) {
          this.offSets.push(crankShaftOffset);
        }
      }

      const injectorComponentSpecs = componentInfoResponse.additionalSpecs.filter(additional => additional.name.startsWith('Injector_'));
      if (componentInfoResponse.additionalSpecs && injectorComponentSpecs && injectorComponentSpecs.length > 0) {

        injectorComponentSpecs.forEach(injSpec => {
          if (!injSpec.value || injSpec.value.length < 10) {
            return;
          }

          const injectorOffSet = new OffSet();
          injectorOffSet.name = injSpec.name;
          injectorOffSet.type = 'Injector';

          injectorOffSet.position = injSpec.name.replace('Injector_', '');
          injectorOffSet.vendor = injSpec.value.length > 10 ? (injSpec.value.length <= 25 ? 'Bosch' : 'Delphi') : '';
          injectorOffSet.value = injSpec.value;

          if (this.offSets.findIndex(offSet =>  offSet.value === injectorOffSet.value) === -1) {
            this.offSets.push(injectorOffSet);
          }

        })
      }
    });
  }

  public async saveOffSets() {
    this.loading = true;

    for (const offSet of this.offSets) {
      if (!offSet.stored) {
        const result = await this.qualityManagementService.saveOffSet(this.componentId, this.componentName, offSet);

        if (result) {
          offSet.stored = true;
        }
      }
    }

    await this.initOffSets();
    this.loading = false;
    this.offSetsFound = this.offSets.filter(offSet => !offSet.stored).length > 0;


  }

  protected readonly EnterpriseRoleProviderService = EnterpriseRoleProviderService;
}
