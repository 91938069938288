import {StackIdentifierType} from './stackIdentifierType';
import {ComponentType} from './componentType';
import {Component} from './component';
import {CppItemBase} from '../cppItemBase';
import {StackResponse} from "@cstx/volkswagen-mqs-tracking-service-client";

export class Stack extends CppItemBase {
  id: string;
  stackIdentifier: string;
  stackIdentifierType: StackIdentifierType;

  stackedAt: string;
  dispatchedAt: string;
  stackLabel: string;

  componentType: ComponentType;
  components: Array<Component>;
  typeMappedComponents: Map<ComponentType, Array<Component>>;

  rfid: string;
  transportUnit: string;
  transportDestination: string;

  isLoaded = false;
  deliveryNote: string;
  customer: string;
  storageArea: string;
  storageGroup: string;
  storagePlace: string;
  storageZone: string;
  costCenter: string;

  constructor(stackIdentifier?: string, stackResponse?: StackResponse) {
    super();

    if (stackIdentifier) {
      this.stackIdentifier = stackIdentifier;
      this.stackIdentifierType  = this.getStackIdentifierType();
    }

    if (stackResponse) {
      this.deliveryNote = stackResponse.deliveryNote;
      this.customer =  stackResponse.customer;
      this.storageArea = stackResponse.storageArea;
      this.storageGroup = stackResponse.storageGroup;
      this.storagePlace = stackResponse.storagePlace;
      this.storageZone = stackResponse.storageZone;
      this.costCenter = stackResponse.costCenter;

    }

    this.components = [];
    this.typeMappedComponents          = new Map<ComponentType, Array<Component>>(
      [[ComponentType.Part, [] ] , [ ComponentType.Engine, [] ], [ ComponentType.Unknown, [] ] ]);
    this.componentType       = ComponentType.Unknown;
  }

  public setStackComponentType() {
    this.componentType = ComponentType.Unknown;
  }


  private getStackIdentifierType(): StackIdentifierType {
    const gtlTemplate: RegExp = /^(?=.{22}$)([(1-6)(J)]{2})([A-Z]{2})([0-9]{9})([0-9]{9})$/;
    const mbkTemplate: RegExp = /^(?=.{13})([1]{1})([0-9]{13})$/;

    if (gtlTemplate.test(this.stackIdentifier)) {
      return StackIdentifierType.GTL;
    }

    if (mbkTemplate.test(this.stackIdentifier)) {
      return StackIdentifierType.MBK;
    }

    return StackIdentifierType.Unknown;
  }
}

