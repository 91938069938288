import {BlockingActionStatistic} from './blockingActionStatistic';
import {
  BlockedComponentResponse,
  BlockingActionCreateRequest,
  BlockingActionResponse,
  BlockingActionState,
  BlockingActionUpdateRequest,
  BlockingType,
  ComponentStatus,
  ComponentType,
  CreateBlockingActionV2RequestParams
} from '@cstx/volkswagen-mqs-quality-management-service-client';
import {AuditHistoryEntry} from './auditHistoryEntry';
import {StackResponse} from '@cstx/volkswagen-mqs-tracking-service-client';
import {
  ReleaseBlockedComponentsRequestParams
} from '@cstx/volkswagen-mqs-quality-management-service-client/api/blockingActionsControllerV2.service';


export class BlockingAction {
  id?: string;
  blockingActionNr?: string;
  description?: string;

  componentIds?: string[];
  componentIdentifiers?: string[];


  componentIdentifiersExtension?: string[];
  releasedComponentIds?: string[];

  state?: BlockingActionState;

  blockedAt?: string;
  blockingReason: string;
  blockedBy?: string;

  releasedAt?: string;
  releaseReason?: string;
  releasedBy?: string;

  statistic?: BlockingActionStatistic;

  type?: BlockingType;

  untranslatedComponentNames?: string[]

  public assignedComponents = new Array<BlockedComponentResponse>();
  public blockingServiceRequestType?: BlockingServiceRequestType | null;
  public requestFailed: boolean;
  public history: Array<AuditHistoryEntry> = new Array<AuditHistoryEntry>();

  public stacks: Array<StackResponse>;

  public componentsFile: any;
  public componentsFileHasHeader: boolean = undefined;
  public componentsFileColumnIndex: number = undefined;

  public filterId = '00000000-0000-0000-0000-000000000000'; // ID of the filter entity which is the source of the blockingAction
  public componentReleaseReasons = new Array<string>();

  public trackingInfoLoadingState: TrackingInfoLoadingState = TrackingInfoLoadingState.NOT_LOADED;
  public assignedComponentsLoaded = false;

  public readonly manualStacksLoadingThreshold = 5000;
  public notLoadedForThreshold = false;

  constructor(response: BlockingActionResponse = null) {
    this.blockingServiceRequestType = null;

    if (response) {
        this.id = response.id;

        this.blockedAt = response.blockedAt;
        this.blockingReason = response.blockingReason;

        this.releasedAt = response.releasedAt;
        this.releaseReason = response.releaseReason;

        // TODO: Replace with backend releasedBy
        this.releasedBy = response.releasedAt ? response.modifiedBy : undefined;

        this.state = response.blockingActionState;

        // this.type = response.type;
        this.blockingActionNr = response.humanReadableId;

        // this.blockingFilterId = response.blockingFilterId;
        this.componentIds = response.componentIds;

        // TODO: Replace with backend blockedBy
        this.blockedBy = response.createdBy;
        this.description = response.description;
        this.componentReleaseReasons = Array.from(response.componentReleaseReasons.values());

        this.filterId = response.filterId;

        this.untranslatedComponentNames = response.untranslatedComponentNames
    }
  }

  public getCreateBlockingActionV2Request(): CreateBlockingActionV2RequestParams {
    return {
      // type: this.type,
      action: this.blockingReason,
      blockingReason: this.blockingReason,
      description: this.description,
      componentIdentifiers: this.componentIdentifiers,
      fileParseInputContent: this.componentsFile,
      fileParseInputColumnIndexOverride: this.componentsFileColumnIndex,
      fileParseInputIgnoreTopRowOverride: this.componentsFileHasHeader

    };
  }

  /**
   * Deprecated
   */
  public getBlockingActionCreateRequest(): BlockingActionCreateRequest {
    return {
      type: this.type,
      blockingReason: this.blockingReason,
      description: this.description,
      componentIds: this.componentIds ? this.componentIds : null
    };
  }


  public getBlockingActionUpdateRequest(): BlockingActionUpdateRequest {
    return  {
      releaseReason: this.releaseReason,
      id: this.id
    };
  }

  public getFilterLink(): string {
    if (this.filterId) {
      return 'actions/filter/edit/' + this.filterId;
    } else {
      return null;
    }
  }

  public getBlockingActionReleaseComponentsRequest(): ReleaseBlockedComponentsRequestParams {
    return {
      blockingActionId: this.id,
      releaseReason: this.releaseReason,
      componentIdentifiers: this.releasedComponentIds,
      fileParseInputContent: this.componentsFile,
      fileParseInputColumnIndexOverride: this.componentsFileColumnIndex,
      fileParseInputIgnoreTopRowOverride: this.componentsFileHasHeader

    }
  }

  public getEnginesInBlockingCount(): number {
      if (this.assignedComponents) {
          return this.assignedComponents.filter(c => c.componentType === ComponentType.Engine).length;
      }

      return 0;
  }

  public loadStacksAutomatically(): boolean {
    let isThresholdBigger: boolean = this.manualStacksLoadingThreshold > this.getEnginesInBlockingCount();
    this.notLoadedForThreshold = isThresholdBigger;
    return isThresholdBigger;
  }

  public getEnginesInBlockingBlockedCount() : number {
      if (this.assignedComponents) {
          return this.assignedComponents.filter(c =>
            c.componentType === ComponentType.Engine && c.componentStatus !== ComponentStatus.Released).length;
      }
      return 0;
  }


  public isEngineInBlockingAction(): boolean {
    return this.assignedComponents.some(component => component.componentType === 'Engine');
  }

  private calcEngineCount(isShipped:boolean):number {
    if (!this.stacks) {
      return 0;
    }
    const assignedEnginesIds = this.getDistinctEngineIds();
    return this.stacks
      .filter(stack => (isShipped ? stack.customer !== null : stack.customer === null))
      .reduce((count, stack) =>
        count + stack.components.filter(c => assignedEnginesIds.has(c.componentId)).length, 0
      );
  }

  public getStackedEnginesCount(): number {
    const engineIds = this.getDistinctEngineIds();
    let stackedEngineCount = 0;

    this.stacks.forEach(stack => {
      stack.components.forEach(component => {
        if (engineIds.has(component.componentId)) {
          stackedEngineCount++;
        }
      });
    });
    return stackedEngineCount;
  }


  public getDistinctEngineIds() {
    return new Set(this.assignedComponents.filter(component => component.componentType === 'Engine').map(ac => ac.componentId));
  }

  public getEnginesNotShippedCount(): number {
    if (!this.stacks) {
      return 0;
    }
    return this.getDistinctEngineIds().size - this.getEnginesShippedCount();
  }

  public getEnginesStoredCount(): number {
    return this.calcEngineCount(false);
  }

  public getEnginesShippedCount(): number {
    return this.calcEngineCount(true);
  }
}

export enum BlockingServiceRequestType {
  GET = 'GET',
  RELEASE = 'RELEASE',
  DELETE = 'DELETE',
  EXTEND = 'EXTEND',
  PARTIAL_RELEASE = 'PARTIAL_RELEASE',
  CREATE_FOR_FILTER = 'CREATE_FOR_FILTER'
}

export enum TrackingInfoLoadingState {
  NOT_LOADED = 'NOT_LOADED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  NONE = 'NONE'
}
