import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ConfigService} from './core/services/config.service';
import {CoreModule} from './core/core.module';
import {HomeModule} from './modules/home/home.module';
import {LoginModule} from './modules/login/login.module';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {SharedModule} from './shared/shared.module';
import {FormsModule} from '@angular/forms';
import {AuthInterceptor} from './core/interceptor/http-interceptor';
import {DatePipe, LocationStrategy} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateHttpPropertiesLoader} from './core/utils/properties-loader/ngx-translate-properties-loader';
import {OpMissingTranslationHandler} from './core/utils/OpMissingTranslationHandler';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import {PRESERVE_QUERY_KEYS, QueryParamPreservationStrategy} from './core/utils/query-param-preservation-strategy';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpPropertiesLoader(http, './assets/i18n/messages_', '.properties');
}

const appInitializerFn = (configService: ConfigService) => {
  return () => configService.initializeApp();
};



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: OpMissingTranslationHandler
      },
      useDefaultLang: false,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
    }),
    CoreModule,
    HomeModule,
    LoginModule,
    SharedModule,
    FormsModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot()
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      deps: [ConfigService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: PRESERVE_QUERY_KEYS,
      useValue: new Array<string>('kioskMode')
    },
    {
      provide: LocationStrategy,
      useClass: QueryParamPreservationStrategy,
    },
/*    HttpCancelService,
    { provide: HTTP_INTERCEPTORS,
      useClass: ManageHttpInterceptor,
      multi: true
    },*/
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
