import {Component, EventEmitter, isDevMode, OnDestroy, OnInit} from '@angular/core';
import {Info} from '../info';
import {InfoFilter} from '../infoFilter';
import {KioskModeService} from '../../../../core/services/kiosk-mode.service';
import {InfosService} from '../infos.service';
import {FileExplorerService} from '../../../../shared/components-merged/usecase-file-explorer/file-explorer.service';
import {UserInformationType} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ConfigService} from '../../../../core/services/config.service';
import {EmployeeInfo} from './employeeInfo';
import {parseDate} from 'ngx-bootstrap/chronos';
import {PreviewService} from '../../../../shared/components-merged/usecase-file-explorer/preview/preview.service';
import {UseCaseFile} from '../../../../shared/components-merged/usecase-file-explorer/useCaseFile';
import {LoggingService} from '../../../../core/logging/logging.service';
import {LoggingSource} from '../../../../core/logging/loggingSource';
import {UptimeServiceService} from '../../../../shared/services/uptime-service.service';
import {Subject } from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {HttpEventType} from '@angular/common/http';
import {FilePreview} from '../../../../shared/components-merged/usecase-file-explorer/filePreview';
import fileSize from 'filesize';
import {LocationService} from '../../../../core/services/location.service';
import {PerformanceMetric, ProcessError} from '@cstx/volkswagen-mqs-application-management-service-client';
import {ApplicationManagementService} from '../../../../shared/services/backend/application-management.service';
import {PDFDocumentProxy} from 'ng2-pdf-viewer';


@Component({
  selector: 'op-employee-info',
  templateUrl: './employee-info.component.html',
  styleUrls: ['./employee-info.component.scss']
})
export class EmployeeInfoComponent implements OnInit, OnDestroy {


  /**
   * TODO: Re-set the right timeout settings
   * TODO: Integrate Video viewer
   */

  public set previewMode(value: boolean) {
    this._previewMode = value;

    if (this._previewMode) {
      this.filter.isPublished = undefined;
    } else {
      this.filter.isPublished = true;
    }
  }

  constructor(private infoService: InfosService,
              private fileExplorerService: FileExplorerService,
              private route: ActivatedRoute,
              private router: Router,
              private locationService: LocationService,
              private applicationManagementService: ApplicationManagementService) {

    (window as any).pdfWorkerSrc = '/assets/pdf.worker.min.js';
  }

  public infos = new Array<Info>();
  public updateInProgress: boolean;
  public filter = new InfoFilter();
  public activeTab: string;
  public employeeInfos = new Array<EmployeeInfo>();

  private noReload: boolean;
  private _previewMode: boolean;
  private userInterActionTimeoutIntervalInMs = 30000; // 30 sek
  private tabChangeIntervalInMs = 15000; // 15 sek
  private lastGetInfosRequest: string;
  private employeeInfoCheckIntervalInMs = 300000; // 5min
  private timeBasedReloadInMinutes = 30;
  private costCenter: string;
  private workSequence: string;
  private userInterAction: boolean;

  private onComponentDestroyed: Subject<boolean> = new Subject<boolean>();
  private onUserInteractionStopped = new EventEmitter();
  private onUserInteractionStarted = new EventEmitter();

  private autoChangeTabsTimeout: NodeJS.Timer;
  private userInteractionTimeout: NodeJS.Timer;
  private employeeInfoCheckIntervalTimeout: NodeJS.Timer;

  protected readonly KioskModeService = KioskModeService;

  public async ngOnInit() {
    ConfigService.headerTitle = 'Mitarbeiter Info';

    this.route.queryParamMap
      .pipe(takeUntil(this.onComponentDestroyed))
      .subscribe(params => {

        this.tryConfigureFromQueryParams(params);
    });

    this.initFilter();
    this.initUserInteractionMonitoring();

    UptimeServiceService.start();

    await this.updateV2();

    if (this.employeeInfos.length > 0 ){
      setTimeout(() => {
        this.setActive(this.employeeInfos[0]);
        this.autoChangeTabs();
      }, 100);
    }

    window.addEventListener('unhandledrejection', (event) => {
      // event.preventDefault(); // This will not print the error in the console

      LoggingService.logTrace(LoggingSource.EMPLOYEE_INFO_COMPONENT,
        `UnhandledRejection error catched. ${event.reason} ...`);


      // console.log("Unhandled promise rejection");
      // console.log(event.reason);
    });

    window.addEventListener('error', (event) => {
      // event.preventDefault(); // This will not print the error in the console

      LoggingService.logTrace(LoggingSource.EMPLOYEE_INFO_COMPONENT,
        `Unhandled error catched. ${event.message} ...`);

      LoggingService.logError(LoggingSource.EMPLOYEE_INFO_COMPONENT,
        `Unhandled error catched. ${event.message} ...`, event.error);


      // console.log('Unhandled error encountered');
      // console.log(event.message);
    });

    await this.run();
  }

  private async run() {
    this.employeeInfoCheckIntervalTimeout =  setTimeout(async () => {
      const infos = await this.getInfos();

      if (infos) {
        await this.checkTimeBasedReload();
        const changed = await this.checkInfosChanged(infos);

        if (changed) {
          LoggingService.logTrace(LoggingSource.EMPLOYEE_INFO_COMPONENT,
            `Changed detected between current information displayed and information published in system. Starting update...`);

          await this.updateV2();
        }
      }

      await this.run();

    }, this.employeeInfoCheckIntervalInMs);
  }

  private async getInfos(): Promise<Info[]>  {
    let infos: Array<Info> = null;

    try {
      this.lastGetInfosRequest = new Date().toISOString();

      const response =
        await this.infoService.getInfos(this.filter);

      if (response) {
        infos = response.content.map(userInformationResponse =>
          new Info().mapFromUserInformationResponse(userInformationResponse));

        infos = this.infoService.filterLocal(infos, this.filter);
      }
    } catch (error) {
      LoggingService.logError(LoggingSource.EMPLOYEE_INFO_COMPONENT,
        'Their was error when trying to receive the infos.!', error);
    }

    return infos;
  }

  public async updateV2() {
    if (this.updateInProgress) {
      LoggingService.logWarning(LoggingSource.EMPLOYEE_INFO_COMPONENT,
        'Their is already an update in progress. Aborting...!');
      return;
    }

    this.updateInProgress = true;

    const infos        = await this.getInfos();

    if (infos === null || infos.length < 1) {
      this.updateInProgress = false;

      LoggingService.logWarning(LoggingSource.EMPLOYEE_INFO_COMPONENT,
        'No infos to process! Aborting....');
      return;
    }

    const useCaseFiles = new Array<UseCaseFile>();
    for (const info of infos) {
      /**
       * Generating useCaseFiles for every file of the current info.
       */
      if (info.informationType === UserInformationType.Employeeinfo) {

        useCaseFiles.push(...
          await this.fileExplorerService.GetFilesForUseCaseAsync
          ('infos', 1, 50, true, info.id));

        LoggingService.logTrace(LoggingSource.EMPLOYEE_INFO_COMPONENT,
          `Added files for info [${info.title}] to internal useCaseFiles list for further processing.`);
      }

      /**
       * If the info is of type stopnotification, we are creating a pdf, containing the image
       * on index 0 in the info and then adding the employeeInfo object to the list of objects
       */
      if (info.informationType === UserInformationType.Stopnotification) {
        info.fileList = await this.fileExplorerService.GetFilesForUseCaseAsync
        ('infos', 1, 50, true, info.id);

        const stopSignPdf = await this.infoService.makePdfAsync(info);

        if (stopSignPdf === null) {
          return;
        }

        info.fileList = new Array<UseCaseFile>(); // This is a workaround, to prevent the constant detection of changes

        const employeeInfo = new EmployeeInfo();
        await employeeInfo.setFromStopNotificationInfo(info, stopSignPdf);

        this.employeeInfos.push(employeeInfo);
      }
    }

    /**
     * Foreach useCaseFile of infos, we are creating an employee info and add it to
     * the list of objects to show.
     */
    for (const useCaseFile of useCaseFiles) {
        const s3Key = useCaseFile.s3Key.split('/')
        const info = infos.find(i => i.id === s3Key[1]);

        const employeeInfo =
          new EmployeeInfo(s3Key[s3Key.length - 1],
            useCaseFile, info.id, info.isPublished,
            info.informationType === UserInformationType.Stopnotification);


        if (this.employeeInfos.findIndex(existing => existing.title === employeeInfo.title) === -1) {

          await this.createFilePreview(employeeInfo);

          this.employeeInfos.push(employeeInfo);
        }
    }

    this.infos = infos;
    this.updateInProgress = false;
  }

  public async checkInfosChanged(freshInfos: Array<Info>): Promise<boolean> {
    if (!this.infos) {
      return false;
    }

    if (this.infos.length !== freshInfos.length) {
      /**
       * Current and new infos count are different.
       */

      LoggingService.logInfo(LoggingSource.EMPLOYEE_INFO_COMPONENT,
        'Current and new infos count are different. Change detected!');
      return true;
    }

    freshInfos.forEach(freshInfo => {
      if (parseDate(freshInfo.modifiedAt) > parseDate(this.lastGetInfosRequest)) {
        /**
         * At least one info was changed since last loaded infos.
         */

        LoggingService.logInfo(LoggingSource.EMPLOYEE_INFO_COMPONENT,
          'At least one info was changed since last loaded infos. Change detected!');
        return true;
      }

      const oldInfo = this.infos.find(i => i.id === freshInfo.id);
      if (oldInfo && (freshInfo.fileList?.length !== oldInfo.fileList.length)) {
        /**
         * At least one info has a more or less files then last time loaded.
         */

        LoggingService.logInfo(LoggingSource.EMPLOYEE_INFO_COMPONENT,
          'At least one info has a more or less files then last time loaded. Change detected!');

        return true;
      }
    });

    return false;
  }

  public isActive(tab: string): boolean {
    if (tab === this.activeTab) {
      return true;
    }

    return false;
  }

  public setActive(employeeInfo: EmployeeInfo) {
      this.activeTab = employeeInfo.title;

    /**
     * Set currently selected employeeInfo to selected false.
     */
    const info = this.employeeInfos.find(i => i.selected);
      if (info) {
        info.selected = false;
      }

    /**
     * Mark new selected employeeInfo aus selected true.
     */
    employeeInfo.selected = true;
  }

  public userInteractionHandler() {
    if (!this.userInterAction) {
      this.onUserInteractionStarted.emit();
    }

    clearTimeout(this.userInteractionTimeout)
    this.userInteractionTimeout = setTimeout(() => {
      this.onUserInteractionStopped.emit();
    }, this.userInterActionTimeoutIntervalInMs);
  }


  private initFilter() {
    this.filter.currentPageIndex = 0;
    this.filter.sort = ['activeFrom,desc'];
    this.filter.informationTypes = [UserInformationType.Employeeinfo, UserInformationType.Stopnotification];
    this.filter.costCenter = this.costCenter;
    this.filter.workSequence = this.workSequence;
    this.filter.isActive = true;

    LoggingService.logTrace(LoggingSource.EMPLOYEE_INFO_COMPONENT,
      `Method [initFilter] finished.`);
  }

  private initUserInteractionMonitoring() {
    this.userInteractionHandler();

    this.onUserInteractionStopped
      .pipe(takeUntil(this.onComponentDestroyed))
      .subscribe(() => {
      LoggingService.logInfo(LoggingSource.EMPLOYEE_INFO_COMPONENT, 'User stopped to interact with this screen!');

      this.userInterAction = false;
      this.autoChangeTabs();
    });

    this.onUserInteractionStarted
      .pipe(takeUntil(this.onComponentDestroyed))
      .subscribe(async () => {
        LoggingService.logInfo(LoggingSource.EMPLOYEE_INFO_COMPONENT, 'User started to interact with this screen!');

        this.userInterAction = true;
        clearTimeout(this.autoChangeTabsTimeout);

        const metrics = new Array<PerformanceMetric>();
        const errorList = new Array<ProcessError>();
        await this.applicationManagementService.postStatus(metrics, errorList);
      })
  }

  private autoChangeTabs() {
    if (this.userInterAction) {
      return;
    }

    this.autoChangeTabsTimeout = setTimeout(() => {
      if (this.employeeInfos.length >= 1) {
        const activeFileIndex =
          this.employeeInfos.findIndex(e => e.title === this.activeTab);

        if (this.employeeInfos.length === activeFileIndex + 1) {
          this.setActive(this.employeeInfos[0]);
        } else {
          this.setActive(this.employeeInfos[activeFileIndex + 1]);
        }
      }

      clearTimeout(this.autoChangeTabsTimeout);
      this.autoChangeTabs();

    }, this.tabChangeIntervalInMs);
  }

  private async checkTimeBasedReload() {
    if (this.noReload) {
      return;
    }

    if (UptimeServiceService.upTimeInMs / 1000 / 60 > this.timeBasedReloadInMinutes) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';

      UptimeServiceService.reset();

      LoggingService.logTrace(LoggingSource.EMPLOYEE_INFO_COMPONENT,
        `Method [checkTimeBasedReload] finished. Reload is requested due to elapsed time. Calling self...`);

      await this.router.navigateByUrl(this.router.url)
    }
  }

  private async createFilePreview(employeeInfo: EmployeeInfo) {
    try {

      const downloadObservable =
        this.fileExplorerService.GetUseCaseFileAsync('infos', employeeInfo.file.s3Key);

      downloadObservable.subscribe(httpEvent => {
        if (httpEvent.type === HttpEventType.DownloadProgress) {

          const intermediate = httpEvent.loaded / parseInt(employeeInfo.file.Size) * 100;
          employeeInfo.file.DownloadProgress = Math.round((intermediate + Number.EPSILON) * 100) / 100
        }

        if (httpEvent.type === HttpEventType.Response) {
          // TODO: replace with constructor mapping or getFilePreview from PreviewRequest mapping
          const filePreview = new FilePreview();
          filePreview.fileName = employeeInfo.file.Name;
          filePreview.OriginalName = employeeInfo.file.OriginalName;
          filePreview.fileType = PreviewService.getFileType(filePreview.fileName);
          filePreview.Uploaded = employeeInfo.file.CreatedAt;
          filePreview.Size = fileSize(employeeInfo.file.Size as unknown as number);

          if (httpEvent.body) {
            filePreview.Blob = httpEvent.body;
            filePreview.file = httpEvent.body;
          }

          employeeInfo.filePreview = filePreview;
        }
      });
    }
    catch (error) {
      LoggingService.logError(LoggingSource.EMPLOYEE_INFO_COMPONENT,
        `There was an error when trying to create filePreview for employeeInfo [${employeeInfo.title}]!`, error);
    }
  }

  public ngOnDestroy(): void {
    clearTimeout(this.employeeInfoCheckIntervalTimeout);
    clearTimeout(this.autoChangeTabsTimeout);
    clearTimeout(this.userInteractionTimeout);

    if (isDevMode()) {
      console.log('Destroying employee-info component');
    }

    this.infos = null;
    this.employeeInfos = null;
    this.onComponentDestroyed.next(true)
    this.onComponentDestroyed.complete();
  }

  private tryConfigureFromQueryParams(params: ParamMap) {
    const employeeInfoCheckIntervalInMsOverride =
      parseInt(params.get('refreshInMs'));

    if (employeeInfoCheckIntervalInMsOverride >= 5000) {
      this.employeeInfoCheckIntervalInMs = employeeInfoCheckIntervalInMsOverride;
    } else {
      LoggingService.logWarning(LoggingSource.EMPLOYEE_INFO_COMPONENT,
        `Value for refreshInMs was not set or invalid. Only values above 5000 are allowed. Using default [${this.employeeInfoCheckIntervalInMs} ms]`);
    }

    const timeBasedReloadInMinutes =
      parseInt(params.get('reloadIntervalInMinutes'));

    if (timeBasedReloadInMinutes >= 3) {
      this.timeBasedReloadInMinutes = timeBasedReloadInMinutes;
    } else {
      LoggingService.logWarning(LoggingSource.EMPLOYEE_INFO_COMPONENT,
        `Value for timeBasedReloadInMinutes was not set or invalid. Only values above 3 are allowed. Using default [${this.timeBasedReloadInMinutes} minutes]`);
    }

    const tabChangeIntervalInMs =
      parseInt(params.get('tabChangeIntervalInMs'));

    if (tabChangeIntervalInMs >= 15000) {
      this.tabChangeIntervalInMs = tabChangeIntervalInMs;
    } else {
      LoggingService.logWarning(LoggingSource.EMPLOYEE_INFO_COMPONENT,
        `Value for tabChangeIntervalInMs was not set or invalid. Only values above 15000 are allowed. Using default [${this.tabChangeIntervalInMs} ms]`);
    }

    const userInterActionTimeoutIntervalInMs =
      parseInt(params.get('userInterActionTimeoutIntervalInMs'));

    if (userInterActionTimeoutIntervalInMs >= 5000) {
      this.userInterActionTimeoutIntervalInMs = userInterActionTimeoutIntervalInMs;
    } else {
      LoggingService.logWarning(LoggingSource.EMPLOYEE_INFO_COMPONENT,
        `Value for userInterActionTimeoutIntervalInMs was not set or invalid. Only values above 5000 are allowed. Using default [${this.userInterActionTimeoutIntervalInMs} ms]`);
    }


    this.noReload
      = params.get('noReload') === 'true';


    this.previewMode = params.get('previewMode') === 'true';

    this.costCenter = params.get('costCenter');
    if (this.costCenter && this.costCenter !== '') {
      ConfigService.headerTitle = ConfigService.headerTitle + ' ' + this.costCenter;
    }

    this.workSequence = params.get('workSequence');
    if (this.workSequence && this.workSequence !== '') {
      ConfigService.headerTitle = ConfigService.headerTitle + ' ' + this.workSequence;
    }

    if (this.costCenter && this.workSequence) {
      this.locationService.setDeviceLocation(this.costCenter, this.workSequence);
    }
  }

  public handlePdfViewerError(event: PDFDocumentProxy) {
    LoggingService.logError(LoggingSource.EMPLOYEE_INFO_COMPONENT,
      `There was an error emitted from 3rd party pdf-viewer component.`, event._pdfInfo.error);
  }

  public handlePdfViewerPagesInitialized(event: PDFDocumentProxy) {
    LoggingService.logTrace(LoggingSource.EMPLOYEE_INFO_COMPONENT,
      `There was an event emitted from 3rd party pdf-viewer on handlePdfViewerPagesInitialized component.`);
  }

  public handlePdfViewerPageRendered(event: PDFDocumentProxy) {
    LoggingService.logTrace(LoggingSource.EMPLOYEE_INFO_COMPONENT,
      `There was an event emitted from 3rd party pdf-viewer on handlePdfViewerPageRendered component.`);
  }

  public handlePdfViewerAfterLoadComplete(event: PDFDocumentProxy)
  {
    LoggingService.logTrace(LoggingSource.EMPLOYEE_INFO_COMPONENT,
      `There was an event emitted from 3rd party pdf-viewer on handlePdfViewerAfterLoadComplete component.`);
  }
}
