import {EventEmitter, Injectable, isDevMode} from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {
  public static isStarted = false;

  public static traceLogEnabled = false;
  public static consoleLogOnly = false;
  public static year: string;
  public static calendarWeek: string;
  public static partNumber: string;
  public static activeTab: string;

  private static unsubscribe
    = new Subject<boolean>();

  public static queryParamChanged = new EventEmitter();

  public static start(route: ActivatedRoute) {
    route.queryParamMap
      .pipe(takeUntil(QueryParamsService.unsubscribe))
      .subscribe(queryParams => {

        if (isDevMode()) {
          QueryParamsService.traceLogEnabled = true;
          QueryParamsService.consoleLogOnly = true;
        } else {
          QueryParamsService.traceLogEnabled = queryParams.get('traceLogEnabled') === 'true';
          QueryParamsService.consoleLogOnly = queryParams.get('consoleLogOnly') === 'true';
        }

        QueryParamsService.activeTab = queryParams.get('activeTab') ?? 'home';
        QueryParamsService.year = queryParams.get('year');
        QueryParamsService.calendarWeek = queryParams.get('calendarWeek');
        QueryParamsService.partNumber = queryParams.get('partNumber');

        QueryParamsService.isStarted = true;

        QueryParamsService.queryParamChanged.emit();
      });
  }

  public static stop() {
    QueryParamsService.unsubscribe.next(true);
    QueryParamsService.unsubscribe.complete();

    QueryParamsService.isStarted = false;
  }
}
