<op-detail-section-wrapper
  [title]="'offSets.label' | translate"
  [description]="'offSets.description' | translate"
  [contentTemplate]="contentTemplate"
  [hasContent]="true"
  [selfLink]="selfLink"
  [contentContainerId]="contentContainerId"
  [loading]="loading">
</op-detail-section-wrapper>

<ng-template #contentTemplate>
  <ng-container *opRequiredClaimByName="'engine-offsets-write'">
    <div class="row pt-1 pb-1">
      <div class="col">
        {{ 'offSets.try-parse-offsets' | translate }}

        <ng-container *ngIf="noNewOffSetsFound">
            <div class="small">
              {{ 'offSets.no-new-off-sets' | translate }}
            </div>
        </ng-container>

      </div>

      <div class="col small">
        <button type="button" class="btn btn-sm btn-primary mr-3"
                [disabled]="loading"
                (click)="parseOffSets()">{{ 'offSets.execute' | translate }}
        </button>
        <ng-container *ngIf="loading">
          <i class="far fa-hourglass fa-spin" style="color: darkgray"></i>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="offSetsFound">
        <div class="row pt-3">
          <div class="col">
            <h6>{{ 'offSets.new-found' | translate }}</h6>
          </div>
        </div>

      <div class="row pl-3">
        <div class="cpp-column-fixed-width-175">{{ 'offsets.type' | translate }}</div>
        <div class="cpp-column-fixed-width-175">{{ 'offsets.offset' | translate }}</div>
        <div class="cpp-column-fixed-width-320 pl-2">{{ 'offsets.rawValue' | translate }}</div>
        <div class="col pr-1"></div>
      </div>

      <ng-container *ngFor="let offSet of offSets; index as i">
        <div class="row pl-3 pb-1" *ngIf="!offSet.stored">
          <div class="cpp-column-fixed-width-175">
            {{ offSet.type }} {{ offSet.position }}
          </div>
          <div class="cpp-column-fixed-width-175 text-monospace">
            {{ offSet.value }}
          </div>
          <div class="cpp-column-fixed-width-320 text-monospace overflow-hidden text-overflow-ellipses pre-white">
            {{ offSet.raw }}
          </div>
          <div class="col pr-1">
            <i class="fas fa-times small pointer" (click)="onOffSetDelete(i)"></i>
            <ng-container *ngIf="offSetDeletePendingId && offSetDeletePendingId === offSet.id">
              <i class="far fa-hourglass fa-spin" style="color: darkgray"></i>
            </ng-container>
          </div>
        </div>
      </ng-container>

        <ng-container *ngIf="offSetsFound">
          <button type="button" class="btn btn-sm btn-primary mr-3"
                  [disabled]="loading"
                  (click)="saveOffSets()">{{ 'offSets.execute-add' | translate }}
          </button>
          <ng-container *ngIf="loading">
            <i class="far fa-hourglass fa-spin" style="color: darkgray"></i>
          </ng-container>

      </ng-container>
    </ng-container>
  </ng-container>



<ng-template #noContentTemplate>
  <div class="row">
  <div *ngIf="(!offSets || offSets.length > 1) && !loading" class="col-12 m-0 pb-0 pt-0">
    <div class="col text-center">{{ 'global.nothing-to-show' | translate }}</div>
  </div>
  <div *ngIf="(!offSets || offSets.length > 1) && loading" class="col-12 m-0 pb-0 pt-0">
    <div class="col text-center">
      <op-spinner-small *ngIf="loading"></op-spinner-small>
    </div>
  </div>
  </div>
</ng-template>

<ng-container *ngIf="offSets && offSets.length > 0; else noContentTemplate">
  <div class="pb-3 pt-3">
    <div class="row">
      <div class="col">
        <h6>{{ 'offSets.current-found' | translate }}</h6>
      </div>
    </div>

    <div class="row pl-3">
      <div class="cpp-column-fixed-width-175">{{ 'offsets.type' | translate }}</div>
      <div class="cpp-column-fixed-width-175">{{ 'offsets.offset' | translate }}</div>
      <div class="cpp-column-fixed-width-320 pl-2">{{ 'offsets.rawValue' | translate }}</div>
      <div class="col pr-1"></div>
    </div>
    <ng-container *ngFor="let offSet of offSets; index as i">
      <div class="row pl-3 pb-1" *ngIf="offSet.stored">
        <div class="cpp-column-fixed-width-175">
          {{ offSet.type }} {{ offSet.position }}
        </div>
        <div class="cpp-column-fixed-width-175 text-monospace">
          {{ offSet.value }}
        </div>
        <div class="cpp-column-fixed-width-320 text-monospace overflow-hidden text-overflow-ellipses pre-white">
          {{ offSet.raw }}
        </div>
        <div class="col pr-1">
          <op-delete-entry-with-confirmation class="pr-1"
                                             [trashIconClasses]="'cpp-icon-delete'"
                                             [securityQuestion]="'offset.delete-question' | translate"
                                             [errorMessage]="undefined"
                                             [disabled]="offSetDeletePendingId && offSetDeletePendingId !== offSet.id"

                                             [hidden]="!EnterpriseRoleProviderService.hasRequiredClaimByName('engine-offsets-write')
                                              || (offSetDeletePendingId && offSetDeletePendingId === offSet.id)"

                                             (onConfirmation)="onOffSetDelete(i)"
          >
          </op-delete-entry-with-confirmation>
          <ng-container *ngIf="offSetDeletePendingId && offSetDeletePendingId === offSet.id">
            <i class="far fa-hourglass fa-spin" style="color: darkgray"></i>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
</ng-template>
