import {Injectable} from '@angular/core';
import {
  BulkCopyV2Request,
  BulkCopyV2Response,
  ComponentTypeEnum, CopyV2Request, CopyV2Response,
  DataFile,
  FileControllerService,
  FileTypeEnum,
  UsecaseMatchgroupControllerService,
  UseCaseMetaData
} from '@cstx/volkswagen-mqs-file-handling-service-client';
import {UseCaseFile} from './useCaseFile';
import {map} from 'rxjs/operators';
import {FileUpload} from './fileUpload';
import {ConfigService} from '../../../core/services/config.service';
import {AuthService} from '../../../core/services/auth.service';
import {FileType} from './fileType';
import {firstValueFrom, Observable} from 'rxjs';
import {FilterValue} from '../../components/guided-filter-input/guided-filter-input.component';
import {ErrorHandler} from '../../services/error-handler/error-handler';
import {HttpEvent} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileExplorerService {
  private filterValues: Array<FilterValue> = new Array<FilterValue>();
  constructor(private fileControllerService: FileControllerService,
              private useCaseMatchGroupController: UsecaseMatchgroupControllerService,
              private configService: ConfigService,
              private authService: AuthService) {
  }

  regExUploadPattern = [{usecase: 'sample', pattern: '!_([7]{1}[0-9]{3})#-#_([0-9\-]{10})'}];


  public GetFilesForUseCase(usecase: string, page: number = 1,
                            pagingSize: number = 250,
                            withMetadata = false,
                            searchTerm: string = ''): Promise<UseCaseFile[]> {
    return new Promise<UseCaseFile[]>((resolve, reject) => {
      let files = new Array<UseCaseFile>();

      searchTerm = this.filterValues.length > 0 ? this.GenerateSearchTerm() : searchTerm;

      this.fileControllerService.getFilesForUseCase({
        useCase: usecase,
        withMetadata,
        maxPageSize: pagingSize,
        pageNumber: page,
        prefix: searchTerm

      })
        .toPromise()
        .then(response => {
          response.Contents?.forEach(_ => {
            const file = new UseCaseFile();
            file.MapFromUseCaseForm(_);

            files.push(file);
          });

          files = this.sortForUseCase(files);

          resolve(files);
        })
        .catch(error => {
          ErrorHandler.printError(error);
          resolve(files);
        });
    });
  }

  public async GetFilesForUseCaseAsync(usecase: string,
                                       page: number = 1,
                                       pagingSize: number = 250,
                                       withMetadata = false,
                                       searchTerm: string = ''): Promise<UseCaseFile[]> {
    try {
      const request = await this.fileControllerService.getFilesForUseCase({
        useCase: usecase,
        withMetadata,
        maxPageSize: pagingSize,
        pageNumber: page,
        prefix: searchTerm
      });

      const result = await firstValueFrom(request);

      let files = new Array<UseCaseFile>();

      result.Contents.forEach(_ => {
        const file = new UseCaseFile();
        file.MapFromUseCaseForm(_);

        files.push(file);
      });

      files = this.sortForUseCase(files);

      return files;
    } catch (error) {
      ErrorHandler.printError(error);
      return null;
    }
  }

  public async GetSearchGroupForUseCase(useCase: string): Promise<string[]> {
    const r = await this.useCaseMatchGroupController.getUseCaseMatchGroup();
    const result = await firstValueFrom(r);

    const arr = new Array<string>();

    // debugger;
    Object.entries(result).forEach(e => {
      if (e[0] === useCase) {

        e[1].forEach(ei => {
          arr.push(ei);
        })
      }
    });

    return arr;
  }


  public GetFile(id: string, fileName: string, componentType: ComponentTypeEnum, fileType: FileTypeEnum): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      this.fileControllerService.getFile({
        componentType,
        id,
        fileName,
        fileType
      })
        .pipe(map(value => value as Blob))
        .toPromise()
        .then(response => {
          resolve(response);
        })
        .catch(reason => {
          reject(reason);
        });
    });
  }

  public GetUseCaseFile(usecase: string, s3key: string): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      this.fileControllerService.getFileV2({
        usecase,
        s3key
      })
        .pipe(map(value => value as Blob))
        .toPromise()
        .then(response => {
          resolve(response);
        })
        .catch(reason => {
          reject(reason);
        });
    });
  }

  public GetUseCaseFileAsync(usecase: string, s3key: string): Observable<HttpEvent<Blob>> {
      const result =
        this.fileControllerService.getFileV2({
        usecase, s3key
      }, 'events', true);

    return result;
  }

  // Error beim sperren per Datei
  public DeleteFromUseCase(s3key: string, useCase: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.fileControllerService.deleteFileV2({
        usecase: useCase,
        s3key
      }).toPromise()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          ErrorHandler.printError(error);
          reject(false);
        });
    });
  }

  public UploadToUseCase(fileUploadRequest: FileUpload, useCase: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.fileControllerService.uploadGeneric({
        useCase,
        source: fileUploadRequest.source,
        file: fileUploadRequest.file,
        origin: this.authService.currentUser.email,
        s3Key: fileUploadRequest.s3Key,
        ogName: true,
        regExS3KeyPattern: this.getRegExUploadPattern(useCase),
      }).toPromise()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          ErrorHandler.printError(error);
          reject(false);
        });
    });
  }

  public CopyFileInUseCase(sourcePath: string, targetPath: string, useCase: string): Promise<CopyV2Response> {
    return firstValueFrom(this.fileControllerService.copyFileV2({s3sourcekey: sourcePath, s3targetkey: targetPath, usecase: useCase}));
  }

  public BulkCopyFileInUseCase(operations: CopyOperation[], useCase: string): Promise<BulkCopyV2Response> {
    const requests: BulkCopyV2Request = {
      copyRequests: operations.map(op => {
        const request: CopyV2Request = {sourceS3Key: op.sourcePath, targetS3Key: op.targetPath};
        return request;
      })
    }
    return firstValueFrom(this.fileControllerService.bulkCopyFileV2({bulkCopyV2Request: requests, usecase: useCase}));
  }

  private sort(response: Array<DataFile>) {
    return response.sort((a, b) => {
      if (a.modifiedDate < b.modifiedDate) {
        return 1;
      }

      if (a.modifiedDate > b.modifiedDate) {
        return -1;
      }

      return 0;
    });
  }

  private sortForUseCase(response: Array<UseCaseFile>) {
    return response.sort((a, b) => {
      if (a.OriginalName > b.OriginalName) {
        return 1;
      }

      if (a.OriginalName < b.OriginalName) {
        return -1;
      }

      return 0;
    });
  }

  public GetUseCaseFileMetadata(usecase: string, s3key: string): Promise<UseCaseMetaData> {
    return new Promise<UseCaseMetaData>((resolve, reject) => {
      this.fileControllerService.getMetadataV2({
        usecase,
        s3key
      }).toPromise().then(result => {
        resolve(result);
      });
    });
  }


  public GetFileMetadata(id: string, fileName: string, componentType: ComponentTypeEnum, fileType: FileTypeEnum) {
    return new Promise<any>((resolve, reject) => {
      this.fileControllerService.getMetadata({
        componentType,
        id,
        fileName,
        fileType
      }).toPromise()
        .then(response => {
          resolve(response);
        })
        .catch(reason => {
          reject(reason);
        });
    });
  }


  // This filetype is only for visualization purposes
  // and no copy of the file-handling-service FileTypeEnum
  GetFileTypeBasedOnFileExtension(fileName: string): FileType {
    if (!fileName) {
      return;
    }

    let fileExtension = fileName.split('.').pop();
    fileExtension = fileExtension.toLowerCase();

    switch (fileExtension) {
      case 'img':
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'svg':
      case 'bmp':
        return FileType.Image;

      case 'csv':
      case 'txt':
      case 'json':
      case 'yaml':
      case 'dfq':
        return FileType.Text;

      case 'pdf':
        return FileType.Pdf;

      case 'bin':
      case 'rmb':
      case 'dat':
        return FileType.Binary;
    }

    return FileType.Other;
  }

  private checkFileType(file: any): FileTypeEnum {

    let fileType = file.type;
    if (typeof fileType === 'string' && fileType.includes('image')) {
      fileType = 'image';
    }

    switch (fileType) {
      case 'text/plain':
        return 'txt';
      case 'application/octet-stream':
        return 'bin';
      case 'image':
        return 'image';
      default:
        return this.checkFileBasedOnExtension(file.name);
    }
  }

  private checkFileBasedOnExtension(fileName): FileTypeEnum {
    if (!fileName) {
      return;
    }

    let fileExtension = fileName.split('.').pop();
    fileExtension = fileExtension.toLowerCase();

    switch (fileExtension) {
      case 'img':
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'svg':
        return 'image';

      case 'csv':
      case 'txt':
      case 'xml':
      case 'json':
        return 'txt';

      case 'bin':
      case 'rmb':
      case 'dat':
        return 'bin';
    }

    return 'other';
  }

  /**
   *
   * @param file The file as blob we want to present as download
   * @param fileName The target filename for the provided file
   * @public
   * @async
   *
   * This function create a pseudo a href class and provides the given blob
   * as download with the given name as target name used for storing.
   *
   */
  public provideFileAsDownload(file: Blob, fileName: string) {
    const objectUrl: string = URL.createObjectURL(file);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  getUseCaseDirectLink(token: string, s3key: string, usecase: string): string {
    let fhsUrl = this.configService.getFhsApiUrl();

    const apiSuffix = '/v2/public/file?usecase=' + usecase + '&s3key=' + encodeURIComponent(s3key) + '&token=' + token;

    fhsUrl = fhsUrl + apiSuffix;
    return fhsUrl;
  }

  private getRegExUploadPattern(useCase: string) {
    const pattern = this.regExUploadPattern.find(p => p.usecase === useCase);

    if (pattern === undefined) {
      return '';
    }

    return pattern.pattern;
  }

  SetFilter(filterValues: Array<FilterValue>) {
    this.filterValues = filterValues;
  }

  private GenerateSearchTerm() {
    let _ = '';

    this.filterValues.forEach(v => {
      if (_ === '') {
        _ = _ + v.value;
      } else {
        _ = _ + '/' + v.value;
      }
    })
    return _;
  }
}

export class CopyOperation {
  constructor(
    public sourcePath: string,
    public targetPath: string
  ) {}
}


