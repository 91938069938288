<div class="pr-3 pl-3" (mouseenter)="setHover(true)" (mouseleave)="setHover(false)" style="position: relative;">
  <div class="d-flex flex-column justify-content-center align-items-center pointer"
       style="position: absolute; top: 1px; right: 0; height: 50px; width: 50px; background-color: lightgray;"
       (click)="onDeleteRequested.emit(componentCheckResult.componentName)"
        *ngIf="!noRemove">
    <i class="cpp-icon-delete-2"></i>
  </div>

  <div class="d-flex flex-column justify-content-center align-items-center"
       [ngStyle]="{ 'top': noRemove ? '1px' : '53px '}"
       style="position: absolute; right: 0; height: 50px; width: 50px; background-color: lightgray;">

    <a [href]="componentCheckResult?.selfLink" target="_blank">
      <i class="cpp-icon-link"></i>
    </a>

  </div>

  <div class="d-flex flex-column justify-content-center align-items-center"

       [class.pointer]="componentCheckResult && componentCheckResult.details && !componentCheckResult.details.isEmpty"
       [class.detailsEmpty]="componentCheckResult && componentCheckResult.details && componentCheckResult.details.isEmpty"
       [ngStyle]="{ 'top': noRemove ? '53px' : '105px '}"
       style="position: absolute; right: 0; height: 50px; width: 50px; background-color: lightgray;"
       (click)="toggleDetailsSections()">

    <i [ngClass]="[detailsExtended ? 'cpp-icon-extend-details' : 'cpp-icon-collapsed-details']"
       [class.detailsEmpty]="componentCheckResult && componentCheckResult.details && componentCheckResult.details.isEmpty"
    ></i>
  </div>

  <div class="row pr-5">
    <div class="cpp-column-fixed-width-8 border border-white"
         [ngStyle]="{ 'background-color': hover ? '#B8DAFF' : ''}"></div>
    <div class="col d-flex flex-column justify-content-center align-items-center border border-white"
         style="min-height: 155px;"
         [tooltip]="logisticalStateTooltipTemplate"
         [ngStyle]=" { 'background-color': componentCheckResult.blocked ? 'lightcoral' : 'lightgreen' } ">



      <ng-template #logisticalStateTooltipTemplate>
        <op-logistical-state-tooltip [engine]="componentCheckResult.engine" [part]="componentCheckResult.part"></op-logistical-state-tooltip>
      </ng-template>

      <ng-container *ngIf="componentCheckResult"
                    [ngSwitch]="componentCheckResult.componentType === 'engine'
                    ? componentCheckResult.engine.getLogisticalState()
                    : componentCheckResult.part.getLogisticalState()">

        <ng-container *ngSwitchCase="LogisticalState.FREE">
          <i class="fas"
             [class.fa-cogs]="componentCheckResult.componentType === 'engine'"
             [class.fa-cog]="componentCheckResult.componentType !== 'engine'"
             style="color: darkgreen; font-size: 5em; font-weight: bolder">
          </i>
        </ng-container>
        <ng-container *ngSwitchCase="LogisticalState.UNFREE">
          <i class="fas"
             [class.fa-cogs]="componentCheckResult.componentType === 'engine'"
             [class.fa-cog]="componentCheckResult.componentType !== 'engine'"
             style="color: darkred; font-size: 5em; font-weight: bolder">
          </i>
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{
            componentCheckResult.componentType === 'engine'
              ? componentCheckResult.engine.getLogisticalState()
              : componentCheckResult.part.getLogisticalState()
          }}
        </ng-container>

        <div style="font-weight: bolder; font-size: 0.9em">
          {{ componentCheckResult.selfManufactured ? componentCheckResult.manufacturer : '' }}
        </div>
      </ng-container>
    </div>
    <div class="col-8">
      <div class="row" style="min-height: 90px;">
        <div
          class="col-6 d-flex flex-column justify-content-center align-items-center border border-white"
          style="background-color: #00e5ff; font-weight: bolder; white-space: nowrap"


          [ngStyle]="{ 'font-size': componentCheckResult.componentName.length > 10 ? '1.2em' : '2em'}"
        >
          <div style="width: 100%;overflow-wrap: break-word"
              [class.text-wrap]="componentCheckResult.componentName.length > 10">

            {{ componentCheckResult.componentName }}
          </div>

        </div>
        <div class="col-6 d-flex flex-column justify-content-center align-items-center border border-white"
             style="background-color: #00e5ff">

          <div>
            {{
              componentCheckResult.componentType === 'engine'
                ? componentCheckResult.engine.keyCode
                : componentCheckResult.part.partNumber
            }}
          </div>

          <div *ngIf="componentCheckResult.componentType === 'engine'">
            <op-datetime-show [dateTime]="componentCheckResult.engine.buildDate"></op-datetime-show>
          </div>
          <div *ngIf="componentCheckResult.componentType !== 'engine'">
            <op-datetime-show [dateTime]="componentCheckResult.part.productionDate"></op-datetime-show>
          </div>

        </div>
      </div>
      <div class="row" style="min-height: 90px">

        <div class="col d-flex flex-column justify-content-center align-items-center border border-white pointer"
             style="background-color: #eeeeee"
             [tooltip]="hasKeyCodeHistoryTooltip"
             *ngIf="componentCheckResult && componentCheckResult.engine"
        >
           <span class="position-relative mr-2 d-inline-block icon-container">
            <i class="fas fa-key lead-icon" style="color: lightgray; font-weight: bold; font-size: 2em"
               [class.hasKeyCodeHistory]="componentCheckResult.engine.hasKeyCodeHistory"></i>
          </span>
        </div>

        <div class="col d-flex flex-column justify-content-center align-items-center border border-white pointer"
             style="background-color: #eeeeee"
             [tooltip]="blockedTooltip">
          <span *ngIf="componentCheckResult && componentCheckResult.engine;"
                class="position-relative mr-2 d-inline-block icon-container">
            <i class="fas" style="color: lightgray; font-weight: bold; font-size: 2em"
               [ngClass]="componentCheckResult.engine.engineBlockedState !== 'NONE' ? 'fa-lock' : 'fa-lock-open'"
               [ngStyle]="{ 'color': componentCheckResult.engine.engineBlockedState !== 'NONE' ? 'darkred' : ( !componentCheckResult.engine.hasBlockingActions ? 'lightgray' : 'darkgreen') }"></i>
          </span>

          <span *ngIf="componentCheckResult && componentCheckResult.part"
                class="position-relative mr-2 d-inline-block icon-container">
            <i class="fas" style="color: lightgray; font-weight: bold; font-size: 2em"
               [ngClass]="componentCheckResult.part.partBlockedState !== 'NONE' ? 'fa-lock' : 'fa-lock-open'"
               [ngStyle]="{ 'color': componentCheckResult.part.partBlockedState !== 'NONE' ? 'darkred' : ( !componentCheckResult.part.hasBlockingActions ? 'lightgray' : 'darkgreen') }"></i>
          </span>
        </div>
        <div class="col d-flex flex-column justify-content-center align-items-center border border-white pointer"
             style="background-color: #eeeeee" [tooltip]="reworkStatePendingTooltip">
           <span *ngIf="componentCheckResult && componentCheckResult.engine"
                 class="position-relative mr-2 d-inline-block icon-container">
            <i class="fas fa-cog lead-icon" style="color: lightgray; font-weight: bold; font-size: 2em"
               [class.hasReworks]="componentCheckResult.engine.hasReworks"></i>
          </span>

          <span *ngIf="componentCheckResult && componentCheckResult.part"
                class="position-relative mr-2 d-inline-block icon-container">
            <i class="fas fa-cog lead-icon" style="color: lightgray; font-weight: bold; font-size: 2em"
               [class.hasReworks]="componentCheckResult.part.hasReworks"></i>
          </span>
        </div>

        <div class="col d-flex flex-column justify-content-center align-items-center border border-white pointer"
             style="background-color: #eeeeee" [tooltip]="hasHotTestTooltip">
           <span *ngIf="componentCheckResult && componentCheckResult.engine"
                 class="position-relative mr-2 d-inline-block icon-container">
            <i class="fas fa-burn lead-icon" style="color: lightgray; font-weight: bold; font-size: 2em"
               [class.hasHotTest]="componentCheckResult.engine.hasHotTests"></i>
          </span>

          <span *ngIf="componentCheckResult && componentCheckResult.part"
                class="position-relative mr-2 d-inline-block icon-container">
            <i class="fas fa-burn lead-icon" style="color: lightgray; font-weight: bold; font-size: 2em"
               [class.hasHotTest]="componentCheckResult.part.hasHotTests"></i>
          </span>
        </div>

        <div class="col d-flex flex-column justify-content-center align-items-center border border-white pointer"
             style="background-color: #eeeeee" [tooltip]="hasColdTestTooltip">
           <span *ngIf="componentCheckResult && componentCheckResult.engine"
                 class="position-relative mr-2 d-inline-block icon-container">
            <i class="fas fa-snowflake lead-icon" style="color: lightgray; font-weight: bold; font-size: 2em"
               [class.hasColdTest]="componentCheckResult.engine.hasColdTests"></i>
          </span>
          <span *ngIf="componentCheckResult && componentCheckResult.part"
                class="position-relative mr-2 d-inline-block icon-container">
            <i class="fas fa-snowflake lead-icon" style="color: lightgray; font-weight: bold; font-size: 2em"
               [class.hasColdTest]="componentCheckResult.part.hasColdTests"></i>
          </span>
        </div>


        <div class="col d-flex flex-column justify-content-center align-items-center border border-white pointer"
             style="background-color: #eeeeee" [tooltip]="directRunnerTooltip">
           <span class="position-relative mr-2 d-inline-block icon-container"
                 *ngIf="componentCheckResult && componentCheckResult.engine">
            <i class="fas fa-fast-forward lead-icon" style="color: lightgray; font-weight: bold; font-size: 2em"
               [class.directRunner]="componentCheckResult.engine.isDirectRunner()"></i>
          </span>

          <span class="position-relative mr-2 d-inline-block icon-container"
                *ngIf="componentCheckResult && componentCheckResult.part">
            <i class="fas fa-fast-forward lead-icon" style="color: lightgray; font-weight: bold; font-size: 2em"
               [class.directRunner]="componentCheckResult.part.isDirectRunner()"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="componentCheckResult && componentCheckResult.stackIdentifier && !noLogistics">
    <div class="row pr-5">
      <div class="cpp-column-fixed-width-8 border border-white"
           [ngStyle]="{ 'background-color': hover ? '#B8DAFF' : ''}"></div>
      <div class="col d-flex flex-column justify-content-center align-items-center border border-white pointer"
           style="min-height: 65px; background-color: #eeeeee; font-size: 1em"
           [tooltip]="stackIdentifierTooltip">
        <span>
          <i class="fab fa-stack-overflow pr-1" style="color: #767676"></i>
          {{ componentCheckResult.stackIdentifier }}
          <op-copy-to-clipboard-icon class="small" [content]="componentCheckResult.stackIdentifier"></op-copy-to-clipboard-icon>
        </span>

      </div>
      <div class="col d-flex flex-column justify-content-center align-items-center border border-white pointer"
           style="min-height: 65px; background-color: #eeeeee" *ngIf="componentCheckResult.stackLocation"
           [tooltip]="stackLocationTooltip">

         <span>
          <i class="fas fa-warehouse pr-1" style="color: #767676"></i>
           {{ componentCheckResult.stackLocation }}
        </span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="componentCheckResult && componentCheckResult.details && detailsExtended">
    <div class="row pr-5" *ngIf="componentCheckResult.details.reworkDetails">
      <div class="cpp-column-fixed-width-8 border border-white"
           [ngStyle]="{ 'background-color': hover ? '#B8DAFF' : ''}"></div>
      <div class="col d-flex flex-column justify-content-center align-items-center border border-white pointer"
           style="background-color: #eeeeee" [tooltip]="reworkStatePendingTooltip">
           <span class="position-relative mr-2 d-inline-block icon-container">
            <i class="fas fa-cog hasReworks" style="font-weight: bold; font-size: 2em"></i>
          </span>
      </div>

      <div class="col-8">
        <div class="row" *ngFor="let rework of componentCheckResult.details.reworkDetails">

          <ng-template #reworkDetailsTooltip>
            <div class="row">
              <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'rework.complaint' | translate }}</div>
              <div class="col">{{ rework.response.complaint }}</div>
            </div>
            <div class="row">
                <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'rework.deliveredAt' | translate }}</div>
                <<div class="col"><op-datetime-show [dateTime]="rework.response.deliveredAt"></op-datetime-show></div>
            </div>
            <div class="row">
              <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'rework.analysis' | translate }}</div>
              <div class="col">{{ rework.response.analysis }}</div>
            </div>
            <div class="row">
              <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'rework.rework' | translate }}</div>
              <div class="col">{{ rework.response.rework }}</div>
            </div>
            <div class="row">
              <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'rework.employee' | translate }}</div>
              <div class="col">{{ rework.response.employee }}</div>
            </div>
            <div class="row">
              <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'rework.dispatchedAt' | translate }}</div>
              <div class="col"><op-datetime-show [dateTime]="rework.response.dispatchedAt"></op-datetime-show></div>
            </div>
            <div class="row">
              <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'rework.reworkState' | translate }}</div>
              <div class="col"><op-component-state-badge [name]="rework.response.reworkState"></op-component-state-badge></div>
            </div>



            <br>
            <ng-container>
              <div class="row" *ngIf="rework.response.reworkState === 'OPEN'">
                <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'rework.reworkOpen' | translate }}</div>
              </div>
            </ng-container>

            <ng-container *ngIf="rework.response.reworkType === 'OFFLINE' && rework.response.reworkState === 'APPROVED'; else reworkApprovalMissingTemplate">
              <div class="row" *ngIf="rework.response.qualityAssuranceCheckedAt">
                <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'rework.qualityAssuranceCheckedAt' | translate }}</div>
                <div class="col"><op-datetime-show [dateTime]="rework.response.qualityAssuranceCheckedAt"></op-datetime-show></div>
              </div>
              <div class="row">
                <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'rework.qualityAssuranceEmployee' | translate }}</div>
                <div class="col">{{ rework.response.qualityAssuranceEmployee }}</div>
              </div>
            </ng-container>

            <ng-template #reworkApprovalMissingTemplate>
              <div class="row">
                <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'rework.reworkApprovalMissing' | translate }}</div>
              </div>
            </ng-template>

          </ng-template>

          <div class="col d-flex flex-column justify-content-center border border-white pointer "
               style="min-height: 65px; background-color: #eeeeee; font-size: 1em;"
               [tooltip]="reworkDetailsTooltip">

            <div class="text-overflow-ellipses" style="white-space: nowrap">
              <op-datetime-show [dateTime]="rework.modifiedAt" class="text-monospace"></op-datetime-show>
              <i class="fas fa-cog pl-3 pr-1" [ngStyle]="{ 'color': rework.done ? 'darkgreen' : 'darkred' }"></i>

              {{ rework.problem ? rework.problem : rework.analysis }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pr-5" *ngIf="componentCheckResult.details.blockingDetails">
      <div class="cpp-column-fixed-width-8 border border-white"
           [ngStyle]="{ 'background-color': hover ? '#B8DAFF' : ''}"></div>
      <div class="col d-flex flex-column justify-content-center align-items-center border border-white pointer"
           style="background-color: #eeeeee" [tooltip]="blockedTooltip">
           <span class="position-relative mr-2 d-inline-block icon-container" *ngIf="componentCheckResult">
             <i class="fas" *ngIf="componentCheckResult.engine"
                [ngClass]="componentCheckResult.engine?.engineBlockedState !== 'NONE' ? 'fa-lock' : 'fa-lock-open'"
                [ngStyle]="{ 'color': componentCheckResult.engine.engineBlockedState !== 'NONE' ? 'darkred' : 'darkgreen' }"
                style="font-weight: bold; font-size: 2em">
            </i>

            <i class="fas" *ngIf="componentCheckResult.part"
               [ngClass]="componentCheckResult.part?.partBlockedState !== 'NONE' ? 'fa-lock' : 'fa-lock-open'"
               [ngStyle]="{ 'color': componentCheckResult.part.partBlockedState !== 'NONE' ? 'darkred' : 'darkgreen' }"
               style="font-weight: bold; font-size: 2em">
            </i>
          </span>
      </div>

      <div class="col-8">
        <div class="row" *ngFor="let blocking of componentCheckResult.details.blockingDetails">
          <ng-template #blockingDetailsTooltip>
            <div class="row" *ngIf="blocking.response.source === 'SZONLINEV2'">
              <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'blockings.humanReadableId' | translate }}</div>
              <div class="col">{{ blocking.response.humanReadableId }}</div>
            </div>
            <div class="row">
              <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'blockings.blockedAt' | translate }}</div>
              <div class="col">
                <op-datetime-show [dateTime]="blocking.response.blockedAt">
                </op-datetime-show>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'blockings.blockedBy' | translate }}</div>
              <div class="col">{{ blocking.response.blockedBy }}</div>
            </div>
            <div class="row">
              <div class="col-12 text-overflow-ellipses pre-white font-weight-bold">{{ 'blockings.blockingReason' | translate }}</div>
              <div class="col">{{ blocking.response.blockingReason ? blocking.response.blockingReason : blocking.response.action }}</div>
            </div>
            <ng-container *ngIf="blocking.response.releasedAt">
              <div class="row">
                <div class="col-12 text-overflow-ellipses pre-white  font-weight-bold">{{ 'blockings.releasedAt' | translate }}</div>
                <div class="col">
                  <op-datetime-show [dateTime]="blocking.response.blockedAt">
                  </op-datetime-show>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-overflow-ellipses pre-white  font-weight-bold">{{ 'blockings.releasedBy' | translate }}</div>
                <div class="col">{{ blocking.response.releasedBy }}</div>
              </div>
              <div class="row" *ngIf="blocking.response.source === 'SZONLINEV2'">
                <div class="col-12 text-overflow-ellipses pre-white  font-weight-bold">{{ 'blockings.releaseReason' | translate }}</div>
                <div class="col">{{ blocking.response.releaseReason }}</div>
              </div>
            </ng-container>
          </ng-template>


          <div class="col d-flex flex-column justify-content-center border border-white pointer bigger-tooltip"
               style="min-height: 65px; background-color: #eeeeee; font-size: 1em"
               [tooltip]="blockingDetailsTooltip">

            <div class="text-overflow-ellipses" style="white-space: nowrap">
              <op-datetime-show [dateTime]="blocking.blockedAt" class="text-monospace"></op-datetime-show>
              <i class="fas pl-3 pr-1"
                 [ngClass]="blocking.released ? 'fa-lock-open' : 'fa-lock'"
                 [ngStyle]="{ 'color': blocking.released ? 'darkgreen' : 'darkred' }"></i>

              {{ blocking.reason }}
            </div>


          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #directRunnerTooltip>
  <div>
    {{ 'component-state-check.tooltip.directRunner' | translate }}
  </div>
  <div>
    {{
      componentCheckResult.componentType === 'engine'
        ? (componentCheckResult.engine.isDirectRunner()
          ? ('true' | translate)
          : ('false' | translate))
        : (componentCheckResult.part.isDirectRunner()
          ? ('true' | translate)
          : ('false' | translate))
    }}
  </div>

</ng-template>

<ng-template #reworkStatePendingTooltip>
  <div>
    {{ 'component-state-check.tooltip.reworks' | translate }}
  </div>
  <div>
    {{
      componentCheckResult.componentType === 'engine' ?
        (componentCheckResult.engine.hasReworks ? ('true' | translate) : ('false' | translate)) : ('false' | translate)
    }}
  </div>

  <div>
    {{ 'component-state-check.tooltip.reworksPending' | translate }}
  </div>
  <div>
    {{
      componentCheckResult.componentType === 'engine' ?
        (componentCheckResult.engine.engineReworkState === 'PENDING' ? ('true' | translate) : ('false' | translate)) : ('false' | translate)
    }}
  </div>
</ng-template>

<ng-template #hasKeyCodeHistoryTooltip>
  <div>
    {{ 'component-state-check.tooltip.keyCodeHistory' | translate }}
  </div>
  <div>
    {{
      componentCheckResult.componentType === 'engine' ?
        (componentCheckResult.engine.hasKeyCodeHistory ? ('true' | translate) : ('false' | translate)) : ('false' | translate)
    }}
  </div>
</ng-template>

<ng-template #blockedTooltip>
  <div>
    {{ 'component-state-check.tooltip.blockings' | translate }}
  </div>
  <div>
    {{
      componentCheckResult.componentType === 'engine'
        ? (componentCheckResult.engine.hasBlockingActions
          ? ('true' | translate)
          : ('false' | translate))
        : (componentCheckResult.part.hasBlockingActions
          ? ('true' | translate)
          : ('false' | translate))
    }}
  </div>

  <div>
    {{ 'component-state-check.tooltip.blocked' | translate }}
  </div>
  <div>
    {{
      componentCheckResult.componentType === 'engine'
        ? (componentCheckResult.engine.engineBlockedState === 'NONE'
          ? ('false' | translate)
          : ('true' | translate))
        : (componentCheckResult.part.partBlockedState === 'NONE'
          ? ('false' | translate)
          : ('true' | translate))
    }}
  </div>
</ng-template>

<ng-template #stackLocationTooltip>
  <div>
    {{ 'component-state-check.tooltip.stackLocation' | translate }}
  </div>
  <div>
    {{ componentCheckResult.stackLocation }}
  </div>

</ng-template>

<ng-template #stackIdentifierTooltip>
  <div>
    {{ 'component-state-check.tooltip.stackIdentifier' | translate }}
  </div>
  <div>
    {{ componentCheckResult.stackIdentifier }}
  </div>
</ng-template>

<ng-template #hasHotTestTooltip>
  <div>
    {{ 'component-state-check.tooltip.hotTests' | translate }}
  </div>
  <div>
    {{
      componentCheckResult.componentType === 'engine'
        ? (componentCheckResult.engine.hasColdTests
          ? ('true' | translate)
          : ('false' | translate))
        : (componentCheckResult.part.hasColdTests
          ? ('true' | translate)
          : ('false' | translate))
    }}
  </div>

  <div>
    {{ 'component-state-check.tooltip.testFailure' | translate }}
  </div>
  <div>
    {{
      componentCheckResult.componentType === 'engine'
        ? (componentCheckResult.engine.engineTestState === 'FAILED'
          ? ('true' | translate)
          : ('false' | translate))
        : ('false' | translate)
    }}
  </div>
</ng-template>

<ng-template #hasColdTestTooltip>
  <div>
    {{ 'component-state-check.tooltip.coldTests' | translate }}
  </div>
  <div>
    {{
      componentCheckResult.componentType === 'engine'
        ? (componentCheckResult.engine.hasColdTests
          ? ('true' | translate)
          : ('false' | translate))
        : (componentCheckResult.part.hasColdTests
          ? ('true' | translate)
          : ('false' | translate))
    }}
  </div>

  <div>
    {{ 'component-state-check.tooltip.testFailure' | translate }}
  </div>
  <div>
    {{
      componentCheckResult.componentType === 'engine'
        ? (componentCheckResult.engine.engineTestState === 'FAILED'
          ? ('true' | translate)
          : ('false' | translate))
        : ('false' | translate)
    }}
  </div>
</ng-template>


